import request from "../../../../../helper/request";

const api = {
  getList: (params) => {
    return request.get("/return-locations", {
      params
    });
  },
  create: (data) => {
    return request.post("/return-locations", data);
  },
  update: (data) => {
    const { id } = data;
    delete data.id;
    return request.patch(`/return-locations/${id}`, data);
  }
};

export default api;
