/* eslint-disable */
import "./RouteChart.scss";

import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip } from "antd";
import { convertHourTo12Clock, getWidthChart, handleSortNoStop } from "../../helper";
import { VetIcon, MarkerEnd } from "../../svgs";
import { DND_STATUSES } from "../../constants";

// 1 hour = 200px
// driver = 41px
// const grid = 10;
const DISTANT_BETWEEN = 21;

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    ...draggableStyle
  };
};

const RouteChart = ({
  route,
  isDragging,
  dndStatus,
  stops,
  originStart,
  originEnd,
  oneHourPx,
  handleHoverMarker,
  handleClosePopup,
  handleClickMarker
  // handleStopDetail,
  // setDriverDetail,
  // setShowDriverDetail
}) => {
  const [isFocusDnd, setFocusDnd] = useState(false);
  const getListStyle = (isDraggingOver, snapshot) => {
    setFocusDnd(isDraggingOver);
  };

  const startTime = originStart && originStart?.split(":")[0];
  const endTime = originEnd && originEnd?.split(":")[0];
  const beginVet = getWidthChart(`${startTime}:00`, route.shiftStart, oneHourPx);

  const widthRoad = getWidthChart(
    route.shiftStart,
    route?.shiftEnd || "23:59",
    oneHourPx
  );
  const createTimesHour = () => {
    let timesDiv = [];
    for (let i = parseInt(startTime); i <= parseInt(endTime); i++) {
      timesDiv.push(
        <div
          key={i}
          className="time-hour"
          style={{ width: `${oneHourPx}px`, transition: "width 1s" }}
        >
          <div className="divide-line" />
        </div>
      );
    }
    return timesDiv;
  };
  const mouseOverStop = (stop) => {
    handleHoverMarker(stop, "stop");
  };
  const mouseOutStop = () => {
    handleClosePopup();
  };
  const mouseOverVet = () => {
    handleHoverMarker(routePlanDriver, "driver");
  };
  const mouseOutVet = () => {
    handleClosePopup();
  };
  const clickToStop = (stop) => {
    handleClickMarker({ ...stop, showViewMore: false }, "stop");
    // handleStopDetail(stop);
  };
  // const handleClickVetInChart = () => {
  //   handleClickMarker(route, "driver");
  //   setDriverDetail(route);
  // };
  // if (!route?.display) {
  //   return <></>;
  // }
  return (
    <div className="route-driver-chart" style={{ width: widthRoad }}>
      <div
        className={`group-line-chart ${isDragging && "isDragging"} ${
          isFocusDnd && "isFocusDnd"
        } ${dndStatus}`}
      >
        <Tooltip title={route.startAddress}>
          <div
            // onClick={handleClickVetInChart}
            // onMouseOver={() => mouseOverVet()}
            // onMouseOut={() => mouseOutVet()}
            className="start-driver"
            style={{
              position: "absolute",
              left: beginVet + DISTANT_BETWEEN
            }}
          >
            <VetIcon color={route?.color} />
          </div>
        </Tooltip>

        <div
          className="road-line"
          style={{
            width: widthRoad,
            backgroundColor: route?.color,
            left: beginVet + DISTANT_BETWEEN
          }}
        />
        {createTimesHour()}
        <Tooltip title={route?.endAddress}>
          <div
            className="finish-driver"
            style={{
              position: "absolute",
              left: getWidthChart(originStart, route.shiftEnd) + 7
            }}
          >
            <MarkerEnd color={route?.color} />
          </div>
        </Tooltip>
      </div>
      <Droppable
        key={route?.id}
        droppableId={`${route?.id}`}
        direction="horizontal"
        isDropDisabled={dndStatus === DND_STATUSES.OPTIMIZE}
      >
        {(provided, snapshot) => (
          <div
            className={`wrap-group-stop ${dndStatus} ${isDragging && "isDragging"}`}
            ref={provided.innerRef}
            style={{
              width: widthRoad,
              position: "absolute",
              left: beginVet + DISTANT_BETWEEN,
              ...getListStyle(snapshot.isDraggingOver, provided)
            }}
            {...provided.droppableProps}
          >
            {stops.map((stop, index) => {
              const width = 24;
              return (
                <Draggable key={stop.id} draggableId={stop.id} index={index}>
                  {(provided1, snapshot1) => {
                    return (
                      <Tooltip
                        title={`[${stop?.sortNo}] ${convertHourTo12Clock(
                          stop?.arrivalTime
                        )}-${convertHourTo12Clock(stop?.finishTime, true)}`}
                        key={stop.id}
                        overlayInnerStyle={{ opacity: 1 }}
                      >
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {...provided1.dragHandleProps}
                          style={{
                            zIndex: stop?.duration || 1,
                            position: "absolute",
                            width: stop?.widthStop,
                            color: route?.color,
                            borderColor: route?.color,
                            left: stop?.marginLeft,
                            ...getItemStyle(
                              snapshot1.isDragging,
                              provided1.draggableProps.style
                            )
                            // transition: "left 1s"
                          }}
                          key={stop.id}
                          className={`driver-stop ${stop.type}`}
                          onClick={() => clickToStop(stop)}
                          onMouseOver={() => mouseOverStop(stop)}
                          onMouseOut={() => mouseOutStop()}
                        >
                          {handleSortNoStop(stop, width)}
                        </div>
                      </Tooltip>
                    );
                  }}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </div>
  );
};
export default RouteChart;
