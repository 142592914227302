import { COLORS } from "configs/constants";
import React from "react";
export const InfoIcon = ({ style }) => {
  return (
    <svg
      style={style}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33337 3.66659H7.66671V4.99992H6.33337V3.66659ZM6.33337 6.33325H7.66671V10.3333H6.33337V6.33325ZM7.00004 0.333252C3.32004 0.333252 0.333374 3.31992 0.333374 6.99992C0.333374 10.6799 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7.00004 0.333252ZM7.00004 12.3333C4.06004 12.3333 1.66671 9.93992 1.66671 6.99992C1.66671 4.05992 4.06004 1.66659 7.00004 1.66659C9.94004 1.66659 12.3334 4.05992 12.3334 6.99992C12.3334 9.93992 9.94004 12.3333 7.00004 12.3333Z"
        fill="#43C1C2"
      />
    </svg>
  );
};

export const SwapCall = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  onClick,
  className
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 4l-4 4h3v7c0 1.1-.9 2-2 2s-2-.9-2-2V8c0-2.21-1.79-4-4-4S5 5.79 5 8v7H2l4 4 4-4H7V8c0-1.1.9-2 2-2s2 .9 2 2v7c0 2.21 1.79 4 4 4s4-1.79 4-4V8h3l-4-4z" />
    </svg>
  );
};

export const LockIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  onClick
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
    </svg>
  );
};

export const UnlockIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  onClick
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
    </svg>
  );
};

export const VisibleIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  onClick
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        fill={color}
        onClick={onClick}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
      </svg>
    </>
  );
};

export const VisibleOffIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  onClick
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={`0 0 24 24`}
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
      <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
    </svg>
  );
};

export const ScheduleIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px"
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        fill={color}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z" />
      </svg>
    </>
  );
};

export const UnscheduleIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px",
  className
}) => {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        height={width}
        viewBox="0 0 24 24"
        width={height}
        fill={color}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9.31 17l2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
      </svg>
    </>
  );
};
export const RestoreIcon = ({ color = COLORS.primary }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C7.03 0 3 4.03 3 9H0L4 12.99L8 9H5C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 12.87 15.87 16 12 16C10.07 16 8.32 15.21 7.06 13.94L5.64 15.36C7.27 16.99 9.51 18 12 18C16.97 18 21 13.97 21 9C21 4.03 16.97 0 12 0ZM11 5V10L15.25 12.52L16.02 11.24L12.5 9.15V5H11Z"
        fill={color}
      />
    </svg>
  );
};

export const MarkerEnd = ({
  color = COLORS.primary,
  width = "32px",
  height = "32px"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>marker copy 3</title>
      <defs>
        <circle id="path-1" cx="14" cy="14" r="14"></circle>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dispatch-Stop-Detail" transform="translate(-1341.000000, -843.000000)">
          <g id="dispatch" transform="translate(80.000000, 656.000000)">
            <g id="timeline" transform="translate(0.000000, 120.000000)">
              <g id="Group-19" transform="translate(24.000000, 48.000000)">
                <g id="timeline" transform="translate(448.000000, 10.000000)">
                  <g id="Group-9-Copy-3" transform="translate(16.000000, 11.000000)">
                    <g id="marker-copy-3" transform="translate(775.000000, 0.000000)">
                      <g id="Shape" strokeWidth="3">
                        <circle
                          stroke="#FFFFFF"
                          strokeLinejoin="square"
                          fill={color}
                          fillRule="evenodd"
                          cx="14"
                          cy="14"
                          r="12.5"
                        ></circle>
                        <use stroke={color} xlinkHref="#path-1"></use>
                      </g>
                      <g id="Group-5" transform="translate(6.000000, 6.000000)">
                        <polygon
                          id="Stroke-1"
                          strokeOpacity="0.0117647061"
                          stroke="#000000"
                          strokeWidth="1.33333336e-11"
                          points="0 0 15.999999 0 15.999999 15.999999 0 15.999999"
                        ></polygon>
                        <polygon
                          id="Fill-3"
                          fill="#FFFFFF"
                          points="9.59999896 3.99999968 9.33333258 2.66666645 3.33333306 2.66666645 3.33333306 13.9999989 4.66666629 13.9999989 4.66666629 9.33333258 8.39999959 9.33333258 8.66666597 10.6666658 13.3333323 10.6666658 13.3333323 3.99999968"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const VetIcon = ({ color = COLORS.primary, width = "41px", height = "41px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        {" "}
        <circle id="path-1" cx="14" cy="14" r="14"></circle>{" "}
      </defs>{" "}
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {" "}
        <g id="Dispatch-Stop-Detail" transform="translate(-755.000000, -177.000000)">
          {" "}
          <g id="Group-10" transform="translate(80.000000, -8.000000)">
            {" "}
            <g id="Group-12" transform="translate(383.861828, 18.000000)">
              {" "}
              <g id="marker-copy-4" transform="translate(297.551655, 173.279808)">
                {" "}
                <g id="Shape">
                  {" "}
                  <use fill={color} fillRule="evenodd" xlinkHref="#path-1"></use>{" "}
                  <circle
                    strokeOpacity="0.2"
                    stroke={color}
                    strokeWidth="6"
                    cx="14"
                    cy="14"
                    r="17"
                  ></circle>{" "}
                  <use stroke="#FFFFFF" strokeWidth="2" xlinkHref="#path-1"></use>{" "}
                </g>{" "}
                <g id="Group-5" transform="translate(6.000000, 6.000000)">
                  {" "}
                  <polygon
                    id="Stroke-1"
                    strokeOpacity="0.0117647061"
                    stroke="#000000"
                    strokeWidth="1.33333336e-11"
                    points="0 0 15.999999 0 15.999999 15.999999 0 15.999999"
                  ></polygon>{" "}
                  <path
                    d="M13.3333323,5.3333329 L11.3333324,5.3333329 L11.3333324,2.66666645 L1.99999984,2.66666645 C1.26666656,2.66666645 0.666666613,3.26666647 0.666666613,3.99999968 L0.666666613,11.3333324 L1.99999984,11.3333324 C1.99999984,12.439999 2.89333323,13.3333323 3.99999968,13.3333323 C5.10666612,13.3333323 5.99999951,12.439999 5.99999951,11.3333324 L9.99999919,11.3333324 C9.99999919,12.439999 10.8933326,13.3333323 11.999999,13.3333323 C13.1066656,13.3333323 13.9999989,12.439999 13.9999989,11.3333324 L15.3333321,11.3333324 L15.3333321,7.99999935 L13.3333323,5.3333329 Z M3.99999976,12.3333325 C3.44666645,12.3333325 2.99999976,11.8866657 2.99999976,11.3333325 C2.99999976,10.7799992 3.44666645,10.3333325 3.99999976,10.3333325 C4.5533329,10.3333325 4.99999976,10.7799992 4.99999976,11.3333325 C4.99999976,11.8866657 4.5533329,12.3333325 3.99999976,12.3333325 Z M12.9999989,6.33333282 L14.3066648,7.99999935 L11.3333324,7.99999935 L11.3333324,6.33333282 L12.9999989,6.33333282 Z M11.9999991,12.3333325 C11.4466657,12.3333325 10.9999991,11.8866657 10.9999991,11.3333325 C10.9999991,10.7799992 11.4466657,10.3333325 11.9999991,10.3333325 C12.5533323,10.3333325 12.9999991,10.7799992 12.9999991,11.3333325 C12.9999991,11.8866657 12.5533323,12.3333325 11.9999991,12.3333325 Z"
                    id="Fill-3"
                    fill="#FFFFFF"
                  ></path>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </svg>
  );
};

export const DoneIcon = ({ color = COLORS.primary, width = "24px", height = "24px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
};

export const OptimizeIcon = ({ color = COLORS.white }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 13.5C15.5 15.5 13 17 13 18.5H11C11 17 8.5 15.5 8.5 13.5C8.5 11.57 10.07 10 12 10C13.93 10 15.5 11.57 15.5 13.5ZM13 19.5H11V21H13V19.5ZM19 13C19 14.68 18.41 16.21 17.42 17.42L18.84 18.84C20.18 17.27 21 15.23 21 13C21 10.26 19.77 7.81 17.84 6.16L16.42 7.58C17.99 8.86 19 10.82 19 13ZM16 5L12 1V4C7.03 4 3 8.03 3 13C3 15.23 3.82 17.27 5.16 18.84L6.58 17.42C5.59 16.21 5 14.68 5 13C5 9.14 8.14 6 12 6V9L16 5Z"
        fill={color}
      />
    </svg>
  );
};

export const SendMobileIcon = ({ color = COLORS.white }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17H19V21C19 22.1 18.1 23 17 23H7C5.9 23 5 22.1 5 21V3C5 1.9 5.9 1.01 7 1.01L17 1C18.1 1 19 1.9 19 3V7H17V6H7V18H17V17ZM22 12L18 8V11H13V13H18V16L22 12Z"
        fill={color}
      />
    </svg>
  );
};

export const RedoIcon = ({ color = COLORS.primary, width = "20px", height = "20px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z" />
    </svg>
  );
};

export const UndoIcon = ({ color = COLORS.primary, width = "20px", height = "20px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width}
      viewBox="0 0 24 24"
      width={height}
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
    </svg>
  );
};

export const VehicleIcon = ({
  color = COLORS.primary,
  width = "20px",
  height = "20px"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </svg>
  );
};
export const DownloadIcon = ({ color = "#7732EF", width = "16px", height = "16px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      fill="none"
    >
      <path
        d="M12.6666 6H9.99992V2H5.99992V6H3.33325L7.99992 10.6667L12.6666 6ZM3.33325 12V13.3333H12.6666V12H3.33325Z"
        fill={color}
      />
    </svg>
  );
};

export const TruckIcon = (color = COLORS.primary) => {
  return `
    <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <circle id="path-1" cx="15" cy="15" r="15"></circle>
      </defs>
      <g id="Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Artboard" transform="translate(-103.000000, -104.000000)">
              <g id="truck" transform="translate(109.000000, 110.000000)">
                  <g id="Oval">
                      <use fill="${color}" fill-rule="evenodd" xlink:href="#path-1"></use>
                      <circle stroke-opacity="0.2" stroke="${color}" stroke-width="6" cx="15" cy="15" r="18"></circle>
                      <use stroke="#FFFFFF" stroke-width="2" xlink:href="#path-1"></use>
                  </g>
                  <g id="Group-5" transform="translate(7.500000, 7.500000)">
                      <polygon id="Stroke-1" stroke-opacity="0.0117647061" stroke="#000000" stroke-width="0.5" points="0 0 14.9999994 0 14.9999994 14.9999994 0 14.9999994"></polygon>
                      <path d="M12.4999993,4.99999985 L10.625,4.99999985 L10.625,2.49999985 L1.87499989,2.49999985 C1.18749993,2.49999985 0.624999963,3.06249988 0.624999963,3.74999977 L0.624999963,10.6249994 L1.87499989,10.6249994 C1.87499989,11.6624993 2.71249996,12.4999999 3.74999977,12.4999999 C4.78749959,12.4999999 5.62499996,11.6624993 5.62499996,10.6249994 L9.37499944,10.6249994 C9.37499944,11.6624993 10.2124995,12.4999999 11.2499993,12.4999999 C12.2874993,12.4999999 13.125,11.6624993 13.125,10.6249994 L14.3749991,10.6249994 L14.3749991,7.49999985 L12.4999993,4.99999985 Z M3.74999978,11.5624993 C3.23124987,11.5624993 2.81249983,11.1437493 2.81249983,10.6249994 C2.81249983,10.1062495 3.23124987,9.68749942 3.74999978,9.68749942 C4.26874968,9.68749942 4.68749972,10.1062495 4.68749972,10.6249994 C4.68749972,11.1437493 4.26874968,11.5624993 3.74999978,11.5624993 Z M12.1874993,5.93749964 L13.4124986,7.49999955 L10.6249994,7.49999955 L10.6249994,5.93749964 L12.1874993,5.93749964 Z M11.2499993,11.5624993 C10.7312494,11.5624993 10.3124994,11.1437493 10.3124994,10.6249994 C10.3124994,10.1062495 10.7312494,9.68749942 11.2499993,9.68749942 C11.7687493,9.68749942 12.1874993,10.1062495 12.1874993,10.6249994 C12.1874993,11.1437493 11.7687493,11.5624993 11.2499993,11.5624993 Z" id="Fill-3" fill="#FFFFFF"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  `;
};
