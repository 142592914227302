import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { doGetDriverFeedbacks } from "./thunks";

const DriverFeedbacksAdapter = createEntityAdapter({
  selectId: (feedback) => feedback.id
});

const initialState = DriverFeedbacksAdapter.getInitialState({
  metadata: {},
  feedback: {}
});

export const driverFeedbacksSlice = createSlice({
  name: "driverFeedbacks",
  initialState,
  reducers: {
    updatePaginationFeedbacks(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetDriverFeedbacks.fulfilled, (state, { payload }) => {
      DriverFeedbacksAdapter.setAll(state, payload.data);
      state.driver = {};
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectFeedbackById,
  selectIds: selectFeedbackByIds,
  selectEntities: selectFeedbackEntities,
  selectAll: selectAllFeedbacks,
  selectTotal: selectTotalFeedbacks
} = DriverFeedbacksAdapter.getSelectors((state) => state.driverFeedbacks);

export const selectMetadata = (state) => state.driverFeedbacks.metadata;
export const selectFeedback = (state) => state.driverFeedbacks.feedback;

export const { updatePaginationFeedbacks } = driverFeedbacksSlice.actions;

export default driverFeedbacksSlice.reducer;
