import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const doGetList = createAsyncThunk(
  "return-locations/get/loading",
  async (params) => {
    return api.getList(params);
  }
);

export const doCreate = createAsyncThunk(
  "return-locations/create/loading",
  async (data) => {
    return api.create(data);
  }
);

export const doUpdate = createAsyncThunk(
  "return-locations/update/loading",
  async (data) => {
    return api.update(data);
  }
);
