import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";

import { handleLogin } from "../store/thunk";
import { withAuthUser } from "../hocs/withAuth";

import "./LoginPage.scss";
const LoginPage = () => {
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(handleLogin(values));
  };
  return (
    <div className="login-page">
      <div className="wrap-page d-flex">
        <div
          className="image-logo"
          style={{
            background: `url(${process.env.PUBLIC_URL}/pages/user/bg-login.png) no-repeat center`,
            backgroundSize: "100% auto"
          }}
        >
          <img
            src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`}
            style={{ width: "auto" }}
          />
        </div>

        <div
          className="image-logo-m"
          style={{
            backgroundSize: "100% auto"
          }}
        >
          <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
          <img
            id="bus-icon"
            src="https://cdn-icons-png.freepik.com/256/14145/14145374.png?ga=GA1.1.1656896880.1708450789&semt=ais"
            alt="flexio delivery"
          />
        </div>
        <div className="image-logo-m" style={{ display: "none" }}>
          <img
            className="image-logo-m"
            src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`}
          />
        </div>

        <div className="form">
          <Form
            scrollToFirstError={true}
            layout="vertical"
            hideRequiredMark
            colon={false}
            onFinish={onSubmit}
          >
            <div className="form-header">
              <div>
                <p className="title">Welcome Back!</p>
                <p>Login to your Flexio account.</p>
              </div>
            </div>
            <div className="form-content">
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  { type: "email", message: "Invalid Email" },
                  { required: true, message: "Required" }
                ]}
              >
                <Input size="large" placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>
            </div>
            <Link to="/forgot-password">
              <div className="text-primary">Forgot Password?</div>
            </Link>

            <div className="form-footer">
              <Button loading={isLoading} type="primary" htmlType="submit" block>
                Login
              </Button>
              <div>
                <p>Don’t have an account? </p>
                <Link to="/register" className="primary-color">
                  Register
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default withAuthUser(LoginPage);
