import React from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";
import { ArrowDownIcon } from "components/svgs";
const { Option } = Select;

const SelectHook = ({
  className,
  control,
  name,
  options,
  defaultValue = "",
  label,
  required,
  showOptionsName = true,
  showLabel = true,
  errors,
  valueSelect = "value",
  labelSelect = "name",
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            suffixIcon={<ArrowDownIcon />}
            defaultValue={defaultValue}
            {...rest}
          >
            {options.map((option) => (
              <Option key={option[valueSelect]} value={option[valueSelect]}>
                {option[labelSelect]}
              </Option>
            ))}
          </Select>
        )}
      />
    </FormItemHook>
  );
};
export { SelectHook };
