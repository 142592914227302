import HeaderPage from "components/headers/HeaderPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import FeedbackTable from "../components/FeedbackTable";
import {
  selectAllFeedbacks,
  selectMetadata,
  updatePaginationFeedbacks
} from "../store/slice";
import { doGetDriverFeedbacks } from "../store/thunks";

const DriverFeedbacksPage = () => {
  const dispatch = useDispatch();

  const feedbacks = useSelector(selectAllFeedbacks);
  const metadata = useSelector(selectMetadata);
  const page = metadata?.currentPage || 1;
  const pagination = paginationFunc(
    metadata,
    "Feedbacks",
    updatePaginationFeedbacks,
    dispatch
  );
  useEffect(() => {
    dispatch(doGetDriverFeedbacks({ page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  return (
    <div className="list-driver-page">
      <HeaderPage leftSide={<div className="title-header">FEEDBACKS</div>} />

      <div className="content-table">
        <FeedbackTable
          dataSource={feedbacks}
          pagination={{ ...pagination, size: "small" }}
        />
      </div>
    </div>
  );
};
export default DriverFeedbacksPage;
