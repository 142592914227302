import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { doCreate, doDelete, doGet, doGetList } from "./thunks";

const AdditionalVehicleAdapter = createEntityAdapter({
  selectId: (additionalVehicle) => additionalVehicle.id
});

const initialState = AdditionalVehicleAdapter.getInitialState({
  metadata: {},
  additionalVehicle: {}
});

export const additionalVehiclesSlice = createSlice({
  name: "adminAdditionalVehicles",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(doGetList.fulfilled, (state, { payload }) => {
      AdditionalVehicleAdapter.setAll(state, payload.data);
      state.additionalVehicle = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGet.fulfilled, (state, { payload }) => {
      state.additionalVehicle = payload;
    });
    builder.addCase(doCreate.fulfilled, (state, { payload }) => {
      AdditionalVehicleAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDelete.fulfilled, (state, { payload }) => {
      AdditionalVehicleAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectAdditionalVehicleById,
  selectIds: selectAdditionalVehicleIds,
  selectEntities: selectAdditionalVehicleEntities,
  selectAll: selectAllAdditionalVehicles,
  selectTotal: selectTotalAdditionalVehicles
} = AdditionalVehicleAdapter.getSelectors((state) => state.additionalVehicles);

export const selectAdditionalVehicle = (state) =>
  state.additionalVehicles.additionalVehicle;

export default additionalVehiclesSlice.reducer;
