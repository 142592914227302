export const ORDER_SERVICE_TYPE_SELECT = [
  {
    name: "All",
    value: ""
  },
  {
    name: "Flexio Pool",
    value: "flexio_pool"
  },
  {
    name: "Flexio Same Day",
    value: "flexio_same_day"
  },
  {
    name: "Flexio Shift",
    value: "flexio_shift"
  },
  {
    name: "Flexio now",
    value: "flexio_now"
  }
];
export const ORDER_STATUS_SELECT = [
  {
    name: "Pending",
    value: "pending"
  },
  {
    name: "Accepted",
    value: "accepted"
  },
  {
    name: "In Progress",
    value: "in-progress"
  },
  {
    name: "Completed",
    value: "completed"
  },
  {
    name: "Canceled",
    value: "canceled"
  }
];
export const ORDERV2_STATUS_SELECT = [
  {
    name: "Submitted",
    value: "submitted"
  },
  {
    name: "Pending",
    value: "pending"
  },
  {
    name: "At Warehouse",
    value: "at_warehouse"
  },
  {
    name: "Pickup Scheduled",
    value: "pickup_scheduled"
  },
  {
    name: "Pickup In Progress",
    value: "pickup_in_progress"
  },
  {
    name: "Pickup Completed",
    value: "pickup_completed"
  },
  {
    name: "Pickup Failed",
    value: "pickup_failed"
  },
  {
    name: "Return Scheduled",
    value: "return_scheduled"
  },
  {
    name: "Return In Progress",
    value: "return_in_progress"
  },
  {
    name: "Return Completed",
    value: "return_completed"
  },
  {
    name: "Return Failed",
    value: "return_failed"
  },
  {
    name: "Dropoff Scheduled",
    value: "dropoff_scheduled"
  },
  {
    name: "Dropoff in progress",
    value: "dropoff_in_progress"
  },
  {
    name: "Dropoff completed",
    value: "dropoff_completed"
  },
  {
    name: "Dropoff Failed",
    value: "dropoff_failed"
  },
  {
    name: "Cancelled",
    value: "cancelled"
  }
];
export const INVOICING_STATUS_SELECT = [
  {
    name: "Completed",
    value: "completed"
  },
  {
    name: "Canceled",
    value: "canceled"
  }
];

export const CAPACITY_PLAN = {
  flexio_shift: { volume: 2370, weight: 35000, length: 9999 },
  flexio_now: { volume: 700, weight: 11000, length: 9999 }
};
