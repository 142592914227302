import React, { useState, useEffect } from "react";
import { Progress, Button } from "antd";

const ProgressModal = ({ numStops, visible, handleCancel }) => {
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    let totalSeconds;
    if (numStops <= 5) totalSeconds = 5;
    else if (numStops > 5 && numStops <= 30) totalSeconds = 20;
    else if (numStops > 30 && numStops <= 100) totalSeconds = 60;
    else totalSeconds = 300;

    const intervalTime = (totalSeconds * 1000) / 100;
    const interval = setInterval(() => {
      setProgress((prev) => {
        const nextValue = prev + 1;
        if (nextValue >= 100) {
          clearInterval(interval);
          setLoadingComplete(true);
        }
        return nextValue;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [numStops]);

  return (
    <>
      <Progress percent={progress} status="active" />
      {loadingComplete && (
        <div className="optimizing-modal-content">
          <span>Hmm, it usually should not take this long.</span>
          <br />
          <span>If the route does not complete,</span>
          <span>click Cancel to go back and edit details.</span>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      )}
    </>
  );
};

export default ProgressModal;
