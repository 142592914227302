import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { doCreate, doUpdate, doGetList } from "./thunks";

const ReturnLocationAdapter = createEntityAdapter({
  selectId: (returnLocation) => returnLocation.id
});

const initialState = ReturnLocationAdapter.getInitialState({
  metadata: {},
  returnLocation: {}
});

export const returnLocationsSlice = createSlice({
  name: "returnLocations",
  initialState,
  reducers: {
    updatePaginations(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetList.fulfilled, (state, { payload }) => {
      ReturnLocationAdapter.setAll(state, payload.data);
      state.returnLocation = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doCreate.fulfilled, (state, { payload }) => {
      ReturnLocationAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doUpdate.fulfilled, (state, { payload }) => {
      ReturnLocationAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload }
      });
    });
  }
});

export const {
  selectById: selectReturnLocationById,
  selectIds: selectReturnLocationIds,
  selectEntities: selectReturnLocationEntities,
  selectAll: selectAllReturnLocations,
  selectTotal: selectTotalReturnLocations
} = ReturnLocationAdapter.getSelectors((state) => state.returnLocations);

export const selectMetadata = (state) => state.returnLocations.metadata;
export const select = (state) => state.returnLocations.returnLocation;

export const { updatePaginations } = returnLocationsSlice.actions;

export default returnLocationsSlice.reducer;
