import { DEFAULT_CENTER } from "configs/constants";
import { replaceElementInArray } from "helper/util";
import { useEffect, useMemo, useState } from "react";
// import { getBounds, groupBy, groupStopByRoute } from "./helper";
import {
  getBounds,
  calculatePixelBetweenStops,
  checkStatusDnd,
  groupBy,
  moveDndWithCouple,
  reorderDnd,
  sortBy,
  groupStopByRoute
} from "./helper";
import { map, round, uniq, uniqBy } from "lodash";
import { updateRoute } from "modules/client/active-orders/store/orderSlice";
import { useDispatch } from "react-redux";

const PickupDropoffContainer = ({
  children,
  stops = [],
  routes = [],
  groupName = "type",
  holder,
  onRedirectToNewJobsPage,
  oneHourPx
}) => {
  const dispatch = useDispatch();
  const [earliestRoute, setEarliestRoute] = useState({});
  const [isDragging, setDragging] = useState(false);
  const [dndStatus, setDndStatus] = useState("none");
  const [groupStops, setGroupStops] = useState(groupBy([...stops], "routeId"));
  const [history, setHistory] = useState({
    currentIndex: 0,
    listGroupStops: []
  });

  const [originStops, setOriginStops] = useState(stops);
  const [displayAll, setDisplayAll] = useState(true);
  const [lockedAll, setLockedAll] = useState(true);
  const [bounds, setBounds] = useState([
    [32.958984, -5.353521], // southwestern corner of the bounds
    [43.50585, 5.615985] // northeastern corner of the bounds
  ]);
  const [containerRoutes, setContainerRoutes] = useState(routes);
  const [containerStops, setContainerStops] = useState(stops);
  const [mapStops, setMapStops] = useState(stops);
  // const [groupStops, setGroupStops] = useState({});

  const [center, setCenter] = useState(DEFAULT_CENTER);
  // const [stops, setStops] = useState([]);

  // const [swapList, setSwapList] = useState([]);
  // const [showModalUnscheduled, setModalUnscheduled] = useState();
  // const [showSwapModal, setSwapModal] = useState(false);
  const [showDriverDetail, setShowDriverDetail] = useState(false);
  const [stopDetail, setStopDetail] = useState();
  const [driverDetail, setDriverDetail] = useState();
  const [popupData, setPopupData] = useState();
  const [markerActive, setActiveMarker] = useState();
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    setDndStatus(checkStatusDnd(history, groupStops));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setDndStatus(checkStatusDnd(history, groupStops));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (!holder) {
      return;
    }
    holder.focus = (stop) => handleClickMarker(stop, "stop");
    return () => {
      holder.focus = undefined;
    };
    // eslint-disable-next-line
  }, [holder]);

  useEffect(() => {
    if (stops.length > 0) {
      const tmpStops = groupStopByRoute(stops, groupName);
      setContainerStops(tmpStops);
      setMapStops(tmpStops);
      setOriginStops(stops);
    }
  }, [stops, groupName]);

  useEffect(() => {
    const tmp = {};
    const routeIds = map(routes, "id");
    const tmpGroupStops = groupBy(containerStops, "routeId");
    let keyRouteIds = Object.keys(tmpGroupStops);
    keyRouteIds = uniq([...keyRouteIds, ...routeIds]);
    const sortShiftStartRoutes = routes.sort(sortBy("shiftStart", false));

    let tmpEarliest = {};
    if (sortShiftStartRoutes.length > 0) {
      tmpEarliest = sortShiftStartRoutes[0];
      setEarliestRoute(tmpEarliest);
    }
    if (keyRouteIds.length > 0) {
      keyRouteIds.forEach((routeId) => {
        let tmpStops = tmpGroupStops[routeId];
        if (tmpStops) {
          tmpStops = calculatePixelBetweenStops(
            tmpStops?.sort(sortBy("arrivalTime", false)),
            tmpEarliest.shiftStart,
            oneHourPx
          );
          if (String(routeId) === "null" || String(routeId) === "unscheduled") {
            tmpStops = tmpStops.map((stop, index) => {
              return {
                ...stop,
                unscheduledNo: index + 1
              };
            });
          }
          tmp[routeId] = tmpStops;
        } else {
          tmp[routeId] = [];
        }

        tmp[`assigned_${routeId}`] = [];
      });
      setGroupStops(tmp);
      setHistory({
        currentIndex: 0,
        listGroupStops: [tmp]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerStops, oneHourPx]);

  useEffect(() => {
    setContainerRoutes(routes);
  }, [routes]);

  useEffect(() => {
    if (stops && stops.length > 0) {
      if (stops.length === 1) {
        setCenter({
          lat: stops[0]?.lat,
          lng: stops[0]?.lng
        });
      } else {
        const dataBounds = getBounds(stops);
        setCenter(dataBounds.center);
        setBounds(dataBounds.bounds);
      }
    }
  }, [stops]);

  const handleClosePopup = (force = false) => {
    if (force) {
      setIsClick(false);
      setPopupData(undefined);
    }
    if (!isClick) {
      setIsClick(false);
      setPopupData(undefined);
    }
  };

  const handleStopDetail = (stop) => {
    setStopDetail(stop);
  };

  const handleClickMarker = (marker, type) => {
    setIsClick(true);
    handleStopDetail();

    setActiveMarker({
      ...marker,
      makerType: type
    });

    type === "driver" && setDriverDetail();
    type === "stop" && setStopDetail();

    setPopupData({
      showViewMore: true,
      ...marker,
      makerType: type
    });
    setCenter({
      lat: marker.lat,
      lng: marker.lng
    });
  };

  const handleHoverMarker = (marker, type) => {
    setPopupData({
      ...marker,
      lat: marker.lat,
      lng: marker.lng,
      makerType: type
    });
  };

  const goToCoupleStop = (clickStop) => {
    if (clickStop?.location?.type === "pickup") {
      const destinationStop = containerStops.find(
        (st) => st.jobId === clickStop.jobId && st.id !== clickStop.id
      );
      handleClickMarker(destinationStop, "stop");
    } else {
      const destinationStop = containerStops.find((st) =>
        st?.jobIds?.includes(clickStop.jobId)
      );
      handleClickMarker(destinationStop, "stop");
    }
  };

  const handleUpdateRoute = (route) => {
    if (route) {
      dispatch(
        updateRoute({
          route
        })
      );
      // setContainerRoutes(replaceElementInArray(containerRoutes, route));
      const tmpStops = [];
      containerStops.forEach((stop) => {
        if (stop.routeId === route.id) {
          tmpStops.push({
            ...stop,
            display: route.display
          });
        }
      });
      setMapStops(uniqBy([...tmpStops, ...containerStops], "id"));
    }
  };
  //type = locked | display
  const handleUpdateAllRoutes = (type) => {
    if (type == "display") {
      let tmpStops = [...containerStops];
      tmpStops = tmpStops.map((t) => ({
        ...t,
        [type]: type === "display" ? !displayAll : !lockedAll
      }));
      // setContainerStops(tmpStops);
      setMapStops(tmpStops);
    }
    let tmpRoutes = [...containerRoutes];

    tmpRoutes = tmpRoutes.map((t) => ({
      ...t,
      [type]: type === "display" ? !displayAll : !lockedAll
    }));
    type === "display" ? setDisplayAll(!displayAll) : setLockedAll(!lockedAll);
    setContainerRoutes(tmpRoutes);
  };

  const onDragEnd = (result) => {
    setDragging(false);
    const { source, destination } = result;
    let tmpListHistory = history.listGroupStops;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    let newItems = {};
    let newListHistory;
    let nextCurrentIndex = history.currentIndex;
    if (sInd === dInd) {
      const items = reorderDnd(groupStops[sInd], source.index, destination.index);
      newItems = {
        ...groupStops
      };
      newItems[sInd] = items;
      nextCurrentIndex = history.currentIndex + 1;
      newListHistory = tmpListHistory.slice(0, nextCurrentIndex).concat(newItems);
    } else {
      //destination always get assigned_
      const tmpResult = moveDndWithCouple(
        groupStops[sInd],
        groupStops[`assigned_${dInd}`],
        source,
        destination,
        true
      );
      newItems = {
        ...groupStops,
        ...tmpResult
      };
      nextCurrentIndex = history.currentIndex + 1;
      newListHistory = tmpListHistory.slice(0, nextCurrentIndex).concat(newItems);
    }
    setHistory({
      currentIndex: nextCurrentIndex,
      listGroupStops: newListHistory
    });
    setGroupStops(newItems);
  };

  const handleClickHistory = (status) => {
    let pointToVersion = history.currentIndex;
    let active = false;
    switch (status) {
      case "undo":
        pointToVersion = history.currentIndex - 1;
        if (pointToVersion >= 0) {
          active = true;
        }
        break;
      case "next":
        pointToVersion = history.currentIndex + 1;
        if (pointToVersion < history?.listGroupStops.length) {
          active = true;
        }
        break;
      case "revert":
        pointToVersion = 0;
        active = true;
        break;
    }
    if (active) {
      const tmpGroup = history.listGroupStops[pointToVersion];
      setGroupStops(tmpGroup);
      setHistory({
        ...history,
        currentIndex: pointToVersion
      });
    }
  };

  const onDragStart = () => {
    setDragging(true);
  };

  const disabledNextButton = useMemo(
    () =>
      history?.listGroupStops?.length === 0 ||
      history?.listGroupStops?.length === history?.currentIndex + 1,
    [history]
  );

  const disabledPreviousButton = useMemo(() => history?.currentIndex === 0, [history]);

  return children({
    stops,
    originStops,
    center,
    markerActive,
    handleClickMarker,
    handleHoverMarker,
    handleClosePopup,
    popupData,
    driverDetail,
    stopDetail,
    goToCoupleStop,
    handleStopDetail,
    handleUpdateRoute,
    handleUpdateAllRoutes,
    mapStops,
    containerStops,
    containerRoutes,
    bounds,
    displayAll,
    setDriverDetail,
    showDriverDetail,
    setShowDriverDetail,
    groupStops,
    setGroupStops,
    onDragEnd,
    onDragStart,
    handleClickHistory,
    history,
    earliestRoute,
    disabledNextButton,
    disabledPreviousButton,
    dndStatus,
    onRedirectToNewJobsPage
  });
};
export default PickupDropoffContainer;
