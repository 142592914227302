import { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";

export function useSearchStops(stops) {
  const [state, setState] = useState([]);
  const fuse = useMemo(
    () =>
      new Fuse(stops, {
        includeScore: true,
        keys: ["address"]
      }),
    [stops]
  );

  const search = (value) => {
    if (value.length === 0) {
      setState([]);
      return;
    }
    const result = fuse.search(value).map((it) => ({
      ...it.item,
      score: it.score
    }));
    setState(result);
  };

  useEffect(() => {
    search("");
    // eslint-disable-next-line
  }, []);

  return [state, search];
}
