import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Button } from "antd";

import {
  selectAllInvoicing,
  selectFilter,
  selectMetadata,
  updateFilterInvoicing,
  updatePaginationInvoicing
} from "../store/slice";
import invoiceApi from "../store/api";

import paginationFunc from "../../../../helper/hooks/pagination";

import InvoicingListTable from "../components/InvoicingListTable";
import AdminBreadCrumb from "components/AdminBreadCrumb";

import { FilterIcon } from "components/svgs";
import { ExportIcon } from "components/svgs";

import "./InvoicingList.scss";
import { getInvoicingList } from "../store/thunks";
import { buildFirstLevelFilterRoute } from "modules/shared/PickupDropoff/helper";
import { shortId } from "helper/util";
import FilterInvoicing from "modules/client/invoicing/components/FilterInvoicing";
import { doExportCsv } from "helper/export";

const InvoicingListPage = () => {
  const breadCrumbs = [{ text: "INVOICING", url: "/admin/invoicing" }];
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const invoicingList = useSelector(selectAllInvoicing);
  const metadata = useSelector(selectMetadata);
  const filter = useSelector(selectFilter);

  const pagination = paginationFunc(
    { ...metadata, itemsPerPage: get(metadata, "itemsPerPage", 10) },
    "Invoice",
    updatePaginationInvoicing,
    dispatch
  );

  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 20;

  useEffect(() => {
    const { pickupDateRange = [], status } = filter || {};
    const filterStatuses = isEmpty(status) ? ["canceled", "completed"] : status;
    dispatch(
      getInvoicingList({
        page: page,
        pageSize: pageSize,
        pickupFilter: { isInternalDriver: true },
        filter: buildFirstLevelFilterRoute(
          pickupDateRange?.length === 2
            ? {
                ...filter,
                status: filterStatuses,
                startPickupDate: pickupDateRange[0],
                endPickupDate: pickupDateRange[1]
              }
            : { ...filter, status: filterStatuses }
        )
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleClickFilter = (values) => {
    dispatch(updateFilterInvoicing(values));
  };

  const handleExportInvoicePdf = async (routeId, invoiceType) => {
    try {
      const response = await invoiceApi.exportInvoicePdf(routeId, invoiceType);
      const blob = new Blob([response], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `${invoiceType}-${shortId(routeId)}-${new Date().getTime()}.pdf`;
      window.open(link.href, "_blank");
    } catch (err) {
      console.log(err);
    }
  };

  const handleExport = async () => {
    const { pickupDateRange = [], status } = filter || {};
    const filterStatuses = isEmpty(status) ? ["canceled", "completed"] : status;
    await doExportCsv({
      path: "routes/export-invoicing",
      name: `invoicing-${new Date().getTime()}`,
      params: {
        filter: buildFirstLevelFilterRoute(
          pickupDateRange?.length === 2
            ? {
                ...filter,
                status: filterStatuses,
                startPickupDate: pickupDateRange[0],
                endPickupDate: pickupDateRange[1]
              }
            : { ...filter, status: filterStatuses }
        )
      }
    });
  };

  return (
    <div className="client-order-list-page invoicing-list-page admin">
      <div className="common-header">
        <AdminBreadCrumb breadCrumbs={breadCrumbs} />
        <div className="buttons d-flex flex-row gap-2">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
          <Button
            type="third"
            size="small"
            className={`svg-icon d-flex flex-row `}
            onClick={handleExport}
          >
            Export <ExportIcon />
          </Button>
        </div>
      </div>
      {showFilter && (
        <FilterInvoicing
          isAdmin
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterInvoicing(undefined))}
        />
      )}
      <div className="content-table">
        <InvoicingListTable
          dataSource={invoicingList}
          pagination={{ ...pagination, size: "small" }}
          onView={handleExportInvoicePdf}
        />
      </div>
    </div>
  );
};

export default InvoicingListPage;
