import { notification, Spin } from "antd";
import GoogleTag from "../components/gtag";
import request from "helper/request";
import React, { useState } from "react";
import Step1 from "../components/steps/Step1";
import "./DriverRegisterPage.scss";
const DriverRegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [step1Data, setStep1Data] = useState({});

  const handleSubmitForm = async (values) => {
    setIsLoading(true);
    const dataSubmit = {
      ...step1Data,
      ...values
    };
    try {
      await request.post("/drivers", dataSubmit);
      setStep1Data({});
      window.location.href = "/drivers/register/success";
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: JSON.parse(e?.message)?.message
      });
    }
  };
  return (
    <Spin spinning={isLoading}>
      <div className="driver-register-page">
        <div className="background-section" />
        <div className="main-content-page">
          <div className="wrap-logo">
            <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
          </div>
          <div className="wrap-form-content">
            <div className="wrap-steps">
              <div className="title">Drivers Registration</div>
            </div>
            <div className="wrap-form">
              <Step1
                dataForm={step1Data}
                setStepData={setStep1Data}
                handleSubmitForm={handleSubmitForm}
              />
            </div>
          </div>
        </div>
      </div>
      <GoogleTag></GoogleTag>
    </Spin>
  );
};
export default DriverRegisterPage;
