import React from "react";

import NotificationSettingsForm from "../forms/Notification";
import "./SettingsPage.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  doCreateNotificationSetting,
  doGetNotificationSettingList,
  doUpdateNotificationSetting
} from "../store/notification-settings/thunks";
import { selectAllNotificationSettings } from "../store/notification-settings/slice";
import { setSuccessMessage } from "app/global/slice";
import profileApi from "modules/client/profile/store/api";

const settingInitial = {
  enableSendSMS: true,
  enableSendEmail: true,
  enableNotifyStartJob: true,
  enableNotifyArrival: true,
  enableNotifyStopCompletion: true,
  enableNotifyGoingNext: true,
  startJobNotificationContent:
    "Your order from {customer_name} was picked up. ETA is {eta}.",
  arrivalNotificationContent: "Your order from {customer_name} has arrived.",
  stopCompletionNotificationContent:
    "Your order from {customer_name} has been completed!",
  goingNextNotificationContent:
    "Your order from {customer_name} is on the way and will be there at {eta}. {tracking_link}",
  notificationFields: {
    stop_id: "FX38274845",
    stop_name: "Central Drug",
    customer_name: "Flexio",
    contact_name: "James Nguyen",
    company: "Flexio",
    eta: "09:10 - 11:10",
    cod: "500",
    driver_name: "Driver La",
    driver_phone: "+15417543010",
    tracking_link: "https://app.goflexio.com/s/3e8ee5a0",
    driver_feedback_link: "https://app.goflexio.com/s/dfb/3e8ee5a0"
  }
};

const NotificationSetting = () => {
  const [setting, setSetting] = useState(settingInitial);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.global.isLoading);
  const notiSettings = useSelector(selectAllNotificationSettings);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    dispatch(doGetNotificationSettingList());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    try {
      const profileRes = await profileApi.getProfile();
      if (!profileRes) return;
      const { client, phone } = profileRes;
      setProfile({
        ...profileRes,
        ...(profileRes.client || {}),
        phone: phone || "",
        supportPhone: client.supportPhone || ""
      });
    } catch (err) {
      console.log(err);
    }
  };
  settingInitial.notificationFields.customer_name = profile.fullName;
  settingInitial.notificationFields.company = profile.clientName;
  useEffect(() => {
    if (notiSettings?.length) {
      setSetting({
        ...notiSettings[0],
        notificationFields: settingInitial.notificationFields
      });
    }
  }, [notiSettings]);

  const handleOnFinish = async (data) => {
    if (data?.id) {
      const { error } = await dispatch(doUpdateNotificationSetting(data));
      if (!error) {
        dispatch(setSuccessMessage("Updated successfully!"));
      }
    } else {
      const { error } = await dispatch(doCreateNotificationSetting(data));
      if (!error) {
        dispatch(setSuccessMessage("Created successfully!"));
      }
    }
  };

  return (
    <>
      <NotificationSettingsForm
        isFetching={isLoading}
        setting={setting}
        handleSubmit={handleOnFinish}
      />
    </>
  );
};

export default NotificationSetting;
