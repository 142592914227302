import React from "react";

import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";
import BroadcastHeader from "../components/BroadcastHeader";
import CreateForm from "../components/CreateForm";

import "./DriverBroadcastCreate.scss";

const breadCrumbs = [
  { text: "DRIVER BROADCASTS", url: "/admin/driver-broadcasts" },
  { text: "CREATE DRIVER BROADCAST" }
];

const DriverBroadcastCreate = () => {
  return (
    <div className="driver-broadcast-create">
      <BroadcastHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="form-content">
        <h2 className="title">Create Driver Broadcast</h2>
        <CreateForm />
      </div>
    </div>
  );
};

export default withPermission(DriverBroadcastCreate, canCreatePermission);
