import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { InputHook, SelectHook, RangePickerHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getClientList } from "modules/admin/clients/store/thunks";
import { selectAllDrivers } from "modules/admin/drivers/store/slice";
import { getDriverList } from "modules/admin/drivers/store/thunks";
import { selectAllClientUsers } from "modules/client/client-users/store/slice";
import { getListClientUser } from "modules/client/client-users/store/thunks";
import { selectAllServices } from "modules/client/service/store/slice";
import { getServiceList } from "modules/client/service/store/thunks";
import { ORDER_STATUS_SELECT } from "../../constants";

import "./FilterRoute.scss";

const FilterRoute = ({
  isAdmin,
  isHistoryPage,
  defaultValues,
  setShowFilter,
  handleClickFilter,
  onReset
}) => {
  const [serviceOption, setServiceOption] = useState([]);
  const users = useSelector(selectAllClientUsers);
  const services = useSelector(selectAllServices);
  const clients = useSelector(selectAllClients);
  const drivers = useSelector(selectAllDrivers);
  console.log("services", services);
  const dispatch = useDispatch();
  const {
    reset,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setServiceOption(
      services.map((ser) => ({
        ...ser,
        value: ser.id
      }))
    );
  }, [services]);

  useEffect(() => {
    dispatch(getListClientUser({ pageSize: 1000 }));
    dispatch(getServiceList());
    if (isAdmin) {
      dispatch(getClientList({ pageSize: 1000 }));
      dispatch(getDriverList());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  return (
    <div className="filter-order-component">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter">
          <InputHook
            control={control}
            placeholder="ID"
            label="ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />

          <RangePickerHook
            control={control}
            getValues={getValues}
            label="Pickup Date Range"
            name="pickupDateRange"
            errors={errors}
            showOptionsName={false}
          />

          <SelectHook
            showSearch={true}
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Service"
            name="serviceId"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={[{ name: "All", value: "" }, ...serviceOption]}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="Created by"
            label="Created by"
            name="userId"
            valueSelect="id"
            labelSelect="fullName"
            required
            errors={errors}
            options={[{ fullName: "All", id: "" }, ...users]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
          <SelectHook
            disabled={isHistoryPage}
            mode="multiple"
            allowClear
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Status"
            name="status"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={ORDER_STATUS_SELECT}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
          <InputHook
            control={control}
            placeholder="Location Name"
            label="Location Name"
            name="locationName"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Physical Address"
            label="Physical Address"
            name="physicalAddress"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Contact Name"
            label="Contact Name"
            name="contactName"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Internal Route ID"
            label="Internal Route ID"
            name="internalRouteId"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Internal Order ID"
            label="Internal Order ID"
            name="internalOrderId"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Internal Customer ID"
            label="Internal Customer ID"
            name="internalCustomerId"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Salesperson"
            label="Salesperson"
            name="salesPerson"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          {isAdmin && (
            <>
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                placeholder="Client"
                label="Client"
                name="clientId"
                valueSelect="id"
                labelSelect="clientName"
                options={[{ clientName: "All", id: "" }, ...clients]}
                required
                errors={errors}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              />
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                placeholder="Driver"
                label="Driver"
                name="driverId"
                valueSelect="id"
                labelSelect="fullName"
                options={[{ fullName: "All", id: "" }, ...drivers]}
                required
                errors={errors}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              />
            </>
          )}
        </div>
        <div className="wrap-button">
          <Button type="link" className="collapse" onClick={() => setShowFilter(false)}>
            Collapse <UpOutlined />
          </Button>
          <Button
            type="link"
            className="svg-icon clear"
            onClick={() => {
              reset();
              onReset();
            }}
          >
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FilterRoute;
