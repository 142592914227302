export const JOB_STATUS_COLOR = {
  assigned: "#0078D4",
  collected: "#43C1C2",
  unassigned: "#888888",
  completed: "#00C853",
  pickup_arrived: "#0078D4",
  dropoff_arrived: "#0078D4",
  failed: "#FF3D17"
};

export const JOB_STATUSES = {
  assigned: "assigned",
  collected: "collected",
  unassigned: "unassigned",
  completed: "completed",
  pickup_arrived: "pickup_arrived",
  dropoff_arrived: "dropoff_arrived",
  failed: "failed"
};

export const ROUTE_STATUSES = {
  pending: "Pending",
  accepted: "Accepted",
  completed: "Completed",
  "in-progress": "In Progress",
  canceled: "Canceled",
  failed: "Failed"
};

/* Added Flexio Pool list of package statuses and corresponding colors to be
  displayed on the frontend*/

export const POOL_PACKAGE_STATUSES = {
  submitted: "Submitted",
  pending: "Pending",
  at_warehouse: "At Warehouse",
  pickup_scheduled: "Pickup Scheduled",
  pickup_in_progress: "Pickup In Progress",
  pickup_completed: "Pickup Completed",
  pickup_failed: "Pickup Failed",
  return_scheduled: "Return Scheduled",
  return_in_progress: "Return In Progress",
  return_completed: "Return Completed",
  return_failed: "Return Failed",
  dropoff_scheduled: "Dropoff Scheduled",
  dropoff_in_progress: "Dropoff In Progress",
  dropoff_completed: "Dropoff Completed",
  dropoff_failed: "Dropoff Failed",
  cancelled: "Canceled"
};

export const POOL_PACKAGE_COLORS = {
  submitted: "#888888",
  pending: "#888888",
  at_warehouse: "#FF8400",
  pickup_scheduled: "#FF8400",
  pickup_in_progress: "#FF8400",
  pickup_completed: "#00C853",
  pickup_failed: "#FF3D17",
  return_scheduled: "#FF8400",
  return_in_progress: "#FF8400",
  return_completed: "#00C853",
  return_failed: "#FF3D17",
  dropoff_scheduled: "#FF8400",
  dropoff_in_progress: "#FF8400",
  dropoff_completed: "#00C853",
  dropoff_failed: "#FF3D17",
  canceled: "#000000"
};
