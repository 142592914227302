import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateDriver,
  doDeleteDriver,
  doGetDriver,
  doImportDrivers,
  doUpdateDriver,
  getDriverList,
  doGetAllCities
} from "./thunks";

const DriverAdapter = createEntityAdapter({
  selectId: (driver) => driver.id
});

const initialState = DriverAdapter.getInitialState({ metadata: {}, driver: {} });

export const driversSlice = createSlice({
  name: "adminDrivers",
  initialState,
  reducers: {
    updatePaginationDrivers(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterDrivers(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDriverList.fulfilled, (state, { payload }) => {
      DriverAdapter.setAll(state, payload.data);
      state.driver = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetDriver.fulfilled, (state, { payload }) => {
      state.driver = payload;
    });
    builder.addCase(doCreateDriver.fulfilled, (state, { payload }) => {
      DriverAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doImportDrivers.fulfilled, (state, { payload }) => {
      DriverAdapter.addMany(state, payload);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems + payload?.length || 0
      };
    });
    builder.addCase(doUpdateDriver.fulfilled, (state, { payload }) => {
      DriverAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload }
      });
      state.driver = payload;
    });
    builder.addCase(doDeleteDriver.fulfilled, (state, { payload }) => {
      DriverAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
    builder.addCase(doGetAllCities.fulfilled, (state, { payload }) => {
      state.cities = payload;
    });
  }
});

export const {
  selectById: selectDriverById,
  selectIds: selectDriverIds,
  selectEntities: selectDriverEntities,
  selectAll: selectAllDrivers,
  selectTotal: selectTotalDrivers
} = DriverAdapter.getSelectors((state) => state.drivers);

export const selectMetadata = (state) => state.drivers.metadata;
export const selectDriver = (state) => state.drivers.driver;
export const selectFilter = (state) => state.drivers.filter;
export const selectAllCities = (state) => state.drivers.cities;

export const { updatePaginationDrivers, updateFilterDrivers } = driversSlice.actions;

export default driversSlice.reducer;
