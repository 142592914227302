/*
  Generate PDF Packing Slips for Flexio Pool Packages.

  The existing "generatePdfPackingSlip" gets data from Routes and Jobs, which we don't have at the package-centric Flexio Pool. This is a version which gets the relevant data directly from a package array (the "dataSource" is usually coming from "PackageList" in FlexioPoolList.jsx; e.g. an array of 20 packages for the paginated view).
*/

// imports
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import _find from "lodash/find";
import { showInfo, shortId } from "./util";

/*
  Helper functions
*/

// helper for splitting address
const splittedAddress = (address) => {
  let firstAddress = "N/A";
  let lastAddress = "N/A";
  if (address) {
    const firstCommaIdx = address.indexOf(",");
    if (firstCommaIdx !== -1) {
      firstAddress = address.substr(0, firstCommaIdx)?.trim();
      lastAddress = address.substr(firstCommaIdx + 1, address.length - 1)?.trim();
    } else {
      firstAddress = address;
    }
  }
  return {
    firstAddress,
    lastAddress
  };
};

// helpers for truncating long address text
// note: see util.js for additional helpers (shortId)
const ellipsisAddressText = (text) => {
  return text?.length > 30 ? `${text.substr(0, 29)}...` : text;
};

const ellipsisPackageName = (text) => {
  return text?.length > 20 ? `${text.substr(0, 19)}...` : text;
};

/*
  Main imperative function for defining the layout of the PDF and writing data to it.
  The usage of it stems from FlexioPoolTable.jsx, which calls:
  const doc = await generatePdfPackingSlipPool(packages);
  And then the generatePdfPackingSlipPool calls this.
*/

const fillContentToFile = (doc, pack) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let startX = 3;
  let startY = 3;

  doc.setLineWidth(0.3);
  doc.line(startX, startY, pageWidth - startX, startY);
  doc.line(startX, pageHeight - startY, pageWidth - startX, pageHeight - startY);
  doc.line(startX, startY, startX, pageHeight - startY);
  doc.line(pageWidth - startX, startY, pageWidth - startX, pageHeight - startY);

  startX += 5;
  startY += 6;

  let img = new Image();
  img.crossOrigin = "anonymous";
  img.src = `/logos/${window.REACT_APP_LOGO_FILE_NAME}`;

  doc.addImage(img, "PNG", startX, startY, 30, 10);

  doc.setFontSize(10);
  doc.setTextColor("888888");
  doc.setFont("inter", "bold");
  doc.text("FROM:", pageWidth - 60, startY);

  startY += 6;

  doc.setTextColor("212121");
  doc.setFontSize(10);
  doc.text(showInfo(pack.pickupContactName), pageWidth - 60, startY);

  startY += 6;

  doc.setFontSize(8);
  doc.setFont("inter", "normal");
  // This is "Location Name" - not using for now, same as address
  // doc.text(ellipsisAddressText(showInfo(pack.pickupAddress)), pageWidth - 60, startY);

  startY += 3;

  doc.setFontSize(12);
  doc.setTextColor("1F3998");
  doc.setFont("inter", "bold");
  doc.text("Flexio Pool", startX + 2, startY);

  doc.line(startX + 35, startY - 21, startX + 35, startY + 8);

  startY += 1;

  doc.setFontSize(8);
  doc.setTextColor("212121");
  doc.setFont("inter", "normal");
  doc.text(
    ellipsisAddressText(splittedAddress(pack.pickupAddress).firstAddress),
    pageWidth - 60,
    startY
  );

  startY += 4;

  doc.text(
    ellipsisAddressText(splittedAddress(pack.pickupAddress).lastAddress),
    pageWidth - 60,
    startY
  );

  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 8;

  doc.setFontSize(8);
  doc.setTextColor("888888");
  doc.setFont("inter", "bold");
  doc.text("TO:", pageWidth - 95, startY);

  startY += 6;

  doc.setTextColor("212121");
  doc.setFontSize(10);
  doc.text(showInfo(pack.dropoffContactName), pageWidth - 95, startY);

  startY += 6;

  doc.setFontSize(8);
  doc.setFont("inter", "normal");
  if (showInfo(pack.dropoffStopName) != "N/A") {
    doc.text(showInfo(pack.dropoffStopName), pageWidth - 95, startY);
  }

  startY += 4;

  doc.setFontSize(8);
  doc.text(
    // removing "ellipsisAddressText" to show full address
    splittedAddress(pack.dropoffAddress).firstAddress,
    pageWidth - 95,
    startY
  );

  startY += 4;

  doc.text(
    // removing "ellipsisAddressText" to show full address
    splittedAddress(pack.dropoffAddress).lastAddress,
    pageWidth - 95,
    startY
  );

  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 10;

  doc.setFontSize(12);
  doc.text("Order ID:", startX, startY);
  doc.setFont("inter", "bold");
  doc.text(shortId(pack.orderId), startX + 35, startY);

  startY += 6;

  doc.setFontSize(12);
  doc.setFont("inter", "normal");
  doc.text("Package ID:", startX, startY);
  doc.setFont("inter", "bold");
  doc.text(shortId(pack.id), startX + 35, startY);

  // Line for Shopify Order ID
  if (pack.shopifyOrderId) {
    startY += 6;
    doc.setFontSize(12);
    doc.setFont("inter", "normal");
    const text1 = "Shopify Order ID:";
    const text2 = showInfo(pack.shopifyOrderId);
    const totalWidth = doc.getTextWidth(text1) + doc.getTextWidth(text2);
    //const centerX = (pageWidth - totalWidth) / 2;
    doc.text(text1, startX, startY);
    doc.setFont("inter", "bold");
    doc.text(text2, startX + doc.getTextWidth(text1), startY);
  }
  startY += 5;

  startX += 3;
  doc.setFontSize(6);
  doc.setFont("inter", "normal");
  doc.text(showInfo(pack.packageId), startX, startY);
  //doc.text(pack.quantity + "", pageWidth - 45, startY);
  startX -= 3;

  // doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 8;

  doc.setFontSize(12);
  doc.setFont("inter", "normal");
  doc.text("Reference ID:", startX, startY);
  doc.setFontSize(12);
  doc.setFont("inter", "bold");
  doc.text(showInfo(pack.barcode), startX + 35, startY);

  startY += 8;

  doc.setFontSize(8);
  doc.setFont("inter", "bold");
  doc.text("Driver Note", startX, startY);

  startY += 5;

  // the maxWidth allows the text to auto-wrap.
  // Recommend max 255 chars, which, depending on word breaks, happens to be ~5 lines.
  // If we need more space for Driver Notes, can make the text smaller or widen a little.
  doc.setFontSize(10);
  doc.setFont("inter", "normal");
  doc.text(showInfo(pack?.note), startX, startY, { maxWidth: 85 });

  startY += 15;

  // line to separate barcode from details
  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  // Barcode - the JSBarcode requires an img element to render on, then we write to PDF.
  // img is exported as part of FlexioPoolTable element and set to display:none via page CSS.

  const packBarcodeId = "#poolPackageBarcode";
  JsBarcode(packBarcodeId, shortId(pack.id), {
    format: "CODE128", // explicit format, 128 is better (denser, more chars avail) than 39
    displayValue: false, // false hides rendered numbers below
    text: "Package ID" // manual include this as barcode explainer
  });
  const packBarcodeImg = document.querySelector(`img${packBarcodeId}`);
  doc.addImage(
    packBarcodeImg.src,
    "JPEG",
    startX + 5,
    pack.shopifyOrderId ? startY + 7 : startY + 10,
    80,
    15
  );
};

/*
  Main exported func; takes Pool package array, gets details, calls jsPDF and fillContentToFile.
*/
const generatePdfPackingSlipPool = (poolPackageArray, packageId) => {
  // early return if nothing passed
  if (!poolPackageArray?.length) return;

  // DEBUG - this works - nice to review the array that we then map.
  // console.log("poolPackageArray of 20 items:");
  // console.log(poolPackageArray);

  // map the array. If you want to add more details to packing slip, like phone or email, here's the place to do it.
  const mappedJobToPackages = poolPackageArray.map((item) => ({
    ...item,
    packageId: item?.id,
    orderId: item?.orderV2_id,
    barcode: item?.barcode,
    note: item?.note,
    pickupContactName: item?.pickupContactName,
    pickupAddress: item?.pickupAddress,
    dropoffContactName: item?.dropoffContactName,
    dropoffAddress: item?.dropoffAddress
  }));

  // required to init the PDF, and then help with the loop
  // TODO: pass the index from the datasource > Page > Component > handle Function > this func.
  // const firstPackage = mappedJobToPackages[0];
  const firstPackage = mappedJobToPackages.find(
    (mapPackage) => packageId === mapPackage.id
  );

  // initialize the PDF
  // portrait, a6 size is ~4x6 inches
  const doc = new jsPDF("p", "mm", "a6", true);
  doc.addFont("/Inter-Light.ttf", "inter", "normal");
  doc.addFont("/Inter-Bold.ttf", "inter", "bold");

  // do the main fill of PDF data
  fillContentToFile(doc, firstPackage);

  // Recursive loop for each package
  // will be needed for batch; right now we just do singles
  /*
  for (let i = 1; i < poolPackageArray.length; i++) {
    let quantity = poolPackageArray[i]?.quantity || 0;
    while (quantity > 0) {
      doc.addPage("a6");
      fillContentToFile(doc, poolPackageArray[i]);
      quantity -= 1;
    }
  }
*/
  return doc;
};

export default generatePdfPackingSlipPool;
