import React from "react";
import "./HeaderContent.scss";
import { Link } from "react-router-dom";
import "./ProfileInfoBanner.scss";

import { ExclamationIcon } from "components/svgs";
import { UPDATE_PROFILE_TO_CONTINUE } from "configs/constants";

const ProfileInfoBanner = ({ missingFields }) => {
  return (
    <div className="banner gap-2">
      <div
        className="d-flex"
        style={{
          color: "#FFFF"
        }}
      >
        <p>
          Please complete the address information in your{" "}
          <Link className="profile-info-banner__link" to="/settings">
            profile
          </Link>{" "}
          to unlock all Flexio features.
        </p>
      </div>
    </div>
  );
};

export default ProfileInfoBanner;
