import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import {
  doCreateClientUser,
  doDeleteClientUser,
  doGetClientUser,
  doUpdateClientUser,
  getListClientUser
} from "./thunks";

const ClientUsersAdapter = createEntityAdapter({
  selectId: (clientUser) => clientUser.id
});

const initialState = ClientUsersAdapter.getInitialState({
  metadata: {},
  clientUser: {},
  filter: {}
});

export const clientUserSlice = createSlice({
  name: "client-users",
  initialState,
  reducers: {
    updatePaginationClientUsers(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterClientUsers(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getListClientUser.fulfilled, (state, { payload }) => {
      ClientUsersAdapter.setAll(state, payload.data);
      state.clientUser = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetClientUser.fulfilled, (state, { payload }) => {
      state.clientUser = payload;
    });
    builder.addCase(doCreateClientUser.fulfilled, (state, { payload }) => {
      ClientUsersAdapter.addOne(state, payload);
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        totalItems: (oldMeta.totalItems || 0) + 1
      };
    });
    builder.addCase(doUpdateClientUser.fulfilled, (state, { payload }) => {
      ClientUsersAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.clientUser = payload;
    });
    builder.addCase(doDeleteClientUser.fulfilled, (state, { payload }) => {
      ClientUsersAdapter.removeOne(state, payload?.id);
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        totalItems: (oldMeta.totalItems || 1) - 1
      };
    });
  }
});

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllClientUsers,
  selectTotal: selectTotalUsers
} = ClientUsersAdapter.getSelectors((state) => state.clientUsers);

export const selectMetadata = (state) => state.clientUsers.metadata;
export const selectUser = (state) => state.clientUsers.clientUser;
export const selectFilter = (state) => state.clientUsers.filter;

export const { updatePaginationClientUsers, updateFilterClientUsers } =
  clientUserSlice.actions;

export default clientUserSlice.reducer;
