import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getSaasCountriesList = createAsyncThunk(
  "saascountries/get/loading",
  async (data) => {
    return settingApi.getSaasCountriesList(data);
  }
);

export const getAllSaasCountries = createAsyncThunk(
  "saascountries/getAll/loading",
  async () => {
    return settingApi.getAllSaasCountries();
  }
);

export const doCreateSaasCountry = createAsyncThunk(
  "saascountries/create/loading",
  async (data) => {
    return settingApi.createSaasCountry(data);
  }
);

export const doDeleteSaasCountry = createAsyncThunk(
  "saascountries/delete/loading",
  async (id) => {
    return settingApi.deleteSaasCountry(id);
  }
);
