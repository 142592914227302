import { Spin, Menu } from "antd";
import React, { useState } from "react";
import FilterMap from "./components/FilterMap";
import "./index.scss";
import { useEffect } from "react";
import { dashboardApi } from "./store/api";
import { buildFirstLevelFilterStatistic } from "./helper";
import moment from "moment";
import _pick from "lodash/pick";
import { DATE_FORMAT_FRONTEND } from "configs/constants";
import { setError } from "app/global/slice";
import { useDispatch } from "react-redux";

import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import AdminBreadCrumb from "components/AdminBreadCrumb";
import { decodePolyline } from "modules/shared/PickupDropoff/decodePolyline";
import { useHistory } from "react-router-dom";

import { uniqBy, filter, isEmpty } from "lodash";
import * as firebaseDB from "firebase/database";
import { realtimeDB } from "configs/firebaseConfig";

const breadCrumbs = [{ text: "DASHBOARD" }, { text: "MAP" }];

const DashboardMapPage = ({ isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState("/dashboard/map");

  useEffect(() => {
    const today = moment().format(DATE_FORMAT_FRONTEND);
    loadMapData({ dateRange: [today, today] });
    // eslint-disable-next-line
  }, []);

  const getCurrentDriverLocation = (driverId) => {
    const url = `/location/${moment().format("YYYY-MM-DD")}/${driverId}`;
    const driverLocationRef = firebaseDB.ref(realtimeDB, url);
    const currentDriverLocation = {};

    firebaseDB.onValue(driverLocationRef, (snapshot) => {
      const driverLocation = snapshot.val();
      if (isEmpty(driverLocation)) return;
      currentDriverLocation.lat = driverLocation.latitude;
      currentDriverLocation.lng = driverLocation.longitude;
    });

    return currentDriverLocation;
  };

  const mapRoutes = (routes) => {
    const routeT = [];
    filter(uniqBy(routes, "driverId"), (r) => r.driver)?.forEach((route) => {
      const driverLocation = getCurrentDriverLocation(route.driverId);
      if (driverLocation.lat && driverLocation.lng) {
        routeT.push({
          ...route,
          coords: route?.polylines ? decodePolyline(route.polylines).coords : [],
          display: true,
          lat: driverLocation.lat,
          lng: driverLocation.lng
        });
      }
    });

    return routeT;
  };

  const mapLocations = (routes) => {
    const stopsT = [];
    let sortNoUnscheduled = 1;
    routes?.map((route) => {
      route.routeLocations.forEach((routeLocation) => {
        stopsT.push({
          ...routeLocation,
          ...routeLocation.location,
          sortNo:
            route?.name === "unscheduled" ? sortNoUnscheduled : routeLocation?.sortNo,
          routeId: route?.name === "unscheduled" ? "unscheduled" : route.id,
          color: route?.color || "#c4c4c4",
          orderId: route?.orderId,
          display: true
        });
        if (route?.name === "unscheduled") {
          sortNoUnscheduled += 1;
        }
      });
    });
    return stopsT;
  };

  const loadMapData = async (params) => {
    try {
      setLoading(true);
      const parsedParams = { ...params };
      if (params?.dateRange) {
        delete parsedParams.dateRange;
        parsedParams.startDate = params.dateRange[0];
        parsedParams.endDate = params.dateRange[1];
      }
      const map = await dashboardApi.getMap({
        ..._pick(params, ["clientId", "territoryId"]),
        filter: buildFirstLevelFilterStatistic(parsedParams)
      });
      setMapData(map);
    } catch (err) {
      console.log(err);
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  const onClick = (e) => {
    const key = e?.key;
    setCurrent(key);
    key && history.push(`${key}`);
  };

  return (
    <Spin spinning={loading}>
      <div className="dashboard map">
        {isAdmin ? (
          <AdminBreadCrumb breadCrumbs={breadCrumbs} />
        ) : (
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
            <Menu.Item key="/dashboard">Operations</Menu.Item>
            <Menu.Item key="/dashboard/map">Map</Menu.Item>
          </Menu>
        )}
        <FilterMap isAdmin={isAdmin} handleClickFilter={loadMapData} />
        <PickupDropoffContainer
          stops={mapLocations(mapData.routes)}
          routes={mapRoutes(mapData.routes)}
        >
          {({
            handleClickMarker,
            handleHoverMarker,
            popupData,
            handleClosePopup,
            goToCoupleStop,
            center,
            containerRoutes,
            mapStops,
            bounds,
            driverDetail,
            setDriverDetail,
            showDriverDetail,
            setShowDriverDetail
          }) => (
            <MapBox
              handleClosePopup={handleClosePopup}
              handleClickMarker={handleClickMarker}
              handleHoverMarker={handleHoverMarker}
              stops={mapStops.filter((s) => s.type === "pickup")}
              center={center}
              routes={containerRoutes}
              popupData={popupData}
              goToCoupleStop={goToCoupleStop}
              height={400}
              bounds={bounds}
              driverDetail={driverDetail}
              setDriverDetail={setDriverDetail}
              showDriverDetail={showDriverDetail}
              setShowDriverDetail={setShowDriverDetail}
              isShowViewMoreVet={false}
              isDashboard={true}
            />
          )}
        </PickupDropoffContainer>
      </div>
    </Spin>
  );
};

export default DashboardMapPage;
