import { COLORS } from "configs/constants";
import React from "react";
// import { durationMinutes } from "../../helper";
import { LockIcon, UnlockIcon, VisibleIcon, VisibleOffIcon } from "../../svgs";
import DriverItem from "./DriverItem";
import "./Timeline.scss";
const stopsContainer = {
  maxHeight: "400px"
};
const overflowScroll = {
  overflow: "scroll",
  scrollbarColor: "#bbb #eee",
  scrollbarWidth: "thin"
};

const Timeline = ({
  groupStops = {},
  routes = [],
  displayAll,
  isLockAll,
  firstStartTime = "00:00",
  lastEndTime = "23:59",
  oneHourPx,
  handleUpdateAllRoutes,
  handleHoverMarker,
  handleClosePopup,
  handleClickMarker,
  handleUpdateRoute,
  setDriverDetail,
  setShowDriverDetail
}) => {
  const [startAt, endAt] = [firstStartTime, lastEndTime];
  // const maxDuration = durationMinutes(startAt, endAt);
  const startTime = startAt && startAt?.split(":")[0];
  const endTime = endAt && endAt?.split(":")[0];

  const createTimesHeader = () => {
    let timesDiv = [];
    for (let i = parseInt(startTime); i <= parseInt(endTime); i++) {
      timesDiv.push(
        <div
          key={i}
          className="time-hour"
          style={{ width: `${oneHourPx}px`, transition: "width 1s" }}
        >
          {`${Number(i)}:00`}
        </div>
      );
    }
    return timesDiv;
  };

  return (
    <div className="timeline-component">
      <div className="group-drivers-chart" style={overflowScroll}>
        <div className="driver-chart header ">
          <div className="info-side" style={{ zIndex: 9 }}>
            {isLockAll ? (
              <LockIcon
                color={COLORS.third}
                onClick={() => handleUpdateAllRoutes("locked")}
              />
            ) : (
              <UnlockIcon
                color={COLORS.third}
                onClick={() => handleUpdateAllRoutes("locked")}
              />
            )}
            {displayAll ? (
              <VisibleIcon onClick={() => handleUpdateAllRoutes("display")} />
            ) : (
              <VisibleOffIcon onClick={() => handleUpdateAllRoutes("display")} />
            )}
            <div className="driver-name header-title">Route</div>
            <div className="driver-assign header-title"></div>
          </div>
          <div className="chart-side-wrap">
            <div className="chart-side">{createTimesHeader()}</div>
          </div>
        </div>
        <div style={stopsContainer}>
          {routes.map(
            (route, index) =>
              route?.name !== "unscheduled" && (
                <DriverItem
                  key={index}
                  assignStops={groupStops[`assigned_${route.id}`]}
                  route={route}
                  originStart={startAt}
                  originEnd={endAt}
                  stops={groupStops[route?.id]}
                  oneHourPx={oneHourPx}
                  handleHoverMarker={handleHoverMarker}
                  handleClosePopup={handleClosePopup}
                  handleClickMarker={handleClickMarker}
                  handleUpdateRoute={handleUpdateRoute}
                  setDriverDetail={setDriverDetail}
                  setShowDriverDetail={setShowDriverDetail}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};
export default Timeline;
