import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { doGetInternalDriverFeedbacks } from "./thunks";

const InternalDriverFeedbacksAdapter = createEntityAdapter({
  selectId: (feedback) => feedback.id
});

const initialState = InternalDriverFeedbacksAdapter.getInitialState({
  metadata: {},
  feedback: {}
});

export const internalDriverFeedbacksSlice = createSlice({
  name: "internalDriverFeedbacks",
  initialState,
  reducers: {
    updatePaginationFeedbacks(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetInternalDriverFeedbacks.fulfilled, (state, { payload }) => {
      InternalDriverFeedbacksAdapter.setAll(state, payload.data);
      state.driver = {};
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectFeedbackById,
  selectIds: selectFeedbackByIds,
  selectEntities: selectFeedbackEntities,
  selectAll: selectAllFeedbacks,
  selectTotal: selectTotalFeedbacks
} = InternalDriverFeedbacksAdapter.getSelectors((state) => state.internalDriverFeedbacks);

export const selectMetadata = (state) => state.internalDriverFeedbacks.metadata;
export const selectFeedback = (state) => state.internalDriverFeedbacks.feedback;

export const { updatePaginationFeedbacks } = internalDriverFeedbacksSlice.actions;

export default internalDriverFeedbacksSlice.reducer;
