import React from "react";
import SettingHeader from "../components/header/SettingHeader";
import ServiceForm from "../components/form/ServiceForm";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";

const ServiceNew = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/services", text: "SERVICES" },
    { text: "NEW" }
  ];
  return (
    <div className="large-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create Service</h2>
        <ServiceForm />
      </div>
    </div>
  );
};
export default withPermission(ServiceNew, canCreatePermission);
