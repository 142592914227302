import { createAsyncThunk } from "@reduxjs/toolkit";
import clientUserApi from "./api";

export const getListClientUser = createAsyncThunk(
  "client-users/get/loading",
  async (params) => {
    return clientUserApi.getList(params);
  }
);

export const doGetClientUser = createAsyncThunk(
  "client-users/getOne/loading",
  async (id) => {
    return clientUserApi.getOne(id);
  }
);

export const doCreateClientUser = createAsyncThunk(
  "client-users/create/loading",
  async (data) => {
    return clientUserApi.create(data);
  }
);

export const doUpdateClientUser = createAsyncThunk(
  "client-users/update/loading",
  async (data) => {
    return clientUserApi.update(data);
  }
);

export const doDeleteClientUser = createAsyncThunk(
  "client-users/delete/loading",
  async (id) => {
    return clientUserApi.delete(id);
  }
);
