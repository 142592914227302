import "./TableBase.scss";
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { searchFor } from "../../helper/util";

const TableBase = ({
  columns,
  data,
  handleClickRow = () => {},
  searchText = "",
  pagination = false,
  loading = false,
  ...props
}) => {
  const [dataSource, setDataSource] = useState(data);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    setValueSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    if (valueSearch) {
      const filteredData = searchFor(valueSearch, data);
      setDataSource(filteredData);
    }
  }, [data, valueSearch]);

  return (
    <div className="table-component">
      <Table
        onRow={(record) => {
          return {
            onClick: () => handleClickRow(record) // click row
          };
        }}
        rowKey="ID"
        // removing this doesn't break table layouts, and allows minWidth for FlexioPoolTable
        //scroll={{ x: "100%" }}
        columns={columns}
        dataSource={dataSource}
        pagination={{ ...pagination }}
        className="table-striped-rows"
        {...props}
        loading={loading}
      />
    </div>
  );
};
export default TableBase;
