import React from "react";
import { useSelector } from "react-redux";
import withFetchOne from "../../../../hocs/withFetchOne";
import TerritoryForm from "../components/form/TerritoryForm";
import SettingHeader from "../components/header/SettingHeader";
import { doGetTerritory } from "../store/TerritoryThunks";
import { selectTerritory } from "../store/TerritorySlice";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";

const TerritoryEdit = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/territories", text: "TERRITORIES" },
    { text: "EDIT" }
  ];
  const territory = useSelector(selectTerritory);
  return (
    <div className="large-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Edit Territory</h2>
        <TerritoryForm territory={territory} />
      </div>
    </div>
  );
};
export default withPermission(
  withFetchOne(TerritoryEdit, {
    fetchData: doGetTerritory
  }),
  canEditPermission
);
