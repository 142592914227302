import React from "react";
import moment from "moment";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { shortId } from "helper/util";
import TableBase from "components/table/TableBase";
import { DATETIME_FORMAT_FRONTEND } from "configs/constants";

const BroadcastTable = ({ dataSource, pagination }) => {
  const columns = [
    {
      title: "ID",
      width: 120,
      dataIndex: "id",
      // eslint-disable-next-line react/display-name
      render: (value) => <p style={{ margin: 0, color: "#1f3998" }}>{shortId(value)}</p>
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 250
    },
    {
      title: "Recipient",
      dataIndex: "noOfRecipients",
      width: 120
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      width: 120
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 120,
      render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
    }
  ];
  const history = useHistory();

  const handleClickOnRow = (record) => {
    return {
      onClick: () => {
        history.push(`/admin/driver-broadcasts/${record.id}`);
      }
    };
  };

  return (
    <div className="ActiveOrdersTable">
      <TableBase
        columns={columns}
        data={dataSource}
        pagination={pagination}
        onRow={handleClickOnRow}
        rowKey="id"
      />
    </div>
  );
};
export default BroadcastTable;
