import React, {
  memo,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessMessage } from "app/global/slice";
import MyProfile from "../forms/MyProfile";
import profileApi from "modules/client/profile/store/api";
import { Spin } from "antd";
import { setError } from "app/global/slice";
import { ProfileContext } from "layouts/private/ProfileContext";
import { FeatureFlagsContext } from "layouts/private/FeatureFlagsContext";

const MyProfilePage = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const profileCtx = useContext(ProfileContext);
  const fetchProfile = useMemo(() => profileCtx?.fetchProfile, [profileCtx]);
  const featureCtx = useContext(FeatureFlagsContext);
  const updatePoolFeature = useCallback(
    () => featureCtx?.fetchFeatureFlags(),
    [featureCtx]
  );

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    setLoading(true);
    try {
      const profileRes = await profileApi.getProfile();
      if (!profileRes) return;
      setProfile({
        ...profileRes,
        ...(profileRes.client || {}),
        phone: profileRes.phone || "",
        supportPhone: profileRes.client.supportPhone || ""
      });
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleOnFinish = async (data) => {
    try {
      setSubmitting(true);
      const profileRes = await profileApi.updateProfile(data);
      setProfile({ ...profileRes, ...(profileRes.client || {}) });
      dispatch(setSuccessMessage("Updated successfully!"));
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setSubmitting(false);
      fetchProfile();
      updatePoolFeature();
    }
  };

  return (
    <div className="return-process-setting">
      <Spin spinning={loading}>
        <MyProfile isFetching={submitting} profile={profile} onSubmit={handleOnFinish} />
      </Spin>
    </div>
  );
};

export default MyProfilePage;
