import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  TimePicker,
  Upload
} from "antd";
import { CheckCircleOutlined, DeleteFilled, MinusOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

import AddressInput from "components/inputs/AddressInput";
import request from "helper/request";
import DefaultMapbox from "modules/client/active-orders/components/mapbox/DefaultMapbox";
import { selectLocation } from "../store/LocationSlice";
import { getLocation, updateLocation } from "../store/LocationThunks";
import "./EditStop.scss";
import { TIME_FORMAT_BACKEND } from "configs/constants";
import { setSuccessMessage, setError } from "app/global/slice";
import AdminClientHeader from "modules/admin/clients/components/header/AdminClientHeader";
import { setGlobalLoading } from "app/global/slice";

import { isInternalDriverService } from "modules/client/flexio-services/helper";
import { doGetRoute } from "modules/client/routes/store/thunks";
import { selectRoute } from "modules/client/routes/store/slice";
import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";
import PackageList from "modules/client/active-orders/components/PackageList";
import PackForm from "modules/client/active-orders/components/form/PackForm";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import routeApi from "modules/client/routes/store/api";

const { Dragger } = Upload;

const SUFFIX_NEW_PACK_ID = "-pack";

const EditStopPage = ({ isAdmin = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentAddress, setCurrentAddress] = useState();
  const [imageAddressInfos, setImageAddressInfos] = useState([]);
  const [isInternalDriver, setIsInternalDriver] = useState(true);
  const isLoading = useSelector((state) => state.global.isLoading);
  const location = useSelector(selectLocation);
  const route = useSelector(selectRoute);
  const params = useParams();
  const { id: locationId, routeId = "" } = params;
  const activeJobsUrl = `${isAdmin ? "/admin" : ""}/routes`;
  const activeJobDetailUrl = `${activeJobsUrl}/${routeId}`;
  const breadCrumbs = [
    { text: "ACTIVE ROUTES", url: activeJobsUrl },
    { text: routeId.substr(0, 8).toUpperCase(), url: activeJobDetailUrl },
    { text: "STOPS" },
    { text: locationId.substr(0, 8).toUpperCase() }
  ];
  const [form] = Form.useForm();

  const packageTypeList = useSelector(selectAllPackageTypes);
  const [originalPackages, setOriginalPackages] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedPack, setSelectedPack] = useState(null);
  const [visibleFormModal, setVisibleFormPackModal] = useState(false);
  const [formPack] = Form.useForm();

  useEffect(() => {
    if (route) {
      setIsInternalDriver(isInternalDriverService(route.service));
    }
  }, [route]);

  useEffect(() => {
    if (!routeId) return;
    if (routeId) {
      dispatch(doGetRoute(routeId));
    }
  }, [dispatch, routeId]);

  useEffect(() => {
    if (!locationId) return;
    dispatch(getLocation(locationId));
    dispatch(getPackageTypeList({ pageSize: 1000 }));
  }, [dispatch, locationId]);

  useEffect(() => {
    if (location.attachments) {
      const attachments = location.attachments.map((file) => {
        return { ...file, uid: file.id, status: "done" };
      });
      const jobPackages = location.job?.packages;
      setPackages(jobPackages);
      setOriginalPackages(jobPackages);
      setImageAddressInfos(attachments);
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(location)?.length) {
      const { start, end } = location;
      form.setFieldsValue({
        ...location,
        start: start ? moment(start, TIME_FORMAT_BACKEND) : null,
        end: end ? moment(end, TIME_FORMAT_BACKEND) : null
      });
      setCurrentAddress({
        address: location.address,
        lngLat: [location.lng, location.lat]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [activeKey, setActiveKey] = useState(["1"]);

  const handleSetAddress = (value) => {
    setCurrentAddress({ address: value.address, lngLat: value.lngLat });
  };

  const onChangeFiles = async (values) => {
    return;
    // if (values.file.status === "done") {
    //   console.log("values", values);
    //   setImageAddressInfos(
    //     values.fileList.map((file) => {
    //       return { ...file.response, uid: file.uid };
    //     })
    //   );
    // }
  };

  const onRemoveFile = (file) => {
    const tmpFiles = [...imageAddressInfos];
    const idx = imageAddressInfos.findIndex((image) => image.uid === file.uid);

    if (idx !== -1) {
      tmpFiles.splice(idx, 1);
      setImageAddressInfos(tmpFiles);
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".png, .jpeg, .jpg, .pdf",
    multiple: true,
    listType: "picture-card",
    className: "upload-list-inline",
    customRequest: async ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(setGlobalLoading({ isLoading: true }));
      try {
        const response = await request("/v1/files", {
          method: "POST",
          data: formData,
          headers: {
            "content-type": "multipart/form-data"
          }
        });
        setImageAddressInfos([...imageAddressInfos, { ...response, uid: response.id }]);
        onSuccess(response);
        dispatch(setGlobalLoading({ isLoading: false }));
      } catch (e) {
        console.log(e);
        onError();
      }
    },
    onChange: onChangeFiles,
    onRemove: onRemoveFile,
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ReactSVG src="/icons/delete-upload.svg" />
    },
    defaultFileList: [...imageAddressInfos],
    fileList: [...imageAddressInfos]
  };

  const processPackages = () => {
    const firstOriginalPack = originalPackages[0];

    const updatedPackages = packages?.map((pack) => {
      if (!pack.id?.includes(SUFFIX_NEW_PACK_ID)) return pack;

      delete pack.id;
      return {
        ...pack,
        jobId: firstOriginalPack.jobId,
        locationId: firstOriginalPack.locationId,
        orderId: firstOriginalPack.orderId,
        routeId: firstOriginalPack.routeId
      };
    });

    const updatedPackageIds = packages
      .filter((pack) => pack.id)
      ?.map((filteredPack) => filteredPack.id);
    const deletedPackages = originalPackages
      ?.filter((pack) => !updatedPackageIds.includes(pack.id))
      ?.map((filteredPack) => ({ ...filteredPack, isDeleted: true }));

    return [...updatedPackages, ...deletedPackages];
  };

  const handleOnFinish = async (values) => {
    const { address, lngLat } = currentAddress;
    const { start, end } = values;
    const { error } = await dispatch(
      updateLocation({
        id: locationId,
        ...values,
        address,
        lng: lngLat[0],
        lat: lngLat[1],
        start: start
          ? typeof start === "object"
            ? start.format(TIME_FORMAT_BACKEND)
            : moment(start).format(TIME_FORMAT_BACKEND)
          : undefined,
        end: end
          ? typeof end === "object"
            ? end.format(TIME_FORMAT_BACKEND)
            : moment(end).format(TIME_FORMAT_BACKEND)
          : undefined,
        files: imageAddressInfos?.map((image) => image.id),
        packages: processPackages()
      })
    );
    if (!error) {
      dispatch(setSuccessMessage("Updated successfully!"));
      history.push(`${activeJobDetailUrl}/dispatch?from=edit-stop`);
    } else {
      dispatch(setError("Updated failure!"));
    }
  };

  const handleDeleleteStop = async () => {
    const { jobId } = location;
    if (!jobId) return;

    try {
      await routeApi.softDeleteJob(jobId);
      dispatch(setSuccessMessage("Deleted stop successfully!"));
      history.push(`${activeJobDetailUrl}/dispatch?from=delete-stop`);
    } catch (err) {
      dispatch(setSuccessMessage("Deleted stop failure!"));
      console.log(err);
    }
  };

  const headerCollapse = (key) => {
    return (
      <div className="headerCollapse">
        <div className="text">Additional Details</div>
        {activeKey.includes(key) ? (
          <ReactSVG src="/icons/collapse.svg" className="icon" />
        ) : (
          <ReactSVG src="/icons/dropoff.svg" className="icon" />
        )}
      </div>
    );
  };
  console.log("imageAddressInfos", imageAddressInfos);

  const handleCancelFormPackModal = () => {
    formPack.resetFields();
    setSelectedPack(null);
    setVisibleFormPackModal(false);
  };

  const handleSavePack = (values) => {
    const newPackages = packages?.length ? [...packages] : [];
    if (!values.id) {
      newPackages.push({ ...values, id: `${new Date().getTime()}${SUFFIX_NEW_PACK_ID}` });
    } else {
      const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
      const newPack = {
        ...newPackages[indexpack],
        ...values
      };
      newPackages.splice(indexpack, 1, newPack);
    }
    setPackages(newPackages);
    handleCancelFormPackModal();
  };

  const openPackCreateUpdate = (pack) => {
    setSelectedPack(pack);
    setVisibleFormPackModal(true);
  };

  const handleRemovePack = (values) => {
    const newPackages = [...packages];
    const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
    newPackages.splice(indexpack, 1);
    setPackages(newPackages);
  };

  return (
    <div className="EditStopPage">
      <AdminClientHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleOnFinish}
        form={form}
      >
        <div className="wrapper">
          <h2 className="title">Edit Stop {locationId?.substr(0, 8).toUpperCase()}</h2>
          <h2 className="stop-type-name">{location?.type} LOCATION</h2>
          <div className="contentForm">
            <div className="AddressForm">
              <div className="address">
                <div className="form">
                  <Form.Item
                    label="Map Address"
                    name="address"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <AddressInput
                      placeholder="Address"
                      onSelect={(value) => handleSetAddress(value)}
                      address={currentAddress}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                      >
                        <div className="left">Location Name</div>
                        <div
                          className="right"
                          style={{ fontWeight: 400, color: "#888888" }}
                        >
                          Optional
                        </div>
                      </div>
                    }
                    name="locationName"
                  >
                    <Input placeholder="Location Name" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                      >
                        <div className="left">Physical Address</div>
                        <div
                          className="right"
                          style={{ fontWeight: 400, color: "#888888" }}
                        >
                          Optional
                        </div>
                      </div>
                    }
                    name="physicalAddress"
                  >
                    <Input placeholder="Physical Address" />
                  </Form.Item>
                </div>
                <div className="map">
                  <DefaultMapbox address={currentAddress} setAddress={handleSetAddress} />
                </div>
              </div>
              <div className="info">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      label="Contact Name"
                      name="contactName"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Contact Name" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                          }}
                        >
                          <div className="left">E-mail</div>
                          <div
                            className="right"
                            style={{ fontWeight: 400, color: "#888888" }}
                          >
                            Optional
                          </div>
                        </div>
                      }
                      name="email"
                      rules={[{ type: "email", message: "Invalid Email" }]}
                    >
                      <Input placeholder="E-mail" />
                    </Form.Item>
                    <div className="custom-label">
                      <label>Phone Numbers</label>
                    </div>
                    <Form.List name="phoneList" label="Phone Numbers">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKeyValue, ...restField }, index) => {
                              return (
                                <div key={name} className={`phone_numbers x${key}`}>
                                  <Row>
                                    <Col span={8}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "type"]}
                                        fieldKey={[fieldKeyValue, "type"]}
                                        rules={[{ required: true, message: "Required" }]}
                                      >
                                        <Select>
                                          <Select.Option value="contact">
                                            Contact
                                          </Select.Option>
                                          <Select.Option value="salesperson">
                                            Salesperson
                                          </Select.Option>
                                          <Select.Option value="mainSupport">
                                            Main Support
                                          </Select.Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                      <Row>
                                        <Col span={index > 0 ? 19 : 24}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "phone"]}
                                            fieldKey={[fieldKeyValue, "phone"]}
                                            rules={[
                                              { required: true, message: "Required" }
                                            ]}
                                          >
                                            <PhoneInput country="ca" enableSearch />
                                          </Form.Item>
                                        </Col>
                                        {index > 0 && (
                                          <Col span={5} className="icon">
                                            <ReactSVG
                                              src="/icons/minus.svg"
                                              onClick={() => remove(name)}
                                            />
                                          </Col>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                          <Form.Item className="bottom-btn">
                            <Button type="link" onClick={() => add({ type: "contact" })}>
                              Add More Phone Number
                              <ReactSVG src="/icons/plus.svg" className="react-icon" />
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                  <Col span={8}>
                    {(isAdmin || isInternalDriver) && (
                      <>
                        <div className="custom-label">
                          <label>
                            {location?.type === "dropoff"
                              ? "Drop-Off Time Window"
                              : "Pickup Time Window"}
                          </label>
                        </div>
                        <Row className="from_to">
                          <Col span={11}>
                            <Form.Item
                              name="start"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <TimePicker
                                format={TIME_FORMAT_BACKEND}
                                value={form.getFieldValue("start")}
                                onSelect={(value) =>
                                  form.setFieldsValue({ start: value })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "center" }}>
                            <MinusOutlined />
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              name="end"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <TimePicker
                                format={TIME_FORMAT_BACKEND}
                                value={form.getFieldValue("end")}
                                onSelect={(value) => form.setFieldsValue({ end: value })}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          label="Est. Waiting/Service Time"
                          name="duration"
                          rules={[{ required: true, message: "Required" }]}
                          tooltip={{
                            title:
                              "Please assign an estimated duration in minutes of time taken from when the driver arrives at the location, is attended, finishes the delivery and resumes driving. Here it is better to go with a worst case scenario to ensure the routes can be finished."
                          }}
                        >
                          <Input
                            type="number"
                            placeholder="Est. Waiting/Service Time"
                            suffix="Minutes"
                          />
                        </Form.Item>
                      </>
                    )}
                    <Form.Item label="Note" name="note">
                      <Input.TextArea placeholder="Note" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <label>Image/s and Documents</label>
                    <Dragger {...uploadProps}>
                      <p className="ant-upload-hint">
                        Click or drag file to to upload or
                      </p>
                      <p className="ant-upload-drag-icon">
                        <img
                          src="/icons/browse_upload.png"
                          style={{ width: "83px" }}
                          alt=""
                        />
                      </p>
                    </Dragger>
                  </Col>
                </Row>
              </div>
              <div className="additional-details">
                <Collapse
                  defaultActiveKey={activeKey}
                  activeKey={activeKey}
                  onChange={setActiveKey}
                  ghost
                  collapsible="header"
                >
                  <Collapse.Panel
                    showArrow={false}
                    header={headerCollapse("1")}
                    ghost
                    key="1"
                  >
                    <Row>
                      <Col span="5">
                        <Form.Item label="Internal Route ID" name="internalRouteId">
                          <Input placeholder="Internal Route ID" />
                        </Form.Item>
                      </Col>
                      <Col span="5">
                        <Form.Item label="Internal Order IDs" name="internalOrderId">
                          <Input placeholder="Internal Order IDs" />
                        </Form.Item>
                      </Col>
                      <Col span="5">
                        <Form.Item label="Internal Customer ID" name="internalCustomerId">
                          <Input placeholder="Internal Customer ID" />
                        </Form.Item>
                      </Col>
                      <Col span="5">
                        <Form.Item label="Salesperson" name="salesPerson">
                          <Input placeholder="Salesperson" />
                        </Form.Item>
                      </Col>
                      <Col span="4">
                        <Form.Item label="COD" name="cod">
                          <Input type="number" placeholder="COD" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </div>
              <div className="package-list-section">
                <PackageList
                  packages={packages}
                  editPack={(pack) => openPackCreateUpdate(pack)}
                  addPack={() => openPackCreateUpdate()}
                  removePack={handleRemovePack}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div>
            <Popconfirm
              title="Are you sure to delete this stop?"
              onConfirm={handleDeleleteStop}
              okText="Yes"
              cancelText="No"
            >
              <Button className="delete-btn">
                Delete <DeleteFilled />
              </Button>
            </Popconfirm>
            <Button onClick={() => history.goBack()}>Cancel</Button>
            <Button
              type="primary"
              className="create-order"
              htmlType="submit"
              loading={isLoading}
            >
              Update
              <CheckCircleOutlined />
            </Button>
          </div>
        </div>
      </Form>
      {visibleFormModal && (
        <Modal
          title={!selectedPack ? <div>Add Package</div> : <div>Edit Package</div>}
          visible={true}
          onCancel={handleCancelFormPackModal}
          footer={
            <div className="buttons-modal">
              <Form.Item>
                <Button size="large" onClick={handleCancelFormPackModal}>
                  Cancel
                </Button>
                <Button size="large" type="primary" onClick={() => formPack.submit()}>
                  Confirm
                  <CheckCircleOutlined />
                </Button>
              </Form.Item>
            </div>
          }
          width={792}
        >
          <PackForm
            pack={selectedPack}
            formPack={formPack}
            onOk={handleSavePack}
            packageTypeList={packageTypeList}
          />
        </Modal>
      )}
    </div>
  );
};
export default withPermission(EditStopPage, canEditPermission);
