import React from "react";
import { Radio } from "antd";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";

const GroupRadioHook = ({
  disabled = false,
  className,
  classNameRadio,
  type,
  placeholder = "",
  control,
  errors,
  name,
  label,
  required = false,
  showLabel,
  showOptionsName,
  options,
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        placeholder={placeholder || name}
        control={control}
        name={name}
        render={({ field }) => (
          <div>
            <Radio.Group
              {...rest}
              {...field}
              // checked={field.value}
              type={type}
              disabled={disabled}
              className={classNameRadio}
            >
              {options.map((otp) => (
                <Radio key={otp.value} value={otp.value}>
                  {otp.name}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
      />
    </FormItemHook>
  );
};
export { GroupRadioHook };
