import request from "../../../../helper/request";

const driverFeedbackApi = {
  sendFeedback: (payload) => {
    return request.post("/client/driver-feedbacks", payload);
  },
  getInternalDriverFeedbacks: (params) => {
    return request.get("/client/driver-feedbacks", params);
  }
};

export default driverFeedbackApi;
