import { useEffect, useState } from "react";
import moment from "moment";
import * as firebaseDB from "firebase/database";
import { realtimeDB } from "../../configs/firebaseConfig";
import _isEmpty from "lodash/isEmpty";

export function useDriverLocation(driverId) {
  const [currentDriver, setCurrentDriver] = useState(null);

  useEffect(() => {
    if (!driverId) return;
    const url = `/location/${moment().format("YYYY-MM-DD")}/${driverId}`;
    const driverLocationRef = firebaseDB.ref(realtimeDB, url);

    firebaseDB.onValue(driverLocationRef, (snapshot) => {
      const driverLocation = snapshot.val();
      if (_isEmpty(driverLocation)) return;
      setCurrentDriver({
        lat: driverLocation.latitud,
        lng: driverLocation.longitud
      });
    });
  }, [driverId]);
  return currentDriver;
}
export function useDriverLocationByRoute(routeId) {
  const [currentDriver, setCurrentDriver] = useState(null);

  useEffect(() => {
    if (!routeId) return;
    const url = `/driver-location/${routeId}`;
    const driverLocationRef = firebaseDB.ref(realtimeDB, url);

    firebaseDB.onValue(driverLocationRef, (snapshot) => {
      const driverLocation = snapshot.val();
      if (
        !driverLocation ||
        typeof driverLocation.latitud !== "number" ||
        typeof driverLocation.longitud !== "number"
      )
        return;
      setCurrentDriver({
        lat: driverLocation.latitud,
        lng: driverLocation.longitud
      });
    });
  }, [routeId]);
  return currentDriver;
}
