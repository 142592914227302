import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getLocation } from "./LocationThunks";

const LocationAdapter = createEntityAdapter({
  selectId: (location) => location.id
});

const initialState = LocationAdapter.getInitialState({
  location: {}
});

export const locationSlice = createSlice({
  name: "adminLocations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocation.fulfilled, (state, { payload }) => {
      state.location = payload;
    });
  }
});

export const {
  selectById: selectLocationById,
  selectIds: selectLocationIds,
  selectEntities: selectLocationEntities,
  selectAll: selectAllLocations,
  selectTotal: selectTotalLocations
} = LocationAdapter.getSelectors((state) => state.adminLocations);

export const selectLocation = (state) => state.adminLocations.location;

export default locationSlice.reducer;
