import { DATE_FORMAT_FRONTEND } from "configs/constants";
import moment from "moment";

const chartDataMock = {
  jobsByStatus: {
    unassigned: 30,
    inprogress: 30,
    completed: 30,
    delayed: 15
  },
  stopsByStatus: {
    unassigned: 30,
    inprogress: 30,
    completed: 30,
    failed: 15
  },
  jobsByVehicleType: {
    walking: 30,
    bicycle: 25,
    motorcycle: 30,
    car: 40,
    suv: 20,
    vansm: 30,
    vanxl: 30
    // trucktype1: 10
  },
  packagesBySize: {
    micro: 30,
    small: 25,
    medium: 30,
    large: 10,
    pallet: 20,
    custom: 30
  },
  jobsByService: {
    flexioShift: 30,
    flexioNow: 30,
    internalDriver: 15
  }
};
const JOB_BY_STATUS_CHART_COLOR = {
  pending: "#888888",
  accepted: "#0078D4",
  "in-progress": "#FF8400",
  completed: "#00C853",
  canceled: "#000000",
  failed: "#FF3D17"
};
const JOB_BY_STATUS_LABEL = {
  pending: "Pending",
  accepted: "Accepted",
  "in-progress": "In Progress",
  completed: "Completed",
  canceled: "Canceled",
  failed: "Failed"
};
const STOP_BY_STATUS_CHART_COLOR = {
  unassigned: "#888888",
  assigned: "#0078D4",
  arrived: "#43C1C2",
  completed: "#00C853",
  going_next: "#FFB667",
  failed: "#FF3D17"
};
const STOP_BY_STATUS_LABEL = {
  unassigned: "Unassigned",
  assigned: "Assigned",
  arrived: "Arrived",
  completed: "Completed",
  going_next: "Going Next",
  failed: "Failed"
};
const JOB_BY_VEHICLE_TYPE_CHART_COLOR = {
  van_md: "#FFB667",
  van_sm: "#FF8400",
  van_xl: "#00F265",
  // walking: "#FF6F53",
  // bicycle: "#FF3D17",
  // motorcycle: "#3355D1",
  cars: "#1F3998",
  suv: "#FFB667"
  // truck_type_1: "#00C853"
};
const JOB_BY_VEHICLE_TYPE_LABEL = {
  van_md: "Van MD",
  van_sm: "Van SM",
  van_xl: "Van XL",
  // walking: "Walking",
  // bicycle: "Bycicle",
  // motorcycle: "Motorcycle",
  cars: "Cars",
  suv: "SUV"
  // truck_type_1: "Struck Type 1"
};
const PACKAGE_BY_SIZE_CHART_COLOR = {
  micro: "#FF6F53",
  small: "#3355D1",
  medium: "#FFB667",
  large: "#651FFF",
  pallet: "#00F265",
  custom: "#00D1FF"
};
const PACKAGE_BY_SIZE_LABEL = {
  micro: "Micro",
  small: "Small",
  medium: "Medium",
  large: "Large",
  pallet: "Pallet",
  custom: "Custom"
};
const JOB_BY_SERVICE_CHART_COLOR = {
  flexio_shift: "#1F3998",
  flexio_now: "#43C1C2",
  internal_driver: "#FF8400"
};
const JOB_BY_SERVICE_LABEL = {
  flexio_shift: "Flexio Shift",
  flexio_now: "Flexio Now",
  internal_driver: "Internal Driver"
};

const prepareChartData = (chartData) => {
  const {
    jobsByStatus,
    stopsByStatus,
    jobsByVehicleType,
    packagesBySize,
    jobsByService
  } = chartData;
  const chartJobStatuses = Object.keys(JOB_BY_STATUS_LABEL).filter(
    (key) => jobsByStatus[key]
  );
  const chartStopStatuses = Object.keys(STOP_BY_STATUS_LABEL).filter(
    (key) => stopsByStatus[key]
  );
  const chartJobVehicleTypes = Object.keys(JOB_BY_VEHICLE_TYPE_LABEL).filter(
    (key) => jobsByVehicleType[key]
  );
  const chartPackageSizes = Object.keys(PACKAGE_BY_SIZE_LABEL).filter(
    (key) => packagesBySize[key]
  );
  const chartJobServices = Object.keys(JOB_BY_SERVICE_LABEL).filter(
    (key) => jobsByService[key]
  );

  return {
    jobsByStatusChartData: {
      labels: chartJobStatuses.map((key) => JOB_BY_STATUS_LABEL[key]),
      datasets: [
        {
          data: chartJobStatuses.map((key) => jobsByStatus[key]),
          backgroundColor: chartJobStatuses.map((key) => JOB_BY_STATUS_CHART_COLOR[key])
        }
      ]
    },
    stopsByStatusChartData: {
      labels: chartStopStatuses.map((key) => STOP_BY_STATUS_LABEL[key]),
      datasets: [
        {
          data: chartStopStatuses.map((key) => stopsByStatus[key]),
          backgroundColor: chartStopStatuses.map((key) => STOP_BY_STATUS_CHART_COLOR[key])
        }
      ]
    },
    jobsByVehicleChartData: {
      labels: chartJobVehicleTypes.map((key) => JOB_BY_VEHICLE_TYPE_LABEL[key]),
      datasets: [
        {
          data: chartJobVehicleTypes.map((key) => jobsByVehicleType[key]),
          backgroundColor: chartJobVehicleTypes.map(
            (key) => JOB_BY_VEHICLE_TYPE_CHART_COLOR[key]
          )
        }
      ]
    },
    packagesBySizeChartData: {
      labels: chartPackageSizes.map((key) => PACKAGE_BY_SIZE_LABEL[key]),
      datasets: [
        {
          data: chartPackageSizes.map((key) => packagesBySize[key]),
          backgroundColor: chartPackageSizes.map(
            (key) => PACKAGE_BY_SIZE_CHART_COLOR[key]
          )
        }
      ]
    },
    jobsByServiceChartData: {
      labels: chartJobServices.map((key) => JOB_BY_SERVICE_LABEL[key]),
      datasets: [
        {
          data: chartJobServices.map((key) => jobsByService[key]),
          backgroundColor: chartJobServices.map((key) => JOB_BY_SERVICE_CHART_COLOR[key])
        }
      ]
    }
  };
};

export function calculateChartTotal(dataObj = {}) {
  return Object.keys(dataObj).reduce((total, nextKey) => total + dataObj[nextKey], 0);
}

export function buildFirstLevelFilterStatistic(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "startDate",
    "endDate",
    "isFlexioDriver",
    "isInternalDriver"
  ];

  const FILTER_VALUES = {
    startDate: "$eq",
    endDate: "$eq",
    isFlexioDriver: "$eq",
    isInternalDriver: "$eq"
  };
  return Object.keys(filter)
    .filter((field) => FIRST_LEVEL_FILTER.includes(field) && filter[field])
    .map((filteredField) => {
      let value = filter[filteredField];
      if (filteredField === "startDate" || filteredField === "endDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      return `${filteredField}||${FILTER_VALUES[filteredField]}||${value}`;
    });
}

export default prepareChartData;
