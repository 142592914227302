import React from "react";
const BroadcastIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 0.666992H2.33333C1.41667 0.666992 0.674999 1.41699 0.674999 2.33366L0.666666 17.3337L4 14.0003H15.6667C16.5833 14.0003 17.3333 13.2503 17.3333 12.3337V2.33366C17.3333 1.41699 16.5833 0.666992 15.6667 0.666992ZM5.66667 10.667H4V9.00033H5.66667V10.667ZM5.66667 8.16699H4V6.50033H5.66667V8.16699ZM5.66667 5.66699H4V4.00033H5.66667V5.66699ZM11.5 10.667H7.33333V9.00033H11.5V10.667ZM14 8.16699H7.33333V6.50033H14V8.16699ZM14 5.66699H7.33333V4.00033H14V5.66699Z"
        fill="white"
      />
    </svg>
  );
};
export { BroadcastIcon };
