import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RightOutlined,
  DeleteTwoTone
} from "@ant-design/icons";
import _find from "lodash/find";
import _keys from "lodash/keys";
import _isEmpty from "lodash/isEmpty";
import _trim from "lodash/trim";
import _uniq from "lodash/uniq";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Image,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  Checkbox,
  Input
} from "antd";
import StatusInList from "components/items/StatusInList";
import { DATETIME_FORMAT_FRONTEND } from "configs/constants";
import { JOB_STATUS_COLOR, ROUTE_STATUSES } from "helper/status";
import { fullName, replaceElementInArray, showDate } from "helper/util";
import { separateStatusUnderlineLetter } from "modules/shared/PickupDropoff/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import withFetchOneMore from "../../../../hocs/withFetchOneMore";
import OrdersHeader from "../components/header/RoutesHeader";
import JobStep from "../components/JobStep";
import { selectRoute, selectRouteDispatch } from "../store/slice";
import { doGetRoute, doGetRoutesDispatch } from "../store/thunks";
import routeApi from "../store/api";
import adminApi from "../../../admin/orders/store/api";
import "./RouteDetailPage.scss";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";
import { orderBy, find } from "lodash";
import { PrintPDFIcon } from "components/svgs";
import generatePdfPackingSlip from "helper/generatePdfPackingSlip";
import { shortId } from "helper/util";
import { formatCurrency } from "helper/util";
import { isInternalDriverService } from "modules/client/flexio-services/helper";
import { setError } from "app/global/slice";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { canEditPermission } from "configs/constants";
import { ImportIcon } from "components/svgs/ImportIcon";
import { convertEmergencyStatus } from "helper/util";
import { LOCATION_TYPES } from "configs/constants";
import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import { ONE_HOUR_PX } from "modules/shared/PickupDropoff/constants";
import { useDriverLocationByRoute } from "modules/shared/driver-location";

const RouteDetail = ({ isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const route = useSelector(selectRoute);
  const mapRoute = useSelector(selectRouteDispatch);
  const [showChangeJobStatus, setShowChangeJobStatus] = useState(false);
  const [newJobStatus, setNewJobStatus] = useState();
  const [failedReason, setFailedReason] = useState("");
  const [currentJob, setcurrentJob] = useState({});
  const [submiting, setSubmiting] = useState(false);
  const [packsByType, setPackByType] = useState([]);
  const [routePackageTypeMap, setRoutePackageTypeMap] = useState([]);
  const [isInternalDriver, setIsInternalDriver] = useState(false);
  const [totalRoutePackages, setTotalRoutePackages] = useState(0);
  const [batchStatusUpdate, setBatchStatusUpdate] = useState(false);
  const [printing, setPrinting] = useState(false);
  const idF = route?.id ? route.id.substr(0, 8).toUpperCase() : "";
  const routesUri = isAdmin ? "/admin/routes" : "/routes";
  const breadCrumbs = [{ text: "ACTIVE ROUTES", url: routesUri }, { text: idF }];
  const [jobStatusArray, setJobStatusArray] = useState([]);
  const [selectAll, setSelectAll] = useState();
  const [showChangeRouteStatus, setShowChangeRouteStatus] = useState(false);
  const [showPickPackages, setShowPickPackages] = useState(false);
  const [newRouteStatus, setNewRouteStatus] = useState();
  const { pending, ...otherStatuses } = ROUTE_STATUSES;
  const { unassigned, ...otherStopStatuses } = JOB_STATUS_COLOR;
  const [oneHourPx, setOneHourPx] = useState(ONE_HOUR_PX);
  const currentDriver = useDriverLocationByRoute(mapRoute?.id || "");
  const goToDetailJob = (id) => {
    history.push(`${routesUri}/${route?.id}/${id}`);
  };

  const canEdit = allowPermission(useSelector(selectRole), canEditPermission);

  useEffect(() => {
    if (route) {
      setIsInternalDriver(isInternalDriverService(route.service));
    }
  }, [route]);

  useEffect(() => {
    const rs = {};
    const packageTypeMap = {};
    if (route) {
      const { packages = [], routeLocations = [] } = route;
      let originalPackages = [...packages];
      const returnRouteLocation = find(routeLocations, { isReturning: true });
      const returnPickupJobIds = routeLocations
        .filter((item) => item.isReturnPickup)
        .map((filteredItem) => filteredItem.jobId);
      originalPackages = originalPackages.filter(
        (pack) =>
          ![...returnPickupJobIds, returnRouteLocation?.jobId].includes(pack.jobId)
      );

      originalPackages?.forEach((pack) => {
        if (!packageTypeMap[pack.packageTypeId]) {
          packageTypeMap[pack.packageTypeId] = pack.packageType?.name;
        }
        if (!rs[pack.packageTypeId]) {
          rs[pack.packageTypeId] = [];
        }
        rs[pack.packageTypeId].push(pack);
      });
      routeLocations?.forEach((routeLocation) => {
        if (routeLocation?.routeLocationPackages?.length > 0) {
          const locationType = routeLocation?.location?.type;
          const note = routeLocation.groupStatusDate?.completedAt?.note;

          routeLocation?.routeLocationPackages.forEach((locationPackage) => {
            const tmpPackages = rs[locationPackage.packageTypeId];
            const findPack = tmpPackages?.find((p) => p.id === locationPackage.packageId);
            if (findPack) {
              const newPackages = replaceElementInArray(tmpPackages, {
                ...findPack,
                validationMethod: locationPackage?.validationMethod
              });
              const fullfillNewPackages = newPackages.map((newPack) => ({
                ...newPack,
                [`${locationType}Note`]: _uniq(
                  [findPack[`${locationType}Note`], note].filter(
                    (text) => !_isEmpty(_trim(text || ""))
                  )
                )?.join(", "),
                [`${locationType}Quantity`]:
                  (newPack[`${locationType}Quantity`] || 0) +
                  (newPack.id === locationPackage.packageId
                    ? locationPackage?.quantity
                    : 0)
              }));
              rs[locationPackage.packageTypeId] = fullfillNewPackages;
            }
          });
        }
      });
      setTotalRoutePackages(originalPackages?.length);
    }

    setPackByType(rs);
    setRoutePackageTypeMap(packageTypeMap);
  }, [route, route?.packages, route?.routeLocations]);

  const handleRedirectToEditStop = (event, jobId) => {
    event.stopPropagation();
    const stopId = _find(_find(route?.jobs, { id: jobId })?.locations || [], {
      type: LOCATION_TYPES.DROPOFF
    })?.id;
    history.push(`${isAdmin ? "/admin" : ""}/routes/${route?.id}/stops/${stopId}/edit`);
  };

  const handleChangeStatus = (event, status) => {
    if ((!isAdmin && !isInternalDriver) || !canEdit) return;
    event.stopPropagation();
    setcurrentJob(status);
    setShowChangeJobStatus(true);
  };

  const handleUpdateJobStatus = async () => {
    try {
      setSubmiting(true);
      if (newJobStatus === "completed") {
        const data = {
          quantity: 0,
          type: "",
          packageId: ""
        };
        await routeApi.deliverPackages(currentJob.id, data);
      }
      await routeApi.updateJobStatus(currentJob?.id, {
        status: newJobStatus,
        routeId: route?.id,
        failedReason
      });
      setShowChangeJobStatus(false);
      setNewJobStatus(null);
      dispatch(setSuccessMessage("Updated status successfully!"));
      dispatch(doGetRoute(route?.id));
      dispatch(doGetRoutesDispatch(mapRoute?.id));
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setSubmiting(false);
    }
  };

  const getPdfForJob = async (jobId) => {
    try {
      setSubmiting(true);
      const res = await routeApi.getJobPDF(jobId);
      if (res.POD) {
        console.log("res", res);
        const linkSource = `data:application/pdf;base64,${res.POD}`;
        const downloadLink = document.createElement("a");
        const fileName = jobId + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch(setSuccessMessage("Download successfully!"));
      } else {
        dispatch(
          setError(
            "We regret to inform you that the PDF generation is currently unavailable. Please try again later."
          )
        );
      }
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setSubmiting(false);
    }
  };

  const handleSubmitCancelRoute = async (routeId) => {
    try {
      isAdmin ? await adminApi.cancelRoute(routeId) : await routeApi.cancelRoute(routeId);
      dispatch(setSuccessMessage("Canceled job successfully!"));
      dispatch(doGetRoute(route?.id));
      dispatch(doGetRoutesDispatch(mapRoute?.id));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleleteStop = async (event, jobId) => {
    event.stopPropagation();
    if (!jobId) return;
    try {
      await routeApi.softDeleteJob(jobId);
      dispatch(setSuccessMessage("Deleted stop successfully!"));
      dispatch(doGetRoute(route?.id));
      dispatch(doGetRoutesDispatch(mapRoute?.id));
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrintPackingSlip = async () => {
    const { packages, jobs, routeLocations } = route;
    if (!packages?.length || !jobs?.length || !routeLocations?.length) return;
    const returnRouteLocation = find(routeLocations, { isReturning: true });
    const returnPickupJobIds = routeLocations
      .filter((item) => item.isReturnPickup)
      .map((filteredItem) => filteredItem.jobId);
    const pdfJobs = jobs.filter(
      (job) => ![...returnPickupJobIds, returnRouteLocation?.jobId].includes(job?.id)
    );
    try {
      setPrinting(true);
      const doc = await generatePdfPackingSlip(packages, pdfJobs, routeLocations);
      const filename = `Order-${shortId(route.id)}-packing-slip.pdf`;
      doc?.save(filename);
    } finally {
      setPrinting(false);
    }
  };
  const handleRouteStatusArray = (job) => {
    const tempArr = jobStatusArray;
    if (tempArr.includes(job)) {
      const index = tempArr.indexOf(job);
      tempArr.splice(index, 1);
      setJobStatusArray(tempArr);
    } else {
      jobStatusArray.push(job);
    }
  };

  const handleBatchUpdate = async () => {
    for (const job of jobStatusArray) {
      try {
        setSubmiting(true);
        if (newJobStatus === "completed") {
          const data = {
            quantity: 0,
            type: "",
            packageId: ""
          };
          await routeApi.deliverPackages(job, data);
        }
        await routeApi.updateJobStatus(job, {
          status: newJobStatus,
          routeId: route?.id,
          failedReason
        });
        setNewJobStatus(null);
        dispatch(doGetRoute(route?.id));
        dispatch(doGetRoutesDispatch(mapRoute?.id));
      } catch (err) {
        const error = JSON.parse(err?.message || "{}");
        dispatch(setError(error));
      }
    }
    dispatch(setSuccessMessage("Updated status successfully!"));
    setSubmiting(false);
    setShowChangeJobStatus(false);
    setBatchStatusUpdate(false);
    setJobStatusArray([]);
    setSelectAll(false);
  };

  const [packageValues, setPackageValues] = useState({});

  const handlePackageUpdate = async (pack) => {
    try {
      setSubmiting(true);
      const updatedPackageQuantity = Number(packageValues[pack.id] || 0);
      const data = {
        quantity: updatedPackageQuantity,
        validationMethod: "manual",
        packageId: pack.id
      };
      await routeApi.deliverPackages(pack.jobId, data, "");
      setSubmiting(false);

      setPackageValues({ ...packageValues, [pack.id]: 0 });

      setShowPickPackages(false);
    } catch (err) {
      console.error("Error in handlePackageUpdate:", err);
      dispatch(setError(err));
    }
  };

  /**
   * Get delivery addresses for each package on a route.
   * This function iterates through packages and looks for the corresponding
   * delivery address in the stop associated with each packages.
   *
   * @returns dropoffAddresses.
   */
  const getDropoffAddressById = () => {
    const dropoffAddresses = {};

    if (!route || !route.jobs) {
      console.error("Route or route.jobs is undefined");
      return dropoffAddresses;
    }

    Object.keys(packsByType).forEach((packageTypeId) => {
      const packages = packsByType[packageTypeId] || [];

      packages.forEach((pack) => {
        const matchingJob = route.jobs.find((job) => job.id === pack.jobId);

        if (!matchingJob) {
          console.error(`Job not found for package ID: ${pack.id}`);
          return;
        }

        const dropoffLocation =
          matchingJob.locations.find((location) => location.type === "dropoff") ||
          matchingJob.locations.find((location) => location.type === "dropoff") ||
          {};

        if (dropoffLocation) {
          const address = matchingJob.dropoffAddress || "No Address Available";
          dropoffAddresses[pack.id] = address;
        } else {
          console.log(`No dropoff location found for package ID: ${pack.id}`);
        }
      });
    });

    return dropoffAddresses;
  };

  const dropoffAddressesById = getDropoffAddressById();

  const handleChangePackageValue = (packId, value) => {
    setPackageValues({ ...packageValues, [packId]: value });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const selectedJobs = selectAll ? [] : route?.jobs.map((job) => job.id);
    setJobStatusArray(selectedJobs);
  };

  const handleUpdateRouteStatus = async () => {
    try {
      setSubmiting(true);
      await routeApi.updateRouteStatus(route?.id, {
        status: newRouteStatus
      });
      setShowChangeRouteStatus(false);
      setNewRouteStatus(null);
      dispatch(setSuccessMessage("Updated status successfully!"));
      dispatch(doGetRoute(route?.id));
      dispatch(doGetRoutesDispatch(mapRoute?.id));
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setSubmiting(false);
    }
  };

  const isPartialJob = (job) => {
    const { packages = [], routeLocations = [] } = route;
    if (
      _isEmpty(job) ||
      job.status !== "completed" ||
      !packages?.length ||
      !routeLocations?.length
    )
      return false;
    const jobId = job.id;
    const returnRouteLocations = routeLocations.filter(
      (rl) => (rl.isReturnPickup || rl.isReturning) && rl.jobId === jobId
    );
    if (returnRouteLocations?.length) return false;
    const allPickupPackages = packages.filter((pack) => pack.jobId === jobId);
    const totalPickupPackages = allPickupPackages?.reduce(
      (total, curPack) => total + curPack.quantity,
      0
    );

    const allPickupPackageIds = allPickupPackages?.map((pack) => pack.id) || [];

    const allDropoffPackages = routeLocations
      ?.filter((rl) => rl.jobId === jobId && rl.location?.type === "dropoff")
      ?.reduce((rlPackages, curRL) => rlPackages.concat(curRL.routeLocationPackages), [])
      ?.filter((rlPackage) => allPickupPackageIds.includes(rlPackage.packageId));
    const totalDropoffPackages = allDropoffPackages?.reduce(
      (total, curRLPackages) => total + curRLPackages.quantity,
      0
    );

    return totalPickupPackages - totalDropoffPackages !== 0;
  };

  if (!route) {
    return <div />;
  }

  if (route?.jobs) {
    console.log(
      'sort(sortBy("arrivalTime", false))',
      orderBy(route?.jobs, ["finishTime"], ["asc"])
    );
    console.log(route.jobs[0]);
  }

  return (
    <section className="modules active-orders pages route-detail">
      <div className="route-detail-header-wrapper custom-heading-space">
        <OrdersHeader breadCrumbs={breadCrumbs} hasButton={false} />
        {isAdmin && (
          <Button
            size="small"
            className={`svg-icon d-flex flex-row batch-right`}
            onClick={() => {
              setShowChangeJobStatus(true);
              setBatchStatusUpdate(true);
            }}
          >
            Batch Status Update
          </Button>
        )}
        {!isAdmin &&
        route?.jobs &&
        route.jobs.map((job) => job.status).includes("collected") ? (
          <Tooltip
            placement="left"
            title="Cancel Job disabled because Pickup has been completed. If you need to Cancel please contact Flexio support."
          >
            <Button
              size="small"
              className={`svg-icon d-flex flex-row cancel-job-button`}
              disabled
            >
              Cancel Job <CloseCircleOutlined />
            </Button>
          </Tooltip>
        ) : (
          canEdit &&
          !["canceled", "completed", "failed"].includes(route?.status) && (
            <Popconfirm
              title="Are you sure to cancel this job?"
              onConfirm={() => handleSubmitCancelRoute(route.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" className={`svg-icon d-flex flex-row`}>
                Cancel Job <CloseCircleOutlined />
              </Button>
            </Popconfirm>
          )
        )}
      </div>
      <Row gutter={25} className="wrapper">
        <Col span="4">
          <div className="left detail">
            {isAdmin ? (
              <div className="status" onClick={() => setShowChangeRouteStatus(true)}>
                <StatusInList value={convertEmergencyStatus(route)} />
              </div>
            ) : (
              <div className="status">
                <StatusInList value={convertEmergencyStatus(route)} />
              </div>
            )}
            {showChangeRouteStatus && (
              <Modal
                closable={false}
                visible={true}
                title="Edit Job Status"
                width={"70%"}
                footer={
                  <div className="buttons-modal">
                    <Button
                      size="large"
                      onClick={() => {
                        setShowChangeRouteStatus(false);
                        setNewJobStatus(null);
                      }}
                      loading={submiting}
                      disabled={submiting}
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={!newRouteStatus || newRouteStatus === route?.status}
                      loading={submiting}
                      size="large"
                      type="primary"
                      onClick={handleUpdateRouteStatus}
                    >
                      Update
                      <CheckCircleOutlined />
                    </Button>
                  </div>
                }
              >
                <div className="d-flex flex-column gap-3 change-job-status-modal">
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <StatusInList value={convertEmergencyStatus(route)} />
                    </div>
                  </Row>
                  <Row className="flex-column gap-2">
                    <label>New Status</label>
                    <Select
                      listHeight={350}
                      size="large"
                      placeholder="Select status"
                      onChange={(status) => setNewRouteStatus(status)}
                      style={{ width: "100%" }}
                      value={newRouteStatus}
                    >
                      {_keys(
                        route?.status !== "accepted" ? otherStatuses : ROUTE_STATUSES
                      ).map((status) => (
                        <Select.Option key={status} value={status}>
                          {separateStatusUnderlineLetter(status)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </div>
              </Modal>
            )}
            <div className="content">
              <label>Order ID</label>
              <p>{idF}</p>
            </div>
            <div className="content">
              <label>Pickup at</label>
              <p>
                {moment(route?.order?.pickupDate).utc().format(DATETIME_FORMAT_FRONTEND)}
              </p>
            </div>
            <div className="content">
              <label>Service</label>
              <p>{route?.service}</p>
            </div>
            {/* <div className="content">
              <label>Vehicle Type</label>
              <p>{route?.vehicleCategory?.name}</p>
            </div> */}
            <div className="content">
              <label>Estimate Price</label>
              <p>{formatCurrency(route?.clientEstimatePrice) ?? "N/A"}</p>
            </div>
            {isAdmin && (
              <div className="content">
                <label>Created by</label>
                <p>{fullName(route?.order?.user)}</p>
              </div>
            )}
            <div className="content">
              <label>Created at</label>
              <p>{showDate(route?.createdAt)}</p>
            </div>
            <div className="content">
              <label>Return location</label>
              <p>{route.isReturning ? "Yes" : "No"}</p>
            </div>
            <div className="content">
              <label>Vehicle Category</label>
              <p>{route?.vehicleCategory?.name || "N/A"}</p>
            </div>
            <div className="content">
              <label>Driver Assigned</label>
              <p>{fullName(route?.driver)}</p>
              <p>{route?.driver?.phone || "N/A"}</p>
              <p>
                {`${route?.driver?.vehicleMake || "N/A"} - ${
                  route?.driver?.vehicleModel || "N/A"
                }`}
              </p>
              <p>{route?.driver?.vehicleColor}</p>
              {route?.driver?.vehicleLicensePlate && (
                <Image
                  className="pt-3"
                  width={100}
                  src={route?.driver?.vehicleLicensePlate?.url}
                />
              )}
              <div className="d-flex flex-justify-center mt-3">
                {route?.driver?.firstName && (
                  <Avatar
                    size={64}
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {route?.driver?.firstName?.slice(0, 1)}
                  </Avatar>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span="20">
          <div className="right">
            <div>
              <PickupDropoffContainer
                stops={mapRoute?.locations}
                routes={mapRoute?.routes}
                oneHourPx={oneHourPx}
              >
                {({
                  handleClickMarker,
                  handleHoverMarker,
                  popupData,
                  handleClosePopup,
                  goToCoupleStop,
                  center,
                  containerRoutes,
                  mapStops,
                  bounds,
                  driverDetail,
                  setDriverDetail,
                  showDriverDetail,
                  setShowDriverDetail
                }) => (
                  <MapBox
                    handleClosePopup={handleClosePopup}
                    handleClickMarker={handleClickMarker}
                    /* handleHoverMarker={handleHoverMarker} */
                    stops={mapStops}
                    center={center}
                    routes={containerRoutes}
                    order={containerRoutes}
                    popupData={popupData}
                    goToCoupleStop={goToCoupleStop}
                    height={600}
                    bounds={bounds}
                    currentDriver={currentDriver}
                    driverDetail={driverDetail}
                    setDriverDetail={setDriverDetail}
                    showDriverDetail={showDriverDetail}
                    setShowDriverDetail={setShowDriverDetail}
                    isAdmin={isAdmin}
                  />
                )}
              </PickupDropoffContainer>
            </div>
            <div className="right-block jobs">
              <div className="header">
                <h3>JOB LIST</h3>
                {(isAdmin || canEdit) && (
                  <Button
                    size="small"
                    className={`svg-icon d-flex flex-row batch-right float-right`}
                    onClick={() => {
                      // prettier-ignore
                      history.push(`${routesUri}/${mapRoute?.id}/${mapRoute.locations[0].id}/add-job`);
                    }}
                  >
                    Add Stop
                  </Button>
                )}
                <div className="circle">
                  <span>{route?.jobs?.length}</span>
                </div>
              </div>
              {route?.jobs &&
                orderBy(route?.jobs, ["finishTime", "createdAt"], ["asc"]).map(
                  (job, i) => (
                    <div key={job.id}>
                      <div className="job">
                        <Row align="middle">
                          <Col span="18" onClick={() => goToDetailJob(job?.id)}>
                            <JobStep job={job} routeLocations={route?.routeLocations} />
                          </Col>
                          <Col span="6">
                            <div className="status-actions d-flex flex-row flex-end flex-items-center flex-justify-end gap-3">
                              <div className="d-flex flex-column flex-items-end gap-2">
                                <div className="d-flex gap-2">
                                  {isPartialJob(job) && (
                                    <Tag color="#ffa629">
                                      <span style={{ color: "#1f3998" }}>Partial</span>
                                    </Tag>
                                  )}
                                  <Tag
                                    color={JOB_STATUS_COLOR[job?.status]}
                                    onClick={(e) => handleChangeStatus(e, job)}
                                  >
                                    {separateStatusUnderlineLetter(job?.status)}
                                  </Tag>
                                  {canEdit && (
                                    <>
                                      <Button
                                        className="d-flex flex-items-center flex-justify-center"
                                        style={{
                                          height: 22,
                                          width: 22,
                                          padding: "0 5px"
                                        }}
                                        onClick={(e) =>
                                          handleRedirectToEditStop(e, job?.id)
                                        }
                                      >
                                        <ReactSVG
                                          src="/icons/edit.svg"
                                          className="react-icon"
                                        />
                                      </Button>
                                      {!["failed", "completed"].includes(job?.status) && (
                                        <Popconfirm
                                          title="Are you sure to delete this stop?"
                                          onConfirm={(e) =>
                                            handleDeleleteStop(e, job?.id)
                                          }
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <Button
                                            className="d-flex flex-items-center flex-justify-center"
                                            style={{
                                              height: 22,
                                              width: 22,
                                              padding: "0 5px"
                                            }}
                                          >
                                            <DeleteTwoTone twoToneColor="#FF3D17" />
                                          </Button>
                                        </Popconfirm>
                                      )}
                                    </>
                                  )}
                                  <Button
                                    type="primary"
                                    className="d-flex flex-items-center flex-justify-center download-button"
                                    style={{ background: "white" }}
                                    disabled={submiting}
                                    onClick={(e) => getPdfForJob(job.id)}
                                  >
                                    <ImportIcon />
                                  </Button>
                                </div>

                                {route?.driver && route?.driver?.firstName && (
                                  <div className="d-flex flex-row flex-items-center gap-1">
                                    <Avatar
                                      style={{
                                        color: "#f56a00",
                                        backgroundColor: "#fde3cf"
                                      }}
                                    >
                                      {route?.driver?.firstName?.slice(0, 1)}
                                    </Avatar>

                                    <div className="fw-bold-500">
                                      {fullName(route?.driver)}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div onClick={() => goToDetailJob(job?.id)}>
                                <RightOutlined />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {i !== route?.jobs.length - 1 && <Divider />}
                    </div>
                  )
                )}
            </div>
            <div className="right-block packages-block">
              <div className="packages-block-header">
                <div className="header">
                  <h3>PACKAGE LIST</h3>
                  <div className="count">{totalRoutePackages}</div>
                </div>
                {route?.packages?.length && route?.jobs?.length && (
                  <Button
                    size="small"
                    type="third"
                    className={`svg-icon d-inline-flex flex-row`}
                    onClick={handlePrintPackingSlip}
                    loading={printing}
                  >
                    Print Packing Slip <PrintPDFIcon />
                  </Button>
                )}
                {isAdmin && (
                  <Button
                    size="small"
                    type="third"
                    className={`svg-icon d-inline-flex flex-row`}
                    onClick={() => setShowPickPackages(true)}
                  >
                    Pickup Packages
                  </Button>
                )}
                {showPickPackages && (
                  <Modal
                    closable={false}
                    visible={true}
                    title="Pickup Packages"
                    width={"70%"}
                    footer={
                      <div className="buttons-modal">
                        <Button
                          size="large"
                          onClick={() => {
                            setShowPickPackages(false);
                            setNewJobStatus(null);
                          }}
                          loading={submiting}
                          disabled={submiting}
                        >
                          Cancel
                        </Button>
                      </div>
                    }
                  >
                    <div className="d-flex flex-column gap-3 change-job-status-modal buttons-modal">
                      <Row className="gap-5">
                        {Object.keys(packsByType)?.length &&
                          Object.keys(packsByType).map((key, i) => (
                            <div key={key}>
                              <div className="pickupAddress">
                                <div className="packages-by-type">
                                  <div className="type">
                                    <b style={{ textTransform: "capitalize" }}>
                                      {routePackageTypeMap[key]} Package
                                    </b>
                                  </div>

                                  {packsByType[key].map((pack, iP) => (
                                    <React.Fragment key={pack.id}>
                                      <h4
                                        style={{
                                          fontWeight: "bold",
                                          color: "rgb(60, 86, 166)"
                                        }}
                                      >
                                        {dropoffAddressesById[pack.id] ||
                                          "No Address Available"}
                                      </h4>
                                      <div className="package">
                                        {iP + 1}. {pack.name} x {pack.quantity}{" "}
                                        {pack?.barcode ? `(${pack?.barcode})` : ""}
                                      </div>
                                      <ul>
                                        <li>
                                          {`Packages: ${pack?.pickupQuantity || 0}/ ${
                                            pack.quantity
                                          }`}
                                          {pack.validationMethod ? (
                                            <span> (${pack.validationMethod})</span>
                                          ) : (
                                            <span> (N/A)</span>
                                          )}
                                          <input
                                            type="number"
                                            min="0"
                                            value={packageValues[pack.id] || 0}
                                            onChange={(e) =>
                                              handleChangePackageValue(
                                                pack.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <span>{pack?.pickupQuantity}</span>
                                          <button
                                            style={{
                                              width: "auto !important",
                                              border: "1px solid #3c56a6",
                                              backgroundColor: "#fff",
                                              color: "#3c56a6",
                                              fontWeight: "bold",
                                              marginLeft: ".5em",
                                              cursor: "pointer"
                                            }}
                                            onClick={() =>
                                              handlePackageUpdate(pack, location)
                                            }
                                          >
                                            Update <CheckCircleOutlined />
                                          </button>
                                        </li>
                                      </ul>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                              {i !== Object.keys(packsByType).length - 1 && <Divider />}
                            </div>
                          ))}
                      </Row>
                    </div>
                  </Modal>
                )}
              </div>
              <div className="content">
                {Object.keys(packsByType)?.length &&
                  Object.keys(packsByType).map((key, i) => (
                    <div key={key}>
                      <div className="packages-by-type">
                        <div className="type">
                          <b style={{ textTransform: "capitalize" }}>
                            {routePackageTypeMap[key]} Package
                          </b>
                        </div>
                        {packsByType[key].map((pack, iP) => (
                          <React.Fragment key={pack.id}>
                            <div className="package">
                              {iP + 1}. {pack.name} x {pack.quantity}{" "}
                              {pack?.barcode ? `(${pack?.barcode})` : ""}
                            </div>
                            <ul>
                              <li>
                                {" "}
                                {`Pickup: ${pack?.pickupQuantity || 0}/ ${
                                  pack.quantity
                                } (${pack?.validationMethod || "N/A"})`}{" "}
                                {Number(pack?.pickupQuantity || 0) - pack.quantity !==
                                  0 &&
                                  route.status === "completed" && (
                                    <strong style={{ color: "#ff3c17" }}>
                                      {pack.pickupNote || "N/A"}
                                    </strong>
                                  )}
                              </li>
                              <li>
                                {`Dropoff: ${pack?.dropoffQuantity || 0}/ ${
                                  pack?.pickupQuantity || 0
                                } (${pack?.validationMethod || "N/A"})`}{" "}
                                {Number(pack?.dropoffQuantity || 0) -
                                  (pack?.pickupQuantity || 0) !==
                                  0 &&
                                  route.status === "completed" && (
                                    <strong style={{ color: "#ff3c17" }}>
                                      {pack.dropoffNote || "N/A"}
                                    </strong>
                                  )}
                              </li>
                            </ul>
                          </React.Fragment>
                        ))}
                      </div>
                      {i !== Object.keys(packsByType).length - 1 && <Divider />}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {showChangeJobStatus && (
        <Modal
          closable={false}
          visible={true}
          title="Edit Job Status"
          width={"70%"}
          footer={
            <div className="buttons-modal">
              <Button
                size="large"
                onClick={() => {
                  setShowChangeJobStatus(false);
                  setBatchStatusUpdate(false);
                  setSelectAll(false);
                  setJobStatusArray([]);
                }}
                loading={submiting}
                disabled={submiting}
              >
                Cancel
              </Button>
              {newJobStatus === "completed" ? (
                <Popconfirm
                  title="This will mark all packages as delivered"
                  onConfirm={
                    batchStatusUpdate ? handleBatchUpdate : handleUpdateJobStatus
                  }
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button
                    disabled={!newJobStatus || newJobStatus === currentJob?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  disabled={!newJobStatus || newJobStatus === currentJob?.status}
                  loading={submiting}
                  size="large"
                  type="primary"
                  onClick={batchStatusUpdate ? handleBatchUpdate : handleUpdateJobStatus}
                >
                  Update
                  <CheckCircleOutlined />
                </Button>
              )}
            </div>
          }
        >
          <div className="d-flex flex-column gap-3 change-job-status-modal">
            {!batchStatusUpdate && (
              <Row className="gap-5">
                <label>Current Status</label>
                <Tag color={JOB_STATUS_COLOR[currentJob?.status]}>
                  {separateStatusUnderlineLetter(currentJob?.status || "")}
                </Tag>
              </Row>
            )}
            {batchStatusUpdate && (
              <>
                <Row className="gap-5">
                  <label>Select Jobs</label>
                </Row>
                <Row className="gap-5">
                  <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                </Row>
                <div className="right-block jobs">
                  {route?.jobs &&
                    orderBy(route?.jobs, ["finishTime", "createdAt"], ["asc"]).map(
                      (job, i) => (
                        <div key={job.id}>
                          <div className="job">
                            <Row align="middle">
                              <Col span="2">
                                <Checkbox
                                  onChange={() => handleRouteStatusArray(job?.id)}
                                  disabled={selectAll ? true : false}
                                ></Checkbox>
                              </Col>
                              <Col span="15">
                                <JobStep
                                  job={job}
                                  routeLocations={route?.routeLocations}
                                />
                              </Col>
                              <Col span="6">
                                <div className="status-actions d-flex flex-row flex-end flex-items-center flex-justify-end gap-3">
                                  <div className="d-flex flex-column flex-items-end gap-2">
                                    <div className="d-flex gap-2">
                                      {isPartialJob(job) && (
                                        <Tag color="#ffa629">
                                          <span style={{ color: "#1f3998" }}>
                                            Partial
                                          </span>
                                        </Tag>
                                      )}
                                      <Tag color={JOB_STATUS_COLOR[job?.status]}>
                                        {separateStatusUnderlineLetter(job?.status)}
                                      </Tag>
                                    </div>
                                    {route?.driver && route?.driver?.firstName && (
                                      <div className="d-flex flex-row flex-items-center gap-1">
                                        <Avatar
                                          style={{
                                            color: "#f56a00",
                                            backgroundColor: "#fde3cf"
                                          }}
                                        >
                                          {route?.driver?.firstName?.slice(0, 1)}
                                        </Avatar>

                                        <div className="fw-bold-500">
                                          {fullName(route?.driver)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          {i !== route?.jobs.length - 1 && <Divider />}
                        </div>
                      )
                    )}
                </div>
              </>
            )}
            <Row className="flex-column gap-2">
              <label>New Status</label>
              <Select
                listHeight={350}
                size="large"
                placeholder="Select status"
                onChange={(status) => setNewJobStatus(status)}
                style={{ width: "100%" }}
                value={newJobStatus}
              >
                {_keys(
                  route?.jobs.find((job) => job.status === "assigned") === undefined
                    ? otherStopStatuses
                    : JOB_STATUS_COLOR
                ).map((status) => (
                  <Select.Option key={status} value={status}>
                    {separateStatusUnderlineLetter(status)}
                  </Select.Option>
                ))}
              </Select>
              {newJobStatus === "failed" && (
                <>
                  <div className="mt-2">Failed Reason</div>
                  <Input
                    value={failedReason}
                    placeholder="Enter reason"
                    onChange={(e) => setFailedReason(e.target.value)}
                  />
                </>
              )}
            </Row>
          </div>
        </Modal>
      )}
      <img id="packageBarcode" />
    </section>
  );
};
export default withFetchOneMore(RouteDetail, {
  fetchDataApis: [doGetRoute, doGetRoutesDispatch]
});
