import React from "react";
import { COLORS } from "../../configs/constants";
const ExportIcon = ({ color = COLORS.primaryAdmin, onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.6667 3.33341L9.72008 4.28008L8.66008 3.22008V10.6667H7.34008V3.22008L6.28008 4.28008L5.33341 3.33341L8.00008 0.666748L10.6667 3.33341ZM13.3334 6.66675V14.0001C13.3334 14.7334 12.7334 15.3334 12.0001 15.3334H4.00008C3.26008 15.3334 2.66675 14.7334 2.66675 14.0001V6.66675C2.66675 5.92675 3.26008 5.33341 4.00008 5.33341H6.00008V6.66675H4.00008V14.0001H12.0001V6.66675H10.0001V5.33341H12.0001C12.7334 5.33341 13.3334 5.92675 13.3334 6.66675Z"
        fill={color}
      />
    </svg>
  );
};
export { ExportIcon };
