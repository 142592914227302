import request from "helper/request";
import { getTimeZone } from "helper/util";

const routeApi = {
  getListHistory: (params) => {
    return request.get("/routes/history", {
      params
    });
  },
  getList: (params) => {
    return request.get("/routes", {
      params
    });
  },
  getRoute: (id) => {
    return request.get(`/routes/${id}`);
  },
  getRouteOrder: (id) => {
    return request.get(`/routes/${id}/orders`);
  },
  getRouteDispatch: (id) => {
    return request.get(`/routes/${id}/dispatch`);
  },
  export: (startDate, endDate) => {
    return request.get(`/routes/export`, {
      params: {
        startDate,
        endDate
      }
    });
  },
  updateJobStatus: (id, data) => {
    return request.patch(`/jobs/${id}/update-status`, data);
  },
  softDeleteJob: (id) => {
    return request.delete(`/jobs/${id}`);
  },
  assignDriver: (routeId, driverId) => {
    return request.patch(`/admin/routes/${routeId}/assign-driver`, { driverId });
  },
  reassignDriver: (routeId, driverId) => {
    return request.patch(`/admin/routes/${routeId}/reassign-driver`, { driverId });
  },
  cancelRoute: (routeId) => {
    return request.patch(`/routes/${routeId}/cancel-route`);
  },
  getLocation: (id) => {
    return request.get(`/locations/${id}`);
  },
  updateLocation: (data) => {
    const payload = { ...data };
    delete payload.id;
    return request.patch(`/admin/locations/${data.id}`, data);
  },
  getJobPDF: (jobId) => {
    return request.get(`/mvp/jobs/${jobId}/pdf?tz=${getTimeZone()}`);
  },

  updateRouteStatus: (routeId, status) => {
    return request.patch(`/admin/routes/${routeId}/status`, status);
  },

  deliverPackages: (jobId, data, type = "All") => {
    return request.patch(
      `/admin/locations/${jobId}/deliver?quantity=${data?.quantity}&type=${type}&packageId=${data?.packageId}`
    );
  },
  updatePackagesAdmin: (id, packageId, data) => {
    return request.patch(`/mobile/route-locations/${id}/collect/${packageId}`, data);
  }
};

export default routeApi;
