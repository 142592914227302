import React from "react";
const PrintPDFIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 0.333496H4.33325C3.59992 0.333496 2.99992 0.933496 2.99992 1.66683V9.66683C2.99992 10.4002 3.59992 11.0002 4.33325 11.0002H12.3333C13.0666 11.0002 13.6666 10.4002 13.6666 9.66683V1.66683C13.6666 0.933496 13.0666 0.333496 12.3333 0.333496ZM6.66659 5.3335C6.66659 5.88683 6.21992 6.3335 5.66659 6.3335H4.99992V7.66683H3.99992V3.66683H5.66659C6.21992 3.66683 6.66659 4.1135 6.66659 4.66683V5.3335ZM9.99992 6.66683C9.99992 7.22016 9.55325 7.66683 8.99992 7.66683H7.33325V3.66683H8.99992C9.55325 3.66683 9.99992 4.1135 9.99992 4.66683V6.66683ZM12.6666 4.66683H11.6666V5.3335H12.6666V6.3335H11.6666V7.66683H10.6666V3.66683H12.6666V4.66683ZM4.99992 5.3335H5.66659V4.66683H4.99992V5.3335ZM1.66659 3.00016H0.333252V12.3335C0.333252 13.0668 0.933252 13.6668 1.66659 13.6668H10.9999V12.3335H1.66659V3.00016ZM8.33325 6.66683H8.99992V4.66683H8.33325V6.66683Z"
        fill="#1F3998"
      />
    </svg>
  );
};
export { PrintPDFIcon };
