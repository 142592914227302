import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  getFailedRegistrationList,
  getAllFailedRegistration
} from "./FailedRegistrationThunk";

const FailedRegistrationAdapter = createEntityAdapter({
  selectId: (failedRegistration) => failedRegistration.id
});

const initialState = FailedRegistrationAdapter.getInitialState({
  metadata: {},
  failedRegistration: {}
});

export const failedRegistrationSlice = createSlice({
  name: "failedRegistrationData",
  initialState,
  reducers: {
    updatePaginationFailedRegistration(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFailedRegistration.fulfilled, (state, { payload }) => {
      FailedRegistrationAdapter.setAll(state, payload);
      state.failedRegistration = {};
      state.metadata = {};
    });
    builder.addCase(getFailedRegistrationList.fulfilled, (state, { payload }) => {
      FailedRegistrationAdapter.setAll(state, payload.data);
      state.failedRegistration = {};
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectFailedRegistrationById,
  selectIds: selectFailedRegistrationIds,
  selectEntities: selectFailedRegistrationEntities,
  selectAll: selectAllFailedRegistration,
  selectTotal: selectTotalFailedRegistration
} = FailedRegistrationAdapter.getSelectors((state) => state.failedRegistrationData);

export const selectMetadata = (state) => state.failedRegistrationData.metadata;
export const selectFailedRegistration = (state) =>
  state.failedRegistrationData.failedRegistration;

export const { updatePaginationFailedRegistration } = failedRegistrationSlice.actions;

export default failedRegistrationSlice.reducer;
