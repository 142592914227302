import { UserGroup } from "configs/constants";
import { UserRole } from "configs/constants";
import { doExportCsv } from "helper/export";
import { allowPermission } from "helper/util";
import { buildFirstLevelFilter } from "modules/admin/clients/helper";
import { selectRole } from "modules/auth/store/authSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import AdminClientHeader from "modules/admin/clients/components/header/AdminClientHeader";
import FilterAdminUser from "../components/header/FilterAdminUser";
import AdminUserAdminTable from "../components/table/AdminUserAdminTable";
import { getUserList } from "../store/thunks";

import {
  selectAllUsers,
  selectFilter,
  selectMetadata,
  updateFilterUsers,
  updatePaginationUsers
} from "../store/userSlice";
import "../styles/commonUser.scss";

const params = { group: "flexio" };
const breadCrumbs = [{ text: "USERS" }, { text: "ADMINS" }];

const AdminUserAdminList = () => {
  const buttonParams = allowPermission(useSelector(selectRole), [UserRole.ADMIN])
    ? {
        text: "Create Admin User",
        link: "/admin/users/admins/new"
      }
    : null;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFilterUsers(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientList = useSelector(selectAllUsers);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Admin User",
    updatePaginationUsers,
    dispatch
  );
  const [showFilter, setShowFilter] = useState(false);
  const filter = useSelector(selectFilter);
  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 10;

  useEffect(() => {
    delete params?.clientType;
    if (filter?.clientType) {
      params.clientType = filter.clientType;
    }
    dispatch(
      getUserList({
        ...params,
        page,
        pageSize,
        filter: buildFirstLevelFilter(filter)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleExport = async () => {
    await doExportCsv({
      path: "admin/users/export?group=flexio",
      name: "All Admin"
    });
  };

  const handleClickFilter = (values) => {
    dispatch(updateFilterUsers(values));
  };

  return (
    <div className="admin-user-territory-list">
      <AdminClientHeader
        breadCrumbs={breadCrumbs}
        buttonParams={buttonParams}
        handleExport={handleExport}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      {showFilter && (
        <FilterAdminUser
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterUsers(undefined))}
        />
      )}
      <div className="admin-content">
        <AdminUserAdminTable dataSource={clientList} pagination={pagination} />
      </div>
    </div>
  );
};
export default AdminUserAdminList;
