import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AdminClientsTable from "../components/table/AdminClientsTable";
import AdminClientHeader from "../components/header/AdminClientHeader";
import {
  selectAllClients,
  selectFilter,
  selectMetadata,
  updateFilterClients,
  updatePaginationClients
} from "../store/clientSlice";
import { getClientList } from "../store/thunks";
import paginationFunc from "../../../../helper/hooks/pagination";
import FilterClient from "../components/header/FilterClient";
import { buildFirstLevelFilter } from "../helper";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";
import { selectGroup } from "modules/auth/store/authSlice";
import { UserGroup } from "configs/constants";

const params = {};
const breadCrumbs = [{ text: "CLIENTS" }];

const AdminClientList = () => {
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const buttonParams =
    allowPermission(useSelector(selectRole), canCreatePermission) && isFlexioAdmin
      ? {
          text: "Add New Client",
          link: "/admin/clients/new"
        }
      : null;
  const dispatch = useDispatch();
  const clientList = useSelector(selectAllClients);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Client",
    updatePaginationClients,
    dispatch
  );
  const [showFilter, setShowFilter] = useState(false);
  const filter = useSelector(selectFilter);
  const page = metadata?.currentPage || 1;
  const pageSize =
    (metadata?.itemsPerPage || 10) > 100 ? 10 : metadata?.itemsPerPage || 10;

  useEffect(() => {
    dispatch(
      getClientList({
        ...params,
        page,
        pageSize,
        filter: buildFirstLevelFilter(filter)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, filter]);

  const handleClickFilter = (values) => {
    dispatch(updateFilterClients(values));
  };

  return (
    <div className="admin-client-list">
      <AdminClientHeader
        breadCrumbs={breadCrumbs}
        buttonParams={buttonParams}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      {showFilter && (
        <FilterClient
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterClients(undefined))}
        />
      )}
      <div className="admin-content">
        <AdminClientsTable dataSource={clientList} pagination={pagination} />
      </div>
    </div>
  );
};

export default AdminClientList;
