import React from "react";
import { Popup } from "react-mapbox-gl";
import StopPopup from "./StopPopup";
import DriverPopup from "./DriverPopup";

const MapPopup = ({
  data,
  handleClosePopup,
  handleStopDetail,
  handleDriverDetail,
  setDriverDetail,
  stops,
  goToCoupleStop,
  // isAdmin,
  isDashboard = false
}) => {
  return (
    <Popup
      coordinates={[data.lng, data.lat]}
      offset={{
        "bottom-left": [12, -38],
        bottom: [0, -38],
        "bottom-right": [-12, -38]
      }}
    >
      {data.makerType === "driver" ? (
        <DriverPopup
          driver={data}
          handleClosePopup={handleClosePopup}
          handleDriverDetail={handleDriverDetail}
          setDriverDetail={setDriverDetail}
          isDashboard={isDashboard}
        />
      ) : (
        <StopPopup
          stops={stops}
          stop={data}
          handleClosePopup={handleClosePopup}
          handleStopDetail={handleStopDetail}
          goToCoupleStop={goToCoupleStop}
          // canEdit={isAdmin}
          isDashboard={isDashboard}
        />
      )}
    </Popup>
  );
};
export default MapPopup;
