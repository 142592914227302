import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "./api";

export const getOrderList = createAsyncThunk("admin/orders/get/loading", async (data) => {
  return adminApi.getOrderList(data);
});

export const getAllOrder = createAsyncThunk("admin/orders/getAll/loading", async () => {
  return adminApi.getAllOrder();
});

export const doGetOrder = createAsyncThunk("admin/orders/getOne/loading", async (id) => {
  return adminApi.getOrder(id);
});

export const doCreateOrder = createAsyncThunk(
  "admin/orders/create/loading",
  async (data) => {
    return adminApi.createOrder(data);
  }
);

export const doUpdateOrder = createAsyncThunk(
  "admin/orders/create/loading",
  async (data) => {
    return adminApi.updateOrder(data);
  }
);

export const doDeleteOrder = createAsyncThunk(
  "admin/orders/delete/loading",
  async (id) => {
    return adminApi.deleteOrder(id);
  }
);
