/* eslint-disable react/display-name */
import { PlusOutlined, FunnelPlotOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
import "./AdminClientHeader.scss";
import { ExportIcon } from "components/svgs/ExportIcon";

const AdminClientHeader = ({
  breadCrumbs,
  hasButton = true,
  buttonParams,
  handleExport
}) => {
  return (
    <div className="AdminClientHeader">
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons d-flex flex-row">
          <Button
            type="third"
            className={`svg-icon d-flex flex-row`}
            onClick={handleExport}
          >
            Export <ExportIcon />
          </Button>
          <Button>
            Filter
            <FunnelPlotOutlined />
          </Button>
          {buttonParams && (
            <Link to={buttonParams.link}>
              <Button type="primary">
                {buttonParams.text}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default AdminClientHeader;
