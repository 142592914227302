"use strict";
import { MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload
} from "antd";
import React, { useState, useCallback, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { ReactSVG } from "react-svg";
import AddressInput from "../../../../../components/inputs/AddressInput";
import request from "../../../../../helper/request";
import DefaultMapbox from "../mapbox/DefaultMapbox";
import "./AddressForm.scss";
import LocationsDropdown from "modules/client/pool/components/LocationsDropdown";
import moment from "moment/moment";

const { Dragger } = Upload;

const AddressForm = ({
  stepType,
  onChangeTime,
  nameValue,
  fieldKey,
  address = {},
  setAddress,
  orderData,
  id,
  imagesAddressInfo,
  setImagesAddressInfo,
  handleLocationNameUpdate
}) => {
  const imageUploaded = {};
  const [activeKey, setActiveKey] = useState(["1"]);
  const [savedLocations, setSavedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const onchangeActive = (value) => {
    setActiveKey(value);
  };
  const setAddressMap = (value) => {
    setAddress(value, orderData.id);
  };

  const timePickerBlur = (time, column) => {
    onChangeTime(time, orderData.id, stepType, column);
  };

  const headerCollapse = (key) => {
    return (
      <div className="headerCollapse">
        <div className="text">Additional Details</div>
        {activeKey.includes(key) ? (
          <ReactSVG src="/icons/collapse.svg" className="icon" />
        ) : (
          <ReactSVG src="/icons/dropoff.svg" className="icon" />
        )}
      </div>
    );
  };

  const getSavedLocations = useCallback(async () => {
    const response = await request.get("/saved-locations/all-locations");
    setSavedLocations(response);
  }, [setSavedLocations]);

  useEffect(() => {
    getSavedLocations();
    // eslint-disable-next-line
  }, []);

  const handleSelectedData = (locationId) => {
    const foundLocation = savedLocations.find((location) => location.id === locationId);
    orderData.location_name = foundLocation.locationName;
    orderData.physical_address = foundLocation.address;
    orderData.contact_name = foundLocation.contactName;
    orderData.email = foundLocation.contactEmail;
    orderData.phone_numbers = [{ type: "contact", phone: foundLocation.contactPhone }];
    orderData.from_time = moment(foundLocation.timeWindowStart, "HH:mm");
    orderData.to_time = moment(foundLocation.timeWindowEnd, "HH:mm");
    orderData.duration = foundLocation.waitingTime;
    orderData.note = foundLocation.note;
    orderData.internalCustomerId = foundLocation.internalCustomerId;
    orderData.salesPerson = foundLocation.salesperson;
    setAddress(
      {
        address: foundLocation.address,
        lngLat: [foundLocation.lng, foundLocation.lat]
      },
      id
    );
    setSelectedLocation(foundLocation);
  };

  const imageList =
    (imagesAddressInfo[stepType] && imagesAddressInfo[stepType][id]) || [];

  const onChangeFiles = async (values) => {
    if (values.file.status === "done") {
      let newImagesAddressInfo = { ...imagesAddressInfo };

      const newStepType = { ...newImagesAddressInfo[stepType] };
      newStepType[id] = values.fileList.map((file) => {
        return { ...file.response, uid: file.uid };
      });

      newImagesAddressInfo = {
        ...newImagesAddressInfo,
        [stepType]: newStepType
      };
      setImagesAddressInfo(newImagesAddressInfo);
    }
  };

  const onRemoveFile = (file) => {
    const newImagesAddressInfo = { ...imagesAddressInfo };
    const newImageList = [...imageList];
    const index = newImageList.findIndex((image) => image.uid === file.uid);
    if (index !== -1) {
      newImageList.splice(index, 1);
      newImagesAddressInfo[stepType][id] = newImageList;
      setImagesAddressInfo(newImagesAddressInfo);
    }
  };
  const uploadProps = {
    name: "file",
    accept: ".png, .jpeg, .jpg, .pdf",
    multiple: true,
    listType: "picture-card",
    className: "upload-list-inline",
    customRequest: async ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await request("/v1/files", {
          method: "POST",
          data: formData,
          headers: {
            "content-type": "multipart/form-data"
          }
        });
        onSuccess(response);
      } catch (e) {
        console.log(e);
        onError();
      }
    },
    onChange: onChangeFiles,
    onRemove: onRemoveFile,
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ReactSVG src="/icons/delete-upload.svg" />
    },
    defaultFileList: imageList,
    FileList: imageList
  };

  return (
    <div className="AddressForm">
      <div className="address">
        <div className="form">
          <Form.Item
            label="Location Name"
            name={[nameValue, "location_name"]}
            fieldKey={[fieldKey, "location_name"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <LocationsDropdown
              name="pickupStopName"
              options={savedLocations}
              handlePickup={handleSelectedData}
              handleNewLocationName={(value) => {
                handleLocationNameUpdate(value, orderData.id);
              }}
              locationName={orderData.location_name}
            />
          </Form.Item>
          <Form.Item
            label="Map Address"
            name={[nameValue, "address"]}
            fieldKey={[fieldKey, "address"]}
            rules={[
              () => ({
                validator(_, value) {
                  if (!value?.address) {
                    return Promise.reject("Required");
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <AddressInput
              placeholder="Address"
              onSelect={(a) => setAddress(a, orderData.id)}
              address={address}
            />
          </Form.Item>

          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div className="left">Physical Address</div>
                <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                  Optional
                </div>
              </div>
            }
            name={[nameValue, "physical_address"]}
            fieldKey={[fieldKey, "physical_address"]}
          >
            <Input placeholder="Physical Address" />
          </Form.Item>
        </div>
        <div className="map">
          <DefaultMapbox address={address} setAddress={setAddressMap} />
        </div>
      </div>
      <div className="info">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <div className="left">Contact Name</div>
                  <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                    Optional
                  </div>
                </div>
              }
              name={[nameValue, "contact_name"]}
              fieldKey={[fieldKey, "contact_name"]}
              // rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Contact Name" />
            </Form.Item>
            <Form.Item
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <div className="left">E-mail</div>
                  <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                    Optional
                  </div>
                </div>
              }
              name={[nameValue, "email"]}
              fieldKey={[fieldKey, "email"]}
              rules={[{ type: "email", message: "Invalid Email" }]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <div className="custom-label">
              <label>Phone Numbers</label>
            </div>
            <Form.List
              name={[nameValue, "phone_numbers"]}
              fieldKey={[fieldKey, "phone_numbers"]}
              label="Phone Numbers"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKeyValue, ...restField }, index) => {
                    return (
                      <div key={name} className={`phone_numbers x${key}`}>
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKeyValue, "type"]}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select>
                                <Select.Option value="contact">Contact</Select.Option>
                                <Select.Option value="salesperson">
                                  Salesperson
                                </Select.Option>
                                <Select.Option value="mainSupport">
                                  Main Support
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Row>
                              <Col span={index > 0 ? 19 : 24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "phone"]}
                                  fieldKey={[fieldKeyValue, "phone"]}
                                  rules={[{ required: true, message: "Required" }]}
                                >
                                  <PhoneInput />
                                </Form.Item>
                              </Col>
                              {index > 0 && (
                                <Col span={5} className="icon">
                                  <ReactSVG
                                    src="/icons/minus.svg"
                                    onClick={() => remove(name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Form.Item className="bottom-btn">
                    <Button type="link" onClick={() => add({ type: "contact" })}>
                      Add More Phone Number
                      <ReactSVG src="/icons/plus.svg" className="react-icon" />
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col span={8}>
            <div className="custom-label">
              <label>
                {stepType === "dropoffs" ? "Drop-Off Time Window" : "Pickup Time Window"}
              </label>
            </div>
            <Row className="from_to">
              <Col span={11}>
                <Form.Item
                  name={[nameValue, "from_time"]}
                  fieldKey={[fieldKey, "from_time"]}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <TimePicker
                    format="HH:mm"
                    onSelect={(value) => timePickerBlur(value, "from_time")}
                  />
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                <MinusOutlined />
              </Col>
              <Col span={11}>
                <Form.Item
                  name={[nameValue, "to_time"]}
                  fieldKey={[fieldKey, "to_time"]}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <TimePicker
                    format="HH:mm"
                    onSelect={(value) => timePickerBlur(value, "to_time")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Est. Waiting/Service Time"
              name={[nameValue, "duration"]}
              fieldKey={[fieldKey, "duration"]}
              rules={[{ required: true, message: "Required" }]}
              tooltip={{
                title:
                  "Please assign an estimated duration in minutes of time taken from when the driver arrives at the location, is attended, finishes the delivery and resumes driving. Here it is better to go with a worst case scenario to ensure the routes can be finished."
              }}
            >
              <Input
                type="number"
                placeholder="Est. Waiting/Service Time"
                suffix="Minutes"
              />
            </Form.Item>
            <Form.Item
              label="Note"
              name={[nameValue, "note"]}
              fieldKey={[fieldKey, "note"]}
            >
              <Input.TextArea placeholder="Note" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <label>Image/s and Documents</label>
            <Dragger {...uploadProps}>
              <p className="ant-upload-hint">Click or drag file to to upload or</p>
              <p className="ant-upload-drag-icon">
                <img src="/icons/browse_upload.png" style={{ width: "83px" }} alt="" />
              </p>
            </Dragger>
          </Col>
        </Row>
      </div>
      <div className="additional-details">
        <Collapse
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={onchangeActive}
          ghost
          collapsible="header"
        >
          <Collapse.Panel showArrow={false} header={headerCollapse("1")} ghost key="1">
            <Row>
              <Col span="5">
                <Form.Item
                  label="Internal Route ID"
                  name={[nameValue, "internalRouteId"]}
                  fieldKey={[fieldKey, "internalRouteId"]}
                >
                  <Input
                    placeholder="Internal Route ID"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item
                  label="Internal Order ID’s"
                  name={[nameValue, "internalOrderId"]}
                  fieldKey={[fieldKey, "internalOrderId"]}
                >
                  <Input
                    placeholder="Internal Order ID’s"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item
                  label="Internal Customer ID"
                  name={[nameValue, "internalCustomerId"]}
                  fieldKey={[fieldKey, "internalCustomerId"]}
                >
                  <Input
                    placeholder="Internal Customer ID"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item
                  label="Salesperson"
                  name={[nameValue, "salesPerson"]}
                  fieldKey={[fieldKey, "salesPerson"]}
                >
                  <Input
                    placeholder="Salesperson"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item
                  label="COD"
                  name={[nameValue, "cod"]}
                  fieldKey={[fieldKey, "cod"]}
                >
                  <Input
                    type="number"
                    placeholder="COD"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};
export default AddressForm;
