/* eslint-disable react/display-name */
import { Button, Popconfirm, Space, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import TableBase from "../../../../../components/table/TableBase";
import RouteStatus from "../item/RouteStatus";
import "../../styles/routes.scss";
import { RouteIcon, ExportIcon } from "components/svgs";
import { doExportCsv } from "helper/export";
import {
  ArrowRightOutlined,
  RetweetOutlined,
  CloseCircleOutlined,
  UserDeleteOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { canEditPermission } from "configs/constants";
import { convertEmergencyStatus, getTimeZone } from "helper/util";
import EditPriceInput from "./EditPriceInput";

const RouteTable = ({
  dataSource,
  pagination,
  hiddenColumns = [],
  onCancelRoute,
  onAssignDriverClick,
  onReassignDriverClick,
  onUnassignDriverClick
}) => {
  const canEdit = allowPermission(useSelector(selectRole), canEditPermission);
  const history = useHistory();

  const onRowOrder = (record) => {
    return {
      onClick: () => {
        history.push(`/admin/routes/${record.id}`);
      } // click row
    };
  };

  const handleExport = async (record) => {
    await doExportCsv({
      path: `routes/export/${record.id}?tz=${getTimeZone()}`,
      name: record.id
    });
  };

  const editOnStatus = (status) =>
    status !== "completed" && status !== "canceled" ? true : false;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: "left",
      width: 120,
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    {
      title: "Client",
      dataIndex: "client",
      width: 100,
      render: (value) => {
        return value?.clientName;
      }
    },
    {
      title: "Driver",
      dataIndex: "driver",
      width: 100,
      render: (value) => {
        return value ? value.firstName + " " + value.lastName : "N/A";
      }
    },
    {
      title: "# of stops",
      dataIndex: "routeLocations",
      width: 100,
      render: (value) => {
        return value?.length || "N/A";
      }
    },
    {
      title: "Client Estimate",
      width: 150,
      dataIndex: "clientEstimatePrice",
      render: (value, record) => {
        const clientRouteEstimate = record?.routeEstimates.find(
          (routeEstimate) => routeEstimate?.type === "client_estimate"
        );
        return (
          <EditPriceInput
            value={value}
            record={record}
            priceType="clientEstimatePrice"
            typeName="typeClient"
            manualUpdate={clientRouteEstimate?.manualUpdate}
          />
        );
      }
    },
    {
      title: "Driver Estimate",
      width: 150,
      dataIndex: "driverEstimatePrice",
      render: (value, record) => {
        const driverRouteEstimate = record?.routeEstimates.find(
          (routeEstimate) => routeEstimate?.type === "driver_estimate"
        );
        return (
          <EditPriceInput
            value={value}
            record={record}
            priceType="driverEstimatePrice"
            typeName="typeDriver"
            manualUpdate={driverRouteEstimate?.manualUpdate}
          />
        );
      }
    },

    {
      title: "Pickup Date",
      width: 130,
      dataIndex: "startDate"
    },
    {
      title: "Pickup Time",
      dataIndex: "pickupTime",
      width: 130,
      render: (value) => {
        return value === "null" ? "N/A" : value;
      }
    },
    {
      title: "Distance",
      dataIndex: "distance",
      width: 100
    },
    {
      title: "Duration",
      dataIndex: "workingMins",
      width: 100
    },
    {
      title: "Min. Vehicle",
      dataIndex: "vehicleCategory",
      width: 130,
      render: (value) => {
        return value?.name || "N/A";
      }
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 110,
      render: (record) => <RouteStatus value={convertEmergencyStatus(record)} />
    },
    {
      title: "Client Final Price",
      dataIndex: "clientFinalPrice",
      width: 150,
      render: (value, record) => {
        const clientRouteFinal = record?.finalPrices.find(
          (finalPrice) => finalPrice?.type === "client_final_price"
        );
        return (
          <EditPriceInput
            value={value}
            record={record}
            priceType="clientFinalPrice"
            typeName="typeClient"
            manualUpdate={clientRouteFinal?.manualUpdate}
            disabled={editOnStatus(record?.status)}
          />
        );
      }
    },
    {
      title: "Driver Final Price",
      dataIndex: "driverFinalPrice",
      width: 150,
      render: (value, record) => {
        const driverRouteFinal = record?.finalPrices.find(
          (finalPrice) => finalPrice?.type === "driver_final_price"
        );
        return (
          <EditPriceInput
            value={value}
            record={record}
            priceType="driverFinalPrice"
            typeName="typeDriver"
            manualUpdate={driverRouteFinal?.manualUpdate}
            disabled={editOnStatus(record?.status)}
          />
        );
      }
    },
    {
      title: "",
      key: "action",
      width: 150,
      fixed: "right",
      render: (_, record) => {
        if (
          record.pickupTime === "null" &&
          record.jobs?.length === 1 &&
          record.jobs[0].pickupAddress === record.jobs[0].dropoffAddress
        ) {
          return <></>;
        } else {
          return (
            <Space>
              <Tooltip title="Export Job">
                <div onClick={(e) => e.stopPropagation()}>
                  <ExportIcon onClick={() => handleExport(record)} />
                </div>
              </Tooltip>
              <Tooltip title="View Route">
                <div onClick={(e) => e.stopPropagation()}>
                  <Link to={`/admin/routes/${record.id}/dispatch`}>
                    <RouteIcon />
                  </Link>
                </div>
              </Tooltip>
              {!["canceled", "completed"].includes(record.status) && canEdit && (
                <Tooltip title="Cancel Job">
                  <div onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      title="Are you sure to cancel this job?"
                      onConfirm={() => onCancelRoute && onCancelRoute(record.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <CloseCircleOutlined />
                    </Popconfirm>
                  </div>
                </Tooltip>
              )}
              {record.status === "pending" && canEdit && (
                <Tooltip title="Assign Driver">
                  <Button
                    type="text"
                    className="no-padding"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAssignDriverClick && onAssignDriverClick(record);
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>
                </Tooltip>
              )}
              {["accepted", "in-progress"].includes(record.status) && canEdit && (
                <Tooltip title="Reassign Driver">
                  <Button
                    type="text"
                    className="no-padding"
                    onClick={(e) => {
                      e.stopPropagation();
                      onReassignDriverClick && onReassignDriverClick(record);
                    }}
                  >
                    <RetweetOutlined />
                  </Button>
                </Tooltip>
              )}
              {/* Unassign driver button, renders only if the route status is "Accepted" and has a driver assigned */}
              {record.status === "accepted" && canEdit && record.driverId !== null && (
                <Tooltip placement="bottomLeft" title="Unassign Driver">
                  <div className="no-padding" onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure to unassign this driver?"
                      onConfirm={() =>
                        onUnassignDriverClick && onUnassignDriverClick(record.id)
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <UserDeleteOutlined />
                    </Popconfirm>
                  </div>
                </Tooltip>
              )}
            </Space>
          );
        }
      }
    }
  ];
  const filteredColumns =
    hiddenColumns.length > 0
      ? columns.filter((col) => !hiddenColumns.includes(col.title))
      : columns;
  return (
    <TableBase
      onRow={onRowOrder}
      columns={filteredColumns}
      data={dataSource}
      pagination={pagination}
      rowKey="id"
    />
  );
};
export default RouteTable;
