import { yupResolver } from "@hookform/resolvers/yup";
import { Button, notification } from "antd";
import { GroupRadioHook, InputHook } from "components/hook-forms";
import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Cards from "../components/Cards";
import ActionButton from "./ActionButtons";
import { step3Schema } from "./schema";
import "./Step3Form.scss";
import { CheckCircleIcon } from "components/svgs";

const AccountTypesOptions = [
  { value: "checking", name: "Checking" },
  { value: "savings", name: "Savings" }
];

const Step3Form = ({ driver, handleUpdateDriver, showActionButton, isAdmin = true }) => {
  // const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(step3Schema),
    defaultValues: driver
  });

  useEffect(() => {
    if (!isEmpty(driver)) reset(driver);
  }, [driver, reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = async (values) => {
    console.log(driver?.id);
    const error = await handleUpdateDriver({ ...values, id: driver?.id });
    if (!error) {
      notification.success({
        message: "Update successfully!",
        placement: "bottomRight"
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Cards
        title={"Payment Information"}
        className="wrap-step-3-form"
        rightTitle="All fields are required"
      >
        <>
          <div className="group-form">
            <InputHook
              control={control}
              label="(Optional) Full Name of Bank Account Owner if different"
              placeholder="e.g. MY TRUCKING, LLC or partner's name"
              name="fullNameBank"
              showOptionsName={false}
              errors={errors}
            />
            <InputHook
              control={control}
              label="(Optional) Email Address of Bank Account Owner if different"
              placeholder="e.g. accounting@mytrucking.com or partner's email address"
              name="emailBank"
              showOptionsName={false}
              errors={errors}
            />
            <InputHook
              control={control}
              label="Bank Name"
              placeholder="Bank Name"
              name="bankName"
              showOptionsName={false}
              errors={errors}
            />
            <InputHook
              control={control}
              label="Bank Account Number"
              placeholder="Bank Account Number"
              name="bankAccountNumber"
              showOptionsName={false}
              errors={errors}
            />
            <InputHook
              control={control}
              label="Bank Account Routing Number"
              placeholder="Bank Account Routing Number"
              name="bankAccountRoutingNumber"
              showOptionsName={false}
              errors={errors}
            />
            <GroupRadioHook
              label="Account Types"
              placeholder="Account Types"
              options={AccountTypesOptions}
              showOptionsName={false}
              control={control}
              name="accountType"
              errors={errors}
            />
          </div>
        </>
      </Cards>
      {showActionButton && (
        <>
          {isAdmin ? (
            <ActionButton text="Update" />
          ) : (
            <div className="action-buttons">
              <Button type="primary" className="svg-icon" htmlType="submit">
                Update
                <CheckCircleIcon color="#FFFFFF" />
              </Button>
            </div>
          )}
        </>
      )}
    </form>
  );
};
export default Step3Form;
