import { createAsyncThunk } from "@reduxjs/toolkit";
import driverApi from "./api";

export const createDriverPassword = createAsyncThunk(
  "drivers/create-password/loading",
  async (data) => {
    return driverApi.createPassword(data);
  }
);

export const saveAdvancedInfo = createAsyncThunk(
  "drivers/update-driver/loading",
  async (data) => {
    return driverApi.saveAdvancedInfo(data);
  }
);
