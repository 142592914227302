import { Spin } from "antd";
import { setSuccessMessage } from "app/global/slice";
import request from "helper/request";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import Step3Form from "modules/admin/drivers/forms/Step3Form";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export default function PaymentInformation() {
  const profileDriverCtx = useContext(DriverProfileContext);
  const fetchDriverProfile = profileDriverCtx.fetchProfile;
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateDriver = async (values) => {
    try {
      setLoading(true);

      const dataSubmit = {
        fullNameBank: values?.fullNameBank,
        emailBank: values?.emailBank,
        bankName: values?.bankName,
        bankAccountNumber: values?.bankAccountNumber,
        bankAccountRoutingNumber: values?.bankAccountRoutingNumber,
        accountType: values?.accountType
      };

      const response = await request.patch("/drivers/", dataSubmit);
      dispatch(setSuccessMessage("Updated successfully!"));
      fetchDriverProfile();
      return response;
    } catch (error) {
      console.log(error);
      return {};
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Step3Form
        driver={driverProfile}
        handleUpdateDriver={handleUpdateDriver}
        showActionButton={true}
        isAdmin={false}
      />
    </Spin>
  );
}
