import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";

const SwitchHook = ({
  // disabled = false,
  getValues,
  className,
  placeholder = "",
  control,
  errors,
  name,
  label,
  required = false,
  showOptionsName,
  defaultChecked = false,
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={false}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        placeholder={placeholder || name}
        control={control}
        name={name}
        render={({ field }) => (
          // <Input
          //   {...field}
          //   type={type}
          //   disabled={disabled}
          //   {...rest}
          //   placeholder={placeholder || name}
          // />
          <>
            <Switch
              {...field}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={getValues(name) ? getValues(name) : defaultChecked}
              defaultChecked={getValues(name) ? getValues(name) : defaultChecked}
              {...rest}
            />
            <span style={{ paddingLeft: 10 }}>{label}</span>
          </>
        )}
      />
    </FormItemHook>
  );
};
export { SwitchHook };
