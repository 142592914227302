/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
import "../../styles/settings.scss";

const SettingHeader = ({ breadCrumbs, buttonParams, hasButton = true }) => {
  return (
    <div className="setting-header">
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <>
          {buttonParams && (
            <div className="buttons">
              <Link to={buttonParams.link}>
                <Button type="primary">
                  {buttonParams.text}
                  <PlusOutlined />
                </Button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default SettingHeader;
