import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Table, Tooltip } from "antd";
import AdditionalVehicleStatus from "modules/admin/drivers/components/AdditionalVehicleStatus";
import request from "helper/request";
import { Link, useParams } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import "./Vehicles.scss";

export default function Vehicles() {
  const profileDriverCtx = useContext(DriverProfileContext);
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  const [additionalVehicleList, setAdditionalVehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const params = useParams();
  const { id: driverId } = params;

  const getVehicleList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await request.get("/mobile/additional-vehicles/my-vehicles");
      if (!response?.data.length) {
        return;
      }
      setAdditionalVehicleList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getVehicleList();
  }, [getVehicleList]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CarCardComponent = ({ vehicleProps }) => {
    return (
      <Link
        to={`/driver/${driverId}/additional-vehicle/${vehicleProps?.id}/edit`}
        style={{ color: "black" }}
      >
        <div
          className="card-container"
          style={{ background: "white", padding: "10px", marginBottom: "10px" }}
        >
          <div style={{ background: "white" }}>
            <img
              src={vehicleProps?.vehicleImageFrontSide?.url}
              alt="driver vehicle image"
              width="300"
              height="200"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <p
              style={{
                fontSize: "1.3em",
                fontWeight: "bold",
                width: "100%",
                marginBottom: "0px"
              }}
            >
              {vehicleProps?.vehicleMake} {vehicleProps?.vehicleModel}{" "}
              {vehicleProps?.vehicleYear} {vehicleProps?.vehicleColor}
            </p>

            <p>Number Plate: {vehicleProps?.numberPlate}</p>
          </div>
        </div>
      </Link>
    );
  };

  const columns = useMemo(
    () => [
      {
        title: "Vehicle Make",
        dataIndex: "vehicleMake",
        responsive: ["sm"]
      },
      {
        title: "Vehicle Model",
        dataIndex: "vehicleModel"
      },
      {
        title: "Vehicle Color",
        dataIndex: "vehicleColor"
      },
      {
        title: "Vehicle Year",
        dataIndex: "vehicleYear"
      },
      {
        title: "Number Plate",
        dataIndex: "numberPlate"
      },
      {
        title: "Status",
        render: (record) => <AdditionalVehicleStatus status={record?.status} />
      },
      {
        title: "Edit",
        render: (record) => (
          <Link to={`/driver/${driverId}/additional-vehicle/${record?.id}/edit`}>
            <Tooltip title="Edit">
              <img
                src="/icons/edit.svg"
                className={`table-status`}
                style={{ width: "14px" }}
              />
            </Tooltip>
          </Link>
        )
      }
    ],
    [driverId]
  );

  return (
    <>
      {isMobile ? (
        <div className="vehicle-cards-container">
          <h3>
            <b>Vehicles</b>
          </h3>
          {additionalVehicleList.length > 0 &&
            additionalVehicleList.map((vehicle) => (
              <CarCardComponent vehicleProps={vehicle} key={vehicle.id} />
            ))}
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={additionalVehicleList}
          rowKey="id"
          pagination={false}
          loading={loading}
          style={{ fontSize: "5px" }}
        />
      )}

      <Link
        to={`/driver/${driverProfile?.id}/add-additional-vehicle`}
        className="add-additional-vehicle-btn"
      >
        Add Vehicle <PlusCircleOutlined />
      </Link>
    </>
  );
}
