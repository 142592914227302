/* eslint-disable react/display-name */
import React, { useState, useCallback, useMemo } from "react";
import TableBase from "../../../../components/table/TableBase";
import moment from "moment";
import { DATETIME_FORMAT_FRONTEND } from "configs/constants";
import PoolStatus from "components/items/PoolStatus";
import { convertEmergencyStatus } from "helper/util";
import "modules/admin/orders/styles/routes.scss";
import { Checkbox, Tag } from "antd";
import { CircleDotIcon } from "components/svgs";
import { convertHexToRGBA } from "helper/util";
import PoolModalList from "./PoolModalList";
import request from "helper/request";

const FlexioPoolOrderTable = ({
  dataSource,
  pagination,
  hiddenColumns = [],
  setSelectedPackage,
  setShowUpdateStatus,
  selectAll,
  handlePackageArray,
  loading = false,
  refreshOrders
}) => {
  const [showModalList, setShowModalList] = useState(false);
  const [orderPackagesLoading, setOrderPackagesLoading] = useState(false);
  const [orderPackagesData, setOrderPackagesData] = useState([]);
  const [poolOrder, setPoolOrder] = useState({});

  const getOrderPackages = useCallback(async (pkgOrder) => {
    setOrderPackagesLoading(true);
    try {
      const orderPackages = await request.get(`/orderv2/${pkgOrder.id}`);

      if (!orderPackages?.length) {
        resetDOMPackageState(orderPackages, pkgOrder);
        return;
      }

      resetDOMPackageState(orderPackages, pkgOrder);
    } catch (error) {
      console.log(error);
    } finally {
      setOrderPackagesLoading(false);
    }
  });

  const resetDOMPackageState = (orderPkgs, order) => {
    setOrderPackagesData(orderPkgs);
    setPoolOrder(order);
    setShowModalList(true);
  };
  const columns = useMemo(
    () => [
      /* The "Select" column only shows up in the batch update modal,
    used to select a few packages for the status update*/
      {
        title: "Select",
        fixed: "left",
        width: 50,
        render: (value) => {
          return (
            <Checkbox
              onChange={() => handlePackageArray(value?.id)}
              disabled={selectAll ? true : false}
            ></Checkbox>
          );
        }
      },
      {
        title: "Order ID",
        width: 70,
        dataIndex: "id",
        render: (value, record) => {
          return (
            <p
              style={{ margin: 0, color: "#1f3998" }}
              onClick={() => getOrderPackages(record)}
            >
              {value.substr(0, 8).toUpperCase()}
            </p>
          );
        }
      },
      {
        title: "Client",
        width: 100,
        dataIndex: "client",
        render: (value) => {
          return <p style={{ margin: 0, color: "#1f3998" }}>{value?.clientName}</p>;
        }
      },
      /* Package data is selected and modal shows up when clicking on a package status from the table */
      {
        title: "Status",
        width: 80,
        render: (record) => (
          <PoolStatus
            value={convertEmergencyStatus(record)}
            record={record}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            isAdmin={true}
          />
        )
      },
      {
        title: "Payment Status",
        dataIndex: "paymentstatus",
        width: 100,
        render: (value) => {
          return value ? value : "N/A";
        }
      },
      {
        title: "Submitted Date",
        width: 100,
        dataIndex: "submittedDate",
        render: (value) =>
          value ? moment(value).format(DATETIME_FORMAT_FRONTEND) : "N/A"
      },
      {
        title: "Created At",
        width: 110,
        dataIndex: "createdAt",
        render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
      },
      {
        title: "Last Updated At",
        width: 110,
        dataIndex: "updatedAt",
        render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
      }
    ],
    [getOrderPackages]
  );
  const filteredColumns =
    hiddenColumns.length > 0
      ? columns.filter((col) => !hiddenColumns.includes(col.title))
      : columns;
  return (
    <>
      <PoolModalList
        open={showModalList}
        onCancel={() => {
          setShowModalList(false);
        }}
        dataSource={orderPackagesData}
        width={"80%"}
        refreshList={getOrderPackages}
        loading={orderPackagesLoading}
        setLoading={setOrderPackagesLoading}
        poolOrder={poolOrder}
        refreshOrders={refreshOrders}
      />
      <TableBase
        columns={filteredColumns}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
        style={{ overflow: "auto" }}
        loading={loading}
      />
      {/* img required as a target for generatePdfPackingSlipPool jsBarcode to render against. It's CSS is set to display none. */}
      <img id="poolPackageBarcode" />
    </>
  );
};
export default FlexioPoolOrderTable;
