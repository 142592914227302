import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Dropdown, Menu, Tooltip } from "antd";
import HeaderPage from "components/headers/HeaderPage";
import { canCreatePermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import InternalDriverFormHook from "../components/forms/InternalDriverFormHook";
import { ReactSVG } from "react-svg";
import { buildFirstLevelFilter } from "../helper";
import {
  selectAllInternalDrivers,
  selectMetadata,
  updatePaginationDrivers
} from "../store/slice";
import {
  doCreateInternalDriver,
  doDeleteInternalDriver,
  doUpdateInternalDriver,
  getInternalDriverList
} from "../store/thunks";
import InternalDriverTable from "../tables/InternalDriverTable";
import "./InternalDriverListPage.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ProfileContext } from "layouts/private/ProfileContext";

const InternalDriverListPage = () => {
  const [state, setState] = useState({
    visibleModal: false,
    action: "",
    driver: null
  });
  const dispatch = useDispatch();

  const canCreate = allowPermission(useSelector(selectRole), canCreatePermission);
  const driverList = useSelector(selectAllInternalDrivers);
  const metadata = useSelector(selectMetadata);
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile, [profileCtx]);
  const fetchProfile = useCallback(() => profileCtx.fetchProfile, [profileCtx]);
  const page = metadata?.currentPage || 1;
  // const pageSize = metadata?.itemsPerPage || 10;
  const pagination = paginationFunc(
    metadata,
    "Drivers",
    updatePaginationDrivers,
    dispatch
  );

  const items = [
    {
      key: "item-1",
      label: (
        <a href={window.REACT_APP_DOWNLOAD_ANDROID_URL} target="_blank" rel="noreferrer">
          Android
        </a>
      )
    },
    {
      key: "item-2",
      label: (
        <a href={window.REACT_APP_DOWNLOAD_IOS_URL} target="_blank" rel="noreferrer">
          iOS
        </a>
      )
    }
  ];

  useEffect(() => {
    dispatch(
      getInternalDriverList({
        filter: buildFirstLevelFilter({ is_internal_driver: true })
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleDeleteInternalDriver = (id) => {
    dispatch(doDeleteInternalDriver(id));
  };

  const handleSubmit = (values) => {
    if (values.id) {
      dispatch(
        doUpdateInternalDriver({
          ...values
        })
      );
      fetchProfile();
    } else {
      dispatch(
        doCreateInternalDriver({
          ...values
        })
      );
      fetchProfile();
    }

    setState({
      ...state,
      visibleModal: false
    });
  };

  const handleClickEdit = (driver) => {
    setState({
      ...state,
      driver,
      visibleModal: true
    });
  };
  return (
    <div className="list-driver-page">
      <HeaderPage
        leftSide={<div className="title-header">DRIVERS</div>}
        rightSide={
          canCreate ? (
            <div className="d-flex gap-2">
              <Tooltip
                title={
                  !profile?.subscription?.areSeatsAvailable
                    ? "Please upgrade your subscription if you want to create more drivers."
                    : ""
                }
                placement="top"
              >
                <Button
                  type="secondary"
                  onClick={() =>
                    setState({
                      ...state,
                      visibleModal: true,
                      driver: null
                    })
                  }
                  disabled={!profile?.subscription?.areSeatsAvailable}
                >
                  Create Driver
                  <PlusOutlined />
                </Button>
              </Tooltip>
              <Dropdown trigger={"hover"} placement={"bottom"} menu={{ items }}>
                <div className={"driver-app"}>
                  <div className="first-chacracter clickable">
                    <Button size={"small"}>
                      Driver APP
                      <ReactSVG className="driver-icon" src="/icons/local_shipping.svg" />
                    </Button>
                  </div>
                </div>
              </Dropdown>
            </div>
          ) : (
            <Dropdown trigger={"hover"} placement={"bottom"} menu={{ items }}>
              <div className={"driver-app"}>
                <div className="first-chacracter clickable">
                  <Button size={"small"}>
                    Driver APP
                    <ReactSVG className="driver-icon" src="/icons/local_shipping.svg" />
                  </Button>
                </div>
              </div>
            </Dropdown>
          )
        }
      />
      {!profile?.subscription?.areSeatsAvailable && (
        <div className="p-1" style={{ color: "#cf1322" }}>
          You have exceeded the internal driver limit for your subscription. Please
          upgrade your subscription in the account{" "}
          <Link to="settings">
            <span style={{ textDecoration: "underline", textUnderlineOffset: 4 }}>
              settings.
            </span>
          </Link>{" "}
        </div>
      )}
      <div className="content-table">
        <InternalDriverTable
          doDeleteInternalDriver={handleDeleteInternalDriver}
          dataSource={driverList}
          handleClickEdit={handleClickEdit}
          pagination={{ ...pagination, size: "small" }}
        />
      </div>
      {state.visibleModal && (
        <Modal
          closable={false}
          visible={true}
          title={"Create Driver"}
          className="no-padding-modal"
          onCancel={() =>
            setState({
              ...state,
              visibleModal: false
            })
          }
          footer={false}
          width={792}
        >
          <InternalDriverFormHook
            driver={state.driver}
            submitForm={handleSubmit}
            onCancel={() =>
              setState({
                ...state,
                visibleModal: false
              })
            }
          />
        </Modal>
      )}
    </div>
  );
};
export default InternalDriverListPage;
