import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init(window.AMPLITUDE_KEY, {
  autocapture: {
    elementInteractions: false,
    attribution: false,
    pageViews: false,
    sessions: false,
    formInteractions: false,
    fileDownloads: false
  }
});
const identifyEvent = new amplitude.Identify();
identifyEvent.set("env", window.REACT_APP_DD_ENV);
amplitude.identify(identifyEvent);

if (window.REACT_APP_DD_ENV === "stg" || window.REACT_APP_DD_ENV === "prod") {
  const tracingUrlsRegex = /https:\/\/.*\.goflexio\.com/;
  Sentry.init({
    dsn: "https://d8f852c012df1c767c5a6e2d651e098c@o4506826509320192.ingest.sentry.io/4506826511286272",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [tracingUrlsRegex],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
