import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "./api";

export const getLocation = createAsyncThunk("admin/locations/get/loading", async (id) => {
  return adminApi.getLocation(id);
});

export const updateLocation = createAsyncThunk(
  "admin/locations/update/loading",
  async (data) => {
    return adminApi.updateLocation(data);
  }
);
