import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateService,
  doDeleteService,
  doGetService,
  doSetDefaultService,
  getServiceList
} from "./ServiceThunks";

const ServiceAdapter = createEntityAdapter({
  selectId: (service) => service.id
});

const initialState = ServiceAdapter.getInitialState({
  metadata: {},
  service: {}
});

export const serviceSlice = createSlice({
  name: "adminServices",
  initialState,
  reducers: {
    updatePaginationServices(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceList.fulfilled, (state, { payload }) => {
      ServiceAdapter.setAll(state, payload.data);
      state.service = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetService.fulfilled, (state, { payload }) => {
      state.service = payload;
    });
    builder.addCase(doCreateService.fulfilled, (state, { payload }) => {
      ServiceAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doSetDefaultService.fulfilled, (state, { payload }) => {
      ServiceAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.service = payload;
    });
    builder.addCase(doDeleteService.fulfilled, (state, { payload }) => {
      ServiceAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectServiceById,
  selectIds: selectServiceIds,
  selectEntities: selectServiceEntities,
  selectAll: selectAllServices,
  selectTotal: selectTotalServices
} = ServiceAdapter.getSelectors((state) => state.adminServices);

export const selectMetadata = (state) => state.adminServices.metadata;
export const selectService = (state) => state.adminServices.service;

export const { updatePaginationServices } = serviceSlice.actions;

export default serviceSlice.reducer;
