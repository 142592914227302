import React from "react";
import "./DriverStatus.scss";
import { removeSpecialChars } from "helper/util";

const DriverStatus = ({ status }) => {
  return (
    <div className={`driver-status ${removeSpecialChars(status)}`}>
      {removeSpecialChars(status)}
    </div>
  );
};
export default React.memo(DriverStatus);
