import React, { useEffect, useState, useCallback } from "react";
import pluralize from "pluralize";
import { DragDropContext } from "react-beautiful-dnd";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select, Tooltip } from "antd";
import { CloseCircleOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { round, uniq, find } from "lodash";
import moment from "moment";

import { CheckIcon } from "components/svgs/CheckIcon";
import { ExportIcon } from "components/svgs/ExportIcon";
import { deleteShiftStart } from "modules/client/active-orders/helper";
import { handleExistingRoutesOptimize } from "modules/client/active-orders/helper";
import {
  handleNullRoutes,
  handleRoutesOptimize
} from "modules/client/active-orders/helper";
import {
  doOptimizeOrder,
  doSortOrder,
  doStoreDraftId
} from "modules/client/active-orders/store/thunks";
import orderApi from "modules/client/active-orders/store/api";
import { LOCATION_TYPES, COLORS } from "configs/constants";
import { handleExportClientData } from "helper/export";
import { pluck } from "helper/util";

import Timeline from "./components/Timeline";
import UnschedulePart from "./components/UnschedulePart";

import { DND_STATUSES } from "../constants";
import { DoneIcon, OptimizeIcon, RedoIcon, RestoreIcon, UndoIcon } from "../svgs";

import "./ChartContainer.scss";

const { Option } = Select;
const actionButtons = {
  noBorder: { border: "none" }
};
const ChartContainer = ({
  order,
  routes = [],
  handleConfirmRoute,
  handleClickMarker,
  handleHoverMarker,
  handleClosePopup,
  handleStopDetail,
  showConfirmButton = true,
  vehicleCategoryList,
  isShowButtonAction = true,
  handleUpdateRoute,
  handleUpdateAllRoutes,
  displayAll,
  isSelectRouteNumber = false,
  setSelectRouteNumber,
  numberRoutes,
  setNumberRoutes = () => {},
  setDriverDetail,
  setShowDriverDetail,
  groupStops,
  originStops,
  onDragEnd,
  onDragStart,
  handleClickHistory,
  earliestRoute,
  disabledNextButton,
  disabledPreviousButton,
  dndStatus,
  onCancelBack,
  oneHourPx,
  onZoomIn,
  onZoomOut,
  allowOptimize,
  onSetUpdating
}) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isDragging, setDragging] = useState(false);
  const [isScheduledAll, setIsScheduledAll] = useState(false);
  const [unscheduledJobIds, setUnscheduledJobIds] = useState([]);
  const [useBalance, setUseBalance] = useState(true);
  const trueBalanceText = "Disable Balancing";
  const falseBalanceText = "Enable Balancing";
  const [useBalanceButtonText, setUseBalanceButtonText] = useState(
    useBalance ? trueBalanceText : falseBalanceText
  );
  const [isOptimized, setIsOptimized] = useState(true);
  const toggleUseBalance = () => {
    setUseBalance((current) => !current);
    setIsOptimized(false);
  };
  useEffect(() => {
    setUseBalanceButtonText(useBalance ? trueBalanceText : falseBalanceText);
  }, [useBalance]);
  const optimizationPrefDefault = "time";
  // eslint-disable-next-line
  const [optimizationPreference, setOptimizationPreference] = useState(
    optimizationPrefDefault
  );
  const timeWindowMethodDefault = "strict";
  // eslint-disable-next-line
  const [timeWindowMethod, setTimeWindowMethod] = useState(timeWindowMethodDefault);
  const [csvData, setCsvData] = useState({ headers: [], rows: [] });
  const clientId = order?.clientId;

  const handleTimeImport = (time) => {
    return moment(time, "HH:mm");
  };

  useEffect(() => {
    if (order && !order?.id) {
      const [headers, rows] = handleExportClientData(order);
      setCsvData({
        headers,
        rows
      });

      const { jobs = {} } = order;
      setIsScheduledAll(
        !Object.keys(jobs)?.filter((jobId) => jobs[jobId]?.routeId === "unscheduled")
          ?.length
      );
    }
  }, [order]);

  useEffect(() => {
    if (!isSelectRouteNumber) setNumberRoutes(routes.length);
    // eslint-disable-next-line
  }, [routes]);

  useEffect(() => {
    if (!originStops?.length) return;
    setUnscheduledJobIds(
      uniq(
        originStops
          .filter((stop) => stop.routeId === "unscheduled")
          ?.map((filteredStop) => filteredStop.jobId)
      )
    );
    // eslint-disable-next-line
  }, [originStops]);

  const handleRoutesSort = () => {
    const keys = Object.keys(order.routes);
    let tmpRoutesResponse = {};
    keys.forEach((key) => {
      const groupPickupStop = find(groupStops[key], { type: LOCATION_TYPES.PICKUP });
      const orderParam = uniq([
        ...(groupPickupStop?.stopIds || []),
        ...(pluck("id", groupStops[key]) || [])
      ]);
      tmpRoutesResponse[key] = {
        color: order.routes[key].color,
        name: order.routes[key].name,
        // capacity: order.routes[key].capacity,
        order: orderParam,
        start_location: {
          ...order.routes[key].start_location,
          id: key
        },
        locked: order.routes[key]?.locked,
        type: [key]
      };
    });
    const nullRoutes = handleNullRoutes(order, numberRoutes);
    return { ...nullRoutes, ...tmpRoutesResponse };
  };

  const handleClickOptimize = (
    numberRoute = 30,
    userSelectRouteNumber = false,
    numberRoutesSelected = numberRoutes
  ) => {
    setSelectRouteNumber && setSelectRouteNumber(userSelectRouteNumber);
    let visits = {};
    const ids = Object.keys(groupStops);

    ids.forEach((id) => {
      if (timeWindowMethod == "ignore") {
        groupStops[id].forEach((stop) => {
          stop.start = "00:00";
          stop.end = "23:59";
        });
      } else if (timeWindowMethod == "fuzzy") {
        groupStops[id].forEach((stop) => {
          stop.start = moment(stop.start, "HH:mm").subtract(1, "hour").format("HH:mm");
          stop.end = moment(stop.end, "HH:mm").add(1, "hour").format("HH:mm");
        });
      }

      if (id.includes("assigned_")) {
        groupStops[id].forEach((stop) => {
          const jobId = stop.id.split("__")[1];
          visits = {
            ...visits,
            [jobId]: {
              ...visits[jobId],
              [stop?.type]: {
                location: {
                  name: stop.address,
                  lat: stop.lat,
                  lng: stop.lng
                },
                start: stop.start,
                end: stop.end,
                duration: Number(stop.originalDuration || 0)
              },
              type: [id]
            }
          };
        });
      } else {
        const route = routes.find((r) => r.id === id);
        groupStops[id].forEach((stop) => {
          if (stop.type === "pickup") {
            stop?.jobIds.forEach((jobId) => {
              visits = {
                ...visits,
                [jobId]: {
                  ...visits[jobId],
                  pickup: {
                    location: {
                      name: stop.address,
                      lat: stop.lat,
                      lng: stop.lng
                    },
                    start: stop.start,
                    end: stop.end,
                    duration: Number(stop.originalDuration || 0)
                  },
                  type: route?.locked ? [id] : []
                }
              };
            });
          } else {
            const jobId = stop.id.split("__")[1];

            visits = {
              ...visits,
              [jobId]: {
                ...visits[jobId],
                [stop?.type]: {
                  location: {
                    name: stop.address,
                    lat: stop.lat,
                    lng: stop.lng
                  },
                  start: stop.start,
                  end: stop.end,
                  duration: Number(stop.originalDuration || 0)
                },
                type: route?.locked ? [id] : []
              }
            };
          }
        });
      }
    });
    const fleet = handleRoutesOptimize(order, numberRoute, routes);
    const fleetParam = deleteShiftStart(fleet);
    const draftId = new Date().getTime();
    dispatch(doStoreDraftId(draftId));
    dispatch(
      doOptimizeOrder({
        userId: user?.sub,
        draftId,
        visits,
        fleet: fleetParam,
        options: {
          polylines: true,
          balance: useBalance,
          distance_type: "distance_travelled",
          optimization_preference: optimizationPreference
        },
        order: {
          ...order,
          routes: fleetParam
        },
        vehicleCategoryList,
        tags: [`client_id_${clientId}`]
      })
    );
    setIsOptimized(true);
  };

  const handleExistingRouteOptimize = () => {
    let visits = {};
    const ids = Object.keys(groupStops);

    ids.forEach((id) => {
      if (id.includes("assigned_")) {
        groupStops[id].forEach((stop) => {
          if (stop.type === LOCATION_TYPES.PICKUP) {
            stop?.jobIds.forEach((jobId) => {
              visits = {
                ...visits,
                [jobId]: {
                  ...visits[jobId],
                  pickup: {
                    location: {
                      name: stop.address,
                      lat: stop.lat,
                      lng: stop.lng
                    },
                    start: stop.start,
                    end: stop.end,
                    duration: Number(stop.duration || 0)
                  },
                  type: []
                }
              };
            });
          } else {
            const jobId = stop.jobId;
            visits = {
              ...visits,
              [jobId]: {
                ...visits[jobId],
                [stop?.type]: {
                  location: {
                    name: stop.address,
                    lat: stop.lat,
                    lng: stop.lng
                  },
                  start: stop.start,
                  end: stop.end,
                  duration: Number(stop.duration || 0)
                },
                type: []
              }
            };
          }
        });
      } else {
        const route = routes.find((r) => r.id === id);
        for (const stop of groupStops[id]) {
          if (stop.type === LOCATION_TYPES.PICKUP) {
            stop?.jobIds.forEach((jobId) => {
              visits = {
                ...visits,
                [jobId]: {
                  ...visits[jobId],
                  pickup: {
                    location: {
                      name: stop.address,
                      lat: stop.lat,
                      lng: stop.lng
                    },
                    start: stop.start,
                    end: stop.end,
                    duration: Number(stop.duration || 0)
                  },
                  type: route?.locked ? [id] : []
                }
              };
            });
          } else {
            if (stop.isReturning || stop.isReturnPickup) continue;
            const jobId = stop.jobId;
            visits = {
              ...visits,
              [jobId]: {
                ...visits[jobId],
                [stop?.type]: {
                  location: {
                    name: stop.address,
                    lat: stop.lat,
                    lng: stop.lng
                  },
                  start: stop.start,
                  end: stop.end,
                  duration: Number(stop.duration || 0)
                },
                type: route?.locked ? [id] : []
              }
            };
          }
        }
      }
    });
    const fleet = handleExistingRoutesOptimize(order, routes[0]);
    const fleetParam = deleteShiftStart(fleet);
    const draftId = new Date().getTime();
    dispatch(doStoreDraftId(draftId));
    onSetUpdating(true);
    orderApi.optimizeExistingOrder({
      userId: user?.sub,
      draftId,
      visits,
      fleet: fleetParam,
      options: {
        polylines: true,
        balance: false,
        distance_type: "distance_travelled",
        optimization_preference: optimizationPreference
      },
      tags: [`client_id_${clientId}`]
    });
  };

  const handleClickForceRouteAll = (
    numberRoute = 30,
    userSelectRouteNumber = false,
    numberRoutesSelected = numberRoutes
  ) => {
    setSelectRouteNumber(userSelectRouteNumber);
    let visits = {};
    const ids = Object.keys(groupStops);
    ids.forEach((id) => {
      if (id.includes("assigned_")) {
        groupStops[id]?.forEach((stop) => {
          if (stop.type === "pickup") return;
          const jobId = stop.id.split("__")[1];
          const pickupStop = find(groupStops[id], { type: "pickup" });
          const additionalVisit = {
            ...visits[jobId],
            dropoff: {
              location: {
                name: stop.address,
                lat: stop.lat,
                lng: stop.lng
              },
              start: stop.start,
              end: stop.end,
              duration: Number(stop.originalDuration || 0)
            },
            pickup: {
              location: {
                name: pickupStop.address,
                lat: pickupStop.lat,
                lng: pickupStop.lng
              },
              start: pickupStop.start,
              end: pickupStop.end,
              duration: Number(pickupStop.originalDuration || 0)
            },
            // load,
            type: [id]
          };
          if (unscheduledJobIds?.includes(jobId)) {
            delete additionalVisit.dropoff?.end;
            delete additionalVisit.pickup?.end;
          }
          visits = {
            ...visits,
            [jobId]: additionalVisit
          };
        });
      } else {
        const route = routes.find((r) => r.id === id);
        groupStops[id].forEach((stop) => {
          if (stop.type === "pickup") {
            stop?.jobIds.forEach((jobId) => {
              // const job = order.jobs[jobId];
              // const load = calculateLoad(job.packages);
              const additionalVisit = {
                ...visits[jobId],
                pickup: {
                  location: {
                    name: stop.address,
                    lat: stop.lat,
                    lng: stop.lng
                  },
                  start: stop.start,
                  end: stop.end,
                  duration: Number(stop.originalDuration || 0)
                },
                // load,
                type: route?.locked ? [id] : []
              };
              if (unscheduledJobIds?.includes(jobId)) delete additionalVisit.pickup.end;
              visits = {
                ...visits,
                [jobId]: additionalVisit
              };
            });
          } else {
            const jobId = stop.id.split("__")[1];
            // const job = order.jobs[jobId];
            // const load = calculateLoad(job.packages);
            const additionalVisit = {
              ...visits[jobId],
              [stop?.type]: {
                location: {
                  name: stop.address,
                  lat: stop.lat,
                  lng: stop.lng
                },
                start: stop.start,
                end: stop.end,
                duration: Number(stop.originalDuration || 0)
              },
              // load,
              type: route?.locked ? [id] : []
            };
            if (unscheduledJobIds?.includes(jobId))
              delete additionalVisit[stop?.type]?.end;
            visits = {
              ...visits,
              [jobId]: additionalVisit
            };
          }
        });
      }
    });
    const fleet = handleRoutesOptimize(order, numberRoute, routes);
    const fleetParam = deleteShiftStart(fleet);
    const optimizeFleetParam = Object.keys(fleetParam)?.reduce((obj, routeKey) => {
      const curObj = fleetParam[routeKey];
      delete curObj.shift_end;
      obj[routeKey] = curObj;
      return obj;
    }, {});

    const draftId = new Date().getTime();
    dispatch(doStoreDraftId(draftId));
    dispatch(
      doOptimizeOrder({
        userId: user?.sub,
        draftId,
        visits,
        fleet: optimizeFleetParam,
        options: {
          polylines: true,
          balance: useBalance,
          distance_type: "distance_travelled",
          optimization_preference: optimizationPreference
        },
        order: {
          ...order,
          routes: optimizeFleetParam
        },
        vehicleCategoryList,
        tags: [`client_id_${clientId}`]
      })
    );
  };

  const handleClickSort = () => {
    let visits = {};
    const ids = Object.keys(groupStops);

    ids.forEach((id) => {
      if (id.includes("assigned_")) {
        groupStops[id].forEach((stop) => {
          const jobId = stop.id.split("__")[1];
          // const job = order.jobs[jobId];
          // const load = calculateLoad(job.packages);

          visits = {
            ...visits,
            [jobId]: {
              ...visits[jobId],

              location: {
                name: stop.address,
                lat: stop.lat,
                lng: stop.lng
              },
              start: stop.start,
              end: stop.end,
              duration: Number(stop.originalDuration || 0),
              type: []
              // load
            }
          };
        });
      } else {
        groupStops[id].forEach((stop) => {
          if (stop?.stopIds?.length) {
            stop?.stopIds?.forEach((stopId) => {
              visits = {
                ...visits,
                [stopId]: {
                  ...visits[stopId],
                  location: {
                    name: stop.address,
                    lat: stop.lat,
                    lng: stop.lng
                  },
                  start: stop.start,
                  end: stop.end,
                  duration: Number(stop.originalDuration || 0)
                }
              };
            });
          } else {
            // const job = order.jobs[stop?.jobId];
            // const load = calculateLoad(job.packages);
            visits = {
              ...visits,
              [stop.id]: {
                ...visits[stop.id],
                location: {
                  name: stop.address,
                  lat: stop.lat,
                  lng: stop.lng
                },
                start: stop.start,
                end: stop.end,
                duration: Number(stop.originalDuration || 0)
              }
            };
          }
        });
      }
    });
    const fleet = handleRoutesSort();
    const draftId = new Date().getTime();
    dispatch(doStoreDraftId(draftId));
    // TODO: What is the difference between doSortOrder and doOptimizeOrder
    dispatch(
      doSortOrder({
        userId: user?.sub,
        draftId,
        visits,
        fleet,
        options: {
          polylines: true,
          optimization_preference: optimizationPreference
        },
        order: {
          ...order,
          routes: fleet
        },
        vehicleCategoryList,
        tags: [`client_id_${clientId}`]
      })
    );
  };

  const handleChangeSelect = useCallback((value) => {
    setNumberRoutes(value);
    setSelectRouteNumber(true);
    setIsOptimized(false);
  }, []);

  const handleOptimizationSelect = useCallback((value) => {
    setOptimizationPreference(value);
    setIsOptimized(false);
  }, []);

  const handleTimeWindowMethod = useCallback((value) => {
    setTimeWindowMethod(value);
    setIsOptimized(false);
  }, []);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="chart-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f9f9f9"
            }}
          >
            <UnschedulePart
              isDragging={isDragging}
              dndStatus={dndStatus}
              stops={groupStops["unscheduled"]}
              handleHoverMarker={handleHoverMarker}
              handleClosePopup={handleClosePopup}
              handleClickMarker={handleClickMarker}
              handleStopDetail={handleStopDetail}
            />
            <div style={{ marginRight: "58px" }}>
              <Button type="text" onClick={() => onZoomOut()}>
                <ZoomOutOutlined />
              </Button>
              <Button type="text" onClick={() => onZoomIn()}>
                <ZoomInOutlined />
              </Button>
            </div>
          </div>
          <div>
            <Timeline
              displayAll={displayAll}
              firstStartTime={earliestRoute.shiftStart}
              firstEndTime={"23:59"}
              routes={routes}
              groupStops={groupStops}
              isDragging={isDragging}
              oneHourPx={oneHourPx}
              setDragging={setDragging}
              dndStatus={dndStatus}
              onDragEnd={onDragEnd}
              handleClickMarker={handleClickMarker}
              handleHoverMarker={handleHoverMarker}
              handleClosePopup={handleClosePopup}
              handleUpdateRoute={handleUpdateRoute}
              handleUpdateAllRoutes={handleUpdateAllRoutes}
              setDriverDetail={setDriverDetail}
              setShowDriverDetail={setShowDriverDetail}
            />
          </div>
        </div>
      </DragDropContext>
      {(isShowButtonAction || allowOptimize) && (
        <div className="button-actions">
          <div className="left-side-button">
            <Tooltip title="Undo">
              <Button
                style={actionButtons.noBorder}
                onClick={() => handleClickHistory("undo")}
                // disabled={disabledPreviousButton}
              >
                <UndoIcon
                  color={disabledPreviousButton ? COLORS.brown : COLORS.primary}
                />
              </Button>
            </Tooltip>

            <Tooltip title="Redo">
              <Button
                style={actionButtons.noBorder}
                onClick={() => handleClickHistory("next")}
                // disabled={disabledNextButton}
              >
                <RedoIcon color={disabledNextButton ? COLORS.brown : COLORS.primary} />
              </Button>
            </Tooltip>

            <Tooltip title="Revert">
              <Button
                className=""
                style={actionButtons.noBorder}
                onClick={() => handleClickHistory("revert")}
                // disabled={disabledPreviousButton}
              >
                <RestoreIcon
                  color={disabledPreviousButton ? COLORS.brown : COLORS.yellow}
                />
              </Button>
            </Tooltip>
          </div>
          <div className="right-side-button">
            {allowOptimize ? (
              <Button
                type="tertiary"
                className="svg-icon"
                onClick={() => handleExistingRouteOptimize()}
              >
                Optimize Route &nbsp;
                <OptimizeIcon />
              </Button>
            ) : (
              <>
                <Tooltip title="Cancel">
                  <Button style={actionButtons.noBorder} onClick={onCancelBack}>
                    <CloseCircleOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Export">
                  <Button style={actionButtons.noBorder}>
                    <CSVLink
                      filename={"confirm-route.csv"}
                      data={csvData.rows}
                      headers={csvData.headers}
                    >
                      <ExportIcon />
                    </CSVLink>
                  </Button>
                </Tooltip>
                <Select
                  defaultValue="time"
                  style={{ width: 120 }}
                  onChange={handleOptimizationSelect}
                  options={[
                    { value: "time", label: "Time" },
                    { value: "distance", label: "Distance" }
                  ]}
                />
                <Select
                  defaultValue="strict"
                  style={{ width: 100 }}
                  onChange={handleTimeWindowMethod}
                  options={[
                    { value: "strict", label: "Strict" },
                    { value: "fuzzy", label: "Fuzzy" },
                    { value: "ignore", label: "Ignore" }
                  ]}
                />
                <Button onClick={toggleUseBalance}>{useBalanceButtonText}</Button>
                <Select
                  defaultValue={numberRoutes}
                  style={{ width: 120 }}
                  onChange={handleChangeSelect}
                  value={numberRoutes}
                >
                  {Array.from(Array(30)).map((_, index) => (
                    <Option key={index} value={index + 1}>
                      {pluralize("Route", index + 1, true)}
                    </Option>
                  ))}
                </Select>
                {dndStatus === DND_STATUSES.SORT ? (
                  <Button
                    type="secondary-with-icon"
                    className="svg-icon"
                    onClick={() => handleClickSort("sort")}
                  >
                    Apply <DoneIcon color={COLORS.primary} />
                  </Button>
                ) : (
                  <>
                    <Button
                      type="primary"
                      className="svg-icon"
                      onClick={() => handleClickForceRouteAll(numberRoutes)}
                      disabled={isScheduledAll}
                    >
                      Force Route All &nbsp;
                      <OptimizeIcon />
                    </Button>
                    <Button
                      type="tertiary"
                      className="svg-icon"
                      onClick={() => handleClickOptimize(numberRoutes)}
                      disabled={isOptimized && dndStatus !== DND_STATUSES.OPTIMIZE}
                    >
                      Optimize Route &nbsp;
                      <OptimizeIcon />
                    </Button>
                  </>
                )}
                {showConfirmButton && (
                  <Button
                    type="secondary"
                    className="svg-icon"
                    onClick={handleConfirmRoute}
                    disabled={
                      isSelectRouteNumber ||
                      [DND_STATUSES.SORT, DND_STATUSES.OPTIMIZE].includes(dndStatus)
                    }
                  >
                    Confirm Route
                    <CheckIcon color={COLORS.white} />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ChartContainer;
