import React from "react";
const RouteIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.82277 18.7179C6.54493 18.7179 7.15899 19.1866 7.37816 19.8355H17.0681C18.0115 19.8355 18.7791 19.0676 18.7791 18.1244C18.7791 17.1805 18.0112 16.4131 17.0681 16.4131H13.7154C12.1941 16.4131 10.9563 15.1756 10.9563 13.654C10.9563 12.1327 12.1938 10.895 13.7154 10.895H16.6304C16.8496 10.2461 17.4637 9.77734 18.1855 9.77734C19.0907 9.77734 19.827 10.5136 19.827 11.4188C19.827 12.324 19.0907 13.0602 18.1855 13.0602C17.4638 13.0602 16.8496 12.5915 16.6304 11.9426H13.7154C12.7719 11.9426 12.0043 12.7105 12.0043 13.6537C12.0043 14.5972 12.7722 15.3648 13.7154 15.3648H17.0682C18.5895 15.3648 19.8273 16.6027 19.8273 18.1238C19.8273 19.6451 18.5893 20.8829 17.0682 20.8829H7.37757C7.1584 21.5318 6.54434 22.0002 5.82218 22.0002C4.9173 22.0002 4.18103 21.264 4.18103 20.3591C4.18103 19.4541 4.91741 18.7179 5.82248 18.7179H5.82277Z"
          fill="black"
        />
        <path
          d="M3 13.6167C3 14.2205 3.20637 14.8143 3.58124 15.2877L5.69816 17.9495L7.81408 15.289C8.18967 14.8141 8.3962 14.2203 8.3962 13.6166C8.3962 12.1289 7.18579 10.9185 5.69805 10.9185C4.21015 10.9186 3.00004 12.1288 3.00004 13.6166L3 13.6167ZM4.6924 13.6167C4.6924 13.0619 5.14341 12.6109 5.69819 12.6109C6.25269 12.6109 6.70399 13.0619 6.70399 13.6167C6.70399 14.1711 6.25269 14.6224 5.69819 14.6224C5.14341 14.6224 4.6924 14.1711 4.6924 13.6167Z"
          fill="black"
        />
        <path
          d="M15.5138 4.69819C15.5138 5.30205 15.7202 5.89582 16.095 6.36926L18.212 9.03102L20.3279 6.37055C20.7035 5.89563 20.91 5.30187 20.91 4.69816C20.91 3.21041 19.6996 2 18.2118 2C16.7239 2 15.5138 3.21041 15.5138 4.69816L15.5138 4.69819ZM17.2058 4.69819C17.2058 4.14341 17.6568 3.6924 18.2115 3.6924C18.766 3.6924 19.2173 4.14341 19.2173 4.69819C19.2173 5.25269 18.766 5.70399 18.2115 5.70399C17.6568 5.70384 17.2058 5.25254 17.2058 4.69819Z"
          fill="black"
        />
      </svg>
    </>
  );
};
export { RouteIcon };
