import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function withFetchOne(WrappedComponent, { fetchData, idSend = null }) {
  const WithFetch = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const id = idSend || params?.id;
    useEffect(() => {
      if (id) {
        dispatch(fetchData(id));
      }
    }, [dispatch, id]);
    return <WrappedComponent {...props} />;
  };
  return WithFetch;
}
export default withFetchOne;
