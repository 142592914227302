import { Button, Tooltip } from "antd";
import { COLORS } from "configs/constants";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  InfoIcon,
  LockIcon,
  SwapCall,
  UnlockIcon,
  VisibleIcon,
  VisibleOffIcon
} from "../../svgs";
import "./DriverItem.scss";
import Slot from "./Slot";
import RouteChart from "./RouteChart";
import { WarningOutlined } from "@ant-design/icons";
import { DND_STATUSES } from "../../constants";

const DriverItem = ({
  route,
  routePlan,
  history,
  handleSetSwapModal,
  isDragging,
  dndStatus,
  assignStops = [],
  indexVet,
  stops = [],
  originStart,
  originEnd,
  divideLine,
  oneHourPx,
  handleHoverMarker,
  handleClosePopup = () => {},
  handleUpdateRoute,
  handleClickMarker,
  handleStopDetail,
  setDriverDetail,
  setShowDriverDetail
}) => {
  const [visible, setVisible] = useState(false);
  // const [locked, setLocked] = useState(route?.locked);

  const getListStyle = (focus) => ({
    border: focus ? "dashed 1px " : ""
    // zIndex: 10
  });
  const isOriginDnd = history?.currentIndex === 0;
  const infoIsDragging = {
    position: "unset"
    // zIndex: 0
  };

  const handleSetLocked = () => {
    // setLocked(!locked);
    handleUpdateRoute({
      ...route,
      locked: !route?.locked
    });
  };

  const handleClickInfoDriver = () => {
    handleClickMarker(route, "driver");
    setShowDriverDetail(true);
    setDriverDetail(route);
  };

  return (
    <div className={`driver-chart timeline-driver-item ${isDragging && "isDragging"}`}>
      <div
        className="info-side"
        style={isDragging ? infoIsDragging : { position: "sticky", zIndex: 900 }}
      >
        {route?.locked ? (
          <LockIcon color={COLORS.third} onClick={handleSetLocked} />
        ) : (
          <UnlockIcon color={COLORS.third} onClick={handleSetLocked} />
        )}
        {route?.display ? (
          <VisibleIcon
            onClick={() =>
              handleUpdateRoute({
                ...route,
                display: !route?.display
              })
            }
          />
        ) : (
          <VisibleOffIcon
            onClick={() =>
              handleUpdateRoute({
                ...route,
                display: !route?.display
              })
            }
          />
        )}

        <div className="driver-name d-flex flex-items-center gap-2">
          {route.workingMins > 480 && (
            <Tooltip
              title="Working time is exceeding 8 hours for this driver"
              color={"#000"}
            >
              <WarningOutlined style={{ color: "#FF3D17" }} />
            </Tooltip>
          )}
          <div onClick={() => setVisible(!visible)}>{route?.name}</div>
        </div>
        <div className="driver-assign-action">
          <Button
            type="link"
            className="svg-icon summary"
            size="small"
            onClick={handleClickInfoDriver}
          >
            <InfoIcon width="16px" height="16px" />
          </Button>

          <SwapCall
            // width="16px"
            // height="16px"
            onClick={() => (isOriginDnd ? handleSetSwapModal(route.id) : "")}
            color={isOriginDnd ? COLORS.purple : COLORS.brown}
            className={isOriginDnd ? "" : "disabled"}
          />
          <Droppable
            key={route.id}
            droppableId={`assigned_${route.id}`}
            direction="horizontal"
            isDropDisabled={dndStatus === DND_STATUSES.SORT}
          >
            {(provided, snapshot) => (
              <div
                className={`list-slots  ${isDragging && "isDragging"} ${dndStatus}`}
                style={{ ...getListStyle(snapshot.isDraggingOver) }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {assignStops.map((stop, index) => (
                  <Draggable key={stop.id} draggableId={stop.id} index={index}>
                    {(provided1, snapshot1) => (
                      <Slot
                        provided={provided1}
                        snapshot={snapshot1}
                        stop={stop}
                        key={stop.id}
                        handleHoverMarker={handleHoverMarker}
                        handleClosePopup={handleClosePopup}
                        handleClickMarker={handleClickMarker}
                        handleStopDetail={handleStopDetail}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </div>
      </div>
      {route?.display && (
        <div className="chart-side-wrap">
          <div className="chart-side">
            <RouteChart
              isDragging={isDragging}
              // setDragging={setDragging}
              dndStatus={dndStatus}
              indexVet={indexVet}
              route={route}
              stops={stops}
              originStart={originStart}
              originEnd={originEnd}
              handleHoverMarker={handleHoverMarker}
              handleClosePopup={handleClosePopup}
              handleClickMarker={handleClickMarker}
              handleStopDetail={handleStopDetail}
              setDriverDetail={setDriverDetail}
              routePlan={routePlan}
              oneHourPx={oneHourPx}
            />
            {divideLine}
          </div>
        </div>
      )}
    </div>
  );
};
export default DriverItem;
