import { Spin, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import "./AppLoading.scss";

const AppLoading = () => {
  const loading = useSelector((state) => state.global.isLoading);
  return (
    <Modal
      open={loading}
      footer={null}
      className="app-modal-spin"
      closable={false}
      zIndex={99}
    />
  );
  // return <></>;
};
export default AppLoading;
