import React from "react";
const BrowserUploadIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 0.666687H1.33333C0.593333 0.666687 0 1.26669 0 2.00002V10C0 10.7334 0.593333 11.3334 1.33333 11.3334H4V10H1.33333V3.33335H10.6667V10H8V11.3334H10.6667C11.4 11.3334 12 10.7334 12 10V2.00002C12 1.26669 11.4067 0.666687 10.6667 0.666687ZM6 4.66669L3.33333 7.33335H5.33333V11.3334H6.66667V7.33335H8.66667L6 4.66669Z"
        fill="#1F3998"
      />
    </svg>
  );
};
export { BrowserUploadIcon };
