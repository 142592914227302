/* eslint-disable react/display-name */
import { Popconfirm, Space, Switch } from "antd";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { INTERNAL_DRIVER_SERVICE } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _uniq from "lodash/uniq";

import TableBase from "../../../../../components/table/TableBase";
import { doDeleteService, doSetDefaultService } from "../../store/ServiceThunks";

const ServiceTable = ({ dataSource, pagination }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDelete = (service) => {
    dispatch(doDeleteService(service));
  };

  const handleEdit = (record) => {
    history.push(`/admin/settings/services/${record.id}`);
  };

  const columns = [
    /* {
      title: "Default Service",
      dataIndex: "isDefault",
      render: (value, record) => {
        return (
          record?.name?.toLowerCase() !== INTERNAL_DRIVER_SERVICE && (
            <Switch
              checked={value}
              disabled={!canEdit}
              onChange={() => dispatch(doSetDefaultService(record.id))}
            />
          )
        );
      }
    }, */
    {
      title: "Service Name",
      dataIndex: "name",
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}>{value}</p>;
      }
    },
    {
      title: "Service Description",
      dataIndex: "description"
    },
    {
      title: "Vehicle Categories",
      dataIndex: "vehicleRates",
      render: (value) => {
        return value && _uniq(value.map((o) => o.vehicleCategory?.name))?.join(", ");
      }
    }
  ];
  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          record?.name?.toLowerCase() !== INTERNAL_DRIVER_SERVICE && (
            <Space size="middle">
              {canEdit && (
                <img
                  src="/icons/edit.svg"
                  className={`table-status ${value}`}
                  onClick={() => handleEdit(record)}
                />
              )}
              {canDelete && (
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => handleDelete(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <img src="/icons/delete.svg" className={`table-status ${value}`} />
                </Popconfirm>
              )}
            </Space>
          )
        );
      }
    });
  }
  return (
    <TableBase columns={columns} data={dataSource} pagination={pagination} rowKey="id" />
  );
};
export default ServiceTable;
