import { getApp, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_APIKEY,
  authDomain: `${window.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: window.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${window.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: window.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
  appId: window.REACT_APP_FIREBASE_APP_ID
};

let app;
try {
  app = getApp();
} catch (err) {
  app = initializeApp(firebaseConfig);
}

export const realtimeDB = getDatabase(app);
