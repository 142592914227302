import { createAsyncThunk } from "@reduxjs/toolkit";
import driverApi from "./api";

export const getDriverList = createAsyncThunk("drivers/get/loading", async (data) => {
  return driverApi.getDriverList(data);
});

export const doGetDriver = createAsyncThunk("drivers/getOne/loading", async (id) => {
  return driverApi.getDriver(id);
});

export const doCreateDriver = createAsyncThunk("drivers/create/loading", async (data) => {
  console.log(data);
  return driverApi.createDriver(data);
});

export const doImportDrivers = createAsyncThunk(
  "drivers/import/loading",
  async (data) => {
    return driverApi.importDrivers(data);
  }
);

export const doUpdateDriver = createAsyncThunk("drivers/update/loading", async (data) => {
  return driverApi.updateDriver(data);
});

export const doDeleteDriver = createAsyncThunk("drivers/delete/loading", async (id) => {
  return driverApi.delete(id);
});

export const doGetAllCities = createAsyncThunk("drivers/getCities/loading", async () => {
  return driverApi.getCities();
});
