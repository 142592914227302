import React, { useState, useRef, useCallback, useMemo } from "react";
import { Button, Modal, Checkbox, notification, Spin } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import request from "helper/request";
import paymentMethodImg from "./paymentmethodimg.webp";
import PaymentForm from "./PaymentForm";
import SecuredCardIcon from "./cardIcon.webp";

const stripePromise = loadStripe(window.REACT_APP_STRIPE_PK);

const PaymentMethodStep1 = ({ handleCheckbox, isChecked }) => {
  return (
    <div>
      <h2
        style={{
          width: "100%",
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "20px",
          fontWeight: "bold"
        }}
      >
        You are one step away!
      </h2>
      <div
        id="text"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        <img
          src={paymentMethodImg}
          alt="payment method flexio"
          style={{ width: "50%" }}
        />
        <div style={{ width: "50%", padding: "20px" }}>
          <p>
            Before we can complete your Flexio Pool order, we need to add your payment
            method, you won&apos;t be charged until we pick up your package.
          </p>
          <div>
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <Checkbox checked={isChecked} onChange={handleCheckbox} />
            </span>
            <span style={{ marginLeft: "8px" }}>
              I understand that no charges will be made until Flexio successfully picks up
              my package.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px"
        }}
      ></div>
    </div>
  );
};

const PaymentMethodStep2 = ({
  clientSecret,
  paymentFormRef,
  handleNextStep,
  setOpenPaymentMethodModal,
  isLoading,
  setIsLoading,
  setFormSubmitted
}) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <img
          src={SecuredCardIcon}
          alt="Payment Icon"
          style={{ width: "30px", height: "auto", marginRight: "10px" }}
        />
        <h2
          style={{
            width: "100%",
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#1F3998"
          }}
        >
          Payment Information
        </h2>
      </div>

      <Spin size="large" spinning={isLoading} style={{}}>
        <Elements stripe={stripePromise}>
          {clientSecret ? (
            <PaymentForm
              clientSecret={clientSecret}
              paymentFormRef={paymentFormRef}
              handleNextStep={handleNextStep}
              setOpenPaymentMethodModal={setOpenPaymentMethodModal}
              setIsLoading={setIsLoading}
              setFormSubmitted={setFormSubmitted}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Elements>
      </Spin>
    </div>
  );
};

function Payment({ openPaymentMethodModal, setOpenPaymentMethodModal }) {
  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentMethodStep2, setIsPaymentMethodStep2] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const paymentFormRef = useRef();

  const handleExternalFormSubmit = useCallback(() => {
    if (paymentFormRef.current) {
      paymentFormRef.current.requestSubmit();
    }
  }, []);

  const fetchClientSecret = useCallback(async () => {
    try {
      const res = await request.get("/payment-method/request-intent");
      setClientSecret(res.clientSecret);
    } catch (error) {
      notification.error({
        message:
          "Card form unable to open at this moment. Please try again later or contact support."
      });
      setIsChecked(false);
      setIsPaymentMethodStep2(false);
      setOpenPaymentMethodModal(false);
    }
  }, [setOpenPaymentMethodModal]);

  const handleCheckbox = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, []);

  const handleNextStep = useCallback(() => {
    setIsPaymentMethodStep2(true);
    fetchClientSecret();
  }, [fetchClientSecret]);

  const modalFooter = useMemo(
    () => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px"
        }}
      >
        <> {!isPaymentMethodStep2 ? "1/2" : "2/2"}</>
        <Button
          className={`${isPaymentMethodStep2 ? "KeepItBlue" : ""}`}
          onClick={isPaymentMethodStep2 ? handleExternalFormSubmit : handleNextStep}
          disabled={
            (!isChecked && !isPaymentMethodStep2) ||
            (formSubmitted && isPaymentMethodStep2)
          }
          type={isPaymentMethodStep2 ? "submit" : "primary"}
          form={isPaymentMethodStep2 ? "PaymentForm" : ""}
        >
          {isPaymentMethodStep2 ? "Add Payment Method" : "Continue"}
        </Button>
      </div>
    ),
    [
      isPaymentMethodStep2,
      isChecked,
      handleExternalFormSubmit,
      handleNextStep,
      formSubmitted,
      isLoading
    ]
  );

  const handleModalCancel = useCallback(() => {
    setIsChecked(false);
    setIsPaymentMethodStep2(false);
    setOpenPaymentMethodModal(!openPaymentMethodModal);
  }, [openPaymentMethodModal, setOpenPaymentMethodModal]);

  return (
    <Modal
      visible={openPaymentMethodModal}
      onCancel={handleModalCancel}
      footer={modalFooter}
      maxWidth={700}
      width={700}
      centered
    >
      <div
        style={{
          padding: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px"
        }}
      >
        {!isPaymentMethodStep2 ? (
          <PaymentMethodStep1 isChecked={isChecked} handleCheckbox={handleCheckbox} />
        ) : (
          <PaymentMethodStep2
            clientSecret={clientSecret}
            paymentFormRef={paymentFormRef}
            handleNextStep={handleNextStep}
            setOpenPaymentMethodModal={setOpenPaymentMethodModal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setFormSubmitted={setFormSubmitted}
          />
        )}
      </div>
    </Modal>
  );
}

export default Payment;
