import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _trim from "lodash/trim";
import _map from "lodash/map";
import _find from "lodash/find";
import moment from "moment";
import { Button, Tooltip, notification } from "antd";
import * as XLSX from "xlsx";

import request from "helper/request";
import { QuestionIcon } from "components/svgs";
import { DATE_FORMAT_FRONTEND, DATE_FORMAT_API_BACKEND } from "configs/constants";
import { getAllVehicleCategories } from "modules/admin/settings/store/VehicleCategoryThunks";
import { selectAllVehicleCategories } from "modules/admin/settings/store/VehicleCategorySlice";
import "./ImportDriver.scss";

const REQUIRED_COLUMNS = [
  "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Country",
  "Postal Address",
  "Account Types",
  "Vehicle Types",
  "Vehicle Make",
  "Vehicle Model",
  "Vehicle Color",
  "Vehicle Year",
  "Number Plate",
  "Full Name of Bank Account Owner",
  "Email Address of Bank Account Owner",
  "Bank Name",
  "Bank Account Number",
  "Bank Account Routing Number",
  "Account Types",
  "SSN or EIN",
  "Name",
  "Address"
];
const PHONE_COLUMNS = ["Phone Number"];
const EMAIL_COLUMNS = ["Email", "Email Address of Bank Account Owner"];
const DATE_COLUMNS = [
  "Driver's License Expiration Date",
  "Driving Record Expiration Date",
  "Criminal Record Certificate Expiration Date",
  "Driver's Registration Expiration Date",
  "Vehicle Insurance Expiration Date"
];
const BOOLEAN_COLUMNS = ["Active"];
const VEHICLE_TYPES = "Vehicle Types";
const FLEXIO_VEHICLE_TYPES = "Flexio Vehicle Types";
const MAP_CSV_DATA = {
  "First Name": "firstName",
  "Last Name": "lastName",
  Email: "email",
  "Phone Number": "phone",
  Country: "country",
  City: "city",
  "Postal Address": "postalAddress",
  "Driver's License Expiration Date": "licenseExpirationDate",
  "Driving Record Expiration Date": "recordExpirationDate",
  "Criminal Record Certificate Expiration Date": "recordCertificateDate",
  "SSN or EIN": "taxSsnOrEin",
  Name: "taxName",
  Address: "taxAddress",
  "Full Name of Bank Account Owner": "fullNameBank",
  "Email Address of Bank Account Owner": "emailBank",
  "Bank Name": "bankName",
  "Bank Account Number": "bankAccountNumber",
  "Bank Account Routing Number": "bankAccountRoutingNumber",
  "Account Types": "accountType",
  "Vehicle Types": "vehicleTypes",
  "Vehicle Make": "vehicleMake",
  "Vehicle Model": "vehicleModel",
  "Vehicle Color": "vehicleColor",
  "Vehicle Year": "vehicleYear",
  "Number Plate": "numberPlate",
  "Driver's Registration Expiration Date": "registrationExpirationDate",
  "Vehicle Insurance Expiration Date": "insuranceExpirationDate",
  "Flexio Vehicle Types": "flexioVehicleTypes",
  Active: "active"
};

const formatDate = (date) =>
  moment(date, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_API_BACKEND);
const formatPhoneNumber = (phoneNumber) => {
  const trimmedPhone = _trim(phoneNumber);
  return (trimmedPhone?.indexOf("+") === -1 ? "+" : "") + trimmedPhone;
};
const mapBooleanValue = (val) => {
  const value = _trim(val || "").toUpperCase();
  if (value === "TRUE") return true;
  return false;
};

const ImportDriver = ({ importing, onImport }) => {
  const [error, setError] = useState(false);
  const [array, setArray] = useState([]);
  const [sampleFile, setSampleFile] = useState("");
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const vehicleCategories = useSelector(selectAllVehicleCategories) || [];

  useEffect(() => {
    const getSampleFile = async () => {
      setSampleFile(await request("/v1/files/2023-05-29/Drivers_Import_File.xlsx"));
    };
    dispatch(getAllVehicleCategories());
    getSampleFile();
    // eslint-disable-next-line
  }, []);

  const onImportExcel = (tmpFile) => {
    //  gets the uploaded file object
    const { files } = tmpFile.target;

    //  the file is read through the filereader object
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });

        let data = [];
        for (const sheet in workbook.Sheets) {
          // esline-disable-next-line
          if (workbook?.Sheets) {
            //  using sheet_to_json  method will be excel  into json  data
            data = data.concat(
              XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { raw: false })
            );
            const [resultArr, isError] = checkErrors(data);
            setError(isError);
            setArray(resultArr);
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    };

    //  open the file in binary mode
    fileReader.readAsBinaryString(files[0]);
  };

  const caseError = (item) => {
    let tpmItem = { ...item };
    let isError = false;

    PHONE_COLUMNS.forEach((column) => {
      if (tpmItem[column] === undefined) return;
      if (!/^[0-9()-*#+ -]+$/.test(_trim(tpmItem[column]))) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is invalid`]
            : [`${column} is invalid`]
        };
      }
    });

    EMAIL_COLUMNS.forEach((column) => {
      if (tpmItem[column] === undefined) return;
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          _trim(tpmItem[column])
        )
      ) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is invalid`]
            : [`${column} is invalid`]
        };
      }
    });

    DATE_COLUMNS.forEach((column) => {
      if (tpmItem[column] === undefined) return;
      if (
        !/^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
          _trim(tpmItem[column])
        )
      ) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is invalid`]
            : [`${column} is invalid`]
        };
      }
    });

    REQUIRED_COLUMNS.forEach((column) => {
      if (!item[column]) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is not exist`]
            : [`${column} is not exist`]
        };
      }
    });
    return [tpmItem, isError];
  };

  const checkErrors = (arr) => {
    let isError = false;
    let tmpArr = [...arr];
    tmpArr = tmpArr.map((ar) => {
      const [item, err] = caseError(ar);
      isError = isError || err;
      return item;
    });
    return [tmpArr, isError];
  };

  const handleOnChangeImport = (e) => {
    onImportExcel(e);
  };

  const clickChooseFile = (e) => {
    fileInputRef.current.click();
  };

  const mapVehicleCategoryIds = (importedVCNames = []) =>
    importedVCNames
      ?.map((name) => _find(vehicleCategories, { name })?.id)
      ?.filter((id) => id);

  const handleImport = async () => {
    const mappedData = _map(array, (curObj) => {
      const mappedObj = {};
      Object.keys(curObj).forEach((key) => {
        if (PHONE_COLUMNS.includes(key)) {
          mappedObj[MAP_CSV_DATA[key]] = formatPhoneNumber(curObj[key]);
        } else if (DATE_COLUMNS.includes(key)) {
          mappedObj[MAP_CSV_DATA[key]] = formatDate(curObj[key]);
        } else if (BOOLEAN_COLUMNS.includes(key)) {
          mappedObj[MAP_CSV_DATA[key]] = mapBooleanValue(curObj[key]);
        } else if (key === FLEXIO_VEHICLE_TYPES) {
          mappedObj[MAP_CSV_DATA[key]] = mapVehicleCategoryIds(JSON.parse(curObj[key]));
        } else if (key === VEHICLE_TYPES) {
          mappedObj[MAP_CSV_DATA[key]] = JSON.parse(curObj[key]);
        } else {
          mappedObj[MAP_CSV_DATA[key]] = curObj[key];
        }
      });
      return mappedObj;
    });
    const driverEmails = mappedData.map((item) => item.email);
    if (new Set(driverEmails || []).size !== driverEmails?.length) {
      notification.warning({
        message: "Driver emails are duplicated",
        placement: "topRight"
      });
      return;
    }
    onImport && onImport({ items: mappedData });
  };

  const headerKeys = Object.keys(Object.assign({}, ...array)).filter(
    (k) => k !== "errors"
  );

  return (
    <div className="import-locations-component m-3 pb-4">
      <input
        ref={fileInputRef}
        type={"file"}
        id={"csvFileInput"}
        accept={".xlsx, .xls"}
        onChange={handleOnChangeImport}
        className="hidden file-import"
      />
      <div className="">
        <div className="d-flex flex-row gap-2 ant-space-align-center">
          <Button
            className="btn-download svg-icon mb-3"
            size="small"
            onClick={clickChooseFile}
          >
            Choose file
          </Button>
          <Tooltip
            placement="right"
            overlayStyle={{ maxWidth: "600px" }}
            title={
              <span>
                Click here to import your own <b>.xlsx</b> file and add routes in bulk.
              </span>
            }
          >
            <div className="d-flex mb-3 ant-space-align-center">
              <QuestionIcon />
            </div>
          </Tooltip>
        </div>
        <div className="download-text mb-3">
          Download Sample File:{" "}
          <a target="_blank" href={sampleFile} rel="noopener noreferrer">
            sample.xlsx
          </a>
        </div>
      </div>
      <table id="client-table">
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th key={key} className="px-3 header-table">
                {key}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {array.map((item, idx) =>
            item.errors ? (
              <tr key={`import-driver-${idx}`} className={item.errors && "errors"}>
                {headerKeys.map((key) => (
                  <Tooltip placement="top" title={String(item.errors)} key={key}>
                    <td className="px-3">{item[key]}</td>
                  </Tooltip>
                ))}
              </tr>
            ) : (
              <tr key={`import-driver-${idx}`}>
                {headerKeys.map((key) => (
                  <td className="px-3" key={key}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
      {!error && array?.length > 0 && (
        <div className="d-flex flex-justify-end">
          <Button
            type="primary"
            className="btn-download svg-icon mb-3"
            onClick={handleImport}
            disabled={importing}
          >
            Import
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImportDriver;
