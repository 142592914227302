import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../modules/auth/store/authSlice";
import MainLayout from "../layouts/private/MainLayout";
import { UserGroup } from "configs/constants";
import { ProfileProvider } from "layouts/private/ProfileContext";
import { FeatureFlagsProvider } from "layouts/private/FeatureFlagsContext";

const PrivateUserRoute = (props) => {
  const { component: Component, isFullScreen = false, ...rest } = props;
  const authentication = useSelector(selectAuthentication);
  const group = useSelector(selectGroup);
  if (!authentication || group !== UserGroup.CLIENT) {
    return (
      <Redirect
        to={{
          pathname: `/login`
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(propsCom) => {
        return (
          <FeatureFlagsProvider>
            <ProfileProvider>
              <MainLayout isFullScreen={isFullScreen}>
                <Component {...propsCom} />
              </MainLayout>
            </ProfileProvider>
          </FeatureFlagsProvider>
        );
      }}
    />
  );
};
export default PrivateUserRoute;
