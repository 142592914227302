import React from "react";
import { Form, Input, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const ChangePasswordForm = ({ form, isFetching, handleSubmit }) => {
  return (
    <div className="common-form change-password-form">
      <h3>Change Password</h3>
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password size="large" placeholder="Current Password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password size="large" placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password size="large" placeholder="Confirm Password" />
        </Form.Item>
        <div className="form-footer">
          <Button loading={isFetching} type="primary" htmlType="submit">
            Save
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
