/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TableBase from "../../../../../components/table/TableBase";

const FailedRegistrationTable = ({ dataSource, pagination }) => {
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}>{value}</p>;
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName"
    },
    {
      title: "Last Name",
      dataIndex: "lastName"
    },
    {
      title: "Company Name",
      dataIndex: "companyName"
    },
    {
      title: "Market",
      dataIndex: "market"
    },
    {
      title: "Country",
      dataIndex: "country"
    }
  ];
  return (
    <TableBase columns={columns} data={dataSource} pagination={pagination} rowKey="id" />
  );
};
export default FailedRegistrationTable;
