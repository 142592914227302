import React from "react";
import { Card, Select } from "antd";
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  BarController,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip
);

const RevenueChart = () => {
  return (
    <div className="chart-revenue">
      <Card>
        <div
          className="d-flex m-3"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="chart-header">
            <h1>Overview</h1>
            <p>Monthly Earning</p>
          </div>
          <div>
            <Select
              defaultValue="quarterly"
              // onChange={handleChange}
              options={[
                { value: "weekly", label: "Weekly" },
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
                { value: "quarterly", label: "Quarterly" }
              ]}
            />
          </div>
        </div>
        <Chart
          type="bar"
          data={{
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Agu",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            datasets: [
              {
                label: "Revenue",
                data: [
                  44050, 48000, 37405, 44394, 44200, 44050, 48000, 37405, 44394, 44200,
                  44050, 48000
                ],
                backgroundColor: [
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60"
                ],
                barPercentage: 0.8,
                borderColor: "#00000000",
                fill: false,
                hoverBackgroundColor: "#45c9ed",
                borderRadius: 10
              }
            ]
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true,
                position: "nearest"
              }
            },
            scales: {
              x: {
                border: {
                  color: "#00000000"
                },
                grid: {
                  display: false
                }
              },
              y: {
                display: false,
                grid: {
                  display: false
                }
              }
            }
          }}
        />
      </Card>
    </div>
  );
};

export default RevenueChart;
