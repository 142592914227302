import React from "react";
const AddIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 11.8133H1.33333V3.66659H6V2.33325H1.33333C0.6 2.33325 0 2.93325 0 3.66659V11.6666C0 12.3999 0.6 12.9999 1.33333 12.9999H9.33333C10.0667 12.9999 10.6667 12.3999 10.6667 11.6666V6.99992H9.33333V11.8133Z"
        fill="white"
      />
      <path
        d="M10.6666 0.333252H9.33325V2.33325H7.33325C7.33992 2.33992 7.33325 3.66659 7.33325 3.66659H9.33325V5.65992C9.33992 5.66659 10.6666 5.65992 10.6666 5.65992V3.66659H12.6666V2.33325H10.6666V0.333252Z"
        fill="white"
      />
      <path d="M8.00008 5H2.66675V6.33333H8.00008V5Z" fill="white" />
      <path d="M2.66675 7V8.33333H8.00008V7H6.00008H2.66675Z" fill="white" />
      <path d="M8.00008 9H2.66675V10.3333H8.00008V9Z" fill="white" />
    </svg>
  );
};
export { AddIcon };
