import React from "react";
import { formatToUSD } from "helper/util";
import { CheckCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

export default function SubscriptionCTA({ content, onSubmit }) {
  return (
    <div className="pl-4 pr-3 pb-3" style={subscriptionContainer}>
      <div className="pl-2 pr-2" style={subscriptionPriceHeader}>
        <div>
          <h2 className="m-0 text-bold" style={{ fontSize: "1.5em" }}>
            {content?.tierType}
          </h2>
          <div className="mt-2">
            <div>2 Drivers minimun requirement</div>
            {formatToUSD(content?.amount * 2)} / mo minimum
          </div>
        </div>
        <div>
          <div className="text-center">
            <h1 className="m-0" style={{ fontSize: "3.5em", fontWeight: "bold" }}>
              ${content?.amount}
            </h1>
            <p className="m-0">per driver / mo</p>
            <Button
              className="mt-1"
              style={subButtonStyle}
              htmlType="submit"
              onClick={() => onSubmit(content.id)}
            >
              Start Free Trial
            </Button>
          </div>
        </div>
      </div>
      <div>
        <AdditionalFeatures features={additionalFeatures} />
      </div>
    </div>
  );
}

const additionalFeatures = [
  <span key={1}>
    Unlimited optimized routes for you internal drivers using the <b>Routing Engine</b>.
  </span>,
  <span key={2}>
    Use our <b>Web & Driver App</b> to create routes and assigned them to your fleet.
  </span>,
  <span key={3}>
    Check your driver{"'"}s locations by using our <b>Live Driver Tracker</b>.
  </span>,
  <span key={4}>
    Keep your clients informed by using our limited <b>Notification Modules</b> for text
    messages and emails
  </span>,
  <span key={5}>
    Take leverage of our <b>Address Validation Logic</b> to confirm and validate your
    destination addresses
  </span>,
  <span key={6}>
    Sign up to <b>3 Users</b> to manager your fleet and create optimized routes.
  </span>,
  <span key={7}>
    Access our Flexio Delivery Services if your workload is more than what your internal
    fleet can handle.
  </span>
];

const AdditionalFeatures = ({ features }) => {
  return (
    <ul className="pl-2">
      {features.map((feature, featureIdx) => {
        return (
          <li key={featureIdx} style={featureStyle}>
            <CheckCircleFilled style={{ color: "#78b254", margin: "0 10px 0 0" }} />
            {feature}
          </li>
        );
      })}
    </ul>
  );
};

const subscriptionContainer = {
  border: "solid 1px #000"
};

const subscriptionPriceHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexFlow: "row wrap"
};

const subButtonStyle = {
  marginLeft: "50%",
  transform: "translateX(-50%)",
  borderRadius: 30,
  border: 0,
  background: "#0e72e6",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px"
};

const featureStyle = {
  padding: "0.5em 0",
  listStyle: "none"
};
