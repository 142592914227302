import request from "../../../../helper/request";

const userApi = {
  getUserList: (params) => {
    console.log(params);
    return request.get("/admin/users", {
      params
    });
  },

  createClientUser: (data) => {
    return request.post("/admin/users", data);
  },

  createTerritoryUser: (data) => {
    return request.post("/admin/users/user-territory", data);
  },

  createAdminUser: (data) => {
    return request.post("/admin/users/user-admin", data);
  },

  createAdminUserPassword: (data) => {
    return request.post("/admin/users/create-password", data);
  },

  getUser: (id) => {
    return request.get(`/admin/users/${id}`);
  },

  updateUser: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/users/${id}`, data);
  },

  updateUserTerritory: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/users/user-territory/${id}`, data);
  },
  updateUserAdmin: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/users/user-admin/${id}`, data);
  },

  deleteUser: (data) => {
    return request.delete(`/admin/users/${data.id}`);
  }
};

export default userApi;
