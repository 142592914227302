import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { decodePolyline } from "modules/shared/PickupDropoff/decodePolyline";
import {
  assignDriver,
  cancelRoute,
  doGetRoute,
  doGetRoutesDispatch,
  getRouteList,
  reassignDriver
} from "./thunks";

const RoutesAdapter = createEntityAdapter({
  selectId: (route) => route.id
});

const initialState = RoutesAdapter.getInitialState({
  metadata: {},
  history: {
    metadata: {}
  },
  filter: {
    status: ["pending", "accepted", "in-progress"]
  }
});

const mapRoutes = (routes) => {
  return routes.map((route) => ({
    ...route,
    coords: route?.polylines ? decodePolyline(route.polylines).coords : [],
    display: true,
    lat: route?.startAddressLat,
    lng: route?.startAddressLng
  }));
};

export const mapLocations = (routes) => {
  const stops = [];
  let sortNoUnscheduled = 1;
  routes.map((route) => {
    route.routeLocations.forEach((routeLocation) => {
      const isUnscheduled = route?.name === "unscheduled" || !routeLocation.job?.orderId;
      stops.push({
        ...routeLocation,
        ...routeLocation.location,
        jobId: routeLocation.jobId || routeLocation.location?.jobId,
        sortNo: isUnscheduled ? sortNoUnscheduled : routeLocation?.sortNo,
        routeId: isUnscheduled ? "unscheduled" : route.id,
        color: route?.color || "#c4c4c4",
        orderId: route?.orderId,
        display: true
      });
      if (route?.name === "unscheduled") {
        sortNoUnscheduled += 1;
      }
    });
  });
  return stops;
};

export const routeSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    updatePaginationRoutes(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterRoutes(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRouteList.fulfilled, (state, { payload }) => {
      RoutesAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });

    builder.addCase(doGetRoute.fulfilled, (state, { payload }) => {
      state.route = {
        ...state.route,
        ...payload
      };
    });
    builder.addCase(doGetRoutesDispatch.fulfilled, (state, { payload }) => {
      state.routeDispatch = {
        ...payload,
        routes: mapRoutes([payload]),
        locations: mapLocations([payload])
      };
    });
    builder.addCase(assignDriver.fulfilled, (state, { payload }) => {
      RoutesAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(reassignDriver.fulfilled, (state, { payload }) => {
      RoutesAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(cancelRoute.fulfilled, (state, { payload }) => {
      RoutesAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
  }
});

export const {
  selectById: selectRouteById,
  selectIds: selectRouteIds,
  selectEntities: selectRouteEntities,
  selectAll: selectAllRoutes,
  selectTotal: selectTotalRoutes
} = RoutesAdapter.getSelectors((state) => {
  return state.routes;
});

export const selectMetadata = (state) => state.routes.metadata;
export const selectFilter = (state) => state.routes.filter;
export const selectRoute = (state) => state.routes.route;
export const selectRouteDispatch = (state) => state.routes.routeDispatch;

export const {
  updateFilterRoutes,
  updatePaginationRoutes,
  saveAsDraftNewRoute,
  resetNewRoute
} = routeSlice.actions;

export default routeSlice.reducer;
