/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "components/AdminBreadCrumb";
import { FilterIcon } from "components/svgs";

const BroadcastHeader = ({ breadCrumbs, buttonParams, hasButton = true }) => {
  return (
    <div className="d-flex flex-items-center flex-justify-between mb-4">
      {breadCrumbs && <AdminBreadCrumb breadCrumbs={breadCrumbs} />}
      {hasButton && (
        <div className="d-flex flex-justify-end gap-3">
          <Button size="small" className="svg-icon d-flex flex-row">
            Filter <FilterIcon />
          </Button>
          {buttonParams && (
            <div className="buttons">
              <Link to={buttonParams.link}>
                <Button size="small" type="primary">
                  {buttonParams.text}
                  <PlusOutlined />
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default BroadcastHeader;
