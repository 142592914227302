import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import { Modal, message, Spin } from "antd";
import * as firebaseDB from "firebase/database";

import { convertObjectToArray } from "helper/util";
import withFullHeight from "hocs/withFullHeight";
import { selectAllVehicleTypes } from "modules/client/vehicle-types/store/slice";
import { getVehicleTypeList } from "modules/client/vehicle-types/store/thunks";
import ChartContainer from "modules/shared/PickupDropoff/chart/ChartContainer";
import { convertJobsToLocations } from "modules/shared/PickupDropoff/helper";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import { ONE_HOUR_PX } from "modules/shared/PickupDropoff/constants";
import { setGlobalLoading } from "app/global/slice";
import { withPermission } from "hocs/withPermission";
import { UserRole } from "configs/constants";
import { realtimeDB } from "configs/firebaseConfig";
import clientApi from "modules/admin/clients/store/api";
import { setError } from "app/global/slice";

import ProgressModal from "../components/ProgressModal";

import {
  dataJobTypeOptimize,
  deleteShiftStart,
  filterValidRoutes,
  handleRoutesOptimize
} from "../../../client/active-orders/helper";
import "modules/client/active-orders/pages/ConfirmRoutePage.scss";

import {
  STATE_STATUSES,
  saveAsDraftNewOrder,
  selectDraftId,
  selectNewOrder,
  selectOrderState,
  resetNewOrderStatuses
} from "../../../client/active-orders/store/orderSlice";
import {
  doOptimizeOrder,
  doOptimizeOrderCallback,
  doSortOrderCallback,
  doStoreDraftId
} from "../../../client/active-orders/store/thunks";

const ConfirmRoutePageAdmin = ({ isAdmin }) => {
  const [isSelectRouteNumber, setSelectRouteNumber] = useState(false);
  const [numberRoutes, setNumberRoutes] = useState(30);
  const [showOptimizing, setShowOptimizing] = useState(false);
  const [errorMesage, setErrorMessage] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  // const [tmpFleet, setTmpFleet] = useState([]);
  const [oneHourPx, setOneHourPx] = useState(ONE_HOUR_PX);
  const [client, setClient] = useState();

  const [stops, setStops] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const historyRoute = useHistory();

  const newOrder = useSelector(selectNewOrder);
  const orderState = useSelector(selectOrderState);
  const vehicleCategoryList = useSelector(selectAllVehicleTypes);
  const draftIdSelector = useSelector(selectDraftId);
  const clientId = newOrder?.clientId;

  const dispatch = useDispatch();

  // PROGRESS MODAL
  const [isModalVisible, setIsModalVisible] = useState(true);

  const fetchClient = useCallback(async () => {
    try {
      const response = await clientApi.getClient(clientId);
      setClient(response);
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  useEffect(() => {
    if (orderState?.routeEngineStatus !== STATE_STATUSES.COMPLETED || !draftIdSelector)
      return;

    const dataPathRef = `/optimizing-ordering-completed/${String([
      user.sub,
      draftIdSelector
    ])}`;
    const driverLocationRef = firebaseDB.ref(realtimeDB, dataPathRef);

    firebaseDB.onValue(driverLocationRef, (snapshot) => {
      const res = snapshot.val();
      if (!res || !vehicleCategoryList?.length) return;

      if (res?.payload?.data?.error) {
        setErrorMessage(res?.payload?.data?.error);
        setShowOptimizing(false);
        messageApi.open({
          type: "error",
          content: res?.payload?.data?.error,
          duration: 5000
        });
        dispatch(setGlobalLoading({ isLoading: false }));
        return;
      }

      if (res.event === "routeDraft.optimizing.completed") {
        console.log("[COMPLETED] optimizing routes");
        console.log("vehicleCategoryList: ", vehicleCategoryList);
        const { input } = res.payload.data;
        const routes = newOrder?.routes ? newOrder?.routes : input.fleet;
        // const routes = input.fleet;
        dispatch(
          doOptimizeOrderCallback({
            ...res.payload.data,
            order: {
              ...newOrder,
              routes
            },
            vehicleCategoryList,
            isSelectRouteNumber,
            numberRoutes
          })
        );
        setSelectRouteNumber(false);
      }
      if (res.event === "routeDraft.sorting.completed") {
        console.log("[COMPLETED] sorting routes");
        const { input } = res.payload.data;
        const routes = newOrder?.routes ? newOrder?.routes : input.fleet;

        dispatch(
          doSortOrderCallback({
            ...res.payload.data,
            order: {
              ...newOrder,
              routes
            },
            vehicleCategoryList,
            isSelectRouteNumber,
            numberRoutes
          })
        );
        setSelectRouteNumber(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState?.routeEngineStatus, draftIdSelector]);

  useEffect(() => {
    const jobs = convertObjectToArray(newOrder?.jobs);
    const tmpStops = convertJobsToLocations(jobs);
    setStops(tmpStops);
  }, [newOrder?.jobs]);

  useEffect(() => {
    const arrRoutes = convertObjectToArray(newOrder?.routes);

    if (!isSelectRouteNumber) setNumberRoutes(arrRoutes.length);
    // eslint-disable-next-line
  }, [newOrder?.routes]);

  useEffect(() => {
    dispatch(getVehicleTypeList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { routeEngineStatus, status } = orderState || {};

    if (errorMesage) return;

    if (
      [routeEngineStatus, status].includes(STATE_STATUSES.PROCESSING) ||
      (routeEngineStatus === STATE_STATUSES.COMPLETED &&
        status !== STATE_STATUSES.COMPLETED)
    ) {
      console.log("[START] optimizing or sorting");
      dispatch(setGlobalLoading({ isLoading: true }));
      setShowOptimizing(true);
    } else {
      console.log("[END] optimizing or sorting");
      setShowOptimizing(false);
    }
    // eslint-disable-next-line
  }, [orderState?.routeEngineStatus, orderState?.status]);

  useEffect(() => {
    if (!newOrder?.jobs) {
      historyRoute.push("/orders/new");
    }

    if (!vehicleCategoryList.length) return;
    if (newOrder?.isConfirmRoute === false) {
      const tmpJobs = convertObjectToArray(newOrder?.jobs);
      const visits = dataJobTypeOptimize(tmpJobs);
      const number = Math.ceil((tmpJobs.length || 1) / client?.preferredNoOfStops) || 1;
      let fleet = handleRoutesOptimize(newOrder, number, newOrder?.routes);
      const fleetParam = deleteShiftStart(fleet);
      // setTmpFleet(fleet);
      dispatch(
        saveAsDraftNewOrder({
          data: {
            ...newOrder,
            isConfirmRoute: false
          }
        })
      );
      if (!orderState.status && !orderState.routeEngineStatus && client) {
        console.log("Call optimize order at ConfirmRoutePage");
        const draftId = new Date().getTime();
        dispatch(doStoreDraftId(draftId));
        dispatch(
          doOptimizeOrder({
            userId: user?.sub,
            draftId,
            visits,
            fleet: fleetParam,
            options: {
              polylines: true,
              balance: true,
              distance_type: "distance_travelled"
            },
            order: {
              ...newOrder,
              routes: fleetParam
            },
            vehicleCategoryList,
            tags: [`client_id_${clientId}`]
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleCategoryList, client]);

  const redirectToNewJobsPage = () => {
    isAdmin ? historyRoute.push("/admin/jobs/new") : historyRoute.push("/orders/new");
  };

  const handleConfirmRoute = () => {
    dispatch(
      saveAsDraftNewOrder({
        data: {
          ...newOrder,
          routes: filterValidRoutes(newOrder.routes),
          isConfirmRoute: true
        }
      })
    );
    redirectToNewJobsPage();
  };

  const handleCancelBack = () => {
    dispatch(resetNewOrderStatuses());
    redirectToNewJobsPage();
  };

  useEffect(() => {
    return () => {
      if (historyRoute.action === "POP") {
        handleCancelBack();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZoomIn = () => {
    setOneHourPx(oneHourPx + oneHourPx / 10);
  };
  const handleZoomOut = () => {
    setOneHourPx(oneHourPx - oneHourPx / 10);
  };

  if (!newOrder?.jobs) {
    return <div />;
  }

  return (
    <div className="confirm-route-page">
      {contextHolder}
      <PickupDropoffContainer
        stops={stops}
        routes={convertObjectToArray(newOrder?.routes).map((r) => ({
          ...r,
          lat: r.lat || r.startAddressLat,
          lng: r.lng || r.startAddressLng,
          routeLocations: stops?.filter((s) => s.routeId === r.id)
        }))}
        groupName="type"
        onRedirectToNewJobsPage={redirectToNewJobsPage}
        onCancelBack={handleCancelBack}
        oneHourPx={oneHourPx}
      >
        {({
          handleUpdateAllRoutes,
          handleUpdateRoute,
          handleClickMarker,
          handleHoverMarker,
          popupData,
          handleClosePopup,
          goToCoupleStop,
          handleStopDetail,
          markerActive,
          center,
          containerStops,
          originStops,
          containerRoutes,
          mapStops,
          bounds,
          displayAll,
          driverDetail,
          setDriverDetail,
          showDriverDetail,
          setShowDriverDetail,
          groupStops,
          onDragEnd,
          onDragStart,
          handleClickHistory,
          history,
          earliestRoute,
          disabledNextButton,
          disabledPreviousButton,
          dndStatus,
          onRedirectToNewJobsPage
        }) => (
          <>
            <div className={"confirm-route-title"}>Confirm Route for Estimate</div>
            <MapBox
              handleClosePopup={handleClosePopup}
              handleClickMarker={handleClickMarker}
              handleHoverMarker={handleHoverMarker}
              stops={mapStops}
              height={600}
              center={center}
              routes={containerRoutes}
              popupData={popupData}
              goToCoupleStop={goToCoupleStop}
              markerActive={markerActive}
              bounds={bounds}
              driverDetail={driverDetail}
              setDriverDetail={setDriverDetail}
              showDriverDetail={showDriverDetail}
              setShowDriverDetail={setShowDriverDetail}
            />
            <ChartContainer
              isSelectRouteNumber={isSelectRouteNumber}
              setSelectRouteNumber={setSelectRouteNumber}
              numberRoutes={numberRoutes}
              setNumberRoutes={setNumberRoutes}
              originStops={originStops}
              handleStopDetail={handleStopDetail}
              handleConfirmRoute={handleConfirmRoute}
              routes={containerRoutes}
              stops={containerStops}
              order={newOrder}
              handleClickMarker={handleClickMarker}
              handleHoverMarker={handleHoverMarker}
              handleClosePopup={handleClosePopup}
              vehicleCategoryList={vehicleCategoryList}
              handleUpdateRoute={handleUpdateRoute}
              handleUpdateAllRoutes={handleUpdateAllRoutes}
              displayAll={displayAll}
              setDriverDetail={setDriverDetail}
              setShowDriverDetail={setShowDriverDetail}
              groupStops={groupStops}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
              handleClickHistory={handleClickHistory}
              history={history}
              earliestRoute={earliestRoute}
              disabledNextButton={disabledNextButton}
              disabledPreviousButton={disabledPreviousButton}
              dndStatus={dndStatus}
              onRedirectToNewJobsPage={onRedirectToNewJobsPage}
              onCancelBack={handleCancelBack}
              oneHourPx={oneHourPx}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              showConfirmButton={errorMesage ? false : true}
            />
          </>
        )}
      </PickupDropoffContainer>
      {showOptimizing && (
        <Modal
          centered
          zIndex={9900}
          closable={false}
          visible={true}
          footer={false}
          width={360}
        >
          <div className="optimizing-modal-content">
            <span>We are working on creating the</span>
            <span>most efficient routes!</span>
            <br />
            <span>Please wait a moment.</span>
            <br />
            <ProgressModal
              numStops={newOrder.dropoffs.length}
              visible={isModalVisible}
              handleCancel={handleCancelBack}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default withPermission(withFullHeight(ConfirmRoutePageAdmin, 520), [
  UserRole.ADMIN,
  UserRole.MANAGER
]);
