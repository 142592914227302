import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { markerLayer, markerIcon } from "../settings";

const icon = markerIcon();
const RouteLayer = ({
  routes,
  handleClickMarker,
  isShowViewMore = true,
  markerActive
}) => {
  const setCursor = (map, cursor) => {
    map.getCanvas().style.cursor = cursor;
  };

  return (
    <Layer {...markerLayer("driver", icon["driver"])}>
      {routes
        .filter((driver) => driver.display)
        .filter((driver) => {
          if (markerActive === undefined || markerActive?.makerType === "driver") {
            return driver;
          }
        })
        .map((driver) => {
          return (
            <Feature
              key={driver.id}
              coordinates={[driver.lng, driver.lat]}
              onClick={() =>
                handleClickMarker({ ...driver, showViewMore: isShowViewMore }, "driver")
              }
              onMouseEnter={({ map }) => {
                setCursor(map, "pointer");
              }}
              onMouseLeave={({ map }) => {
                setCursor(map, "");
              }}
              properties={{
                label: "B",
                color: driver?.color || "#C4C4C4",
                colorText: "#FFFFFF"
              }}
            />
          );
        })}
    </Layer>
  );
};
export default RouteLayer;
