import React from "react";
const GroupIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99167 0.666656C4.39167 0.666656 0.658335 4.39999 0.658335 8.99999C0.658335 13.6 4.39167 17.3333 8.99167 17.3333C13.5917 17.3333 17.325 13.6 17.325 8.99999C17.325 4.39999 13.5917 0.666656 8.99167 0.666656ZM12 5.94999C12.8917 5.94999 13.6083 6.66666 13.6083 7.55832C13.6083 8.44999 12.8917 9.16666 12 9.16666C11.1083 9.16666 10.3917 8.44999 10.3917 7.55832C10.3833 6.66666 11.1083 5.94999 12 5.94999ZM7 4.63332C8.08333 4.63332 8.96667 5.51666 8.96667 6.59999C8.96667 7.68332 8.08333 8.56666 7 8.56666C5.91667 8.56666 5.03333 7.68332 5.03333 6.59999C5.03333 5.50832 5.90834 4.63332 7 4.63332ZM7 12.2417V15.3667C5 14.7417 3.41667 13.2 2.71667 11.2333C3.59167 10.3 5.775 9.82499 7 9.82499C7.44167 9.82499 8 9.89166 8.58333 10.0083C7.21667 10.7333 7 11.6917 7 12.2417ZM8.99167 15.6667C8.76667 15.6667 8.55 15.6583 8.33333 15.6333V12.2417C8.33333 11.0583 10.7833 10.4667 12 10.4667C12.8917 10.4667 14.4333 10.7917 15.2 11.425C14.225 13.9 11.8167 15.6667 8.99167 15.6667Z"
        fill="white"
      />
    </svg>
  );
};
export { GroupIcon };
