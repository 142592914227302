import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Radio, Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import React, { useEffect, useState } from "react";
import _find from "lodash/find";
import _flattenDeep from "lodash/flatMapDeep";
import _ from "lodash";

import "./ClientForm.scss";
import { useHistory } from "react-router-dom";
import { selectAllDrivers } from "../../../drivers/store/slice";
import { getDriverList } from "modules/admin/drivers/store/thunks";
import { doCreateClient, doUpdateClient } from "../../store/thunks";
import { fullName } from "helper/util";
import { MARKET_OPTIONS } from "configs/constants";
import { INDUSTRY_OPTIONS } from "configs/constants";
import DefaultMapbox from "modules/client/active-orders/components/mapbox/DefaultMapbox";
import AddressInput from "components/inputs/AddressInput";
import { City, State } from "country-state-city";
import { PUERTO_RICO_STATES_AND_CITIES } from "modules/shared/pr-states-cities";
import { ArrowDownIcon } from "components/svgs";
import { selectAllServices } from "modules/admin/settings/store/ServiceSlice";
import { getServiceList } from "modules/admin/settings/store/ServiceThunks";
import { INTERNAL_DRIVER_SERVICE } from "configs/constants";
import { loadAllCountries } from "helper/util";

const clientInit = {};
const { Option } = Select;
const defaultOptionName = "Please Select";
const isoCodePR = "PR";

const ClientForm = ({ client }) => {
  let driversList = useSelector(selectAllDrivers);
  let servicesList = useSelector(selectAllServices);
  const [countryStates, setCountryStates] = useState([]);
  const [stateCities, setStateCities] = useState([]);
  const [currentAddress, setCurrentAddress] = useState();
  const [manualInvoice, setManualInvoice] = useState(false);

  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const servicesOptions = servicesList
    .filter((item) => item.name && !item.isDefault)
    ?.map((service) => ({
      label: service.name,
      value: service.id
    }));
  const history = useHistory();
  const dispatch = useDispatch();

  if (client) {
    driversList = [...driversList, ...client.drivers];
    servicesList = [...servicesList, client.services];
  }
  // This should be "Cancel" not Canle
  const handleCanle = () => {
    history.push("/admin/clients");
  };
  const onSubmit = async (values) => {
    try {
      let erRs = undefined;
      const { address, lngLat } = currentAddress;
      const payload = {
        ...values,
        address,
        lng: Number(lngLat[0]),
        lat: Number(lngLat[1]),
        services: values.services?.map((serviceId) => ({ id: serviceId }))
      };
      if (!client?.id) {
        const { error } = await dispatch(doCreateClient(payload));
        erRs = error;
      } else {
        const { error } = await dispatch(
          doUpdateClient({
            ...payload,
            id: client.id,
            driverIds: values.driverIds,
            manualInvoice
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/clients");
    } catch {
      return false;
    }
  };

  useEffect(() => {
    dispatch(getDriverList({ pageSize: 1000 }));
    dispatch(getServiceList());
    let dataForm = clientInit;
    if (client) {
      const driverIds = client.drivers.map((driver) => driver.id);
      const serviceIds = client.services?.map((service) => service.id);
      dataForm = { ...client, driverIds, services: serviceIds };
      setCurrentAddress({
        address: client.address,
        lngLat: [client.lng, client.lat]
      });
    }
    setManualInvoice(client?.manualInvoice);
    form.setFieldsValue(dataForm);
    // eslint-disable-next-line
  }, [client, form]);

  const onChangeCountry = async (isoCodeCountry) => {
    if (!isoCodeCountry) return;
    form.setFieldsValue({ state: defaultOptionName });
    form.setFieldsValue({ city: defaultOptionName });
    let stateList = [];
    if (isoCodeCountry === isoCodePR) {
      stateList = PUERTO_RICO_STATES_AND_CITIES.map((item, idx) => ({
        name: item.state,
        isoCode: `${isoCodePR}-${idx}`
      }));
    } else {
      stateList = await State.getStatesOfCountry(isoCodeCountry);
    }
    setCountryStates(stateList);
  };

  const onChangeState = (stateName) => {
    const isoCodeState = _find(countryStates, { name: stateName })?.isoCode;

    if (!isoCodeState) return;
    form.setFieldsValue({ city: defaultOptionName });
    const isoCodeCountry = form.getFieldValue("country");
    let cities = [];
    if (isoCodeCountry === isoCodePR) {
      cities = _flattenDeep(
        PUERTO_RICO_STATES_AND_CITIES.filter((item) => item.state === stateName).map(
          (filteredItem) => [...filteredItem.cities]
        )
      ).map((city) => ({ name: city }));
    } else {
      cities = City.getCitiesOfState(isoCodeCountry, isoCodeState);
    }
    setStateCities(cities);
  };

  const handleSetAddress = (value) => {
    setCurrentAddress({ address: value.address, lngLat: value.lngLat });
  };

  const filterDriver = (name) => {
    if (!name) return;
    dispatch(getDriverList({ filter: [`name||$cont||${name}`] }));
  };

  const debouncedFilterDriver = _.debounce((input) => {
    filterDriver(input);
  }, 1000);

  return (
    <div className="ClientForm">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Company Name"
            name="clientName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            label="Support Email"
            name="supportEmail"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input size="large" placeholder="Support Email" />
          </Form.Item>
          <Form.Item label="Support Phone" name="supportPhone">
            <PhoneInput country="ca" enableSearch />
          </Form.Item>
          <Form.Item
            label="Accounting Email"
            name="accountingEmail"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input size="large" placeholder="Accounting Email" />
          </Form.Item>
          <Form.Item label="Manual Invoice" name="manualInvoice">
            <Switch
              checked={manualInvoice}
              onChange={() => setManualInvoice(!manualInvoice)}
            />
          </Form.Item>
          <Form.Item
            label="Market"
            name="market"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select size="large" placeholder="Please Select">
              {MARKET_OPTIONS.map((market) => (
                <Select.Option key={market} value={market}>
                  {market}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Industry" name="industry">
            <Select
              size="large"
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option value="">Please Select</Select.Option>
              {INDUSTRY_OPTIONS.map((industry) => (
                <Select.Option key={industry} value={industry}>
                  {industry}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Address Field 1" name="addressField1">
            <Input size="large" placeholder="Address Field 1" />
          </Form.Item>
          <Form.Item label="Address Field 2" name="addressField2">
            <Input size="large" placeholder="Address Field 1" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              size="large"
              placeholder="Please Select"
              onChange={onChangeCountry}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
            >
              {loadAllCountries().map((country) => (
                <Select.Option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select
              size="large"
              placeholder="Please Select"
              onChange={onChangeState}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
            >
              {[{ name: defaultOptionName, isoCode: "" }, ...countryStates].map(
                (state) => (
                  <Select.Option key={`state-${state.isoCode}`} value={state.name}>
                    {state.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item label="City" name="city">
            <Select
              size="large"
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
            >
              {[{ name: defaultOptionName }, ...stateCities].map((city) => (
                <Select.Option key={`city-${city.name}`} value={city.name}>
                  {city.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Zip Code" name="postalAddress">
            <Input size="large" placeholder="Zip Code" />
          </Form.Item>
          <Form.Item
            label="Map Address"
            name="address"
            rules={[{ required: true, message: "Required" }]}
          >
            <AddressInput
              placeholder="Address"
              onSelect={(value) => handleSetAddress(value)}
              address={currentAddress}
            />
          </Form.Item>
          <div className="map">
            <DefaultMapbox address={currentAddress} setAddress={handleSetAddress} />
          </div>
          <Form.Item label="Dedicated Drivers" name="driverIds">
            <Select
              mode="multiple"
              placeholder="Please Select Drivers"
              allowClear
              showSearch
              optionFilterProp="children"
              onSearch={(input) => {
                debouncedFilterDriver(input);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {driversList?.map((driverList) => {
                if (driverList.active === true)
                  return (
                    <Option key={driverList.id} value={driverList.id}>
                      {fullName(driverList)}
                    </Option>
                  );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Choose which Services will be specific values"
            placeholder="Services"
            name="services"
          >
            <Select
              showSearch
              suffixIcon={<ArrowDownIcon />}
              mode="multiple"
              allowClear
              options={servicesOptions}
              placeholder="Choose a service"
            />
          </Form.Item>
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCanle}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {client ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default ClientForm;
