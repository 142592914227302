import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AdditionalVehicleForm from "modules/admin/drivers/forms/AdditionalVehicleForm";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import request from "helper/request";
import { Spin } from "antd";
import { useParams, useHistory } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import { setSuccessMessage, setError } from "app/global/slice";
import { useDispatch } from "react-redux";

const AddAdditionalVehicle = () => {
  const profileDriverCtx = useContext(DriverProfileContext);
  const fetchDriverProfile = profileDriverCtx.fetchProfile;
  const [loading, setLoading] = useState(false);
  const [additionalVehicle, setAdditionalVehicle] = useState({});
  const params = useParams();
  const { additionalVehicleId } = params || {};
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!additionalVehicleId) return;
    getAdditionalVehicle();
  }, []);

  const getAdditionalVehicle = useCallback(async () => {
    try {
      const response = await request.get(
        `mobile/additional-vehicles/${additionalVehicleId}`
      );
      setAdditionalVehicle(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleAddAdditionalVehicle = useCallback(async (values) => {
    try {
      setLoading(true);

      const dataSubmit = {
        vehicleTypes: values?.vehicleTypes,
        vehicleMake: values?.vehicleMake,
        vehicleModel: values?.vehicleModel,
        vehicleColor: values?.vehicleColor,
        vehicleYear: values?.vehicleYear,
        numberPlate: values?.numberPlate,
        status: values?.status,
        vehicleLicensePlate: values?.vehicleLicensePlate,
        vehicleImageLeftSide: values?.vehicleImageLeftSide,
        vehicleImageRightSide: values?.vehicleImageRightSide,
        vehicleImageFrontSide: values?.vehicleImageFrontSide,
        vehicleImageBackSide: values?.vehicleImageBackSide,
        vehicleRegistration: values?.vehicleRegistration,
        vehicleInsurance: values?.vehicleInsurance,
        registrationExpirationDate: values?.registrationExpirationDate,
        insuranceExpirationDate: values?.insuranceExpirationDate,
        flexioVehicleTypes: values?.flexioVehicleTypes
      };

      await request.post("/mobile/additional-vehicles", dataSubmit);

      fetchDriverProfile();
      dispatch(setSuccessMessage("Created successfully!"));
      history.goBack();
    } catch (error) {
      console.log(error);
      return {};
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateAdditionalVehicle = useCallback(async (values) => {
    try {
      setLoading(true);

      const dataSubmit = {
        vehicleTypes: values?.vehicleTypes,
        vehicleMake: values?.vehicleMake,
        vehicleModel: values?.vehicleModel,
        vehicleColor: values?.vehicleColor,
        vehicleYear: values?.vehicleYear,
        numberPlate: values?.numberPlate,
        status: values?.status,
        vehicleLicensePlate: values?.vehicleLicensePlate,
        vehicleImageLeftSide: values?.vehicleImageLeftSide,
        vehicleImageRightSide: values?.vehicleImageRightSide,
        vehicleImageFrontSide: values?.vehicleImageFrontSide,
        vehicleImageBackSide: values?.vehicleImageBackSide,
        vehicleRegistration: values?.vehicleRegistration,
        vehicleInsurance: values?.vehicleInsurance,
        registrationExpirationDate: values?.registrationExpirationDate,
        insuranceExpirationDate: values?.insuranceExpirationDate,
        flexioVehicleTypes: values?.flexioVehicleTypes
      };

      await request.patch(
        `/mobile/additional-vehicles/${additionalVehicleId}`,
        dataSubmit
      );

      fetchDriverProfile();
      dispatch(setSuccessMessage("Updated successfully!"));
      history.goBack();
    } catch (error) {
      console.log(error);
      return {};
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      {(!additionalVehicleId || !_isEmpty(additionalVehicle)) && (
        <AdditionalVehicleForm
          data={additionalVehicle}
          onSubmit={
            additionalVehicleId
              ? handleUpdateAdditionalVehicle
              : handleAddAdditionalVehicle
          }
          isDriver={true}
        />
      )}
    </Spin>
  );
};

export default AddAdditionalVehicle;
