import request from "../../../../helper/request";

const clientApi = {
  getClientList: (params) => {
    return request.get("/admin/clients", {
      params
    });
  },

  getAllClientCities: (params) => {
    return request.get("/admin/clients/client-cities");
  },

  createClient: (data) => {
    return request.post("/admin/clients", data);
  },

  getClient: (id) => {
    return request.get(`/admin/clients/${id}`);
  },

  updateClient: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/clients/${id}`, data);
  },

  deleteClient: (data) => {
    return request.delete(`/admin/clients/${data.id}`);
  }
};

export default clientApi;
