import { CheckCircleFilled } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "antd";
import {
  InputHook,
  PhoneInputHook,
  ReactColorForm,
  InputPasswordHook,
  SelectHook
} from "components/hook-forms";
import { loadAllCountries } from "helper/util";
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import "./InternalDriverFormHook.scss";
import { internalDriverForm } from "./schema";
import { getStatesOfCountry } from "country-state-city/lib/state";

const FLEXIO_DRIVER_DATA = {
  firstName: "Flexio",
  lastName: Math.floor(Math.random(1000) * 100),
  startAddress: "685 Pacific Boulevard, Vancouver, BC V6B 0R4, Canada",
  startAddressLat: 49.2771031,
  startAddressLng: -123.1088116,
  shiftStart: "09:00",
  shiftEnd: "17:00"
};
const InternalDriverFormHook = ({ driver, submitForm, onCancel }) => {
  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(internalDriverForm),
    defaultValues: driver?.id ? driver : { color: "#43C1C2", isFlexioDriver: false }
  });
  const watchIsFlexioDriver = watch("isFlexioDriver");
  const [isUS, setIsUS] = useState(false);

  useEffect(() => {
    if (watchIsFlexioDriver) {
      Object.keys(FLEXIO_DRIVER_DATA).forEach((key) => {
        setValue(key, FLEXIO_DRIVER_DATA[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIsFlexioDriver]);

  const handleCountryChange = useCallback(
    (value) => {
      setValue("country", value);
      setIsUS(value === "US");
    },
    [setValue]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)} className="driver-form-hook-component">
      <div className="wrap-step-1-form">
        <InputHook
          control={control}
          placeholder="Email"
          label="E-mail"
          name="email"
          showOptionsName={false}
          errors={errors}
          disabled={watchIsFlexioDriver}
        />
        <PhoneInputHook
          control={control}
          label="Phone Number"
          placeholder="Phone Number"
          name="phone"
          showOptionsName={false}
          errors={errors}
          disabled={watchIsFlexioDriver}
        />
        <InputHook
          control={control}
          label="First Name"
          placeholder="First Name"
          name="firstName"
          showOptionsName={false}
          errors={errors}
          disabled={watchIsFlexioDriver}
        />
        <InputHook
          control={control}
          label="Last Name"
          placeholder="Last Name"
          name="lastName"
          showOptionsName={false}
          errors={errors}
        />

        <SelectHook
          showOptionsName={false}
          control={control}
          getValues={getValues}
          label="Country"
          name="country"
          placeholder="Country"
          valueSelect="isoCode"
          labelSelect="name"
          required
          errors={errors}
          options={loadAllCountries()}
          disabled={watchIsFlexioDriver}
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
          }
          onChange={handleCountryChange}
        />
        {isUS && (
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            label="State"
            name="state"
            placeholder="State"
            valueSelect="isoCode"
            labelSelect="name"
            required
            errors={errors}
            options={getStatesOfCountry("US")}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
            defaultValue="Please Select"
          />
        )}

        <InputPasswordHook
          control={control}
          label="Password"
          placeholder="Enter your password"
          type="password"
          name="password"
          showOptionsName={false}
          errors={errors}
        />
        <div className="wrap-arrival-time">
          <div className="inline-content-select">
            <ReactColorForm
              showOptionsName={false}
              label="Color Code"
              setValue={setValue}
              getValues={getValues}
              control={control}
              name="color"
              errors={errors}
            />
            <div />
          </div>
        </div>
      </div>
      <div className="form-control">
        <Button type="link" className="cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          {`${driver ? "Update" : "Create"}`} <CheckCircleFilled />
        </Button>
      </div>
    </form>
  );
};
export default InternalDriverFormHook;
