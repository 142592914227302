import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "antd";
import { InputPasswordHook, InputHook } from "components/hook-forms";
import React from "react";
import { useForm } from "react-hook-form";
import { registerForm } from "./schema";
import "./CreatePasswordForm.scss";
import { useLocation } from "react-router-dom";

// const useQuery = () => {
//   const { search } = useLocation();

//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

const CreatePasswordForm = ({ submitForm }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const { search } = useLocation();
  const emailGroup = search.split("&");
  const email = emailGroup[0].split("email=")[1];
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerForm),
    defaultValues: {
      email: email,
      token: queryParams.get("token")
    }
  });

  return (
    <form onSubmit={handleSubmit(submitForm)} className="create-password-component">
      <InputHook
        control={control}
        label="Email"
        placeholder="Email"
        name="email"
        showOptionsName={false}
        errors={errors}
        disabled={true}
      />
      <InputPasswordHook
        control={control}
        label="Password"
        placeholder="Password"
        name="password"
        showOptionsName={false}
        errors={errors}
      />
      <InputPasswordHook
        control={control}
        label="Confirm Password"
        placeholder="Confirm Password"
        name="confirmPassword"
        showOptionsName={false}
        errors={errors}
      />
      <Button type="primary" htmlType="submit" style={{ marginTop: 4 }}>
        Create Password
      </Button>
    </form>
  );
};
export default CreatePasswordForm;
