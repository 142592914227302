import React, { useState, useCallback, useEffect } from "react";
import request from "helper/request";
import Input from "antd/lib/input/Input";
import { Table, Popconfirm, message, Modal, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import AddressInput from "components/inputs/AddressInput";
import ImportLocation from "../components/ImportLocation";
import { ImportIcon } from "components/svgs/ImportIcon";

export default function Locations() {
  const [savedLocations, setSavedLocations] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [editData, setEditData] = useState({
    locationName: ""
  });
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [openImportModal, setOpenImportModal] = useState(false);

  const getSavedLocations = useCallback(async () => {
    setLoading(true);
    const response = await request.get("/saved-locations/all-locations");
    setSavedLocations(response);
    setLoading(false);
  }, [setSavedLocations, setLoading]);

  const findEmpty = useCallback((obj) => {
    if (
      !obj.locationName ||
      !obj.address ||
      !obj.contactName ||
      !obj.contactPhone ||
      !obj.lat ||
      !obj.lng
    ) {
      return true;
    }

    return false;
  }, []);

  const updateSavedLocations = useCallback(
    async (locationId) => {
      setLoading(true);

      const foundEmpty = findEmpty(editData);
      if (foundEmpty === true) {
        messageApi.open({
          type: "warning",
          content: "You might have miss something. Please try again."
        });
        setLoading(false);
        return;
      }
      const response = await request.patch(`/saved-locations/${locationId}`, editData);
      messageApi.open({
        type: "success",
        content: "Updated successfully."
      });

      await getSavedLocations();
      setRowId(null);
      setEditData(null);
      setLoading(false);
    },
    [
      setRowId,
      setEditData,
      setLoading,
      getSavedLocations,
      messageApi,
      editData,
      findEmpty
    ]
  );

  useEffect(() => {
    getSavedLocations();
    // eslint-disable-next-line
  }, []);

  const onValueChange = (e, id) => {
    const { name, value } = e.target;

    setEditData({
      ...editData,
      [name]: value
    });
  };

  const enableRowFields = useCallback(
    (record, value, placeholder, inputName, type = "text") => {
      if (rowId === record.id) {
        return (
          <>
            {inputName === "address" ? (
              <AddressInput
                placeholder="Address"
                onSelect={(addrObj) =>
                  setEditData({
                    ...editData,
                    address: addrObj.address,
                    lat: addrObj.lngLat[1],
                    lng: addrObj.lngLat[0]
                  })
                }
                address={{
                  address: record.address
                }}
              />
            ) : (
              <Input
                placeholder={placeholder}
                value={editData[inputName]}
                name={inputName}
                onChange={(e) => onValueChange(e, record.id)}
                required
                type={type}
              />
            )}
          </>
        );
      }

      return <>{value}</>;
    },
    [rowId, editData, onValueChange]
  );

  const deleteSavedLocation = useCallback(
    async (savedLocationId) => {
      setLoading(true);
      const response = await request.delete(`/saved-locations/${savedLocationId}`);
      await getSavedLocations();
      setRowId(null);
      setLoading(false);
    },
    [setLoading, getSavedLocations, setRowId, setLoading]
  );

  const transformToEditData = useCallback((recordData) => {
    return {
      locationName: recordData.locationName,
      address: recordData.address,
      contactName: recordData.contactName,
      contactPhone: recordData.contactPhone,
      contactEmail: recordData.contactEmail,
      lat: recordData.lat,
      lng: recordData.lng,
      timeWindowStart: recordData.timeWindowStart,
      timeWindowEnd: recordData.timeWindowEnd
    };
  }, []);

  const columns = [
    {
      title: "Location Name",
      dataIndex: "locationName",
      key: "locationName",
      render: (value, record, index) =>
        enableRowFields(record, value, "Location Name", "locationName")
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (value, record, index) =>
        enableRowFields(record, value, "Address", "address")
    },
    {
      title: "Contact Name",
      dataIndex: "contactName",
      key: "contactName",
      render: (value, record, index) =>
        enableRowFields(record, value, "Contact Name", "contactName")
    },
    {
      title: "Contact Phone",
      dataIndex: "contactPhone",
      key: "contactPhone",
      render: (value, record, index) =>
        enableRowFields(record, value, "Contact Phone", "contactPhone")
    },
    {
      title: "Contact Email",
      dataIndex: "contactEmail",
      key: "contactEmail",
      render: (value, record, index) =>
        enableRowFields(record, value, "Contact Email", "contactEmail", "email")
    },
    {
      title: "Time Window Start",
      dataIndex: "timeWindowStart",
      key: "timeWindowStart",
      render: (value, record, index) =>
        enableRowFields(record, value, "Time Window Start", "timeWindowStart", "time")
    },
    {
      title: "Time Window End",
      dataIndex: "timeWindowEnd",
      key: "timeWindowEnd",
      render: (value, record, index) =>
        enableRowFields(record, value, "Time Window End", "timeWindowEnd", "time")
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, record, index) => (
        <div className="d-flex">
          {!(rowId === record.id) && (
            <div className="m-1">
              <img
                src="/icons/edit.svg"
                style={{ width: 15, cursor: "pointer" }}
                onClick={(e) => {
                  setRowId(record?.id);
                  setEditData({
                    ...transformToEditData(record)
                  });
                }}
              />
            </div>
          )}
          {rowId === record.id && (
            <div
              style={{ fontWeight: "bold", cursor: "pointer" }}
              className="m-1"
              onClick={() => setRowId(null)}
            >
              X
            </div>
          )}
          {rowId === record.id && (
            <div
              style={{ fontWeight: "bold", cursor: "pointer" }}
              className="m-1"
              onClick={() => {
                updateSavedLocations(record?.id);
              }}
            >
              <CheckOutlined />
            </div>
          )}
          {rowId !== record.id && (
            <div className="m-1">
              <Popconfirm
                title="Are you sure you want delete this?"
                onConfirm={() => deleteSavedLocation(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <img src="/icons/delete.svg" style={{ width: 15, cursor: "pointer" }} />
              </Popconfirm>
            </div>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="return-process-form">
      {contextHolder}
      <h2 className="title" style={{ marginBottom: "0px", fontSize: "18px" }}>
        Locations
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "0px",
          marginBottom: "20px"
        }}
      >
        <Button
          className={`svg-icon  d-flex flex-row`}
          onClick={() => {
            setOpenImportModal(!openImportModal);
          }}
        >
          Import
          <ImportIcon />
        </Button>
      </div>

      <div id="editor">
        {openImportModal && (
          <Modal
            title={"Import Flexio Pool Data"}
            footer={false}
            width={1000}
            open={openImportModal}
            onCancel={() => {
              setOpenImportModal(!openImportModal);
            }}
          >
            <ImportLocation
              setOpenImportModal={setOpenImportModal}
              getSavedLocations={getSavedLocations}
            />
          </Modal>
        )}
        <Table
          dataSource={savedLocations}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
}
