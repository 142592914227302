import { BrowserUploadIcon } from "components/svgs/BrowserUploadIcon";
import { ImageIcon } from "components/svgs";
import { Controller } from "react-hook-form";
import React, { useRef, useState } from "react";
import "./index.scss";
import { DeleteIcon } from "components/svgs/DeleteIcon";
import request from "helper/request";
import { Spin } from "antd";
import FormItemHook from "../FormItem/FormItemHook";
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 20971520;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUploadHook = ({
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  className,
  control,
  errors,
  name,
  label,
  required = false,
  showLabel,
  showOptionsName,
  setValueForm,
  getValueForm,
  ...otherProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(getValueForm(name));

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = async (e) => {
    setIsLoading(true);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);

      const filesAsArray = convertNestedObjectToArray(updatedFiles);
      const formData = new FormData();
      formData.append("file", filesAsArray[0]);

      const response = await request("/v1/files/guest", {
        method: "POST",
        data: formData,
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      setFiles(response);
      setValueForm(name, response);
      setIsLoading(false);
    }
  };

  const removeFile = () => {
    setFiles({});
    setValueForm(name, {});
  };

  const errorMessage = {
    message: errors[name]?.id || errors[name]?.message ? `${label} is required` : null
  };

  const [isLargeImageOpen, setIsLargeImageOpen] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errorMessage}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        control={control}
        name="test"
        render={() => (
          <div className="file-upload-hook">
            <Spin spinning={isLoading}>
              <article className="file-preview-container">
                <ImageIcon width="30px" height="30px" />
                <section className="preview-list">
                  {files?.url && (
                    <section className="preview-container">
                      <div className="wrap-image-preview">
                        <img
                          className="image-preview"
                          src={files?.url}
                          alt={`file preview`}
                          onClick={() => {
                            setIsLargeImageOpen(true);
                            setLargeImageUrl(files.url);
                          }}
                        />
                        <DeleteIcon
                          className="delete-icon"
                          onClick={() => removeFile()}
                        />
                      </div>
                    </section>
                  )}
                  <LargeImageModal
                    isOpen={isLargeImageOpen}
                    imageUrl={largeImageUrl}
                    onClose={() => setIsLargeImageOpen(false)}
                  />
                </section>
              </article>
            </Spin>
            <div className="file-upload-container">
              <label>Drag and drop your files anywhere or</label>
              <button
                className="upload-file-btn"
                type="button"
                onClick={handleUploadBtnClick}
              >
                Browse &nbsp; <BrowserUploadIcon />
              </button>
              <input
                className="form-fields"
                type="file"
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=""
                value=""
                {...otherProps}
              />
            </div>
          </div>
        )}
      />
    </FormItemHook>
  );
};

const LargeImageModal = ({ isOpen, imageUrl, onClose }) => {
  const closeModal = () => {
    if (isOpen) {
      onClose();
    }
  };

  return (
    <div className={`custom-modal ${isOpen ? "open" : ""}`} onClick={closeModal}>
      <div className="modal-content">
        <img
          src={imageUrl}
          alt="Large Image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export { FileUploadHook };
