import { CaretRightOutlined } from "@ant-design/icons";
import React from "react";
import _isEmpty from "lodash/isEmpty";

import SelectRoutes from "./SelectRoutes";
import "./ServiceType.scss";

const ServiceType = ({ service, routes, setRoutes, estimatePriceOrganization }) => {
  const { bulletPointOne, bulletPointTwo, bulletPointThree } = service;

  return (
    <div className="service-type-component">
      <div className="wrap-box">
        <div className="first-content">
          <div className="service-title">{service.name}</div>
          <div className="list-name">
            {[bulletPointOne, bulletPointTwo, bulletPointThree]
              .filter((item) => !_isEmpty(item))
              .map((name, idx) => (
                <div key={`${name}-${idx}`} className="name">
                  <CaretRightOutlined style={{ color: "#FFFFFF" }} /> {name}
                </div>
              ))}
          </div>
        </div>
        <SelectRoutes
          routes={routes}
          setRoutes={setRoutes}
          service={service}
          estimatePriceOrganization={estimatePriceOrganization}
        />
        {service.termsLink && (
          <div className="wrap-return-pickup">
            <div className="terms-conditions">
              <a href={service.termsLink} rel="noreferrer" target="_blank">
                Flexio Terms & Conditions
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceType;
