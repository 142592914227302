import { createAsyncThunk } from "@reduxjs/toolkit";
import { setGlobalLoading } from "../../../../app/global/slice";
import { ACTION } from "../../../../configs/constants";
import driverFeedbackApi from "./api";

export const doGetDriverFeedbacks = createAsyncThunk(
  "driver-feedbacks/get/loading",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return driverFeedbackApi.getDriverFeedbacks(payload);
  }
);
