import { yupResolver } from "@hookform/resolvers/yup";
import { notification, Spin } from "antd";
import request from "helper/request";

import {
  InputHook,
  PhoneInputHook,
  SelectHook,
  CheckBoxHook
} from "components/hook-forms";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ActionButton from "./ActionButtons";
import { step1Schema } from "./schema";
import "./Step1Form.scss";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { loadAllCountries } from "helper/util";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { State, City } from "country-state-city";
import { prCities } from "./prCities";
import { GroupCheckboxHook } from "components/hook-forms";
import { VEHICLE_TYPE_OPTIONS } from "configs/constants";

const Step1Form = ({ setStepData, handleSubmitForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localForm1, setLocationForm1] = useState({});
  const [isUS, setIsUS] = useState(false);
  const [cities, setCities] = useState([]);
  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(step1Schema),
    defaultValues: localForm1
  });

  const handleCountryChange = (value) => {
    setValue("country", value);
    if (value === "US") {
      setIsUS(true);
    } else {
      setIsUS(false);
    }
    let countryCities = [];
    if (value === "PR") {
      countryCities = prCities;
    } else {
      countryCities = City.getCitiesOfCountry(value);
      setCities(countryCities || []);
    }
    setCities(countryCities);
  };

  const submitForm = async (values) => {
    try {
      setIsLoading(true);
      const isExistEmail = await request.post("/drivers/validate-email", {
        email: getValues("email")
      });
      if (isExistEmail) {
        notification.error({
          message: "Email has been register!",
          placement: "topRight"
        });
        return;
      }
      const assignedTerritoryId = await request.get(
        "/drivers/get-territoryId-by-country",
        { params: { country: getValues("country") } }
      );

      const saasCountry = await request.get("/admin/saascountries/get-saas-country", {
        params: { country: getValues("country") }
      });

      if (!saasCountry) {
        notification.error({
          message: "Flexio services are not available in this country",
          placement: "topRight"
        });
        return;
      }
      const firstStepData = { ...values, territoryId: assignedTerritoryId };
      setLocationForm1(firstStepData);
      setStepData(firstStepData);
      handleSubmitForm(firstStepData);
    } catch (err) {
      notification.error({
        message: err?.message,
        placement: "topRight"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-step-1-form">
          <InputHook
            control={control}
            label="First Name"
            placeholder="First Name"
            name="firstName"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            placeholder="Email"
            label="Email"
            name="email"
            showOptionsName={false}
            errors={errors}
          />
          <PhoneInputHook
            control={control}
            label="Phone Number"
            placeholder="Phone Number"
            name="phone"
            showOptionsName={false}
            errors={errors}
            required={true}
          />
          <div className="vehicle-types-check-list">
            <GroupCheckboxHook
              label="Vehicle Type on Registration"
              options={VEHICLE_TYPE_OPTIONS}
              showOptionsName={false}
              control={control}
              name="vehicleTypeOnRegistration"
              errors={errors}
            />
          </div>
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            label="Country"
            name="country"
            placeholder="Country"
            valueSelect="isoCode"
            labelSelect="name"
            required
            errors={errors}
            options={loadAllCountries()}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
            onChange={handleCountryChange}
          />

          {isUS && (
            <SelectHook
              showOptionsName={false}
              control={control}
              getValues={getValues}
              label="State"
              name="state"
              placeholder="State"
              valueSelect="isoCode"
              labelSelect="name"
              required
              errors={errors}
              options={getStatesOfCountry("US")}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
              defaultValue="Please Select"
            />
          )}

          <CheckBoxHook
            showOptionsName={false}
            control={control}
            name="agree"
            errors={errors}
            classNameCheckbox="secondary"
            showLabel={false}
            className="agree-checkbox"
          >
            <div>
              <span>I agree with Flexio &nbsp;</span>
              <Link
                to={{
                  pathname: isMobile
                    ? "https://www.goflexio.com/driverterms_eng"
                    : "https://www.goflexio.com/clientterms"
                }}
                target="_blank"
              >
                <span className="link">Terms & Conditions</span>
              </Link>
            </div>
          </CheckBoxHook>
          {cities.length > 0 && (
            <SelectHook
              showOptionsName={false}
              control={control}
              getValues={getValues}
              placeholder="City"
              label="City"
              name="city"
              valueSelect="name"
              labelSelect="name"
              required
              errors={errors}
              options={cities}
              showSearch
            />
          )}
        </div>
        <ActionButton />
      </form>
    </Spin>
  );
};
export default Step1Form;
