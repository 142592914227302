import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FileUploadHook,
  GroupCheckboxHook,
  InputHook,
  SwitchHook,
  DatePickerHook
} from "components/hook-forms";
import { CheckCircleIcon } from "components/svgs";
import Cards from "../components/Cards";
import { ADDITIONAL_VEHICLE_STATUS, VEHICLE_OPTIONS } from "../constants";
import { additionalVehicleSchema } from "./schema";
import { getAllVehicleCategories } from "modules/admin/settings/store/VehicleCategoryThunks";
import { selectAllVehicleCategories } from "modules/admin/settings/store/VehicleCategorySlice";
import { selectAllDriverVehicleCategories } from "modules/driver/store/VehicleCategorySlice";
import { getVehicleCategories } from "modules/driver/store/VehicleCategoryThunks";

import "./Step2Form.scss";
import "./ActionButtons";
import { formatDateToBackend } from "helper/util";

const AdditionalVehicleForm = ({ onSubmit, data = {}, isDriver = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleCategories = useSelector(
    isDriver ? selectAllDriverVehicleCategories : selectAllVehicleCategories
  );

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(additionalVehicleSchema),
    defaultValues: { ...data, status: data.status === ADDITIONAL_VEHICLE_STATUS.verified }
  });

  useEffect(() => {
    dispatch(isDriver ? getVehicleCategories() : getAllVehicleCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!vehicleCategories?.length) return;
    setVehicleTypeOptions(
      vehicleCategories.map((item) => ({ label: item.name, value: item.id }))
    );
    // eslint-disable-next-line
  }, [vehicleCategories]);

  const submitForm = async (values) => {
    onSubmit({
      ...values,
      status: values?.status
        ? ADDITIONAL_VEHICLE_STATUS.verified
        : ADDITIONAL_VEHICLE_STATUS.pending,
      vehicleLicensePlate: values?.vehicleLicensePlate?.id || undefined,
      vehicleImageLeftSide: values?.vehicleImageLeftSide?.id || undefined,
      vehicleImageRightSide: values?.vehicleImageRightSide?.id || undefined,
      vehicleImageFrontSide: values?.vehicleImageFrontSide?.id || undefined,
      vehicleImageBackSide: values?.vehicleImageBackSide?.id || undefined,
      vehicleRegistration: values?.vehicleRegistration?.id || undefined,
      vehicleInsurance: values?.vehicleInsurance?.id || undefined,
      registrationExpirationDate: formatDateToBackend(values?.registrationExpirationDate),
      insuranceExpirationDate: formatDateToBackend(values?.insuranceExpirationDate)
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Cards
        title="Vehicle Information"
        className="wrap-step-2-form"
        rightTitle="All fields are required"
      >
        <>
          <div
            className="vehicle-types-check-list"
            style={{ marginTop: "30px", marginLeft: "26px" }}
          >
            <GroupCheckboxHook
              label="Vehicle Types"
              required
              options={VEHICLE_OPTIONS}
              showOptionsName={false}
              control={control}
              name="vehicleTypes"
              errors={errors}
            />
          </div>

          <div className="group-form">
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Make"
              placeholder="Vehicle Make"
              name="vehicleMake"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Model"
              placeholder="Vehicle Model"
              name="vehicleModel"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Color"
              placeholder="Vehicle Color"
              name="vehicleColor"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Year"
              placeholder="Vehicle Year"
              name="vehicleYear"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Number Plate"
              placeholder="Number Plate"
              name="numberPlate"
              required
              errors={errors}
            />
            {!isDriver && (
              <SwitchHook
                className="switch-hook vehicle-status"
                control={control}
                getValues={getValues}
                label="Verified"
                name="status"
                showOptionsName={false}
                defaultChecked={false}
                errors={errors}
              />
            )}
          </div>
        </>
      </Cards>

      <Cards
        title="Upload Images"
        className="wrap-step-2-form"
        rightTitle="All image uploads are required"
      >
        <div className="group-form">
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Licence Plate #"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleLicensePlate"
          />
          <div className="form-item-hook"></div>
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Left Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageLeftSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Right Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageRightSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Front Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageFrontSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Back Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageBackSide"
          />
        </div>
      </Cards>

      <Cards
        title="Upload Images"
        className="wrap-step-2-admin-form"
        rightTitle="All document uploads are required"
      >
        <div className="group-form">
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Registration"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleRegistration"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Insurance"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleInsurance"
          />
          <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Driver's Registration Expiration Date"
            label="Driver's Registration Expiration Date"
            name="registrationExpirationDate"
            errors={errors}
            showOptionsName={false}
          />
          <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Vehicle Insurance Expiration Date"
            label="Vehicle Insurance Expiration Date"
            name="insuranceExpirationDate"
            errors={errors}
            showOptionsName={false}
          />
        </div>
        {!isDriver && (
          <div className="px-4 p-1">
            <div className="flexio-vehicle-types-check-list">
              <GroupCheckboxHook
                label="Flexio Vehicle Types"
                required
                options={vehicleTypeOptions}
                showOptionsName={false}
                control={control}
                name="flexioVehicleTypes"
                errors={errors}
              />
            </div>
          </div>
        )}
      </Cards>
      <div className="action-buttons">
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button type="primary" className="svg-icon" htmlType="submit">
          {data?.id ? "Update" : "Create"} &nbsp;
          <CheckCircleIcon color="#FFFFFF" />
        </Button>
      </div>
    </form>
  );
};

export default AdditionalVehicleForm;
