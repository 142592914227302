import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../modules/auth/store/authSlice";
import MainLayout from "../layouts/privateAdmin/MainLayout";
import { UserGroup } from "configs/constants";
import { ProfileProvider } from "../layouts/private/ProfileContext";

const PrivateAdminRoute = (props) => {
  const { component: Component, isFullScreen = false, ...rest } = props;
  const authentication = useSelector(selectAuthentication);
  const group = useSelector(selectGroup);
  if (!authentication || ![UserGroup.FLEXIO, UserGroup.TERRITORY].includes(group)) {
    return (
      <Redirect
        to={{
          pathname: `/admin/login`
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(propsCom) => {
        return (
          <ProfileProvider>
            <MainLayout isFullScreen={isFullScreen}>
              <Component {...propsCom} isAdmin={true} />
            </MainLayout>
          </ProfileProvider>
        );
      }}
    />
  );
};
export default PrivateAdminRoute;
