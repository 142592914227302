import React, { useState, useMemo, useCallback, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./PaymentForm.scss";
import { notification } from "antd";
import { ProfileContext } from "layouts/private/ProfileContext";
import request from "helper/request";

const PaymentForm = ({
  clientSecret,
  paymentFormRef,
  setOpenPaymentMethodModal,
  setFormSubmitted,
  setIsLoading
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formError, setFormError] = useState(null);
  const [cardholderName, setCardholderName] = useState("");
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const setProfile = useMemo(() => profileCtx?.setProfile, [profileCtx]);

  const handleClearFields = useCallback(() => {
    if (elements) {
      elements.getElement(CardElement).clear();
    }
    setCardholderName("");
  }, [elements]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      setFormSubmitted(true);
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardholderName
          }
        }
      });

      if (error) {
        setIsLoading(false);
        setFormSubmitted(false);
        setFormError(
          "There was a problem creating your payment method. Please try again."
        );
      } else {
        try {
          const stripePaymentMethods = await request.get(
            "/payment-method/payment-methods"
          );
          setProfile({
            ...profile,
            paymentMethods: stripePaymentMethods
          });
          notification.success({
            message: "Payment Method Saved!"
          });
          handleClearFields();
          setOpenPaymentMethodModal(false);
        } catch (err) {
          setIsLoading(false);
          setFormSubmitted(false);
          notification.error({
            message: "Failed to save payment method. Please try again."
          });
          setFormError("Failed to save payment method. Please try again.");
        }
      }
    },
    [
      stripe,
      elements,
      clientSecret,
      cardholderName,
      setProfile,
      profile,
      handleClearFields,
      setOpenPaymentMethodModal
    ]
  );

  const style = useMemo(
    () => ({
      base: {
        color: "#000000",
        fontSize: "16px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        "::placeholder": {
          color: "#cccccc"
        }
      },
      invalid: {
        color: "#df1b41"
      }
    }),
    []
  );

  return (
    <form className="PaymentForm" onSubmit={handleSubmit} ref={paymentFormRef}>
      <div>
        <input
          className="CardName"
          type="text"
          id="cardholder-name"
          value={cardholderName}
          placeholder="Name on Card"
          onChange={(e) => {
            const value = e.target.value;
            const filteredValue = value.replace(/[^\p{L}\s]/gu, "");
            setCardholderName(filteredValue);
          }}
          required
        />
      </div>
      <div className="CardWrapper">
        <div className="CardInputWrapper">
          <CardElement className="CardWrapper" options={{ style }} />
        </div>
      </div>
      {formError && <div>{formError}</div>}
      <div style={{ width: "100%", textAlign: "center", marginTop: "40px" }}>
        <a
          href="https://www.goflexio.com/clientterms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </div>
    </form>
  );
};

export default PaymentForm;
