import React from "react";
const DrawIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 3.33333H10V2H11.3333V3.33333ZM10 14H11.3333V12.2733L13.06 14L14 13.06L12.2733 11.3333H14V10H10V14ZM12.6667 6H14V4.66667H12.6667V6ZM12.6667 8.66667H14V7.33333H12.6667V8.66667ZM7.33333 14H8.66667V12.6667H7.33333V14ZM4.66667 3.33333H6V2H4.66667V3.33333ZM2 11.3333H3.33333V10H2V11.3333ZM3.33333 14V12.6667H2C2 13.4 2.6 14 3.33333 14ZM12.6667 2V3.33333H14C14 2.6 13.4 2 12.6667 2ZM7.33333 3.33333H8.66667V2H7.33333V3.33333ZM2 6H3.33333V4.66667H2V6ZM4.66667 14H6V12.6667H4.66667V14ZM2 8.66667H3.33333V7.33333H2V8.66667ZM2 3.33333H3.33333V2C2.6 2 2 2.6 2 3.33333Z"
        fill="#1F3998"
      />
    </svg>
  );
};
export { DrawIcon };
