import React from "react";
const SettingIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.95 8.78333C13.9833 8.53333 14 8.275 14 8C14 7.73333 13.9833 7.46667 13.9416 7.21667L15.6333 5.9C15.7833 5.78333 15.825 5.55833 15.7333 5.39167L14.1333 2.625C14.0333 2.44167 13.825 2.38333 13.6416 2.44167L11.65 3.24167C11.2333 2.925 10.7916 2.65833 10.3 2.45833L9.99998 0.341667C9.96665 0.141667 9.79998 0 9.59998 0H6.39998C6.19998 0 6.04165 0.141667 6.00831 0.341667L5.70831 2.45833C5.21665 2.65833 4.76665 2.93333 4.35831 3.24167L2.36665 2.44167C2.18331 2.375 1.97498 2.44167 1.87498 2.625L0.283315 5.39167C0.183315 5.56667 0.216649 5.78333 0.383315 5.9L2.07498 7.21667C2.03331 7.46667 1.99998 7.74167 1.99998 8C1.99998 8.25833 2.01665 8.53333 2.05831 8.78333L0.366649 10.1C0.216649 10.2167 0.174982 10.4417 0.266649 10.6083L1.86665 13.375C1.96665 13.5583 2.17498 13.6167 2.35831 13.5583L4.34998 12.7583C4.76665 13.075 5.20831 13.3417 5.69998 13.5417L5.99998 15.6583C6.04165 15.8583 6.19998 16 6.39998 16H9.59998C9.79998 16 9.96665 15.8583 9.99165 15.6583L10.2916 13.5417C10.7833 13.3417 11.2333 13.075 11.6416 12.7583L13.6333 13.5583C13.8166 13.625 14.025 13.5583 14.125 13.375L15.725 10.6083C15.825 10.425 15.7833 10.2167 15.625 10.1L13.95 8.78333ZM7.99998 11C6.34998 11 4.99998 9.65 4.99998 8C4.99998 6.35 6.34998 5 7.99998 5C9.64998 5 11 6.35 11 8C11 9.65 9.64998 11 7.99998 11Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};
export { SettingIcon };
