import { Button } from "antd";
import { Link } from "react-router-dom";
import React from "react";

import { withAuthUser } from "../hocs/withAuth";

import "./WelcomePage.scss";
const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <div className="logo-img">
        <img src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`} />
      </div>
      <h1 style={{ marginTop: "2em" }}>Success!</h1>
      <p> Your account has been succesfully created. Please login to continue. </p>
      <Link to="/login" className="login-button">
        <Button type="primary" block>
          Login
        </Button>
      </Link>
    </div>
  );
};
export default withAuthUser(WelcomePage);
