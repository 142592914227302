import { Modal } from "antd";
import { DEFAULT_CENTER } from "configs/constants";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useState, useEffect } from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import DriverDirection from "./layers/DriverDirection";
import RouteLayer from "./layers/RouteLayer";
// import DropoffLayer from "./layers/DropoffLayer";
import LocationLayer from "./layers/LocationLayer";
import MarkerActiveLayer from "./layers/MarkerActiveLayer";
// import PickupLayer from "./layers/PickupLayer";
import UnscheduledLayer from "./layers/UnscheduledLayer";
import "./MapBox.scss";
import { DriverDetailPopup } from "./popups/DriverDetailPopup";
import MapPopup from "./popups/MapPopup";
import { TruckIcon } from "../svgs";
import ReactFullscreen from "react-easyfullscreen";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const Map = ReactMapboxGl({
  accessToken: window.REACT_APP_MAPBOX_KEY,
  onStyleLoad: (map) => map.resize(),
  zoom: 12
});

const MapBox = ({
  goToCoupleStop,
  handleStopDetail,
  driverDetail,
  setDriverDetail,
  setShowDriverDetail,
  showDriverDetail,
  popupData,
  isShowViewMoreVet = true,
  handleClickMarker,
  hideUnscheduled,
  width = "100%",
  routes = [],
  stops = [],
  handleClosePopup,
  center = DEFAULT_CENTER,
  height,
  markerActive,
  bounds = [],
  currentDriver,
  isAdmin,
  isDashboard = false,
  fitBoundsOptions
}) => {
  const [fitBounds, setFitBounds] = useState(bounds);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [longLat, setLongLat] = useState(DEFAULT_CENTER);

  useEffect(() => {
    if (currentDriver) setLongLat([currentDriver.lng, currentDriver.lat]);
  }, [currentDriver]);

  useEffect(() => {
    if (bounds.length > 0) {
      setTimeout(() => {
        setFitBounds(bounds);
      }, 1000);
    }
  }, [bounds]);

  useEffect(() => {
    const exitHandler = () => {
      if (
        !document.fullscreen &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("webkitfullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
  }, []);

  if (isNaN(height)) {
    return <div />;
  }
  const idsVetHidden = [];
  const colorsHidden = hideUnscheduled ? ["#C4C4C4"] : [];
  routes.forEach((driver) => {
    if (driver?.display === false) {
      idsVetHidden.push(driver?.id);
      colorsHidden.push(driver.color);
    }
  });
  const unscheduledStops = stops
    .filter((stop) => stop.routeId === null || stop.routeId === "")
    .map((s, index) => ({ ...s, sortNo: index + 1 }));

  const handleRequestFullScreen = (onRequest) => {
    onRequest();
    setIsFullscreen(true);
  };

  const handleExitFullScreen = (onExit) => {
    onExit();
    setIsFullscreen(false);
  };

  return (
    <ReactFullscreen>
      {({ ref, onRequest, onExit }) => (
        <div ref={ref} className="map-box-component">
          <Map
            style="mapbox://styles/mapbox/streets-v11"
            movingMethod="easeTo"
            containerStyle={{
              height: isFullscreen ? "100%" : height,
              width
            }}
            center={longLat}
            fitBounds={fitBounds}
            fitBoundsOptions={fitBoundsOptions}
          >
            <div className="mapboxgl-control-container">
              <div className="mapboxgl-ctrl-top-right">
                <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                  {!isFullscreen ? (
                    <button
                      className="mapboxgl-ctrl-fullscreen"
                      type="button"
                      aria-label="Enter fullscreen"
                      onClick={() => handleRequestFullScreen(onRequest)}
                    >
                      <span
                        className="mapboxgl-ctrl-icon"
                        aria-hidden="true"
                        title="Enter fullscreen"
                      ></span>
                    </button>
                  ) : (
                    <button
                      className="mapboxgl-ctrl-shrink"
                      type="button"
                      aria-label="Exit fullscreen"
                      onClick={() => handleExitFullScreen(onExit)}
                    >
                      <span
                        className="mapboxgl-ctrl-icon"
                        aria-hidden="true"
                        title="Exit fullscreen"
                      ></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            {!isDashboard && <DriverDirection routes={routes} />}
            <UnscheduledLayer
              // isShowViewMore={isShowViewMoreStop}
              idsVetHidden={idsVetHidden}
              colorsHidden={colorsHidden}
              stops={unscheduledStops}
              handleClickMarker={handleClickMarker}
              handleClosePopup={handleClosePopup}
            />
            <LocationLayer
              colorsHidden={colorsHidden}
              stops={stops}
              handleClickMarker={handleClickMarker}
              handleClosePopup={handleClosePopup}
            />

            {isDashboard ? (
              routes.map((route, index) => {
                return (
                  <Marker coordinates={[route.lng, route.lat]} key={index}>
                    <img
                      style={{ width: 28, cursor: "pointer" }}
                      src={`data:image/svg+xml;charset=utf-8;base64,${btoa(
                        unescape(encodeURIComponent(TruckIcon(route?.color)))
                      )}`}
                      onClick={() =>
                        handleClickMarker(
                          { ...route, showViewMore: isShowViewMoreVet },
                          "driver"
                        )
                      }
                    />
                  </Marker>
                );
              })
            ) : (
              <RouteLayer
                markerActive={markerActive}
                isShowViewMore={isShowViewMoreVet}
                routes={routes}
                handleClickMarker={handleClickMarker}
                handleClosePopup={handleClosePopup}
              />
            )}
            <MarkerActiveLayer
              marker={markerActive}
              handleClickMarker={handleClickMarker}
            />
            {currentDriver && (
              <Marker coordinates={currentDriver}>
                <img
                  style={{ width: "4em" }}
                  src={`data:image/svg+xml;charset=utf-8;base64,${btoa(
                    unescape(encodeURIComponent(TruckIcon(routes[0]?.color)))
                  )}`}
                />
              </Marker>
            )}
            {popupData && (
              <MapPopup
                goToCoupleStop={goToCoupleStop}
                stops={stops}
                handleStopDetail={handleStopDetail}
                handleDriverDetail={setShowDriverDetail}
                setDriverDetail={setDriverDetail}
                data={popupData}
                handleClosePopup={handleClosePopup}
                isAdmin={isAdmin}
                isDashboard={isDashboard}
              />
            )}
            {showDriverDetail && driverDetail && (
              <Modal
                centered
                visible={true}
                onCancel={() => setShowDriverDetail(false)}
                width={1280}
                footer={null}
                zIndex={9999}
              >
                <DriverDetailPopup
                  driver={driverDetail}
                  handleClickMarker={handleClickMarker}
                  groupStops={stops}
                />
              </Modal>
            )}
          </Map>
        </div>
      )}
    </ReactFullscreen>
  );
};
export default MapBox;
