import React, { useState, createContext, useEffect, useCallback, useMemo } from "react";
import { setError } from "app/global/slice";
import { useDispatch } from "react-redux";
import profileApi from "modules/client/profile/store/api";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await profileApi.getProfile();
      setProfile(response);
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const isSubscriptionActive = useMemo(() => {
    if (!window.SUBSCRIPTION_FEATURE) {
      return true;
    }

    return !!profile?.subscription?.isMembershipActive;
  }, [profile]);

  const hasUsedTrial = useMemo(() => profile?.hasUsedTrial, [profile]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        fetchProfile,
        setProfile,
        isSubscriptionActive,
        hasUsedTrial,
        isLoading
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
