import { createAsyncThunk } from "@reduxjs/toolkit";
import { setGlobalLoading } from "../../../../app/global/slice";
import { ACTION } from "../../../../configs/constants";
import userApi from "./api";

export const getUserList = createAsyncThunk("users/get/loading", async (data) => {
  return userApi.getUserList(data);
});

export const doCreateUser = createAsyncThunk("users/create", async (data, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return userApi.createClientUser(data);
});

export const doCreateTerritoryUser = createAsyncThunk(
  "users/territory/create/loading",
  async (data) => {
    return userApi.createTerritoryUser(data);
  }
);

export const doCreateAdminUser = createAsyncThunk(
  "users/admin/create/loading",
  async (data) => {
    return userApi.createAdminUser(data);
  }
);

export const doUpdateUserAdmin = createAsyncThunk(
  "users/admin/update/loading",
  async (data) => {
    return userApi.updateUserAdmin(data);
  }
);

export const doGetUser = createAsyncThunk("users/get", async (id, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return userApi.getUser(id);
});

export const doUpdateUser = createAsyncThunk("users/update", async (data, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return userApi.updateUser(data);
});

export const doUpdateUserTerritory = createAsyncThunk(
  "users/territory/update/loading",
  async (data) => {
    return userApi.updateUserTerritory(data);
  }
);

export const deleteUser = createAsyncThunk("users/delete", async (data, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return userApi.deleteUser(data);
});
