import { Button, Input, Popover } from "antd";
// import { COLORS_DRIVERS } from "configs/constants";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import "./index.scss";

import { ChromePicker } from "react-color";
import FormItemHook from "components/hook-forms/FormItem/FormItemHook";

const ReactColorForm = ({
  setValue,
  getValues,
  control,
  name,
  label,
  required = false,
  errors,
  showLabel,
  showOptionsName,
  className
}) => {
  const [visible, setVisible] = useState(false);
  const handleSelectedColor = (color) => {
    setValue(name, `${color.hex}`);
  };

  const handleChangeColor = (e) => {
    setValue(name, e.target.value);
  };
  return (
    <div className="color-input-form">
      <FormItemHook
        name={name}
        label={label}
        required={required}
        errors={errors[name]}
        showLabel={showLabel}
        showOptionsName={showOptionsName}
        className={className}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={"#cecece"}
          render={() => (
            <div className="wrap-form">
              <Popover
                overlayClassName="no-padding"
                content={
                  <ChromePicker
                    color={getValues(name)}
                    value={getValues(name)}
                    onChange={handleSelectedColor}
                  />
                }
                visible={visible}
                onVisibleChange={() => setVisible(!visible)}
              >
                <Button size="large" className="color-showed">
                  <span
                    className="color"
                    style={{ backgroundColor: `${getValues(name)}` }}
                  />
                </Button>
              </Popover>
              <Input value={getValues(name)} onChange={handleChangeColor} />
            </div>
          )}
        />
      </FormItemHook>
    </div>
  );
};
export { ReactColorForm };
