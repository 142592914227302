import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import "./DriveRegisterSuccessPage.scss";

const DriveRegisterSuccessPage = () => {
  const history = useHistory();
  const handleBack = () => {
    // Detect webview in flutter
    const isInWebView = () => {
      return window.flutter_inappwebview !== undefined;
    };

    if (isInWebView()) {
      if (window.flutter_inappwebview) {
        // built-in Flutter webview bridge method
        window.flutter_inappwebview.callHandler("navigateBack");
      }
    } else {
      // Fallback for standard web navigation
      history.push("/driver/login");
    }
  };
  return (
    <div className="driver-register-success-page-web">
      <div className="background-section" />
      <div className="main-content-page">
        <div className="wrap-logo">
          <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
        </div>
        <div className="d-flex flex-column wrap-body flex-items-center flex-justify-between">
          <div className="wrap-content">
            <div className="title">Thank you for signing up!</div>
            <div className="orange-text">
              Complete your profile to start earning today!
            </div>
            <br />
            <div className="description">
              <div>Your account has been created.</div>
              <div className="bold">Login to complete your profile.</div>
              <br />
              <div className="textAlignCenter">
                Once your profile is complete, we will review your application. Once you
                are activated, you will be able to start driving for Flexio!
              </div>
              <br />
              <div className="textAlignCenter">
                If you have any questions, please feel free to email us at&nbsp;
                <span>
                  <a href="mailto:drivers@goflexio.com">drivers@goflexio.com</a>.
                </span>
              </div>
              <br />
              <div className="wrap-button mb-3">
                <Button type="primary" onClick={handleBack}>
                  LOGIN
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DriveRegisterSuccessPage;
