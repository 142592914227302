import { createAsyncThunk } from "@reduxjs/toolkit";
import clientApi from "./api";
import { setGlobalLoading } from "../../../../app/global/slice";
import { ACTION } from "../../../../configs/constants";

export const getClientList = createAsyncThunk(
  "client/get/loading",
  async (data, thunkAPI) => {
    await thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.fetch }));
    return clientApi.getClientList(data);
  }
);

export const doCreateClient = createAsyncThunk(
  "clients/create",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return clientApi.createClient(data);
  }
);

export const doGetClient = createAsyncThunk("clients/get", async (id, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return clientApi.getClient(id);
});

export const doUpdateClient = createAsyncThunk(
  "clients/update",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return clientApi.updateClient(data);
  }
);

export const deleteClient = createAsyncThunk("clients/delete", async (data, thunkAPI) => {
  thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
  return clientApi.deleteClient(data);
});
