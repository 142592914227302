import React from "react";
import Input from "antd/es/input/Input";
import classes from "./StopSearch.module.scss";
import { useSearchStops } from "../store/usecase";

export function StopSearch({ stops, onStopClick }) {
  const [result, search] = useSearchStops(stops);

  const onSearchChange = (e) => {
    search(e.target.value);
  };
  const hasResult = result.length > 0;

  return (
    <div className={classes.stopSearch}>
      <Input
        onChange={onSearchChange}
        placeholder={"Search for a stop"}
        type={"search"}
        allowClear={true}
      />
      {hasResult && (
        <ul className={classes.result}>
          {result.map((it) => (
            <li key={it.id} onClick={() => onStopClick(it)}>
              <div className={classes.indicator}>{it.sortNo}</div>
              <div>{it.address}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
