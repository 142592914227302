import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateOrder,
  doDeleteOrder,
  doGetOrder,
  getAllOrder,
  getOrderList
} from "./thunks";

const OrderAdapter = createEntityAdapter({
  selectId: (order) => order.id
});

const initialState = OrderAdapter.getInitialState({
  metadata: {},
  order: {}
});

export const orderSlice = createSlice({
  name: "adminOrders",
  initialState,
  reducers: {
    updatePaginationOrders(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrder.fulfilled, (state, { payload }) => {
      OrderAdapter.setAll(state, payload);
      state.order = {};
      state.metadata = {};
    });
    builder.addCase(getOrderList.fulfilled, (state, { payload }) => {
      OrderAdapter.setAll(state, payload.data);
      state.order = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetOrder.fulfilled, (state, { payload }) => {
      state.order = payload;
    });
    builder.addCase(doCreateOrder.fulfilled, (state, { payload }) => {
      OrderAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDeleteOrder.fulfilled, (state, { payload }) => {
      OrderAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectOrderById,
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: selectTotalOrders
} = OrderAdapter.getSelectors((state) => state.adminOrders);

export const selectMetadata = (state) => state.adminOrders.metadata;
export const selectOrder = (state) => state.adminOrders.order;

export const { updatePaginationOrders } = orderSlice.actions;

export default orderSlice.reducer;
