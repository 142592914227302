import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import ReturnProcess from "../forms/ReturnProcess";
import { useDispatch, useSelector } from "react-redux";
import { selectAllReturnLocations } from "../store/return-process/slice";
import { doCreate, doGetList, doUpdate } from "../store/return-process/thunks";
import { setSuccessMessage } from "app/global/slice";

const initialData = {
  enabled: true,
  start: moment("00:00", "HH:mm"),
  end: moment("23:59", "HH:mm"),
  duration: 10,
  phoneList: [{ type: "contact", phone: "" }]
};

const ReturnProcessPage = () => {
  const [returnLocation, setReturnLocation] = useState(initialData);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.global.isLoading);
  const returnLocations = useSelector(selectAllReturnLocations);

  useEffect(() => {
    dispatch(doGetList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (returnLocations?.length) {
      setReturnLocation(returnLocations[0]);
    }
  }, [returnLocations]);

  const handleOnFinish = async (data) => {
    if (returnLocation?.id) {
      const { error } = await dispatch(doUpdate({ ...data, id: returnLocation.id }));
      if (!error) {
        dispatch(setSuccessMessage("Updated successfully!"));
      }
    } else {
      const { error } = dispatch(doCreate(data));
      if (!error) {
        dispatch(setSuccessMessage("Created successfully!"));
      }
    }
  };
  return (
    <div className="return-process-setting">
      <ReturnProcess
        isFetching={isLoading}
        returnLocation={returnLocation}
        onSubmit={handleOnFinish}
      />
    </div>
  );
};

export default memo(ReturnProcessPage);
