import {
  IdcardOutlined,
  DollarOutlined,
  CarOutlined,
  UserOutlined,
  PoweroffOutlined,
  MenuOutlined
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import { ArrowLeftIcon } from "components/svgs/ArrowLeftIcon";
import { ArrowRightIcon } from "components/svgs/ArrowRightIcon";
import React, { useState, useCallback, useContext, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DashboardIcon } from "../../../components/svgs";
import "./AdminSiderContent.scss";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import "./DriverSiderContent.scss";
import { useDispatch } from "react-redux";
import { logout } from "modules/auth/store/authSlice";
const { Sider } = Layout;
const getPathName = (str) => {
  let rs = "/";
  const path = str && str[str.length - 1] == "/" ? str.substring(0, str.length - 1) : str;
  switch (path) {
    case path === "/driver":
      rs = "/driver/overview";
      break;
    case path.includes("/driver") && path.includes("/basic-information"):
      rs = "/driver/basic-information";
      break;
    case path.includes("/driver") && path.includes("/advanced-information"):
      rs = "/driver/advanced-information";
      break;
    case path.includes("/driver") && path.includes("/payment-information"):
      rs = "/driver/payment-information";
      break;
    case path.includes("/driver") && path.includes("/vehicles"):
      rs = "/driver/vehicles";
      break;
  }
  return rs;
};

const DriverSiderContent = () => {
  const location = useLocation();
  const pathname = getPathName(getPathName(location.pathname));
  const [siderKey, setSiderKey] = useState(pathname);
  const [openKey, setOpenKey] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const handleClickCollapse = () => {
    setCollapsed(!collapsed);
  };
  const profileDriverCtx = useContext(DriverProfileContext);
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  const dispatch = useDispatch();
  const handleLogout = useCallback(async () => {
    dispatch(logout());
  }, [dispatch]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const history = useHistory();

  const handleClickMenuItem = useCallback(
    (value) => {
      setCollapsed(true);
      const key = value?.key;
      setSiderKey(getPathName(key));
      setOpenKey([]);
      if (key === "logout") {
        history.push("/driver/login");
      } else if (key) {
        history.push(`${key}`);
      }
    },
    [setSiderKey, setOpenKey, history]
  );

  return (
    <>
      <div className="burger-container hide-if-desktop">
        <Button
          icon={<MenuOutlined />}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        ></Button>
      </div>
      <Sider
        className="admin-sider-content-component fixed-sider"
        trigger={null}
        collapsible
        width={windowWidth > 600 ? "250" : "100%"}
        collapsedWidth={windowWidth > 600 ? 60 : 0}
        collapsed={collapsed}
      >
        <Menu
          className="driver-sider-child"
          mode="inline"
          openKeys={openKey}
          selectedKeys={[siderKey]}
          onClick={handleClickMenuItem}
        >
          <Menu.Item
            className="driver-sider-child"
            key="/driver/overview"
            icon={
              <span className="anticon">
                <DashboardIcon />
              </span>
            }
          >
            Overview
          </Menu.Item>
          <Menu.Item
            key="/driver/basic-information"
            icon={
              <span className="anticon">
                <UserOutlined />
              </span>
            }
          >
            Basic Information
          </Menu.Item>
          <Menu.Item
            key="/driver/advanced-information"
            icon={
              <span className="anticon">
                <IdcardOutlined />
              </span>
            }
          >
            Advanced Information
          </Menu.Item>
          <Menu.Item
            key="/driver/payment-information"
            icon={
              <span className="anticon">
                <DollarOutlined />
              </span>
            }
          >
            Payment Information
          </Menu.Item>
          <Menu.Item
            key={`/driver/vehicles/${driverProfile?.id}`}
            icon={
              <span className="anticon">
                <CarOutlined />
              </span>
            }
          >
            Vehicles
          </Menu.Item>

          <Menu.Item
            key={"logout"}
            icon={
              <span className="anticon">
                <PoweroffOutlined />
              </span>
            }
            style={{ color: "red" }}
            onClick={handleLogout}
          >
            Log Out
          </Menu.Item>
        </Menu>
        <div className="button-collapse hide-if-mobile " onClick={handleClickCollapse}>
          <span>{collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}</span>
        </div>
      </Sider>
    </>
  );
};
export default DriverSiderContent;
