import React from "react";
import "./Popup.scss";
import { CloseOutlined, ArrowRightOutlined } from "@ant-design/icons";
const DriverPopup = ({
  driver,
  handleClosePopup,
  handleDriverDetail,
  setDriverDetail,
  isDashboard = false
}) => {
  return (
    <div className="popup-map-box-component">
      <CloseOutlined onClick={handleClosePopup} />
      <div className="group-info">
        {isDashboard ? (
          <div>{`${driver?.driver?.firstName || ""} ${
            driver?.driver?.lastName || ""
          }`}</div>
        ) : (
          <>
            <div>{driver?.startAddress}</div>
            <div
              className="view-more"
              onClick={() => {
                handleDriverDetail(true);
                setDriverDetail(driver);
              }}
            >
              View More <ArrowRightOutlined />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default DriverPopup;
