import { UPDATE_PROFILE_TO_CONTINUE } from "configs/constants";
import React from "react";
import { Link } from "react-router-dom";

export const ToolTipTitle = ({
  textTitle = UPDATE_PROFILE_TO_CONTINUE,
  linkText = "Profile",
  linkPath = "/settings"
}) => {
  return (
    <>
      <div>
        {textTitle} <Link to={linkPath}>{linkText}</Link>
      </div>
    </>
  );
};
