import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";
import { shortId } from "helper/util";

import { selectBroadcast } from "../store/slice";
import { doGetDriverBroadcast } from "../store/thunks";
import { SENT_TO_TYPES } from "../components/CreateForm";
import BroadcastHeader from "../components/BroadcastHeader";

import "./DriverBroadcastDetail.scss";

const DriverBroadcastDetail = () => {
  const params = useParams();
  const id = params?.id;
  const shortBroadcastId = shortId(id);
  const breadCrumbs = [
    { text: "DRIVER BROADCASTS", url: "/admin/driver-broadcasts" },
    { text: shortBroadcastId }
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(doGetDriverBroadcast(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const { title, message, type, territory, flexioVehicleTypes, cities, drivers } =
    useSelector(selectBroadcast) || {};
  return (
    <div className="driver-broadcast-detail">
      <BroadcastHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="detail-content">
        <h2 className="title">{shortBroadcastId}</h2>
        <label>Title</label>
        <p>{title}</p>
        <label>Message</label>
        <p>{message}</p>
        <label className="sent-to">Sent to:</label>
        {type === SENT_TO_TYPES.GROUP ? (
          <>
            <label>Territory</label>
            <p>{_isEmpty(territory) ? "All Territories" : territory.name}</p>
            {!_isEmpty(cities) && (
              <>
                <label>Cities</label>
                <p>{cities.join(", ")}</p>
              </>
            )}
            {!_isEmpty(flexioVehicleTypes) && (
              <>
                <label>Vehicle Types</label>
                <p>{flexioVehicleTypes.map((item) => item.name).join(", ")}</p>
              </>
            )}
          </>
        ) : (
          <>
            <label>Drivers</label>
            {drivers?.map((item) => (
              <p
                style={{ marginBottom: 4 }}
                key={item.id}
              >{`${item.fullName} (${item.email})`}</p>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default withPermission(DriverBroadcastDetail, canCreatePermission);
