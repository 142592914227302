import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import "./AdminLoginPage.scss";
import { handleLoginAdmin } from "../store/thunk";
import { withAuthAdmin } from "../hocs/withAuth";
import { Link } from "react-router-dom";

const AdminLoginPage = () => {
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(handleLoginAdmin(values));
  };

  return (
    <div className="admin-login-page">
      <div id="background"></div>
      <div className="logo">
        <img src={`/logos/admin/${window.REACT_APP_LOGO_FILE_NAME}`} />
      </div>
      <div className="form">
        <Form
          scrollToFirstError={true}
          layout="vertical"
          hideRequiredMark
          colon={false}
          onFinish={onSubmit}
        >
          <div className="form-header">
            <div>
              <p className="title">Welcome Back!</p>
              <p>Login to your Flexio admin account.</p>
            </div>
          </div>
          <div className="form-content">
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { type: "email", message: "Invalid Email" },
                { required: true, message: "Required" }
              ]}
            >
              <Input size="large" placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <Link to="/admin/forgot-password">
              <div className="text-primary pb-3">Forgot Password?</div>
            </Link>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              block
              size="large"
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default withAuthAdmin(AdminLoginPage);
