import React, { useContext, useMemo, useState } from "react";
import Step2Form from "modules/admin/drivers/forms/Step2Form";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import request from "helper/request";
import { Spin } from "antd";
import { formatDateToBackend } from "helper/util";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";

function AdvanceInformation() {
  const profileDriverCtx = useContext(DriverProfileContext);
  const fetchDriverProfile = profileDriverCtx.fetchProfile;
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateDriver = async (values) => {
    try {
      setLoading(true);
      const dataSubmit = {
        city: values?.city,
        postalAddress: values?.postalAddress,
        profilePhoto: values?.profilePhoto?.id || undefined,
        vehicleTypeOnRegistration: values?.vehicleTypeOnRegistration || undefined,
        driverLicense: values?.driverLicense?.id || undefined,
        drivingRecord: values?.drivingRecord?.id || undefined,
        criminalRecordCertificate: values?.criminalRecordCertificate?.id || undefined,
        licenseExpirationDate: formatDateToBackend(values?.licenseExpirationDate),
        recordExpirationDate: formatDateToBackend(values?.recordExpirationDate),
        recordCertificateDate: formatDateToBackend(values?.recordCertificateDate),
        taxSsnOrEin: values?.taxSsnOrEin,
        taxName: values?.taxName,
        taxAddress: values?.taxAddress
      };

      const response = await request.patch("/drivers/", dataSubmit);
      dispatch(setSuccessMessage("Updated successfully!"));
      fetchDriverProfile();
      return response;
    } catch (error) {
      console.log(error);
      return {};
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        {driverProfile && (
          <Step2Form
            driver={driverProfile}
            handleUpdateDriver={handleUpdateDriver}
            showActionButton={true}
            isAdmin={false}
          />
        )}
      </Spin>
    </div>
  );
}

export default AdvanceInformation;
