import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "antd";

import { DateRangeIcon } from "components/svgs";
import { DATE_FORMAT_FRONTEND } from "configs/constants";
import FormItemHook from "../FormItem/FormItemHook";

const { RangePicker } = DatePicker;

const RangePickerHook = ({
  defaultValue,
  getValues,
  control,
  name,
  label,
  required,
  showOptionsName = true,
  showLabel = true,
  errors,
  className,
  icon,
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RangePicker
            {...rest}
            {...field}
            // value={getValues(name) ? moment(getValues(name), DATE_FORMAT_FRONTEND) : ""}
            format={DATE_FORMAT_FRONTEND}
            suffixIcon={icon ? icon : <DateRangeIcon />}
            allowClear={false}
          />
        )}
      />
    </FormItemHook>
  );
};
export { RangePickerHook };
