export function buildFirstLevelFilter(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "id",
    "client_name",
    "first_name",
    "last_name",
    "client_id",
    "country",
    "active",
    "role",
    "email",
    "territory_id",
    "is_internal_driver"
  ];

  const FILTER_VALUES = {
    id: "$cont",
    client_name: "$contL",
    first_name: "$contL",
    last_name: "$contL",
    email: "$contL",
    client_id: "$eq",
    country: "$eq",
    active: "$eq",
    role: "$eq",
    territory_id: "$eq",
    is_internal_driver: "$eq"
  };

  return Object.keys(filter)
    .filter(
      (field) =>
        FIRST_LEVEL_FILTER.includes(field) &&
        filter[field] !== undefined &&
        filter[field] !== null &&
        filter[field] !== ""
    )
    .map(
      (filteredField) =>
        `${filteredField}||${FILTER_VALUES[filteredField]}||${filter[filteredField]}`
    );
}
