/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import TableBase from "components/table/TableBase";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import pluralize from "pluralize";
import React from "react";
import { useSelector } from "react-redux";

const InternalDriverTable = ({ dataSource, doDeleteInternalDriver, handleClickEdit }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);

  const handleDelete = (record) => {
    doDeleteInternalDriver(record);
  };

  const columns = [
    {
      title: "ID",
      width: 120,
      render: (value) => {
        return (
          <p style={{ margin: 0, color: value?.color || "#1f3998" }}>
            {value?.id.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 120
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: 150
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: 250
    },
    {
      title: "Country",
      dataIndex: "country",
      width: 150
    }
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      // fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <img
                src="/icons/edit.svg"
                className={`table-status`}
                onClick={() => handleClickEdit(record)}
              />
            )}
            {canDelete && (
              <div onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => handleDelete(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <img src="/icons/delete.svg" className={`table-status`} />
                </Popconfirm>
              </div>
            )}
          </Space>
        );
      }
    });
  }

  return (
    <div className="ActiveOrdersTable">
      <TableBase
        columns={columns}
        onRow={() => {}}
        data={dataSource}
        pagination={{
          position: ["bottomCenter"],
          total: (total) => total,
          defaultPageSize: 10,
          showTotal: (total) => pluralize("Driver", total, true)
        }}
        rowKey="id"
      />
    </div>
  );
};
export default InternalDriverTable;
