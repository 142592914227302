import React from "react";
import { Checkbox } from "antd";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";
const CheckboxGroup = Checkbox.Group;

const GroupCheckboxHook = ({
  disabled = false,
  className,
  classNameCheckbox,
  type,
  placeholder = "",
  control,
  errors,
  name,
  label,
  required = false,
  showLabel,
  showOptionsName,
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        placeholder={placeholder || name}
        control={control}
        name={name}
        render={({ field }) => (
          <div>
            <CheckboxGroup
              {...rest}
              {...field}
              // checked={field.value}
              type={type}
              disabled={disabled}
              className={classNameCheckbox}
            />
          </div>
        )}
      />
    </FormItemHook>
  );
};
export { GroupCheckboxHook };
