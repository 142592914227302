import AppToggle from "./AppToggle";
import { Checkbox, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import classes from "./CustomValidationField.module.scss";
import { QuestionIcon } from "components/svgs";

export default function CustomValidationField({
  type,
  placeholder,
  enabled,
  required,
  namePrefix,
  customTitle,
  onEnableChange,
  tooltip
}) {
  const [checked, setChecked] = useState(enabled);
  const [_required, setRequired] = useState(required);
  const [_customTitle, setCustomTitle] = useState(customTitle);

  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  useEffect(() => {
    setRequired(required);
  }, [required]);

  useEffect(() => {
    setCustomTitle(customTitle);
  }, [customTitle]);

  return (
    <div className={classes.customField}>
      <div className={classes.checkItem}>
        <AppToggle
          checked={checked}
          name={`${namePrefix}_enabled`}
          onCheckedChange={(isChecked) => {
            setChecked(isChecked);
            onEnableChange?.(isChecked);
          }}
        >
          {type}
        </AppToggle>
        <Tooltip title={tooltip}>
          <div style={{ marginLeft: ".5em", marginTop: ".5em" }}>
            <QuestionIcon color="#8f8f8f" width="20px" />
          </div>
        </Tooltip>
        <Checkbox
          name={`${namePrefix}_required`}
          disabled={!checked}
          checked={_required}
          value={_required}
          onChange={(it) => setRequired(it.target.checked)}
        >
          Required
        </Checkbox>
      </div>
      <label>Custom Title</label>
      <Input
        onChange={(it) => setCustomTitle(it.target.value)}
        name={`${namePrefix}_customTitle`}
        placeholder={placeholder}
        disabled={!checked}
        value={_customTitle}
      />
    </div>
  );
}
