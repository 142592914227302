import { createSlice } from "@reduxjs/toolkit";
import { ACTION } from "../../configs/constants";

function isActionWithPending(action) {
  return (
    // action.type.endsWith("get/pending") ||
    // action.type.endsWith("create/pending") ||
    // action.type.endsWith("update/pending") ||
    // action.type.endsWith("delete/pending") ||
    action.type.endsWith("loading/pending")
  );
}
function isActionWithFailed(action) {
  return action.type.endsWith("/rejected");
}
function isActionWithFulfilled(action) {
  return action.type.endsWith("/fulfilled");
}

// special not loading here
const NOT_LOADING = ["someRequest/pending"];

const commonSlice = createSlice({
  name: "global",
  initialState: {
    isLoading: false,
    successMessage: "",
    action: ACTION.fetch,
    errors: {}
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
      state.action = action.payload.action;
    },
    setError: (state, action) => {
      state.errors = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isActionWithPending, (state, action) => {
      if (!NOT_LOADING.includes(action.type)) {
        state.isLoading = true;
      }
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isActionWithFailed, (state, action) => {
      const errors = action?.error;
      state.isLoading = false;
      state.errors = JSON.parse(errors?.message || "{}");
    });
  }
});

const { actions, reducer: commonReducer } = commonSlice;
export const { setGlobalLoading, setError, setSuccessMessage } = actions;

export default commonReducer;
