import React, { useMemo } from "react";
import { Button, Space } from "antd";
import { Link, useLocation } from "react-router-dom";
import { round, isEmpty } from "lodash";
import pluralize from "pluralize";
import Icon, { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { COLORS } from "configs/constants";

import "./Popup.scss";

const StopPopup = ({
  handleClosePopup,
  stop,
  goToCoupleStop,
  // canEdit,
  isDashboard = false
}) => {
  const location = useLocation();
  const travelMins = stop.travelMins || 0;
  const serviceMins = stop.duration || 0;
  const waitingMins = stop.waitingMins || 0;
  const workingMins = stop.workingMins || 0;
  const distance = stop?.distance ? round(stop?.distance / 1000, 2) : 0;
  const locationName =
    stop?.locationName ||
    (stop.id.includes("__") ? stop.id : stop.id.slice(0, 8)?.toUpperCase());
  console.log(stop);

  const editStopUrl = useMemo(() => {
    if (isEmpty(stop)) return "";
    return `${location.pathname.includes("/admin/") ? "/admin" : ""}/routes/${
      stop?.routeId || "unknown"
    }/stops/${stop?.locationId}/edit`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stop]);

  const addStopUrl = useMemo(() => {
    if (isEmpty(stop)) return "";
    return `${location.pathname.includes("/admin/") ? "/admin" : ""}/routes/${
      stop?.routeId || "unknown"
    }/${stop?.locationId || "unknown"}/add-job`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stop]);

  return (
    <div className="order-popup">
      <CloseOutlined onClick={handleClosePopup} />
      <div className="header">
        <div className="left">
          {/* {stop.id.includes("__") ? stop.id : stop.id.slice(0, 8)} */}
          {locationName}
        </div>
        {/* <div className="right">
          <a href={`/orders/${stop.orderId}`} target="_blank" rel="noopener noreferrer">
            View Order Details {IconDetailOrder}
          </a>
        </div> */}
      </div>
      <div className="content-top">
        <div className="type">{stop.type}</div>
        <div className="address">{stop.address}</div>
      </div>
      <div className="content-main">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Time Window</th>
              {stop && (
                <>
                  <th style={{ width: "25%" }}>Arrival Time</th>
                  <th style={{ width: "25%" }}>Finish Time</th>
                  <th style={{ width: "25%" }}>Distance</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{(stop.start || "") + " - " + (stop.end || "")}</td>
              {stop && (
                <>
                  <td>{stop?.arrivalTime}</td>
                  <td>{stop?.finishTime}</td>
                  <td>{distance} km</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
        {stop && (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Travel Time</th>
                <th style={{ width: "25%" }}>Service Time</th>
                <th style={{ width: "25%" }}>Working Time</th>
                <th style={{ width: "25%" }}>Waiting Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {travelMins} {pluralize("min", travelMins)}
                </td>
                <td>
                  {serviceMins} {pluralize("min", serviceMins)}
                </td>
                <td>
                  {workingMins} {pluralize("min", workingMins)}
                </td>
                <td>
                  {waitingMins} {pluralize("min", waitingMins)}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div className="actions">
        {isDashboard ? (
          <Link to={`/admin/routes/${stop?.routeId || "unknown"}/${stop?.jobId}`}>
            <Button type="secondary-with-icon" className="no-border no-padding">
              View
            </Button>
          </Link>
        ) : (
          <Space size={8}>
            {stop.type === "pickup" ? (
              <div
                className="d-flex gap-2 flex-wrap flex-items-center mr-3"
                style={{ width: "100%" }}
              >
                {stop?.stopIds?.map((id) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => goToCoupleStop(stop)}
                    key={id}
                  >
                    {id.includes("__") ? id : id.slice(0, 4)}
                  </div>
                ))}
                {stop?.locationId && (
                  <Link to={addStopUrl}>
                    <Button type="secondary-with-icon" className="no-border no-padding">
                      Add Stops
                      <PlusCircleOutlined />
                    </Button>
                  </Link>
                )}
              </div>
            ) : (
              <Button type="secondary-with-icon" onClick={() => goToCoupleStop(stop)}>
                Go to Pickup
                <Icon component={MoveToInbox} />
              </Button>
            )}
            {stop?.locationId && (
              <Link to={editStopUrl}>
                <Button type="secondary-with-icon" className="no-border no-padding">
                  Edit
                  <Icon component={EditStopIcon} />
                </Button>
              </Link>
            )}
          </Space>
        )}
      </div>
    </div>
  );
};
export default StopPopup;

const MoveToInbox = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6933 3.48667L12.7667 2.36667C12.5867 2.14 12.3133 2 12 2H4C3.68667 2 3.41333 2.14 3.22667 2.36667L2.30667 3.48667C2.11333 3.71333 2 4.01333 2 4.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V4.33333C14 4.01333 13.8867 3.71333 13.6933 3.48667ZM4.16 3.33333H11.84L12.3933 4H3.61333L4.16 3.33333ZM3.33333 12.6667V5.33333H12.6667V12.6667H3.33333ZM5.33333 9.33333H7.03333V11.3333H8.96667V9.33333H10.6667L8 6.66667L5.33333 9.33333Z"
      fill={COLORS.primary}
    />
  </svg>
);

const EditStopIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 1.33301C5.42665 1.33301 3.33331 3.42634 3.33331 5.99967C3.33331 9.49967 7.99998 14.6663 7.99998 14.6663C7.99998 14.6663 12.6666 9.49967 12.6666 5.99967C12.6666 3.42634 10.5733 1.33301 7.99998 1.33301ZM6.95998 7.99967H5.99998V7.03967L8.23331 4.81301L9.18665 5.76634L6.95998 7.99967ZM9.92665 5.03301L9.45998 5.49967L8.49998 4.53967L8.96665 4.07301C9.06665 3.97301 9.22665 3.97301 9.32665 4.07301L9.92665 4.67301C10.0266 4.77301 10.0266 4.93301 9.92665 5.03301Z"
      fill={COLORS.primary}
    />
  </svg>
);

const DetailOrder = () => (
  <svg viewBox="0 0 12 14">
    <path
      fill="currentColor"
      d="M2.66667 9.99984H7.33333V11.3332H2.66667V9.99984ZM2.66667 7.33317H9.33333V8.6665H2.66667V7.33317ZM2.66667 4.6665H9.33333V5.99984H2.66667V4.6665ZM10.6667 1.99984H7.88C7.6 1.2265 6.86667 0.666504 6 0.666504C5.13333 0.666504 4.4 1.2265 4.12 1.99984H1.33333C1.24 1.99984 1.15333 2.0065 1.06667 2.0265C0.806667 2.07984 0.573333 2.21317 0.393333 2.39317C0.273333 2.51317 0.173333 2.65984 0.106667 2.81984C0.0400001 2.97317 0 3.1465 0 3.33317V12.6665C0 12.8465 0.0400001 13.0265 0.106667 13.1865C0.173333 13.3465 0.273333 13.4865 0.393333 13.6132C0.573333 13.7932 0.806667 13.9265 1.06667 13.9798C1.15333 13.9932 1.24 13.9998 1.33333 13.9998H10.6667C11.4 13.9998 12 13.3998 12 12.6665V3.33317C12 2.59984 11.4 1.99984 10.6667 1.99984ZM6 1.83317C6.27333 1.83317 6.5 2.05984 6.5 2.33317C6.5 2.6065 6.27333 2.83317 6 2.83317C5.72667 2.83317 5.5 2.6065 5.5 2.33317C5.5 2.05984 5.72667 1.83317 6 1.83317ZM10.6667 12.6665H1.33333V3.33317H10.6667V12.6665Z"
    ></path>
  </svg>
);

const IconDetailOrder = (
  <Icon component={DetailOrder} style={{ width: 12, marginLeft: 6 }} />
);
