import { Badge } from "antd";
import Card from "antd/lib/card/Card";
import React from "react";

const StatisticCard = ({
  icon,
  data,
  title,
  others,
  showMenu = false,
  handleMenu = null,
  ...props
}) => {
  return (
    <Card
      {...props}
      style={{
        flex: 1,
        background: "#f8fafb",
        overflow: "hidden",
        border: "0px solid #2491bc",
        borderRadius: "0px",
        padding: "1em 0"
      }}
    >
      <div
        className="dot-container"
        style={styles.dotContainer}
        hidden={!showMenu}
        onClick={handleMenu}
      >
        <Badge color="#636D6E" style={styles.dot} />
        <Badge color="#636D6E" style={styles.dot} />
        <Badge color="#636D6E" style={styles.dot} />
      </div>
      <span style={{ position: "absolute", right: "0", top: "-20%" }}>{icon}</span>
      <div className="mb-4">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ color: "#7d7d7d" }}>{title}</span>
        </div>
        <h1
          className="m-0"
          style={{ fontSize: "2em", color: "#2491bc", fontWeight: 700 }}
        >
          {data}
        </h1>
        <div
          style={{
            flex: 1,
            borderRadius: "0px",
            padding: "1em 0",
            width: "100%"
          }}
        >
          {others}
        </div>
      </div>
    </Card>
  );
};

const styles = {
  dotContainer: {
    position: "absolute",
    right: "20px",
    top: "5px",
    cursor: "pointer",
    zIndex: 999,
    transition: "all 0.2s ease-out"
  },
  dot: {
    margin: "0 0.1em"
  }
};

export default StatisticCard;
