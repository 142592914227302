import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { markerLayer, markerIcon } from "../settings";

const icon = markerIcon();

const MarkerActiveLayer = ({ marker, handleClickMarker }) => {
  const setCursor = (map, cursor) => {
    map.getCanvas().style.cursor = cursor;
  };
  if (!marker) {
    return <></>;
  }
  const type = marker.type;

  return (
    <>
      {type === "pickup" && (
        <Layer {...markerLayer(`current-pickup`, icon[`current-pickup`])}>
          <Feature
            key={marker.id}
            coordinates={[marker?.lng, marker?.lat]}
            onClick={() => handleClickMarker(marker, marker?.makerType)}
            onMouseEnter={({ map }) => {
              setCursor(map, "pointer");
            }}
            onMouseLeave={({ map }) => {
              setCursor(map, "");
            }}
            properties={{
              label:
                marker?.makerType === "driver"
                  ? "B"
                  : marker?.sortNo || marker?.unscheduledNo,
              color: marker?.color || "#C4C4C4",
              colorText: "#FFFFFF"
            }}
          />
        </Layer>
      )}

      {type === "dropoff" && (
        <Layer {...markerLayer(`current-dropoff`, icon[`current-dropoff`])}>
          <Feature
            key={marker.id}
            coordinates={[marker?.lng, marker?.lat]}
            onClick={() => handleClickMarker(marker, marker?.makerType)}
            onMouseEnter={({ map }) => {
              setCursor(map, "pointer");
            }}
            onMouseLeave={({ map }) => {
              setCursor(map, "");
            }}
            properties={{
              label:
                marker?.makerType === "driver"
                  ? "B"
                  : marker?.sortNo || marker?.unscheduledNo,
              color: marker?.color || "#C4C4C4",
              colorText: "#FFFFFF"
            }}
          />
        </Layer>
      )}

      {type === "driver" && (
        <Layer {...markerLayer(`current-driver`, icon[`current-driver`])}>
          <Feature
            key={marker.id}
            coordinates={[marker?.lng, marker?.lat]}
            onClick={() => handleClickMarker(marker, marker?.makerType)}
            onMouseEnter={({ map }) => {
              setCursor(map, "pointer");
            }}
            onMouseLeave={({ map }) => {
              setCursor(map, "");
            }}
            properties={{
              label:
                marker?.makerType === "driver"
                  ? "B"
                  : marker?.sortNo || marker?.unscheduledNo,
              color: marker?.color || "#C4C4C4",
              colorText: "#FFFFFF"
            }}
          />
        </Layer>
      )}
    </>
  );
};
export default MarkerActiveLayer;
