import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form } from "antd";

import "./SettingsPage.scss";
import ChangePasswordForm from "../forms/ChangePassword";
import passwordApi from "../store/password/api";
import { setError, setSuccessMessage } from "../../../../app/global/slice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOnFinish = async (values) => {
    try {
      setLoading(true);
      await passwordApi.updatePassword(values);
      dispatch(setSuccessMessage("Updated password successfully!"));
      form.resetFields();
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChangePasswordForm form={form} isFetching={loading} handleSubmit={handleOnFinish} />
  );
};

export default ChangePassword;
