import React from "react";
const HistoryIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 0.5C6.19159 0.5 2.83325 3.85833 2.83325 8H0.333252L3.57492 11.2417L3.63325 11.3583L6.99992 8H4.49992C4.49992 4.775 7.10825 2.16667 10.3333 2.16667C13.5583 2.16667 16.1666 4.775 16.1666 8C16.1666 11.225 13.5583 13.8333 10.3333 13.8333C8.72492 13.8333 7.26659 13.175 6.21659 12.1167L5.03325 13.3C6.39158 14.6583 8.25825 15.5 10.3333 15.5C14.4749 15.5 17.8333 12.1417 17.8333 8C17.8333 3.85833 14.4749 0.5 10.3333 0.5ZM9.49992 4.66667V8.83333L13.0416 10.9333L13.6833 9.86667L10.7499 8.125V4.66667H9.49992Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};
export { HistoryIcon };
