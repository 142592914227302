import React from "react";
import Card from "../Cards";
import "./Step1.scss";
import Step1Form from "../../forms/Step1Form";
const Step1 = ({ dataForm, setStepData, handleSubmitForm }) => {
  return (
    <div className="step-1-component">
      <Card title={"Basic Information"} rightTitle="All fields are required">
        <Step1Form
          dataForm={dataForm}
          setStepData={setStepData}
          handleSubmitForm={handleSubmitForm}
        />
      </Card>
    </div>
  );
};
export default Step1;
