import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import moment from "moment";
import { Button, Tooltip, Card, notification } from "antd";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { QuestionIcon } from "components/svgs";
import { setGlobalLoading } from "app/global/slice";
import { selectCurrentUser } from "modules/auth/store/authSlice";
import request from "helper/request";
import { poolPackageSizes } from "helper/util";

import "modules/client/routes/components/exports/index.scss";

const COLUMN_CANNOT_NULL = [
  "Package Size",
  "Pickup Address",
  "Dropoff Address",
  "Pickup Contact Name",
  "Pickup Contact Phone Number",
  "Dropoff Contact Name",
  "Dropoff Phone Number"
];
const PHONE_COLUMNS = ["Pickup Contact Phone Number", "Dropoff Contact Phone Number"];
const LAT_LNG_DATA = ["Pickup Lat", "Pickup Lng", "Dropoff Lat", "Dropoff Lng"];
const PACKAGE_DATA = ["Package Size"];
const BUSINESS_NAMES = ["Pickup Stop Name", "Dropoff Stop Name"];

const ImportFlexioPool = ({ handleCloseModal, profile, refreshRoutes }) => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const packageTypeList = useSelector(selectAllPackageTypes);
  const [importData, setImportData] = useState([]);
  const [error, setError] = useState(false);
  const fileInputRef = useRef();

  const onImportExcel = (tmpFile) => {
    const { files } = tmpFile.target;
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, {
          type: "binary",
          cellDates: false,
          raw: true
        });
        let data = [];
        for (const sheet in workbook.Sheets) {
          // esline-disable-next-line
          if (workbook?.Sheets) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            const [resultArr, isError] = checkErrors(data);
            setError(isError);
            setImportData(resultArr);
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };

  const handleOnChangeImport = (e) => {
    onImportExcel(e);
  };

  const clickChooseFile = (e) => {
    fileInputRef.current.click();
  };

  const handleDateImport = (date) => {
    return date ? moment(date, "DD/MM/YYYY") : undefined;
  };

  const hasInvalidPackageSize = (item) => {
    let packageSizes = poolPackageSizes.map((name) => name.toLowerCase());
    return !packageSizes.includes(item.toLowerCase());
  };
  const packageTypes = (item) => {
    let packageType = packageTypeList.find(
      (pack) => pack.name.toLowerCase() === item.toLowerCase()
    );
    return packageType;
  };
  // This does the actual mapping of CSV fields to the object / DTO.
  // You will also need to extend the backend OrderV2 DTO, Response, and Service.
  const handleImport = async () => {
    dispatch(setGlobalLoading({ isLoading: true }));
    handleCloseModal(false);
    let packages = [];
    for (let i = 0; i < importData.length; i++) {
      const item = importData[i];
      const id = item["Id"];
      const keys = Object.keys(item);

      if (hasInvalidPackageSize(item["Package Size"])) {
        dispatch(setGlobalLoading({ isLoading: false }));
        return notification.error({
          message: `Package size "${item["Package Size"]}", not valid.`,
          placement: "topRight"
        });
      }

      [item["Package Size"]].forEach((packageSize) => {
        const packageType = packageTypes(packageSize);
        packages.push({
          id,
          packageTypeId: packageType.id,
          pickupStopName: item["Pickup Stop Name"],
          pickupAddress: item["Pickup Address"],
          dropoffStopName: item["Dropoff Stop Name"],
          dropoffAddress: item["Dropoff Address"],
          pickupDate: handleDateImport(item["Pickup Date"]),
          note: item["Note"],
          pickupContactName: item["Pickup Contact Name"],
          pickupContactEmail: item["Pickup Contact Email"],
          pickupContactPhone: item["Pickup Contact Phone Number"],
          dropoffContactName: item["Dropoff Contact Name"],
          dropoffContactEmail: item["Dropoff Contact Email"],
          dropoffContactPhone: item["Dropoff Phone Number"],
          pickupLat: item["Pickup Lat"],
          pickupLng: item["Pickup Lng"],
          dropoffLat: item["Dropoff Lat"],
          dropoffLng: item["Dropoff Lng"],
          barcode: item["Barcode"]
        });
      });
    }
    // fetch for the create order await
    // no need to manage state
    const poolOrderCreateResponse = await request.post("/orderv2/create", {
      client_id: profile.client.id,
      user_id: currentUser.sub,
      packages: packages,
      submittedDate: moment().toDate(),
      isFlexioPoolOrder: true,
      note: "ABCD"
    });

    if (poolOrderCreateResponse?.message) {
      notification.error({
        message: poolOrderCreateResponse?.message,
        placement: "topRight"
      });
    } else {
      notification.success({
        message: "Import successfully!",
        placement: "topRight"
      });
    }

    refreshRoutes();
    dispatch(setGlobalLoading({ isLoading: false }));
  };

  const checkFields = (item) => {
    let tpmItem = { ...item };
    let isError = false;
    COLUMN_CANNOT_NULL.forEach((column) => {
      if (!item[column]) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} cannot be empty`]
            : [`${column} cannot be empty`]
        };
      }
    });
    return [tpmItem, isError];
  };

  const checkErrors = (arr) => {
    let isError = false;
    let tmpArr = [...arr];
    tmpArr = tmpArr.map((ar) => {
      const [item, err] = checkFields(ar);
      isError = isError || err;
      return item;
    });
    return [tmpArr, isError];
  };

  const headerKeys = Object.keys(Object.assign({}, ...importData)).filter(
    (k) => k !== "errors"
  );
  return (
    <div className="import-locations-component m-3 pb-4">
      <input
        type={"file"}
        id={"csvFileInput"}
        accept={".xlsx, .xls, .csv"}
        className="hidden file-import"
        onChange={handleOnChangeImport}
        ref={fileInputRef}
      />
      <div className="">
        <div className="d-flex flex-row gap-2 ant-space-align-center">
          <Button
            className="btn-download svg-icon mb-3"
            size="small"
            onClick={clickChooseFile}
          >
            Choose file
          </Button>
          <Tooltip
            placement="right"
            overlayStyle={{ maxWidth: "600px" }}
            title={
              <span>
                Click here to import your own <b>.xlsx</b> or <b>.csv</b> file and add
                routes in bulk.
              </span>
            }
          >
            <div className="d-flex mb-3 ant-space-align-center">
              <QuestionIcon />
            </div>
          </Tooltip>
        </div>
        <div className="download-text mb-3">
          Download Sample File:{" "}
          <a
            target="_blank"
            href="https://flexio-media.s3.us-east-2.amazonaws.com/sample-xlsx/flexiopool.csv"
            rel="noopener noreferrer"
          >
            flexiopool.csv
          </a>
        </div>
        <Card bodyStyle={{ backgroundColor: "#f2f2f2" }}>
          <p>
            The following describes the requirements for importing an <b>.xlsx</b> or{" "}
            <b>.csv</b> file:
          </p>
          <p>Mandatory fields:</p>
          <ul>
            <li>Package Size (Postal, Micro, Small, Medium)</li>
            <li>Pickup Address</li>
            <li>Pickup Contact Name</li>
            <li>Pickup Contact Phone Number</li>
            <li>Dropoff Address</li>
            <li>Dropoff Contact Name</li>
            <li>Dropoff Contact Phone Number</li>
          </ul>
        </Card>
      </div>
      <table id="client-table">
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th key={key} className="px-3 header-table">
                {key}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {importData.map((item, index) =>
            item.errors ? (
              <tr key={index} className={item.errors && "errors"}>
                {headerKeys.map((key) => (
                  <Tooltip placement="top" title={String(item.errors)} key={key}>
                    <td className="px-3">{item[key]}</td>
                  </Tooltip>
                ))}
              </tr>
            ) : (
              <tr key={index}>
                {headerKeys.map((key) => (
                  <td className="px-3" key={key}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
      {!error && importData?.length > 0 && (
        <div className="d-flex flex-justify-end">
          <Button
            type="primary"
            className="btn-download svg-icon mb-3"
            onClick={handleImport}
          >
            Import
          </Button>
        </div>
      )}
    </div>
  );
};
export default ImportFlexioPool;
