import React from "react";
import { useSelector } from "react-redux";
import withFetchOne from "../../../../hocs/withFetchOne";
import PackageTypeForm from "../components/form/PackageTypeForm";
import SettingHeader from "../components/header/SettingHeader";
import { doGetPackageType } from "../store/PackageTypeThunks";
import { selectPackageType } from "../store/PackageTypeSlice";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";

const PackageTypeEdit = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/package-types", text: "PACKAGE TYPES" },
    { text: "EDIT" }
  ];
  const packageType = useSelector(selectPackageType);
  return (
    <div className="medium-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Edit Package Type</h2>
        <PackageTypeForm packageType={packageType} />
      </div>
    </div>
  );
};
export default withPermission(
  withFetchOne(PackageTypeEdit, {
    fetchData: doGetPackageType
  }),
  canEditPermission
);
