import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import _find from "lodash/find";
import { showInfo, shortId } from "./util";

const splittedAddress = (address) => {
  let firstAddress = "N/A";
  let lastAddress = "N/A";
  if (address) {
    const firstCommaIdx = address.indexOf(",");
    if (firstCommaIdx !== -1) {
      firstAddress = address.substr(0, firstCommaIdx)?.trim();
      lastAddress = address.substr(firstCommaIdx + 1, address.length - 1)?.trim();
    } else {
      firstAddress = address;
    }
  }
  return {
    firstAddress,
    lastAddress
  };
};

const ellipsisAddressText = (text) => {
  return text?.length > 30 ? `${text.substr(0, 29)}...` : text;
};

const ellipsisPackageName = (text) => {
  return text?.length > 20 ? `${text.substr(0, 19)}...` : text;
};

const fillContentToFile = (doc, pack) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let startX = 3;
  let startY = 3;

  doc.setLineWidth(0.3);
  doc.line(startX, startY, pageWidth - startX, startY);
  doc.line(startX, pageHeight - startY, pageWidth - startX, pageHeight - startY);
  doc.line(startX, startY, startX, pageHeight - startY);
  doc.line(pageWidth - startX, startY, pageWidth - startX, pageHeight - startY);

  startX += 5;
  startY += 6;

  let img = new Image();
  img.crossOrigin = "anonymous";
  img.src = `/logos/${window.REACT_APP_LOGO_FILE_NAME}`;
  doc.addImage(img, "PNG", startX, startY, 30, 10);

  doc.setFontSize(10);
  doc.setTextColor("888888");
  doc.setFont("inter", "bold");
  doc.text("FROM:", pageWidth - 60, startY);

  startY += 6;

  doc.setTextColor("212121");
  doc.setFontSize(12);
  doc.text(showInfo(pack.job.pickupContactName), pageWidth - 60, startY);

  startY += 6;

  doc.setFontSize(10);
  doc.setFont("inter", "normal");
  doc.text(
    ellipsisAddressText(showInfo(pack.pickupLocation.location?.locationName)),
    pageWidth - 60,
    startY
  );

  startY += 3;

  doc.setFontSize(14);
  doc.setTextColor("1F3998");
  doc.setFont("inter", "bold");
  doc.text("Flexio Shift", startX + 2, startY);

  doc.line(startX + 35, startY - 21, startX + 35, startY + 8);

  startY += 1;

  doc.setFontSize(10);
  doc.setTextColor("212121");
  doc.setFont("inter", "normal");
  doc.text(
    ellipsisAddressText(splittedAddress(pack.job.pickupAddress).firstAddress),
    pageWidth - 60,
    startY
  );

  startY += 4;

  doc.text(
    ellipsisAddressText(splittedAddress(pack.job.pickupAddress).lastAddress),
    pageWidth - 60,
    startY
  );

  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 8;

  // img = new Image();
  // img.crossOrigin = "anonymous";
  // img.src = "/logos/client-logo.png";
  // doc.addImage(img, "PNG", startX, startY - 2);

  doc.setFontSize(10);
  doc.setTextColor("888888");
  doc.setFont("inter", "bold");
  doc.text("TO:", pageWidth - 60, startY);

  startY += 6;

  doc.setTextColor("212121");
  doc.setFontSize(12);
  doc.text(showInfo(pack.job.dropOffContactName), pageWidth - 60, startY);

  startY += 6;

  doc.setFontSize(10);
  doc.setFont("inter", "normal");
  doc.text(
    ellipsisAddressText(showInfo(pack.dropoffLocation.location?.locationName)),
    pageWidth - 60,
    startY
  );

  startY += 4;

  doc.setFontSize(10);
  doc.text(
    ellipsisAddressText(splittedAddress(pack.job.dropoffAddress).firstAddress),
    pageWidth - 60,
    startY
  );

  startY += 4;

  doc.text(
    ellipsisAddressText(splittedAddress(pack.job.dropoffAddress).lastAddress),
    pageWidth - 60,
    startY
  );

  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 10;

  doc.setFontSize(16);
  doc.text("Order ID:", startX, startY);
  doc.setFont("inter", "bold");
  doc.text(shortId(pack.job.routeId), startX + 26, startY);

  startY += 8;

  doc.setFontSize(10);
  doc.text("Package ID", startX, startY);
  doc.text("Quantity", pageWidth - 45, startY);

  startY += 5;

  doc.setFontSize(12);
  doc.setFont("inter", "normal");
  doc.text(ellipsisPackageName(showInfo(pack.name)), startX, startY);
  doc.text(pack.quantity + "", pageWidth - 45, startY);

  doc.line(startX - 5, startY + 3, pageWidth - startX + 5, startY + 3);

  startY += 8;

  doc.setFontSize(10);
  doc.setFont("inter", "bold");
  doc.text("Barcode Number", startX, startY);

  startY += 5;

  doc.setFontSize(12);
  doc.setFont("inter", "normal");
  doc.text(showInfo(pack.barcode), startX, startY);

  startY += 8;

  doc.setFontSize(10);
  doc.setFont("inter", "bold");
  doc.text("Driver Note", startX, startY);

  startY += 5;

  doc.setFontSize(12);
  doc.setFont("inter", "normal");
  doc.text(showInfo(pack?.dropoffLocation.location?.note), startX, startY);

  startY += 5;

  const packBarcodeId = "#packageBarcode";
  JsBarcode(packBarcodeId, pack.barcode);
  const packBarcodeImg = document.querySelector(`img${packBarcodeId}`);
  doc.addImage(packBarcodeImg.src, "JPEG", startX + 5, startY, 80, 30);
};

const generatePdfPackingSlip = (packages, jobs, routeLocations) => {
  if (!packages?.length || !jobs?.length) return;
  const mappedJobToPackages = packages
    .map((item) => ({
      ...item,
      job: _find(jobs, { id: item?.jobId }),
      dropoffLocation:
        _find(
          routeLocations,
          {
            jobId: item?.jobId,
            location: { type: "dropoff" }
          },
          {}
        ) || {},
      pickupLocation:
        _find(
          routeLocations,
          {
            jobId: item?.jobId,
            location: { type: "pickup" }
          },
          {}
        ) || {}
    }))
    .filter((pack) => pack.job);
  if (!mappedJobToPackages?.length) return;

  const firstPackage = mappedJobToPackages[0];

  const doc = new jsPDF("p", "mm", "a6", true);
  doc.addFont("/Inter-Light.ttf", "inter", "normal");
  doc.addFont("/Inter-Bold.ttf", "inter", "bold");

  fillContentToFile(doc, firstPackage);

  if (firstPackage?.quantity > 1) {
    let quantity = firstPackage?.quantity - 1;
    while (quantity > 0) {
      doc.addPage("a6");
      fillContentToFile(doc, firstPackage);
      quantity -= 1;
    }
  }

  for (let i = 1; i < mappedJobToPackages.length; i++) {
    let quantity = mappedJobToPackages[i]?.quantity || 0;
    while (quantity > 0) {
      doc.addPage("a6");
      fillContentToFile(doc, mappedJobToPackages[i]);
      quantity -= 1;
    }
  }

  return doc;
};

export default generatePdfPackingSlip;
