import React from "react";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";
import PhoneInput from "react-phone-input-2";
import "./index.scss";
const PhoneInputHook = ({
  disabled = false,
  className,
  type,
  placeholder = "",
  control,
  errors,
  name,
  label,
  required = false,
  showLabel,
  showOptionsName
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      <Controller
        placeholder={placeholder || name}
        control={control}
        name={name}
        render={({ field }) => (
          <PhoneInput
            country="ca"
            enableSearch
            {...field}
            type={type}
            disabled={disabled}
          />
        )}
      />
      {/* {errors[name] && <p className="errors">{errors[name].message}</p>} */}
    </FormItemHook>
  );
};
export { PhoneInputHook };
