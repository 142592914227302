import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Row, Select, Checkbox, Tabs } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { get } from "lodash";
import _keys from "lodash/keys";
import paginationFunc from "../../../../helper/hooks/pagination";
import FlexioPoolHeader from "../components/Header";
import FlexioPoolTable from "../components/FlexioPoolTable";
import FilterFlexioPool from "../components/Filter";
import PoolStatus from "components/items/PoolStatus";
import flexioPoolApi from "modules/client/pool/store/api";
import { POOL_PACKAGE_STATUSES } from "helper/status";
import { separateStatusUnderlineLetter } from "modules/shared/PickupDropoff/helper";
import { setSuccessMessage } from "app/global/slice";
import {
  selectMetadata,
  updatePaginationPackages
} from "modules/client/pool/store/slice";
import "modules/admin/orders/styles/routes.scss";
import { doExportCsv } from "helper/export";
import { refreshPackagesV2 } from "helper/getPackagesV2";
import { buildFirstLevelFilterPoolExport } from "helper/util";

const breadCrumbs = [{ text: "POOL PACKAGES" }];

const { TabPane } = Tabs;

const FlexioPoolList = () => {
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const metadata = useSelector(selectMetadata);
  const [axiosPackageList, setAxiosPackageList] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [batchStatusUpdate, setBatchStatusUpdate] = useState(false);
  const [packageArray, setPackageArray] = useState([]);
  const [selectAll, setSelectAll] = useState();
  const [newStatus, setNewStatus] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [submiting, setSubmiting] = useState(false);
  const pagination = useMemo(
    () =>
      paginationFunc(
        { ...metadata, itemsPerPage: metadata?.itemsPerPage || 20 },
        "Packages",
        updatePaginationPackages,
        dispatch
      ),
    [metadata, dispatch]
  );

  const myReduxState = useSelector((state) => state.myReducer);

  const page = useMemo(() => metadata?.currentPage || 1, [metadata?.currentPage]);
  const pageSize = useMemo(() => metadata?.itemsPerPage || 20, [metadata?.itemsPerPage]);

  const getPackages = useCallback(() => {
    refreshPackagesV2(
      filterValues,
      page,
      pageSize,
      setAxiosPackageList,
      updatePaginationPackages,
      dispatch
    );
  }, [filterValues, page, pageSize, dispatch]);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  // method that handles apply button filter request
  const handleClickFilter = () => {
    getPackages();
  };
  // method that handles Clear button reset
  const handleReset = () => {
    setFilterValues({});
  };

  const handleFilterChange = (newFilterValues) => {
    setFilterValues(newFilterValues);
  };

  const onExport = async () => {
    try {
      setExporting(true);
      await doExportCsv({
        path: "orderv2/export",
        name: `pool-orders-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilterPoolExport(
            filterValues?.pickupDateRange?.length > 0
              ? {
                  ...filterValues,
                  startPickupDate: filterValues.pickupDateRange[0],
                  endPickupDate: filterValues.pickupDateRange[1]
                }
              : {
                  ...filterValues
                }
          )
        }
      });
    } finally {
      setExporting(false);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setSubmiting(true);

      await flexioPoolApi.updatePackageStatus(selectedPackage?.id, {
        status: newStatus
      });

      dispatch(setSuccessMessage("Updated status successfully!"));
    } catch (err) {
      console.error("Error in handlePackageUpdate:", err);
    } finally {
      setSubmiting(false);
      setNewStatus(null);
      setShowUpdateStatus(false);
      getPackages();
    }
  };

  /* Adds all the packages visible on the current page to the array for
  batch status update*/

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const selectedPackages = selectAll ? [] : axiosPackageList?.map((pack) => pack.id);
    setPackageArray(selectedPackages);
  };

  /* Adds packages to the array for batch status update one by one by clicking the select
  checkbox on the modal table */

  const handlePackageArray = (pack) => {
    const tempArr = packageArray;
    /* Checks if the package already exists on the array to remove it if we uncheck the box */
    if (tempArr.includes(pack)) {
      const index = tempArr.indexOf(pack);
      tempArr.splice(index, 1);
      setPackageArray(tempArr);
    } else {
      packageArray.push(pack);
    }
  };

  /* Maps through the ids in the package array and performs the update on each one */

  const handleBatchUpdate = async () => {
    for (const packageId of packageArray) {
      try {
        setSubmiting(true);
        await flexioPoolApi.updatePackageStatus(packageId, {
          status: newStatus
        });
      } catch (err) {
        console.error("Error in handleBatchUpdate:", err);
      }
    }
    dispatch(setSuccessMessage("Updated status successfully!"));
    setSubmiting(false);
    setShowUpdateStatus(false);
    setBatchStatusUpdate(false);
    setPackageArray([]);
    setSelectAll(false);
    getPackages();
  };

  /* State for tab change */
  const handleTabChange = (activeKey) => {
    if (activeKey === "1") {
      setShowFilter(false);
      setFilterValues({});
    } else if (activeKey === "2") {
      setShowFilter(true);
      setFilterValues({ status: "submitted" });
    } else if (activeKey === "3") {
      setShowFilter(true);
      setFilterValues({ status: "at_warehouse" });
    } else {
      setShowFilter(false);
      setFilterValues({});
    }
  };

  return (
    <Tabs defaultActiveKey="1" type="card" onChange={handleTabChange}>
      <TabPane tab="All" key="1">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={setShowFilter}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
      <TabPane tab="Submitted" key="2">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={setShowFilter}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
      <TabPane tab="At Warehouse" key="3">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={true}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
    </Tabs>
  );
};
export default FlexioPoolList;
