import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../modules/auth/store/authSlice";
import DriverMainLayout from "layouts/privateDriver/MainLayout";
import { UserGroup } from "configs/constants";
import { DriverProfileProvider } from "layouts/privateDriver/DriverProfileContext";

const PrivateDriverRoute = (props) => {
  const { component: Component, isFullScreen = false, ...rest } = props;
  const authentication = useSelector(selectAuthentication);

  if (!authentication) {
    return (
      <Redirect
        to={{
          pathname: `/driver/login`
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(propsCom) => {
        return (
          <DriverProfileProvider>
            <DriverMainLayout isFullScreen={isFullScreen}>
              <Component {...propsCom} />
            </DriverMainLayout>
          </DriverProfileProvider>
        );
      }}
    />
  );
};
export default PrivateDriverRoute;
