import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  doCreateVehicleCategory,
  doUpdateVehicleCategory
} from "../../store/VehicleCategoryThunks";
import { getAllPackageType } from "../../store/PackageTypeThunks";
import { selectAllPackageTypes } from "../../store/PackageTypeSlice";
import "../../styles/settings.scss";
import { useState } from "react";

const VehicleCategoryForm = ({ vehicleCategory }) => {
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const packageTypes = useSelector(selectAllPackageTypes).map((packageType) => ({
    value: packageType.id,
    label: packageType.name
  }));
  const [packageTypesValue, setPackageTypesValue] = useState([]);

  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/settings/vehicle-categories");
  };

  const onSubmit = async (values) => {
    values = {
      ...values,
      ...{
        packageTypes: packageTypesValue.map((packageTypeId) => ({
          id: packageTypeId
        }))
      }
    };
    try {
      let erRs = undefined;
      if (!vehicleCategory) {
        const { error } = await dispatch(
          doCreateVehicleCategory({
            ...values
          })
        );
        erRs = error;
      } else {
        const { error } = await dispatch(
          doUpdateVehicleCategory({
            ...values,
            id: vehicleCategory?.id
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/settings/vehicle-categories");
    } catch {
      return false;
    }
  };

  function onChange(checkedValues) {
    setPackageTypesValue(checkedValues);
  }

  useEffect(() => {
    if (vehicleCategory && vehicleCategory.packageTypes) {
      setPackageTypesValue(
        vehicleCategory.packageTypes.map((packageType) => packageType.id)
      );
    }
    form.setFieldsValue(vehicleCategory);
  }, [vehicleCategory, form]);

  useEffect(() => {
    dispatch(getAllPackageType());
  }, [dispatch]);

  return (
    <div className="common-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Vehicle Category Name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Vehicle Category Name" />
          </Form.Item>
          <Form.Item
            label="Max Length"
            name="maxLength"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Length" suffix="inches" />
          </Form.Item>
          <Form.Item
            label="Max Weight"
            name="maxWeight"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Weight" suffix="lbs" />
          </Form.Item>
          <Form.Item
            label="Max Volume"
            name="maxVolume"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Volume" suffix="curf" />
          </Form.Item>
          <Form.Item label="Package Type Accepted">
            <Checkbox.Group
              className="column-checkbox"
              style={{ width: "100%" }}
              options={packageTypes}
              value={packageTypesValue}
              onChange={onChange}
            />
          </Form.Item>
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {vehicleCategory ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default VehicleCategoryForm;
