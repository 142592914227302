import { Form } from "antd";
import { BrowserUploadIcon } from "components/svgs/BrowserUploadIcon";
import { ImageIcon } from "components/svgs";
import React, { useRef, useState } from "react";
import "./index.scss";
import { DeleteIcon } from "components/svgs/DeleteIcon";
import request from "helper/request";
import { Spin } from "antd";
import { useEffect } from "react";
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 20971520;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUploadFormItem = ({
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  name,
  label,
  rules = [],
  setFieldsValue,
  getFieldValue,
  ...otherProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    setFiles(getFieldValue(name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFieldValue(name)]);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = async (e) => {
    setIsLoading(true);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);

      const filesAsArray = convertNestedObjectToArray(updatedFiles);
      const formData = new FormData();
      formData.append("file", filesAsArray[0]);

      const response = await request("/v1/files/guest", {
        method: "POST",
        data: formData,
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      setFiles(response);
      setFieldsValue({ [name]: response });
      setIsLoading(false);
    }
  };

  const removeFile = () => {
    setFiles({});
    setFieldsValue({ [name]: null });
  };

  return (
    <Form.Item label={label} name={name} rules={rules}>
      <div className="file-upload-hook">
        <Spin spinning={isLoading}>
          <article className="file-preview-container">
            <ImageIcon width="30px" height="30px" />
            <section className="preview-list">
              {files?.url && (
                <section className="preview-container">
                  <div className="wrap-image-preview">
                    <img
                      className="image-preview"
                      src={files?.url}
                      alt={`file preview `}
                    />
                    <DeleteIcon className="delete-icon" onClick={() => removeFile()} />
                  </div>
                </section>
              )}
            </section>
          </article>
        </Spin>
        <div className="file-upload-container">
          <label>Drag and drop your files anywhere or</label>
          <button
            className="upload-file-btn"
            type="button"
            onClick={handleUploadBtnClick}
          >
            Browse &nbsp; <BrowserUploadIcon />
          </button>
          <input
            className="form-fields"
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            {...otherProps}
          />
        </div>
      </div>
    </Form.Item>
  );
};

export default FileUploadFormItem;
