import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import ServiceTable from "../components/table/ServiceTable";
import { getServiceList } from "../store/ServiceThunks";

import {
  selectAllServices,
  selectMetadata,
  updatePaginationServices
} from "../store/ServiceSlice";
import "../styles/settings.scss";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";

const params = { group: "flexio" };
const breadCrumbs = [{ text: "SETTINGS" }, { text: "SERVICES" }];

const ServiceList = () => {
  const dispatch = useDispatch();
  const serviceList = useSelector(selectAllServices);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Vehicle Category",
    updatePaginationServices,
    dispatch
  );

  const buttonParams = allowPermission(useSelector(selectRole), canCreatePermission)
    ? {
        text: "Create Service",
        link: "/admin/settings/services/new"
      }
    : null;

  return (
    <div className="ServiceList">
      <SettingHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />
      <div className="admin-content">
        <ServiceTable dataSource={serviceList} pagination={pagination} />
      </div>
    </div>
  );
};
export default withFetchTablePagination(ServiceList, {
  fetchData: getServiceList,
  selectMeta: selectMetadata,
  params
});
