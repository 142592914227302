import { useWindowSize } from "helper/hooks";
import withFetchOne from "hocs/withFetchOne";
import ChartContainer from "modules/shared/PickupDropoff/chart/ChartContainer";
import { ONE_HOUR_PX } from "modules/shared/PickupDropoff/constants";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectOrder } from "../store/orderSlice";
import { doGetOrderRoutes } from "../store/thunks";
const OrderRouteDetailPage = () => {
  const size = useWindowSize();
  const height = size.height - 456;
  const [oneHourPx, setOneHourPx] = useState(ONE_HOUR_PX);

  const order = useSelector(selectOrder);
  const handleConfirmRoute = () => {};

  const handleZoomIn = () => {
    setOneHourPx(oneHourPx + oneHourPx / 10);
  };
  const handleZoomOut = () => {
    setOneHourPx(oneHourPx - oneHourPx / 10);
  };

  return (
    <div>
      <div>Order Route Detail</div>
      {order?.locations && (
        <PickupDropoffContainer
          stops={order?.locations}
          routes={order?.routes}
          oneHourPx={oneHourPx}
        >
          {({
            handleUpdateAllRoutes,
            handleUpdateRoute,
            handleClickMarker,
            handleHoverMarker,
            popupData,
            handleClosePopup,
            goToCoupleStop,
            handleStopDetail,
            markerActive,
            center,
            containerStops,
            originStops,
            containerRoutes,
            mapStops,
            bounds,
            displayAll,
            driverDetail,
            setDriverDetail,
            showDriverDetail,
            setShowDriverDetail,
            groupStops,
            onDragEnd,
            onDragStart,
            handleClickHistory,
            history,
            earliestRoute,
            disabledNextButton,
            disabledPreviousButton,
            dndStatus
          }) => (
            <>
              <MapBox
                handleClosePopup={handleClosePopup}
                handleClickMarker={handleClickMarker}
                handleHoverMarker={handleHoverMarker}
                stops={containerStops}
                center={center}
                routes={containerRoutes}
                popupData={popupData}
                goToCoupleStop={goToCoupleStop}
                height={height}
                markerActive={markerActive}
              />
              <ChartContainer
                showConfirmButton={false}
                handleConfirmRoute={handleConfirmRoute}
                routes={containerRoutes}
                stops={containerStops}
                firstPickup={{}}
                order={order}
                handleClickMarker={handleClickMarker}
                handleHoverMarker={handleHoverMarker}
                handleClosePopup={handleClosePopup}
                handleUpdateRoute={handleUpdateRoute}
                handleUpdateAllRoutes={handleUpdateAllRoutes}
                displayAll={displayAll}
                setDriverDetail={setDriverDetail}
                setShowDriverDetail={setShowDriverDetail}
                groupStops={groupStops}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                handleClickHistory={handleClickHistory}
                history={history}
                earliestRoute={earliestRoute}
                disabledNextButton={disabledNextButton}
                disabledPreviousButton={disabledPreviousButton}
                dndStatus={dndStatus}
                oneHourPx={oneHourPx}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
              />
            </>
          )}
        </PickupDropoffContainer>
      )}
    </div>
  );
};

export default withFetchOne(OrderRouteDetailPage, {
  fetchData: doGetOrderRoutes
});
