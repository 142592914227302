import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateVehicleCategory,
  doDeleteVehicleCategory,
  doGetVehicleCategory,
  getVehicleCategoryList,
  getAllVehicleCategories
} from "./VehicleCategoryThunks";

const VehicleCategoryAdapter = createEntityAdapter({
  selectId: (vehicleCategory) => vehicleCategory.id
});

const initialState = VehicleCategoryAdapter.getInitialState({
  metadata: {},
  vehicleCategory: {}
});

export const vehicleCategorySlice = createSlice({
  name: "vehicleCategories",
  initialState,
  reducers: {
    updatePaginationVehicleCategories(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVehicleCategories.fulfilled, (state, { payload }) => {
      VehicleCategoryAdapter.setAll(state, payload);
      state.vehicleCategory = {};
      state.metadata = {};
    });
    builder.addCase(getVehicleCategoryList.fulfilled, (state, { payload }) => {
      VehicleCategoryAdapter.setAll(state, payload.data);
      state.vehicleCategory = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetVehicleCategory.fulfilled, (state, { payload }) => {
      state.vehicleCategory = payload;
    });
    builder.addCase(doCreateVehicleCategory.fulfilled, (state, { payload }) => {
      VehicleCategoryAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDeleteVehicleCategory.fulfilled, (state, { payload }) => {
      VehicleCategoryAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectVehicleCategoryById,
  selectIds: selectVehicleCategoryIds,
  selectEntities: selectVehicleCategoryEntities,
  selectAll: selectAllVehicleCategories,
  selectTotal: selectTotalVehicleCategories
} = VehicleCategoryAdapter.getSelectors((state) => state.vehicleCategories);

export const selectMetadata = (state) => state.vehicleCategories.metadata;
export const selectVehicleCategory = (state) => state.vehicleCategories.vehicleCategory;

export const { updatePaginationVehicleCategories } = vehicleCategorySlice.actions;

export default vehicleCategorySlice.reducer;
