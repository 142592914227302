import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AdminUserClientTable from "../components/table/AdminUserClientTable";
import {
  selectAllUsers,
  selectFilter,
  selectMetadata,
  updateFilterUsers,
  updatePaginationUsers
} from "../store/userSlice";
import { getUserList } from "../store/thunks";
import paginationFunc from "../../../../helper/hooks/pagination";
import "../styles/commonUser.scss";
import { doExportCsv } from "helper/export";
import AdminClientHeader from "modules/admin/clients/components/header/AdminClientHeader";
import { buildFirstLevelFilter } from "modules/admin/users/helper";
import FilterClientUser from "../components/header/FilterClientUser";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";
import { selectGroup } from "modules/auth/store/authSlice";
import { UserGroup } from "configs/constants";

const params = { group: "client" };
const breadCrumbs = [{ text: "USERS" }, { text: "CLIENTS" }];

const AdminUserClientList = () => {
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const buttonParams =
    allowPermission(useSelector(selectRole), canCreatePermission) && isFlexioAdmin
      ? {
          text: "Create Client User",
          link: "/admin/users/clients/new"
        }
      : null;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFilterUsers(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientList = useSelector(selectAllUsers);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Client User",
    updatePaginationUsers,
    dispatch
  );
  const [showFilter, setShowFilter] = useState(false);
  const [exporting, setExporting] = useState(false);
  const filter = useSelector(selectFilter);
  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 10;

  useEffect(() => {
    delete params?.clientType;
    if (filter?.clientType) {
      params.clientType = filter.clientType;
    }
    dispatch(
      getUserList({
        ...params,
        page,
        pageSize,
        filter: buildFirstLevelFilter(filter)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleExport = async () => {
    try {
      setExporting(true);
      await doExportCsv({
        path: "admin/users/export?group=client",
        name: `Clients-List-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilter({ ...filter })
        }
      });
    } finally {
      setExporting(false);
    }
  };

  const handleClickFilter = (values) => {
    dispatch(updateFilterUsers(values));
  };

  return (
    <div className="admin-user-client-list">
      <AdminClientHeader
        breadCrumbs={breadCrumbs}
        buttonParams={buttonParams}
        exporting={exporting}
        handleExport={handleExport}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      {showFilter && (
        <FilterClientUser
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterUsers(undefined))}
        />
      )}
      <div className="admin-content">
        <AdminUserClientTable dataSource={clientList} pagination={pagination} />
      </div>
    </div>
  );
};

export default AdminUserClientList;
