import { Button } from "antd";
import React, { useEffect, useState } from "react";

import "./PackageListDrop.scss";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";

const PackageListDrop = ({ id, packages, selectedPack, isAll }) => {
  const [packsByType, setPackByType] = useState([]);
  const packageTypeList = useSelector(selectAllPackageTypes);

  useEffect(() => {
    const rs = {};
    packages.forEach((pack) => {
      const packageType = packageTypeList.find((p) => p.id === pack.packageTypeId);
      rs[packageType?.name] = rs[packageType?.name] || [];
      rs[packageType?.name].push({
        ...pack,
        quantity:
          pack?.dropoffs?.find((drop) => drop.id === id)?.quantity || pack.quantity
      });
    });
    setPackByType(rs);
  }, [id, packages, packageTypeList]);

  return (
    <div className="PackageListDrop">
      <h1>PACKAGE REVIEW</h1>
      {Object.keys(packsByType)?.length > 0 &&
        Object.keys(packsByType).map((key) => {
          const packs = packsByType[key];
          return (
            <div className="package-item" key={key}>
              <div className="top">
                <div className="content">
                  <b>{key} Package</b>
                </div>
              </div>
              <div className="bottom">
                {packs.map((pa, i) => (
                  <p key={pa.id}>
                    {i + 1}. {pa.name} x {pa.quantity}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
      {!isAll && (
        <Button size="large" className="selected-pack" onClick={selectedPack}>
          Select Package <ReactSVG src="/icons/selectPack.svg" className="icon" />
        </Button>
      )}
    </div>
  );
};
export default PackageListDrop;
