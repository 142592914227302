import request from "helper/request";

const driverApi = {
  createPassword: (params) => {
    return request.post("/drivers/create-password", {
      ...params
    });
  },
  saveAdvancedInfo: (params) => {
    return request.post("/drivers/update-driver", {
      ...params
    });
  }
};

export default driverApi;
