import React from "react";
import { useSelector } from "react-redux";

import CustomDatePicker from "../../../../../components/datepicker/CustomDatePicker";
import FormItem from "../../../../../components/form/FormItem";
import { DAY_DATE_FORMAT } from "../../../../../configs/constants";
import { selectNewOrder } from "../../store/orderSlice";
import ServiceType from "./components/ServiceType";
import "./ServiceStep.scss";

const ServiceStep = ({
  flexioServices,
  routes,
  setRoutes,
  pickupDate,
  setPickupDate,
  estimatePriceOrganization
}) => {
  const newOrder = useSelector(selectNewOrder);

  return (
    <div className="service-step-component">
      <div className="title">SELECT SERVICE</div>
      <FormItem label="Pickup Date" className="pickup-date-item">
        <CustomDatePicker
          value={pickupDate}
          format={DAY_DATE_FORMAT}
          onChange={(val) => setPickupDate(val)}
        />
      </FormItem>

      {newOrder?.isConfirmRoute && (
        <div className="wrap-plan-service">
          {flexioServices.map((service) => (
            <ServiceType
              key={service.id}
              service={service}
              routes={routes}
              setRoutes={setRoutes}
              estimatePriceOrganization={estimatePriceOrganization}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceStep;
