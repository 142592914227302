import { createAsyncThunk } from "@reduxjs/toolkit";
import notificationSettingApi from "./api";

export const doGetNotificationSettingList = createAsyncThunk(
  "notification-settings/get/loading",
  async (params) => {
    return notificationSettingApi.getNotificationSettingList(params);
  }
);

export const doCreateNotificationSetting = createAsyncThunk(
  "notification-settings/create/loading",
  async (data) => {
    return notificationSettingApi.createNotificationSetting(data);
  }
);

export const doUpdateNotificationSetting = createAsyncThunk(
  "notification-settings/update/loading",
  async (data) => {
    return notificationSettingApi.updateNotificationSetting(data);
  }
);
