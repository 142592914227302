import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateNotificationSetting,
  doUpdateNotificationSetting,
  doGetNotificationSettingList
} from "./thunks";

const NotificationSettingAdapter = createEntityAdapter({
  selectId: (notificationSetting) => notificationSetting.id
});

const initialState = NotificationSettingAdapter.getInitialState({
  metadata: {},
  notificationSetting: {}
});

export const notificationSettingsSlice = createSlice({
  name: "notificationSettings",
  initialState,
  reducers: {
    updatePaginationNotificationSettings(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetNotificationSettingList.fulfilled, (state, { payload }) => {
      NotificationSettingAdapter.setAll(state, payload.data);
      state.notificationSetting = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doCreateNotificationSetting.fulfilled, (state, { payload }) => {
      NotificationSettingAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doUpdateNotificationSetting.fulfilled, (state, { payload }) => {
      NotificationSettingAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload }
      });
    });
  }
});

export const {
  selectById: selectNotificationSettingById,
  selectIds: selectNotificationSettingIds,
  selectEntities: selectNotificationSettingEntities,
  selectAll: selectAllNotificationSettings,
  selectTotal: selectTotalNotificationSettings
} = NotificationSettingAdapter.getSelectors((state) => state.notificationSettings);

export const selectMetadata = (state) => state.notificationSettings.metadata;
export const selectNotificationSetting = (state) =>
  state.notificationSettings.notificationSetting;

export const { updatePaginationNotificationSettings } = notificationSettingsSlice.actions;

export default notificationSettingsSlice.reducer;
