/* eslint-disable react/display-name */
import { PlusOutlined, FunnelPlotOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
import "./AdminClientHeader.scss";
import { ImportIcon } from "components/svgs/ImportIcon";
import { ExportIcon } from "components/svgs/ExportIcon";
import { FilterIcon } from "components/svgs";

const AdminClientHeader = ({
  breadCrumbs,
  buttonParams,
  hasButton = true,
  showFilter,
  setShowFilter,
  handleExport,
  hasImport,
  setShowImport
}) => {
  return (
    <div className="AdminClientHeader">
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons d-flex flex-justify-end gap-3">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
          {handleExport && (
            <Button
              type="third"
              size="small"
              className={`svg-icon d-flex flex-row`}
              onClick={handleExport}
            >
              Export <ExportIcon />
            </Button>
          )}
          {hasImport && (
            <Button
              size="small"
              type="third"
              className={`svg-icon  d-flex flex-row`}
              onClick={setShowImport}
            >
              Import <ImportIcon />
            </Button>
          )}
          {buttonParams && (
            <Link to={buttonParams.link}>
              <Button type="primary" size="small">
                {buttonParams.text}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default AdminClientHeader;
