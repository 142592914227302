import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

import { UserGroup, canCreatePermission } from "configs/constants";
import { doExportCsv } from "helper/export";
import { allowPermission } from "helper/util";
import AdminClientHeader from "modules/admin/clients/components/header/AdminClientHeader";
import { buildFirstLevelFilter } from "modules/admin/drivers/helper";
import { selectGroup, selectRole } from "modules/auth/store/authSlice";
import paginationFunc from "helper/hooks/pagination";
import { setGlobalLoading } from "app/global/slice";

import FilterDriver from "../components/FilterDriver";
import ImportDriver from "../components/ImportDriver";
import {
  selectAllDrivers,
  selectFilter,
  selectMetadata,
  updateFilterDrivers,
  updatePaginationDrivers
} from "../store/slice";
import { doDeleteDriver, doImportDrivers, getDriverList } from "../store/thunks";
import DriverTable from "../tables/DriverTable";

const breadCrumbs = [{ text: "USERS" }, { text: "DRIVERS" }];

const ListDriverPage = () => {
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const buttonParams =
    allowPermission(useSelector(selectRole), canCreatePermission) && isFlexioAdmin
      ? {
          text: "Add New Driver",
          link: "/admin/users/drivers/new"
        }
      : null;
  const dispatch = useDispatch();
  const driverList = useSelector(selectAllDrivers);
  const metadata = useSelector(selectMetadata);
  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 10;

  const pagination = paginationFunc(
    metadata,
    "Drivers",
    updatePaginationDrivers,
    dispatch
  );
  const [showFilter, setShowFilter] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [importing, setImporting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const filter = useSelector(selectFilter);

  useEffect(() => {
    dispatch(
      getDriverList({
        page: page,
        pageSize: pageSize,
        filter: buildFirstLevelFilter(filter)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, filter]);

  const handleDeleteDriver = (id) => {
    dispatch(doDeleteDriver(id));
  };

  const handleExport = async () => {
    try {
      setExporting(true);
      await doExportCsv({
        path: "admin/drivers/export",
        name: `Drivers-List-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilter({ ...filter })
        }
      });
    } finally {
      setExporting(false);
    }
  };

  const handleClickFilter = (values) => {
    dispatch(updateFilterDrivers(values));
  };

  const handleImportDrivers = async (payload) => {
    try {
      setImporting(true);
      dispatch(setGlobalLoading({ isLoading: true }));
      const { error } = await dispatch(doImportDrivers(payload));
      if (!error) {
        setShowImport(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setImporting(false);
      dispatch(setGlobalLoading({ isLoading: false }));
    }
  };

  return (
    <div className="list-driver-page">
      <AdminClientHeader
        breadCrumbs={breadCrumbs}
        buttonParams={buttonParams}
        exporting={exporting}
        handleExport={handleExport}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        hasImport={true}
        setShowImport={setShowImport}
      />
      {showFilter && (
        <FilterDriver
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterDrivers(undefined))}
        />
      )}
      <div className="content-table">
        <DriverTable
          doDeleteDriver={handleDeleteDriver}
          dataSource={driverList}
          pagination={{ ...pagination, size: "small" }}
        />
      </div>
      {showImport && (
        <Modal
          closable={false}
          visible={true}
          title={"Import Drivers Data"}
          className="no-padding-modal"
          onCancel={() => setShowImport(false)}
          handleImportDrivers
          footer={false}
          width={1000}
        >
          <ImportDriver importing={importing} onImport={handleImportDrivers} />
        </Modal>
      )}
    </div>
  );
};
export default ListDriverPage;
