import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getRouteHistoryList } from "./thunks";

const RoutesHistoryAdapter = createEntityAdapter({
  selectId: (history) => history.id
});

const defaultHistoryFilter = { status: ["canceled", "completed"] };
const initialState = RoutesHistoryAdapter.getInitialState({
  metadata: {},
  filter: defaultHistoryFilter
});

export const routeHistorySlice = createSlice({
  name: "routesHistory",
  initialState,
  reducers: {
    updatePaginationRoutesHistory(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterRoutes(state, action) {
      state.filter = { ...action.payload, ...defaultHistoryFilter };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRouteHistoryList.fulfilled, (state, { payload }) => {
      RoutesHistoryAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectRouteHistoryById,
  selectIds: selectRouteHistoryIds,
  selectEntities: selectRouteHistoryEntities,
  selectAll: selectAllRoutesHistory,
  selectTotal: selectTotalRouteHistory
} = RoutesHistoryAdapter.getSelectors((state) => {
  return state.routesHistory;
});

export const selectMetadata = (state) => state.routesHistory.metadata;
export const selectFilter = (state) => state.routesHistory.filter;
export const selectRouteHistory = (state) => state.routesHistory;

export const { updatePaginationRoutesHistory, updateFilterRoutes } =
  routeHistorySlice.actions;

export default routeHistorySlice.reducer;
