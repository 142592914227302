import React from "react";

import AdminClientHeader from "../components/header/AdminClientHeader";
import "./AdminUserCreateClient.scss";
import TerritoryForm from "../components/form/TerritoryForm";
import { withPermission } from "hocs/withPermission";
import { UserRole } from "configs/constants";

const AdminUserCreateTerritory = () => {
  const breadCrumbs = [
    { text: "USERS" },
    { url: "/admin/territories/clients", text: "TERRITORIES" },
    { text: "NEW" }
  ];
  return (
    <div className="admin-user-create-client admin-create-update-client">
      <AdminClientHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create Territories User</h2>
        <TerritoryForm />
      </div>
    </div>
  );
};
export default withPermission(AdminUserCreateTerritory, [UserRole.ADMIN]);
