import React, { useEffect, useState } from "react";
import {
  selectAllRoutesHistory,
  selectFilter,
  selectMetadata,
  updateFilterRoutes,
  updatePaginationRoutesHistory
} from "../store/slice";
import { getRouteHistoryList } from "../store/thunks";
import paginationFunc from "../../../../helper/hooks/pagination";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import RouteListTable from "modules/client/routes/components/tables/RouteListTable";
import AdminBreadCrumb from "components/AdminBreadCrumb";
import { buildFirstLevelFilterRoute } from "modules/shared/PickupDropoff/helper";
import FilterRoute from "modules/client/routes/components/header/FilterRoute";
import { FilterIcon } from "components/svgs";
import { Button } from "antd";

const RouteHistoryListPage = ({ isAdmin }) => {
  const breadCrumbs = [
    { text: "HISTORY JOBS", url: `${isAdmin ? "/admin" : ""}/history` }
  ];
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const routeList = useSelector(selectAllRoutesHistory);
  const metadata = useSelector(selectMetadata);
  const filter = useSelector(selectFilter);
  const defaultPerPage = isAdmin ? 10 : 20;

  const pagination = paginationFunc(
    { ...metadata, itemsPerPage: get(metadata, "itemsPerPage", defaultPerPage) },
    "History Job",
    updatePaginationRoutesHistory,
    dispatch
  );

  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || defaultPerPage;

  useEffect(() => {
    const { pickupDateRange = [] } = filter || {};
    dispatch(
      getRouteHistoryList({
        page: page,
        pageSize: pageSize,
        filter: buildFirstLevelFilterRoute(
          pickupDateRange?.length === 2
            ? {
                ...filter,
                startPickupDate: pickupDateRange[0],
                endPickupDate: pickupDateRange[1]
              }
            : filter
        ),
        pickupFilter: filter
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleClickFilter = (values) => {
    dispatch(updateFilterRoutes(values));
  };

  return (
    <div className="client-order-list-page">
      <div className={`order-header-component my-4 ${showFilter && "filter"}`}>
        <AdminBreadCrumb breadCrumbs={breadCrumbs} />
        <div className="buttons d-flex flex-justify-end gap-3">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
        </div>
      </div>
      {showFilter && (
        <FilterRoute
          isAdmin={isAdmin}
          isHistoryPage={true}
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterRoutes(undefined))}
        />
      )}
      <div className="content-table">
        <RouteListTable
          isAdmin={isAdmin}
          dataSource={routeList}
          pagination={{ ...pagination, size: "small" }}
        />
      </div>
    </div>
  );
};

export default RouteHistoryListPage;
