import request from "../../../../helper/request";

const internalDriverApi = {
  getDriverList: (params) => {
    console.log(params);
    return request.get("/drivers", {
      params
    });
  },
  createInternalDriver: (data) => {
    return request.post("/drivers/internal", data);
  },
  updateInternalDriver: (data) => {
    return request.patch(`/drivers/${data.id}`, data);
  },
  delete: (data) => {
    return request.delete(`/drivers/${data?.id}`);
  }
};

export default internalDriverApi;
