export const markerIcon = () => {
  const icon = {};
  [
    "delivery_pickup",
    "arrivedAt_pickup",
    "completedAt_pickup",
    "delivery_dropoff",
    "arrivedAt_dropoff",
    "completedAt_dropoff",
    "dropoff",
    "pickup",
    "stop",
    "driver",
    "current-driver",
    "current-pickup",
    "current-dropoff"
  ].forEach((type) => {
    const img = new Image();
    img.src = `/markers/${type}.png`;
    icon[type] = img;
  });
  return icon;
};

export const markerLayer = (type, image, sdf = true) => {
  return {
    type: "symbol",
    images: [type, image, { sdf }],
    layout: {
      "icon-image": type,
      "icon-size": 0.5,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-offset": [0, 0],
      "text-field": "{label}",
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 10,
      "text-allow-overlap": false,
      "text-ignore-placement": false,
      "text-offset": [0, -0.4]
    },
    paint: {
      "icon-color": { type: "identity", property: "color" },
      "icon-halo-width": 1,
      "icon-halo-color": { type: "identity", property: "color" },
      "text-color": { type: "identity", property: "colorText" }
    }
  };
};
