import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

import { Button, Menu, Dropdown } from "antd";
import { GiftTwoTone } from "@ant-design/icons";
import "./HeaderContent.scss";
import UserInfo from "../components/Users/UserInfo";
import { AddIcon } from "components/svgs";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";
import { QuestionIcon } from "components/svgs";
import { UserRole } from "configs/constants";
import { ProfileContext, ProfileProvider } from "./ProfileContext";
import { FeatureFlagsContext } from "./FeatureFlagsContext";

const SIDER_KEYS = Object.freeze({
  route: "routes",
  pool: "pool",
  history: "history",
  driver: "drivers",
  clientUser: "clientUsers"
});

const HeaderContentMemo = () => {
  // dumbComponent || statelessComponents
  const user = useSelector((state) => state.auth.user);
  const role = useSelector(selectRole);
  const canCreate = allowPermission(role, canCreatePermission);
  const [siderKey] = useState(window.location.pathname.split("/")[1] || SIDER_KEYS.route);
  const featureCtx = useContext(FeatureFlagsContext);
  const isPoolFeatureEnable = useMemo(() => featureCtx?.isPoolAvailable, [featureCtx]);
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const isSubscriptionActive = useMemo(
    () => profileCtx?.isSubscriptionActive,
    [profileCtx]
  );
  const menuItems = [
    {
      key: "dashboard",
      label: (
        <Link to="/dashboard">
          <span className="anticon">
            <ReactSVG src="/icons/dashboard.svg" />
          </span>{" "}
          Dashboard
        </Link>
      )
    },
    {
      key: SIDER_KEYS.route,
      label: (
        <Link to="/routes">
          <span className="anticon">
            <ReactSVG src="/icons/assignment.svg" />
          </span>{" "}
          Routes
        </Link>
      )
    },
    isPoolFeatureEnable && {
      key: SIDER_KEYS.pool,
      label: (
        <Link to="/pool">
          <span className="anticon">
            <GiftTwoTone twoToneColor={"#43C1C2"} />
          </span>{" "}
          Flexio Pool
        </Link>
      )
    },
    {
      key: SIDER_KEYS.history,
      label: (
        <Link to="/history">
          <span className="anticon">
            <ReactSVG src="/icons/history.svg" />
          </span>{" "}
          History
        </Link>
      )
    },
    {
      key: SIDER_KEYS.clientUser, // ??
      label: role === UserRole.ADMIN && (
        <Link to="/client-users">
          <span className="anticon">
            <ReactSVG src="/icons/group.svg" />
          </span>{" "}
          Users
        </Link>
      )
    },
    {
      key: SIDER_KEYS.driver,
      label: (
        <Link to="/drivers">
          <span className="anticon">
            <ReactSVG src="/icons/local_shipping.svg" />
          </span>{" "}
          My Drivers
        </Link>
      )
    },
    {
      key: "feedbacks",
      label: (
        <Link to="/feedbacks">
          <span className="anticon">
            <ReactSVG src="/icons/feedback.svg" />
          </span>{" "}
          Feedback
        </Link>
      )
    }
  ];

  const items = [
    {
      key: "help-center",
      label: (
        <div className="item">
          <a href="https://support.goflexio.com" target="_blank" rel="noreferrer">
            Help Center
          </a>
        </div>
      )
    },
    {
      key: "tutorial-video",
      label: (
        <div className="item">
          <a href="https://youtu.be/TaI-YPzOygM" target="_blank" rel="noreferrer">
            Tutorial Video
          </a>
        </div>
      )
    },
    {
      key: "specialist-calendar",
      label: (
        <div className="item">
          <a
            href="https://meetings.hubspot.com/iraymond/route-upload-support"
            target="_blank"
            rel="noreferrer"
          >
            Talk to a Specialist
          </a>
        </div>
      )
    }
  ];

  return (
    <div>
      <div className="header-content">
        <div className="logo">
          <Link to="/">
            <img src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`} alt="logo" />
          </Link>
        </div>
        <ProfileProvider>
          <UserInfo isUser={true} user={user} />
        </ProfileProvider>
      </div>
    </div>
  );
};

export const HeaderContent = React.memo(HeaderContentMemo);
