import request from "../../../../helper/request";

const adminApi = {
  getOrderList: (params) => {
    return request.get("/admin/orders", {
      params
    });
  },
  getAllOrder: () => {
    return request.get("/admin/orders/all");
  },
  createOrder: (data) => {
    console.log(data);
    return request.post("/admin/orders", data);
  },
  updateOrder: (data) => {
    const id = data.id;
    delete data.id;
    console.log(data);
    return request.patch(`/admin/orders/${id}`, data);
  },
  getOrder: (id) => {
    console.log(id);
    return request.get(`/admin/orders/${id}`);
  },
  deleteOrder: (id) => {
    return request.delete(`admin/orders/${id}`);
  },
  getRouteList: (params) => {
    return request.get("/admin/routes", {
      params
    });
  },
  getRoute: (id) => {
    return request.get(`/admin/routes/${id}`);
  },
  assignDriver: (routeId, driverId) => {
    return request.patch(`/admin/routes/${routeId}/assign-driver`, { driverId });
  },
  reassignDriver: (routeId, driverId) => {
    return request.patch(`/admin/routes/${routeId}/reassign-driver`, { driverId });
  },
  unassignDriver: (routeId) => {
    return request.patch(`/admin/routes/${routeId}/unassign-driver`);
  },
  cancelRoute: (routeId) => {
    return request.patch(`/admin/routes/${routeId}/cancel-route`);
  },
  getLocation: (id) => {
    return request.get(`/admin/locations/${id}`);
  },
  updateLocation: (data) => {
    const payload = { ...data };
    delete payload.id;
    return request.patch(`/admin/locations/${data.id}`, data);
  },
  updatePrice: (route) => {
    const { id, price, type, user, clientName } = route;
    const params = JSON.stringify({ price, type, user, clientName });

    return request.patch(`/admin/routes/${id}/price`, params);
  }
};

export default adminApi;
