import { TIME_FORMAT_BACKEND } from "configs/constants";
import moment from "moment";
import { ONE_HOUR_PX } from "./constants";
import _ from "lodash";
import { convertObjectToArray } from "helper/util";
import { DATE_FORMAT_FRONTEND } from "configs/constants";
export const getBounds = (listCoordinate) => {
  let x0, x1, y0, y1;
  listCoordinate.forEach((coordinate) => {
    if (!x0) {
      x0 = x1 = coordinate.lat;
      y0 = y1 = coordinate.lng;
    } else {
      if (coordinate.lat > x1) x1 = Number(coordinate.lat);
      if (coordinate.lat < x0) x0 = Number(coordinate.lat);
      if (coordinate.lng > y1) y1 = Number(coordinate.lng);
      if (coordinate.lng < y0) y0 = Number(coordinate.lng);
    }
  });

  const latCenter = (Number(x1) + Number(x0)) / 2;
  const lngCenter = (Number(y1) + Number(y0)) / 2;
  return {
    bounds: [
      [Number(y1), Number(x1)],
      [Number(y0), Number(x0)]
    ],
    center: {
      lat: Number(latCenter),
      lng: Number(lngCenter)
    }
  };
};

export const convertJobsToLocations = (jobs) => {
  let locations = [];
  let sortNo = 0;
  let sortNoUnscheduled = 0;
  jobs.map((job) => {
    locations.push({
      ...job.pickup,
      routeId: job?.routeId,
      color: job?.color,
      id: `pickup__${job.id}`,
      jobId: job.id
    });
    locations.push({
      ...job.dropoff,
      routeId: job?.routeId,
      color: job?.color,
      id: `dropoff__${job.id}`,
      jobId: job.id
    });
  });
  locations = locations?.sort(sortBy("arrivalTime", false));
  locations = locations.map((location) => {
    if (location.routeId === "unscheduled") {
      sortNoUnscheduled += 1;
    } else {
      sortNo = location?.sortNo;
    }
    return {
      ...location,
      sortNo: location.routeId === "unscheduled" ? sortNoUnscheduled : sortNo
    };
  });
  return locations;
};

export const addOrRemoveElementInArray = (array, id) => {
  let tmpArray = [...array];
  const index = tmpArray.indexOf(id);
  if (index > -1) {
    tmpArray.splice(index, 1);
  } else {
    tmpArray.push(id);
  }
  return tmpArray;
};
export const convertToUrlDate = (date) => {
  return date.replaceAll("/", "-");
};

export const assignBy = (key) => {
  return (data, item) => {
    data[item[key]] = item;
    return data;
  };
};

export const convertArrayToObject = (data, key) => {
  return data.reduce(assignBy(key), {});
};

export const shortText = (text, length = 20) => {
  if (!text) {
    return "";
  }
  return `${text.slice(0, length)} ${text.length > length ? "...." : ""}`;
};

function trimString(s) {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == " ") l++;
  while (r > l && s[r] == " ") r -= 1;
  return s.substring(l, r + 1);
}

function compareObjects(o1, o2) {
  var k = "";
  for (k in o1) if (o1[k] != o2[k]) return false;
  for (k in o2) if (o1[k] != o2[k]) return false;
  return true;
}

const itemExists = (haystack, needle) => {
  for (var i = 0; i < haystack.length; i++)
    if (compareObjects(haystack[i], needle)) return true;
  return false;
};

export const searchFor = (toSearch, objects) => {
  var results = [];
  toSearch = trimString(toSearch).toLowerCase();
  for (var i = 0; i < objects.length; i++) {
    for (var key in objects[i]) {
      if (String(objects[i][key]).toLowerCase().indexOf(toSearch) != -1) {
        if (!itemExists(results, objects[i])) results.push(objects[i]);
      }
    }
  }
  return results;
};

export const uniqArray = (arr1, arr2) => {
  if (arr1.length === 0) {
    return [];
  }
  const arr3 = [];
  arr1.map((obj) => {
    const found = arr2.find((ele) => ele.driverId === obj.id);
    if (!found) {
      arr3.push(obj);
    }
  });
  return arr3;
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const sortByColor = (colors = [], colorFirst = "") => {
  if (!colors.includes(colorFirst)) return colors;

  const filterColors = colors.filter((cl) => cl !== colorFirst);

  return [colorFirst, ...filterColors];
};

export const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const sortBy = (field, reverse, primer) => {
  const key = primer
    ? function (x) {
        return primer(x[field]);
      }
    : function (x) {
        return x[field];
      };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
};

export const convertToMinutes = (time) => {
  const timeSplit = time.split(":");

  const hour = timeSplit[0];
  const minute = timeSplit[1];

  return parseInt(hour) * 60 + parseInt(minute);
};
export const getFirstStartAndLastEnd = (drivers) => {
  if (drivers.length === 0) return [0, 0];
  const firstRoute = drivers[0];
  let returnStart = firstRoute.shiftStart;
  let returnEnd = firstRoute.shiftEnd;
  let firstStart = convertToMinutes(drivers[0]?.shiftStart);
  let lastEnd = convertToMinutes(drivers[0]?.shiftEnd);

  drivers.forEach((driver) => {
    const minuteStartAt = convertToMinutes(driver?.shiftStart);
    const minuteEndAt = convertToMinutes(driver?.shiftEnd);
    if (minuteStartAt < firstStart) {
      firstStart = minuteStartAt;
      returnStart = driver.shiftStart;
    }

    if (lastEnd < minuteEndAt) {
      lastEnd = minuteEndAt;
      returnEnd = driver.shiftEnd;
    }
  });
  return [returnStart, returnEnd];
};

export const durationMinutes = (start, end) => {
  if (!start || !end) return 0;
  return convertToMinutes(end) - convertToMinutes(start);
};

export const convertMinutesToPixel = (minutes, oneHourPx = ONE_HOUR_PX) => {
  // 1 hour = 98px =>  60 minutes = 98px
  return (minutes * oneHourPx) / 60;
};

export const getWidthChart = (start = "00:00", end = "23:59", oneHourPx) => {
  const duration = durationMinutes(start, end);
  return convertMinutesToPixel(duration, oneHourPx);
};

export const numberWithCommas = (numb) => {
  if (!numb) return;
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str?.join(".");
};

export const checkShowAll = (drivers) => {
  let countHide = 0;
  drivers.forEach((driver) => {
    driver.display && countHide++;
  });
  return countHide === drivers.length;
};
export const checkLockAll = (drivers) => {
  let countHide = 0;
  drivers.forEach((driver) => {
    driver.locked && countHide++;
  });

  return countHide === drivers.length;
};

export const calculatePixelBetweenStops = (stops, firstStartTime, oneHourPx) => {
  if (stops.length === 0) return stops;
  let tmpStop = stops[0];
  const returnStops = stops.map((stop, index) => {
    const widthStop = getWidthChart(stop.arrivalTime, stop.finishTime, oneHourPx);
    const beforeDuration = getWidthChart(firstStartTime, stop.arrivalTime, oneHourPx);
    tmpStop = stop;
    return {
      ...stop,
      marginLeft: beforeDuration,
      widthStop
    };
  });
  return returnStops;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const separateStatusUnderlineLetter = (string) => {
  return string
    .split("_")
    .map((ele) => capitalizeFirstLetter(ele))
    ?.join(" ");
};

export const separateStatusLetter = (string) => {
  return capitalizeFirstLetter(string)
    .match(/[A-Z][a-z]+/g)
    .toString();
};

export const checkWorkingPercent = (percent) => {
  if (percent > 100) return 100;
  if (percent < 0) return 0;
  return percent;
};

export const convertHourTo12Clock = (time, isEndDay = false) => {
  if (!time) {
    return isEndDay ? "11:59PM" : "00:00AM";
  }
  return moment(time, TIME_FORMAT_BACKEND).format("hh:mm A");
};

export const handleSortNoStop = (stop, width) => {
  return stop?.unscheduledNo
    ? stop.unscheduledNo
    : width >= 10
    ? stop?.sortNo > 999
      ? ""
      : stop?.sortNo
    : "";
};

export const checkStatusDnd = (history, groupStop) => {
  let countAssigned = 0;
  if (history.currentIndex === 0) {
    return "none";
  } else {
    const ids = Object.keys(groupStop);
    ids.forEach((id) => {
      if (id.includes("assigned_")) {
        const assignedStops = groupStop[id];
        if (assignedStops.length > 0) {
          countAssigned++;
        }
      }
    });
  }

  return countAssigned > 0 ? "optimize" : "sort";
};

export const compareTwoArrayStop = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) return false;
  let status = true;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]?.id !== arr2[i]?.id) {
      return false;
    }
  }
  return status;
};

export const reorderDnd = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  const beforeEndStop = result[endIndex - 1];

  //30 is width default of stop
  const tmpWidthBeforeStop =
    Number(beforeEndStop?.widthStop) === 0 ? 30 : Number(beforeEndStop?.widthStop);
  result.splice(endIndex, 0, {
    ...removed,
    marginLeft: Number(beforeEndStop?.marginLeft) + tmpWidthBeforeStop + 8,
    changed: true
  });

  return result;
};

export const moveDnd = (
  source = [],
  destination = [],
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, {
    ...removed,
    changed: true,
    marginLeft: 10
  });

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const moveDndWithCouple = (
  source = [],
  destination = [],
  droppableSource,
  droppableDestination,
  moveToAssigned = false
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  const [tmpSourceStops, removedStops] = handleGetStopsRelated(
    sourceClone,
    droppableSource
  );
  destClone.splice(droppableDestination.index, 0, ...removedStops);

  const result = {};
  result[droppableSource.droppableId] = tmpSourceStops;
  if (moveToAssigned) {
    const routeName = droppableDestination.droppableId.includes("assigned")
      ? droppableDestination.droppableId
      : `assigned_${droppableDestination.droppableId}`;
    result[routeName] = destClone;
  } else {
    result[droppableDestination.droppableId] = destClone;
  }

  return result;
};

// return sourceStops and removed stops
export const handleGetStopsRelated = (sourceStops, droppableSource) => {
  const tmpSourceStops = [...sourceStops];

  const stopRemoved = sourceStops[droppableSource.index];
  let removeStops = [];
  const [removed] = tmpSourceStops.splice(droppableSource.index, 1);

  if (stopRemoved.type === "pickup") {
    if (stopRemoved.jobIds) {
      const relatedStops = tmpSourceStops
        .filter((stop) => stopRemoved.jobIds.includes(stop.jobId))
        .map((s, index) => ({
          ...s,
          index: 99
        }));
      relatedStops.forEach((st) => {
        const index = tmpSourceStops.findIndex((e) => e.id === st.id);
        tmpSourceStops.splice(index, 1);
      });
      removeStops = [...removeStops, ...relatedStops, stopRemoved];
    }
  } else {
    let pickupStop = sourceStops.find((stop) =>
      stop?.jobIds?.includes(stopRemoved.jobId)
    );
    const indexPickupStop = tmpSourceStops.findIndex((st) => st.id === pickupStop.id);
    if (pickupStop.jobIds.length > 1) {
      const indexJobId = pickupStop.jobIds.findIndex((elm) => elm === stopRemoved.jobId);
      const removeJobIdSplit = stopRemoved.id.split("__");
      const removePickupId = `pickup__${removeJobIdSplit[1]}`;

      const indexStopId = pickupStop.stopIds.findIndex((elm) => elm === removePickupId);
      if (indexJobId !== -1) {
        pickupStop.jobIds.splice(indexJobId, 1);
      }
      if (indexStopId !== -1) {
        pickupStop.stopIds.splice(indexStopId, 1);
      }
      removeStops = [
        ...removeStops,
        {
          ...pickupStop,
          jobIds: [stopRemoved.jobId],
          id: `pickup__${stopRemoved.jobId}`
        },
        removed
      ];

      //replace pickupStop when remove jobJb

      tmpSourceStops.splice(indexPickupStop, 1, pickupStop);
    } else {
      removeStops = [removed, pickupStop];
      tmpSourceStops.splice(indexPickupStop, 1);
    }
  }
  return [tmpSourceStops, removeStops];
};

export const getListStyle = (isDraggingOver) => {
  if (isDraggingOver) {
    return {
      border: "dashed 1px !important",
      backgroundColor: "#fff"
    };
  }
};
const checkExistStop = (existStop, nextStop, pickups) => {
  const tmpPickup = { ...pickups };
  if (
    existStop.arrivalTime === stop.arrivalTime ||
    existStop.finishTime === nextStop.finishTime
  ) {
    if (existStop.duration > nextStop.duration) {
      tmpPickup[existStop.arrivalTime] = {
        ...existStop,
        stopIds: existStop?.stopIds ? [...existStop.stopIds, nextStop.id] : [nextStop.id],
        jobIds: existStop?.jobIds
          ? [...existStop.jobIds, nextStop.jobId]
          : [nextStop.jobId]
      };
    } else {
      tmpPickup[existStop.arrivalTime] = {
        ...existStop,
        ...nextStop,
        stopIds: existStop?.stopIds ? [...existStop.stopIds, nextStop.id] : [nextStop.id],
        jobIds: existStop?.jobIds
          ? [...existStop.jobIds, nextStop.jobId]
          : [nextStop.jobId]
      };
    }
  }
  return tmpPickup;
};
export const groupStopByRoute = (stops, groupName = "location.type") => {
  const groupByRouteId = _.groupBy(stops, "routeId");
  let tmpStops = [];
  for (const [key, value] of Object.entries(groupByRouteId)) {
    tmpStops = [...tmpStops, ...groupStops(value, groupName)];
  }
  return tmpStops;
};
export const groupStops = (stops, groupName = "location.type") => {
  const groupType = _.groupBy(stops, groupName);
  const groupPickupAddress = _.groupBy(groupType.pickup, "address");
  let pickups = {};
  _.keys(groupPickupAddress).forEach((address) => {
    groupPickupAddress[address].sort(sortBy("arrivalTime", false)).forEach((stop) => {
      const tmpStop = pickups[stop.arrivalTime];
      if (!tmpStop) {
        if (_.keys(pickups).length === 0) {
          pickups[stop.arrivalTime] = {
            ...stop,
            stopIds: [stop.id],
            jobIds: [stop.jobId]
          };
        } else {
          _.keys(pickups).forEach((key) => {
            const tmpStop1 = pickups[key];
            if (
              tmpStop1.arrivalTime === stop.arrivalTime ||
              tmpStop1.finishTime === stop.finishTime ||
              tmpStop1.finishTime === stop.arrivalTime
            ) {
              if (tmpStop1.duration > stop.duration) {
                pickups[tmpStop1.arrivalTime] = {
                  ...tmpStop1,
                  stopIds: tmpStop1?.stopIds ? [...tmpStop1.stopIds, stop.id] : [stop.id],
                  jobIds: tmpStop1?.jobIds
                    ? [...tmpStop1.jobIds, stop.jobId]
                    : [stop.jobId]
                };
              } else {
                pickups[tmpStop1.arrivalTime] = {
                  ...tmpStop1,
                  ...stop,
                  stopIds: tmpStop1?.stopIds ? [...tmpStop1.stopIds, stop.id] : [stop.id],
                  jobIds: tmpStop1?.jobIds
                    ? [...tmpStop1.jobIds, stop.jobId]
                    : [stop.jobId]
                };
              }
            } else {
              pickups[stop.arrivalTime] = {
                ...stop,
                stopIds: stop?.stopIds ? [...stop.stopIds, stop.id] : [stop.id],
                jobIds: stop?.jobIds ? [...stop.jobIds, stop.jobId] : [stop.jobId]
              };
            }
          });
        }
      } else {
        if (
          tmpStop.arrivalTime === stop.arrivalTime ||
          tmpStop.finishTime === stop.finishTime ||
          tmpStop.finishTime === stop.arrivalTime
        ) {
          if (tmpStop.duration > stop.duration) {
            pickups[tmpStop.arrivalTime] = {
              ...tmpStop,
              stopIds: tmpStop?.stopIds ? [...tmpStop.stopIds, stop.id] : [stop.id],
              jobIds: tmpStop?.jobIds ? [...tmpStop.jobIds, stop.jobId] : [stop.jobId]
            };
          } else {
            pickups[tmpStop.arrivalTime] = {
              ...tmpStop,
              ...stop,
              stopIds: tmpStop?.stopIds ? [...tmpStop.stopIds, stop.id] : [stop.id],
              jobIds: tmpStop?.jobIds ? [...tmpStop.jobIds, stop.jobId] : [stop.jobId]
            };
          }
        } else {
          pickups[stop.arrivalTime] = {
            ...stop,
            stopIds: stop?.stopIds ? [...stop.stopIds, stop.id] : [stop.id],
            jobIds: stop?.jobIds ? [...stop.jobIds, stop.jobId] : [stop.jobId]
          };
        }
      }
    });
  });

  let returnPickupStops = [
    ...convertObjectToArray(pickups),
    ...(groupType.dropoff || [])
  ];

  returnPickupStops = _.uniqBy(returnPickupStops, "id");

  return returnPickupStops.sort(sortBy("arrivalTime", false)).map((item, index) => ({
    ...item,
    sortNo: index + 1
  }));
};

export function buildFirstLevelFilterRoute(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "id",
    "service_type",
    "userId",
    "status",
    "pickupDate",
    "clientIds",
    "serviceIds",
    "startPickupDate",
    "endPickupDate"
  ];

  const FILTER_VALUES = {
    id: "$cont",
    service_type: "$eq",
    userId: "$eq",
    pickupDate: "$eq",
    status: "$eq",
    clientIds: "$eq",
    serviceIds: "$eq",
    startPickupDate: "$eq",
    endPickupDate: "$eq"
  };
  return Object.keys(filter)
    .filter((field) => FIRST_LEVEL_FILTER.includes(field) && !_.isEmpty(filter[field]))
    .map((filteredField) => {
      let value = filter[filteredField];
      if (filteredField === "pickupDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      if (filteredField === "startPickupDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      if (filteredField === "endPickupDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      return `${filteredField}||${FILTER_VALUES[filteredField]}||${value}`;
    });
}
