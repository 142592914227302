import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getTerritoryList = createAsyncThunk(
  "territory/get/loading",
  async (data) => {
    return settingApi.getTerritoryList(data);
  }
);

export const doGetTerritory = createAsyncThunk("territory/getOne/loading", async (id) => {
  return settingApi.getTerritory(id);
});

export const doCreateTerritory = createAsyncThunk(
  "territory/create/loading",
  async (data) => {
    return settingApi.createTerritory(data);
  }
);

export const doUpdateTerritory = createAsyncThunk(
  "territory/create/loading",
  async (data) => {
    return settingApi.updateTerritory(data);
  }
);

export const doDeleteTerritory = createAsyncThunk(
  "territory/delete/loading",
  async (id) => {
    return settingApi.deleteTerritory(id);
  }
);
