import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { saveAdvancedInfo } from "../store/thunk";
import "./DriverCreatePasswordPage.scss";
import AdditionalInformation from "../forms/AdditionalInformation";

const DriverCreatePasswordPage = () => {
  const dispatch = useDispatch();
  const [formStep, setFormStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});

  const submitForm = async (values) => {
    const dataSubmit = {
      ...step1Data,
      ...values
    };

    try {
      const { error } = await dispatch(saveAdvancedInfo(dataSubmit));
      if (!error) {
        notification.success({
          message: "Update password successfully",
          placement: "bottomRight"
        });
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return (
    <div className="create-password-page">
      <div className="background-section" />
      <div className="main-content-page">
        <div className="header-title">Additional Information</div>
        <div className="wrap-content">
          {Number(formStep) === 1 && (
            <AdditionalInformation
              formStep={formStep}
              setFormStep={setFormStep}
              setAdditionalData={setStep1Data}
              handleSubmitForm={submitForm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverCreatePasswordPage;
