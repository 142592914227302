import request from "../../../../helper/request";

const invoicingApi = {
  getList: (params) => {
    return request.get("/routes", {
      params
    });
  },
  exportInvoicePdf: (routeId, invoiceType) => {
    return request.get(`/invoicings/export-pdf/${routeId}`, {
      params: { invoiceType },
      responseType: "blob",
      headers: { Accept: "application/pdf" }
    });
  }
};

export default invoicingApi;
