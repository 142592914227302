import request from "../../../../helper/request";

const orderApi = {
  getOrderList: (params) => {
    return request.get("/orders", {
      params
    });
  },
  getOrderRoutes: (id) => {
    return request.get(`/orders/${id}/routes`);
  },
  delete: (id) => {
    return request.delete(`/orders/${id}`);
  },
  createActiveOrder: (data) => {
    return request.post("/orders", data);
  },
  getOrder: (id) => {
    return request.get(`/orders/${id}`);
  },
  getJobOrder: (id) => {
    return request.get(`/jobs/${id}`);
  },
  addJobToRoute: (data) => {
    return request.post(`/jobs/add-job-to-route`, data);
  },
  optimizeOrder: (data) => {
    return request.post(`/routes/orders-optimize`, data);
  },
  optimizeExistingOrder: (data) => {
    return request.post(`/routes/existing-order-optimize`, data);
  },
  sortOrderRoute: (data) => {
    return request.post(`/routes/orders-sort`, data);
  },
  fetchPricingEstimate: (data) => {
    return request.post(`/pricing/estimate`, data);
  }
};

export default orderApi;
