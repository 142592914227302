import * as React from "react";
const ImproveSvg = (props) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 97 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={48.5} cy={48.5} rx={48} ry={48.5} fill="#00A9D6" />
    <path
      d="M80.0963 27.3496C79.7763 25.8496 80.8763 26.2996 44.3363 26.2996C43.5263 26.2996 42.0363 27.6296 41.6863 27.6296H25.7763C24.0263 27.6296 24.0163 30.2796 25.7763 30.2796H37.3463C30.0363 34.6696 29.9563 34.4996 29.7963 35.3196C29.7363 35.6096 30.0163 33.8496 28.9263 40.8896H21.7963C20.0463 40.8896 20.0363 43.5396 21.7963 43.5396H28.5163L27.0863 52.8196H17.8163C16.0663 52.8196 16.0563 55.4696 17.8163 55.4696H26.6763L25.6563 62.0996H21.7963C20.0463 62.0996 20.0363 64.7496 21.7963 64.7496H25.2463C24.3963 70.2696 24.2363 70.3096 24.7663 70.9196C25.3263 71.5696 24.8163 71.3796 39.0363 71.3796C39.2463 71.3796 60.6263 71.3796 60.2563 71.3796C60.9763 71.3796 74.3763 63.0996 74.4963 62.9696C74.6763 62.7696 74.8163 62.4296 74.8363 62.2996C80.3263 26.6296 80.1963 27.7596 80.1063 27.3396L80.0963 27.3496ZM72.2963 61.2896L61.9863 67.4696L66.7663 36.3996L77.0663 30.2196L72.2863 61.2896H72.2963ZM44.7063 28.9596H52.8163L43.9763 34.2596H35.8663L44.7063 28.9596ZM60.7663 28.9596L51.9263 34.2596H49.1263L57.9663 28.9596H60.7663ZM45.4763 36.9096H50.7463L50.1363 40.8896H44.8663L45.4763 36.9096ZM65.9263 28.9596H74.0363L65.1963 34.2596H57.0863L65.9263 28.9596ZM27.9263 64.7596H29.7463C31.4963 64.7596 31.5063 62.1096 29.7463 62.1096H28.3363L29.3563 55.4796H36.3763C38.1263 55.4796 38.1363 52.8296 36.3763 52.8296H29.7563L31.1863 43.5496H35.0463C36.7963 43.5496 36.8063 40.8996 35.0463 40.8996H31.5963L32.2063 36.9196H42.7863C41.5563 44.9196 40.3063 43.5496 51.2663 43.5496C51.9163 43.5496 52.4763 43.0696 52.5763 42.4296L53.4263 36.9196H64.0063L59.1063 68.7496H48.5263L49.3163 63.6496C49.4363 62.8396 48.8163 62.1196 48.0063 62.1196H40.0463C39.3963 62.1196 38.8363 62.5996 38.7363 63.2396L37.8863 68.7496H27.3063L27.9163 64.7696L27.9263 64.7596ZM40.5763 68.7396L41.1863 64.7596H46.4563L45.8463 68.7396H40.5763Z"
      fill="white"
    />
    <path
      d="M23.1162 35.5914H25.7662C27.5162 35.5914 27.5262 32.9414 25.7662 32.9414H23.1162C21.3662 32.9414 21.3562 35.5914 23.1162 35.5914Z"
      fill="white"
    />
    <path
      d="M20.4663 48.8492H23.1163C24.8663 48.8492 24.8763 46.1992 23.1163 46.1992H20.4663C18.7163 46.1992 18.7063 48.8492 20.4663 48.8492Z"
      fill="white"
    />
    <path
      d="M33.7263 74.0508H17.8163C16.0663 74.0508 16.0563 76.7008 17.8163 76.7008H33.7263C35.4763 76.7008 35.4863 74.0508 33.7263 74.0508Z"
      fill="white"
    />
    <path
      d="M48.3163 23.65H53.6163C55.3763 23.65 55.3663 21 53.6163 21H48.3163C46.5663 21 46.5563 23.65 48.3163 23.65Z"
      fill="white"
    />
    <path
      d="M35.0563 23.65H43.0163C44.7663 23.65 44.7763 21 43.0163 21H35.0563C33.3063 21 33.2963 23.65 35.0563 23.65Z"
      fill="white"
    />
    <path
      d="M41.6862 74.0508H39.0362C37.2862 74.0508 37.2762 76.7008 39.0362 76.7008H41.6862C43.4362 76.7008 43.4462 74.0508 41.6862 74.0508Z"
      fill="white"
    />
  </svg>
);
export default ImproveSvg;
