import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import DriverLoginPage from "modules/auth/pages/DriverLoginPage";
import DriverRegister from "modules/driver/register/DriverRegister";
import PrivateDriverRoute from "routes/PrivateDriverRoute";
import DriverOverviewPage from "modules/driver/pages/overview/OverviewPage";
import BasicInformationDetails from "modules/driver/pages/basic-information/BasicInformationDetails";
import AdvanceInformation from "modules/driver/pages/advance-information/AdvanceInformation";
import PaymentInformation from "modules/driver/pages/payment-information/PaymentInformation";
import Vehicles from "modules/driver/pages/vehicles/Vehicles";
import AddAdditionalVehicle from "modules/driver/pages/add-additional-vehicle/AddAdditionalVehicle";

const DriverSpace = () => {
  const role = useSelector(selectRole);
  return (
    <Switch>
      <PrivateDriverRoute path="/driver/vehicles/:id" component={Vehicles} />
      {window.DRIVER_REGISTRATION && (
        <Route path="/driver/register" component={DriverRegister} />
      )}
      {window.DRIVER_REGISTRATION && (
        <Route exact path="/driver/login" component={DriverLoginPage} />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          exact
          path="/driver/overview"
          component={DriverOverviewPage}
        />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          path="/driver/basic-information"
          component={BasicInformationDetails}
        />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          path="/driver/advanced-information"
          component={AdvanceInformation}
        />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          path="/driver/payment-information"
          component={PaymentInformation}
        />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          path="/driver/:id/add-additional-vehicle"
          component={AddAdditionalVehicle}
        />
      )}
      {window.DRIVER_REGISTRATION && (
        <PrivateDriverRoute
          path="/driver/:id/additional-vehicle/:additionalVehicleId/edit"
          component={AddAdditionalVehicle}
        />
      )}
    </Switch>
  );
};
export default DriverSpace;
