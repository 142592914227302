/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TableBase from "../../../../../components/table/TableBase";
import { doDeleteSaasCountry } from "../../store/SaasCountriesThunks";

const CountryTable = ({ dataSource, pagination }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDelete = (saasCountry) => {
    dispatch(doDeleteSaasCountry(saasCountry));
  };

  const columns = [
    {
      title: "Country Name",
      dataIndex: "name",
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}>{value}</p>;
      }
    },
    {
      title: "Country",
      dataIndex: "country"
    }
  ];
  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Space size="middle">
            {canDelete && (
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <img src="/icons/delete.svg" className={`table-status ${value}`} />
              </Popconfirm>
            )}
          </Space>
        );
      }
    });
  }
  return (
    <TableBase columns={columns} data={dataSource} pagination={pagination} rowKey="id" />
  );
};
export default CountryTable;
