import React, { useState } from "react";
import { Input, Row, Col } from "antd";

const RatesPerVehicleCategoryFields = ({ title, data, setData }) => {
  const ratesOnChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const gridStyle = {
    display: "grid",
    gridTemplateRows: "100%",
    gridTemplateColumns: "50%"
  };

  return (
    <>
      <h2 className="title mt-3">{title}</h2>

      <Row>
        <Col span={24}>
          <h3 className="ratesSectionTitle">Pre Subtotal</h3>
          <div className="m-3">
            Base Price
            <Input
              name="basePrice"
              value={data.basePrice}
              onChange={ratesOnChange}
              type="number"
              addonBefore={window.CURRENCY_SYMBOL}
              required
            />
          </div>

          <div className="m-3 additionalBorder">
            Price Per Addtional Time On Route (hrs)
            <Input
              name="pricePerAdditionalTimeOnRoute"
              value={data.pricePerAdditionalTimeOnRoute}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/hr`}
              required
            />
            <div className="m-3">
              Threshold for Additional Time on Route
              <Input
                name="thresholdAdditionalTimeOnRoute"
                value={data.thresholdAdditionalTimeOnRoute}
                onChange={ratesOnChange}
                type="number"
                addonBefore="hrs"
                required
              />
            </div>
          </div>

          <div className="m-3">
            Price per distance on Route (mi)
            <Input
              name="pricePerDistanceOnRoute"
              value={data.pricePerDistanceOnRoute}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/mi`}
              required
            />
          </div>

          <div className="m-3  additionalBorder">
            Price per Return Distance Short (mi)
            <Input
              name="pricePerReturnDistanceShort"
              value={data.pricePerReturnDistanceShort}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/mi`}
              required
            />
            <div className="m-3">
              Max Return Distance - Short
              <Input
                name="maxReturnDistanceShort"
                value={data.maxReturnDistanceShort}
                onChange={ratesOnChange}
                type="number"
                addonBefore="mi"
                required
              />
            </div>
          </div>

          <div className="m-3 additionalBorder">
            Price per Return Distance Long (mi)
            <Input
              name="pricePerReturnDistanceLong"
              value={data.pricePerReturnDistanceLong}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/mi`}
              required
            />
            <div className="m-3">
              Max Return Distance - Long
              <Input
                name="maxReturnDistanceLong"
                value={data.maxReturnDistanceLong}
                onChange={ratesOnChange}
                type="number"
                addonBefore="mi"
                required
              />
            </div>
          </div>

          <div className="m-3">
            Price per Return Time
            <Input
              name="pricePerReturnTime"
              value={data.pricePerReturnTime}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/hr`}
              required
            />
          </div>

          <div className="m-3">
            Price per Micro Package
            <Input
              name="pricePerMicroPackage"
              value={data.pricePerMicroPackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>

          <div className="m-3">
            Price per Small Package
            <Input
              name="pricePerSmallPackage"
              value={data.pricePerSmallPackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>

          <div className="m-3">
            Price per Medium Package
            <Input
              name="pricePerMediumPackage"
              value={data.pricePerMediumPackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="m-3">
            Price per Large Package
            <Input
              name="pricePerLargePackage"
              value={data.pricePerLargePackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>
          <div className="m-3">
            Price per Custom Package
            <Input
              name="pricePerCustomPackage"
              value={data.pricePerCustomPackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>
          <div className="m-3">
            Price per Pallet Package
            <Input
              name="pricePerPalletPackage"
              value={data.pricePerPalletPackage}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/pack`}
              required
            />
          </div>
          <div className="m-3">
            Price Per Pickup Stops
            <Input
              name="pricePerPickupStops"
              value={data.pricePerPickupStops}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
              required
            />
          </div>
          <div className="m-3">
            Price Per Delivery Stops
            <Input
              name="pricePerDeliveryStops"
              value={data.pricePerDeliveryStops}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
              required
            />
          </div>
          <div className="m-3">
            Price Return Delivery Stop
            <Input
              name="priceReturnDeliveryStop"
              value={data.priceReturnDeliveryStop}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
              required
            />
          </div>
          <div className="m-3 additionalBorder">
            Price Per Over Delivery Stops
            <Input
              name="pricePerOverDeliveryStops"
              value={data.pricePerOverDeliveryStops}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
              required
            />
            <div className="m-3">
              Threshold for Over Delivery Stops
              <Input
                name="thresholdOverDeliveryStops"
                value={data.thresholdOverDeliveryStops}
                onChange={ratesOnChange}
                type="number"
                addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
                required
              />
            </div>
          </div>
          <div className="m-3">
            Volume Package Discounts
            <Input
              name="volumePackageDiscounts"
              value={data.volumePackageDiscounts}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
              required
            />
            <div className="m-3">
              Volume Package Discount Level 1
              <Input
                name="volumePackageDiscountLevelOne"
                value={data.volumePackageDiscountLevelOne}
                onChange={ratesOnChange}
                type="number"
                addonBefore="/pkg"
                required
              />
              Volume Package Discount Level 2
              <Input
                name="volumePackageDiscountLevelTwo"
                value={data.volumePackageDiscountLevelTwo}
                onChange={ratesOnChange}
                type="number"
                addonBefore="/pkg"
                required
              />
            </div>
          </div>
          <div className="m-3 additionalBorder">
            Price Per Distance Fuel Surcharge (mi)
            <Input
              name="pricePerDistanceFuelSurcharge"
              value={data.pricePerDistanceFuelSurcharge}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/mi`}
              required
            />
            <div className="m-3">
              Threshold for Fuel Surcharge
              <Input
                name="thresholdFuelSurcharge"
                value={data.thresholdFuelSurcharge}
                onChange={ratesOnChange}
                type="number"
                addonBefore="mi"
                required
              />
            </div>
          </div>
          <h3 className="ratesSectionTitle">Subtotal</h3>
          <div className="m-3 additionalBorder">
            Price Per Minimum Deliveries
            <Input
              name="pricePerMinimumDeliveries"
              value={data.pricePerMinimumDeliveries}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/stop`}
              required
            />
            <div className="m-3">
              Minimum Delivery Stops
              <Input
                name="minimumDeliveries"
                value={data.minimumDeliveries}
                onChange={ratesOnChange}
                type="number"
                addonBefore="# stops"
                required
              />
            </div>
          </div>
          <div className="m-3 additionalBorder">
            Price for Minimum Earnings
            <Input
              name="minimumEarnings"
              value={data.minimumEarnings}
              onChange={ratesOnChange}
              type="number"
              addonBefore={window.CURRENCY_SYMBOL}
            />
            <div className="m-3">
              Minimum Time for Minimum Earnings (hrs)
              <Input
                name="minimumTimeMinimumEarnings"
                value={data.minimumTimeMinimumEarnings}
                onChange={ratesOnChange}
                type="number"
                addonBefore="hrs"
                required
              />
            </div>
          </div>
          <h3 className="ratesSectionTitle">Completed Rates</h3>
          <div className="m-3 additionalBorder">
            Price Difference Per Completed Total Route Time (hrs)
            <Input
              name="priceDifferencePerCompletedTotalRouteTime"
              value={data.priceDifferencePerCompletedTotalRouteTime}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/hr`}
              required
            />
            <div className="m-3">
              Threshold for Price Difference Per Completed Total Route Time
              <Input
                name="thresholdPriceDifferencePerCompleted"
                value={data.thresholdPriceDifferencePerCompleted}
                onChange={ratesOnChange}
                type="number"
                addonBefore="% in decimals"
                required
              />
            </div>
          </div>
          <div className="m-3 additionalBorder">
            Price Per Additional Waiting Time Completed (mins)
            <Input
              name="pricePerAdditionalWaitingTimeCompleted"
              value={data.pricePerAdditionalWaitingTimeCompleted}
              onChange={ratesOnChange}
              type="number"
              addonBefore={`${window.CURRENCY_SYMBOL}/min`}
              required
            />
            <div className="m-3">
              Max Accepted Waiting Time (mins)
              <Input
                name="maxAcceptedWaitingTime"
                value={data.maxAcceptedWaitingTime}
                onChange={ratesOnChange}
                type="number"
                addonBefore="mins"
                required
              />
            </div>
          </div>
          <div className="m-3">
            Discount Completed Deliveries
            <Input
              name="discountCompletedDeliveries"
              value={data.discountCompletedDeliveries}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
              required
            />
          </div>
          <div className="m-3">
            Price Per Failed Delivery
            <Input
              name="pricePerFailedDelivery"
              value={data.pricePerFailedDelivery}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
              required
            />
          </div>
          <div className="m-3">
            Preferred Driver
            <Input
              name="preferredDriver"
              value={data.preferredDriver}
              onChange={ratesOnChange}
              type="number"
              addonBefore={window.CURRENCY_SYMBOL}
              required
            />
          </div>
          <h3 className="ratesSectionTitle"> Cancelled Rates </h3>
          <div className="m-3">
            Rate Per Cancelled Job Started Same Day
            <Input
              name="ratePerCancelledJobStartedSameDay"
              value={data.ratePerCancelledJobStartedSameDay}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
              required
            />
          </div>
          <div className="additionalBorder">
            <div className="m-3">
              Rate Per Cancelled Job Less 12hr
              <Input
                name="ratePerCancelledJobLess12hr"
                value={data.ratePerCancelledJobLess12hr}
                onChange={ratesOnChange}
                type="number"
                addonBefore="% in decimals"
                required
              />
            </div>
            <div className="m-3">
              Rate Per Cancelled Job Over 12hr
              <Input
                name="ratePerCancelledJobMoreThan12hr"
                value={data.ratePerCancelledJobMoreThan12hr}
                onChange={ratesOnChange}
                type="number"
                addonBefore="% in decimals"
              />
            </div>
            <div className="m-3">
              <div className="m-3">
                Threshold for Discount Cancelled Job (hrs)
                <Input
                  name="thresholdCancelledJobHour"
                  value={data.thresholdCancelledJobHour}
                  onChange={ratesOnChange}
                  type="number"
                  addonBefore="hrs"
                  required
                />
              </div>
            </div>
          </div>
          <h3 className="ratesSectionTitle">Estimated Price</h3>
          <div className="m-3">
            Booking Fee
            <Input
              name="bookingFee"
              value={data.bookingFee}
              onChange={ratesOnChange}
              type="number"
              addonBefore={window.CURRENCY_SYMBOL}
            />
          </div>
          <div className="m-3">
            Additional Insurance
            <Input
              name="additionalInsurance"
              value={data.additionalInsurance}
              onChange={ratesOnChange}
              type="number"
              addonBefore={window.CURRENCY_SYMBOL}
            />
          </div>
          <div className="m-3">
            B2B Tax
            <Input
              name="b2bTax"
              value={data.b2bTax}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
            />
          </div>
          <div className="m-3">
            Sales Tax
            <Input
              name="salesTax"
              value={data.salesTax}
              onChange={ratesOnChange}
              type="number"
              addonBefore="% in decimals"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default RatesPerVehicleCategoryFields;
