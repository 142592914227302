import React from "react";
import "./index.scss";
const Card = ({ children, title, rightTitle, className }) => {
  return (
    <div className={`card-component ${className}`}>
      <div className="wrap-card-title">
        <div className="card-title">{title}</div>
        {rightTitle && <div className="right-title">{rightTitle}</div>}
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};
export default React.memo(Card);
