import request from "../../../../helper/request";

const profileApi = {
  getProfile: () => {
    return request.get("/client/users/profile");
  },
  updateProfile: (payload) => {
    return request.patch("/client/users/profile", payload);
  },
  updateAPIKey: () => {
    return request.get("/client/auth/refresh-api-key");
  },
  updateRoutingPreferences: (payload) => {
    return request.patch("/client/users/routing-preferences", payload);
  }
};

export default profileApi;
