export const ORDER_SERVICE_TYPE_SELECT = [
  {
    name: "All",
    value: ""
  },
  {
    name: "Flexio Pool",
    value: "flexio_pool"
  },
  {
    name: "Flexio Same Day",
    value: "flexio_same_day"
  },
  {
    name: "Flexio Shift",
    value: "flexio_shift"
  },
  {
    name: "Flexio now",
    value: "flexio_now"
  }
];
export const ORDER_STATUS_SELECT = [
  {
    name: "Pending",
    value: "pending"
  },
  {
    name: "In Progress",
    value: "in_progress"
  },
  {
    name: "Done",
    value: "done"
  }
];

export const CAPACITY_PLAN = {
  flexio_shift: { volume: 2370, weight: 35000, length: 9999 },
  flexio_now: { volume: 700, weight: 11000, length: 9999 }
};

export const ROUTE_ESTIMATE_TYPES = {
  CLIENT_ESTIMATE: "client_estimate",
  DRIVER_ESTIMATE: "driver_estimate"
};
