import { Button, Form, Input, Row, Col, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import "./AdminLoginPage.scss";
import { handleLoginDriver } from "../store/thunk";
import { withAuthDriver } from "../hocs/withAuth";
import { Link } from "react-router-dom";

const DriverLoginPage = () => {
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(handleLoginDriver(values));
  };

  return (
    <div className="">
      <Row className="d-flex">
        <Col md={12}>
          <div
            style={{
              background: `url(${process.env.PUBLIC_URL}/pages/user/happy-driver.jpg) no-repeat center`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              position: "relative"
            }}
          ></div>
        </Col>
        <Col md={12} sm={24} xs={24} className="d-flex">
          <Card style={{ flex: 1, maxWidth: 500, margin: "auto" }}>
            <div
              className="logo"
              style={{
                position: "absolute",
                width: "100%",
                top: -90,
                left: -10,
                textAlign: "center"
              }}
            >
              <img
                src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`}
                style={{ width: "80%", maxWidth: 200 }}
              />
            </div>
            <div>
              <Form
                scrollToFirstError={true}
                layout="vertical"
                hideRequiredMark
                colon={false}
                onFinish={onSubmit}
              >
                <div className="form-header">
                  <div>
                    <h2 className="title">Welcome Back!</h2>
                    <p>Login to your Flexio driver account.</p>
                  </div>
                </div>
                <div className="form-content">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      { type: "email", message: "Invalid Email" },
                      { required: true, message: "Required" }
                    ]}
                  >
                    <Input size="large" placeholder="Email Address" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input.Password size="large" placeholder="Password" />
                  </Form.Item>
                </div>
                <div className="form-footer pb-3">
                  <div className="text-primary pb-3">
                    <Link to="/admin/forgot-password">Forgot Password? </Link>
                  </div>
                  <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </div>
            <p className="text-center text-gray-300 mt-4">
              {"Don't have a driver account?"} <Link to="/driver/register">Sign Up</Link>
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default withAuthDriver(DriverLoginPage);
