import React, { useCallback, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";

import AdminBreadCrumb from "components/AdminBreadCrumb";
import HeaderPage from "components/headers/HeaderPage";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doGetDriver } from "../store/thunks";
import { selectDriver } from "../store/slice";
import AdditionalVehicleForm from "../forms/AdditionalVehicleForm";
import "./AdditionalVehicle.scss";
import { doCreate, doGet, doUpdate } from "../store/additional-vehicle/thunks";
import { setSuccessMessage, setError } from "app/global/slice";
import { selectAdditionalVehicle } from "../store/additional-vehicle/slice";
import { LIST_ADDTIONAL_VEHICLE_URL } from "../constants";
import { shortId } from "helper/util";
import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";

const AdditionalVehiclePage = () => {
  const history = useHistory();
  const params = useParams();
  const { id: driverId, additionalVehicleId } = params || {};
  const driver = useSelector(selectDriver);
  const additionalVehicle = useSelector(selectAdditionalVehicle);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!driverId) return;
    dispatch(doGetDriver(driverId));
  }, [dispatch, driverId]);

  useEffect(() => {
    if (!additionalVehicleId) return;
    dispatch(doGet(additionalVehicleId));
  }, [dispatch, additionalVehicleId]);

  const breadCrumbs = useCallback(() => {
    return [
      { text: "USER", url: "/admin/users" },
      { text: "DRIVERS", url: `/admin/users/drivers` },
      {
        text: `${driver.firstName} ${driver.lastName}`,
        url: `/admin/users/drivers/${driverId}`
      },
      {
        text: _isEmpty(additionalVehicle)
          ? "ADD ADDITIONAL VEHICLE"
          : shortId(additionalVehicleId)
      }
    ];
  }, [driverId, driver, additionalVehicleId, additionalVehicle]);

  const handleCreate = async (data) => {
    try {
      const { error } = await dispatch(doCreate({ ...data, driverId }));
      if (!error) {
        dispatch(setSuccessMessage("Created successfully!"));
        history.push(LIST_ADDTIONAL_VEHICLE_URL(driverId));
      } else {
        dispatch(setError("Created failure!"));
      }
    } catch (err) {
      dispatch(setError("Created failure!"));
      console.log(err);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const { error } = await dispatch(doUpdate(data));
      if (!error) {
        dispatch(setSuccessMessage("Updated successfully!"));
      } else {
        dispatch(setError("Updated failure!"));
      }
    } catch (err) {
      dispatch(setError("Updated failure!"));
      console.log(err);
    }
  };

  return (
    <div className="additional-vehicle-page">
      <HeaderPage leftSide={<AdminBreadCrumb breadCrumbs={breadCrumbs() || []} />} />
      {(!additionalVehicleId || !_isEmpty(additionalVehicle)) && (
        <AdditionalVehicleForm
          data={additionalVehicle}
          onSubmit={additionalVehicleId ? handleUpdate : handleCreate}
        />
      )}
    </div>
  );
};

export default withPermission(AdditionalVehiclePage, canEditPermission);
