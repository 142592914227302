import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getFailedRegistrationList = createAsyncThunk(
  "failedregistration/get/loading",
  async (data) => {
    return settingApi.getFailedRegistrationList(data);
  }
);

export const getAllFailedRegistration = createAsyncThunk(
  "failedregistration/getAll/loading",
  async () => {
    return settingApi.getAllFailedRegistration();
  }
);
