import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "antd";
import { InputPasswordHook, InputHook, GroupRadioHook } from "components/hook-forms";
import { useForm } from "react-hook-form";
import { registerForm } from "./drivers/schema";
import { useLocation } from "react-router-dom";
import "./drivers/CreatePasswordForm.scss";

import Cards from "../components/Cards";
import "./Step3Form.scss";

const AdditionalInformation = ({ handleSubmitForm }) => {
  const AccountTypesOptions = [
    { value: "checking", name: "Checking" },
    { value: "savings", name: "Savings" }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = (values) => {
    handleSubmitForm(values);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const { search } = useLocation();
  const emailGroup = search.split("&");
  const email = emailGroup[0].split("email=")[1];

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerForm),
    defaultValues: {
      email: email,
      token: queryParams.get("token")
    }
  });

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Cards
        title={"Payment Information"}
        className="wrap-step-3-form"
        rightTitle="All fields are required"
      >
        <div className="group-form">
          <InputHook
            control={control}
            label="Full Name of Bank Account Owner"
            placeholder="Full Name of Bank Account Owner"
            name="fullNameBank"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Email Address of Bank Account Owner"
            placeholder="Email Address of Bank Account Owner"
            name="emailBank"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Bank Name"
            placeholder="Bank Name"
            name="bankName"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Bank Account Number"
            placeholder="Bank Account Number"
            name="bankAccountNumber"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Bank Account Routing Number"
            placeholder="Bank Account Routing Number"
            name="bankAccountRoutingNumber"
            showOptionsName={false}
            errors={errors}
          />
          <GroupRadioHook
            label="Account Types"
            placeholder="Account Types"
            options={AccountTypesOptions}
            showOptionsName={false}
            control={control}
            name="accountType"
            errors={errors}
          />
        </div>
      </Cards>

      <Cards
        title={"Tax Information"}
        className="wrap-step-3-form"
        rightTitle="All fields are required"
      >
        <div className="group-form">
          <InputHook
            control={control}
            label="SSN or EIN"
            placeholder="SSN or EIN"
            name="taxSsnOrEin"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Name"
            placeholder="Name"
            name="taxName"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="Address"
            placeholder="Address"
            name="taxAddress"
            showOptionsName={false}
            errors={errors}
          />
        </div>
      </Cards>
      {/* Create Password */}
      <Cards title={"Create Password"} rightTitle="All fields are required">
        <div className="group-form">
          <InputHook
            control={control}
            label="Email"
            placeholder="Email"
            name="email"
            showOptionsName={false}
            errors={errors}
            disabled={true}
          />
          <InputPasswordHook
            control={control}
            label="Password"
            placeholder="Password"
            name="password"
            showOptionsName={false}
            errors={errors}
          />
          <InputPasswordHook
            control={control}
            label="Confirm Password"
            placeholder="Confirm Password"
            name="confirmPassword"
            showOptionsName={false}
            errors={errors}
          />
          <Button type="primary" htmlType="submit" style={{ marginTop: 4 }}>
            Complete Registration
          </Button>
        </div>
      </Cards>
    </form>
  );
};

export default AdditionalInformation;
