import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { InputHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import React from "react";
import { useForm } from "react-hook-form";
import "modules/admin/clients/components/header/AdminFilter.scss";
import { SelectHook } from "components/hook-forms";
import { USER_ROLES } from "configs/constants";

const FilterAdminUser = ({
  defaultValues,
  setShowFilter,
  handleClickFilter,
  onReset
}) => {
  const {
    reset,
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  return (
    <div className="admin-filter">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter">
          <InputHook
            control={control}
            placeholder="ID"
            label="ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="First Name"
            label="First Name"
            name="first_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Last Name"
            label="Last Name"
            name="last_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Role"
            name="role"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={USER_ROLES}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
        <div className="wrap-button">
          <Button type="link" className="collapse" onClick={() => setShowFilter(false)}>
            Collapse <UpOutlined />
          </Button>
          <Button
            type="link"
            className="svg-icon clear"
            onClick={() => {
              reset();
              onReset();
            }}
          >
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FilterAdminUser;
