import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { markerIcon, markerLayer } from "../settings";

const icon = markerIcon();

const PickupLayer = ({ stops }) => {
  const setCursor = (map, cursor) => {
    map.getCanvas().style.cursor = cursor;
  };
  return stops.map((stop) => {
    return (
      <Layer {...markerLayer(stop?.type, icon[stop?.type], false)} key={stop.id}>
        <Feature
          coordinates={[stop?.lng, stop?.lat]}
          onMouseEnter={({ map }) => {
            setCursor(map, "pointer");
          }}
          onMouseLeave={({ map }) => {
            setCursor(map, "");
          }}
          properties={{
            color: stop?.color || "#C4C4C4",
            colorText: "#FFFFFF"
          }}
        />
      </Layer>
    );
  });
};
export default PickupLayer;
