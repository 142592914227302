import React from "react";
const SentFeebackSuccessIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.24" cx="40" cy="40" r="40" fill="#00C853" />
      <g filter="url(#filter0_d_5227_46028)">
        <circle cx="40" cy="40" r="32" fill="#00C853" />
      </g>
      <g clipPath="url(#clip0_5227_46028)">
        <path
          d="M36.0002 45.56L30.4402 40L28.5469 41.88L36.0002 49.3334L52.0002 33.3334L50.1202 31.4534L36.0002 45.56Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5227_46028"
          x="4"
          y="4"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5227_46028"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5227_46028"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_5227_46028">
          <rect width="32" height="32" fill="white" transform="translate(24 24)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { SentFeebackSuccessIcon };
