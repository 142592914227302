import React from "react";
const FilterIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66679 2.00008H9.33345L5.99345 6.20008L2.66679 2.00008ZM0.833452 1.74008C2.18012 3.46675 4.66679 6.66675 4.66679 6.66675V10.6667C4.66679 11.0334 4.96679 11.3334 5.33345 11.3334H6.66679C7.03345 11.3334 7.33345 11.0334 7.33345 10.6667V6.66675C7.33345 6.66675 9.81345 3.46675 11.1601 1.74008C11.5001 1.30008 11.1868 0.666748 10.6335 0.666748H1.36012C0.806785 0.666748 0.493452 1.30008 0.833452 1.74008Z"
        fill="#212121"
      />
    </svg>
  );
};
export { FilterIcon };
