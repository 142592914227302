import React from "react";
import { ToggleCheckedIcon, ToggleUncheckedIcon } from "../../../../components/icons";
import classes from "./AppToggle.module.scss";

export default function AppToggle({
  children,
  reverse,
  disabled,
  checked,
  onCheckedChange,
  name
}) {
  return (
    <div
      className={classes.toggle}
      onClick={() => {
        if (disabled) return;
        const newChecked = !checked;
        onCheckedChange(newChecked);
      }}
    >
      <input type={"hidden"} name={name} value={checked} />
      {reverse && children}
      {!checked || disabled ? <ToggleUncheckedIcon /> : <ToggleCheckedIcon />}
      {!reverse && children}
    </div>
  );
}
