import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import VehicleCategoryTable from "../components/table/VehicleCategoryTable";
import { getVehicleCategoryList } from "../store/VehicleCategoryThunks";

import {
  selectAllVehicleCategories,
  selectMetadata,
  updatePaginationVehicleCategories
} from "../store/VehicleCategorySlice";
import "../styles/settings.scss";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";

const params = { group: "flexio" };

const VehicleCategoryList = () => {
  const dispatch = useDispatch();
  const vehicleCategoryList = useSelector(selectAllVehicleCategories);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Vehicle Category",
    updatePaginationVehicleCategories,
    dispatch
  );

  const breadCrumbs = [{ text: "SETTINGS" }, { text: "VEHICLE CATEGORIES" }];
  const buttonParams = allowPermission(useSelector(selectRole), canCreatePermission)
    ? {
        text: "Create Vehicle Category",
        link: "/admin/settings/vehicle-categories/new"
      }
    : null;

  return (
    <div className="VehicleCategoryList">
      <SettingHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />
      <div className="admin-content">
        <VehicleCategoryTable dataSource={vehicleCategoryList} pagination={pagination} />
      </div>
    </div>
  );
};
export default withFetchTablePagination(VehicleCategoryList, {
  fetchData: getVehicleCategoryList,
  selectMeta: selectMetadata,
  params
});
