import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import CountriesTable from "../components/table/CountriesTable";
import { getSaasCountriesList } from "../store/SaasCountriesThunks";
import {
  selectAllSaasCountries,
  updatePaginationSaasCountries,
  selectMetadata
} from "../store/SaasCountriesSlice";
import "../styles/settings.scss";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";

const params = { group: "flexio" };

const SaasCountries = () => {
  const dispatch = useDispatch();
  const saasList = useSelector(selectAllSaasCountries);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Saas Countries",
    updatePaginationSaasCountries,
    dispatch
  );
  const breadCrumbs = [{ text: "SETTINGS" }, { text: "SAAS COUNTRIES" }];
  const buttonParams = allowPermission(useSelector(selectRole), canCreatePermission)
    ? {
        text: "Create Country",
        link: "/admin/settings/saas-countries/new"
      }
    : null;
  return (
    <div className="TerritoryList">
      <SettingHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />
      <div className="admin-content">
        <CountriesTable dataSource={saasList} pagination={pagination} />
      </div>
    </div>
  );
};

export default withFetchTablePagination(SaasCountries, {
  fetchData: getSaasCountriesList,
  selectMeta: selectMetadata,
  params
});
