import React from "react";
import "./FormItem.scss";
const FormItem = ({
  className,
  label,
  isOption,
  require = false,
  description,
  children,
  style
}) => {
  return (
    <div className={`form-item-component ${className}`} style={style}>
      <div className="form-label">
        <div className="left-label">
          <span>{label}</span>
          {require && <span className="require">*</span>}
        </div>
        {isOption && <div className="right-label">Optional</div>}
      </div>
      {children}
      {description && <div className="description">{description}</div>}
    </div>
  );
};
export default FormItem;
