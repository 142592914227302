import request from "../../../../helper/request";

const orderETAApi = {
  getDeliveryByJobId(jobId) {
    return request.get(`/s/${jobId}`).then((it) => ({ ...it }));
  },
  getDirection(time, from, to) {
    const form = new FormData();
    form.set("time", time);
    form.set("fromLat", from.lat);
    form.set("fromLng", from.lng);
    form.set("toLat", to.lat);
    form.set("toLng", to.lng);

    const body = new URLSearchParams(form).toString();
    return request.get(`/s/direction?${body}`).then((it) => ({ ...it }));
  }
};

export default orderETAApi;
