import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function withFetchTablePagination(
  WrappedComponent,
  { fetchData, selectMeta, params = {} }
) {
  const WithFetch = (props) => {
    const dispatch = useDispatch();
    const metadata = useSelector(selectMeta);
    const page = metadata?.currentPage || 1;
    const pageSize = metadata?.itemsPerPage || 10;

    useEffect(() => {
      dispatch(
        fetchData({
          ...params,
          page: page,
          pageSize: pageSize
        })
      );
    }, [dispatch, page, pageSize]);

    return <WrappedComponent {...props} />;
  };
  return WithFetch;
}
