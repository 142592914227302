import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getPackagesList } from "./thunks";

const PackagesAdapter = createEntityAdapter({
  selectId: (packagev2) => packagev2.id
});

const initialState = PackagesAdapter.getInitialState({
  metadata: {}
});

export const poolPackagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    updatePaginationPackages(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPackagesList.fulfilled, (state, { payload }) => {
      PackagesAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectPackageById,
  selectIds: selectPackagesIds,
  selectEntities: selectPackageEntities,
  selectAll: selectAllPackages,
  selectTotal: selectTotalPackages
} = PackagesAdapter.getSelectors((state) => state.packages);

export const selectMetadata = (state) => state.packages.metadata;
export const selectPackage = (state) => state.packages.package;
export const selectFilter = (state) => state.packages.filter;

export const { updatePaginationPackages } = poolPackagesSlice.actions;

export default poolPackagesSlice.reducer;
