import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import PackageTypeTable from "../components/table/PackageTypeTable";
import { getPackageTypeList } from "../store/PackageTypeThunks";

import {
  selectAllPackageTypes,
  selectMetadata,
  updatePaginationPackageTypes
} from "../store/PackageTypeSlice";
import "../styles/settings.scss";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";

const params = { group: "flexio" };

const PackageTypeList = () => {
  const dispatch = useDispatch();
  const packageTypeList = useSelector(selectAllPackageTypes);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Package Type",
    updatePaginationPackageTypes,
    dispatch
  );

  const breadCrumbs = [{ text: "SETTINGS" }, { text: "PACKAGE TYPES" }];
  const buttonParams = allowPermission(useSelector(selectRole), canCreatePermission)
    ? {
        text: "Create Package Type",
        link: "/admin/settings/package-types/new"
      }
    : null;

  return (
    <div className="PackageTypeList">
      <SettingHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />
      <div className="admin-content">
        <PackageTypeTable dataSource={packageTypeList} pagination={pagination} />
      </div>
    </div>
  );
};
export default withFetchTablePagination(PackageTypeList, {
  fetchData: getPackageTypeList,
  selectMeta: selectMetadata,
  params
});
