import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import { selectRole } from "modules/auth/store/authSlice";
import { selectGroup } from "modules/auth/store/authSlice";
import { UserGroup } from "configs/constants";

export function withPermission(WrappedComponent, permission = []) {
  const ComponentWithReturn = (props) => {
    const role = useSelector(selectRole);
    const group = useSelector(selectGroup);

    if (!_isEmpty(permission) && !permission.includes(role)) {
      return (
        <Redirect
          to={[UserGroup.FLEXIO, UserGroup.TERRITORY].includes(group) ? "/admin" : "/"}
        />
      );
    }
    return <WrappedComponent {...props} />;
  };

  return ComponentWithReturn;
}
