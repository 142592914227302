import React, { useMemo, useState, useEffect } from "react";
import { Button, Checkbox, Modal, notification, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _difference from "lodash/difference";

import { isInternalDriverService } from "modules/client/flexio-services/helper";
import { VEHICLE_RATE_TYPES } from "configs/constants";
import {
  buildPricingEstimateBody,
  getLastAddress,
  verifyDropLocations,
  verifyPackages,
  verifyPickupLocations
} from "../../../helper";
import "./SelectRoutes.scss";
import orderApi from "../../../store/api";

const pickupValidationMessage =
  "There are stops outside the Pickup Zone accepted. Please modify them or choose another service.";
const dropoffValidationMessage =
  "There are stops outside the Dropoff Zone accepted. Please modify them or choose another service.";
const packageValidationMessage =
  "There are packages not belong to Package Types accepted. Please modify them or choose another service.";

const SelectRoutes = ({ routes, setRoutes, service, estimatePriceOrganization }) => {
  const [outsidePickups, setOutsidePickups] = useState({});
  const [outsideDropoffs, setOutsideDropoffs] = useState({});
  const [notAcceptedPackages, setNotAcceptedPackages] = useState({});
  const [selectedRouteKey, setSelectedRouteKey] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const { id: serviceId, vehicleRates } = service;
  const [fullFilledRouteOptions, setFullFilledRouteOptions] = useState({});
  const isInternalDriver = isInternalDriverService(service?.name);

  useEffect(() => {
    if (_isEmpty(routes) || _isEmpty(service) || isInternalDriver) return;

    const outsidePickupsTemp = {};
    const outsideDropoffsTemp = {};
    const notAcceptedPackagesTemp = {};
    Object.keys(routes).forEach((key) => {
      const { jobs = [] } = routes[key];
      const pickups = [];
      const dropoffs = [];
      const packages = [];
      for (const job of jobs) {
        pickups.push(job.pickup);
        dropoffs.push(job.dropoff);
        packages.push(...job.packages);
      }

      const outsidePickupsByRoute = _uniqBy(
        verifyPickupLocations(service, pickups),
        "id"
      );
      const outsideDropoffsByRoute = _uniqBy(
        verifyDropLocations(service, dropoffs),
        "id"
      );
      const notAcceptedPackagesByRoute = _uniqBy(
        verifyPackages(service, packages),
        "packageTypeId"
      );

      if (!_isEmpty(outsidePickupsByRoute)) {
        outsidePickupsTemp[key] = outsidePickupsByRoute;
      }
      if (!_isEmpty(outsideDropoffsByRoute)) {
        outsideDropoffsTemp[key] = outsideDropoffsByRoute;
      }
      if (!_isEmpty(notAcceptedPackagesByRoute)) {
        notAcceptedPackagesTemp[key] = notAcceptedPackagesByRoute;
      }
    });

    if (!_isEmpty(outsidePickupsTemp)) {
      setOutsidePickups(outsidePickupsTemp);
    }
    if (!_isEmpty(outsideDropoffsTemp)) {
      setOutsideDropoffs(outsideDropoffsTemp);
    }
    if (!_isEmpty(notAcceptedPackagesTemp)) {
      setNotAcceptedPackages(notAcceptedPackagesTemp);
    }

    // eslint-disable-next-line
  }, []);

  const vehicleCategories = vehicleRates
    ?.filter((item) => item.type === VEHICLE_RATE_TYPES.CLIENT_RATE)
    ?.map((filteredItem) => filteredItem.vehicleCategory)
    ?.sort(
      (vehicleCategory1, vehicleCategory2) =>
        vehicleCategory1.maxVolume - vehicleCategory2.maxVolume
    );

  const routeOptions = useMemo(() => {
    if (isInternalDriver && !_isEmpty(routes)) return { ...routes };
    if (_isEmpty(routes) || _isEmpty(vehicleCategories)) return;

    let options = { ...routes };
    Object.keys(routes).forEach((key) => {
      const { jobs = [] } = routes[key];
      let totalPackageVolume = 0;
      let allPackages = [];
      for (const job of jobs) {
        totalPackageVolume += job.packages?.reduce(
          (total, curPackage) => total + curPackage.maxVolume * curPackage.quantity,
          0
        );
        allPackages.push(...job.packages);
      }
      const allPackageTypeIds = _uniqBy(allPackages.map((item) => item.packageTypeId));
      for (const vehicleCategory of vehicleCategories) {
        const vehiclePackageTypeIds = vehicleCategory.packageTypes?.map(
          (item) => item.id
        );
        if (
          totalPackageVolume <= vehicleCategory.maxVolume &&
          _difference(allPackageTypeIds, vehiclePackageTypeIds)?.length === 0
        ) {
          options = {
            ...options,
            [key]: { ...options[key], vehicleCategory: vehicleCategory }
          };
          break;
        }
      }
    });
    return options;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_isEmpty(routeOptions)) return;
    if (isInternalDriver) {
      setFullFilledRouteOptions({ ...routeOptions });
      return;
    }

    const fillPricingEstimation = async () => {
      const routeOptionsTemp = { ...routeOptions };
      const estimatePriceRouteKeys = Object.keys(routeOptionsTemp).filter(
        (key) => routeOptionsTemp[key]?.vehicleCategory
      );
      const fetchPricingEstimatePromises = estimatePriceRouteKeys.map((filteredKey) =>
        orderApi.fetchPricingEstimate({
          ...buildPricingEstimateBody({
            ...routeOptionsTemp[filteredKey],
            serviceId
          }),
          organization: estimatePriceOrganization
        })
      );
      const pricingEstimations = await Promise.all(fetchPricingEstimatePromises);
      for (let i = 0; i < estimatePriceRouteKeys.length; i++) {
        routeOptionsTemp[estimatePriceRouteKeys[i]].routeEstimate = pricingEstimations[i];
      }

      setFullFilledRouteOptions(routeOptionsTemp);
    };
    fillPricingEstimation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeOptions]);

  const onChange = (e, key) => {
    const isChecked = e.target.checked;

    if (isChecked && routes[key]?.serviceId) {
      notification.warning({
        message: `${routes[key]?.name} has been assigned service already. Please uncheck before re-assigning to another service.`
      });
      return;
    }
    const { vehicleCategory, routeEstimate = {} } = fullFilledRouteOptions[key] || {};
    setRoutes({
      ...routes,
      [key]: {
        ...routes[key],
        serviceId: isChecked ? serviceId : undefined,
        service: isChecked ? service : {},
        vehicleCategoryId: isChecked
          ? fullFilledRouteOptions[key]?.vehicleCategory?.id
          : undefined,
        vehicleCategory: isChecked ? vehicleCategory : {},
        clientEstimatePrice: isChecked ? routeEstimate?.client?.estimatePrice || 0 : 0,
        driverEstimatePrice: isChecked ? routeEstimate?.driver?.estimatePrice || 0 : 0,
        clientRouteEstimate: isChecked ? routeEstimate?.client : {},
        driverRouteEstimate: isChecked ? routeEstimate?.driver : {}
      }
    });
  };

  const isErrorValidation = (key) => {
    return (
      !_isEmpty(outsidePickups[key]) ||
      !_isEmpty(outsideDropoffs[key]) ||
      !_isEmpty(notAcceptedPackages[key])
    );
  };

  const mapValidationMessages = (key) => {
    const validationMessages = [];
    if (!_isEmpty(outsidePickups[key])) {
      validationMessages.push(pickupValidationMessage);
    }
    if (!_isEmpty(outsideDropoffs[key])) {
      validationMessages.push(dropoffValidationMessage);
    }
    if (!_isEmpty(notAcceptedPackages[key])) {
      validationMessages.push(packageValidationMessage);
    }
    return validationMessages;
  };

  const onChangeSelectAll = (e) => {
    const isChecked = e.target.checked;

    const tempRoutes = {};
    for (const key of Object.keys(fullFilledRouteOptions || {})) {
      if (
        ((!fullFilledRouteOptions[key].vehicleCategory || isErrorValidation(key)) &&
          !isInternalDriver) ||
        (!isChecked && routes[key]?.serviceId !== serviceId)
      )
        continue;
      if (isChecked && routes[key]?.serviceId && routes[key]?.serviceId !== serviceId) {
        notification.warning({
          message: `${routes[key]?.name} has been assigned service already. Please uncheck before re-assigning to another service.`
        });
        continue;
      }
      const { vehicleCategory, routeEstimate = {} } = fullFilledRouteOptions[key] || {};
      tempRoutes[key] = {
        ...routes[key],
        serviceId: isChecked ? serviceId : undefined,
        service: isChecked ? service : {},
        vehicleCategoryId: isChecked
          ? fullFilledRouteOptions[key]?.vehicleCategory?.id
          : undefined,
        vehicleCategory: isChecked ? vehicleCategory : {},
        clientEstimatePrice: isChecked ? routeEstimate?.client?.estimatePrice || 0 : 0,
        driverEstimatePrice: isChecked ? routeEstimate?.driver?.estimatePrice || 0 : 0,
        clientRouteEstimate: isChecked ? routeEstimate?.client : {},
        driverRouteEstimate: isChecked ? routeEstimate?.driver : {}
      };
    }
    setRoutes({ ...routes, ...tempRoutes });
  };

  const isCheckedAll = () => {
    let availableRouteKeys = Object.keys(fullFilledRouteOptions || {});
    if (isInternalDriver) {
      return (
        Object.keys(routes)?.filter((key) => routes[key]?.serviceId === serviceId)
          ?.length === availableRouteKeys.length
      );
    }
    availableRouteKeys = availableRouteKeys?.filter(
      (routeKey) =>
        fullFilledRouteOptions[routeKey].vehicleCategory && !isErrorValidation(routeKey)
    );
    return (
      Object.keys(routes)?.filter((key) => routes[key]?.serviceId === serviceId)
        ?.length === availableRouteKeys.length && availableRouteKeys?.length
    );
  };

  const renderValidationTooltip = (message, routeKey) => (
    <>
      <p>{message}</p>
      <Button
        type="link"
        style={{ padding: 0, color: "#fff" }}
        onClick={() => {
          setSelectedRouteKey(routeKey), setVisibleModal(!visibleModal);
        }}
      >
        VIEW DETAIL
      </Button>
    </>
  );

  const renderValidationDetail = useMemo(
    () => (
      <>
        {!_isEmpty(outsidePickups[selectedRouteKey]) && (
          <>
            <span>{pickupValidationMessage}</span>
            <br />
            <span>Outside Pickups:</span>
            <br />
            <ul>
              {outsidePickups[selectedRouteKey].map(({ id, locationName, lat, lng }) => (
                <li key={id}>
                  {locationName} ({lat}, {lng})
                </li>
              ))}
            </ul>
          </>
        )}
        {!_isEmpty(outsideDropoffs[selectedRouteKey]) && (
          <>
            <span>{dropoffValidationMessage}</span>
            <br />
            <span>Outside Dropoffs:</span>
            <br />
            <ul>
              {outsideDropoffs[selectedRouteKey].map(({ id, locationName, lat, lng }) => (
                <li key={id}>
                  {locationName} ({lat}, {lng})
                </li>
              ))}
            </ul>
          </>
        )}
        {!_isEmpty(notAcceptedPackages[selectedRouteKey]) && (
          <>
            <span>{packageValidationMessage}</span>
            <br />
            <span>Not accepted Package Types:</span>
            <br />
            <ul>
              {notAcceptedPackages[selectedRouteKey].map(({ packageTypeId, name }) => (
                <li key={packageTypeId}>{name}</li>
              ))}
            </ul>
          </>
        )}
      </>
    ),
    [selectedRouteKey, outsidePickups, outsideDropoffs, notAcceptedPackages]
  );

  const renderRouteOption = (key) => {
    if (_isEmpty(fullFilledRouteOptions[key])) return;

    const isError = isErrorValidation(key);
    return (
      <div className="wrap-checkbox-selected" key={key}>
        <Checkbox
          checked={routes[key]?.serviceId === service?.id}
          key={fullFilledRouteOptions[key]}
          onChange={(e) => onChange(e, key)}
          disabled={
            (!fullFilledRouteOptions[key].vehicleCategory || isError) && !isInternalDriver
          }
        >
          {!isInternalDriver && (
            <span className="vehicle-name">
              {fullFilledRouteOptions[key].vehicleCategory?.name ||
                "Not Available Vehicle"}
              &nbsp; - &nbsp;
            </span>
          )}
          <span className="route-name">{routes[key].name}</span>
          &nbsp; - &nbsp;
          <span className="route-name">{getLastAddress(routes[key])}</span>
          {!isInternalDriver && fullFilledRouteOptions[key].vehicleCategory && !isError && (
            <>
              :&nbsp;
              <b>
                {formatCurrency(
                  fullFilledRouteOptions[key]?.routeEstimate?.client?.estimatePrice || 0
                )}
              </b>
            </>
          )}
          {isInternalDriver && (
            <>
              :&nbsp;<b>{formatCurrency(0)}</b>
            </>
          )}
        </Checkbox>
        {isError && (
          <Tooltip
            overlayStyle={{ whiteSpace: "pre-line" }}
            title={renderValidationTooltip(mapValidationMessages(key)?.join("\n\n"), key)}
            color={"#000"}
          >
            <QuestionCircleFilled style={{ color: "#E0E0E0", cursor: "pointer" }} />
          </Tooltip>
        )}
      </div>
    );
  };

  const formatCurrency = (price) => {
    if (!price) return null;
    else
      return window.CURRENCY_SYMBOL + String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="select-route-component">
      <div className="wrap-select-route">
        <Checkbox
          style={{ marginBottom: "10px" }}
          onChange={onChangeSelectAll}
          checked={isCheckedAll()}
        >
          Select ALL Routes
        </Checkbox>
        {Object.keys(fullFilledRouteOptions || {}).map((routeKey) =>
          renderRouteOption(routeKey)
        )}
      </div>
      <Modal
        onCancel={() => setVisibleModal(!visibleModal)}
        visible={visibleModal}
        title="Error Details"
        footer={false}
      >
        {renderValidationDetail}
      </Modal>
    </div>
  );
};

export default SelectRoutes;
