import { validationApi } from "./api";
import { notification } from "antd";
import { useEffect, useState } from "react";

export function useUpdateValidation() {
  const [state, setState] = useState(false);
  return [
    state,
    (formData) => {
      console.log("Call");

      setState(() => true);
      const paramsMap = {};
      formData.forEach((value, key) => {
        const [type, field, property] = key.split("_");
        const fieldName = `${type}_${field}`;
        if (!paramsMap[fieldName]) {
          paramsMap[fieldName] = { fieldName };
        }
        paramsMap[fieldName][property] =
          property === "enabled" || property === "required" ? value === "true" : value;
        console.log(type, field, property, value);
      });

      const params = Object.values(paramsMap);

      console.log(paramsMap);
      validationApi
        .update(params)
        .then(() => {
          notification.success({ message: "Update validations success!" });
        })
        .catch(() => {
          notification.error({ message: "Update validations failure!" });
        })
        .finally(() => {
          setState(() => false);
        });
    }
  ];
}

const StopValidations = [
  {
    type: "Photos 1",
    placeholder: "Proof of :type Photo",
    defaultChecked: true,
    name: "photo1",
    left: true,
    tooltip: "Requests driver to include a pickup/delivery proof photo"
  },
  {
    type: "Signature 1",
    placeholder: ":type Signature",
    defaultChecked: true,
    name: "signature1",
    left: true,
    tooltip: "Requests driver the fill a signature for the pickup/delivery"
  },
  // This is client notes validation
  /* {
    type: "Notes",
    placeholder: "Customer Notes",
    defaultChecked: true,
    name: "notes",
    left: true
  },*/
  {
    type: "Driver Notes",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "driverNotes",
    left: true,
    tooltip: "Requests driver a note about the pickup/delivery"
  },
  {
    type: "COD",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "cod",
    left: true,
    tooltip: "Indicates driver the amount of Cash on Delivery"
  },
  {
    type: "Photos 2",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "photo2",
    tooltip: "Requests driver to include a second pickup/delivery proof photo"
  },
  {
    type: "Signature 2",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "signature2",
    tooltip: "Requests driver the fill a second signature for the pickup/delivery"
  },
  {
    type: "Document Scanner",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "documentScanner",
    tooltip: "Requests driver to upload a document"
  },
  {
    type: "Barcode Scanner",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "barcodeScanner",
    tooltip: "Request driver to add packages via a barcode scan"
  },
  {
    type: "Instructions",
    placeholder: "Leave it blank if you want to use the default title",
    defaultChecked: false,
    name: "instructions",
    tooltip: "Add specific pickup/delivery instructions"
  }
];

function assign(type, value, lookup) {
  const result = lookup[type][value.name];
  if (!result) return;
  value.defaultChecked = result.enabled;
  value.required = result.required;
  value.customTitle = result.customTitle;
  value.content = result.content;
}

export function useStopValidationFields() {
  const [state, setState] = useState(() => {
    const pickup = StopValidations.map((it) => ({
      ...it,
      placeholder: it.placeholder.replace(":type", "Pickup")
    }));
    const delivery = StopValidations.map((it) => ({
      ...it,
      placeholder: it.placeholder.replace(":type", "Delivery")
    }));
    const instructionsKey = "instructions";
    return {
      pickup: {
        top: pickup.find((it) => it.name === instructionsKey),
        left: pickup.filter((field) => field.left && field.name !== instructionsKey),
        right: pickup.filter((field) => !field.left && field.name !== instructionsKey)
      },
      delivery: {
        top: delivery.find((it) => it.name === instructionsKey),
        left: delivery.filter((field) => field.left && field.name !== instructionsKey),
        right: delivery.filter((field) => !field.left && field.name !== instructionsKey)
      }
    };
  });

  useEffect(() => {
    validationApi.getAll().then((it) => {
      if (!it) return;
      console.log(it);
      const { pickup, delivery } = state;

      const lookup = {};

      it.forEach((field) => {
        const [type, name] = field.fieldName.split("_");
        if (!lookup[type]) lookup[type] = {};
        lookup[type][name] = field;
      });

      pickup.left.forEach((value) => {
        assign("pickup", value, lookup);
      });

      pickup.right.forEach((value) => {
        assign("pickup", value, lookup);
      });
      assign("pickup", pickup.top, lookup);

      delivery.left.forEach((value) => {
        assign("delivery", value, lookup);
      });

      delivery.right.forEach((value) => {
        assign("delivery", value, lookup);
      });
      assign("delivery", delivery.top, lookup);

      setState(() => ({ pickup, delivery }));
    });
    // eslint-disable-next-line
  }, []);
  return state;
}
