import { Button } from "antd";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getListStyle } from "../../helper";
import { UnscheduleIcon, VisibleIcon, VisibleOffIcon, InfoIcon } from "../../svgs";
import Slot from "./Slot";
import "./UnschedulePart.scss";
import { DND_STATUSES } from "../../constants";

const UnschedulePart = ({
  handleSetModalUnscheduled,
  isDragging,
  dndStatus,
  stops = [],
  handleHoverMarker,
  handleClosePopup,
  handleClickMarker,
  hideUnscheduled,
  handleHideUnscheduled,
  handleStopDetail
}) => {
  const hasUnscheduledStops = stops.length > 0;
  return (
    <div className="schedule-part-component">
      <div style={{ position: "relative", width: "0px" }}>
        <a
          href="https://support.goflexio.com/content/unscheduled-stops"
          target="_blank"
          rel="noreferrer"
        >
          <InfoIcon
            style={{
              width: "20px",
              height: "20px",
              position: "relative",
              top: "14px",
              left: "24px"
            }}
          />
        </a>
      </div>
      <div className="left-side">
        {hideUnscheduled ? (
          <VisibleOffIcon onClick={handleHideUnscheduled} />
        ) : (
          <VisibleIcon onClick={handleHideUnscheduled} />
        )}
        <Button
          className="third-btn svg-icon  unschedule-icon no-border"
          size="small"
          onClick={handleSetModalUnscheduled}
        >
          Unscheduled
          <UnscheduleIcon className="small-margin" />
        </Button>
        <div style={{ backgroundColor: hasUnscheduledStops ? "#ff9999" : "" }}>
          <Droppable
            key="unscheduled"
            droppableId="unscheduled"
            direction="horizontal"
            isDropDisabled={dndStatus === DND_STATUSES.OPTIMIZE}
          >
            {(provided, snapshot) => (
              <div
                className={`list-slots ${isDragging && "isDragging"} ${dndStatus}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {stops.map((stop, index) => (
                  <Draggable key={stop.id} draggableId={stop.id} index={index}>
                    {(provided1, snapshot1) => (
                      <Slot
                        provided={provided1}
                        snapshot={snapshot1}
                        stop={stop}
                        key={stop.id}
                        handleHoverMarker={handleHoverMarker}
                        handleClosePopup={handleClosePopup}
                        handleClickMarker={handleClickMarker}
                        handleStopDetail={handleStopDetail}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </div>
      </div>
      {/* <Space className="right-side">
        <Button type="secondary-with-icon" className="svg-icon" size="small">
          Force Schedule All
          <ScheduleIcon color={COLORS.primary} />
        </Button>

        <PopoverSummaryRoutePlan routePlan={routePlan}>
          <Button type="link" className="svg-icon summary" size="small">
            Summary
            <InfoIcon style={{ marginLeft: 5 }} />
          </Button>
        </PopoverSummaryRoutePlan>
      </Space> */}
    </div>
  );
};
export default UnschedulePart;
