import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getVehicleCategoryList = createAsyncThunk(
  "vehicle-categories/get/loading",
  async (data) => {
    return settingApi.getVehicleCategoryList(data);
  }
);

export const getAllVehicleCategories = createAsyncThunk(
  "vehicle-categories/getAll/loading",
  async () => {
    return settingApi.getAllVehicleCategory();
  }
);

export const doGetVehicleCategory = createAsyncThunk(
  "vehicle-categories/getOne/loading",
  async (id) => {
    return settingApi.getVehicleCategory(id);
  }
);

export const doCreateVehicleCategory = createAsyncThunk(
  "vehicle-categories/create/loading",
  async (data) => {
    return settingApi.createVehicleCategory(data);
  }
);

export const doUpdateVehicleCategory = createAsyncThunk(
  "vehicle-categories/create/loading",
  async (data) => {
    return settingApi.updateVehicleCategory(data);
  }
);

export const doDeleteVehicleCategory = createAsyncThunk(
  "vehicle-categories/delete/loading",
  async (id) => {
    return settingApi.deleteVehicleCategory(id);
  }
);
