import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { decodePolyline } from "modules/shared/PickupDropoff/decodePolyline";
import {
  doGetRoute,
  getRouteList,
  assignDriver,
  reassignDriver,
  cancelRoute,
  updatePrice
} from "./RouteThunks";

const RouteAdapter = createEntityAdapter({
  selectId: (route) => route.id
});

const initialState = RouteAdapter.getInitialState({
  metadata: {},
  route: {},
  filter: {
    status: ["pending", "accepted", "in-progress"]
  }
});

export const routeSlice = createSlice({
  name: "adminRoutes",
  initialState,
  reducers: {
    updatePaginationRoutes(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterRoutes(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRouteList.fulfilled, (state, { payload }) => {
      RouteAdapter.setAll(state, payload.data);
      state.route = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetRoute.fulfilled, (state, { payload }) => {
      const locations = payload.routeLocations.map((routeLocation) => {
        return routeLocation.location;
      });

      state.route = {
        ...payload,
        locations,
        display: true,
        color: locations && locations[0]?.color ? locations[0]?.color : payload?.color,
        coords: payload?.polylines ? decodePolyline(payload.polylines).coords : []
      };
    });
    builder.addCase(assignDriver.fulfilled, (state, { payload }) => {
      RouteAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(reassignDriver.fulfilled, (state, { payload }) => {
      RouteAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(cancelRoute.fulfilled, (state, { payload }) => {
      RouteAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(updatePrice.fulfilled, (state, { payload }) => {
      console.log("state.updatePrice", state.adminRoutes);
      console.log("payload", payload);
      RouteAdapter.updateOne(state, {
        id: payload.id,
        changes: { [payload.type]: payload.price }
      });
    });
  }
});

export const {
  selectById: selectRouteById,
  selectIds: selectRouteIds,
  selectEntities: selectRouteEntities,
  selectAll: selectAllRoutes,
  selectTotal: selectTotalRoutes
} = RouteAdapter.getSelectors((state) => state.adminRoutes);

export const selectMetadata = (state) => state.adminRoutes.metadata;
export const selectFilter = (state) => state.adminRoutes.filter;
export const selectRoute = (state) => state.adminRoutes.route;

export const { updatePaginationRoutes, updateFilterRoutes } = routeSlice.actions;

export default routeSlice.reducer;
