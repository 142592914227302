import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { doCreateUser, getUserList, deleteUser, doGetUser, doUpdateUser } from "./thunks";

const UsersAdapter = createEntityAdapter({
  selectId: (clientUser) => clientUser.id
});

const initialState = UsersAdapter.getInitialState({ metadata: {}, user: null });

export const userSlice = createSlice({
  name: "clientsUser",
  initialState,
  reducers: {
    updatePaginationUsers(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterUsers(state, action) {
      if (action.payload === undefined) {
        state.filter = {};
      } else {
        state.filter = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      UsersAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
    builder.addCase(doCreateUser.fulfilled, (state, { payload }) => {
      UsersAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doUpdateUser.fulfilled, (state, { payload }) => {
      UsersAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.metadata = payload.metadata;
      state.user = null;
    });
    builder.addCase(doGetUser.fulfilled, (state, { payload }) => {
      UsersAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.user = payload;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      console.log(payload);
      const oldMeta = state.metadata;
      UsersAdapter.removeOne(state, payload.id);
      state.metadata = {
        ...oldMeta,
        totalItems: oldMeta.totalItems ? oldMeta.totalItems - 1 : undefined
      };
    });
  }
});

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers
} = UsersAdapter.getSelectors((state) => state.users);

export const selectMetadata = (state) => state.users.metadata;
export const selectUser = (state) => state.users.user;
export const selectFilter = (state) => state.users.filter;

export const { updatePaginationUsers, updateFilterUsers } = userSlice.actions;

export default userSlice.reducer;
