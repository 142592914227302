import React, { useCallback, useState } from "react";
import axios from "axios";
import { Select, Spin } from "antd";
import _debounce from "lodash/debounce";
import _find from "lodash/find";

const PlacePolygonSearch = ({ onSelect, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const fetchCities = async (searchValue) => {
    try {
      setFetching(true);
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search.php?q=${searchValue}&polygon_geojson=1&format=json`
      );
      const resData = response?.data || [];

      const polygonData = resData.filter((entry) => entry?.geojson?.type === "Polygon");

      setOptions(
        polygonData.map(({ place_id, display_name }) => ({
          label: display_name,
          value: place_id
        }))
      );
      setSearchResults(response?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };
  const debounceFetcher = useCallback(_debounce(fetchCities, 300), [fetchCities]);

  const handleChangeCity = (value) => {
    onSelect && onSelect(_find(searchResults, { place_id: value })?.geojson?.coordinates);
  };

  return (
    <Select
      showSearch={true}
      filterOption={false}
      placeholder="Search place"
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      onChange={handleChangeCity}
      {...props}
    />
  );
};

export default PlacePolygonSearch;
