import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import React, { useEffect } from "react";

import "./ClientUserForm.scss";
import { USER_ROLES } from "configs/constants";
import { NAME_REGEX } from "configs/constants";

const ClientUserForm = ({ clientUser, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const isEditing = clientUser?.id;

  useEffect(() => {
    if (clientUser) {
      form.setFieldsValue({ ...clientUser, phone: clientUser?.phone || "" });
    }
  }, [clientUser, form]);

  return (
    <div className="ClientUserForm">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input disabled={isEditing} size="large" placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid First Name"
              }
            ]}
          >
            <Input size="large" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid Last Name"
              }
            ]}
          >
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone">
            <PhoneInput country="ca" enableSearch={true} />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select size="large" placeholder="Please Select">
              {USER_ROLES.map(({ value, name }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: !clientUser, message: "Required" }]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {isEditing ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ClientUserForm;
