import React, { useEffect, useState } from "react";
import classes from "./ValidationSection.module.scss";
import CustomValidationField from "./widget/CustomValidationField";
import { Button, Input } from "antd";
import { CheckCircleIcon } from "../../../components/icons";
import { useStopValidationFields, useUpdateValidation } from "./store/usecase";
import { clsx } from "../on-going/store/utils";
import { allowPermission } from "helper/util";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { canEditPermission } from "configs/constants";

function Page() {
  const [loading, update] = useUpdateValidation();
  const fields = useStopValidationFields();
  const canEdit = allowPermission(useSelector(selectRole), canEditPermission);

  const onUpdateClick = (e) => {
    e.preventDefault();
    update(new FormData(e.currentTarget));
  };

  const pickupTop = fields.pickup.top;
  const deliveryTop = fields.delivery.top;
  return (
    <form className={classes.container} onSubmit={onUpdateClick}>
      <div className={classes.title}>Pickup Validations</div>
      <div className={classes.sections}>
        <InstructionField
          instruction={pickupTop}
          className={classes.topSection}
          type={"pickup"}
        />

        <div className={classes.leftSection}>
          {fields.pickup.left.map((it, index) => (
            <CustomValidationField
              key={index}
              type={it.type}
              enabled={it.defaultChecked}
              placeholder={it.placeholder}
              namePrefix={`pickup_${it.name}`}
              required={it.required}
              customTitle={it.customTitle}
              tooltip={it.tooltip}
            />
          ))}
        </div>
        <div className={classes.rightSection}>
          {fields.pickup.right.map((it, index) => (
            <CustomValidationField
              key={index}
              type={it.type}
              enabled={it.defaultChecked}
              placeholder={it.placeholder}
              namePrefix={`pickup_${it.name}`}
              required={it.required}
              customTitle={it.customTitle}
              tooltip={it.tooltip}
            />
          ))}
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.title}>Delivery Validations</div>
      <div className={classes.sections}>
        <InstructionField
          instruction={deliveryTop}
          className={classes.topSection}
          type={"delivery"}
        />
        <div className={classes.leftSection}>
          {fields.delivery.left.map((it, index) => (
            <CustomValidationField
              key={index}
              type={it.type}
              enabled={it.defaultChecked}
              placeholder={it.placeholder}
              namePrefix={`delivery_${it.name}`}
              required={it.required}
              customTitle={it.customTitle}
              tooltip={it.tooltip}
            />
          ))}
        </div>
        <div className={classes.rightSection}>
          {fields.delivery.right.map((it, index) => (
            <CustomValidationField
              key={index}
              type={it.type}
              enabled={it.defaultChecked}
              namePrefix={`delivery_${it.name}`}
              placeholder={it.placeholder}
              required={it.required}
              customTitle={it.customTitle}
              tooltip={it.tooltip}
            />
          ))}
        </div>
      </div>

      {canEdit && (
        <>
          <div className={classes.divider} />
          <Button
            loading={loading}
            type={"primary"}
            htmlType={"submit"}
            className={classes.btnUpdate}
          >
            Update <CheckCircleIcon />
          </Button>
        </>
      )}
    </form>
  );
}

function InstructionField({ instruction, className, type }) {
  const [enabled, setEnabled] = useState(instruction.defaultChecked);
  const [content, setContent] = useState(instruction.content);

  useEffect(() => {
    setEnabled(instruction.defaultChecked);
  }, [instruction.defaultChecked]);

  useEffect(() => {
    setContent(instruction.content);
  }, [instruction.content]);

  return (
    <div className={clsx(classes.instruction, className)}>
      <CustomValidationField
        type={instruction.type}
        enabled={instruction.defaultChecked}
        placeholder={instruction.placeholder}
        namePrefix={`${type}_${instruction.name}`}
        required={instruction.required}
        customTitle={instruction.customTitle}
        onEnableChange={setEnabled}
        tooltip={instruction.tooltip}
      />
      <div className={classes.instructionContent}>
        <label>Content</label>
        <Input.TextArea
          name={`${type}_${instruction.name}_content`}
          disabled={!enabled}
          className={classes.area}
          onChange={(value) => setContent(value.target.value)}
          placeholder={"• Instruction 1\n• Instruction 2\n• Instruction 3"}
          value={content}
        />
      </div>
    </div>
  );
}

export default Page;
