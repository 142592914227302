import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserGroup, canCreatePermission } from "configs/constants";
import { allowPermission } from "helper/util";
import paginationFunc from "helper/hooks/pagination";
import { selectGroup, selectRole } from "modules/auth/store/authSlice";

import BroadcastHeader from "../components/BroadcastHeader";
import BroadcastTable from "../components/BroadcastTable";
import {
  selectAllBroadcasts,
  selectMetadata,
  updatePaginationBroadcasts
} from "../store/slice";
import { doGetDriverBroadcasts } from "../store/thunks";

const breadCrumbs = [{ text: "DRIVER BROADCAST" }];

const DriverBroadcastList = () => {
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const buttonParams =
    allowPermission(useSelector(selectRole), canCreatePermission) && isFlexioAdmin
      ? {
          text: "Create Driver Broadcast",
          link: "/admin/driver-broadcasts/new"
        }
      : null;
  const dispatch = useDispatch();

  const broadcasts = useSelector(selectAllBroadcasts);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Broadcasts",
    updatePaginationBroadcasts,
    dispatch
  );
  const page = metadata?.currentPage || 1;
  const pageSize =
    (metadata?.itemsPerPage || 10) > 100 ? 10 : metadata?.itemsPerPage || 10;

  useEffect(() => {
    dispatch(doGetDriverBroadcasts({ page, pageSize }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  return (
    <>
      <BroadcastHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />

      <div className="content-table">
        <BroadcastTable
          dataSource={broadcasts}
          pagination={{ ...pagination, size: "small" }}
        />
      </div>
    </>
  );
};
export default DriverBroadcastList;
