import React from "react";
import "./HeaderPage.scss";
const HeaderPage = ({ leftSide, rightSide }) => {
  return (
    <div className="header-page-component">
      <div className="left-side">{leftSide}</div>
      <div className="right-side">{rightSide}</div>
    </div>
  );
};
export default HeaderPage;
