import { DatePicker } from "antd";
import React from "react";
import { CalendarIcon } from "../svgs";
import "./CustomDatePicker.scss";
const CustomDatePicker = ({ ...rest }) => {
  return (
    <DatePicker
      allowClear={false}
      suffixIcon={<CalendarIcon />}
      className="custom-date-picker"
      {...rest}
    />
  );
};
export default CustomDatePicker;
