import React, { useState, useEffect } from "react";
import { Input, Menu, Dropdown, notification } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import "./AddressInput.scss";

const AddressInput = ({ address, value, onChange, onSelect, ...props }) => {
  const [addressText, setAddressText] = useState(address?.address ?? "");
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setAddressText(address?.address);
    if (address?.lngLat?.length > 0) {
      setValid(true);
    }
  }, [address]);

  const onChangeAddress = (newAddress) => {
    setAddressText(newAddress);
    setValid(false);
    onChange && onChange({ address: newAddress, lngLat: [] });
  };

  const onSelectAddress = (newAddress) => {
    setAddressText(newAddress);
    setValid(true);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        onSelect && onSelect({ address: newAddress, lngLat: [latLng.lng, latLng.lat] });
      });
  };

  return (
    <PlacesAutocomplete
      value={addressText || ""}
      onChange={onChangeAddress}
      onSelect={onSelectAddress}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const menu = suggestions.length ? (
          <Menu>
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    height: "100%",
                    width: "100%"
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    height: "100%",
                    width: "100%"
                  };
              return (
                <Menu.Item key={index}>
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
        ) : (
          <></>
        );

        return (
          <div>
            <Dropdown overlay={menu} placement="bottomLeft" visible={true}>
              <Input
                {...getInputProps()}
                value={addressText}
                autoComplete="no"
                suffix={
                  valid && addressText ? (
                    <CheckCircleFilled style={{ color: "#43C1C2" }} />
                  ) : (
                    ""
                  )
                }
                {...props}
              />
            </Dropdown>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressInput;
