import React from "react";
import { useParams } from "react-router-dom";
import RoutingMap from "../components/RoutingMap";
import classes from "./GoingNextPage.module.scss";
import { Button } from "antd";
import { clsx } from "../store/utils";
import { useFetchOnGoingOrder } from "../store/usecase";
import { Marker } from "react-mapbox-gl";
import StopOnMap from "../components/StopOnMap";
import PolylineOnMap from "../components/PolylineOnMap";
import { AccessTimeIcon, CallIcon, LocalShippingIcon } from "../components/Icons";
import { RouteLocationStatus } from "../store/model";

export default function OnGoingPage() {
  const { token } = useParams();
  const value = useFetchOnGoingOrder(token);

  const onCallClick = () => {
    window.location.href = "tel:" + value.phone;
  };

  return (
    <div className={classes.container}>
      <div className={classes.map}>
        <RoutingMap bounds={value.bounds} center={value.orderLocation}>
          <DriverMarker location={value.driverLocation} />

          <DestinationMarker location={value.orderLocation} />

          <PolylineOnMap coords={value.coords} color={"#43C1C2"} />
        </RoutingMap>
      </div>
      <div className={classes.bottomSheet}>
        <label>Your order is on the way</label>
        <p>{value.address}</p>
        <div className={classes.hLine} />
        {value.hasDriver() && (
          <div className={classes.driver}>
            <div className={classes.avatar}>{value.driverShortName}</div>
            <label>{value.driverName}</label>
            <p>{value.vehicleModelAndColor}</p>
            <span className={classes.plateNumber}>{value.plateNumber}</span>
          </div>
        )}
        <div className={classes.action}>
          <Status value={value} />
          <Button className={classes.btnCall} onClick={onCallClick}>
            Call <CallIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

function Status({ value }) {
  switch (value.status) {
    case RouteLocationStatus.Unassigned:
    case RouteLocationStatus.Unserved:
    case RouteLocationStatus.Draft:
    case RouteLocationStatus.Completed:
    case RouteLocationStatus.Failed:
      return <SimpleStatus value={value} />;
    case RouteLocationStatus.Assigned:
    case RouteLocationStatus.Pending:
      return <OnGoingStatus value={value} />;

    default:
      return <></>;
  }
}
const statusColorOf = (status) => {
  switch (status) {
    case RouteLocationStatus.Failed:
      return classes.red;
    default:
      return classes.gray;
  }
};

function SimpleStatus({ value }) {
  return (
    <div className={clsx(classes.status, classes.simple, statusColorOf(value.status))}>
      {value.statusName}
    </div>
  );
}

function OnGoingStatus({ value }) {
  return (
    <div className={clsx(classes.status, classes.eta)}>
      <AccessTimeIcon /> <span>ETA</span> <b>{value.eta}</b>
    </div>
  );
}

function DriverMarker({ location }) {
  if (!location) return <></>;
  return (
    <Marker coordinates={[location.lng, location.lat]}>
      <div className={classes.shipping}>
        <LocalShippingIcon />
      </div>
    </Marker>
  );
}

function DestinationMarker({ location }) {
  if (!location) return <></>;
  return (
    <Marker coordinates={[location.lng, location.lat]}>
      <StopOnMap borderColor={"#0078D4"} backgroundColor={"#FFF"} />
    </Marker>
  );
}
