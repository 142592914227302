import React from "react";
import pluralize from "pluralize";

const pagination = (params, text, updateMeta, dispatch) => {
  const onChangePage = (currentPage, itemsPerPage) => {
    dispatch(
      updateMeta({
        ...params,
        currentPage,
        itemsPerPage
      })
    );
  };
  return params
    ? {
        position: ["bottomCenter"],
        current: params.currentPage,
        pageSize: params.itemsPerPage || 100,
        total: params.totalItems,
        showSizeChanger: true,
        onChange: onChangePage,
        onShowSizeChange: onChangePage,
        // eslint-disable-next-line react/display-name
        showTotal: (totalPage) => {
          return (
            <>
              <b>{totalPage}</b> {pluralize(text, totalPage)}
            </>
          );
        }
      }
    : {};
};

export default pagination;
