import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { CheckBoxHook, RangePickerHook, SelectHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getClientList } from "modules/admin/clients/store/thunks";
import clientApi from "modules/admin/clients/store/api";
import { getTerritoryList } from "modules/admin/settings/store/TerritoryThunks";
import { selectAllTerritories } from "modules/admin/settings/store/TerritorySlice";
import { selectGroup } from "modules/auth/store/authSlice";
import { UserGroup, DATE_FORMAT_FRONTEND } from "configs/constants";

import "../../routes/components/header/FilterRoute.scss";

const FilterComponent = ({ isAdmin, handleClickFilter }) => {
  const {
    reset,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });
  const clients = useSelector(selectAllClients);
  const territories = useSelector(selectAllTerritories);
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCityList = async () => {
      const data = await clientApi.getAllClientCities();
      setCities(data.map((item) => ({ id: item, name: item })));
    };
    if (isAdmin) {
      getCityList();
      dispatch(getClientList({ pageSize: 1000 }));
      isFlexioAdmin && dispatch(getTerritoryList({ pageSize: 1000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAdmin]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  const handleReset = async () => {
    await reset();
    setValue("dateRange", [moment(), moment()]);
    const today = moment().format(DATE_FORMAT_FRONTEND);
    handleClickFilter({ dateRange: [today, today] });
  };

  const allCitiesOption = { name: `All Cities (${cities?.length || 0})`, id: "" };
  const cityOptions = cities
    .filter((city) => city.name !== "Please Select")
    .map((city) => ({ name: city.name, id: city.id }));

  return (
    <div className="filter-order-component">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={`wrap-form-filter ${isAdmin ? "" : "three-column"}`}>
          <RangePickerHook
            control={control}
            getValues={getValues}
            label="Date Range"
            name="dateRange"
            errors={errors}
            showOptionsName={false}
            defaultValue={[moment(), moment()]}
          />
          {isAdmin ? (
            <>
              {isFlexioAdmin && (
                <SelectHook
                  showOptionsName={false}
                  control={control}
                  getValues={getValues}
                  placeholder="Territory"
                  label="Territory"
                  name="territoryId"
                  valueSelect="id"
                  labelSelect="name"
                  options={[
                    { name: `All Territories (${territories?.length || 0})`, id: "" },
                    ...territories
                  ]}
                  required
                  errors={errors}
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                />
              )}
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                placeholder="Client"
                label="Client"
                name="clientId"
                valueSelect="id"
                labelSelect="clientName"
                options={[
                  { clientName: `All Clients (${clients?.length || 0})`, id: "" },
                  ...clients
                ]}
                required
                errors={errors}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              />
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                placeholder="City"
                label="City"
                name="city"
                valueSelect="id"
                labelSelect="name"
                /* options={[
                  { name: `All Cities (${cities?.length || 0})`, id: "" },
                  ...cities
                ]} */
                options={[allCitiesOption, ...cityOptions]}
                required
                errors={errors}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              />
            </>
          ) : (
            <div className="wrap-form-filter flex"></div>
          )}
        </div>
        {isAdmin && (
          <div className="wrap-form-filter flex">
            <CheckBoxHook
              showOptionsName={false}
              control={control}
              label="Driver"
              name="isFlexioDriver"
              errors={errors}
              showLabel={true}
            >
              Flexio Driver
            </CheckBoxHook>
            <CheckBoxHook
              showOptionsName={false}
              control={control}
              label="&nbsp;"
              name="isInternalDriver"
              errors={errors}
              showLabel={true}
            >
              Internal Driver
            </CheckBoxHook>
          </div>
        )}
        <div className="wrap-button">
          <Button type="link" className="svg-icon clear" onClick={handleReset}>
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(FilterComponent);
