import { decodePolyline } from "../../../shared/PickupDropoff/decodePolyline";
import { useEffect, useRef, useState } from "react";
import { useDriverLocation } from "../../../shared/driver-location";
import onGoingApi from "./api";
import moment from "moment";
import { capitalize } from "lodash/string";
import { RouteLocationStatus } from "./model";

const mock = {
  status: "assigned|unassigned",
  orderAddress: "123 Long street",
  pickupDate: "2022-07-22",
  orderLat: 10.8101522,
  orderLng: 106.6648894,
  orderEta: "10:00 AM",
  driverFirstName: "Jensen",
  driverLastName: "Alex",
  driverPhone: "+84932221288",
  driverLat: 0.12,
  driverLng: 0.313,
  vehicleModel: "Ford F150 2018",
  vehicleColor: "Silver",
  plateNumber: "HMC 091",
  polyline:
    "ktt`A}bcjSEU]k@|@Y_DoK_TjGwBf@IDKIOGY@KBaCsB_KqJc@e@yI_IeA}@kS_REKo@o@S@u@}@mBmBiFyECH_BjB}DlE{AhB{A~AiIpJmFyFgFmFuEwE_@FcChCgWrYcBfBoFjGqBvBqJrK}LrVqNbYeEzIsJpRcAhCaBbDq@hAaApBgDlBa@XwAh@sAZs@LwFp@o@NqEH{K_@uLg@"
};

function createBounds(coords) {
  if (coords.length < 2) {
    if (coords.length === 1) return createFocusTo(coords[0]);
    return coords;
  }

  const topLeft = { ...coords[0] };
  const bottomRight = { ...coords[1] };

  for (let i = 0; i < coords.length; i++) {
    const coord = coords[i];
    if (topLeft.lat < coord.lat) {
      topLeft.lat = coord.lat;
    }
    if (bottomRight.lat > coord.lat) {
      bottomRight.lat = coord.lat;
    }
    if (topLeft.lng < coord.lng) {
      topLeft.lng = coord.lng;
    }
    if (bottomRight.lng > coord.lng) {
      bottomRight.lng = coord.lng;
    }
  }
  return [topLeft, bottomRight];
}

function createFocusTo(orderLocation) {
  return [
    {
      lat: orderLocation.lat + 0.01,
      lng: orderLocation.lng - 0.01
    },
    {
      lat: orderLocation.lat - 0.01,
      lng: orderLocation.lng + 0.01
    }
  ];
}

export function useFetchOnGoingOrder(jobId) {
  const [state, setState] = useState({
    coords: undefined,
    bounds: undefined,
    hasDriver: () => false
  });
  const driverId = useRef();

  const driverLocation = useDriverLocation(driverId.current);

  useEffect(() => {
    onGoingApi.getDeliveryByJobId(jobId).then((it) => {
      driverId.current = it.driverId;

      const driverName =
        it.driverFirstName || it.driverLastName
          ? `${it.driverFirstName || ""} ${it.driverLastName || ""}`
          : null;

      const vehicle =
        it.driverFirstName || it.driverLastName
          ? `${it.vehicleModel || "N/A"} - ${it.vehicleColor || "N/A"}`
          : null;

      const orderLocation = {
        lat: parseFloat(it.orderLat),
        lng: parseFloat(it.orderLng)
      };

      const newState = {
        ...state,
        phone: it.driverPhone,
        address: it.orderAddress,
        orderLocation: orderLocation,
        driverName: driverName,
        vehicleModelAndColor: vehicle,
        plateNumber: it.plateNumber || "N/A",
        driverShortName: `${
          it.driverFirstName?.substring(0, 1) || it.driverLastName?.substring(0, 1) || ""
        }`,
        status: it.status,
        statusName: capitalize(it.status.replaceAll("_", " ")),
        hasDriver: () => !!it.driverId
      };
      setState(newState);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const orderLocation = state.orderLocation;
    if (!driverLocation || !orderLocation) return;
    const shouldFetch = [
      RouteLocationStatus.Assigned,
      RouteLocationStatus.Pending
    ].includes(state.status);

    if (!shouldFetch) {
      console.log(state.status);
      return;
    }

    const timeNow = moment().format("HH:mm");
    onGoingApi.getDirection(timeNow, driverLocation, orderLocation).then((it) => {
      const polyline = decodePolyline(it.polyline);
      let bounds = createBounds(polyline.coords, driverLocation, orderLocation);
      let coords = polyline.coords.map((latLng) => [latLng.lng, latLng.lat]);
      const newState = {
        ...state,
        driverLocation: driverLocation,
        eta: it.eta,
        coords: coords,
        bounds: bounds
      };
      setState(newState);
    });
    // eslint-disable-next-line
  }, [driverLocation, state.orderLocation]);

  return state;
}
