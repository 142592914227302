import { createAsyncThunk } from "@reduxjs/toolkit";
import additionalVehicleApi from "./api";

export const doGetList = createAsyncThunk(
  "additional-vehicles/get/loading",
  async (data) => {
    return additionalVehicleApi.getList(data);
  }
);

export const doGet = createAsyncThunk(
  "additional-vehicles/getOne/loading",
  async (id) => {
    return additionalVehicleApi.get(id);
  }
);

export const doCreate = createAsyncThunk(
  "additional-vehicles/create/loading",
  async (data) => {
    console.log(data);
    return additionalVehicleApi.create(data);
  }
);

export const doUpdate = createAsyncThunk(
  "additional-vehicles/create/loading",
  async (data) => {
    return additionalVehicleApi.update(data);
  }
);

export const doDelete = createAsyncThunk(
  "additional-vehicles/delete/loading",
  async (id) => {
    return additionalVehicleApi.delete(id);
  }
);
