import request from "../../../../helper/request";

export const packageTypeApi = {
  getList: () => {
    return request.get("/package-types");
  },
  getPoolList: () => {
    return request.get("/package-types?isPool=true");
  }
};
