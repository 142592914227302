import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

import "./AdminBreadCrumb.scss";

const AdminBreadCrumb = ({ breadCrumbs }) => {
  return (
    <Breadcrumb className="AdminBreadCrumb" separator=">">
      {breadCrumbs.map((breadCrumb, i) => (
        <Breadcrumb.Item key={i}>
          {breadCrumb.url ? (
            <Link to={breadCrumb.url}>{breadCrumb.text}</Link>
          ) : (
            breadCrumb.text
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
export default AdminBreadCrumb;
