import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateInternalDriver,
  doDeleteInternalDriver,
  doGetDriver,
  doUpdateInternalDriver,
  getInternalDriverList
} from "./thunks";

const InternalDriverAdapter = createEntityAdapter({
  selectId: (driver) => driver.id
});

const initialState = InternalDriverAdapter.getInitialState({ metadata: {}, driver: {} });

export const internalDriversSlice = createSlice({
  name: "internalDrivers",
  initialState,
  reducers: {
    updatePaginationDrivers(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInternalDriverList.fulfilled, (state, { payload }) => {
      InternalDriverAdapter.setAll(state, payload.data);
      state.driver = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetDriver.fulfilled, (state, { payload }) => {
      state.driver = payload;
    });
    builder.addCase(doCreateInternalDriver.fulfilled, (state, { payload }) => {
      InternalDriverAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doUpdateInternalDriver.fulfilled, (state, { payload }) => {
      InternalDriverAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    });
    builder.addCase(doDeleteInternalDriver.fulfilled, (state, { payload }) => {
      InternalDriverAdapter.removeOne(state, payload?.id);
    });
  }
});

export const {
  selectById: selectInternalDriverById,
  selectIds: selectInternalDriverIds,
  selectEntities: selectInternalDriverEntities,
  selectAll: selectAllInternalDrivers,
  selectTotal: selectTotalInternalDrivers
} = InternalDriverAdapter.getSelectors((state) => state.internalDrivers);

export const selectMetadata = (state) => state.drivers.metadata;
export const selectDriver = (state) => state.drivers.driver;

export const { updatePaginationDrivers } = internalDriversSlice.actions;

export default internalDriversSlice.reducer;
