import React, { useState, createContext, useEffect, useCallback } from "react";
import { setError } from "app/global/slice";
import { useDispatch } from "react-redux";
import request from "../../helper/request";

export const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ children }) => {
  const [isPoolAvailable, setIsPoolAvailable] = useState(null);
  const dispatch = useDispatch();

  const fetchFeatureFlags = useCallback(async () => {
    try {
      const response = await request.get("/feature-flags?flagName=poolService");
      setIsPoolAvailable(response);
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchFeatureFlags();
  }, [fetchFeatureFlags]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        isPoolAvailable,
        fetchFeatureFlags
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
