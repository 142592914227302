import React from "react";
const ClientIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 0.666656H2C1.08333 0.666656 0.341667 1.41666 0.341667 2.33332L0.333334 15.6667C0.333334 16.5833 1.075 17.3333 1.99167 17.3333H12C12.9167 17.3333 13.6667 16.5833 13.6667 15.6667V5.66666L8.66667 0.666656ZM10.3333 14H3.66667V12.3333H10.3333V14ZM10.3333 10.6667H3.66667V8.99999H10.3333V10.6667ZM7.83333 6.49999V1.91666L12.4167 6.49999H7.83333Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};
export { ClientIcon };
