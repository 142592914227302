/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Popconfirm, Space } from "antd";

import TableBase from "components/table/TableBase";
import { canDeletePermission, canEditPermission } from "configs/constants";
import { allowPermission, showDay } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";

import DriverStatus from "../components/DriverStatus";

const DriverTable = ({ dataSource, pagination, doDeleteDriver }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();

  const onRowOrder = (record) => {
    return {
      onClick: () => {
        history.push(`/admin/users/drivers/${record.id}`);
      } // click row
    };
  };

  const handleDelete = (record) => {
    doDeleteDriver(record.id);
    // dispatch(deleteClient(client));
  };

  const handleEdit = (record) => {
    console.log(record);
    // history.push(`/orders/${record.id}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 110,
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      },
      sorter: (a, b) => a.id?.localeCompare(b.id)
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 120,
      sorter: (a, b) => a.firstName?.localeCompare(b.firstName)
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: 120,
      sorter: (a, b) => a.lastName?.localeCompare(b.lastName)
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 210,
      ellipsis: true,
      sorter: (a, b) => a.email?.localeCompare(b.email)
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: 180,
      sorter: (a, b) => a.phone?.localeCompare(b.phone)
    },
    {
      title: "Country",
      dataIndex: "country",
      width: 100,
      sorter: (a, b) => a.country?.localeCompare(b.country)
    },
    {
      title: "Vehicle on Registration",
      dataIndex: "vehicleTypeOnRegistration",
      width: 100,
      sorter: (a, b) =>
        a.vehicleTypeOnRegistration?.localeCompare(b.vehicleTypeOnRegistration),
      render: (vehicleType) => {
        if (Array.isArray(vehicleType)) {
          return vehicleType.join(", ");
        } else {
          return vehicleType;
        }
      }
    },
    {
      title: "City",
      dataIndex: "city",
      width: 100,
      sorter: (a, b) => a.country?.localeCompare(b.city)
    },
    {
      title: "Is Internal Driver",
      width: 150,
      render: (record) => {
        return <div>{record.isInternalDriver ? "YES" : "NO"}</div>;
      },
      sorter: (a, b) => a.isInternalDriver - b.isInternalDriver
    },
    {
      title: "Territory",
      dataIndex: ["territory", "name"],
      width: 150,
      sorter: (a, b) => a.territory?.name?.localeCompare(b.territory?.name)
    },
    {
      title: "Status",
      width: 120,
      render: (record) => {
        return <DriverStatus status={record?.status} />;
      },
      sorter: (a, b) => a.active - b.active
    },
    {
      title: "Registered Date",
      dataIndex: "createdAt",
      width: 160,
      render: (value) => showDay(value),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    }
  ];

  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <img
                src="/icons/edit.svg"
                className={`table-status`}
                onClick={() => handleEdit(record)}
              />
            )}
            {canDelete && (
              <div onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => handleDelete(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <img src="/icons/delete.svg" className={`table-status`} />
                </Popconfirm>
              </div>
            )}
            {record?.requestedDeleteAt && (
              <div className={`driver-status deleting`}>Deleting</div>
            )}
          </Space>
        );
      }
    });
  }
  return (
    <div className="ActiveOrdersTable">
      <TableBase
        columns={columns}
        onRow={onRowOrder}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default DriverTable;
