import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { doCreateSaasCountry } from "../../store/SaasCountriesThunks";
import { ArrowDownIcon } from "components/svgs";
import "../../styles/settings.scss";
import { useState } from "react";
import { DEFAULT_CENTER } from "configs/constants";
import PolygonMapboxForm from "./PolygonMapboxForm";
import { loadAllCountries } from "helper/util";
const { Option } = Select;

const centerOfCountry = {
  US: [-101.299591, 47.116386],
  PR: [-66.105721, 18.466333]
};

const SaasCountriesForm = ({ saasCountry }) => {
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [isEditable, setEditable] = useState(false);
  const [polygon, setPolygon] = useState(saasCountry?.polygon || []);
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/settings/saas-countries");
  };

  const onSubmit = async (values) => {
    values = {
      ...values,
      polygon
    };
    console.log(values);
    try {
      let erRs = undefined;
      if (!saasCountry) {
        const { error } = await dispatch(
          doCreateSaasCountry({
            ...values
          })
        );
        erRs = error;
      } else {
        const { error } = await dispatch(
          doCreateSaasCountry({
            ...values
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/settings/saas-countries");
    } catch {
      return false;
    }
  };
  const getCenterOfPolygon = (arr) => {
    if (arr && arr.length > 0) {
      const x = arr[0].map((xy) => xy[0]);
      const y = arr[0].map((xy) => xy[1]);
      const cx = (Math.min(...x) + Math.max(...x)) / 2;
      const cy = (Math.min(...y) + Math.max(...y)) / 2;
      return [cx, cy];
    }
    return DEFAULT_CENTER;
  };

  useEffect(() => {
    if (saasCountry) {
      setPolygon(saasCountry?.polygon);
      const tmpCenter = getCenterOfPolygon(saasCountry?.polygon);
      setCenter(tmpCenter);
    }
  }, [saasCountry, form]);

  const onCompleteDraw = (coordinates) => {
    setPolygon(coordinates);
    form.setFieldsValue({ polygon: coordinates });
    setEditable(false);
  };

  const handleCountryChange = (value) => {
    setCenter(centerOfCountry[value]);
  };

  return (
    <div className="common-form split-two-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Country Name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Country Name" />
          </Form.Item>
          <Form.Item
            label="Country"
            placeholder="Country"
            name="country"
            rules={[{ required: true, message: "Required" }]}
            labelSelect="name"
          >
            <Select
              suffixIcon={<ArrowDownIcon />}
              onChange={(value) => handleCountryChange(value)}
              showSearch
              optionFilterProp="children"
              defaultValue=""
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
            >
              {loadAllCountries().map((option) => (
                <Option key={option["isoCode"]} value={option["isoCode"]}>
                  {option["name"]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <PolygonMapboxForm
          onCompleteDraw={onCompleteDraw}
          coords={polygon}
          isEditable={isEditable}
          setEditable={setEditable}
          center={center}
        />
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Create
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SaasCountriesForm;
