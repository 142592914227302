import React, { useContext, useMemo } from "react";
import { ProfileContext } from "layouts/private/ProfileContext";
import { Card } from "antd";
import CreditCardLogo from "../components/CreditCardLogo";

const PaymentMethodPage = () => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);

  return (
    <div className="common-form return-process-form">
      <div className="mb-3">
        <h2 className="title">Payment Methods</h2>
      </div>

      {!profile?.paymentMethods?.length && <div>No payment methods available.</div>}
      {profile?.paymentMethods?.map((pm, index) => {
        return (
          <div style={style.cardContainer} key={pm.name + (index + 1)}>
            <Card title="" style={style.pmCardStyle} hoverable>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%"
                }}
              >
                <CreditCardLogo brand={pm.brand} />
                <div>
                  <div style={{ display: "flex" }}>
                    <b>{pm.name}</b>
                    <h4 style={{ color: "#4d4d4d" }}>{pm.CcCardType}</h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly"
                      }}
                    >
                      **** {pm.last4}
                    </div>
                  </div>
                  <div>
                    Exp
                    {" " + pm.exp_month}/{pm.exp_year}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

const style = {
  pmCardStyle: {
    width: "300px"
  },
  cardContainer: {
    padding: 0
  }
};

export default PaymentMethodPage;
