import React, { useEffect } from "react";

const GoogleTag = () => {
  useEffect(() => {
    console.log("useEffect triggered");
    // Google Tag Manager code
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-89JLPXWHZJ";
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-89JLPXWHZJ");
    };
  }, []);

  return <div></div>;
};

export default GoogleTag;
