import { createAsyncThunk } from "@reduxjs/toolkit";
import orderApi from "./api";

export const getOrderList = createAsyncThunk("orders/get/loading", async (data) => {
  return orderApi.getOrderList(data);
});

export const doDeleteOrder = createAsyncThunk("orders/delete/loading", async (id) => {
  return orderApi.delete(id);
});

export const doCreateActiveOrder = createAsyncThunk(
  "orders/create/loading",
  async (data) => {
    return orderApi.createActiveOrder(data);
  }
);

export const doGetOrder = createAsyncThunk("orders/active/loading", async (data) => {
  return orderApi.getOrder(data);
});
export const doGetOrderRoutes = createAsyncThunk(
  "order-routes/active/loading",
  async (data) => {
    return orderApi.getOrderRoutes(data);
  }
);

export const doGetJobOrder = createAsyncThunk("orders/job/loading", async (data) => {
  return orderApi.getJobOrder(data);
});
export const doOptimizeOrder = createAsyncThunk(
  "orders/optimize/loading",
  async (data) => {
    await orderApi.optimizeOrder(data);
    return true;
  }
);

export const doOptimizeOrderCallback = createAsyncThunk(
  "orders/optimize-callback/loading",
  async (data) => {
    const { order, vehicleCategoryList, isSelectRouteNumber, numberRoutes } = data;
    return {
      output: data?.output,
      order,
      vehicleCategoryList,
      isSelectRouteNumber,
      numberRoutes
    };
  }
);

export const doSortOrderCallback = createAsyncThunk(
  "orders/sortOrder-callback/loading",
  async (data) => {
    const { order, vehicleCategoryList } = data;
    // let result = {};
    // result = await orderApi.sortOrderRoute(data);
    return {
      output: data?.output,
      order,
      vehicleCategoryList
    };
  }
);
export const doSortOrder = createAsyncThunk("orders/sortOrder/loading", async (data) => {
  await orderApi.sortOrderRoute(data);
  return true;
});

export const doStoreDraftId = createAsyncThunk(
  "orders/doStoreDraftId/loading",
  (data) => {
    return data;
  }
);
