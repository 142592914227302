import React from "react";
import { Tag } from "antd";
import { CircleIcon, CircleDotIcon, CircleHalfStrokeIcon } from "components/svgs";
import { convertHexToRGBA } from "helper/util";

const PAYMENT_STATUS = {
  unpaid: {
    text: "Unpaid",
    color: "#888888",
    icon: <CircleDotIcon className={`route-status`} color={"#888888"} />
  },
  overdue: {
    text: "Overdue",
    color: "#0078D4",
    icon: <CircleDotIcon className={`route-status`} color={"#0078D4"} />
  },
  paid: {
    text: "Paid",
    color: "#00C853",
    icon: <CircleIcon className={`route-status`} color={"#00C853"} />
  },
  void: {
    text: "Void",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  refunded: {
    text: "Refunded",
    color: "#000000",
    icon: <CircleDotIcon className={`route-status`} color={"#000000"} />
  }
};

const PaymentStatusInList = ({ value }) => {
  const status = value && PAYMENT_STATUS[value] ? value : "overdue";

  return (
    <Tag
      color={convertHexToRGBA(PAYMENT_STATUS[status].color, 20)}
      style={{
        color: PAYMENT_STATUS[status].color,
        lineHeight: "24px",
        textAlign: "center",
        borderRadius: "2px"
      }}
    >
      {PAYMENT_STATUS[status].icon}
      {PAYMENT_STATUS[status].text}
    </Tag>
  );
};

export default PaymentStatusInList;
