import { PHONE_VALIDATION } from "configs/constants";
import { NAME_REGEX } from "configs/constants";
import * as yup from "yup";
export const step1Schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(NAME_REGEX, "Invalid First Name"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(NAME_REGEX, "Invalid Last Name"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(PHONE_VALIDATION, "Phone is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().when("country", {
    is: "US",
    then: yup.string().required("State is required"),
    otherwise: yup.string().nullable()
  }),
  agree: yup.bool().oneOf([true], "Field must be checked").required("Please click agree")
});

export const step2Schema = yup.object().shape({
  state: yup.string().required("State is required"),
  city: yup.string().optional(),
  postalAddress: yup.string().required("Postal Address is required"),
  vehicleTypes: yup.array().required("Vehicle Types is required"),
  vehicleMake: yup.string().required("Vehicle make is required"),
  vehicleModel: yup.string().required("Vehicle model is required"),
  vehicleColor: yup.string().required("Vehicle color is required"),
  vehicleYear: yup.string().required("Vehicle year is required"),
  numberPlate: yup.string().required("Number plate is required"),
  licenseExpirationDate: yup
    .string()
    .required("Driver's License Expiration Date is required"),
  registrationExpirationDate: yup
    .string()
    .required("Driver's Registration Expiration Date is required"),
  insuranceExpirationDate: yup
    .string()
    .required("Vehicle Insurance Expiration Date is required"),
  recordExpirationDate: yup
    .string()
    .required("Driving Record Expiration Date is required"),
  recordCertificateDate: yup
    .string()
    .required("Criminal Record Certificate Expiration Date is required"),
  vehicleLicensePlate: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle License Plate is required"),
  profilePhoto: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Profile Photo Plate is required"),
  vehicleImageLeftSide: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Image Left Side is required"),
  vehicleImageRightSide: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Image Right Side is required"),
  vehicleImageFrontSide: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Image Front Side is required"),
  vehicleImageBackSide: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Image Back Side is required"),
  driverLicense: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Driver License is required"),
  vehicleRegistration: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Registration is required"),
  vehicleInsurance: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Insurance is required"),
  drivingRecord: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Driving Record is required"),
  criminalRecordCertificate: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Criminal Record Certificate is required")
});
export const step2SchemaWithoutState = yup.object().shape({
  city: yup.string().optional(),
  postalAddress: yup.string().required("Postal Address is required"),
  vehicleTypes: yup.array().required("Vehicle Types is required"),
  vehicleMake: yup.string().required("Vehicle make is required"),
  vehicleModel: yup.string().required("Vehicle model is required"),
  vehicleColor: yup.string().required("Vehicle color is required"),
  vehicleYear: yup.string().required("Vehicle year is required"),
  numberPlate: yup.string().required("Number plate is required"),
  vehicleLicensePlate: yup.object().required("Vehicle License Plate is required"),
  profilePhoto: yup.object().required("Profile Photo Plate is required"),
  vehicleImageLeftSide: yup.object().required("Vehicle Image Left Side is required"),
  vehicleImageRightSide: yup.object().required("Vehicle Image Right Side is required"),
  vehicleImageFrontSide: yup.object().required("Vehicle Image Front Side is required"),
  vehicleImageBackSide: yup.object().required("Vehicle Image Back Side is required"),
  driverLicense: yup.object().required("Driver License is required"),
  vehicleRegistration: yup.object().required("Vehicle Registration is required"),
  vehicleInsurance: yup.object().required("Vehicle Insurance is required"),
  drivingRecord: yup.object().required("Driving Record is required"),
  criminalRecordCertificate: yup
    .object()
    .required("Criminal Record Certificate is required")
});
export const step3Schema = yup.object().shape({
  fullNameBank: yup.string().required("Full Name of Bank Account Owner is required"),
  emailBank: yup
    .string()
    .email()
    .required("Email Address of Bank Account Owner is required"),
  bankName: yup.string().required("Bank Name is required"),
  bankAccountNumber: yup.string().required("Bank Account Number is required"),
  bankAccountRoutingNumber: yup
    .string()
    .required("Bank Account Routing Number is required"),
  accountType: yup.string().required("Account Type is required"),
  haveInsurance: yup
    .string()
    .required("Do you have Commercial General Liability Insurance? is required"),
  haveLicenseCertification: yup.string().optional(),
  haveTransportationDelivery: yup.string().required("This field can't blank"),
  rateUsingMobile: yup.string().required("This field can't blank"),
  understandLanguages: yup.array().required("This field can't blank"),
  additionalRelevantExperiences: yup.string().optional(),
  learnFlexio: yup.array().required("This field can't blank"),
  taxSsnOrEin: yup.string().required("SSN or EIN is required"),
  taxName: yup.string().required("Name is required"),
  taxAddress: yup.string().required("Address is required")
});
