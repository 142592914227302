import React from "react";
import SettingHeader from "../components/header/SettingHeader";
import TerritoryForm from "../components/form/TerritoryForm";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";

const TerritoryNew = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/territories", text: "TERRITORIES" },
    { text: "NEW" }
  ];
  return (
    <div className="large-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create Territory</h2>
        <TerritoryForm />
      </div>
    </div>
  );
};
export default withPermission(TerritoryNew, canCreatePermission);
