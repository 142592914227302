import request from "helper/request";

const driverBroadcastApi = {
  getDriverBroadcasts: (params) => {
    return request.get("/admin/driver-broadcasts", { params });
  },
  getDriverBroadcast: (id) => {
    return request.get(`/admin/driver-broadcasts/${id}`);
  },
  createDriverBroadcast: (data) => {
    return request.post("/admin/driver-broadcasts", data);
  }
};

export default driverBroadcastApi;
