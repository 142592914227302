import { yupResolver } from "@hookform/resolvers/yup";
import { notification, Spin, Button } from "antd";
import { InputHook, PhoneInputHook, SelectHook, SwitchHook } from "components/hook-forms";
import request from "helper/request";
import { formatDateToBackend } from "helper/util";
import { loadAllCountries } from "helper/util";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Cards from "../components/Cards";
import { doCreateDriver } from "../store/thunks";
import ActionButton from "./ActionButtons";
import { step1Schema } from "./schema";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { CheckCircleIcon } from "components/svgs";
import "./Step1Form.scss";
import { DRIVER_STATUS_OPTIONS } from "helper/util";
import { GroupRadioHook } from "components/hook-forms";
import { phoneTypeOptions } from "helper/util";
import { serviceProviderOptions } from "helper/util";

const Step1Form = ({ driver, handleUpdateDriver, showActionButton, isAdmin = true }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUS, setIsUS] = useState(false);
  const [currentSwitchStyle, setCurrentSwitchStyle] = useState({});
  const switchStyle = useMemo(() => ({ marginBottom: "30px", marginTop: "-20px" }), []);

  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(step1Schema),
    defaultValues: {
      active: false
    }
  });

  const handleCountryChange = useCallback(
    (value) => {
      setValue("country", value);
      if (value === "US") {
        setIsUS(true);
        setCurrentSwitchStyle(switchStyle);
      } else {
        setIsUS(false);
        setCurrentSwitchStyle({});
      }
      console.log(switchStyle);
      console.log(value);
    },
    [setValue, switchStyle]
  );

  useEffect(() => {
    if (!isEmpty(driver)) {
      reset({ ...driver, emergencyContactPhone: driver?.emergencyContactPhone || "1" });
      if (driver?.country === "US") {
        setIsUS(true);
        setCurrentSwitchStyle(switchStyle);
      }
    }
  }, [driver, reset, switchStyle]);

  const submitForm = async (values) => {
    // setIsLoading(true);
    let errorsCall = null;
    let newDriverId = "";

    const assignTerritoryId = await request.get(
      "/admin/drivers/get-territoryId-by-country",
      { params: { country: getValues("country") } }
    );

    const saasCountry = await request.get("/admin/saascountries/get-saas-country", {
      params: { country: getValues("country") }
    });

    if (!saasCountry) {
      notification.error({
        message: "Flexio services are not available in this country",
        placement: "topRight"
      });
      return;
    }

    /* if (!assignTerritoryId) {
      notification.error({
        message: "Country Out of Range",
        placement: "topRight"
      });
      return;
    } */
    const payload = { ...values, territoryId: assignTerritoryId };
    if (!driver?.id) {
      const isExistEmail = await request.post("/drivers/validate-email", {
        email: getValues("email")
      });
      if (isExistEmail) {
        notification.error({
          message: "Email has been register!",
          placement: "topRight"
        });
        errorsCall = "Email has been register!";
      } else {
        const dataRs = await dispatch(
          doCreateDriver({
            ...payload,
            licenseExpirationDate: formatDateToBackend(values?.licenseExpirationDate),
            recordExpirationDate: formatDateToBackend(values?.recordExpirationDate),
            recordCertificateDate: formatDateToBackend(values?.recordCertificateDate)
          })
        );
        errorsCall = dataRs.error;
        newDriverId = dataRs?.payload?.id;
      }
    } else {
      errorsCall = await handleUpdateDriver(payload);
    }

    if (!errorsCall && !driver?.id) {
      notification.success({
        message: "Create driver successfully!",
        placement: "bottomRight"
      });
      history.push(`/admin/users/drivers/${newDriverId}`);
    }
    if (!errorsCall && driver?.id) {
      notification.success({
        message: "Update successfully!",
        placement: "bottomRight"
      });
    }
    // setIsLoading(false);
  };

  return (
    <Spin spinning={false}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Cards
          title={"Basic Information"}
          className="admin-wrap-step-1-form"
          rightTitle="All fields are required"
        >
          <>
            <div className="group-form">
              <InputHook
                control={control}
                label="First Name"
                placeholder="First Name"
                name="firstName"
                showOptionsName={false}
                errors={errors}
              />
              <InputHook
                control={control}
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                showOptionsName={false}
                errors={errors}
              />
              <InputHook
                control={control}
                placeholder="Email"
                label="Email"
                name="email"
                showOptionsName={false}
                errors={errors}
              />
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                label="Country"
                name="country"
                placeholder="Country"
                valueSelect="isoCode"
                labelSelect="name"
                required
                errors={errors}
                options={loadAllCountries()}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes((input ?? "").toLowerCase())
                }
                onChange={handleCountryChange}
              />
              <PhoneInputHook
                control={control}
                label="Phone Number"
                placeholder="Phone Number"
                name="phone"
                showOptionsName={false}
                errors={errors}
              />
              {isUS && (
                <SelectHook
                  showOptionsName={false}
                  control={control}
                  getValues={getValues}
                  label="State"
                  name="state"
                  placeholder="State"
                  valueSelect="isoCode"
                  labelSelect="name"
                  required={isUS}
                  errors={errors}
                  options={getStatesOfCountry("US")}
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes((input ?? "").toLowerCase())
                  }
                  defaultValue="Please Select"
                />
              )}

              {isAdmin && (
                <div style={currentSwitchStyle}>
                  <SelectHook
                    showOptionsName={false}
                    control={control}
                    label="Status"
                    name="status"
                    required={true}
                    errors={errors}
                    options={DRIVER_STATUS_OPTIONS}
                    defaultValue="Please Select"
                  />
                </div>
              )}
              <GroupRadioHook
                label="Phone Type"
                options={phoneTypeOptions}
                showOptionsName={false}
                control={control}
                name="phoneType"
                errors={errors}
              />
              <GroupRadioHook
                label="Service Provider"
                options={serviceProviderOptions}
                showOptionsName={false}
                control={control}
                name="serviceProvider"
                errors={errors}
              />
              <InputHook
                control={control}
                placeholder="Emergency Contact Name"
                label="Emergency Contact Name"
                name="emergencyContactName"
                showOptionsName={false}
                errors={errors}
              />
              <PhoneInputHook
                control={control}
                placeholder="Emergency Contact Phone"
                label="Emergency Contact Phone"
                name="emergencyContactPhone"
                showOptionsName={false}
                errors={errors}
              />
            </div>
          </>
        </Cards>
        {showActionButton && (
          <>
            {isAdmin ? (
              <ActionButton text={isEmpty(driver) ? "Create" : "Update"} />
            ) : (
              <div className="action-buttons">
                <Button type="primary" className="svg-icon" htmlType="submit">
                  Update
                  <CheckCircleIcon color="#FFFFFF" />
                </Button>
              </div>
            )}
          </>
        )}
      </form>
    </Spin>
  );
};
export default Step1Form;
