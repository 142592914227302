import { Tabs } from "antd";
import isEmpty from "lodash/isEmpty";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import "./DriverPage.scss";
import { useSelector, useDispatch } from "react-redux";

import Step1Form from "../forms/Step1Form";
import Step2Form from "../forms/Step2Form";
import Step3Form from "../forms/Step3Form";
import Step4Form from "../forms/Step4Form";
import AdminBreadCrumb from "components/AdminBreadCrumb";
import HeaderPage from "components/headers/HeaderPage";
import { selectDriver } from "../store/slice";
import { doGetDriver, doUpdateDriver } from "../store/thunks";
import { PlusCircleOutlined } from "@ant-design/icons";
import AdditionalVehicleList from "./AdditionalVehicleList";
import { CREATE_ADDTIONAL_VEHICLE_URL } from "../constants";
import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { canEditPermission } from "configs/constants";
import { formatDateToBackend } from "helper/util";
const { TabPane } = Tabs;

const DriverPage = () => {
  const callback = () => {};
  const queryParams = new URLSearchParams(window.location.search);
  const driver = useSelector(selectDriver);
  const dispatch = useDispatch();
  const params = useParams();
  const id = params?.id;
  useEffect(() => {
    if (id) {
      dispatch(doGetDriver(id));
    }
  }, [dispatch, id]);

  const breadCrumbs = [
    { text: "USER" },
    { text: "DRIVERS", url: "/admin/users/drivers" },
    { text: isEmpty(driver) ? "NEW" : `${driver.firstName} ${driver.lastName}` }
  ];

  const role = useSelector(selectRole);
  const canCreate = allowPermission(role, canCreatePermission);
  const canEdit = allowPermission(role, canEditPermission);

  const handleUpdateDriver = async (values) => {
    const dataSubmit = {
      ...values,
      // vehicleLicensePlate: values?.vehicleLicensePlate?.id || undefined,
      profilePhoto: values?.profilePhoto?.id || undefined,
      vehicleTypeOnRegistration: values?.vehicleTypeOnRegistration || undefined,
      // vehicleImageLeftSide: values?.vehicleImageLeftSide?.id || undefined,
      // vehicleImageRightSide: values?.vehicleImageRightSide?.id || undefined,
      // vehicleImageFrontSide: values?.vehicleImageFrontSide?.id || undefined,
      // vehicleImageBackSide: values?.vehicleImageBackSide?.id || undefined,
      driverLicense: values?.driverLicense?.id || undefined,
      // vehicleRegistration: values?.vehicleRegistration?.id || undefined,
      // vehicleInsurance: values?.vehicleInsurance?.id || undefined,
      drivingRecord: values?.drivingRecord?.id || undefined,
      criminalRecordCertificate: values?.criminalRecordCertificate?.id || undefined,
      licenseExpirationDate: formatDateToBackend(values?.licenseExpirationDate),
      // registrationExpirationDate: formatDateToBackend(values?.registrationExpirationDate),
      // insuranceExpirationDate: formatDateToBackend(values?.insuranceExpirationDate),
      recordExpirationDate: formatDateToBackend(values?.recordExpirationDate),
      recordCertificateDate: formatDateToBackend(values?.recordCertificateDate)
    };
    const { error } = await dispatch(doUpdateDriver(dataSubmit));
    return error;
  };

  return (
    <div className="driver-new-page">
      <HeaderPage leftSide={<AdminBreadCrumb breadCrumbs={breadCrumbs} />} />

      <Tabs defaultActiveKey={queryParams.get("activeTab") || "1"} onChange={callback}>
        <TabPane tab="Basic Information" key="1">
          <Step1Form
            driver={driver}
            showActionButton={canEdit}
            handleUpdateDriver={handleUpdateDriver}
          />
        </TabPane>
        {!isEmpty(driver) && (
          <>
            <TabPane tab="Advanced Information" key="2">
              <Step2Form
                driver={driver}
                showActionButton={canEdit}
                handleUpdateDriver={handleUpdateDriver}
              />
            </TabPane>
            <TabPane tab="Payment Information" key="3">
              <Step3Form
                driver={driver}
                showActionButton={canEdit}
                handleUpdateDriver={handleUpdateDriver}
              />
            </TabPane>
            <TabPane tab="Vehicles" key="4">
              <div className="additional-vehicles-tab">
                <AdditionalVehicleList />
                {canCreate && (
                  <Link
                    className="add-additional-vehicle-btn"
                    to={CREATE_ADDTIONAL_VEHICLE_URL(id)}
                  >
                    Add Vehicle <PlusCircleOutlined />
                  </Link>
                )}
              </div>
            </TabPane>
            <TabPane tab="Others" key="5">
              <Step4Form
                driver={driver}
                showActionButton={canEdit}
                handleUpdateDriver={handleUpdateDriver}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
};
export default withPermission(DriverPage);
