import { COLORS } from "configs/constants";
import { round, find, sortBy } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { VehicleIcon } from "../../svgs";
import "./DriverDetailPopup.scss";

export const DriverDetailPopup = ({ driver, handleClickMarker, groupStops = [] }) => {
  console.log("driver", driver);
  const travelMins = driver?.travelMins || "-";
  const serviceMins = driver?.serviceMins || 0;
  const waitingMins = driver?.waitingMins || "-";
  const returnMins = driver?.returnMins || "-";
  const returnDistance = driver?.returnDistance || 0;
  const workingMins = driver?.workingMins || "-";
  const workingPercentage = driver?.workingPercentage || "-";
  const distance = driver?.distance ? round(driver?.distance / 1000, 2) : 0;
  const routeLocations = driver?.routeLocations || groupStops || [];
  const completedStops = routeLocations?.filter((s) => s.status === "completed");
  return (
    <div className="content-info-window info-route">
      <div className="content-header">
        <div className="title">
          <VehicleIcon color={driver?.color} /> &nbsp;
          {`${driver?.driver?.firstName || ""} ${driver?.driver?.lastName || ""}`}
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "25%" }}> Start Location </th>
              <th style={{ width: "25%" }}> Time Started First Job </th>
              <th style={{ width: "25%" }}> End Location </th>
              <th style={{ width: "25%" }}> Time Ended Last Job </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="address">{driver?.startAddress}</div>
              </td>
              <td>{driver?.shiftStart}</td>
              <td>
                <div className="address">{driver?.endAddress}</div>
              </td>
              <td>{driver?.shiftEnd}</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            borderTop: "1px solid #E0E0E0",
            margin: "10px 0"
          }}
        />
        <table>
          <thead>
            <tr>
              <th style={{ width: "12.5%" }}> Time Shift </th>
              <th style={{ width: "12.5%" }}> Completed </th>
              <th style={{ width: "12.5%" }}> Distance </th>
              <th style={{ width: "12.5%" }}> Travel Time </th>
              <th style={{ width: "12.5%" }}> Service Time </th>
              <th style={{ width: "12.5%" }}> Working Time </th>
              <th style={{ width: "12.5%" }}> Waiting Time </th>
              <th style={{ width: "12.5%" }}> Return Time </th>
              <th style={{ width: "12.5%" }}> Return Distance </th>
              <th style={{ width: "12.5%" }}> Working % </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{driver?.shiftStart + " - " + (driver?.shiftEnd || "")}</td>
              <td>
                {(completedStops?.length || "-") + "/" + (routeLocations?.length || "-")}
              </td>
              <td>{distance} km</td>
              <td>
                {travelMins} {pluralize("min", travelMins)}
              </td>
              <td>
                {serviceMins} {pluralize("min", serviceMins)}
              </td>
              <td>
                {workingMins} {pluralize("min", workingMins)}
              </td>
              <td>
                {waitingMins} {pluralize("min", waitingMins)}
              </td>
              <td>
                {returnMins} {pluralize("min", returnMins)}
              </td>
              <td>{returnDistance / 1000} km</td>
              <td>{workingPercentage}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="content-main">
        <table>
          <thead>
            <tr>
              <th style={{ width: "2%" }}></th>
              <th style={{ width: "5%" }}> Status </th>
              <th style={{ width: "3%" }}> Order ID </th>
              <th style={{ width: "10%" }}> Customer Name </th>
              <th style={{ width: "14%" }}> Address </th>
              <th style={{ width: "8%" }}> Job Type </th>
              <th style={{ width: "9%" }}> Time Window </th>
              <th style={{ width: "8%" }}> Arv. Time </th>
              <th style={{ width: "8%" }}> Fin. Time </th>
              <th style={{ width: "8%" }}> Distance </th>
              <th style={{ width: "8%" }}> Travel Time </th>
              <th style={{ width: "9%" }}> Service Time </th>
              <th style={{ width: "9%" }}> Working Time </th>
              <th style={{ width: "9%" }}> Waiting Time </th>
            </tr>
          </thead>
          <tbody>
            {sortBy(routeLocations, "sortNo").map((stop, index) => {
              const groupStop = find(
                groupStops,
                (g) =>
                  (g.arrivalTime === stop?.arrivalTime ||
                    g.finishTime === stop?.finishTime) &&
                  g.routeId === stop?.routeId
              );
              return (
                <tr
                  key={index}
                  onClick={() => handleClickMarker(groupStop, "stop")}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        border: `1px solid ${driver?.color || "#c4c4c4"}`,
                        color: `${driver?.color || "#c4c4c4"}`,
                        textAlign: "center",
                        minWidth: "24px",
                        minHeight: "24px",
                        lineHeight: "22px",
                        fontSize: "14px",
                        borderRadius:
                          (stop?.type || stop?.location?.type) === "pickup" ? "50%" : 0
                      }}
                    >
                      {index + 1}
                    </div>
                  </td>
                  <td>
                    <div className={`stop-status ${stop?.status}`}>{stop?.status}</div>
                  </td>
                  <td>
                    <a
                      href={`/orders/${stop?.job?.orderId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textTransform: "uppercase" }}
                    >
                      {stop?.job?.orderId?.slice(0, 6)}
                    </a>
                  </td>
                  <td>
                    <div className="customer">
                      {stop?.contactName || stop?.location?.contactName}
                    </div>
                  </td>
                  <td>
                    <div className="address">
                      {stop?.address || stop?.location?.address}
                    </div>
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {stop?.type || stop?.location?.type}
                  </td>
                  <td>
                    {(stop?.start || stop?.location?.start || "") +
                      " - " +
                      (stop?.end || stop?.location?.end || "")}
                  </td>
                  <td>{stop?.arrivalTime}</td>
                  <td>{stop?.finishTime}</td>
                  <td>{stop?.distance ? round(stop?.distance / 1000, 2) : 0} km</td>
                  <td>
                    {stop?.travelMins || 0} {pluralize("min", stop?.travelMins || 0)}
                  </td>
                  <td>
                    {stop?.duration || 0} {pluralize("min", stop?.duration || 0)}
                  </td>
                  <td>
                    {stop?.workingMins || 0} {pluralize("min", stop?.workingMins || 0)}
                  </td>
                  <td>
                    {stop?.waitingMins || 0} {pluralize("min", stop?.waitingMins || 0)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
