import React, { useContext, useMemo, useState } from "react";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
import Step1Form from "modules/admin/drivers/forms/Step1Form";
import request from "helper/request";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";

const BasicInformationDetails = () => {
  const profileDriverCtx = useContext(DriverProfileContext);
  const fetchDriverProfile = profileDriverCtx.fetchProfile;
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateDriver = async (values) => {
    try {
      setLoading(true);
      const dataSubmit = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        country: values.country,
        email: values.email,
        phoneType: values.phoneType,
        serviceProvider: values.serviceProvider,
        emergencyContactName: values.emergencyContactName,
        emergencyContactPhone: values.emergencyContactPhone
      };

      const response = await request.patch("/drivers/", dataSubmit);
      dispatch(setSuccessMessage("Updated successfully!"));
      fetchDriverProfile();
      return response;
    } catch (error) {
      console.log(error);
      return {};
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Step1Form
          driver={driverProfile}
          handleUpdateDriver={handleUpdateDriver}
          showActionButton={true}
          isAdmin={false}
        />
      </Spin>
    </div>
  );
};

export default BasicInformationDetails;
