import ForgotPasswordPage from "modules/admin/auth/ForgotPasswordPage";
import AdminUserCreatePasswordPage from "modules/admin/users/pages/AdminUserCreatePassword";
import AdminClientCreate from "modules/admin/clients/pages/AdminClientCreate";
import AdminClientEdit from "modules/admin/clients/pages/AdminClientEdit";
import AdminClientList from "modules/admin/clients/pages/AdminClientList";
import ListDriverPage from "modules/admin/drivers/pages/DriverListPage";
import DriverPage from "modules/admin/drivers/pages/DriverPage";
import AdditionalVehiclePage from "modules/admin/drivers/pages/AdditionalVehiclePage";
import AdminRouteList from "modules/admin/orders/pages/RouteList";
import AdminDelayedRouteList from "modules/admin/orders/pages/DelayedRouteList";
import AdminEditStopPage from "modules/admin/orders/pages/EditStop";
import AdminSettingPackageTypeEdit from "modules/admin/settings/pages/PackageTypeEdit";
import AdminSettingPackageTypeList from "modules/admin/settings/pages/PackageTypeList";
import AdminSettingPackageTypeNew from "modules/admin/settings/pages/PackageTypeNew";
import AdminSettingServiceEdit from "modules/admin/settings/pages/ServiceEdit";
import AdminSettingServiceList from "modules/admin/settings/pages/ServiceList";
import AdminSettingServiceNew from "modules/admin/settings/pages/ServiceNew";
import AdminSettingTerritoryEdit from "modules/admin/settings/pages/TerritoryEdit";
import AdminSettingTerritoryList from "modules/admin/settings/pages/TerritoryList";
import AdminSettingTerritoryNew from "modules/admin/settings/pages/TerritoryNew";
import AdminSettingSaasCountries from "modules/admin/settings/pages/SaasCountries";
import AdminSettingSaasCountriesNew from "modules/admin/settings/pages/SaasCountriesNew";
import AdminSettingVehicleCategoryEdit from "modules/admin/settings/pages/VehicleCategoryEdit";
import AdminSettingVehicleCategoryList from "modules/admin/settings/pages/VehicleCategoryList";
import AdminSettingVehicleCategoryNew from "modules/admin/settings/pages/VehicleCategoryNew";
import AdminSettingFailedRegistrationList from "modules/admin/settings/pages/FailedRegistrationList";
import AdminSettingVersionList from "modules/admin/settings/pages/VersionList";
import AdminEditUserAdmin from "modules/admin/users/pages/AdminEditUserAdmin";
import AdminEditUserClient from "modules/admin/users/pages/AdminEditUserClient";
import AdminEditUserTerritory from "modules/admin/users/pages/AdminEditUserTerritory";
import AdminUserAdminList from "modules/admin/users/pages/AdminUserAdminList";
import AdminUserClientList from "modules/admin/users/pages/AdminUserClientList";
import AdminUserCreateAdmin from "modules/admin/users/pages/AdminUserCreateAdmin";
import AdminUserCreateClient from "modules/admin/users/pages/AdminUserCreateClient";
import AdminUserCreateTerritory from "modules/admin/users/pages/AdminUserCreateTerritory";
import AdminUserTerritoryList from "modules/admin/users/pages/AdminUserTerritoryList";
import AdminLoginPage from "modules/auth/pages/AdminLoginPage";
import ConfirmRoutePageAdmin from "modules/admin/orders/pages/ConfirmRoutePageAdmin";
import AdminCreateOrderPage from "modules/admin/orders/pages/AdminCreateOrderPage";
import HistoriesPage from "modules/client/history/pages/RouteHistoryListPage";
import RouteDetailPage from "modules/client/routes/pages/RouteDetailPage";
import JobDetail from "modules/client/active-orders/pages/JobDetail";
import RouteDispatchPage from "modules/client/routes/pages/RouteDispatchPage";
import AddJob from "modules/client/active-orders/pages/AddJob";
import DashboardPage from "modules/client/dashboard";
import DashboardMapPage from "modules/client/dashboard/map";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateAdminRoute from "routes/PrivateAdminRoute";
import DriverFeedbacksPage from "modules/admin/driver-feedback/pages/DriverFeedbacksPage";
import DriverBroadcastList from "modules/admin/driver-broadcast/pages/DriverBroadcastList";
import DriverBroadcastCreate from "modules/admin/driver-broadcast/pages/DriverBroadcastCreate";
import DriverBroadcastDetail from "modules/admin/driver-broadcast/pages/DriverBroadcastDetail";
import InvoicingListPage from "modules/admin/invoicing/pages/InvoicingList";
import FlexioPoolList from "modules/admin/pool/pages/FlexioPoolList";
import PoolOrders from "modules/admin/pool/pages/PoolOrders";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { UserRole } from "configs/constants";
import DelayedRouteList from "modules/admin/orders/pages/DelayedRouteList";

const AdminSpace = () => {
  const role = useSelector(selectRole);
  return (
    <Switch>
      <Route exact path="/admin/login" component={AdminLoginPage} />
      <Route exact path="/admin/forgot-password" component={ForgotPasswordPage} />
      <Route
        exact
        path="/admin/create-password"
        component={AdminUserCreatePasswordPage}
      />

      <PrivateAdminRoute exact path="/admin" component={DelayedRouteList} />
      <PrivateAdminRoute exact path="/admin/dashboard" component={DashboardPage} />
      <PrivateAdminRoute exact path="/admin/dashboard/map" component={DashboardMapPage} />
      {role === UserRole.ADMIN && (
        <PrivateAdminRoute
          exact
          path="/admin/users/admins"
          component={AdminUserAdminList}
        />
      )}
      {role === UserRole.ADMIN && (
        <PrivateAdminRoute
          exact
          path="/admin/users/clients"
          component={AdminUserClientList}
        />
      )}
      {role === UserRole.ADMIN && (
        <PrivateAdminRoute
          exact
          path="/admin/users/territories"
          component={AdminUserTerritoryList}
        />
      )}
      {role === UserRole.ADMIN && (
        <PrivateAdminRoute exact path="/admin/users/drivers" component={ListDriverPage} />
      )}
      <PrivateAdminRoute exact path="/admin/users/drivers/new" component={DriverPage} />
      <PrivateAdminRoute exact path="/admin/users/drivers/:id" component={DriverPage} />
      <PrivateAdminRoute
        exact
        path="/admin/users/drivers/:id/add-additional-vehicle"
        component={AdditionalVehiclePage}
      />
      <PrivateAdminRoute
        exact
        path="/admin/users/drivers/:id/add-additional-vehicle/:additionalVehicleId/edit"
        component={AdditionalVehiclePage}
      />

      <PrivateAdminRoute
        exact
        path="/admin/users/territories/new"
        component={AdminUserCreateTerritory}
      />

      <PrivateAdminRoute
        exact
        path="/admin/users/clients/new"
        component={AdminUserCreateClient}
      />
      <PrivateAdminRoute
        exact
        path="/admin/users/admins/new"
        component={AdminUserCreateAdmin}
      />
      <PrivateAdminRoute
        exact
        path="/admin/users/clients/:id"
        component={AdminEditUserClient}
      />
      <PrivateAdminRoute
        exact
        path="/admin/users/territories/:id"
        component={AdminEditUserTerritory}
      />
      <PrivateAdminRoute
        exact
        path="/admin/users/admins/:id"
        component={AdminEditUserAdmin}
      />
      <PrivateAdminRoute exact path="/admin/clients" component={AdminClientList} />
      <PrivateAdminRoute exact path="/admin/clients/new" component={AdminClientCreate} />
      <PrivateAdminRoute exact path="/admin/clients/:id" component={AdminClientEdit} />
      {role !== UserRole.VIEWER && (
        <PrivateAdminRoute
          exact
          path="/admin/settings/package-types"
          component={AdminSettingPackageTypeList}
        />
      )}
      <PrivateAdminRoute
        exact
        path="/admin/settings/package-types/new"
        component={AdminSettingPackageTypeNew}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/package-types/:id"
        component={AdminSettingPackageTypeEdit}
      />
      {role !== UserRole.VIEWER && (
        <PrivateAdminRoute
          exact
          path="/admin/settings/vehicle-categories"
          component={AdminSettingVehicleCategoryList}
        />
      )}
      <PrivateAdminRoute
        exact
        path="/admin/settings/vehicle-categories/new"
        component={AdminSettingVehicleCategoryNew}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/vehicle-categories/:id"
        component={AdminSettingVehicleCategoryEdit}
      />
      {role !== UserRole.VIEWER && (
        <PrivateAdminRoute
          exact
          path="/admin/settings/services"
          component={AdminSettingServiceList}
        />
      )}
      <PrivateAdminRoute
        exact
        path="/admin/settings/services/new"
        component={AdminSettingServiceNew}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/services/:id"
        component={AdminSettingServiceEdit}
      />
      {role !== UserRole.VIEWER && (
        <PrivateAdminRoute
          exact
          path="/admin/settings/territories"
          component={AdminSettingTerritoryList}
        />
      )}
      <PrivateAdminRoute
        exact
        path="/admin/settings/territories/new"
        component={AdminSettingTerritoryNew}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/territories/:id"
        component={AdminSettingTerritoryEdit}
      />
      {role !== UserRole.VIEWER && (
        <PrivateAdminRoute
          exact
          path="/admin/settings/saas-countries"
          component={AdminSettingSaasCountries}
        />
      )}
      <PrivateAdminRoute
        exact
        path="/admin/settings/saas-countries/new"
        component={AdminSettingSaasCountriesNew}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/failed-registration"
        component={AdminSettingFailedRegistrationList}
      />
      <PrivateAdminRoute
        exact
        path="/admin/settings/version"
        component={AdminSettingVersionList}
      />
      <PrivateAdminRoute exact path="/admin/routes" component={AdminRouteList} />
      <PrivateAdminRoute
        exact
        path="/admin/delayed-routes"
        component={AdminDelayedRouteList}
      />
      <PrivateAdminRoute exact path="/admin/routes/:id" component={RouteDetailPage} />
      <PrivateAdminRoute
        exact
        path="/admin/routes/:id/dispatch"
        component={RouteDispatchPage}
      />
      <PrivateAdminRoute exact path="/admin/routes/:id/:eid" component={JobDetail} />
      <PrivateAdminRoute exact path="/admin/routes/:id/:eid/add-job" component={AddJob} />
      <PrivateAdminRoute exact path="/admin/jobs/new" component={AdminCreateOrderPage} />
      <PrivateAdminRoute
        exact
        path="/admin/jobs/confirm-route"
        component={ConfirmRoutePageAdmin}
      />
      <PrivateAdminRoute exact path="/admin/invoicing" component={InvoicingListPage} />
      <PrivateAdminRoute exact path="/admin/history" component={HistoriesPage} />
      <PrivateAdminRoute
        exact
        path="/admin/routes/:routeId/stops/:id/edit"
        component={AdminEditStopPage}
      />
      <PrivateAdminRoute
        exact
        path="/admin/driver-feedbacks"
        component={DriverFeedbacksPage}
      />
      <PrivateAdminRoute
        exact
        path="/admin/driver-broadcasts"
        component={DriverBroadcastList}
      />
      <PrivateAdminRoute
        exact
        path="/admin/driver-broadcasts"
        component={DriverBroadcastList}
      />
      <PrivateAdminRoute
        exact
        path="/admin/driver-broadcasts/new"
        component={DriverBroadcastCreate}
      />
      <PrivateAdminRoute
        exact
        path="/admin/driver-broadcasts/:id"
        component={DriverBroadcastDetail}
      />
      <PrivateAdminRoute exact path="/admin/pool/packages" component={FlexioPoolList} />
      <PrivateAdminRoute exact path="/admin/pool/orders" component={PoolOrders} />
    </Switch>
  );
};
export default AdminSpace;
