import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _keys from "lodash/keys";
import paginationFunc from "../../../../helper/hooks/pagination";
import {
  selectMetadata,
  updatePaginationPackages
} from "modules/client/pool/store/slice";
import "modules/admin/orders/styles/routes.scss";
import request from "helper/request";
import FilterFlexioPool from "../components/Filter";
import FlexioPoolHeader from "../components/Header";
import { buildFirstLevelFilterPoolExport } from "helper/util";
import FlexioPoolOrderTable from "../components/FlexioPoolOrderTable";

const breadCrumbs = [{ text: "POOL ORDERS" }];

const PoolOrders = () => {
  const [ordersV2, setOrdersV2] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const dispatch = useDispatch();
  const metadata = useSelector(selectMetadata);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const page = useMemo(() => metadata?.currentPage || 1, [metadata?.currentPage]);
  const pageSize = useMemo(() => metadata?.itemsPerPage || 20, [metadata?.itemsPerPage]);

  const handleFilterChange = useCallback((newFilterValues) => {
    setFilterValues(newFilterValues);
  });

  const pagination = useMemo(
    () =>
      paginationFunc(
        { ...metadata, itemsPerPage: metadata?.itemsPerPage || 20 },
        "Packages",
        updatePaginationPackages,
        dispatch
      ),
    [metadata, dispatch]
  );

  const getOrdersV2 = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        filter: buildFirstLevelFilterPoolExport(
          filterValues?.pickupDateRange?.length > 0
            ? {
                ...filterValues,
                startPickupDate: filterValues.pickupDateRange[0],
                endPickupDate: filterValues.pickupDateRange[1]
              }
            : {
                ...filterValues
              }
        ),
        page,
        pageSize
      };

      const response = await request.get("/orderv2/all-orders", { params });

      setOrdersV2(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [filterValues]);

  useEffect(() => {
    getOrdersV2();
  }, [getOrdersV2]);

  const handleClickFilter = useCallback(() => {
    getOrdersV2();
  });

  const handleReset = useCallback(() => {
    setFilterValues({});
  });

  return (
    <>
      <FlexioPoolHeader
        breadCrumbs={breadCrumbs}
        showExport={false}
        setShowFilter={setShowFilter}
        hideBatchStatusUpdate={true}
      />
      {showFilter && (
        <FilterFlexioPool
          setShowFilter={setShowFilter}
          onFilterChange={handleFilterChange}
          defaultValues={filterValues}
          handleClickFilter={handleClickFilter}
          onReset={handleReset}
        />
      )}
      <div className="admin-content">
        <FlexioPoolOrderTable
          dataSource={ordersV2}
          refreshOrders={getOrdersV2}
          pagination={pagination}
          hiddenColumns={["Select"]}
          loading={loading}
        />
      </div>
    </>
  );
};
export default PoolOrders;
