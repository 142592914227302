import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function withFetchOneMore(WrappedComponent, { fetchDataApis = [], idSend = null }) {
  const WithFetch = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const id = idSend || params?.id;
    useEffect(() => {
      if (id) {
        fetchDataApis.forEach((fetchData) => {
          dispatch(fetchData(id));
        });
      }
    }, [dispatch, id]);
    return <WrappedComponent {...props} />;
  };
  return WithFetch;
}
export default withFetchOneMore;
