import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getServiceList = createAsyncThunk("service/get/loading", async (data) => {
  return settingApi.getServiceList(data);
});

export const doGetService = createAsyncThunk("service/getOne/loading", async (id) => {
  return settingApi.getService(id);
});

export const doCreateService = createAsyncThunk(
  "service/create/loading",
  async (data) => {
    return settingApi.createService(data);
  }
);

export const doUpdateService = createAsyncThunk(
  "service/create/loading",
  async (data) => {
    return settingApi.updateService(data);
  }
);

export const doSetDefaultService = createAsyncThunk(
  "service/set-is-default/loading",
  async (id) => {
    return settingApi.setDefaultService(id);
  }
);

export const doDeleteService = createAsyncThunk("service/delete/loading", async (id) => {
  return settingApi.deleteService(id);
});
