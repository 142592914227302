import axios from "axios";
import { TOKEN_NAME } from "configs/constants";
import Cookies from "universal-cookie";
import { buildFirstLevelFilterPoolExport } from "./util";

const baseURL = window.REACT_APP_API_ENDPOINT;

/**
 * @name | refreshPackagesV2
 * @param filterValues | Data to filter
 * @param setAxiosPackageList | Hook to set package data
 */
export const refreshPackagesV2 = async (
  filterValues,
  page,
  pageSize,
  setAxiosPackageList,
  updatePaginationPackages,
  dispatch
) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_NAME);
  const params = {
    filter: buildFirstLevelFilterPoolExport(
      filterValues?.pickupDateRange?.length > 0
        ? {
            ...filterValues,
            startPickupDate: filterValues.pickupDateRange[0],
            endPickupDate: filterValues.pickupDateRange[1]
          }
        : {
            ...filterValues
          }
    ),
    page,
    pageSize
  };
  const config = {
    responseType: "json",
    headers: { Authorization: `Bearer ${token}` },
    params
  };
  await getPackageV2List(
    config,
    params,
    setAxiosPackageList,
    updatePaginationPackages,
    dispatch
  );
};

const getPackageV2List = (
  config,
  params,
  setAxiosPackageList,
  updatePaginationPackages,
  dispatch
) => {
  axios
    .get(`${baseURL}/orderv2/all-packages`, config, { params })
    .then((response) => {
      dispatch(updatePaginationPackages(response.data.metadata));
      setAxiosPackageList(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
