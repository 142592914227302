import { Layout } from "antd";
import React from "react";
import { HeaderContent } from "./HeaderContent";
import DriverSiderContent from "layouts/components/LeftSider/DriverSiderContent";
import "./MainLayout.scss";

const { Header, Content } = Layout;

const DriverMainLayout = ({ children, isFullScreen = false }) => {
  return (
    <Layout className={`MainAdminLayout ${isFullScreen && "full-screen"}`}>
      <Header>
        <HeaderContent />
      </Header>
      <Content>
        <Layout>
          <DriverSiderContent />
          <div className="main-content-layout">{children}</div>
        </Layout>
      </Content>
    </Layout>
  );
};
export default DriverMainLayout;
