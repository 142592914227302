"use strict";
import { MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload
} from "antd";
import React, { useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { ReactSVG } from "react-svg";

import AddressInput from "components/inputs/AddressInput";
import request from "helper/request";
import DefaultMapbox from "../mapbox/DefaultMapbox";

import "./AddressForm.scss";

const { Dragger } = Upload;

const AddressFormGroup = ({
  onChangeTime,
  onSetAddress,
  onSetImagesAddressInfo,
  groupName,
  address = {},
  imagesAddressInfo
}) => {
  const [activeKey, setActiveKey] = useState(["1"]);

  const timePickerBlur = (time, column) => {
    onChangeTime(time, groupName, column);
  };

  const headerCollapse = (key) => {
    return (
      <div className="headerCollapse">
        <div className="text">Additional Details</div>
        {activeKey.includes(key) ? (
          <ReactSVG src="/icons/collapse.svg" className="icon" />
        ) : (
          <ReactSVG src="/icons/dropoff.svg" className="icon" />
        )}
      </div>
    );
  };

  const imageList = useMemo(
    () => imagesAddressInfo[groupName] || [],
    [groupName, imagesAddressInfo]
  );

  const onChangeFiles = async (values) => {
    if (values.file.status === "done") {
      let newImagesAddressInfo = { ...imagesAddressInfo };

      const files = values.fileList.map((file) => {
        return { ...file.response, uid: file.uid };
      });

      newImagesAddressInfo = {
        ...newImagesAddressInfo,
        [groupName]: files
      };
      onSetImagesAddressInfo(newImagesAddressInfo);
    }
  };

  const onRemoveFile = (file) => {
    const newImagesAddressInfo = { ...imagesAddressInfo };
    const newImageList = [...imageList];
    const index = newImageList.findIndex((image) => image.uid === file.uid);
    if (index !== -1) {
      newImageList.splice(index, 1);
      newImagesAddressInfo[groupName] = newImageList;
      onSetImagesAddressInfo(newImagesAddressInfo);
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".png, .jpeg, .jpg, .pdf",
    multiple: true,
    listType: "picture-card",
    className: "upload-list-inline",
    customRequest: async ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await request("/v1/files", {
          method: "POST",
          data: formData,
          headers: {
            "content-type": "multipart/form-data"
          }
        });
        onSuccess(response);
      } catch (e) {
        console.log(e);
        onError();
      }
    },
    onChange: onChangeFiles,
    onRemove: onRemoveFile,
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ReactSVG src="/icons/delete-upload.svg" />
    },
    defaultFileList: imageList,
    FileList: imageList
  };

  return (
    <div className="AddressForm" style={{ borderBottom: 0 }}>
      <div className="address">
        <div className="form">
          <Form.Item
            label="Map Address"
            name={[groupName, "address"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <AddressInput
              placeholder="Address"
              onSelect={(value) => onSetAddress(value)}
              address={address}
            />
          </Form.Item>
          <Form.Item
            label="Location Name"
            name={[groupName, "locationName"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Location Name" />
          </Form.Item>
          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div className="left">Physical Address</div>
                <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                  Optional
                </div>
              </div>
            }
            name={[groupName, "physicalAddress"]}
          >
            <Input placeholder="Physical Address" />
          </Form.Item>
        </div>
        <div className="map">
          <DefaultMapbox address={address} setAddress={onSetAddress} />
        </div>
      </div>
      <div className="info">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <div className="left">Contact Name</div>
                  <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                    Optional
                  </div>
                </div>
              }
              name={[groupName, "contactName"]}
            >
              <Input placeholder="Contact Name" />
            </Form.Item>
            <Form.Item
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <div className="left">E-mail</div>
                  <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                    Optional
                  </div>
                </div>
              }
              name={[groupName, "email"]}
              rules={[{ type: "email", message: "Invalid Email" }]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <div className="custom-label">
              <label>Phone Numbers</label>
            </div>
            <Form.List name={[groupName, "phoneList"]} label="Phone Numbers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKeyValue, ...restField }, index) => {
                    return (
                      <div key={name} className={`phone_numbers x${key}`}>
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKeyValue, "type"]}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select>
                                <Select.Option value="contact">Contact</Select.Option>
                                <Select.Option value="salesperson">
                                  Salesperson
                                </Select.Option>
                                <Select.Option value="mainSupport">
                                  Main Support
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Row>
                              <Col span={index > 0 ? 19 : 24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "phone"]}
                                  fieldKey={[fieldKeyValue, "phone"]}
                                  rules={[{ required: true, message: "Required" }]}
                                >
                                  <PhoneInput country="ca" enableSearch />
                                </Form.Item>
                              </Col>
                              {index > 0 && (
                                <Col span={5} className="icon">
                                  <ReactSVG
                                    src="/icons/minus.svg"
                                    onClick={() => remove(name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Form.Item className="bottom-btn">
                    <Button type="link" onClick={() => add({ type: "contact" })}>
                      Add More Phone Number
                      <ReactSVG src="/icons/plus.svg" className="react-icon" />
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col span={8}>
            <div className="custom-label">
              <label>
                {groupName === "dropoff" ? "Drop-Off Time Window" : "Pickup Time Window"}
              </label>
            </div>
            <Row className="from_to">
              <Col span={11}>
                <Form.Item
                  name={[groupName, "fromTime"]}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <TimePicker
                    format="HH:mm"
                    onSelect={(value) => timePickerBlur(value, "fromTime")}
                  />
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                <MinusOutlined />
              </Col>
              <Col span={11}>
                <Form.Item
                  name={[groupName, "toTime"]}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <TimePicker
                    format="HH:mm"
                    onSelect={(value) => timePickerBlur(value, "toTime")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Est. Waiting/Service Time"
              name={[groupName, "duration"]}
              rules={[{ required: true, message: "Required" }]}
              tooltip={{
                title:
                  "Please assign an estimated duration in minutes of time taken from when the driver arrives at the location, is attended, finishes the delivery and resumes driving. Here it is better to go with a worst case scenario to ensure the routes can be finished."
              }}
            >
              <Input
                type="number"
                placeholder="Est. Waiting/Service Time"
                suffix="Minutes"
              />
            </Form.Item>
            <Form.Item label="Note" name={[groupName, "note"]}>
              <Input.TextArea placeholder="Note" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <label>Image/s and Documents</label>
            <Dragger {...uploadProps}>
              <p className="ant-upload-hint">Click or drag file to to upload or</p>
              <p className="ant-upload-drag-icon">
                <img src="/icons/browse_upload.png" style={{ width: "83px" }} alt="" />
              </p>
            </Dragger>
          </Col>
        </Row>
      </div>
      <div className="additional-details">
        <Collapse
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          ghost
          collapsible="header"
        >
          <Collapse.Panel showArrow={false} header={headerCollapse("1")} key="1">
            <Row>
              <Col span="5">
                <Form.Item
                  label="Internal Route ID"
                  name={[groupName, "internalRouteId"]}
                >
                  <Input
                    placeholder="Internal Route ID"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item
                  label="Internal Order ID’s"
                  name={[groupName, "internalOrderId"]}
                >
                  <Input
                    placeholder="Internal Order ID’s"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item
                  label="Internal Customer ID"
                  name={[groupName, "internalCustomerId"]}
                >
                  <Input
                    placeholder="Internal Customer ID"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="5">
                <Form.Item label="Salesperson" name={[groupName, "salesPerson"]}>
                  <Input
                    placeholder="Salesperson"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="COD" name={[groupName, "cod"]}>
                  <Input
                    type="number"
                    placeholder="COD"
                    rules={[{ required: true, message: "Required" }]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default AddressFormGroup;
