import { notification, Button } from "antd";
import React, { useRef, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "./index.scss";
import request from "../../../../../helper/request";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";
import moment from "moment";
import { setGlobalLoading } from "app/global/slice";

const MAP_CSV_DATA = {
  "Contact Name": "contactName",
  Address: "address",
  Latitude: "addressLat",
  Longitude: "addressLng"
};

const ImportHistoricalJobs = ({ handleCloseModal, isAdmin = false, refreshRoutes }) => {
  const [allClients, setAllClients] = useState([]);
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const fetchClients = async () => {
    const tmpClients = await request.get("/admin/clients/all-clients");
    console.log(tmpClients);
    setAllClients(tmpClients);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const onImportExcel = async (tmpFile) => {
    //  gets the uploaded file object
    if (!isAdmin) return;
    dispatch(setGlobalLoading({ isLoading: true }));
    const { files } = tmpFile.target;

    //  the file is read through the filereader object
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });

        let rs = [];
        for (const sheet in workbook.Sheets) {
          // esline-disable-next-line
          if (workbook?.Sheets) {
            //  using sheet_to_json  method will be excel  into json  data
            rs = rs.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            let data = [];
            let error;
            for (let i = 0; i < rs.length; i++) {
              const item = rs[i];
              const keys = Object.keys(item);
              if ("Client Name".includes(keys)) {
                error = "Missing the client name field.";
                break;
              }

              let itemRs = {};
              keys.forEach((key) => {
                if (error) return;
                if (key === "Client Name") {
                  const clientId = allClients.find((c) => c.clientName === item[key])?.id;
                  if (!clientId) {
                    error = "The client does not exist.";
                    return;
                  }
                  itemRs = {
                    ...itemRs,
                    clientId
                  };
                } else if (key === "Phone Number") {
                  itemRs = {
                    ...itemRs,
                    phoneList: [
                      {
                        type: "contact",
                        phone: "+1" + item[key]
                      }
                    ]
                  };
                } else if (MAP_CSV_DATA[key]) {
                  itemRs = {
                    ...itemRs,
                    [MAP_CSV_DATA[key]]: ["Latitude", "Longitude"].includes(key)
                      ? Number(item[key])
                      : item[key]
                  };
                }
              });
              data.push(itemRs);
            }
            if (error) {
              console.log(error);
              notification.error({
                message: error,
                placement: "bottomRight"
              });
            } else {
              console.log("runxxx ", data);
              await request.post("/orders/historical", {
                data,
                pickupDate: moment().format()
              });
              handleCloseModal && handleCloseModal();
              dispatch(setSuccessMessage("Done"));
              refreshRoutes && refreshRoutes();
            }
          }
        }
      } catch (e) {
        console.log("e", e);
        const error = JSON.parse(e.message);
        notification.error({
          message: error.message,
          placement: "bottomRight"
        });
      }
    };

    //  open the file in binary mode
    await fileReader.readAsBinaryString(files[0]);
    dispatch(setGlobalLoading({ isLoading: false }));
  };

  const handleOnChangeImport = (e) => {
    onImportExcel(e);
  };

  const clickChooseFile = (e) => {
    fileInputRef.current.click();
  };
  return (
    <div className="import-locations-component m-3 pb-4">
      <input
        ref={fileInputRef}
        type={"file"}
        id={"csvFileInput"}
        accept={".xlsx, .xls"}
        onChange={handleOnChangeImport}
        className="hidden file-import"
      />
      <div className="">
        <div className="d-flex flex-row gap-2 ant-space-align-center">
          <Button
            className="btn-download svg-icon mb-3"
            size="small"
            onClick={clickChooseFile}
          >
            Choose file
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ImportHistoricalJobs;
