import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { VEHICLE_RATE_TYPES } from "configs/constants";
import { LOWER_CASE_SERVICE_NAME } from "../constants";
import { isInternalDriverService } from "../helper";
import { getFlexioServiceList } from "./thunks";

const flexioServicesAdapter = createEntityAdapter();

const initialState = flexioServicesAdapter.getInitialState({
  metadata: {}
});

const handleMapServiceData = (services) => {
  return services.map((service) => {
    // Each vehicle catagory includes 2 vehicle rates
    const vehicleCategories = service.vehicleRates
      ?.filter((item) => item.type === VEHICLE_RATE_TYPES.CLIENT_RATE)
      ?.map((filteredItem) => filteredItem.vehicleCategory);
    const maxCapacityVehicle = vehicleCategories?.reduce(function (pre, current) {
      return pre?.maxLength * pre?.maxVolume * pre?.maxWeight >
        current?.maxLength * current?.maxVolume * current?.maxWeight
        ? pre
        : current;
    }, []);

    const packageAccepted = service.packageTypes?.map((packageType) => packageType.name);

    return {
      ...service,
      vehicleCategories,
      maxCapacityVehicle,
      packageAccepted: packageAccepted?.join(", "),
      viewPickupAndDelivery: true,
      isInternalDriver: isInternalDriverService(service?.name)
    };
  });
};
export const flexioServicesSlice = createSlice({
  name: "flexioServices",
  initialState,
  reducers: {
    setAllRoutePlans: flexioServicesAdapter.setAll
  },
  extraReducers: (builder) => {
    builder.addCase(getFlexioServiceList.fulfilled, (state, { payload }) => {
      flexioServicesAdapter.setAll(state, handleMapServiceData(payload));
    });
  }
});

export const {
  selectById: selectFlexioServiceById,
  selectIds: selectFlexioServiceIds,
  selectEntities: selectFlexioServiceEntities,
  selectAll: selectAllFlexioServices,
  selectTotal: selectTotalFlexioServices
} = flexioServicesAdapter.getSelectors((state) => state.flexioServices);

export const selectMetadata = (state) => state.flexioServices.metadata;

export default flexioServicesSlice.reducer;
