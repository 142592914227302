import axios from "axios";
import { TOKEN_NAME } from "configs/constants";
import Cookies from "universal-cookie";
import { groupBy, orderBy, get } from "lodash";
import { notification } from "antd";

const FileSaver = require("file-saver");

export const doExportCsv = async ({ path, name, params }) => {
  const cookies = new Cookies();
  const tmpToken = cookies.get(TOKEN_NAME);
  const config = {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${tmpToken}`
    },
    params
  };
  const baseURL = window.REACT_APP_API_ENDPOINT;
  axios
    .get(`${baseURL}/${path}`, config, { params })
    .then((response) => {
      FileSaver.saveAs(response.data, `${name}.csv`);
    })
    .catch((err) => {
      const { status, statusText } = err?.response || {};
      notification.error({ message: `${status} - ${statusText}` });
    });
};

export const handleExportClientData = (order) => {
  if (order) {
    const headers = [
      { label: "Route ID", key: "routeId" },
      { label: "Client Name", key: "clientName" },
      { label: "Client ID", key: "clientId" },
      { label: "Stop ID", key: "stopId" },
      { label: "Stop Type", key: "stopType" },
      { label: "Driver Name", key: "driverName" },
      { label: "Driver ID", key: "driverId" },
      { label: "Vehicle Type", key: "vehicleType" },
      { label: "Service", key: "service" },
      { label: "Contact Name", key: "contactName" },
      { label: "Contact Phone", key: "contactPhone" },
      { label: "Contact Email", key: "contactEmail" },
      { label: "Notes", key: "notes" },
      { label: "Stop Name", key: "stopName" },
      { label: "Stop Address", key: "stopAddress" },
      { label: "Stop Latitude", key: "stopLatitude" },
      { label: "Stop Longitude", key: "stopLongitude" },
      { label: "Stop Time Window - Start", key: "startTime" },
      { label: "Stop Time Window - End", key: "endTime" },
      { label: "Waiting/Service Time", key: "waitingServiceTime" },
      { label: "Internal Route ID", key: "internalRouteId" },
      { label: "Internal Order ID", key: "internalOrderId" },
      { label: "Internal Customer ID", key: "internalCustomerId" },
      { label: "Salesperson", key: "salesperson" },
      { label: "COD", key: "cod" },
      { label: "Total Micro Packages", key: "totalMicroPackages" },
      { label: "Total Small Packages", key: "totalSmallPackages" },
      { label: "Total Medium Packages", key: "totalMediumPackages" },
      { label: "Total Large Packages", key: "totalLargePackages" },
      { label: "Total Other Packages", key: "totalOtherPackages" },
      { label: "Status", key: "status" },
      { label: "Status Date/Time", key: "statusDateTime" },
      { label: "Status Latitude", key: "statusLat" },
      { label: "Status Longitude", key: "statusLng" },
      { label: "Estimate", key: "estimate" },
      { label: "Est. Arrival Time", key: "estimateArrivalTime" },
      { label: "Est. Finish Time", key: "estimateFinishTime" },
      { label: "Est. Total Time", key: "estimateTotalTime" },
      { label: "Est. Total Est. Distance", key: "estimateTotalDistance" },
      { label: "Est. Total Est. Return Distance", key: "estimateReturnDistance" },
      { label: "Est. Total Return Time", key: "estimateReturnTime" },
      { label: "Final Total Time", key: "finalTotalTime" },
      { label: "Final Price", key: "finalPrice" }
    ];

    const rows = [];

    Object.keys(order.jobs).forEach((jobId) => {
      const job = order?.jobs[jobId];
      if (job) {
        const route = order?.routes && order?.routes[job.routeId];
        const microPack = job.packages.find((pack) => pack.name === "Micro");
        const smallPack = job.packages.find((pack) => pack.name === "Small");
        const mediumPack = job.packages.find((pack) => pack.name === "Medium");
        const largePack = job.packages.find((pack) => pack.name === "Large");
        const otherPack = job.packages.find((pack) => pack.name === "Other");
        if (route) {
          ["pickup", "dropoff"].forEach((item) => {
            rows.push({
              routeId: job.routeId,
              clientName: "",
              clientId: "",
              stopId: job.pickup.id,
              stopType: item,
              driverName: "",
              driverId: "",
              vehicleType: route.vehicleCategory?.name,
              service: order.dataService?.service?.name,
              contactName: job[item].contactName,
              contactPhone: contactPhone(job[item].phoneList),
              contactEmail: job[item].email,
              notes: job[item].note,
              stopName: job[item].locationName,
              stopAddress: job[item].address,
              stopLatitude: job[item].lat,
              stopLongitude: job[item].lng,
              startTime: job[item].start,
              endTime: job[item].end,
              waitingServiceTime: job[item].waitingMins,
              internalRouteId: job[item].internalRouteId,
              internalOrderId: job[item].internalOrderId,
              internalCustomerId: job[item].internalCustomerId,
              salesperson: job[item].salesperson,
              cod: job[item].cod,
              totalMicroPackages: microPack?.quantity || "N/A",
              totalSmallPackages: smallPack?.quantity || "N/A",
              totalMediumPackages: mediumPack?.quantity || "N/A",
              totalLargePackages: largePack?.quantity || "N/A",
              totalOtherPackages: otherPack?.quantity || "N/A",
              status: "unassigned",
              statusDateTime: "N/A",
              statusLat: "N/A",
              statusLng: "N/A",
              estimate: "N/A",
              estimateArrivalTime: job[item].arrivalTime,
              estimateFinishTime: job[item].finishTime,
              estimateTotalTime: job[item].workingMins,
              estimateTotalDistance: job[item].distance,
              estimateReturnDistance: "N/A",
              estimateReturnTime: "N/A",
              finalTotalTime: "N/A",
              finalPrice: "N/A"
            });
          });
        }
      }
    });
    const groupType = groupBy(rows, "stopType");
    const groupPickupAddress = groupBy(groupType.pickup, "stopAddress");
    const pickups = [];
    Object.keys(groupPickupAddress).map((address) => {
      pickups.push(groupPickupAddress[address][0]);
    });
    return [
      headers,
      orderBy(
        [...pickups, ...get(groupType, "dropoff", [])],
        ["estimateArrivalTime"],
        ["asc"]
      )
    ];
  }
};

export const contactPhone = (contacts) => {
  if (contacts.length > 0) {
    const arr = [];
    contacts.forEach((contact) => arr.push(`${contact.type}: ${contact.phone}`));
    return arr.join("-");
  } else {
    return "N/A";
  }
};
