import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreateTerritory,
  doDeleteTerritory,
  doGetTerritory,
  getTerritoryList
} from "./TerritoryThunks";

const TerritoryAdapter = createEntityAdapter({
  selectId: (territory) => territory.id
});

const initialState = TerritoryAdapter.getInitialState({
  metadata: {},
  territory: {}
});

export const territorySlice = createSlice({
  name: "territories",
  initialState,
  reducers: {
    updatePaginationTerritories(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTerritoryList.fulfilled, (state, { payload }) => {
      TerritoryAdapter.setAll(state, payload.data);
      state.territory = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetTerritory.fulfilled, (state, { payload }) => {
      state.territory = payload;
    });
    builder.addCase(doCreateTerritory.fulfilled, (state, { payload }) => {
      TerritoryAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDeleteTerritory.fulfilled, (state, { payload }) => {
      TerritoryAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectTerritoryById,
  selectIds: selectTerritoryIds,
  selectEntities: selectTerritoryEntities,
  selectAll: selectAllTerritories,
  selectTotal: selectTotalTerritories
} = TerritoryAdapter.getSelectors((state) => state.territories);

export const selectMetadata = (state) => state.territories.metadata;
export const selectTerritory = (state) => state.territories.territory;

export const { updatePaginationTerritories } = territorySlice.actions;

export default territorySlice.reducer;
