import { GeoJSONLayer } from "react-mapbox-gl";
import React from "react";
import { COLORS } from "../../../../configs/constants";
import { isArrayEmpty } from "../store/utils";

export default function PolylineOnMap({ coords, color }) {
  if (isArrayEmpty(coords)) return <></>;
  return (
    <GeoJSONLayer
      data={{
        type: "Feature",
        properties: { color: color || COLORS.primaryAdmin },
        geometry: {
          type: "LineString",
          coordinates: coords
        }
      }}
      linePaint={{
        "line-color": ["get", "color"],
        "line-width": 3,
        "line-opacity": 0.8
      }}
    />
  );
}
