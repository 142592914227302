import { Steps } from "antd";
import React from "react";
import { filter } from "lodash";
const { Step } = Steps;
const JobStep = ({ job, routeLocations = [] }) => {
  const jobRouteLocations = filter(routeLocations, function (location) {
    return location.jobId == job.id;
  });
  const pickup = jobRouteLocations.find(
    (routeLocation) => routeLocation.location.type === "pickup"
  );
  const dropoff = jobRouteLocations.find(
    (routeLocation) => routeLocation.location.type === "dropoff"
  );
  return (
    <Steps progressDot current={job.id + "1"} direction="vertical" className="step-job">
      <Step
        key={pickup?.id}
        status={pickup?.id}
        description={
          <div>
            <div>
              {!pickup && dropoff?.isReturnPickup && <b>Return Pickup Location</b>}
              {!pickup && dropoff?.isReturning && <b>Return Location</b>}
              {pickup?.location?.locationName && <b>{pickup?.location?.locationName}</b>}
              {pickup?.location?.contactName && <b>- {pickup?.location?.contactName}</b>}
              {pickup?.location?.address && <p>- {pickup?.location?.address}</p>}
            </div>
          </div>
        }
      />

      <Step
        key={dropoff?.id}
        status={dropoff?.id}
        description={
          <div>
            <div>
              {dropoff?.location?.locationName && (
                <b>{dropoff?.location?.locationName}</b>
              )}
              {dropoff?.location?.contactName && (
                <b>- {dropoff?.location?.contactName}</b>
              )}
              <p>- {dropoff?.location?.address}</p>
            </div>
          </div>
        }
      />
    </Steps>
  );
};
export default JobStep;
