import React from "react";
import { Progress } from "antd";

const WaitingModal = ({ percent }) => {
  return (
    <div className="d-flex flex-column flex-items-center py-4">
      <Progress
        type="circle"
        percent={percent}
        width={50}
        trailColor="#E1F5F5"
        strokeColor="#43c1c2"
        strokeWidth="15"
      />
      <div className="text-center pt-3">
        Please wait while we import your stops and validate your locations.
      </div>
    </div>
  );
};
export default WaitingModal;
