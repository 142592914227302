import React, { useState, createContext, useEffect, useCallback, useMemo } from "react";
import { setError } from "app/global/slice";
import { useDispatch } from "react-redux";
import request from "helper/request";

export const DriverProfileContext = createContext();

export const DriverProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await request.get("/drivers/profile");
      setProfile({
        ...response,
        isAdmin: false
      });
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <DriverProfileContext.Provider
      value={{
        profile,
        fetchProfile,
        setProfile,
        isLoading
      }}
    >
      {children}
    </DriverProfileContext.Provider>
  );
};
