import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Modal, notification } from "antd";
import moment from "moment";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

import { selectLocation } from "modules/admin/orders/store/LocationSlice";
import { getLocation } from "modules/admin/orders/store/LocationThunks";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import { withPermission } from "hocs/withPermission";
import { UserRole, LOCATION_TYPES, TIME_FORMAT_BACKEND } from "configs/constants";

import OrdersHeader from "../components/header/OrdersHeader";
import AddressFormGroup from "../components/form/AddressFormGroup";
import PackageList from "../components/PackageList";
import PackageListDrop from "../components/PackageListDrop";
import PackForm from "../components/form/PackForm";
import orderApi from "../store/api";

import "./CreateOrderPage.scss";

const { Panel } = Collapse;

const pickupInit = {
  fromTime: moment("00:00", "HH:mm"),
  toTime: moment("23:59", "HH:mm"),
  duration: 10,
  phoneNumbers: [{ type: "contact", phone: "" }]
};

const dropoffInit = {
  fromTime: moment("00:00", "HH:mm"),
  toTime: moment("23:59", "HH:mm"),
  duration: 10,
  phoneNumbers: [{ type: "contact", phone: "" }]
};

const AddJobPage = ({ isAdmin }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const routeId = params.id;
  const routesUri = isAdmin ? "/admin/routes" : "/routes";
  const breadCrumbs = [
    { text: "ACTIVE ROUTES", url: routesUri },
    { text: routeId?.substr(0, 8).toUpperCase(), url: `${routesUri}/${params.id}` },
    { text: "ADD STOPS" }
  ];
  const pickupLocation = useSelector(selectLocation);
  const packageTypeList = useSelector(selectAllPackageTypes);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [form] = Form.useForm();
  const [activeKeyPickup, setActiveKeyPickup] = useState([LOCATION_TYPES.PICKUP]);
  const [activeKeyDropoff, setActiveKeyDropoff] = useState([LOCATION_TYPES.DROPOFF]);
  const [addressInfo, setAddressInfo] = useState({ pickup: {}, dropoff: {} });
  const [imagesAddressInfo, setImagesAddressInfo] = useState({ pickup: [], dropoff: [] });
  const [visible, setVisible] = useState(false);
  const [formPack] = Form.useForm();
  const [packages, setPackages] = useState([]);
  const [selectedPack, setSelectedPack] = useState(null);

  useEffect(() => {
    const pickupLocationId = params.eid;
    if (_isEmpty(pickupLocationId)) return;
    dispatch(getLocation(pickupLocationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.eid]);

  useEffect(() => {
    if (_isEmpty(pickupLocation)) return;
    if (pickupLocation.attachments) {
      const attachments = pickupLocation.attachments.map((file) => {
        return { ...file, uid: file.id, status: "done" };
      });
      setImagesAddressInfo({ ...imagesAddressInfo, pickup: attachments });
    }
    setAddressInfo({
      pickup: {
        address: pickupLocation.address,
        lngLat: [pickupLocation.lng, pickupLocation.lat]
      }
    });
    const { start, end } = pickupLocation;
    const fromTime = start ? moment(start, TIME_FORMAT_BACKEND) : null;
    const toTime = end ? moment(end, TIME_FORMAT_BACKEND) : null;
    form.setFieldsValue({
      pickup: {
        ...pickupLocation,
        fromTime,
        toTime
      },
      dropoff: { fromTime, toTime }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupLocation]);

  useEffect(() => {
    dispatch(getPackageTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandIconDynamic = (props) => {
    if (props.isActive) {
      return <ReactSVG src="/icons/collapse.svg" />;
    } else {
      return <ReactSVG src="/icons/dropoff.svg" />;
    }
  };

  const handleChangePickupAddress = (value) => {
    const pickupValues = { ...form.getFieldValue(LOCATION_TYPES.PICKUP) };
    pickupValues.address = value.address;
    form.setFieldsValue({
      pickup: pickupValues
    });
    setAddressInfo({ ...addressInfo, pickup: value });
  };

  const handleChangeDropoffAddress = (value) => {
    const dropoffValues = { ...form.getFieldValue(LOCATION_TYPES.DROPOFF) };
    dropoffValues.address = value.address;
    form.setFieldsValue({
      dropoff: dropoffValues
    });
    setAddressInfo({ ...addressInfo, dropoff: value });
  };

  const handleChangeTime = (value, groupName, fieldName) => {
    const stopValues = { ...form.getFieldValue(groupName) };
    stopValues[fieldName] = value;
    const stopObj = {};
    stopObj[groupName] = stopValues;
    form.setFieldsValue(stopObj);
  };

  const handleCancel = () => {
    form.resetFields();
    history.goBack();
  };

  const openPackCreateUpdate = (pack = null) => {
    setVisible(true);
    setSelectedPack(pack);
  };

  const handleSavePack = (values) => {
    const newPackages = [...packages];
    if (!values.id) {
      newPackages.push({ ...values, id: `${new Date().getTime()}-pack` });
    } else {
      const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
      delete values.id;
      const newPack = {
        ...newPackages[indexpack],
        ...values
      };
      newPackages.splice(indexpack, 1, newPack);
    }
    setPackages(newPackages);
    handleCancelModal();
  };

  const removePack = (values) => {
    const newPackages = [...packages];
    const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
    newPackages.splice(indexpack, 1);
    setPackages(newPackages);
  };

  const handleCancelModal = () => {
    formPack.resetFields();
    setSelectedPack(null);
    setVisible(false);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (!packages?.length) {
        notification.error({
          message: "Please select at least 1 package",
          placement: "topRight"
        });
        return;
      }
      const { pickup: pickupValues, dropoff: dropoffValues } = form.getFieldsValue();
      const { fromTime: fromPickupTime, toTime: toPickupTime } = pickupValues;
      const { fromTime: fromDropoffTime, toTime: toDropoffTime } = dropoffValues;
      const payload = {
        pickup: {
          ...pickupValues,
          lng: addressInfo[LOCATION_TYPES.PICKUP]?.lngLat[0],
          lat: addressInfo[LOCATION_TYPES.PICKUP]?.lngLat[1],
          start: fromPickupTime
            ? typeof fromPickupTime === "object"
              ? fromPickupTime.format(TIME_FORMAT_BACKEND)
              : moment(fromPickupTime).format(TIME_FORMAT_BACKEND)
            : undefined,
          end: toPickupTime
            ? typeof toPickupTime === "object"
              ? toPickupTime.format(TIME_FORMAT_BACKEND)
              : moment(toPickupTime).format(TIME_FORMAT_BACKEND)
            : undefined,
          files: imagesAddressInfo[LOCATION_TYPES.PICKUP]?.map((image) => image.id)
        },
        dropoff: {
          ...dropoffValues,
          lng: addressInfo[LOCATION_TYPES.DROPOFF].lngLat[0],
          lat: addressInfo[LOCATION_TYPES.DROPOFF]?.lngLat[1],
          start: fromDropoffTime
            ? typeof fromDropoffTime === "object"
              ? fromDropoffTime.format(TIME_FORMAT_BACKEND)
              : moment(fromDropoffTime).format(TIME_FORMAT_BACKEND)
            : undefined,
          end: toDropoffTime
            ? typeof toDropoffTime === "object"
              ? toDropoffTime.format(TIME_FORMAT_BACKEND)
              : moment(toDropoffTime).format(TIME_FORMAT_BACKEND)
            : undefined,
          files: imagesAddressInfo[LOCATION_TYPES.DROPOFF]?.map((image) => image.id)
        },
        routeId,
        packages
      };
      setIsSubmiting(true);
      const addedJob = await orderApi.addJobToRoute(payload);
      !!addedJob && history.goBack();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <div className="CreateOrderPage">
      {!isAdmin && <OrdersHeader breadCrumbs={breadCrumbs} hasButton={false} />}
      <div className="wrapper">
        <h2 className="title">Add Stops</h2>
        <div className="contentForm ml-0">
          <Form
            scrollToFirstError={true}
            layout="vertical"
            form={form}
            hideRequiredMark
            colon={false}
            initialValues={{ pickup: pickupInit, dropoff: dropoffInit }}
          >
            <div className="pickups block">
              <Collapse
                activeKey={activeKeyPickup}
                onChange={(key) => setActiveKeyPickup(key)}
                ghost
                expandIconPosition="end"
                expandIcon={(props) => expandIconDynamic(props)}
              >
                <Panel header="Pickup Location" key={LOCATION_TYPES.PICKUP}>
                  <AddressFormGroup
                    onSetAddress={handleChangePickupAddress}
                    address={addressInfo.pickup}
                    groupName={LOCATION_TYPES.PICKUP}
                    onChangeTime={handleChangeTime}
                    imagesAddressInfo={imagesAddressInfo}
                    onSetImagesAddressInfo={setImagesAddressInfo}
                  />
                  <PackageList
                    packages={packages}
                    editPack={(pack) => openPackCreateUpdate(pack)}
                    addPack={() => openPackCreateUpdate()}
                    removePack={removePack}
                  />
                </Panel>
              </Collapse>
            </div>
            <div className="dropoffs block">
              <Collapse
                activeKey={activeKeyDropoff}
                onChange={(key) => setActiveKeyDropoff(key)}
                ghost
                expandIconPosition="end"
                expandIcon={(props) => expandIconDynamic(props)}
              >
                <Panel header="Dropoff Location" key={LOCATION_TYPES.DROPOFF}>
                  <AddressFormGroup
                    onSetAddress={handleChangeDropoffAddress}
                    address={addressInfo.dropoff}
                    groupName={LOCATION_TYPES.DROPOFF}
                    onChangeTime={handleChangeTime}
                    imagesAddressInfo={imagesAddressInfo}
                    onSetImagesAddressInfo={setImagesAddressInfo}
                  />
                  <PackageListDrop packages={packages} isAll={true} />
                </Panel>
              </Collapse>
            </div>
          </Form>
        </div>
      </div>
      <div className="footer">
        <Button size="large" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          className="create-order"
          loading={isSubmiting}
          onClick={handleSubmit}
        >
          Add
          <CheckCircleOutlined />
        </Button>
      </div>
      {visible && (
        <Modal
          title={!selectedPack ? <div>Add Package</div> : <div>Edit Package</div>}
          visible={true}
          onCancel={handleCancelModal}
          footer={
            <div className="buttons-modal">
              <Form.Item>
                <Button size="large" onClick={handleCancelModal}>
                  Cancel
                </Button>
                <Button size="large" type="primary" onClick={() => formPack.submit()}>
                  Confirm
                  <CheckCircleOutlined />
                </Button>
              </Form.Item>
            </div>
          }
          width={792}
        >
          <PackForm
            pack={selectedPack}
            formPack={formPack}
            onOk={handleSavePack}
            packageTypeList={packageTypeList}
          />
        </Modal>
      )}
    </div>
  );
};

export default withPermission(AddJobPage, [UserRole.ADMIN, UserRole.MANAGER]);
