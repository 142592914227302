import { Button, Tooltip, Modal, InputNumber, Popconfirm, Spin } from "antd";
import { setError } from "app/global/slice";
import request from "helper/request";
import { formatToUSD } from "helper/util";
import { ProfileContext } from "layouts/private/ProfileContext";
import moment from "moment";
import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import SubscriptionCTA from "../components/SubscriptionCTA";
import { createSubscriptionSession } from "helper/util";

function SubscriptionsPage() {
  const profileCtx = useContext(ProfileContext);
  const fetchProfile = useMemo(() => profileCtx?.fetchProfile, [profileCtx]);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const isProfileLoading = useMemo(() => profileCtx?.isLoading, [profileCtx]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [driverQty, setDriverQty] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(null);
  const [amountToBeCalculated, setAmountToBeCalculated] = useState(null);
  const [proratedCharge, setProratedCharge] = useState(null);
  const [isSeatsAvailable, setIsSeatsAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [subscriptionContent, setSubscriptionContent] = useState(null);
  const dispatch = useDispatch();
  const MINIMUM_QTY = 2;
  const MAX_QTY = 999999;

  useEffect(() => {
    const qty = profile?.subscription?.totalSeats;
    const amountToDecimal = profile?.subscription?.currentAmount;
    const amountCalculated = parseFloat(amountToDecimal * qty);

    setDriverQty(qty);
    setSubscriptionAmount(amountCalculated);
    setAmountToBeCalculated(amountCalculated);
  }, [profile]);

  useEffect(() => {
    if (profile?.subscription) return;

    getSubscriptionContent();
  }, []);

  const getSubscriptionContent = useCallback(async () => {
    try {
      setIsLoading(true);
      const subscriptionData = await request.get(
        `${window.REACT_APP_API_ENDPOINT}/subscriptions/subscription-tier`
      );

      setSubscriptionContent(subscriptionData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await request.post(
        `${window.REACT_APP_API_ENDPOINT}/subscriptions/update-subscription`,
        {
          subscriptionItemId: profile?.subscription?.itemId,
          quantity: driverQty
        }
      );

      if (!response.id) return;

      fetchProfile();
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      const err = JSON.parse(error?.message || "{}");
      dispatch(setError(err));
    }
  });

  const cancelSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await request.post(
        `${window.REACT_APP_API_ENDPOINT}/subscriptions/cancel-subscription`
      );

      if (!response.id) return;
      fetchProfile();
    } catch (e) {
      console.log(e); // cambiar
    }
    setIsLoading(false);
    resetState();
  }, []);

  const resumeSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await request.post(
        `${window.REACT_APP_API_ENDPOINT}/subscriptions/resume-subscription`
      );

      if (!response.id) return;
      fetchProfile();
    } catch (e) {
      console.log(e); // cambiar
    }
    setIsLoading(false);
  }, []);

  const isModalOpen = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const calculateAmount = useCallback((updatedQty) => {
    return parseFloat(updatedQty * profile?.subscription?.currentAmount);
  });

  const handleDriverQty = (value) => {
    const qty = value;
    if (isDriverCurrentSeatsAvailable(qty, profile?.subscription?.internalDriversCount)) {
      setIsSeatsAvailable(false);
      setDriverQty(profile?.subscription?.internalDriversCount || MINIMUM_QTY);
      return;
    }
    setIsSeatsAvailable(true);
    setAmountToBeCalculated(calculateAmount(qty));
    setDriverQty(qty);

    const prorate =
      ((qty - profile?.subscription?.totalSeats) * profile?.subscription?.currentAmount) /
      100;
    setProratedCharge(prorate);
  };

  const resetState = () => {
    setShowUpdateModal(false);
    setShowCancelModal(false);
    setDriverQty(profile?.subscription?.totalSeats);
    setProratedCharge(0);
    setAmountToBeCalculated(subscriptionAmount);
  };

  const isDriverCurrentSeatsAvailable = useCallback((currQty, internalDriversQty) => {
    return currQty < internalDriversQty;
  });

  const createSession = useCallback((priceId) => {
    createSubscriptionSession(priceId);
  }, []);

  return (
    <>
      <h2 style={pageTitleStyle}>Subscriptions</h2>
      {!isProfileLoading && profile?.subscription ? (
        <>
          {/* START UPDATE MODAL */}
          <Modal
            open={showUpdateModal}
            closable={true}
            onCancel={resetState}
            footer={[
              <Button key="cancel" onClick={resetState}>
                Cancel
              </Button>,
              <Popconfirm
                key="save"
                title="Are you sure you want to update this subscription?"
                onConfirm={updateSubscription}
                okText="Yes"
                cancelText="No"
                disabled={isDriverCurrentSeatsAvailable(
                  driverQty,
                  profile?.subscription?.internalDriversCount
                )}
              >
                <Button
                  type="primary"
                  className="m-1"
                  disabled={isDriverCurrentSeatsAvailable(
                    driverQty,
                    profile?.subscription?.internalDriversCount
                  )}
                >
                  Save
                </Button>
              </Popconfirm>
            ]}
          >
            <h3>Edit Subscription</h3>
            <InputNumber
              defaultValue={profile?.subscription?.internalDriversCount || MINIMUM_QTY}
              value={driverQty}
              min={profile?.subscription?.internalDriversCount || MINIMUM_QTY}
              max={MAX_QTY}
              minLength={1}
              maxLength={6}
              onChange={handleDriverQty}
              addonBefore="Drivers Qty"
              size="small"
            />
            <div>
              <small style={{ color: "#b61207" }}>
                {!profile?.subscription?.internalDriversCount ||
                  (!isSeatsAvailable &&
                    `You have ${profile?.subscription?.internalDriversCount} internal drivers.
      You cannot choose seats below that quantity.`)}
              </small>
            </div>
            <div className="mt-3 d-flex" style={{ justifyContent: "space-between" }}>
              <div className="m-1" style={{ color: "#555" }}>
                <span style={{ fontWeight: 600 }}>
                  Bill Next {profile?.subscription?.interval}
                </span>
                : {formatToUSD(amountToBeCalculated)} / {profile?.subscription?.interval}
                <div>
                  <small>${profile?.subscription?.currentAmount} each</small>
                </div>
              </div>
              <p style={{ color: "#555" }}>
                <span style={{ fontWeight: 600 }}>Next Bill Cycle</span>:{" "}
                {moment(profile?.subscription?.periodEndUnixTimeStamp * 1000).format(
                  "LL"
                )}
              </p>
            </div>
            <div className="m-1" style={{ color: "#555" }}>
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                {false && (
                  <div>
                    <span style={{ fontWeight: 600 }}>
                      Prorated {proratedCharge < 0 ? "Credit" : "Charge"}
                    </span>
                    :
                    {formatToUSD(
                      (driverQty - profile?.subscription?.totalSeats) *
                        profile?.subscription?.currentAmount
                    )}
                    <div>
                      <small>
                        Qty {profile?.subscription?.totalSeats} → {driverQty}
                      </small>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="d-flex mt-4"
                style={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <h4 className="pr-2">
                  Amount due{" "}
                  {moment(profile?.subscription?.periodEndUnixTimeStamp * 1000).format(
                    "LL"
                  )}{" "}
                </h4>
                <h3>{formatToUSD(amountToBeCalculated)}</h3>
              </div>
            </div>
          </Modal>
          {/* END UPDATE MODAL */}
          {/* START CANCEL MODAL */}
          <Modal
            open={showCancelModal}
            closable={true}
            onCancel={resetState}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              style: { background: "#fff", color: "#333" }
            }}
            cancelButtonProps={{
              style: { background: "#1F3998", color: "#fff" }
            }}
            onOk={cancelSubscription}
            title="Cancel Subscription"
          >
            <Spin spinning={isLoading}>
              <div>
                This subscription will be cancelled on{" "}
                <strong>
                  {moment(profile?.subscription?.periodEndUnixTimeStamp * 1000).format(
                    "LL"
                  )}
                  .
                </strong>
              </div>
              <div className="mt-3">
                <strong>Are you sure you want to cancel?</strong>
              </div>
            </Spin>
          </Modal>
          {/* END CANCEL MODAL */}

          <Spin spinning={isLoading}>
            <div style={subscriptionContainerStyle}>
              <div>
                <h3>Name</h3>
                <p>{profile?.subscription?.name}</p>
              </div>
              <div>
                <h3>Status</h3>
                <p
                  style={{
                    color: profile?.subscription?.status === "active" ? "#43C1C2" : "#000"
                  }}
                >
                  {profile?.subscription?.status}
                </p>
              </div>
              <div>
                <h3>Drivers</h3>
                <p>{profile?.subscription?.totalSeats}</p>
              </div>
              <Tooltip
                title={
                  profile?.subscription?.cancelPeriodEnd
                    ? "This subscription will be cancelled in the next billing cycle"
                    : ""
                }
              >
                <div>
                  <h3>Next Billing Cycle</h3>
                  <p>
                    {moment(profile?.subscription?.periodEndUnixTimeStamp * 1000).format(
                      "LL"
                    )}
                  </p>
                </div>
              </Tooltip>
              <div>
                <h3>Amount</h3>
                <p>
                  {formatToUSD(subscriptionAmount)} / {profile?.subscription?.interval}
                </p>
              </div>
              <div>
                <h3>Action</h3>
                {!profile?.subscription?.cancelPeriodEnd && (
                  <div
                    className="d-flex"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <a href="#" onClick={isModalOpen} style={{ padding: "0 0.5em" }}>
                      Edit
                    </a>
                    <a
                      href="#"
                      onClick={() => setShowCancelModal(!showCancelModal)}
                      style={{ padding: "0 0.5em" }}
                    >
                      Cancel
                    </a>
                  </div>
                )}
                {profile?.subscription?.cancelPeriodEnd && (
                  <div
                    className="d-flex"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <a
                      href="#"
                      onClick={resumeSubscription}
                      style={{ padding: "0 0.5em" }}
                    >
                      Resume
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Spin>
          {profile?.subscription?.cancelPeriodEnd && (
            <p className="p-3" style={cancelMessageStyle}>
              * This subscription going to be cancelled in the next billing cycle
            </p>
          )}
        </>
      ) : (
        <>
          {subscriptionContent && (
            <SubscriptionCTA content={subscriptionContent} onSubmit={createSession} />
          )}
        </>
      )}
    </>
  );
}

const subscriptionContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  padding: "1em 2em"
};

const pageTitleStyle = {
  padding: "1em"
};

const cancelMessageStyle = {
  color: "#ab140d",
  fontWeight: "800"
};

export default SubscriptionsPage;
