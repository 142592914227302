import { Modal } from "antd";
import { UserRole } from "configs/constants";
import { allowPermission } from "helper/util";
import { buildFirstLevelFilter } from "modules/admin/clients/helper";
import { selectRole } from "modules/auth/store/authSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import ClientUserForm from "../components/form/ClientUserForm";
import ClientUserHeader from "../components/header/ClientUserHeader";
import FilterClientUser from "../components/header/FilterClientUser";
import ClientUserTable from "../components/table/ClientUserTable";
import {
  selectAllClientUsers,
  selectFilter,
  selectMetadata,
  updateFilterClientUsers,
  updatePaginationClientUsers
} from "../store/slice";
import {
  doCreateClientUser,
  doDeleteClientUser,
  doUpdateClientUser,
  getListClientUser
} from "../store/thunks";

const ClientUserListPage = () => {
  const breadCrumbs = [{ text: "COMPANY USERS" }];
  const [visibleModal, setVisibleModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [clientUser, setClientUser] = useState({});
  const dispatch = useDispatch();

  const clientUserList = useSelector(selectAllClientUsers);
  const filter = useSelector(selectFilter);
  const metadata = useSelector(selectMetadata);
  const page = metadata?.currentPage || 1;
  const pageSize =
    (metadata?.itemsPerPage || 10) > 100 ? 10 : metadata?.itemsPerPage || 10;
  const pagination = paginationFunc(
    metadata,
    "Company Users",
    updatePaginationClientUsers,
    dispatch
  );
  const canCreate = allowPermission(useSelector(selectRole), [UserRole.ADMIN]);

  useEffect(() => {
    dispatch(
      getListClientUser({
        page: page,
        pageSize: pageSize,
        filter: buildFirstLevelFilter(filter)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, filter]);

  const handleDelete = (id) => {
    dispatch(doDeleteClientUser(id));
  };

  const handleSubmit = (values) => {
    if (clientUser.id) {
      dispatch(doUpdateClientUser({ ...values, id: clientUser.id }));
    } else {
      dispatch(doCreateClientUser(values));
    }

    setVisibleModal(false);
  };

  const handleCreate = () => {
    setClientUser({});
    setVisibleModal(true);
  };

  const handleEdit = (editedClientUser) => {
    setClientUser(editedClientUser);
    setVisibleModal(true);
  };

  const handleFilter = (values) => {
    dispatch(updateFilterClientUsers(values));
  };

  return (
    <div className="list-driver-page">
      <ClientUserHeader
        breadCrumbs={breadCrumbs}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showCreateButton={canCreate}
        onCreate={handleCreate}
      />
      {showFilter && (
        <FilterClientUser
          defaultValues={filter}
          setShowFilter={setShowFilter}
          onFilter={handleFilter}
          onReset={() => dispatch(updateFilterClientUsers(undefined))}
        />
      )}
      <div className="content-table">
        <ClientUserTable
          dataSource={clientUserList}
          pagination={{ ...pagination, size: "small" }}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      </div>
      {visibleModal && (
        <Modal
          closable={false}
          visible={true}
          title={`${clientUser?.id ? "Update" : "Create"} Company User`}
          className="no-padding-modal"
          onCancel={() => setVisibleModal(false)}
          footer={false}
          width={792}
        >
          <ClientUserForm
            clientUser={clientUser}
            onSubmit={handleSubmit}
            onCancel={() => setVisibleModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ClientUserListPage;
