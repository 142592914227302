import axios from "axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../configs/constants";

const request = axios.create({
  baseURL: window.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json"
  }
});

request.interceptors.request.use(
  async (config) => {
    const cookies = new Cookies();
    const tmpToken = cookies.get(TOKEN_NAME, { path: "/" });

    config.headers = {
      Authorization: `Bearer ${tmpToken}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
    const originalRequest = error.config;
    let errorResponse;
    if (error.response?.status === 401 && !originalRequest._retry) {
      const cookies = new Cookies();
      originalRequest._retry = true;

      const refreshTokenCookies = cookies.get(REFRESH_TOKEN_NAME, { path: "/" });
      return request
        .post(`${window.REACT_APP_API_ENDPOINT}/admin/auth/refresh-access-token`, {
          refreshToken: refreshTokenCookies || "example"
        })
        .then((res) => {
          //res.status === 201 || res.status === 200
          if (res.accessToken) {
            try {
              const accessToken = res.accessToken;
              cookies.set(TOKEN_NAME, accessToken, { path: "/" });
              request.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
              return request.request(originalRequest);
            } catch (e) {
              console.log("e", e);
            }
          }
        });
    } else {
      if (
        error.response?.status === 400 &&
        (originalRequest.url ===
          `${window.REACT_APP_API_ENDPOINT}/auth/refresh-access-token` ||
          originalRequest.url ===
            `${window.REACT_APP_API_ENDPOINT}/admin/auth/refresh-access-token`)
      ) {
        errorResponse = Object.assign(error.response.data, {
          status: 401
        });
        return Promise.reject({
          ...errorResponse,
          message: JSON.stringify(errorResponse)
        });
      }
      if (error.response) {
        errorResponse = Object.assign(error.response.data, {
          status: error.response?.status || error.request?.status
        });
        return Promise.reject({
          ...errorResponse,
          message: JSON.stringify(errorResponse)
        });
      }
      if (error.request && error.request._response) {
        errorResponse = { error: { message: error.request._response } };
      }
      errorResponse = { error: { message: error.message } };
      return Promise.reject(errorResponse);
    }
  }
);

export default request;
