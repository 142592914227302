import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { markerLayer, markerIcon } from "../settings";

const icon = markerIcon();

const LocationLayer = ({ stops, handleClickMarker, isShowViewMore = true }) => {
  const setCursor = (map, cursor) => {
    map.getCanvas().style.cursor = cursor;
  };

  return stops.map((stop, index) => {
    return (
      <Layer {...markerLayer(stop?.type, icon[stop?.type])} key={stop.id}>
        {stop.display && (
          <Feature
            coordinates={[stop?.lng, stop?.lat]}
            onClick={() =>
              handleClickMarker({ ...stop, showViewMore: isShowViewMore }, "stop")
            }
            onMouseEnter={({ map }) => {
              setCursor(map, "pointer");
            }}
            onMouseLeave={({ map }) => {
              setCursor(map, "");
            }}
            properties={{
              label: stop?.sortNo || index + 1,
              color: stop?.color || "#C4C4C4",
              colorText: "#FFFFFF"
            }}
          />
        )}
      </Layer>
    );
  });
};
export default LocationLayer;
