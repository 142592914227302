import React, { useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Button, Tooltip, Card, notification } from "antd";
import { QuestionIcon } from "components/svgs";
import { setGlobalLoading } from "app/global/slice";
import request from "helper/request";

import "modules/client/routes/components/exports/index.scss";

const LOCATION_COLUMN_CANNOT_NULL = ["Stop Name", "Address", "Contact Phone"];

const ImportLocation = ({ setOpenImportModal, getSavedLocations }) => {
  const dispatch = useDispatch();
  const [importData, setImportData] = useState([]);
  const [error, setError] = useState(false);
  const fileInputRef = useRef();

  const onImportExcel = useCallback((tmpFile) => {
    const { files } = tmpFile.target;
    const fileReader = new FileReader();

    const checkFields = (item) => {
      let tpmItem = { ...item };
      let isError = false;
      LOCATION_COLUMN_CANNOT_NULL.forEach((column) => {
        if (!item[column]) {
          isError = true;
          tpmItem = {
            ...tpmItem,
            errors: tpmItem.errors
              ? [...tpmItem.errors, `${column} cannot be empty`]
              : [`${column} cannot be empty`]
          };
        }
      });
      return [tpmItem, isError];
    };

    const checkErrors = (arr) => {
      let isError = false;
      let tmpArr = [...arr];
      tmpArr = tmpArr.map((ar) => {
        const [item, err] = checkFields(ar);
        isError = isError || err;
        return item;
      });
      return [tmpArr, isError];
    };

    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });

        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook?.Sheets) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            const [resultArr, isError] = checkErrors(data);
            setError(isError);
            setImportData(resultArr);
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    };

    fileReader.readAsBinaryString(files[0]);
  }, []);

  const handleOnChangeImport = useCallback(
    (e) => {
      onImportExcel(e);
    },
    [onImportExcel]
  );

  const clickChooseFile = useCallback(() => {
    fileInputRef.current.click();
  }, [fileInputRef]);

  const handleImport = useCallback(async () => {
    dispatch(setGlobalLoading({ isLoading: true }));

    const locations = importData.map((item) => ({
      locationName: item["Stop Name"],
      address: item["Address"],
      contactName: item["Contact Name"],
      contactPhone: item["Contact Phone"],
      contactEmail: item["Contact Email"],
      note: item["Location Note"],
      lat: item["Latitude"],
      lng: item["Longitude"],
      timeWindowStart: item["Time Window - Start"],
      timeWindowEnd: item["Time Window - End"],
      waitingTime: item["Waiting/Service Time"],
      internalCustomerId: item["Internal Customer ID"],
      salesperson: item["Salesperson"]
    }));

    try {
      const response = await request.post("/saved-locations/import", {
        locations
      });

      if (response?.message) {
        notification.error({
          message: response?.message,
          placement: "topRight"
        });
      } else {
        notification.success({
          message: "Import successfully!",
          placement: "topRight"
        });
        getSavedLocations();
        setOpenImportModal(false);
      }
    } catch (e) {
      console.error("Error importing locations:", e);
      notification.error({
        message: "Error importing locations. Please try again.",
        placement: "topRight"
      });
    } finally {
      dispatch(setGlobalLoading({ isLoading: false }));
    }
  }, [importData, dispatch, getSavedLocations, setOpenImportModal]);

  const headerKeys = Object.keys(Object.assign({}, ...importData)).filter(
    (k) => k !== "errors"
  );

  return (
    <div className="import-locations-component m-3 pb-4">
      <input
        type={"file"}
        id={"csvFileInput"}
        accept={".xlsx, .xls, .csv"}
        className="hidden file-import"
        onChange={handleOnChangeImport}
        ref={fileInputRef}
      />
      <div className="">
        <div className="d-flex flex-row gap-2 ant-space-align-center">
          <Button
            className="btn-download svg-icon mb-3"
            size="small"
            onClick={clickChooseFile}
          >
            Choose file
          </Button>
          <Tooltip
            placement="right"
            overlayStyle={{ maxWidth: "600px" }}
            title={
              <span>
                Click here to import your own <b>.xlsx</b> or <b>.csv</b> file and add
                Locations.
              </span>
            }
          >
            <div className="d-flex mb-3 ant-space-align-center">
              <QuestionIcon />
            </div>
          </Tooltip>
        </div>
        <div className="download-text mb-3">
          Download Sample File:{" "}
          <a
            target="_blank"
            href="https://flexio-media.s3.us-east-2.amazonaws.com/sample-xlsx/import_locations.xlsx"
            rel="noopener noreferrer"
          >
            locations.xlsx
          </a>
        </div>
        <Card bodyStyle={{ backgroundColor: "#f2f2f2" }}>
          <p>
            The following describes the requirements for importing an <b>.xlsx</b> or{" "}
            <b>.csv</b> file:
          </p>
          <p>Mandatory fields:</p>
          <ul>
            <li>Stop Name</li>
            <li>Address</li>
            <li>Contact Phone</li>
          </ul>
        </Card>
      </div>
      <table id="client-table">
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th key={key} className="px-3 header-table">
                {key}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {importData.map((item, index) =>
            item.errors ? (
              <tr key={index} className={item.errors && "errors"}>
                {headerKeys.map((key) => (
                  <Tooltip placement="top" title={String(item.errors)} key={key}>
                    <td className="px-3">{item[key]}</td>
                  </Tooltip>
                ))}
              </tr>
            ) : (
              <tr key={index}>
                {headerKeys.map((key) => (
                  <td className="px-3" key={key}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
      {!error && importData?.length > 0 && (
        <div className="d-flex flex-justify-end">
          <Button
            type="primary"
            className="btn-download svg-icon mb-3"
            onClick={handleImport}
          >
            Import
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImportLocation;
