import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button } from "antd";

import { CheckBoxHook, RangePickerHook, SelectHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import { getClientList } from "modules/admin/clients/store/thunks";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getTerritoryList } from "modules/admin/settings/store/TerritoryThunks";
import { selectAllTerritories } from "modules/admin/settings/store/TerritorySlice";
import { selectGroup } from "modules/auth/store/authSlice";
import { UserGroup, DATE_FORMAT_FRONTEND } from "configs/constants";

import "../../routes/components/header/FilterRoute.scss";

const FilterMapComponent = ({ isAdmin, handleClickFilter }) => {
  const {
    reset,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });
  const clients = useSelector(selectAllClients);
  const territories = useSelector(selectAllTerritories);
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdmin) {
      dispatch(getClientList({ pageSize: 1000 }));
      isFlexioAdmin && dispatch(getTerritoryList({ pageSize: 1000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAdmin]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  const handleReset = async () => {
    await reset();
    setValue("dateRange", [moment(), moment()]);
    const today = moment().format(DATE_FORMAT_FRONTEND);
    handleClickFilter({ dateRange: [today, today] });
  };

  return (
    <div className="filter-order-component">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter three-column">
          <RangePickerHook
            control={control}
            getValues={getValues}
            label="Date Range"
            name="dateRange"
            errors={errors}
            showOptionsName={false}
            defaultValue={[moment(), moment()]}
          />
          {isAdmin ? (
            <>
              {isFlexioAdmin && (
                <SelectHook
                  showOptionsName={false}
                  control={control}
                  getValues={getValues}
                  placeholder="Territory"
                  label="Territory"
                  name="territoryId"
                  valueSelect="id"
                  labelSelect="name"
                  options={[
                    { name: `All Territories (${territories?.length || 0})`, id: "" },
                    ...territories
                  ]}
                  required
                  errors={errors}
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                />
              )}
              <SelectHook
                showOptionsName={false}
                control={control}
                getValues={getValues}
                placeholder="Client"
                label="Client"
                name="clientId"
                valueSelect="id"
                labelSelect="clientName"
                options={[
                  { clientName: `All Clients (${clients?.length || 0})`, id: "" },
                  ...clients
                ]}
                required
                errors={errors}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              />
            </>
          ) : (
            <div className="wrap-form-filter flex">
              <CheckBoxHook
                showOptionsName={false}
                control={control}
                label="Driver"
                name="isFlexioDriver"
                errors={errors}
                showLabel={true}
              >
                Flexio Driver
              </CheckBoxHook>
              <CheckBoxHook
                showOptionsName={false}
                control={control}
                label="&nbsp;"
                name="isInternalDriver"
                errors={errors}
                showLabel={true}
              >
                Internal Driver
              </CheckBoxHook>
            </div>
          )}
        </div>
        {isAdmin && (
          <div className="wrap-form-filter flex">
            <CheckBoxHook
              showOptionsName={false}
              control={control}
              label="Driver"
              name="isFlexioDriver"
              errors={errors}
              showLabel={true}
            >
              Flexio Driver
            </CheckBoxHook>
            <CheckBoxHook
              showOptionsName={false}
              control={control}
              label="&nbsp;"
              name="isInternalDriver"
              errors={errors}
              showLabel={true}
            >
              Internal Driver
            </CheckBoxHook>
          </div>
        )}
        <div className="wrap-button">
          <Button type="link" className="svg-icon clear" onClick={handleReset}>
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(FilterMapComponent);
