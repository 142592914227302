import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row } from "antd";
import _isEmpty from "lodash/isEmpty";
import _flattenDeep from "lodash/flattenDeep";
import _find from "lodash/find";
import React from "react";
import "./ReturnProcess.scss";
import { useEffect } from "react";

const RoutingProcessForm = ({ profile, isFetching, onSubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (_isEmpty(profile)) return;
    form.setFieldsValue({ ...profile.client });
  }, [form, profile]);

  const handleOnFinish = async (values) => {
    onSubmit({
      ...values
    });
  };

  return (
    <div className="common-form return-process-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleOnFinish}
        form={form}
      >
        <h2 className="title">Routing Process</h2>
        <div className="form">
          <Row gutter={24}>
            <Col span={12}>
              <h4>Stops per Route</h4>
              <div className="desc" style={{ fontSize: 13 }}>
                Sets the number of stops routes are divided into if total of stops is
                higher
              </div>
              <Form.Item
                name="preferredNoOfStops"
                rules={[{ required: true, message: "Required" }]}
              >
                <InputNumber
                  min={2}
                  max={99}
                  size="large"
                  placeholder="Stops per Route"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="form-footer">
          <Button loading={isFetching} type="primary" htmlType="submit">
            Update
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RoutingProcessForm;
