import React, { useContext, useMemo, useState } from "react";
import { Row, Col, Button } from "antd";
import cn from "classnames";

import NotificationSetting from "./NotificationSetting";
import "./SettingsPage.scss";
import ValidationSection from "../../validations/ValidationSection";
import ReturnProcess from "./ReturnProcessPage";
import ChangePassword from "./ChangePassword";
import MyProfilePage from "./MyProfilePage";
import PaymentMethodPage from "./PaymentMethodPage";
import InvoicingListPage from "../../invoicing/pages/InvoicingList";
import RoutingProcessPage from "./RoutingProcessPage";
import { PoolInvoices } from "./PoolInvoices";
import { FeatureFlagsContext } from "layouts/private/FeatureFlagsContext";
import SubscriptionsPage from "./SubscriptionsPage";

const SETTING_TYPES = {
  MY_PROFILE: "my_profile",
  INVOICING: "invoicing",
  POOL_INVOICES: "pool-invoices",
  SUBSCRIPTIONS: "subscriptions",
  NOTIFICATION: "notification",
  CHANGE_PASSWORD: "change_password",
  VALIDATIONS: "validations",
  ROUTING_PROCESS: "routing_process",
  RETURN_PROCESS: "return_process",
  PAYMENTMETHOD: "payment_method"
};

const SettingsPage = () => {
  const [settingType, setSettingType] = useState(SETTING_TYPES.MY_PROFILE);
  const featureCtx = useContext(FeatureFlagsContext);
  const isPoolFeatureEnable = useMemo(() => featureCtx?.isPoolAvailable, [featureCtx]);
  const isPoolInvoicesEnabled = window.POOL_INVOICING_FEATURE;

  return (
    <div className="settings-page">
      <div className="content-main">
        <Row gutter={16}>
          <Col span={4}>
            <div
              className="setting-menu"
              style={{ maxWidth: "200px", minWidth: "150px" }}
            >
              <Button
                className={cn({ active: settingType === SETTING_TYPES.MY_PROFILE })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.MY_PROFILE)}
              >
                My Profile
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.INVOICING })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.INVOICING)}
              >
                Invoicing
              </Button>
              {isPoolFeatureEnable && isPoolInvoicesEnabled && (
                <Button
                  className={cn({ active: settingType === SETTING_TYPES.POOL_INVOICES })}
                  type="text"
                  onClick={() => setSettingType(SETTING_TYPES.POOL_INVOICES)}
                >
                  Pool Invoices
                </Button>
              )}
              <Button
                className={cn({ active: settingType === SETTING_TYPES.SUBSCRIPTIONS })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.SUBSCRIPTIONS)}
              >
                Subscriptions
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.PAYMENTMETHOD })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.PAYMENTMETHOD)}
              >
                Payment Method
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.NOTIFICATION })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.NOTIFICATION)}
              >
                Notification Settings
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.VALIDATIONS })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.VALIDATIONS)}
              >
                Validations
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.ROUTING_PROCESS })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.ROUTING_PROCESS)}
              >
                Routing Process
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.RETURN_PROCESS })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.RETURN_PROCESS)}
              >
                Return Process
              </Button>
              <Button
                className={cn({ active: settingType === SETTING_TYPES.CHANGE_PASSWORD })}
                type="text"
                onClick={() => setSettingType(SETTING_TYPES.CHANGE_PASSWORD)}
              >
                Change Password
              </Button>
            </div>
          </Col>
          <Col span={18}>
            <div className="setting-content">
              {settingType === SETTING_TYPES.MY_PROFILE && <MyProfilePage />}
              {settingType === SETTING_TYPES.INVOICING && <InvoicingListPage />}
              {settingType === SETTING_TYPES.NOTIFICATION && <NotificationSetting />}
              {settingType === SETTING_TYPES.POOL_INVOICES && <PoolInvoices />}
              {settingType === SETTING_TYPES.SUBSCRIPTIONS && <SubscriptionsPage />}
              {settingType === SETTING_TYPES.VALIDATIONS && <ValidationSection />}
              {settingType === SETTING_TYPES.ROUTING_PROCESS && <RoutingProcessPage />}
              {settingType === SETTING_TYPES.RETURN_PROCESS && <ReturnProcess />}
              {settingType === SETTING_TYPES.CHANGE_PASSWORD && <ChangePassword />}
              {settingType === SETTING_TYPES.PAYMENTMETHOD && <PaymentMethodPage />}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingsPage;
