import { COLORS } from "configs/constants";
import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";

const Polygon = ({ coords = [] }) => {
  return (
    <GeoJSONLayer
      data={{
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: { color: COLORS.primaryAdmin },
            geometry: {
              type: "Polygon",
              coordinates: coords
            }
          }
        ]
      }}
      linePaint={{
        "line-color": ["get", "color"],
        "line-width": 3,
        "line-opacity": 0.8
      }}
      fillPaint={{
        "fill-color": "#1F3998",
        "fill-opacity": 0.3
      }}
    />
  );
};

export default React.memo(Polygon);
