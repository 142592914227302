export function buildFirstLevelFilter(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "id",
    "first_name",
    "last_name",
    "country",
    "city",
    "active",
    "email",
    "territory_id",
    "vehicle_types",
    "status"
  ];

  const FILTER_VALUES = {
    id: "$cont",
    first_name: "$contL",
    last_name: "$contL",
    email: "$contL",
    country: "$eq",
    city: "$contL",
    active: "$eq",
    territory_id: "$eq",
    vehicle_types: "$eq",
    status: "$eq"
  };

  return Object.keys(filter)
    .filter(
      (field) =>
        FIRST_LEVEL_FILTER.includes(field) &&
        filter[field] !== undefined &&
        filter[field] !== null &&
        filter[field] !== ""
    )
    .map(
      (filteredField) =>
        `${filteredField}||${FILTER_VALUES[filteredField]}||${filter[filteredField]}`
    );
}
