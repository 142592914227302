/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tag } from "antd";

import TableBase from "../../../../../components/table/TableBase";
import { allowPermission, convertHexToRGBA } from "../../../../../helper/util";
import { deleteUser } from "../../store/thunks";
import { selectRole } from "modules/auth/store/authSlice";
import { canEditPermission } from "configs/constants";
import { canDeletePermission } from "configs/constants";

const AdminClientTable = ({ dataSource, pagination }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDelete = (user) => {
    dispatch(deleteUser(user));
  };

  const handleEdit = (record) => {
    history.push(`/admin/users/clients/${record.id}`);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 110,
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 120
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: 120
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 160,
      ellipsis: true
    },
    // {
    //   title: "Company Name",
    //   dataIndex: "company",
    //   with: "130px"
    // },
    {
      title: "Role",
      dataIndex: "role",
      width: 120,
      render: (value) => {
        let text = "Super Admin";
        let color = "#43c1c2";
        switch (value) {
          case "manager":
            text = "Manager";
            color = "#f78B0f";
            break;
          case "viewer":
            text = "View Only";
            color = "#212121";
            break;
          default:
            break;
        }
        return (
          <Tag
            color={convertHexToRGBA(color, 20)}
            style={{
              color,
              lineHeight: "24px",
              width: "112px",
              textAlign: "center",
              fontWeight: "600"
            }}
          >
            {text}
          </Tag>
        );
      }
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: 170
    },
    {
      title: "Client",
      dataIndex: "client",
      width: 120,
      render: (value) => {
        return <div>{value?.clientType !== "individual" && value?.clientName}</div>;
      }
    },
    {
      title: "Client Type",
      dataIndex: "client",
      width: 120,
      render: (value) => {
        return <div className="text-capitalize">{value?.clientType}</div>;
      }
    },
    {
      title: "Territory",
      dataIndex: ["territory", "name"],
      width: 120
    }
  ];
  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <img
                src="/icons/edit.svg"
                className={`table-status ${value}`}
                onClick={() => handleEdit(record)}
              />
            )}
            {canDelete && (
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record)}
                okText="Yes"
                cancelText="No"
              >
                <img src="/icons/delete.svg" className={`table-status ${value}`} />
              </Popconfirm>
            )}
          </Space>
        );
      }
    });
  }
  return (
    <div className="AdminClientTable">
      <TableBase
        columns={columns}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default AdminClientTable;
