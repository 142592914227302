import React from "react";
import { useSelector } from "react-redux";

import AdminClientHeader from "../components/header/AdminClientHeader";
import { selectUser } from "../store/userSlice";
import { doGetUser } from "../store/thunks";
import withFetchOne from "../../../../hocs/withFetchOne";
import "./AdminUserCreateClient.scss";
import TerritoryForm from "../components/form/TerritoryForm";
import { withPermission } from "hocs/withPermission";
import { UserRole } from "configs/constants";

const AdminEditUserTerritory = () => {
  const breadCrumbs = [
    { text: "USERS" },
    { url: "/admin/users/territories", text: "TERRITORIES" },
    { text: "EDIT" }
  ];
  const userClient = useSelector(selectUser);
  return (
    <div className="AdminEditUserClient admin-create-update-client">
      <AdminClientHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Edit Territory User</h2>
        <TerritoryForm clientUser={userClient} />
      </div>
    </div>
  );
};
export default withPermission(
  withFetchOne(AdminEditUserTerritory, {
    fetchData: doGetUser
  }),
  [UserRole.ADMIN]
);
