import React, { createContext, useContext, useMemo, useState } from "react";

const ScopeContext = createContext([undefined, () => {}]);

export function useScope(def) {
  const [scope, setScope] = useContext(ScopeContext);
  return [scope || (def instanceof Function ? def() : def), setScope];
}

export function useScopeReducer(reduce, def) {
  const [state, setState] = useScope(def);
  return useMemo(
    () => [state, (action, payload) => setState((pre) => reduce(pre, action, payload))],
    [reduce, state, setState]
  );
}

export function withScope(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const value = useState();
    return (
      <ScopeContext.Provider value={value}>
        <Component {...props} />
      </ScopeContext.Provider>
    );
  };
}
