/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
import { ImportIcon } from "components/svgs/ImportIcon";
import { ExportIcon } from "components/svgs/ExportIcon";
import { FilterIcon } from "components/svgs";

const Header = ({
  breadCrumbs,
  buttonParams,
  showFilter,
  setShowFilter,
  hasButton = true,
  showImport = false,
  showExport = false,
  setShowImport,
  setShowImportHistorical,
  onExport,
  exporting,
  isAdmin
}) => {
  return (
    <div className="common-header">
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons d-flex flex-row gap-2">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
          {showExport && (
            <Button
              size="small"
              type="third"
              className={`svg-icon d-flex flex-row`}
              onClick={onExport}
              loading={exporting}
            >
              Export <ExportIcon />
            </Button>
          )}
          {showImport && (
            <Button
              size="small"
              type="third"
              className={`svg-icon  d-flex flex-row`}
              onClick={setShowImport}
            >
              Import <ImportIcon />
            </Button>
          )}
          {isAdmin && (
            <Button
              size="small"
              type="third"
              className={`svg-icon  d-flex flex-row`}
              onClick={() => setShowImportHistorical(true)}
            >
              Import Historical Data <ImportIcon />
            </Button>
          )}
          {buttonParams && (
            <Link to={buttonParams.link}>
              <Button type="primary" size="small">
                {buttonParams.text}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default Header;
