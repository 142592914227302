import { createAsyncThunk } from "@reduxjs/toolkit";

import { setGlobalLoading } from "app/global/slice";
import { ACTION } from "configs/constants";

import driverBroadcastApi from "./api";

export const doGetDriverBroadcasts = createAsyncThunk(
  "driver-broadcasts/get/loading",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return driverBroadcastApi.getDriverBroadcasts(payload);
  }
);

export const doGetDriverBroadcast = createAsyncThunk(
  "driver-broadcast/get/loading",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return driverBroadcastApi.getDriverBroadcast(id);
  }
);

export const doCreateDriverBroadcast = createAsyncThunk(
  "driver-broadcasts/create",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return driverBroadcastApi.createDriverBroadcast(data);
  }
);
