export const prCities = [
  {
    name: "Adjuntas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1653,
    longitude: -66.7226
  },
  {
    name: "Aguada",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.381,
    longitude: -67.1896
  },
  {
    name: "Aguadilla",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4274,
    longitude: -67.1541
  },
  {
    name: "Aguas Buenas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2511,
    longitude: -66.1126
  },
  {
    name: "Aibonito",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1428,
    longitude: -66.2676
  },
  {
    name: "Añasco",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.282,
    longitude: -67.1251
  },
  {
    name: "Arecibo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4494,
    longitude: -66.7326
  },
  {
    name: "Arroyo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9706,
    longitude: -66.0616
  },
  {
    name: "Barceloneta",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4497,
    longitude: -66.5411
  },
  {
    name: "Barranquitas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1848,
    longitude: -66.3128
  },
  {
    name: "Bayamón",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3984,
    longitude: -66.1552
  },
  {
    name: "Cabo Rojo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0866,
    longitude: -67.1457
  },
  {
    name: "Caguas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2343,
    longitude: -66.0485
  },
  {
    name: "Camuy",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.484,
    longitude: -66.8439
  },
  {
    name: "Canóvanas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3794,
    longitude: -65.8896
  },
  {
    name: "Carolina",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3808,
    longitude: -65.9574
  },
  {
    name: "Cataño",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4484,
    longitude: -66.1144
  },
  {
    name: "Cayey",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1111,
    longitude: -66.1653
  },
  {
    name: "Ceiba",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.253,
    longitude: -65.6486
  },
  {
    name: "Ciales",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3315,
    longitude: -66.4826
  },
  {
    name: "Cidra",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1762,
    longitude: -66.1613
  },
  {
    name: "Coamo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0771,
    longitude: -66.3617
  },
  {
    name: "Comerío",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2196,
    longitude: -66.2233
  },
  {
    name: "Corozal",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3443,
    longitude: -66.3165
  },
  {
    name: "Culebra",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3283,
    longitude: -65.2903
  },
  {
    name: "Dorado",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.458,
    longitude: -66.2672
  },
  {
    name: "Fajardo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3311,
    longitude: -65.6333
  },
  {
    name: "Florida",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3693,
    longitude: -66.5616
  },
  {
    name: "Guánica",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9734,
    longitude: -66.9075
  },
  {
    name: "Guayama",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9836,
    longitude: -66.1094
  },
  {
    name: "Guayanilla",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9705,
    longitude: -66.9237
  },
  {
    name: "Guaynabo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3567,
    longitude: -66.1067
  },
  {
    name: "Gurabo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2552,
    longitude: -65.9721
  },
  {
    name: "Hatillo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4932,
    longitude: -66.8257
  },
  {
    name: "Hormigueros",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1392,
    longitude: -67.1197
  },
  {
    name: "Humacao",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1507,
    longitude: -65.8236
  },
  {
    name: "Isabela",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.5022,
    longitude: -67.022
  },
  {
    name: "Jayuya",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2186,
    longitude: -66.5919
  },
  {
    name: "Juana Díaz",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0547,
    longitude: -66.5148
  },
  {
    name: "Juncos",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.226,
    longitude: -65.9161
  },
  {
    name: "Lajas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.043,
    longitude: -67.0547
  },
  {
    name: "Lares",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2943,
    longitude: -66.8776
  },
  {
    name: "Las Marías",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2706,
    longitude: -66.9921
  },
  {
    name: "Las Piedras",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1883,
    longitude: -65.8837
  },
  {
    name: "Loíza",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4315,
    longitude: -65.8776
  },
  {
    name: "Luquillo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3806,
    longitude: -65.7211
  },
  {
    name: "Manatí",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4306,
    longitude: -66.4811
  },
  {
    name: "Maricao",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.178,
    longitude: -66.9677
  },
  {
    name: "Maunabo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0105,
    longitude: -65.8997
  },
  {
    name: "Mayagüez",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.201,
    longitude: -67.1397
  },
  {
    name: "Moca",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3865,
    longitude: -67.177
  },
  {
    name: "Morovis",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3351,
    longitude: -66.4072
  },
  {
    name: "Naguabo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2071,
    longitude: -65.7346
  },
  {
    name: "Naranjito",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2994,
    longitude: -66.2465
  },
  {
    name: "Orocovis",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2252,
    longitude: -66.4592
  },
  {
    name: "Patillas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0112,
    longitude: -66.0163
  },
  {
    name: "Peñuelas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0565,
    longitude: -66.7274
  },
  {
    name: "Ponce",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0111,
    longitude: -66.6141
  },
  {
    name: "Quebradillas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4672,
    longitude: -66.9396
  },
  {
    name: "Rincón",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3406,
    longitude: -67.2496
  },
  {
    name: "Río Grande",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3801,
    longitude: -65.7245
  },
  {
    name: "Sabana Grande",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.081,
    longitude: -66.9613
  },
  {
    name: "Salinas",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9753,
    longitude: -66.2967
  },
  {
    name: "San Germán",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0866,
    longitude: -67.0566
  },
  {
    name: "San Juan",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4655,
    longitude: -66.1057
  },
  {
    name: "San Lorenzo",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1917,
    longitude: -65.9674
  },
  {
    name: "San Sebastián",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3345,
    longitude: -66.9916
  },
  {
    name: "Santa Isabel",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 17.9679,
    longitude: -66.4015
  },
  {
    name: "Toa Alta",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3905,
    longitude: -66.252
  },
  {
    name: "Toa Baja",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4465,
    longitude: -66.2405
  },
  {
    name: "Trujillo Alto",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.3561,
    longitude: -66.0033
  },
  {
    name: "Utuado",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.2671,
    longitude: -66.7005
  },
  {
    name: "Vega Alta",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4144,
    longitude: -66.3236
  },
  {
    name: "Vega Baja",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.4416,
    longitude: -66.388
  },
  {
    name: "Vieques",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.126,
    longitude: -65.4409
  },
  {
    name: "Villalba",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.1289,
    longitude: -66.484
  },
  {
    name: "Yabucoa",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0549,
    longitude: -65.8885
  },
  {
    name: "Yauco",
    countryCode: "PR",
    stateCode: "PR",
    latitude: 18.0346,
    longitude: -66.8531
  }
];
