import React, { useState } from "react";
import { Space, Form, Input, Button } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  FileMarkdownOutlined
} from "@ant-design/icons";
import { formatCurrency } from "helper/util";
import { useDispatch, useSelector } from "react-redux";
import { updatePrice } from "../../store/RouteThunks";
import { allowPermission } from "helper/util";
import { canEditPermission } from "configs/constants";
import { selectRole } from "modules/auth/store/authSlice";

const EditPriceInput = ({
  value,
  record,
  priceType,
  typeName,
  manualUpdate,
  disabled = false
}) => {
  const [editKey, setEditKey] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const dispatch = useDispatch();
  const canEdit = allowPermission(useSelector(selectRole), canEditPermission);
  const [form] = Form.useForm();

  const checkEditRow = (name, row) => {
    return editKey === name && row?.id === selectedRoute?.id;
  };

  const onFinish = (values) => {
    if (typeof values === "object") {
      const { typeClient, typeDriver, price } = values;
      dispatch(
        updatePrice({
          id: selectedRoute?.id,
          price,
          type: typeClient || typeDriver,
          user: record?.order?.user,
          clientName: record?.client?.clientName
        })
      );
      form?.resetFields();
      setSelectedRoute(null);
    }
  };

  if (
    record.pickupTime === "null" &&
    record.jobs?.length === 1 &&
    record.jobs[0].pickupAddress === record.jobs[0].dropoffAddress
  ) {
    return <></>;
  } else {
    return (
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          remember: true
        }}
      >
        <Space>
          <div
            onClick={(e) => {
              e.stopPropagation(), setEditKey(priceType);
            }}
          >
            {checkEditRow(priceType, record) ? (
              <Space>
                <Form.Item
                  style={{ marginTop: "16px" }}
                  span={4}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "Please input your values!"
                    }
                  ]}
                >
                  <Input
                    defaultValue={value}
                    prefix={window.CURRENCY_SYMBOL}
                    style={{
                      width: "64px"
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={typeName}
                  style={{ display: "none" }}
                  initialValue={priceType}
                ></Form.Item>

                <Button
                  htmlType="submit"
                  type="text"
                  style={{
                    color: "green",
                    backgroundColor: "inherit",
                    marginLeft: "-16px"
                  }}
                >
                  <CheckOutlined />
                </Button>

                <CloseOutlined
                  style={{ color: "#cecece", marginLeft: "-8px" }}
                  onClick={() => setSelectedRoute(null)}
                />
              </Space>
            ) : (
              <Space>
                <span>{formatCurrency(value || 0)}</span>
                {canEdit && (
                  <>
                    {!disabled && (
                      // {/* </Tooltip> */}
                      <EditOutlined
                        onClick={() => {
                          setSelectedRoute(record);
                        }}
                      />
                    )}
                  </>
                )}
              </Space>
            )}
          </div>
        </Space>
        <div className="ml-2 d-inline-block">
          {manualUpdate ? <FileMarkdownOutlined /> : ""}
        </div>
      </Form>
    );
  }
};

export default EditPriceInput;
