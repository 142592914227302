export const ONE_HOUR_PX = 200; //px
export const LEFT_DEFAULT = 0; //px

export const DEFAULT_CENTER = {
  lat: 49.23308915,
  lng: -123.1063475
};

export const DND_STATUSES = {
  SORT: "sort",
  OPTIMIZE: "optimize"
};
