import { yupResolver } from "@hookform/resolvers/yup";
import { Button, notification } from "antd";
import {
  FileUploadHook,
  InputHook,
  InputPasswordHook,
  SelectHook,
  DatePickerHook
} from "components/hook-forms";
import request from "helper/request";
import { City, State } from "country-state-city";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import Cards from "../components/Cards";
import ActionButton from "./ActionButtons";
import { step2Schema, step2SchemaWithoutState } from "./schema";
import "./Step2Form.scss";
import { GroupCheckboxHook } from "components/hook-forms";

import { VEHICLE_OPTIONS } from "../constants";
import { CheckCircleIcon } from "components/svgs";

const Step2Form = ({ driver, handleUpdateDriver, showActionButton, isAdmin = true }) => {
  const [cities, setCities] = useState(City.getCitiesOfCountry(driver.country));
  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      driver.country === "PR" ? step2SchemaWithoutState : step2Schema
    ),
    defaultValues: {
      ...driver
    }
  });
  const watchState = watch("state");
  const countryState = State.getStatesOfCountry(driver.country);

  useEffect(() => {
    if (!isEmpty(driver)) reset(driver);
  }, [driver, reset]);

  useEffect(() => {
    if (driver.country === "PR") {
      const stateCities = City.getCitiesOfState("US", "PR");
      setCities(stateCities);
    } else {
      const stateCities = City.getCitiesOfState(driver.country, watchState);
      setCities(stateCities);
    }
  }, [watchState, driver.country]);
  const submitForm = async (values) => {
    const error = await handleUpdateDriver({
      ...values,
      id: driver?.id,
      vehicleTypeOnRegistration: values.vehicleTypeOnRegistration
    });
    if (!error) {
      notification.success({
        message: "Update successfully!",
        placement: "bottomRight"
      });
    }
  };

  const handleSendLink = useCallback(async () => {
    try {
      const result = await request.post("/admin/drivers/advanced-info", {
        email: driver?.email,
        token: driver?.token,
        firstName: driver?.firstName,
        lastName: driver?.lastName
      });
      if (result) {
        notification.success({
          message: "Email sent!",
          placement: "bottomRight"
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [driver]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Cards title={"Advanced Information"} className="wrap-step-2-form">
        <div className="group-form">
          {driver.country === "US" && (
            <SelectHook
              showOptionsName={false}
              control={control}
              getValues={getValues}
              placeholder="State"
              label="State"
              name="state"
              valueSelect="isoCode"
              labelSelect="name"
              required
              errors={errors}
              options={countryState}
              showSearch
            />
          )}
          {cities.length > 0 && (
            <SelectHook
              showOptionsName={false}
              control={control}
              getValues={getValues}
              placeholder="City"
              label="City"
              name="city"
              valueSelect="name"
              labelSelect="name"
              required
              errors={errors}
              options={cities}
              showSearch
            />
          )}

          <InputHook
            control={control}
            label="Postal Address"
            placeholder="Postal Address"
            name="postalAddress"
            showOptionsName={false}
            errors={errors}
          />
        </div>
        {isAdmin && (
          <div className="vehicle-types-check-list">
            <GroupCheckboxHook
              label="Vehicle Types on Registration"
              required
              options={VEHICLE_OPTIONS}
              showOptionsName={false}
              control={control}
              name="vehicleTypeOnRegistration"
              errors={errors}
              className="p-4"
            />
          </div>
        )}

        {/* <div className="group-form">
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Make"
              placeholder="Vehicle Make"
              name="vehicleMake"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Model"
              placeholder="Vehicle Model"
              name="vehicleModel"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Color"
              placeholder="Vehicle Color"
              name="vehicleColor"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Vehicle Year"
              placeholder="Vehicle Year"
              name="vehicleYear"
              required
              errors={errors}
            />
            <InputHook
              showOptionsName={false}
              control={control}
              label="Number Plate"
              placeholder="Number Plate"
              name="numberPlate"
              required
              errors={errors}
            />
          </div> */}
      </Cards>

      <Cards title="Upload Images" className="wrap-step-2-form">
        <div className="group-form">
          {/* <FileUploadHook
            showOptionsName={false}
            label="Vehicle Licence Plate #"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleLicensePlate"
          /> */}
          <FileUploadHook
            showOptionsName={false}
            label="Profile Photo (shoulders and up)"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="profilePhoto"
          />

          {/* <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Left Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageLeftSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Right Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageRightSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Front Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageFrontSide"
          />
          <FileUploadHook
            showOptionsName={false}
            label="Vehicle Image Back Side"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleImageBackSide"
          /> */}
        </div>
      </Cards>

      <Cards title="Upload Images" className="wrap-step-2-admin-form">
        <div className="group-form">
          <FileUploadHook
            showOptionsName={false}
            label="Driver’s License"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="driverLicense"
          />
          {/* <FileUploadHook
            showOptionsName={false}
            label="Vehicle Registration"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleRegistration"
          /> */}

          <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Driver's License Expiration Date"
            label="Driver's License Expiration Date"
            name="licenseExpirationDate"
            errors={errors}
            showOptionsName={false}
          />

          {/* <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Driver's Registration Expiration Date"
            label="Driver's Registration Expiration Date"
            name="registrationExpirationDate"
            errors={errors}
            showOptionsName={false}
          /> */}

          {/* <FileUploadHook
            showOptionsName={false}
            label="Vehicle Insurance"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="vehicleInsurance"
          /> */}
          <FileUploadHook
            showOptionsName={false}
            label="Driving Record"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="drivingRecord"
          />
          {/* <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Vehicle Insurance Expiration Date"
            label="Vehicle Insurance Expiration Date"
            name="insuranceExpirationDate"
            errors={errors}
            showOptionsName={false}
          /> */}

          <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Driving Record Expiration Date"
            label="Driving Record Expiration Date"
            name="recordExpirationDate"
            errors={errors}
            showOptionsName={false}
          />
          <FileUploadHook
            showOptionsName={false}
            label="Criminal Record Certificate"
            control={control}
            errors={errors}
            accept=".jpg,.png,.jpeg"
            setValueForm={setValue}
            getValueForm={getValues}
            name="criminalRecordCertificate"
          />
          <DatePickerHook
            control={control}
            getValues={getValues}
            placeholder="Criminal Record Certificate Expiration Date"
            label="Criminal Record Certificate Expiration Date"
            name="recordCertificateDate"
            errors={errors}
            showOptionsName={false}
          />
        </div>
      </Cards>

      <Cards title={"Tax Information"} className="wrap-step-3-form">
        <div className="group-form">
          <InputPasswordHook
            control={control}
            label="SSN or EIN"
            placeholder="SSN or EIN"
            name="taxSsnOrEin"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="(Optional) Tax Name if different"
            placeholder="e.g. My Trucking Company, LLC"
            name="taxName"
            showOptionsName={false}
            errors={errors}
          />
          <InputHook
            control={control}
            label="(Optional) Tax Address if different"
            placeholder="e.g. PO Box 1337, San Juan, PR  00936"
            name="taxAddress"
            showOptionsName={false}
            errors={errors}
          />

          <div id="empty div" style={{ padding: "20px" }}></div>
        </div>
      </Cards>
      {showActionButton && (
        <>
          {isAdmin ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                padding: "10px",
                width: "100%"
              }}
            >
              <hr
                style={{
                  width: "100%",
                  marginBottom: "-2px",
                  borderTop: "1px solid #ddd"
                }}
              ></hr>
              <Button
                onClick={handleSendLink}
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "20px"
                }}
              >
                Send this form to driver{"'"}s email
              </Button>
              <ActionButton text="Update" />
            </div>
          ) : (
            <div className="action-buttons">
              <Button type="primary" className="svg-icon" htmlType="submit">
                Update
                <CheckCircleIcon color="#FFFFFF" />
              </Button>
            </div>
          )}
        </>
      )}
    </form>
  );
};
export default Step2Form;
