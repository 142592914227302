import { Button } from "antd";
import React, { useEffect, useState } from "react";

import "./PackageList.scss";
import { ReactSVG } from "react-svg";

const PackageList = ({ packages, editPack, addPack, removePack }) => {
  const [isValidPackages, setIsValidPackages] = useState(false);

  useEffect(() => {
    setIsValidPackages(packages?.filter((pack) => pack.quantity)?.length);
  }, [packages]);

  return (
    <div className="PackageList">
      <h1>PACKAGE</h1>
      {!isValidPackages && (
        <p className="ant-form-item-explain-error">At least 1 package</p>
      )}
      {packages?.map((pack, index) => {
        return (
          <div className="package-item" key={index}>
            <div className="top">
              <div className="content">
                {pack.action !== "new" && (
                  <>
                    {index + 1}. <b>{pack.type} package</b>
                  </>
                )}
              </div>
              {!pack.action && (
                <div className="actions">
                  <Button type="link" className="edit" onClick={() => editPack(pack)}>
                    Edit
                  </Button>
                  <Button type="link" className="delete" onClick={() => removePack(pack)}>
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="bottom">
              {!pack.action && (
                <p>
                  {pack.name} x {pack.quantity}
                </p>
              )}
            </div>
          </div>
        );
      })}
      <Button size="large" className="add-new-pack" onClick={() => addPack()}>
        Add Package <ReactSVG src="/icons/add-pack.svg" className="icon" />
      </Button>
    </div>
  );
};
export default PackageList;
