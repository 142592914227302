/*

  File: Version List
  Last Updated: 2023-11-15
  Updated by: Owen
  Update Details: Switched to using Hash Date Time instead of Version

*/

import React, { useState, useEffect } from "react";
import SettingHeader from "../components/header/SettingHeader";
import "../styles/settings.scss";
import request from "helper/request";

// Get Frontend Version directly from env vars
// TODO Can we just remove this, this never gets updated.
const frontendHash = process.env.REACT_APP_HASH;
const frontendDate = process.env.REACT_APP_DATE;
const frontendTime = process.env.REACT_APP_TIME;

// Build variable URL endpoint for backend
const apiURL = window.REACT_APP_API_ENDPOINT;
const versionURL = apiURL.concat("/admin/version");

// Main constructor
const VersionList = () => {
  const [data, setData] = useState({});
  const breadCrumbs = [{ text: "SETTINGS" }, { text: "VERSION" }];

  // Async hook
  useEffect(() => {
    // define fetch as async, gets data from API URL defined above
    (async () => {
      try {
        const responseData = await request.get(versionURL);
        setData(responseData);
      } catch (error) {
        console.error("Error fetching version data:", error);
      }
    })();
  }, []);

  return (
    <div className="VersionList">
      <SettingHeader breadCrumbs={breadCrumbs} />
      <div className="admin-content version-content">
        <div className="version-list-container">
          <h2>Backend</h2>
          <div className="version-list-container__item">Hash: {data?.beHash}</div>
          <div className="version-list-container__item">Date: {data?.beDate}</div>
          <div className="version-list-container__item">Time: {data?.beTime}</div>
        </div>
        <div className="version-list-container">
          <h2>Frontend</h2>
          <div className="version-list-container__item">Hash: {frontendHash}</div>
          <div className="version-list-container__item">Date: {frontendDate}</div>
          <div className="version-list-container__item">Time: {frontendTime}</div>
        </div>
      </div>
    </div>
  );
};

export default VersionList;
