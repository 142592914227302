import { makeId } from "../../../helper/util";
import moment from "moment/moment";

export function packageHasError(poolPackage) {
  if (
    !poolPackage?.pickupAddress ||
    !poolPackage?.pickupContactName ||
    !poolPackage?.pickupContactPhone ||
    !poolPackage?.dropoffAddress ||
    !poolPackage?.dropoffContactName ||
    !poolPackage?.dropoffContactPhone ||
    !poolPackage?.packageTypeId ||
    !poolPackage?.pickupLat ||
    !poolPackage?.pickupLng ||
    !poolPackage?.dropoffLat ||
    !poolPackage?.dropoffLng ||
    !poolPackage?.pickupDate
  )
    return true;
  return false;
}

export const poolPackageTransform = (poolPackages) => {
  return poolPackages.map((poolPackage) => ({
    pickupAddress: poolPackage?.pickupAddress,
    pickupContactName: poolPackage?.pickupContactName,
    pickupContactPhone: poolPackage?.pickupContactPhone,
    pickupContactEmail: poolPackage?.pickupContactEmail,
    pickupStopName: poolPackage?.pickupStopName,
    dropoffAddress: poolPackage?.dropoffAddress,
    dropoffContactName: poolPackage?.dropoffContactName,
    dropoffContactPhone: poolPackage?.dropoffContactPhone,
    dropoffStopName: poolPackage?.dropoffStopName,
    packageTypeId: poolPackage?.packageTypeId,
    pickupLat: poolPackage?.pickupLat,
    pickupLng: poolPackage?.pickupLng,
    dropoffLat: poolPackage?.dropoffLat,
    dropoffLng: poolPackage?.dropoffLng,
    pickupDate: poolPackage?.pickupDate.toDate(),
    note: poolPackage?.note,
    quantity: poolPackage?.quantity,
    shopifyOrderId: poolPackage?.shopifyOrderId
  }));
};
export const newPackage = {
  id: `${new Date().getTime()}${makeId(4)}`,
  pickupAddress: "",
  pickupContactName: "",
  pickupContactPhone: "",
  pickupContactEmail: "",
  pickupStopName: "",
  pickupDate: moment().add(1, "d"),
  dropoffAddress: "",
  dropoffContactName: "",
  dropoffContactPhone: "",
  dropoffStopName: "",
  packageTypeId: "",
  note: "",
  quantity: 1
};
