import React from "react";
const DeleteIcon = ({ onClick, className }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 0.333252C3.31301 0.333252 0.333008 3.31325 0.333008 6.99992C0.333008 10.6866 3.31301 13.6666 6.99967 13.6666C10.6863 13.6666 13.6663 10.6866 13.6663 6.99992C13.6663 3.31325 10.6863 0.333252 6.99967 0.333252ZM10.333 9.39325L9.39301 10.3333L6.99967 7.93992L4.60634 10.3333L3.66634 9.39325L6.05967 6.99992L3.66634 4.60659L4.60634 3.66659L6.99967 6.05992L9.39301 3.66659L10.333 4.60659L7.93967 6.99992L10.333 9.39325Z"
        fill="#FF3D17"
      />
    </svg>
  );
};
export { DeleteIcon };
