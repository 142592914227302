import request from "../../../../helper/request";

export const dashboardApi = {
  getStatistic: (params = {}) => {
    return request.get("/dashboard/statistic", { params });
  },
  getMap: (params = {}) => {
    return request.get("/dashboard/map", { params });
  }
};
