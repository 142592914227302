import React, { useCallback, useEffect, useState, useMemo } from "react";
import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";
import { MarkerIconFilled } from "components/svgs/MarkerIconFilled";
import _ from "lodash";
import { Button } from "antd";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_KEY,
  onStyleLoad: (m) => m.resize(),
  zoom: 5
});

const MapImportPreview = ({ routes, title = "Preview" }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const initCenter = useMemo(
    () =>
      routes[0]?.address?.lngLat.length
        ? routes[0]?.address?.lngLat
        : [-66.0594, 18.4153],
    [routes]
  );

  const [center, setCenter] = useState([...initCenter]);
  const [bounds, setBounds] = useState([
    [-67.1451071, 18.2529919],
    [-66.0339562, 18.4744149]
  ]);

  useEffect(() => {
    getBounds(routes);
  }, [routes]);

  const openPopup = useCallback((index) => setSelectedIndex(index), []);

  const updateMapCenter = useCallback((coords) => {
    setCenter(coords);
  }, []);

  const handleMarkerOnClick = useCallback((markerIndex, coords) => {
    openPopup(markerIndex);
    updateMapCenter(coords);
  }, []);

  const getMinOrMax = (routesObj, minOrMax, latOrLng) => {
    if (minOrMax == "max") {
      return _.maxBy(routesObj, function (value) {
        return value.address.lngLat[latOrLng];
      })?.address.lngLat[latOrLng];
    } else {
      return _.minBy(routesObj, function (value) {
        return value.address.lngLat[latOrLng];
      })?.address.lngLat[latOrLng];
    }
  };

  const getBounds = useCallback((mapRoute) => {
    if (!validateRoute(mapRoute)) {
      setBounds(bounds);
      return;
    }

    const maxLat = getMinOrMax(mapRoute, "max", 1);
    const minLat = getMinOrMax(mapRoute, "min", 1);
    const maxLng = getMinOrMax(mapRoute, "max", 0);
    const minLng = getMinOrMax(mapRoute, "min", 0);

    const southWest = [minLng, minLat];
    const northEast = [maxLng, maxLat];

    setBounds([southWest, northEast]);
  }, []);

  /**
   * @description Validate route in order to continue
   *  @param route An object that contains route information
   * @returns Undefined or route object
   */
  const validateRoute = (route) => {
    const validRoute = route.find((stop) => {
      return stop?.address?.lngLat.length;
    });

    return validRoute;
  };

  return (
    <div className="mb-4">
      <h2 className="title p-1">{title}</h2>
      <Map
        style="mapbox://styles/mapbox/streets-v11"
        movingMethod="easeTo"
        containerStyle={{
          height: 400,
          width: "100%",
          padding: 3
        }}
        center={center}
        fitBounds={bounds}
        fitBoundsOptions={{ padding: 100 }}
      >
        {routes.map((route, index) => {
          const routeCoords = {};

          if (!route?.address?.lngLat.length) return;

          routeCoords.lng = route.address?.lngLat[0];
          routeCoords.lat = route.address?.lngLat[1];

          return (
            <>
              {selectedIndex === index && (
                <Popup
                  coordinates={[routeCoords.lng, routeCoords.lat]}
                  closeButton={true}
                  closeOnClick={true}
                  offsetTop={-30}
                >
                  {route?.address.address}
                </Popup>
              )}
              <Marker
                coordinates={[routeCoords.lng, routeCoords.lat]}
                key={index}
                onClick={() =>
                  handleMarkerOnClick(index, [routeCoords.lng, routeCoords.lat])
                }
              >
                <img src="/icons/location.png" width="20" height="20" />
              </Marker>
            </>
          );
        })}
        <Button onClick={() => getBounds(routes)}>Show All</Button>
      </Map>
    </div>
  );
};

export default MapImportPreview;
