import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import {
  doCreateDriverBroadcast,
  doGetDriverBroadcast,
  doGetDriverBroadcasts
} from "./thunks";

const DriverBroadcastsAdapter = createEntityAdapter({
  selectId: (broadcast) => broadcast.id
});

const initialState = DriverBroadcastsAdapter.getInitialState({
  metadata: {},
  broadcast: {}
});

export const driverBroadcastsSlice = createSlice({
  name: "driverBroadcasts",
  initialState,
  reducers: {
    updatePaginationBroadcasts(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetDriverBroadcasts.fulfilled, (state, { payload }) => {
      DriverBroadcastsAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetDriverBroadcast.fulfilled, (state, { payload }) => {
      state.broadcast = payload;
    });
    builder.addCase(doCreateDriverBroadcast.fulfilled, (state, { payload }) => {
      DriverBroadcastsAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectBroadcastById,
  selectIds: selectBroadcastByIds,
  selectEntities: selectBroadcastEntities,
  selectAll: selectAllBroadcasts,
  selectTotal: selectTotalBroadcasts
} = DriverBroadcastsAdapter.getSelectors((state) => state.driverBroadcasts);

export const selectMetadata = (state) => state.driverBroadcasts.metadata;
export const selectBroadcast = (state) => state.driverBroadcasts.broadcast;

export const { updatePaginationBroadcasts } = driverBroadcastsSlice.actions;

export default driverBroadcastsSlice.reducer;
