import React, { useEffect, useMemo, useState } from "react";
import { Form, Checkbox, Radio, Row, Col, Input } from "antd";
import sumBy from "lodash/sumBy";

import "./SelectPack.scss";
import { convertArrayToObject } from "helper/util";

const SelectPack = ({ packages, formSelectPack, dropId, onOk, packageTypeList }) => {
  const objPackageTypeList = useMemo(
    () => convertArrayToObject(packageTypeList, "id"),
    [packageTypeList]
  );
  const [packsForm, setPacksForm] = useState({});
  const [checkPacks, setCheckPacks] = useState([]);

  useEffect(() => {
    formSelectPack.resetFields();
    const rs = {};
    const dataForm = {};
    const rsQuantity = [];
    const mapByKeys = (packs) => {
      const rsMapKeys = {};
      packs.forEach((pack) => {
        const packageType = objPackageTypeList[pack.packageTypeId];
        rsMapKeys[packageType.name] = rsMapKeys[packageType.name] || [];
        rsMapKeys[packageType.name].push(pack);
      });
      return rsMapKeys;
    };
    const mapByQuantity = (array) => {
      return array.map((element) => {
        if (!element.dropoffs || !element.dropoffs.length) {
          return {
            id: element.id,
            name: element.name,
            quantity: element.quantity,
            quantityInput: 0,
            ischeck: false
          };
        }
        const quantityOther = sumBy(
          element.dropoffs.filter((drop) => dropId !== drop.id),
          "quantity"
        );
        const quantityCurrent =
          element.dropoffs.find((drop) => dropId === drop.id)?.quantity || 0;
        if (element.quantity - quantityOther > 0) {
          return {
            id: element.id,
            name: element.name,
            quantity: element.quantity - quantityOther,
            quantityInput: quantityCurrent,
            isCheck: quantityCurrent > 0
          };
        }
      });
    };
    console.log("packages", packages);
    const packByKeys = mapByKeys(packages);
    Object.keys(packByKeys).forEach((k) => {
      rs[k] = [];
      const packsByQuantity = mapByQuantity(packByKeys[k]);
      packsByQuantity.forEach((packByQuantity) => {
        rs[k].push({
          id: packByQuantity.id,
          name: packByQuantity.name
        });
        rsQuantity.push({
          id: packByQuantity.id,
          quantityInput: packByQuantity.quantityInput,
          quantity: packByQuantity.quantity,
          quantityLeft: packByQuantity.quantity - packByQuantity.quantityInput,
          isCheck: packByQuantity.isCheck
        });
        dataForm[`${packByQuantity.id}+${dropId}`] = packByQuantity.quantityInput;
      });
    });
    setPacksForm(rs);
    setCheckPacks(rsQuantity);
    formSelectPack.setFieldsValue(dataForm);
    // eslint-disable-next-line
  }, [dropId, formSelectPack, packages]);

  const onChangeInput = (changedValues, allValues) => {
    const value = Object.values(changedValues)[0];
    const newCheckPacks = [...checkPacks];
    const index = newCheckPacks.findIndex(
      (newP) => newP.id === Object.keys(changedValues)[0].split("+")[0]
    );
    newCheckPacks[index].quantityInput = Number(value);
    newCheckPacks[index].quantityLeft = newCheckPacks[index].quantity - Number(value);
    setCheckPacks(newCheckPacks);
  };

  const onChangeCheck = (e, packId) => {
    // setCheckPacks(packId);
    const newCheckPacks = [...checkPacks];
    const index = newCheckPacks.findIndex((newP) => newP.id === packId);
    newCheckPacks[index].isCheck = e.target.checked;
    if (!e.target.checked) {
      const quantityInput = newCheckPacks[index].quantityInput;
      newCheckPacks[index].quantityLeft =
        newCheckPacks[index].quantity + Number(quantityInput);
      newCheckPacks[index].quantityInput = 0;
      const dataForm = {};
      dataForm[`${newCheckPacks[index].id}+${dropId}`] = 0;
      formSelectPack.setFieldsValue(dataForm);
    }
    setCheckPacks(newCheckPacks);
  };

  const checkPack = (id) => {
    return checkPacks.find((p) => p.id === id);
  };

  const onFinish = (values) => {
    const fields = {
      packs: checkPacks.map((pack) => {
        return {
          id: pack.id,
          value: Number(pack.quantityInput)
        };
      }),
      dropId
    };
    onOk && onOk(fields);
  };
  console.log("packsForm", packsForm);
  return (
    <div className="SelectPack">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        form={formSelectPack}
        onValuesChange={onChangeInput}
        hideRequiredMark
        colon={false}
        onFinish={onFinish}
      >
        {Object.keys(packsForm).map((key) => {
          return (
            <div className="packItem" key={key}>
              <b>{key} Package</b>
              {packsForm[key].map((pack, index) => {
                const checkPackData = checkPack(pack.id);
                return (
                  <div className="item" key={pack.id}>
                    <Checkbox
                      onChange={(e) => onChangeCheck(e, pack.id)}
                      checked={checkPackData.isCheck}
                    >
                      {index + 1}. {pack.name} x {checkPackData.quantity}
                    </Checkbox>
                    {checkPackData.isCheck && (
                      <div className="item-form">
                        <Form.Item
                          label="Quantity"
                          rules={[
                            { required: true, message: "Required" },
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject();
                                }
                                if (Number(value) < 1) {
                                  return Promise.reject("Invalid data");
                                }
                                if (Number(value) > checkPackData.quantity) {
                                  return Promise.reject("Invalid data");
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                          name={`${pack.id}+${dropId}`}
                        >
                          <Input
                            size="large"
                            type="number"
                            min={1}
                            suffix={`/${checkPackData.quantity}`}
                          />
                        </Form.Item>
                        <div className="extra">
                          <span>
                            {checkPackData.quantity - checkPackData.quantityInput} left
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </Form>
    </div>
  );
};

export default SelectPack;
