import React, { useEffect, useState } from "react";
import { Form, Radio, Space, Divider, Switch, Input, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { canCreatePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";

const NOTIFICATION_BY = {
  SMS: "SMS",
  EMAIL: "EMAIL",
  SMS_AND_EMAIL: "SMS_AND_EMAIL"
};

const NotificationSettingsForm = ({ isFetching, setting, handleSubmit }) => {
  const [form] = Form.useForm();

  const [copied, setCopied] = useState(false);
  const [enableNotifyStartJob, setEnableNotifyStartJob] = useState(false);
  const [enableNotifyArrival, setEnableNotifyArrival] = useState(false);
  const [enableNotifyStopCompletion, setEnableNotifyStopCompletion] = useState(false);
  const [enableNotifyGoingNext, setEnableNotifyGoingNext] = useState(false);
  const [startJobNotificationContent, setStartJobNotificationContent] = useState("");
  const [arrivalNotificationContent, setArrivalNotificationContent] = useState("");
  const [stopCompletionNotificationContent, setStopCompletionNotificationContent] =
    useState("");
  const [goingNextNotificationContent, setGoingNextNotificationContent] = useState("");
  const role = useSelector(selectRole);
  const canCreate = allowPermission(role, canCreatePermission);
  const canEdit = allowPermission(role, canEditPermission);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  useEffect(() => {
    if (setting) {
      form.setFieldsValue({
        ...setting,
        notificationBy:
          setting.enableSendEmail && setting.enableSendSMS
            ? NOTIFICATION_BY.SMS_AND_EMAIL
            : setting.enableSendEmail
            ? NOTIFICATION_BY.EMAIL
            : setting?.enableSendSMS
            ? NOTIFICATION_BY.SMS
            : ""
      });
      setEnableNotifyStartJob(setting.enableNotifyStartJob);
      setEnableNotifyArrival(setting.enableNotifyArrival);
      setEnableNotifyStopCompletion(setting.enableNotifyStopCompletion);
      setEnableNotifyGoingNext(setting.enableNotifyGoingNext);
      setStartJobNotificationContent(setting.startJobNotificationContent);
      setArrivalNotificationContent(setting.arrivalNotificationContent);
      setStopCompletionNotificationContent(setting.stopCompletionNotificationContent);
      setGoingNextNotificationContent(setting.goingNextNotificationContent);
    }
    // eslint-disable-next-line
  }, [setting]);

  const handleOnFinish = async (values) => {
    const params = { ...values };
    params.id = setting?.id;
    params.enableNotifyStartJob = enableNotifyStartJob;
    params.enableNotifyArrival = enableNotifyArrival;
    params.enableNotifyStopCompletion = enableNotifyStopCompletion;
    params.enableNotifyGoingNext = enableNotifyGoingNext;
    params.enableSendEmail =
      params.notificationBy === NOTIFICATION_BY.EMAIL ||
      params.notificationBy === NOTIFICATION_BY.SMS_AND_EMAIL;
    params.enableSendSMS =
      params.notificationBy === NOTIFICATION_BY.SMS ||
      params.notificationBy === NOTIFICATION_BY.SMS_AND_EMAIL;
    handleSubmit(params);
  };

  const previewMessage = (message) => {
    let preview = message;
    setting?.notificationFields &&
      Object.keys(setting?.notificationFields).forEach((field) => {
        preview = preview?.replaceAll(`{${field}}`, setting.notificationFields[field]);
      });
    return preview;
  };

  const showActionButton = canCreate || (canEdit && setting?.id);
  return (
    <div className="common-form notification-setting-form">
      <h3>Notification Settings</h3>
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleOnFinish}
      >
        <Form.Item
          label="How will your customers receive notifications?"
          name="notificationBy"
          rules={[{ required: true, message: "Required" }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={NOTIFICATION_BY.SMS}>Text Message</Radio>
              <Radio value={NOTIFICATION_BY.EMAIL}>Email</Radio>
              <Radio value={NOTIFICATION_BY.SMS_AND_EMAIL}>Text Message & Email</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Divider />
        <label>What does the notification message say?</label>
        <div className="custom-fields">
          <div className="title">
            These notification fields are available <i>(Click to copy)</i>{" "}
            {copied ? <span className="copied"> Copied </span> : null}
          </div>
          <div className="fields">
            {setting?.notificationFields &&
              Object.keys(setting?.notificationFields).map((field) => (
                <CopyToClipboard
                  key={field}
                  text={`{${field}}`}
                  onCopy={() => setCopied(true)}
                >
                  <div className="filed">{field}</div>
                </CopyToClipboard>
              ))}
          </div>
        </div>
        <div className="notification-messages">
          <div className="item pickup-noti-messages">
            <Form.Item>
              <Switch
                checked={enableNotifyStartJob}
                onChange={(checked) => setEnableNotifyStartJob(checked)}
              />{" "}
              Pickup Arrival
            </Form.Item>
            <div className="desc">
              When the driver hits “Arrive” at Pickup location, a message is sent to all
              the Delivery stops main contact.
            </div>
            <Form.Item
              name="startJobNotificationContent"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) => setStartJobNotificationContent(e.target.value)}
              />
            </Form.Item>
            <div className="desc preview">Preview on mobile:</div>
            <div className="preview-content">
              {previewMessage(startJobNotificationContent)}
            </div>
          </div>
          <Divider />
          <div className="dropoff-noti-messages">
            <div className="item">
              <Form.Item>
                <Switch
                  checked={enableNotifyGoingNext}
                  onChange={(checked) => setEnableNotifyGoingNext(checked)}
                />{" "}
                Drop-off Going Next
              </Form.Item>
              <div className="desc">
                When the driver hits “Going Next” to indicate that he is going next drop
                off stop to delivery customer’s order.`
              </div>
              <Form.Item
                name="goingNextNotificationContent"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input.TextArea
                  rows={4}
                  onChange={(e) => setGoingNextNotificationContent(e.target.value)}
                />
              </Form.Item>
              <div className="desc preview">Preview on mobile:</div>
              <div className="preview-content">
                {previewMessage(goingNextNotificationContent)}
              </div>
            </div>
            <Divider type="vertical" />
            <div className="item">
              <Form.Item>
                <Switch
                  checked={enableNotifyArrival}
                  onChange={(checked) => setEnableNotifyArrival(checked)}
                />{" "}
                Drop-off Arrival
              </Form.Item>
              <div className="desc">
                When the driver hits “Arrive” to indicate that he is arrived to your
                customer’s location.
              </div>
              <Form.Item
                name="arrivalNotificationContent"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input.TextArea
                  rows={4}
                  onChange={(e) => setArrivalNotificationContent(e.target.value)}
                />
              </Form.Item>
              <div className="desc preview">Preview on mobile:</div>
              <div className="preview-content">
                {previewMessage(arrivalNotificationContent)}
              </div>
            </div>
            <Divider type="vertical" />
            <div className="item">
              <Form.Item>
                <Switch
                  checked={enableNotifyStopCompletion}
                  onChange={(checked) => setEnableNotifyStopCompletion(checked)}
                />{" "}
                Drop-off Completion
              </Form.Item>
              <div className="desc">
                When the driver hits “Complete” to indicate that he has completed the
                customer’s order.`
              </div>
              <Form.Item
                name="stopCompletionNotificationContent"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input.TextArea
                  rows={4}
                  onChange={(e) => setStopCompletionNotificationContent(e.target.value)}
                />
              </Form.Item>
              <div className="desc preview">Preview on mobile:</div>
              <div className="preview-content">
                {previewMessage(stopCompletionNotificationContent)}
              </div>
            </div>
          </div>
        </div>
        {showActionButton && (
          <>
            <Divider />
            <div className="form-footer">
              <Button loading={isFetching} type="primary" htmlType="submit">
                {setting?.id ? "Update" : "Create"}
                <CheckCircleOutlined />
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default NotificationSettingsForm;
