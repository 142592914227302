import { Col, Divider, Image, Row, Steps, Tag } from "antd";
import {
  DATETIME_FORMAT_FRONTEND,
  TIME_FORMAT_BACKEND,
  TIME_FORMAT_FRONTEND
} from "configs/constants";
import { showInfo } from "helper/util";
import { replaceElementInArray, showDate } from "helper/util";
import { random } from "lodash";
import MapBoxJobDetail from "modules/shared/PickupDropoff/mapbox/MapBoxJobDetail";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { sumBy } from "lodash";
import OrdersHeader from "../components/header/OrdersHeader";
import { selectJobOrder } from "../store/orderSlice";
import { doGetJobOrder } from "../store/thunks";
import "./JobDetail.scss";

const JobDetail = ({ isAdmin }) => {
  const defaultCenter = {
    lat: 10.7836664,
    lng: 106.6963872
  };

  const [markers, setMarkers] = useState([]);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const job = useSelector(selectJobOrder);
  const [pickup, setPickup] = useState({});
  const [routePickup, setRoutePickup] = useState({});
  const [dropoff, setDropoff] = useState({});
  const [routeDropoff, setRouteDropoff] = useState({});
  const [pickupBarcodes, setPickupBarcodes] = useState([]);
  const [dropoffBarcodes, setDropoffBarcodes] = useState([]);
  const params = useParams();
  const [packsByType, setPackByType] = useState({});
  const createdAt = pickup?.createdAt
    ? moment(pickup?.createdAt).format(DATETIME_FORMAT_FRONTEND)
    : "";
  const packages = job?.packages || [];
  const routesUri = isAdmin ? "/admin/routes" : "/routes";
  const breadCrumbs = [
    { text: "ACTIVE ROUTES", url: routesUri },
    { text: params.id?.substr(0, 8).toUpperCase(), url: `${routesUri}/${params.id}` },
    { text: params.eid?.substr(0, 8).toUpperCase() }
  ];

  useEffect(() => {
    if (params.eid) {
      dispatch(doGetJobOrder(params.eid));
    }
  }, [dispatch, params]);

  const handleDataMapbox = (jobParams) => {
    if (jobParams?.routeLocations) {
      const allowKeys = ["arrivedAt", "completedAt"];
      let tmpMarkers = [];
      jobParams.routeLocations.map((routeLocation) => {
        tmpMarkers = [
          ...tmpMarkers,
          {
            id: random(1000000),
            lat: Number(routeLocation.location.lat),
            lng: Number(routeLocation.location.lng),
            type: `delivery_${routeLocation.location.type}`
          }
        ];
        const groupStatusDate = routeLocation?.groupStatusDate;
        const keysStatus = Object.keys(groupStatusDate);
        keysStatus.map((key) => {
          if (allowKeys.includes(key)) {
            tmpMarkers = [
              ...tmpMarkers,
              {
                id: random(1000000),
                lat: Number(groupStatusDate[key].lat),
                lng: Number(groupStatusDate[key].lng),
                type: `${key}_${routeLocation.location.type}`
              }
            ];
          }
        });
      });
      setMarkers(tmpMarkers);
    }
  };

  useEffect(() => {
    if (job) {
      const stepJob = {
        unassigned: 0,
        assigned: 1,
        pickup_arrived: 2,
        collected: 3,
        dropoff_arrived: 4,
        completed: 5,
        failed: 6
      };
      const locations = job.locations || [];
      const routes = job.routeLocations || [];
      setPickup(locations.find((location) => location.type === "pickup") || {});
      setRoutePickup(routes.find((route) => route.location.type === "pickup") || {});
      setDropoff(locations.find((location) => location.type === "dropoff") || {});
      setRouteDropoff(routes.find((route) => route.location.type === "dropoff") || {});
      const previousStep = Object.keys(job.groupStatusDate || {}).length;
      setStep(stepJob[job?.status] || previousStep - 1);
      handleDataMapbox(job);
    }
  }, [job]);

  useEffect(() => {
    const rs = {};
    const pickupBarcodesT = [];
    const dropoffBarcodesT = [];
    job?.packages?.forEach((pack) => {
      rs[pack?.packageType?.name] = rs[pack?.packageType?.name] || [];
      rs[pack?.packageType?.name].push(pack);
    });
    if (job?.routeLocations?.length > 0) {
      job?.routeLocations.forEach((routeLocation) => {
        if (routeLocation?.routeLocationPackages?.length > 0) {
          routeLocation?.routeLocationPackages.forEach((locationPackage) => {
            if (locationPackage?.package?.packageType?.name) {
              const tmpPackages = rs[locationPackage?.package?.packageType?.name] || [];
              const findPack = tmpPackages.find(
                (p) => p.id === locationPackage?.package?.id
              );
              if (findPack) {
                const newPackages = replaceElementInArray(tmpPackages, {
                  ...findPack,
                  [`${routeLocation?.location?.type}Quantity`]: locationPackage?.quantity,
                  validationMethod: locationPackage?.validationMethod
                });
                if (
                  routeLocation?.location?.type === "pickup" &&
                  locationPackage?.validationMethod === "scan"
                ) {
                  pickupBarcodesT.push({
                    id: findPack?.id,
                    name: findPack?.name,
                    quantity: findPack?.quantity,
                    scanned: locationPackage?.quantity
                  });
                }
                if (
                  routeLocation?.location?.type === "dropoff" &&
                  locationPackage?.validationMethod === "scan"
                ) {
                  dropoffBarcodesT.push({
                    id: findPack?.id,
                    name: findPack?.name,
                    quantity: findPack?.quantity,
                    scanned: locationPackage?.quantity
                  });
                }
                rs[locationPackage?.package?.packageType?.name] = newPackages;
              }
            }
          });
        }
      });
    }
    setPickupBarcodes(pickupBarcodesT);
    setDropoffBarcodes(dropoffBarcodesT);
    setPackByType(rs);
  }, [job]);

  const { Step } = Steps;
  return (
    <section className="modules active-orders pages job-detail">
      <OrdersHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <Row gutter={25} className="wrapper">
        <Col span="4">
          <div className="left steps-job">
            <Steps progressDot current={step} direction="vertical">
              <Step
                title={<Tag className="unassigned">Unassigned</Tag>}
                description={showDate(job?.groupStatusDate?.unassignedAt)}
              />
              <Step
                title={<Tag className="assigned">Assigned</Tag>}
                description={showDate(job?.groupStatusDate?.assignedAt)}
              />
              <Step
                title={<Tag className="assigned">Arrived at Pickup Location</Tag>}
                description={showDate(job?.groupStatusDate?.pickupArriveAt)}
              />
              <Step
                title={<Tag className="collected">Collected</Tag>}
                description={showDate(job?.groupStatusDate?.collectedAt)}
              />
              <Step
                title={<Tag className="assigned">Arrived at Dropoff Location</Tag>}
                description={showDate(job?.groupStatusDate?.dropoffArriveAt)}
              />
              <Step
                title={<Tag className="completed">Completed</Tag>}
                description={showDate(job?.groupStatusDate?.completedAt)}
              />
              <Step
                title={<Tag className="failed">Failed</Tag>}
                description={showDate(job?.groupStatusDate?.failedAt)}
              />
            </Steps>
          </div>
        </Col>
        <Col span="20">
          <div className="right">
            <div className="right-block addresses">
              <Row>
                <Col span="12">
                  <div className="address-info">
                    <div className="header">
                      <h3>PICKUP LOCATION</h3>
                    </div>
                    <div className="info address">
                      <label>Address</label>
                      {pickup.locationName && <p>{pickup.locationName}</p>}
                      <p>{pickup.address}</p>
                    </div>
                    <div className="info">
                      <label>Sender</label>
                      <p>{pickup.contactName}</p>
                      <p>{pickup.email}m</p>
                      {pickup.phoneList?.map((phone, i) => (
                        <p style={{ textTransform: "capitalize" }} key={i}>
                          {phone.type}: {phone.phone}
                        </p>
                      ))}
                    </div>
                    <div className="info">
                      <label>Pickup Time Window</label>
                      <p>
                        {pickup.start
                          ? moment(pickup.start, TIME_FORMAT_BACKEND).format(
                              TIME_FORMAT_FRONTEND
                            )
                          : ""}{" "}
                        -{" "}
                        {pickup.end
                          ? moment(pickup.end, TIME_FORMAT_BACKEND).format(
                              TIME_FORMAT_FRONTEND
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="info">
                      <label>Est. Waiting/Service Time</label>
                      <p>{pickup.duration} minutes</p>
                    </div>
                    <div className="info">
                      <label>Note</label>
                      <p>{showInfo(pickup.note)}</p>
                    </div>
                    <div className="info">
                      <label>Image/s</label>
                      <div className="images">
                        {pickup.attachments?.map((image) => (
                          <div className="image" key={image.id}>
                            <img src={image.url} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="info">
                      <label>Internal Route Id</label>
                      <p>{showInfo(pickup?.internalRouteId)}</p>
                    </div>
                    <div className="info">
                      <label>Internal Order Id</label>
                      <p>{showInfo(pickup?.internalOrderId)}</p>
                    </div>
                    <div className="info">
                      <label>Internal Customer Id</label>
                      <p>{showInfo(pickup?.internalCustomerId)}</p>
                    </div>
                    <div className="info">
                      <label>Salesperson</label>
                      <p>{showInfo(pickup?.salesPerson)}</p>
                    </div>
                    <div className="info">
                      <label>COD</label>
                      <p>{showInfo(pickup?.cod)}</p>
                    </div>
                    <div className="info">
                      <label>Failed Reason</label>
                      <p>{showInfo(routePickup?.groupStatusDate?.failedAt?.note)}</p>
                    </div>
                  </div>
                </Col>
                <Col span="12">
                  <div className="address-info">
                    <div className="header">
                      <h3>DROP-OFF LOCATION</h3>
                    </div>
                    <div className="info address">
                      <label>Address</label>
                      {dropoff.locationName && <p>{dropoff?.locationName} </p>}
                      <p>{dropoff.address}</p>
                    </div>
                    <div className="info">
                      <label>Sender</label>
                      <p>{dropoff.contactName}</p>
                      <p>{dropoff.email}</p>
                      {dropoff.phoneList?.map((phone, i) => (
                        <p style={{ textTransform: "capitalize" }} key={i + "drop"}>
                          {phone.type}: {phone.phone}
                        </p>
                      ))}
                    </div>
                    <div className="info">
                      <label>Drop-Off Time Window</label>
                      <p>
                        {dropoff.start
                          ? moment(dropoff.start, TIME_FORMAT_BACKEND).format(
                              TIME_FORMAT_FRONTEND
                            )
                          : ""}{" "}
                        -{" "}
                        {dropoff.end
                          ? moment(dropoff.end, TIME_FORMAT_BACKEND).format(
                              TIME_FORMAT_FRONTEND
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="info">
                      <label>Est. Waiting/Service Time</label>
                      <p>{dropoff.duration} minutes</p>
                    </div>
                    <div className="info">
                      <label>Note</label>
                      <p>{showInfo(dropoff.note)}</p>
                    </div>
                    <div className="info">
                      <label>Image/s</label>
                      <div className="images">
                        {dropoff.attachments?.map((image) => (
                          <div className="image" key={image.id}>
                            <img src={image.url} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="info">
                      <label>Internal Route Id</label>
                      <p>{showInfo(dropoff?.internalRouteId)}</p>
                    </div>
                    <div className="info">
                      <label>Internal Order Id</label>
                      <p>{showInfo(dropoff?.internalOrderId)}</p>
                    </div>
                    <div className="info">
                      <label>Internal Customer Id</label>
                      <p>{showInfo(dropoff?.internalCustomerId)}</p>
                    </div>
                    <div className="info">
                      <label>Salesperson</label>
                      <p>{showInfo(dropoff?.salesPerson)}</p>
                    </div>
                    <div className="info">
                      <label>COD</label>
                      <p>{showInfo(dropoff?.cod)}</p>
                    </div>
                    <div className="info">
                      <label>Failed Reason</label>
                      <p>{showInfo(routeDropoff?.groupStatusDate?.failedAt?.note)}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="right-block packages-block">
              <div className="header">
                <h3>PACKAGE LIST</h3>
                <div className="count">{packages?.length || 0}</div>
              </div>
              <div className="content">
                <div className="packages-by-type">
                  {Object.keys(packsByType).length > 0 &&
                    Object.keys(packsByType).map((key, i) => (
                      <div key={key}>
                        <div className="packages-by-type">
                          <div className="type">
                            <b style={{ textTransform: "capitalize" }}>{key} Package</b>
                          </div>
                          {packsByType[key].map((pack, iP) => (
                            <React.Fragment key={iP}>
                              <div className="package" key={pack.id}>
                                {iP + 1}. {pack.name} x {pack.quantity}{" "}
                                {pack?.barcode ? `(${pack?.barcode})` : ""}
                              </div>
                              <ul>
                                <li>
                                  {" "}
                                  {`Pickup: ${pack?.pickupQuantity || 0}/ ${
                                    pack.quantity
                                  } (${showInfo(pack?.validationMethod)})`}{" "}
                                </li>
                                <li>
                                  {`Dropoff: ${pack?.dropoffQuantity || 0}/ ${
                                    pack.quantity
                                  } (${showInfo(pack?.validationMethod)})`}{" "}
                                </li>
                              </ul>
                            </React.Fragment>
                          ))}
                        </div>
                        {i !== Object.keys(packsByType).length - 1 && <Divider />}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div style={{ width: "100%" }}>
                <MapBoxJobDetail
                  height={544}
                  stops={markers}
                  width="100%"
                  center={defaultCenter}
                />
              </div>
            </div>
            <div className="right-block route-block">
              <Row gutter={25}>
                <Col span="12">
                  <div className="item pod">
                    <div className="header">
                      <h3>POP {routePickup.proofs2?.length > 0 && "1"}</h3>
                    </div>
                    <div className="content">
                      {routePickup.proofs?.length > 0 ? (
                        routePickup.proofs.map((proof) => (
                          <Image key={proof?.id} width={100} src={proof?.url} />
                        ))
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  {routePickup.proofs2?.length > 0 && (
                    <div className="item pod">
                      <div className="header">
                        <h3>POP 2</h3>
                      </div>
                      <div className="content">
                        {routePickup.proofs2.map((proof) => (
                          <Image key={proof?.id} width={100} src={proof?.url} />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="item signature">
                    <div className="header">
                      <h3>SIGNATURE {routePickup.signaturePhoto2 && "1"}</h3>
                    </div>
                    <div className="content">
                      {routePickup?.signaturePhoto ? (
                        <>
                          <img src={routePickup.signaturePhoto.url} />
                          <div>
                            Signature Name:{" "}
                            {routePickup?.senderName || "(No name was provided)"}
                          </div>
                        </>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  {routePickup.signaturePhoto2 && (
                    <div className="item signature">
                      <div className="header">
                        <h3>SIGNATURE 2</h3>
                      </div>
                      <div className="content">
                        <img src={routePickup.signaturePhoto2.url} />
                        <div>
                          Signature Name 2:{" "}
                          {routePickup?.senderName || "(No name was provided)"}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="item notes customer-notes">
                    <div className="header">
                      <h3>CUSTOMER NOTES</h3>
                    </div>
                    <div className="content">
                      {routePickup?.location?.note ? (
                        <div>{routePickup?.location?.note}</div>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item document-scanner">
                    <div className="header">
                      <h3>DOCUMENT SCANNER</h3>
                    </div>
                    <div className="content">
                      {routePickup.documentScanners?.length > 0 ? (
                        routePickup.documentScanners.map((d) => (
                          <Image key={d?.id} width={100} src={d?.url} />
                        ))
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item notes driver-notes">
                    <div className="header">
                      <h3>DRIVER NOTES</h3>
                    </div>
                    <div className="content">
                      {routePickup?.driverNotes ? (
                        <div>{routePickup?.driverNotes}</div>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item barcode-scanner">
                    <div className="header">
                      <h3>BARCODE SCANNER</h3>
                    </div>
                    <div className="content">
                      {pickupBarcodes.length > 0 ? (
                        <>
                          <div
                            className={`barcode-scanner-status ${
                              sumBy(pickupBarcodes, "scanned").length > 0
                                ? "scanned"
                                : "pending"
                            }`}
                          >
                            {sumBy(pickupBarcodes, "scanned").length > 0
                              ? "scanned"
                              : "pending"}
                          </div>
                          {pickupBarcodes.map((pack, i) => (
                            <div className="package" key={pack.id}>
                              {pack.name} x {pack.quantity}: {pack.scanned || 0}/
                              {pack.quantity}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span="12">
                  <div className="item pod">
                    <div className="header">
                      <h3>POD {routeDropoff.proofs2?.length > 0 && "1"}</h3>
                    </div>
                    <div className="content">
                      {routeDropoff.proofs?.length > 0 ? (
                        routeDropoff.proofs.map((proof) => (
                          <Image key={proof?.id} width={100} src={proof?.url} />
                        ))
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  {routeDropoff.proofs2?.length > 0 && (
                    <div className="item pod">
                      <div className="header">
                        <h3>POD 2</h3>
                      </div>
                      <div className="content">
                        {routeDropoff.proofs2.map((proof) => (
                          <Image key={proof?.id} width={100} src={proof?.url} />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="item signature">
                    <div className="header">
                      <h3>SIGNATURE {routeDropoff.signaturePhoto2 && "1"}</h3>
                    </div>
                    <div className="content">
                      {routeDropoff?.signaturePhoto ? (
                        <>
                          <img src={routeDropoff.signaturePhoto.url} />
                          <div>
                            Signature Name:{" "}
                            {routeDropoff?.senderName || "(No name was provided)"}
                          </div>
                        </>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  {routeDropoff?.signaturePhoto2 && (
                    <div className="item signature">
                      <div className="header">
                        <h3>SIGNATURE 2</h3>
                      </div>
                      <div className="content">
                        <img src={routeDropoff.signaturePhoto2.url} />
                        <div>
                          Signature Name 2:{" "}
                          {routeDropoff?.senderName2 || "(No name was provided)"}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="item notes customer-notes">
                    <div className="header">
                      <h3>CUSTOMER NOTES</h3>
                    </div>
                    <div className="content">
                      {routeDropoff?.location?.note ? (
                        <div>{routeDropoff?.location?.note}</div>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item document-scanner">
                    <div className="header">
                      <h3>DOCUMENT SCANNER</h3>
                    </div>
                    <div className="content">
                      {routeDropoff.documentScanners?.length > 0 ? (
                        routeDropoff.documentScanners.map((d) => (
                          <Image key={d?.id} width={100} src={d?.url} />
                        ))
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item notes driver-notes">
                    <div className="header">
                      <h3>DRIVER NOTES</h3>
                    </div>
                    <div className="content">
                      {routeDropoff?.driverNotes ? (
                        <div>{routeDropoff?.driverNotes}</div>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item barcode-scanner">
                    <div className="header">
                      <h3>BARCODE SCANNER</h3>
                    </div>
                    <div className="content">
                      {dropoffBarcodes.length > 0 ? (
                        <>
                          <div
                            className={`barcode-scanner-status ${
                              sumBy(dropoffBarcodes, "scanned").length > 0
                                ? "scanned"
                                : "pending"
                            }`}
                          >
                            {sumBy(dropoffBarcodes, "scanned").length > 0
                              ? "scanned"
                              : "pending"}
                          </div>
                          {dropoffBarcodes.map((pack, i) => (
                            <div className="package" key={pack.id}>
                              {pack.name} x {pack.quantity}: {pack.scanned || 0}/
                              {pack.quantity}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                  <div className="item cod-receive">
                    <div className="header">
                      <h3>COD</h3>
                    </div>
                    <div className="content">
                      {routeDropoff?.location?.cod ? (
                        <>
                          <div
                            className={`cod-receive-status ${
                              routeDropoff?.status === "completed"
                                ? "received"
                                : "pending"
                            }`}
                          >
                            {routeDropoff?.status === "completed"
                              ? "received"
                              : "pending"}
                          </div>
                          <div className="cod-receive-value">
                            $ {routeDropoff?.location?.cod}
                          </div>
                        </>
                      ) : (
                        <div className="empty secondary-text">Empty</div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};
export default JobDetail;
