import * as React from "react";
const OptimizeSvg = (props) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={48.5} cy={48} r={48} fill="#00A9D6" />
    <path
      d="M79.84 47.0429C62.52 26.8329 63.79 28.0129 62.92 28.0129H17.83C17.1 28.0129 16.5 28.6029 16.5 29.3429V61.1729C16.5 61.9029 17.09 62.5029 17.83 62.5029H24.59C26.03 69.5429 36.15 69.5429 37.58 62.5029H60.39C61.83 69.5429 71.95 69.5429 73.38 62.5029H78.82C79.55 62.5029 80.15 61.9129 80.15 61.1729C80.15 46.9229 80.26 47.5529 79.83 47.0529L79.84 47.0429ZM75.95 46.5829H61.59V35.9729H66.85L75.94 46.5829H75.95ZM31.09 65.1529C28.9 65.1529 27.11 63.3729 27.11 61.1729C27.11 58.9729 28.89 57.1929 31.09 57.1929C33.29 57.1929 35.07 58.9729 35.07 61.1729C35.07 63.3729 33.29 65.1529 31.09 65.1529ZM66.9 65.1529C64.71 65.1529 62.92 63.3729 62.92 61.1729C62.92 58.9729 64.7 57.1929 66.9 57.1929C69.1 57.1929 70.88 58.9729 70.88 61.1729C70.88 63.3729 69.1 65.1529 66.9 65.1529ZM73.39 59.8429C71.95 52.8029 61.83 52.8029 60.4 59.8429H37.59C36.15 52.8029 26.03 52.8029 24.6 59.8429H19.16V57.1929H23.14C24.89 57.1929 24.9 54.5429 23.14 54.5429H19.16V30.6729H45.68V41.2829C45.68 43.0429 48.33 43.0329 48.33 41.2829V30.6729H62.31L64.58 33.3229H60.26C59.53 33.3229 58.93 33.9129 58.93 34.6529V47.9129C58.93 48.6429 59.52 49.2429 60.26 49.2429H77.5V51.8929H74.85C73.09 51.8929 73.1 54.5429 74.85 54.5429H77.5V59.8429H73.39Z"
      fill="white"
    />
    <path
      d="M54.9601 46.582H53.63C51.87 46.582 51.88 49.232 53.63 49.232H54.9601C56.7201 49.232 56.7101 46.582 54.9601 46.582Z"
      fill="white"
    />
    <path
      d="M66.9001 59.8438C65.1501 59.8438 65.1401 62.4937 66.9001 62.4937C68.6601 62.4937 68.6601 59.8438 66.9001 59.8438Z"
      fill="white"
    />
    <path
      d="M31.0901 59.8438C29.3401 59.8438 29.3301 62.4937 31.0901 62.4937C32.8501 62.4937 32.8501 59.8438 31.0901 59.8438Z"
      fill="white"
    />
    <path
      d="M39.05 34.6426H25.79C25.06 34.6426 24.46 35.2326 24.46 35.9726V49.2326C24.46 49.9626 25.05 50.5626 25.79 50.5626H39.05C39.78 50.5626 40.38 49.9726 40.38 49.2326V35.9726C40.38 35.2426 39.79 34.6426 39.05 34.6426ZM37.72 47.9126H27.11V37.3026H29.76V38.6326C29.76 39.3626 30.35 39.9626 31.09 39.9626H33.74C34.47 39.9626 35.07 39.3726 35.07 38.6326V37.3026H37.72V47.9126Z"
      fill="white"
    />
  </svg>
);
export default OptimizeSvg;
