import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  doCreatePackageType,
  doDeletePackageType,
  doGetPackageType,
  getPackageTypeList,
  getAllPackageType
} from "./PackageTypeThunks";

const PackageTypeAdapter = createEntityAdapter({
  selectId: (packageType) => packageType.id
});

const initialState = PackageTypeAdapter.getInitialState({
  metadata: {},
  packageType: {}
});

export const packageTypeSlice = createSlice({
  name: "packageTypes",
  initialState,
  reducers: {
    updatePaginationPackageTypes(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPackageType.fulfilled, (state, { payload }) => {
      PackageTypeAdapter.setAll(state, payload);
      state.packageType = {};
      state.metadata = {};
    });
    builder.addCase(getPackageTypeList.fulfilled, (state, { payload }) => {
      PackageTypeAdapter.setAll(state, payload.data);
      state.packageType = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetPackageType.fulfilled, (state, { payload }) => {
      state.packageType = payload;
    });
    builder.addCase(doCreatePackageType.fulfilled, (state, { payload }) => {
      PackageTypeAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDeletePackageType.fulfilled, (state, { payload }) => {
      PackageTypeAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectPackageTypeById,
  selectIds: selectPackageTypeIds,
  selectEntities: selectPackageTypeEntities,
  selectAll: selectAllPackageTypes,
  selectTotal: selectTotalPackageTypes
} = PackageTypeAdapter.getSelectors((state) => state.packageTypes);

export const selectMetadata = (state) => state.packageTypes.metadata;
export const selectPackageType = (state) => state.packageTypes.packageType;

export const { updatePaginationPackageTypes } = packageTypeSlice.actions;

export default packageTypeSlice.reducer;
