import React from "react";
import { useForm } from "react-hook-form";
import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

import { InputHook, SelectHook, RangePickerHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";

import "modules/client/routes/components/header/FilterRoute.scss";
import { ORDERV2_STATUS_SELECT } from "modules/client/routes/constants";

const FilterFlexioPool = ({ defaultValues, setShowFilter, onReset, onFilterChange }) => {
  const {
    reset,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });
  const [messageApi, contextHolder] = message.useMessage();

  const submitForm = async (values) => {
    onFilterChange(values);
  };

  return (
    <div className="filter-order-component">
      {contextHolder}
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter">
          <InputHook
            control={control}
            placeholder="Package ID"
            label="Package ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Order ID"
            label="Order ID"
            name="orderId"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <RangePickerHook
            control={control}
            getValues={getValues}
            label="Pickup Date Range"
            name="pickupDateRange"
            errors={errors}
            showOptionsName={false}
          />
          <SelectHook
            mode="multiple"
            allowClear
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Status"
            name="status"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={ORDERV2_STATUS_SELECT}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
        <div className="wrap-button">
          <Button
            type="link"
            className="collapse"
            onClick={() => setShowFilter && setShowFilter(false)}
          >
            Collapse <UpOutlined />
          </Button>
          <Button
            type="link"
            className="svg-icon clear"
            onClick={() => {
              reset();
              onReset();
            }}
          >
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FilterFlexioPool;
