export const PUERTO_RICO_STATES_AND_CITIES = [
  {
    state: "Adjuntas",
    cities: [
      "Barrio Pueblo",
      "Capáez",
      "Garzas",
      "Guayabo Dulce",
      "Guayo",
      "Guilarte",
      "Juan González",
      "Limaní",
      "Pellejas",
      "Portillo",
      "Portugués",
      "Saltillo",
      "Tanamá",
      "Vegas Abajo",
      "Vegas Arriba",
      "Yahuecas",
      "Yayales"
    ]
  },
  {
    state: "Aguada",
    cities: [
      "Asomante",
      "Atalaya",
      "Barrio Pueblo",
      "Carrizal",
      "Cerro Gordo",
      "Cruces",
      "Espinar",
      "Guanábano",
      "Guaniquilla",
      "Guayabo",
      "Jagüey",
      "Lagunas",
      "Mal Paso",
      "Mamey",
      "Marías",
      "Naranjo",
      "Piedras Blancas",
      "Río Grande"
    ]
  },
  {
    state: "Aguadilla",
    cities: [
      "Aguacate",
      "Arenales",
      "Barrio Pueblo",
      "Borinquen",
      "Caimital Alto",
      "Caimital Bajo",
      "Camaceyes",
      "Ceiba Alta",
      "Ceiba Baja",
      "Corrales",
      "Guerrero",
      "Maleza Alta",
      "Maleza Baja",
      "Montaña",
      "Palmar",
      "Victoria"
    ]
  },
  {
    state: "Aguas Buenas",
    cities: [
      "Bairoa",
      "Barrio Pueblo",
      "Bayamoncito",
      "Cagüitas",
      "Jagüeyes",
      "Juan Asencio",
      "Mula",
      "Mulita",
      "Sonadora",
      "Sumidero"
    ]
  },
  {
    state: "Aibonito",
    cities: [
      "Algarrobo",
      "Asomante",
      "Barrio Pueblo",
      "Caonillas",
      "Cuyón",
      "Llanos",
      "Pasto",
      "Plata",
      "Robles"
    ]
  },
  {
    state: "Añasco",
    cities: [
      "Añasco Abajo",
      "Añasco Arriba",
      "Barrio Pueblo",
      "Caguabo",
      "Caracol",
      "Carreras",
      "Casey Abajo",
      "Casey Arriba",
      "Cerro Gordo",
      "Cidra",
      "Corcovada",
      "Dagüey",
      "Espino",
      "Hatillo",
      "Humatas",
      "Marías",
      "Miraflores",
      "Ovejas",
      "Piñales",
      "Playa",
      "Quebrada Larga",
      "Río Arriba",
      "Río Cañas"
    ]
  },
  {
    state: "Arecibo",
    cities: [
      "Arenalejos",
      "Arrozal",
      "Barrio Pueblo",
      "Cambalache",
      "Carreras",
      "Domingo Ruíz",
      "Dominguito",
      "Esperanza",
      "Factor",
      "Garrochales",
      "Hato Abajo",
      "Hato Arriba",
      "Hato Viejo",
      "Islote",
      "Miraflores",
      "Río Arriba",
      "Sabana Hoyos",
      "Santana",
      "Tanamá"
    ]
  },
  {
    state: "Arroyo",
    cities: ["Ancones", "Barrio Pueblo", "Guásimas", "Palmas", "Pitahaya", "Yaurel"]
  },
  {
    state: "Barceloneta",
    cities: ["Barrio Pueblo", "Florida Afuera", "Garrochales", "Palmas Altas"]
  },
  {
    state: "Barranquitas",
    cities: [
      "Barrancas",
      "Barrio Pueblo",
      "Cañabón",
      "Helechal",
      "Honduras",
      "Palo Hincado",
      "Quebrada Grande",
      "Quebradillas"
    ]
  },
  {
    state: "Bayamón",
    cities: [
      "Barrio Pueblo",
      "Buena Vista",
      "Cerro Gordo",
      "Dajaos",
      "Guaraguao Abajo",
      "Guaraguao Arriba",
      "Hato Tejas",
      "Juan Sánchez",
      "Minillas",
      "Nuevo",
      "Pájaros",
      "Santa Olaya"
    ]
  },
  {
    state: "Cabo Rojo",
    cities: [
      "Bajura",
      "Barrio Pueblo",
      "Boquerón",
      "Guanajibo",
      "Llanos Costa",
      "Llanos Tuna",
      "Miradero",
      "Monte Grande",
      "Pedernales"
    ]
  },
  {
    state: "Caguas",
    cities: [
      "Bairoa",
      "Barrio Pueblo",
      "Beatriz",
      "Borinquen",
      "Cañabón",
      "Cañaboncito",
      "Río Cañas",
      "San Antonio",
      "San Salvador",
      "Tomás de Castro",
      "Turabo"
    ]
  },
  {
    state: "Camuy",
    cities: [
      "Abra Honda",
      "Barrio Pueblo",
      "Camuy Arriba",
      "Ciénagas",
      "Cibao",
      "Membrillo",
      "Piedra Gorda",
      "Puente",
      "Puertos",
      "Quebrada",
      "Santiago",
      "Yeguada",
      "Zanja"
    ]
  },
  {
    state: "Canóvanas",
    cities: [
      "Barrio Pueblo",
      "Canóvanas",
      "Cubuy",
      "Hato Puerco",
      "Lomas",
      "Torrecilla Alta"
    ]
  },
  {
    state: "Carolina",
    cities: [
      "Barrazas",
      "Barrio Pueblo",
      "Buena Vista",
      "Cacao",
      "Cangrejo Arriba",
      "Canovanillas",
      "Carruzos",
      "Cedro",
      "Martín González",
      "Sabana Abajo",
      "San Antón",
      "Santa Cruz",
      "Trujillo Bajo"
    ]
  },
  {
    state: "Cataño",
    cities: ["Barrio Pueblo", "Palmas"]
  },
  {
    state: "Cayey",
    cities: [
      "Barrio Pueblo",
      "Beatriz",
      "Cedro",
      "Cercadillo",
      "Culebras Alto",
      "Culebras Bajo",
      "Farallón",
      "Guavate",
      "Jájome Alto",
      "Jájome Bajo",
      "Lapa",
      "Matón Abajo",
      "Matón Arriba",
      "Monte Llano",
      "Pasto Viejo",
      "Pedro Avila",
      "Piedras",
      "Quebrada Arriba",
      "Rincón",
      "Sumido",
      "Toíta",
      "Vegas"
    ]
  },
  {
    state: "Ceiba",
    cities: [
      "Barrio Pueblo",
      "Chupacallos",
      "Daguao",
      "Guayacán",
      "Machos",
      "Quebrada Seca",
      "Río Abajo",
      "Saco"
    ]
  },
  {
    state: "Ciales",
    cities: [
      "Barrio Pueblo",
      "Cialitos",
      "Cordillera",
      "Frontón",
      "Hato Viejo",
      "Jaguas",
      "Pesas",
      "Pozas",
      "Toro Negro"
    ]
  },
  {
    state: "Cidra",
    cities: [
      "Arenas",
      "Barrio Pueblo",
      "Bayamón",
      "Beatriz",
      "Ceiba",
      "Certenejas",
      "Honduras",
      "Monte Llano",
      "Rabanal",
      "Río Abajo",
      "Rincón",
      "Salto",
      "Sud",
      "Toíta"
    ]
  },
  {
    state: "Coamo",
    cities: [
      "Barrio Pueblo",
      "Coamo Arriba",
      "Cuyón",
      "Hayales",
      "Los Llanos",
      "Palmarejo",
      "Pasto",
      "Pedro García",
      "Pulguillas",
      "San Ildefonso",
      "Santa Catalina"
    ]
  },
  {
    state: "Comerío",
    cities: [
      "Barrio Pueblo",
      "Cedrito",
      "Cejas",
      "Doña Elena",
      "Naranjo",
      "Palomas",
      "Piñas",
      "Río Hondo",
      "Vega Redonda"
    ]
  },
  {
    state: "Corozal",
    cities: [
      "Abras",
      "Barrio Pueblo",
      "Cibuco",
      "Cuchillas",
      "Dos Bocas",
      "Magueyes",
      "Maná",
      "Negros",
      "Padilla",
      "Palmarejo",
      "Palmarito",
      "Palos Blancos",
      "Pueblo"
    ]
  },
  {
    state: "Culebra",
    cities: [
      "Barrio Pueblo",
      "Flamenco",
      "Fraile",
      "Playa Sardinas I",
      "Playa Sardinas II",
      "San Isidro"
    ]
  },
  {
    state: "Dorado",
    cities: ["Barrio Pueblo", "Espinosa", "Higuillar", "Maguayo", "Mameyal", "Río Lajas"]
  },
  {
    state: "Fajardo",
    cities: [
      "Barrio Pueblo",
      "Cabezas",
      "Demajagua",
      "Florencio",
      "Naranjo",
      "Quebrada Fajardo",
      "Quebrada Vueltas",
      "Río Arriba",
      "Sardinera"
    ]
  },
  {
    state: "Florida",
    cities: ["Florida Adentro"]
  },
  {
    state: "Guánica",
    cities: [
      "Arena",
      "Barrio Pueblo",
      "Caño",
      "Carenero",
      "Ciénaga",
      "Ensenada",
      "Montalva",
      "Susúa Baja"
    ]
  },
  {
    state: "Guayama",
    cities: [
      "Algarrobo",
      "Barrio Pueblo",
      "Caimital",
      "Carite",
      "Carmen",
      "Guamaní",
      "Jobos",
      "Machete",
      "Palmas",
      "Pozo Hondo"
    ]
  },
  {
    state: "Guayanilla",
    cities: [
      "Barrero",
      "Barrio Pueblo",
      "Boca",
      "Cedro",
      "Consejo",
      "Indios",
      "Jagua Pasto",
      "Jaguas",
      "Llano",
      "Macaná",
      "Magas",
      "Pasto",
      "Playa",
      "Quebrada Honda",
      "Quebradas",
      "Rufina",
      "Sierra Baja"
    ]
  },
  {
    state: "Guaynabo",
    cities: [
      "Barrio Pueblo",
      "Camarones",
      "Frailes",
      "Guaraguao",
      "Hato Nuevo",
      "Mamey",
      "Pueblo Viejo",
      "Río",
      "Santa Rosa",
      "Sonadora"
    ]
  },
  {
    state: "Gurabo",
    cities: [
      "Barrio Pueblo",
      "Celada",
      "Hato Nuevo",
      "Jaguar",
      "Jaguas",
      "Mamey",
      "Masa",
      "Navarro",
      "Quebrada Infierno",
      "Rincón"
    ]
  },
  {
    state: "Hatillo",
    cities: [
      "Aibonito",
      "Barrio Pueblo",
      "Bayaney",
      "Buena Vista",
      "Campo Alegre",
      "Capáez",
      "Carrizales",
      "Corcovado",
      "Hatillo",
      "Naranjito"
    ]
  },
  {
    state: "Hormigueros",
    cities: [
      "Barrio Pueblo",
      "Benavente",
      "Guanajibo",
      "Hormigueros",
      "Jaguitas",
      "Lavadero"
    ]
  },
  {
    state: "Humacao",
    cities: [
      "Antón Ruíz",
      "Barrio Pueblo",
      "Buena Vista",
      "Candelero Abajo",
      "Candelero Arriba",
      "Cataño",
      "Collores",
      "Mabú",
      "Mambiche",
      "Mariana",
      "Punta Santiago",
      "Río Abajo",
      "Tejas"
    ]
  },
  {
    state: "Isabela",
    cities: [
      "Arenales Altos",
      "Arenales Bajos",
      "Bajura",
      "Barrio Pueblo",
      "Bejucos",
      "Coto",
      "Galateo Alto",
      "Galateo Bajo",
      "Guayabos",
      "Guerrero",
      "Jobos",
      "Llanadas",
      "Mora",
      "Planas"
    ]
  },
  {
    state: "Jayuya",
    cities: [
      "Barrio Pueblo",
      "Coabey",
      "Collores",
      "Jauca",
      "Jayuya Abajo",
      "Mameyes Arriba",
      "Pica",
      "Río Grande",
      "Saliente",
      "Veguitas",
      "Zamas"
    ]
  },
  {
    state: "Juana Díaz",
    cities: [
      "Amuelas",
      "Barrio Pueblo",
      "Callabo",
      "Capitanejo",
      "Cintrona",
      "Collores",
      "Emajagual",
      "Guayabal",
      "Jacaguas",
      "Lomas",
      "Río Cañas Abajo",
      "Río Cañas Arriba",
      "Sabana Llana",
      "Tijeras"
    ]
  },
  {
    state: "Juncos",
    cities: [
      "Barrio Pueblo",
      "Caimito",
      "Ceiba Norte",
      "Ceiba Sur",
      "Gurabo Abajo",
      "Gurabo Arriba",
      "Lirios",
      "Mamey",
      "Valenciano Abajo",
      "Valenciano Arriba"
    ]
  },
  {
    state: "Lajas",
    cities: [
      "Barrio Pueblo",
      "Candelaria",
      "Costa",
      "Lajas",
      "Lajas Arriba",
      "Llanos",
      "Palmarejo",
      "París",
      "Parguera",
      "Plata",
      "Sabana Yeguas",
      "Santa Rosa"
    ]
  },
  {
    state: "Lares",
    cities: [
      "Barrio Pueblo",
      "Bartolo",
      "Buenos Aires",
      "Callejones",
      "Espino",
      "La Torre",
      "Lares",
      "Mirasol",
      "Pezuela",
      "Piletas",
      "Pueblo",
      "Río Prieto"
    ]
  },
  {
    state: "Las Marías",
    cities: [
      "Alto Sano",
      "Anones",
      "Barrio Pueblo",
      "Bucarabones",
      "Buena Vista",
      "Cerrote",
      "Chamorro",
      "Espino",
      "Furnias",
      "Maravilla Este",
      "Maravilla Norte",
      "Maravilla Sur",
      "Naranjales",
      "Palma Escrita",
      "Purísima Concepción",
      "Río Cañas"
    ]
  },
  {
    state: "Las Piedras",
    cities: [
      "Barrio Pueblo",
      "Boquerón",
      "Ceiba",
      "Collores",
      "El Río",
      "Montones",
      "Quebrada Arenas",
      "Tejas"
    ]
  },
  {
    state: "Loíza",
    cities: [
      "Barrio Pueblo",
      "Canóvanas",
      "Medianía Alta",
      "Medianía Baja",
      "Torrecilla Alta",
      "Torrecilla Baja"
    ]
  },
  {
    state: "Luquillo",
    cities: [
      "Barrio Pueblo",
      "Juan Martín",
      "Mameyes I",
      "Mata de Plátano",
      "Pitahaya",
      "Sabana"
    ]
  },
  {
    state: "Manatí",
    cities: [
      "Bajura Adentro",
      "Bajura Afuera",
      "Barrio Pueblo",
      "Coto Norte",
      "Coto Sur",
      "Río Arriba Poniente",
      "Río Arriba Saliente",
      "Tierras Nuevas Poniente",
      "Tierras Nuevas Saliente"
    ]
  },
  {
    state: "Maricao",
    cities: [
      "Barrio Pueblo",
      "Bucarabones",
      "Indiera Alta",
      "Indiera Baja",
      "Indiera Fría",
      "Maricao Afuera",
      "Montoso"
    ]
  },
  {
    state: "Maunabo",
    cities: [
      "Barrio Pueblo",
      "Calzada",
      "Emajagua",
      "Lizas",
      "Matuyas Alto",
      "Matuyas Bajo",
      "Palo Seco",
      "Quebrada Arenas",
      "Talante",
      "Tumbao"
    ]
  },
  {
    state: "Mayagüez",
    cities: [
      "Algarrobos",
      "Barrio Pueblo",
      "Bateyes",
      "Guanajibo",
      "Isla de Mona e Islote Monito",
      "Isla Desecheo",
      "Juan Alonso",
      "Leguísamo",
      "Limón",
      "Malezas",
      "Mayagüez Arriba",
      "Miradero",
      "Montoso",
      "Naranjales",
      "Quebrada Grande",
      "Quemado",
      "Río Cañas Abajo",
      "Río Cañas Arriba",
      "Río Hondo",
      "Rosario",
      "Sábalos",
      "Sabanetas"
    ]
  },
  {
    state: "Moca",
    cities: [
      "Aceitunas",
      "Barrio Pueblo",
      "Capá",
      "Centro",
      "Cerro Gordo",
      "Cruz",
      "Cuchillas",
      "Marías",
      "Naranjo",
      "Plata",
      "Rocha",
      "Voladoras"
    ]
  },
  {
    state: "Morovis",
    cities: [
      "Barahona",
      "Barrio Pueblo",
      "Cuchillas",
      "Fránquez",
      "Monte Llano",
      "Morovis Norte",
      "Morovis Sud",
      "Pasto",
      "Perchas",
      "Río Grande",
      "San Lorenzo",
      "Torrecillas",
      "Unibón",
      "Vaga"
    ]
  },
  {
    state: "Naguabo",
    cities: [
      "Barrio Pueblo",
      "Daguao",
      "Duque",
      "Húcares",
      "Maizales",
      "Mariana",
      "Peña Pobre",
      "Río",
      "Río Blanco",
      "Santiago y Lima"
    ]
  },
  {
    state: "Naranjito",
    cities: [
      "Achiote",
      "Anones",
      "Barrio Pueblo",
      "Cedro Abajo",
      "Cedro Arriba",
      "Guadiana",
      "Lomas",
      "Nuevo"
    ]
  },
  {
    state: "Orocovis",
    cities: [
      "Ala de la Piedra",
      "Barrio Pueblo",
      "Barros",
      "Bauta Abajo",
      "Bauta Arriba",
      "Bermejales",
      "Botijas",
      "Cacaos",
      "Collores",
      "Damián Abajo",
      "Damián Arriba",
      "Gato",
      "Mata de Cañas",
      "Orocovis",
      "Pellejas",
      "Sabana",
      "Saltos"
    ]
  },
  {
    state: "Patillas",
    cities: [
      "Apeadero",
      "Bajo",
      "Barrio Pueblo",
      "Cacao Alto",
      "Cacao Bajo",
      "Egozcue",
      "Guardarraya",
      "Jacaboa",
      "Jagual",
      "Mamey",
      "Marín",
      "Muñoz Rivera",
      "Mulas",
      "Pollos",
      "Quebrada Arriba",
      "Ríos"
    ]
  },
  {
    state: "Peñuelas",
    cities: [
      "Barreal",
      "Barrio Pueblo",
      "Coto",
      "Cuebas",
      "Encarnación",
      "Jaguas",
      "Macaná",
      "Quebrada Ceiba",
      "Rucio",
      "Santo Domingo",
      "Tallaboa Alta",
      "Tallaboa Poniente",
      "Tallaboa Saliente"
    ]
  },
  {
    state: "Ponce",
    cities: [
      "Anón",
      "Bucaná",
      "Canas",
      "Canas Urbano",
      "Capitanejo",
      "Cerrillos",
      "Coto Laurel",
      "Cuarto",
      "Guaraguao",
      "Machuelo Abajo",
      "Machuelo Arriba",
      "Magueyes",
      "Magueyes Urbano",
      "Maragüez",
      "Marueño",
      "Monte Llano",
      "Playa",
      "Portugués",
      "Portugués Urbano",
      "Primero",
      "Quebrada Limón",
      "Quinto",
      "Real",
      "Sabanetas",
      "San Antón",
      "San Patricio",
      "Segundo",
      "Sexto",
      "Tercero",
      "Tibes",
      "Vayas"
    ]
  },
  {
    state: "Quebradillas",
    cities: [
      "Barrio Pueblo",
      "Cacao",
      "Charcas",
      "Cocos",
      "Guajataca",
      "San Antonio",
      "San José",
      "Terranova"
    ]
  },
  {
    state: "Rincón",
    cities: [
      "Atalaya",
      "Barrero",
      "Barrio Pueblo",
      "Calvache",
      "Cruces",
      "Ensenada",
      "Jagüey",
      "Pueblo",
      "Puntas",
      "Río Grande"
    ]
  },
  {
    state: "Río Grande",
    cities: [
      "Barrio Pueblo",
      "Ciénaga Alta",
      "Ciénaga Baja",
      "Guzmán Abajo",
      "Guzmán Arriba",
      "Herreras",
      "Jiménez",
      "Mameyes 2",
      "Zarzal"
    ]
  },
  {
    state: "Sabana Grande",
    cities: [
      "Barrio Pueblo",
      "Machuchal",
      "Rayo",
      "Rincón",
      "Santana",
      "Susúa",
      "Tabonuco",
      "Torre"
    ]
  },
  {
    state: "Salinas",
    cities: [
      "Aguirre",
      "Barrio Pueblo",
      "Lapa",
      "Palmas",
      "Quebrada Yeguas",
      "Río Jueyes"
    ]
  },
  {
    state: "San Germán",
    cities: [
      "Ancones",
      "Barrio Pueblo",
      "Caín Alto",
      "Caín Bajo",
      "Cotuí",
      "Duey Alto",
      "Duey Bajo",
      "Guamá",
      "Hoconuco Alto",
      "Hoconuco Bajo",
      "Maresúa",
      "Minillas",
      "Retiro",
      "Rosario Alto",
      "Rosario Bajo",
      "Rosario Peñón",
      "Sabana Eneas",
      "Sabana Grande Abajo",
      "Tuna"
    ]
  },
  {
    state: "San Juan",
    cities: [
      "Caimito",
      "Cupey",
      "El Cinco",
      "Gobernador Piñero",
      "Hato Rey Central",
      "Hato Rey Norte",
      "Hato Rey Sur",
      "Monacillo",
      "Monacillo Urbano",
      "Oriente",
      "Pueblo",
      "Quebrada Arenas",
      "Sabana Llana Norte",
      "Sabana Llana Sur",
      "San Juan Antiguo",
      "Santurce",
      "Tortugo",
      "Universidad"
    ]
  },
  {
    state: "San Lorenzo",
    cities: [
      "Barrio Pueblo",
      "Cayaguas",
      "Cerro Gordo",
      "Espino",
      "Florida",
      "Hato",
      "Jagual",
      "Quebrada",
      "Quebrada Arenas",
      "Quebrada Honda",
      "Quemados"
    ]
  },
  {
    state: "San Sebastián",
    cities: [
      "Aibonito",
      "Alto Sano",
      "Bahomamey",
      "Barrio Pueblo",
      "Calabazas",
      "Cibao",
      "Cidral",
      "Culebrinas",
      "Eneas",
      "Guacio",
      "Guajataca",
      "Guatemala",
      "Hato Arriba",
      "Hoya Mala",
      "Juncal",
      "Magos",
      "Mirabales",
      "Perchas I",
      "Perchas II",
      "Piedras Blancas",
      "Pozas",
      "Robles",
      "Salto",
      "Sonador"
    ]
  },
  {
    state: "Santa Isabel",
    cities: [
      "Barrio Pueblo",
      "Boca Velázquez",
      "Descalabrado",
      "Felicia I",
      "Felicia II",
      "Jauca I",
      "Jauca II",
      "Playa"
    ]
  },
  {
    state: "Toa Alta",
    cities: [
      "Barrio Pueblo",
      "Contorno",
      "Galateo",
      "Mucarabones",
      "Ortiz",
      "Piñas",
      "Quebrada Arenas",
      "Quebrada Cruz",
      "Río Lajas"
    ]
  },
  {
    state: "Toa Baja",
    cities: ["Barrio Pueblo", "Candelaria", "Media Luna", "Palo Seco", "Sabana Seca"]
  },
  {
    state: "Trujillo Alto",
    cities: [
      "Barrio Pueblo",
      "Carraízo",
      "Cuevas",
      "Dos Bocas",
      "La Gloria",
      "Quebrada Grande",
      "Quebrada Negrito",
      "St. Just"
    ]
  },
  {
    state: "Utuado",
    cities: [
      "Angeles",
      "Arenas",
      "Barrio Pueblo",
      "Caguana",
      "Caniaco",
      "Caonillas Abajo",
      "Caonillas Arriba",
      "Consejo",
      "Don Alonso",
      "Guaonica",
      "Las Palmas",
      "Limón",
      "Mameyes Abajo",
      "Paso Palma",
      "Río Abajo",
      "Roncador",
      "Sabana Grande",
      "Salto Abajo",
      "Salto Arriba",
      "Santa Isabel",
      "Santa Rosa",
      "Tetuán",
      "Viví Abajo",
      "Viví Arriba"
    ]
  },
  {
    state: "Vega Alta",
    cities: [
      "Bajura",
      "Barrio Pueblo",
      "Candelaria",
      "Cienegueta",
      "Espinosa",
      "Maricao",
      "Mavilla",
      "Sabana"
    ]
  },
  {
    state: "Vega Baja",
    cities: [
      "Algarrobo",
      "Almirante Norte",
      "Almirante Sur",
      "Barrio Pueblo",
      "Cabo Caribe",
      "Ceiba",
      "Cibuco",
      "Puerto Nuevo",
      "Pugnado Adentro",
      "Pugnado Afuera",
      "Quebrada Arenas",
      "Río Abajo",
      "Río Arriba",
      "Yeguada"
    ]
  },
  {
    state: "Vieques",
    cities: [
      "Barrio Pueblo (Isabel II)",
      "Florida",
      "Llave",
      "Mosquito",
      "Puerto Diablo",
      "Puerto Ferro",
      "Puerto Real",
      "Punta Arenas"
    ]
  },
  {
    state: "Villalba",
    cities: [
      "Barrio Pueblo",
      "Caonillas Abajo",
      "Caonillas Arriba",
      "Hato Puerco Abajo",
      "Hato Puerco Arriba",
      "Vacas",
      "Villalba Abajo",
      "Villalba Arriba"
    ]
  },
  {
    state: "Yabucoa",
    cities: [
      "Aguacate",
      "Barrio Pueblo",
      "Calabazas",
      "Camino Nuevo",
      "Guayabota",
      "Jácana",
      "Juan Martín",
      "Limones",
      "Playa",
      "Tejas"
    ]
  },
  {
    state: "Yauco",
    cities: [
      "Aguas Blancas",
      "Algarrobo",
      "Almácigo Alto",
      "Almácigo Bajo",
      "Barina",
      "Barrio Pueblo",
      "Caimito",
      "Collores",
      "Diego Hernández",
      "Duey",
      "Frailes",
      "Jácanas",
      "Naranjo",
      "Quebradas",
      "Ranchera",
      "Río Prieto",
      "Rubias",
      "Sierra Alta",
      "Susúa Alta",
      "Susúa Baja",
      "Vegas"
    ]
  }
];
