import React from "react";
import { Input } from "antd";
import { Controller } from "react-hook-form";
import FormItemHook from "../FormItem/FormItemHook";
const { TextArea } = Input;

const InputPasswordHook = ({
  disabled = false,
  className,
  type,
  placeholder = "",
  control,
  errors,
  name,
  label,
  required = false,
  typeField = "input",
  showLabel,
  showOptionsName,
  ...rest
}) => {
  return (
    <FormItemHook
      name={name}
      label={label}
      required={required}
      errors={errors[name]}
      showLabel={showLabel}
      showOptionsName={showOptionsName}
      className={className}
    >
      {typeField === "input" ? (
        <Controller
          placeholder={placeholder || name}
          control={control}
          name={name}
          render={({ field }) => (
            <Input.Password
              {...field}
              type={type}
              disabled={disabled}
              {...rest}
              placeholder={placeholder || name}
            />
          )}
        />
      ) : (
        <Controller
          placeholder={placeholder || name}
          control={control}
          name={name}
          render={({ field }) => <TextArea {...field} row={4} disabled={disabled} />}
        />
      )}
      {/* {errors[name] && <p className="errors">{errors[name].message}</p>} */}
    </FormItemHook>
  );
};
export { InputPasswordHook };
