import request from "../../../../../helper/request";

const notificationSettingApi = {
  getNotificationSettingList: (params) => {
    return request.get("/notification-settings", {
      params
    });
  },
  createNotificationSetting: (data) => {
    return request.post("/notification-settings", data);
  },
  updateNotificationSetting: (data) => {
    return request.patch(`/notification-settings/${data.id}`, data);
  }
};

export default notificationSettingApi;
