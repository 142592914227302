import React from "react";
import { Link } from "react-router-dom";

import "./HeaderContent.scss";
import UserInfo from "../components/Users/UserInfo";
import { useSelector } from "react-redux";
import { ProfileProvider } from "layouts/private/ProfileContext";

const HeaderContentAdmin = () => {
  const admin = useSelector((state) => state.auth.user);
  return (
    <ProfileProvider>
      <div className="header-content">
        <div className="left">
          <div className="logo">
            <Link to="/admin">
              <img src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`} alt="logo" />
            </Link>
          </div>
          <div className="title">
            <h1>
              <b>FLEXIO ADMIN DASHBOARD</b>
            </h1>
          </div>
        </div>
        <UserInfo isUser={false} user={admin} />
      </div>
    </ProfileProvider>
  );
};
export const HeaderContent = React.memo(HeaderContentAdmin);
