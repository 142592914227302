import { createAsyncThunk } from "@reduxjs/toolkit";
import internalDriverApi from "./api";

export const getInternalDriverList = createAsyncThunk(
  "internal-drivers/get/loading",
  async (data) => {
    return internalDriverApi.getDriverList(data);
  }
);

export const doGetDriver = createAsyncThunk(
  "internal-drivers/getOne/loading",
  async (id) => {
    return internalDriverApi.getDriver(id);
  }
);

export const doCreateInternalDriver = createAsyncThunk(
  "internal-drivers/create/loading",
  async (data) => {
    return internalDriverApi.createInternalDriver(data);
  }
);

export const doUpdateInternalDriver = createAsyncThunk(
  "internal-drivers/update/loading",
  async (data) => {
    return internalDriverApi.updateInternalDriver(data);
  }
);

export const doDeleteInternalDriver = createAsyncThunk(
  "internal-drivers/delete/loading",
  async (id) => {
    return internalDriverApi.delete(id);
  }
);
