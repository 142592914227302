import { Layout } from "antd";
import React, { useContext, useMemo } from "react";
import { HeaderContent } from "./HeaderContent";
import "./MainLayout.scss";
import ProfileInfoBanner from "./ProfileInfoBanner";
import { ProfileContext } from "./ProfileContext";
import MessageMobile from "./MassageMobile";
import ClientSiderContent from "layouts/components/LeftSider/ClientSiderContent";

const { Header, Content } = Layout;

const MainLayout = ({ children, isFullScreen }) => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const missingFields = useMemo(() => {
    const fields = [];
    if (!profile?.client.addressField1) {
      fields.push("Address");
    }
    return fields;
  }, [profile]);

  const shouldShowBanner = useMemo(() => {
    if (!profile) return false;

    return missingFields.length > 0;
  }, [profile, missingFields]);

  return (
    <Layout className={`MainLayout ${isFullScreen && "full-screen"}`}>
      <Header style={headerStyle}>
        <HeaderContent />
      </Header>
      {shouldShowBanner && <ProfileInfoBanner missingFields={missingFields} />}
      <MessageMobile />
      <Content>
        <Layout>
          <ClientSiderContent />
          <div className="main-content-layout">{children}</div>
        </Layout>
      </Content>
    </Layout>
  );
};

const headerStyle = {
  position: "relative",
  zIndex: 999
};
export default MainLayout;
