import "./ActionButtons.scss";
import { Button } from "antd";
import { ArrowForwardIcon, CheckCircleIcon } from "components/svgs";
import React from "react";
const ActionButtons = () => {
  return (
    <div className="action-buttons">
      <Button type="primary" className="svg-icon" htmlType="submit">
        Complete Registration &nbsp;
        <CheckCircleIcon color="#FFFFFF" />
      </Button>
    </div>
  );
};
export default React.memo(ActionButtons);
