import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import _keys from "lodash/keys";
import request from "helper/request";
import LocationsDropdown from "./LocationsDropdown";

const AddPoolPackageForm = ({ submit, packageTypes = [], poolOrder }) => {
  const [form] = Form.useForm();
  const [savedLocations, setSavedLocations] = useState([]);

  const getSavedLocations = useCallback(async () => {
    try {
      const response = await request.get(
        `/saved-locations/all-locations/${poolOrder?.client_id}`
      );
      setSavedLocations(response);
    } catch (error) {
      console.log(error);
    }
  }, [setSavedLocations]);

  useEffect(() => {
    getSavedLocations();
    // eslint-disable-next-line
  }, []);

  const handleSelectDataPickup = useCallback(
    (locationId) => {
      const value = savedLocations.find((location) => location.id === locationId);
      if (value) {
        form.setFieldsValue({
          ...form.getFieldsValue,
          pickupAddress: value.address,
          pickupLat: value.lat,
          pickupLng: value.lng,
          pickupContactName: value.contactName,
          pickupContactEmail: value.contactEmail,
          pickupContactPhone: value.contactPhone,
          pickupStopName: value.locationName
        });
      }
    },
    [savedLocations]
  );

  const handleSelectDataDropoff = useCallback(
    (locationId) => {
      const value = savedLocations.find((location) => location.id === locationId);
      if (value) {
        form.setFieldsValue({
          ...form.getFieldsValue,
          dropoffAddress: value.address,
          dropoffLat: value.lat,
          dropoffLng: value.lng,
          dropoffContactName: value.contactName,
          dropoffContactPhone: value.contactPhone,
          dropoffStopName: value.locationName
        });
      }
    },
    [savedLocations]
  );

  const handleDropoffLocationChange = useCallback((value) => {
    form.setFieldsValue({
      ...form.getFieldsValue,
      dropoffStopName: value
    });
  }, []);

  const handlePickupLocationChange = useCallback((value) => {
    form.setFieldsValue({
      ...form.getFieldsValue,
      pickupStopName: value
    });
  }, []);

  return (
    <Form form={form} layout="vertical" className="mt-4" onFinish={submit}>
      <h2>Add New Package</h2>
      <Row gutter={30} className="mt-1 mb-4">
        <Col md={12}>
          <Form.Item label="Pickup Stop Name" name={["pickupStopName"]}>
            <LocationsDropdown
              name="pickupStopName"
              options={savedLocations}
              handlePickup={handleSelectDataPickup}
              locationName={form.getFieldsValue?.pickupStopName}
              handleNewLocationName={handlePickupLocationChange}
              searchValue={form.getFieldsValue?.pickupStopName}
            />
          </Form.Item>
          <Form.Item
            label="Pickup Address"
            name={["pickupAddress"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Plaza Las Américas, San Juan" />
          </Form.Item>
          <Form.Item
            label="Pickup Contact Name"
            name={["pickupContactName"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>
          <Form.Item
            label="Pickup Contact Email"
            name={["pickupContactEmail"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="test@example.com" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Pickup Latitude" name={["pickupLat"]}>
            <Input placeholder="12.3456" />
          </Form.Item>
          <Form.Item label="Pickup Longitude" name={["pickupLng"]}>
            <Input placeholder="12.3456" />
          </Form.Item>
          <Form.Item
            label="Pickup Contact Phone"
            name={["pickupContactPhone"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="(787) 344-5844" />
          </Form.Item>
          <Form.Item
            label="Pickup Date"
            name={["pickupDate"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} className="mt-1 mb-4">
        <Col md={12}>
          <Form.Item label="Dropoff Stop Name" name={["dropoffStopName"]}>
            <LocationsDropdown
              name="dropoffStopName"
              options={savedLocations}
              handlePickup={handleSelectDataDropoff}
              locationName={form.getFieldsValue?.dropoffStopName}
              handleNewLocationName={handleDropoffLocationChange}
              searchValue={form.getFieldsValue?.dropoffStopName}
            />
          </Form.Item>
          <Form.Item
            label="Dropoff Address"
            name={["dropoffAddress"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Museo del Niño, Carolina" />
          </Form.Item>
          <Form.Item
            label="Dropoff Contact Name"
            name={["dropoffContactName"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Jenny Doe" />
          </Form.Item>
          <Form.Item label="Dropoff Contact Email" name={["dropoffContactEmail"]}>
            <Input placeholder="test@example.com" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Dropoff Latitude" name={["dropoffLat"]}>
            <Input placeholder="12.3456" />
          </Form.Item>
          <Form.Item label="Dropoff Longitude" name={["dropoffLng"]}>
            <Input placeholder="12.3456" />
          </Form.Item>
          <Form.Item
            label="Dropoff Contact Phone"
            name={["dropoffContactPhone"]}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="(787) 344-5844" />
          </Form.Item>
          <Form.Item
            label="Package Size"
            name="packageTypeId"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select defaultValue="">
              <Select.Option value="">Choose a Package</Select.Option>
              {packageTypes.map((pkgType) => (
                <Select.Option key={pkgType.id} value={pkgType.id}>
                  {pkgType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} className="mt-1 mb-4">
        <Col md={24}>
          <Form.Item label="Note" name={["note"]}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
};

export default AddPoolPackageForm;
