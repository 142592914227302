import "./FormItemHook.scss";
import React from "react";
const FormItemHook = ({
  className,
  children,
  label,
  required,
  errors,
  showLabel = true,
  showOptionsName = true,
  ref
}) => {
  return (
    <div className={`form-item-hook ${className}`} ref={ref}>
      {showLabel && (
        <div className="label-content">
          <label>{label}</label>
          {showOptionsName && (
            <div className="require-status">{required ? "Required" : "Optional"}</div>
          )}
        </div>
      )}
      {children}
      {errors && errors.message && <p className="errors">{errors.message}</p>}
    </div>
  );
};
export default FormItemHook;
