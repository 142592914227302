/* eslint-disable react/display-name */
import React from "react";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import StatusInList from "components/items/StatusInList";
import { RouteIcon } from "components/svgs";
import { ExportIcon } from "components/svgs/ExportIcon";
import { doExportCsv } from "helper/export";
import { fullName } from "helper/util";
import moment from "moment";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import TableBase from "../../../../../components/table/TableBase";
import {
  canEditPermission,
  DATETIME_FORMAT_FRONTEND
} from "../../../../../configs/constants";
import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import { isInternalDriverService } from "modules/client/flexio-services/helper";
import { showDay } from "helper/util";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { convertEmergencyStatus } from "helper/util";
import { orderBy } from "lodash";

const RouteListTable = ({
  dataSource,
  pagination,
  doDelete,
  isAdmin,
  hiddenColumns = [],
  onAssignDriverClick,
  onReassignDriverClick,
  onCancelRoute
}) => {
  const history = useHistory();
  const routesUri = isAdmin ? "/admin/routes" : "/routes";
  const canEdit = allowPermission(useSelector(selectRole), canEditPermission);

  const onRowOrder = (record) => {
    return {
      onClick: () => {
        history.push(`${routesUri}/${record.id}`);
      } // click row
    };
  };

  const handleExport = async (record) => {
    await doExportCsv({
      path: `routes/export/${record.id}`,
      name: record.id
    });
  };

  const additionalAdminColumns = isAdmin
    ? [
        {
          title: "Client",
          width: 150,
          render: (value) => (
            <div>
              {value?.order?.client?.clientName || value?.client?.clientName || "N/A"}
            </div>
          )
        },
        {
          title: "Driver",
          width: 150,
          render: (value) => <div>{fullName(value?.driver).trim()}</div>
        }
      ]
    : [];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 110,
      fixed: "left",
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    ...additionalAdminColumns,
    {
      title: <Tooltip title="Number of pickup stops"># PUs</Tooltip>,
      dataIndex: "noOfPickup",
      width: 80
    },
    {
      title: <Tooltip title="Number of dropoff stops"># DOs</Tooltip>,
      dataIndex: "noOfDropOff",
      width: 80
    },
    {
      title: <Tooltip title="Number of packages in this route"># PKGs</Tooltip>,
      dataIndex: "noOfPackages",
      width: 80
    },

    {
      title: "Pickup Date",
      width: 150,
      ellipsis: true,
      render: (value) => showDay(value?.pickupDate)
    },
    {
      title: "Service",
      width: 150,
      render: (value) => <div>{value?.service || "N/A"}</div>
    },
    {
      title: "Min. Vehicle Type",
      width: 150,
      render: (value) => <div>{value?.minVehicleType || "N/A"}</div>
    },
    {
      title: "Created by",
      ellipsis: true,
      width: 150,
      render: (record) => {
        return record?.createdBy || "N/A";
      }
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      width: 200,
      ellipsis: true,
      render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
    },
    {
      title: "Status",
      width: 120,
      render: (record) => <StatusInList value={convertEmergencyStatus(record)} />
    },
    {
      title: "Last Stop",
      width: 150,
      render: (value) => <div>{value?.lastStop || "N/A"}</div>
    },
    {
      title: "",
      key: "action",
      width: 150,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <Space size="middle">
            {/* <img
              src="/icons/edit.svg"
              className={`table-status`}
              onClick={() => handleEdit(record)}
            /> */}
            <Tooltip title="Export Job">
              <div onClick={(e) => e.stopPropagation()}>
                <ExportIcon onClick={() => handleExport(record)} />
              </div>
            </Tooltip>
            <Tooltip title="View Route">
              <div onClick={(e) => e.stopPropagation()}>
                <Link to={`${routesUri}/${record.id}/dispatch`}>
                  <RouteIcon />
                </Link>
              </div>
            </Tooltip>
            {!["canceled", "completed"].includes(record.status) &&
              isInternalDriverService(record.service) &&
              canEdit && (
                <Tooltip title="Cancel Job">
                  <div onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      title="Are you sure to cancel this job?"
                      onConfirm={() => onCancelRoute && onCancelRoute(record.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <CloseCircleOutlined />
                    </Popconfirm>
                  </div>
                </Tooltip>
              )}
            {record.status === "pending" &&
              isInternalDriverService(record.service) &&
              canEdit && (
                <Tooltip title="Assign Driver">
                  <Button
                    type="text"
                    className="no-padding"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAssignDriverClick && onAssignDriverClick(record);
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>
                </Tooltip>
              )}
            {["accepted", "in-progress"].includes(record.status) &&
              isInternalDriverService(record.service) &&
              canEdit && (
                <Tooltip title="Reassign Driver">
                  <Button
                    type="text"
                    className="no-padding"
                    onClick={(e) => {
                      e.stopPropagation();
                      onReassignDriverClick && onReassignDriverClick(record);
                    }}
                  >
                    <RetweetOutlined />
                  </Button>
                </Tooltip>
              )}
          </Space>
        );
      }
    }
  ];
  const filteredColumns =
    hiddenColumns.length > 0
      ? columns.filter((col) => !hiddenColumns.includes(col.title))
      : columns;
  return (
    <div className="RouteListTable" style={{ overflow: "auto" }}>
      <TableBase
        columns={filteredColumns}
        onRow={onRowOrder}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default RouteListTable;
