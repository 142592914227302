import request from "../../../../helper/request";

const serviceApi = {
  getList: (params) => {
    return request.get("/services", {
      params
    });
  }
};

export default serviceApi;
