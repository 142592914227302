import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Menu, notification, Popconfirm } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import OrdersHeader from "modules/client/active-orders/components/header/OrdersHeader";
import PoolAddressForm from "modules/client/pool/components/PoolAddressForm";
import { TrashIcon } from "components/svgs";
import request from "helper/request";
import { setGlobalLoading } from "app/global/slice";
import { useHistory } from "react-router-dom";
import {
  newPackage,
  packageHasError,
  poolPackageTransform
} from "modules/client/pool/helpers";
import { selectAllPackageTypes } from "../client/package-types/store/slice";
import { getPoolPackageTypeList } from "../client/package-types/store/thunks";
import Payment from "./AddPaymentMethod/Payment";
import { ProfileContext } from "layouts/private/ProfileContext";

const breadCrumbs = [
  { text: "FLEXIO POOL", url: "/pool" },
  { text: "CREATE POOL ORDER" }
];

const CreatePoolOrderPage = () => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [packageForm] = Form.useForm();
  const [packageData, setPackageData] = useState([newPackage]);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const selectPackage = useCallback(
    (packageIndex) => {
      setSelectedPackageIndex(packageIndex);
    },
    [setSelectedPackageIndex]
  );
  const selectedPackageMenuItem = useMemo(
    () => [selectedPackageIndex.toString()],
    [selectedPackageIndex]
  );

  const removePackage = useCallback(
    (index) => {
      setPackageData([...packageData.slice(0, index), ...packageData.slice(index + 1)]);
      if (selectedPackageIndex === 0) return;
      setSelectedPackageIndex(selectedPackageIndex - 1);
    },
    [packageData, selectedPackageIndex]
  );

  const arePackagesValid = useCallback(() => {
    const pkgMissingData = packageData.find(packageHasError);
    if (pkgMissingData) {
      const pkgIndex = packageData.indexOf(pkgMissingData);
      setSelectedPackageIndex(pkgIndex);
      packageForm.validateFields();
      return false;
    }
    return true;
  }, [packageData, packageForm]);

  const selectedPackage = useMemo(() => {
    return { ...packageData[selectedPackageIndex] };
  }, [packageData, selectedPackageIndex]);

  useEffect(() => {
    packageForm.setFieldsValue(selectedPackage);
  }, [packageForm, selectedPackage]);

  const onFinishOrder = async () => {
    if (
      (!profile?.paymentMethods || profile.paymentMethods.length === 0) &&
      window.ADD_PAYMENT_METHOD
    ) {
      setOpenPaymentMethodModal(true);
      return;
    }

    if (!arePackagesValid()) return;
    dispatch(setGlobalLoading({ isLoading: true }));
    const data = [...packageData];
    const transformedPoolPackage = poolPackageTransform(data);
    try {
      await request.post("/orderv2/create-manual-order", {
        packages: transformedPoolPackage,
        submittedDate: moment().toDate(),
        isFlexioPoolOrder: true,
        note: "ABCD"
      });
      notification.success({
        message: "Import successfully!",
        placement: "topRight"
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "There was an error.",
        placement: "topRight"
      });
    } finally {
      dispatch(setGlobalLoading({ isLoading: false }));
      history.push("/pool");
    }
  };

  const onSelectPickupAddress = (val) => {
    setPackageData((prevState) => {
      const tmp = [...prevState];
      tmp[selectedPackageIndex] = {
        ...tmp[selectedPackageIndex],
        pickupAddress: val.address,
        pickupLat: val.lngLat[1],
        pickupLng: val.lngLat[0]
      };
      return tmp;
    });
  };

  const onSelectDropoffAddress = (val) => {
    setPackageData((prevState) => {
      const tmp = [...prevState];
      tmp[selectedPackageIndex] = {
        ...tmp[selectedPackageIndex],
        dropoffAddress: val.address,
        dropoffLat: val.lngLat[1],
        dropoffLng: val.lngLat[0]
      };
      return tmp;
    });
  };

  const onPackageFormChange = useCallback(
    (value) => {
      if (value?.pickupAddress || value?.dropoffAddress) {
        return;
      }
      if (value?.quantity) {
        value.quantity = parseInt(value.quantity);
      }
      setPackageData((prevState) => {
        const tmp = [...prevState];
        tmp[selectedPackageIndex] = {
          ...tmp[selectedPackageIndex],
          ...value
        };
        return tmp;
      });
    },
    [selectedPackageIndex, setPackageData]
  );

  const packageTypeList = useSelector(selectAllPackageTypes);
  useEffect(() => {
    dispatch(getPoolPackageTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortPackageTypeList = useMemo(
    () => (packageTypes, sortName) => {
      return packageTypes.sort((a, b) => a[sortName] - b[sortName]);
    },
    []
  );

  return (
    <div className="CreateOrderPage">
      <Payment
        openPaymentMethodModal={openPaymentMethodModal}
        setOpenPaymentMethodModal={setOpenPaymentMethodModal}
      />
      <OrdersHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="wrapper">
        <h2 className="title p-1">Create Pool Order</h2>
        <div className="sider">
          <Menu
            mode="inline"
            style={{ height: "100%" }}
            defaultSelectedKeys={["0"]}
            selectedKeys={selectedPackageMenuItem}
          >
            {packageData.map((_, index) => (
              <Menu.Item key={index}>
                <span
                  style={{ verticalAlign: "super" }}
                  onClick={() => selectPackage(index)}
                >
                  Package {index + 1}
                </span>
                {packageData.length > 1 && (
                  <Popconfirm
                    title="Are you sure you want to delete this package?"
                    onConfirm={() => removePackage(index)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <TrashIcon />
                  </Popconfirm>
                )}
              </Menu.Item>
            ))}
            <Button
              size="large"
              type="link"
              onClick={() => {
                setPackageData([...packageData, newPackage]);
                setSelectedPackageIndex(packageData.length);
              }}
              style={{ color: "#43C1C2", fontSize: "1em" }}
            >
              Add Package <PlusCircleOutlined />
            </Button>
          </Menu>
        </div>
        <div className="contentForm">
          <PoolAddressForm
            packageForm={packageForm}
            packageTypeList={sortPackageTypeList(packageTypeList, "maxLength")}
            selectedPackage={selectedPackage}
            submit={onFinishOrder}
            onSelectPickupAddress={onSelectPickupAddress}
            onSelectDropoffAddress={onSelectDropoffAddress}
            onPackageFormChange={onPackageFormChange}
            setPackageData={setPackageData}
            packageData={packageData}
            selectedPackageIndex={selectedPackageIndex}
          />
        </div>
      </div>
    </div>
  );
};
export default CreatePoolOrderPage;
