import React from "react";
import {
  VisaLogo,
  MastercardLogo,
  AmexLogo,
  JcbLogo,
  DiscoverLogo,
  UnionLogo,
  DinersLogo,
  CardDefaultLogo
} from "components/icons";

const CreditCardLogo = ({ brand }) => {
  let component;
  switch (brand) {
    case "visa":
      component = <VisaLogo style={style.brandLogoStyle} />;
      break;
    case "mastercard":
      component = <MastercardLogo style={style.brandLogoStyle} />;
      break;
    case "amex":
      component = <AmexLogo style={style.brandLogoStyle} />;
      break;
    case "discover":
      component = <DiscoverLogo style={style.brandLogoStyle} />;
      break;
    case "diners":
      component = <DinersLogo style={style.brandLogoStyle} />;
      break;
    case "jcb":
      component = <JcbLogo style={style.brandLogoStyle} />;
      break;
    case "unionpay":
      component = <UnionLogo style={style.brandLogoStyle} />;
      break;
    default:
      component = <CardDefaultLogo style={style.brandLogoStyle} />;
      break;
  }

  return component;
};

const style = {
  brandLogoStyle: {
    width: 50,
    margin: 0,
    padding: 0,
    height: "auto"
  }
};

export default CreditCardLogo;
