import * as yup from "yup";
import moment from "moment";
import { PHONE_VALIDATION } from "configs/constants";
export const internalDriverForm = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(PHONE_VALIDATION, "Phone is required"),
  country: yup.string().required("Country is required"),
  shiftStart: yup.string().required("From Time is required"),
  shiftEnd: yup
    .string()
    .required("End time cannot be empty")
    .test("is-greater", "End time should be greater", function (value) {
      const { shiftStart } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(shiftStart, "HH:mm"));
    })
});

export const registerForm = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(12, "Password must be at least 12 characters long")
    .matches(
      /^[a-zA-Z0-9!@#$%^&*]{12,}$/, // 12 characters minimum, optional: 1 uppercase, 1 number, 1 special character
      "Password must be at least 12 characters long"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(PHONE_VALIDATION, "Phone is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  agree: yup.bool().oneOf([true], "Field must be checked").required("Please click agree")
});
