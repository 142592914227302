import React from "react";
import { Tag } from "antd";
import { CircleIcon, CircleDotIcon, CircleHalfStrokeIcon } from "components/svgs";
import { convertHexToRGBA } from "helper/util";
import { Tooltip } from "antd";

const PACKAGE_STATUS = {
  submitted: {
    text: "Submitted",
    color: "#888888",
    icon: <CircleDotIcon className={`route-status`} color={"#888888"} />
  },
  pending: {
    text: "Pending",
    color: "#888888",
    icon: <CircleDotIcon className={`route-status`} color={"#888888"} />
  },
  at_warehouse: {
    text: "At Warehouse",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  pickup_scheduled: {
    text: "Pickup Scheduled",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#0078D4"} />
  },
  pickup_in_progress: {
    text: "Pickup In Progress",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  pickup_completed: {
    text: "Pickup Completed",
    color: "#00C853",
    icon: <CircleIcon className={`route-status`} color={"#00C853"} />
  },
  pickup_failed: {
    text: "Pickup Failed",
    color: "#FF3D17",
    icon: <CircleDotIcon className={`route-status`} color={"#FF3D17"} />
  },
  return_scheduled: {
    text: "Return Scheduled",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#0078D4"} />
  },
  return_in_progress: {
    text: "Return In Progress",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  return_completed: {
    text: "Return Completed",
    color: "#00C853",
    icon: <CircleIcon className={`route-status`} color={"#00C853"} />
  },
  return_failed: {
    text: "Return Failed",
    color: "#FF3D17",
    icon: <CircleDotIcon className={`route-status`} color={"#FF3D17"} />
  },
  dropoff_scheduled: {
    text: "Dropoff Scheduled",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#0078D4"} />
  },
  dropoff_in_progress: {
    text: "Dropoff In Progress",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  dropoff_completed: {
    text: "Dropoff Completed",
    color: "#00C853",
    icon: <CircleIcon className={`route-status`} color={"#00C853"} />
  },
  dropoff_failed: {
    text: "Dropoff Failed",
    color: "#FF3D17",
    icon: <CircleDotIcon className={`route-status`} color={"#FF3D17"} />
  },
  cancelled: {
    text: "Cancelled",
    color: "#000000",
    icon: <CircleDotIcon className={`route-status`} color={"#000000"} />
  }
};

const NOT_CANCELLED_STATUS = [
  "pickup_completed",
  "pickup_failed",
  "return_scheduled",
  "return_in_progress",
  "return_completed",
  "return_failed",
  "dropoff_scheduled",
  "dropoff_in_progress",
  "dropoff_completed",
  "dropoff_failed",
  "cancelled"
];

const jobStartedCannotCancel = (isAdmin, status) => {
  return !isAdmin && NOT_CANCELLED_STATUS.includes(status);
};

const PoolStatus = ({
  value,
  record,
  setSelectedPackage = () => null,
  setShowUpdateStatus = () => null,
  isAdmin
}) => {
  const status = value && PACKAGE_STATUS[value] ? value : "pending";

  return (
    <Tag
      color={convertHexToRGBA(PACKAGE_STATUS[status].color, 20)}
      /* Added conditional rendering for the modal so that
      it doesn't affect the client view*/
      onClick={() => {
        if (jobStartedCannotCancel(isAdmin, status)) {
          return;
        }
        setSelectedPackage(record);
        setShowUpdateStatus(true);
      }}
      style={{
        color: PACKAGE_STATUS[status].color,
        lineHeight: "24px",
        textAlign: "center",
        borderRadius: "2px"
      }}
    >
      {jobStartedCannotCancel(isAdmin, status) ? (
        <>
          <Tooltip
            placement="top"
            title="You are unable to update status. Job already started."
            showArrow
          >
            {PACKAGE_STATUS[status].icon}
            {PACKAGE_STATUS[status].text}
          </Tooltip>
        </>
      ) : (
        <>
          {PACKAGE_STATUS[status].icon}
          {PACKAGE_STATUS[status].text}
        </>
      )}
    </Tag>
  );
};

export default PoolStatus;
