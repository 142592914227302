import { Avatar, Card, Col, Input, Row, Select, Table } from "antd";
import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import "./index.scss";
import SimpleCards from "./components/SimpleCards";
import { ProfileContext } from "layouts/private/ProfileContext";
import { useHistory } from "react-router-dom";
import RevenueChart from "./components/RevenueChart";
import { formatToUSD } from "helper/util";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import request from "helper/request";

const ResellerDashboardPage = () => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile, [profileCtx]);
  const history = useHistory();
  const [referredClients, setReferredClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRefferedClients = useCallback(async () => {
    try {
      setLoading(true);
      const response = await request.get("/client/users/referred-clients");

      if (!response.length) {
        return;
      }

      setReferredClients(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!profile?.isReseller) {
      return history.push("/dashboard");
    }

    getRefferedClients();
  }, []);
  return (
    <>
      <Row>
        <Col md={8}>
          <div className="revenue-container">
            <p className="m-0 description-text">Revenue</p>
            <p className="m-0 big-text">$100.00</p>
            <p className="m-0 description-text">This Month</p>
          </div>

          <div className="mt-4" style={{ display: "flex", flexFlow: "row wrap" }}>
            <SimpleCards
              className="ml-1 mr-1 mb-1"
              title="Payout"
              amount={"$200"}
              description="December 15, 2024"
            />
            <SimpleCards
              className="ml-1 mr-1"
              title="Total Referrals"
              amount={referredClients.length}
            />
          </div>
        </Col>
        <Col md={16}>
          <RevenueChart />
        </Col>
      </Row>
      <Card className="mt-3">
        <Row>
          <Col md={14}>
            <h1 className="title p-2">Referrals</h1>
          </Col>
          <Col md={10}>
            <div className="list-filter-container">
              <Input
                placeholder="Search"
                inputMode="search"
                prefix={<SearchOutlined />}
                className="mb-2"
              />
              <Select
                defaultValue="monthly"
                // onChange={handleChange}
                options={[
                  { value: "weekly", label: "Weekly" },
                  { value: "monthly", label: "Last 30 Days" },
                  { value: "yearly", label: "Yearly" },
                  { value: "quarterly", label: "Quarterly" }
                ]}
              />
            </div>
          </Col>
        </Row>
        <Table
          pagination={false}
          dataSource={referredClients}
          loading={loading}
          columns={[
            {
              title: "Name",
              render: (record) => {
                return (
                  <div>
                    <Avatar
                      style={{ width: 60, height: 60 }}
                      src={record?.profilePhoto}
                      icon={<UserOutlined style={{ fontSize: 30, marginTop: 10 }} />}
                      className="mr-3"
                    />
                    {record.firstName} {record.lastName}
                  </div>
                );
              }
            },
            {
              title: "Subscribed",
              dataIndex: "subscribed",
              render: (value) => {
                return value ? "Yes" : "No";
              }
            },
            {
              title: "Total Spent",
              dataIndex: "totalSpent"
            },
            {
              title: "Revenue",
              dataIndex: "revenue"
            }
          ]}
        />
      </Card>
    </>
  );
};

export default ResellerDashboardPage;
