import { yupResolver } from "@hookform/resolvers/yup";
import { InputHook, GroupRadioHook, GroupCheckboxHook } from "components/hook-forms";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import Cards from "../components/Cards";
import ActionButton from "./ActionButtons";
import {
  BOOLEAN_OPTIONS,
  BOOLEAN_OTHER_OPTIONS,
  FAVORITES_OPTIONS,
  LANGUAGES_OPTIONS,
  RATE_OPTIONS
} from "./constants";
import { step4Schema } from "./schema";
import "./Step3Form.scss";
import { notification } from "antd";

const Step3Form = ({ driver, handleUpdateDriver, showActionButton }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(step4Schema),
    defaultValues: driver
  });

  useEffect(() => {
    if (!isEmpty(driver)) reset(driver);
  }, [driver, reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = async (values) => {
    const error = await handleUpdateDriver({
      ...values,
      id: driver?.id
    });
    if (!error) {
      notification.success({
        message: "Update successfully!",
        placement: "bottomRight"
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Cards title={"Additional Questions"} className="wrap-step-3-form">
        <div className="single-group">
          <GroupRadioHook
            classNameRadio="commercial-general-checkbox space-between"
            label="Do you have Commercial General Liability Insurance?"
            options={BOOLEAN_OPTIONS}
            showOptionsName={false}
            control={control}
            name="haveInsurance"
            errors={errors}
          />
          <InputHook
            control={control}
            label="Do you have any other relevant transportation license or certification?"
            placeholder="Do you have any other relevant transportation license or certification?"
            name="haveLicenseCertification"
            // showOptionsName={true}
            errors={errors}
          />

          <GroupRadioHook
            classNameRadio="experience-deliveries-checkbox space-between"
            label="Do you have prior experience doing cargo transportation or deliveries? *"
            options={BOOLEAN_OTHER_OPTIONS}
            showOptionsName={false}
            control={control}
            name="haveTransportationDelivery"
            errors={errors}
          />
          <GroupRadioHook
            classNameRadio="rate-technology-checkbox space-between"
            label="Rate your ability using Mobile Applications and/or technology overall?"
            options={RATE_OPTIONS}
            showOptionsName={false}
            control={control}
            name="rateUsingMobile"
            errors={errors}
          />

          <GroupCheckboxHook
            classNameCheckbox="understand-language-checkbox space-between"
            label="Which languages do you understand?"
            options={LANGUAGES_OPTIONS}
            showOptionsName={false}
            control={control}
            name="understandLanguages"
            errors={errors}
          />

          <InputHook
            control={control}
            label="Additional Relevant Experiences"
            placeholder="Additional Relevant Experiences"
            name="additionalRelevantExperiences"
            showOptionsName={true}
            errors={errors}
          />

          <GroupCheckboxHook
            classNameCheckbox="favorites-checkbox"
            label="Which favorite do you understand?"
            options={FAVORITES_OPTIONS}
            showOptionsName={false}
            control={control}
            name="learnFlexio"
            errors={errors}
          />
        </div>
      </Cards>
      {showActionButton && <ActionButton text="Update" />}
    </form>
  );
};
export default Step3Form;
