import { createAsyncThunk } from "@reduxjs/toolkit";
import settingApi from "./api";

export const getPackageTypeList = createAsyncThunk(
  "package-types/get/loading",
  async (data) => {
    return settingApi.getPackageTypeList(data);
  }
);

export const getAllPackageType = createAsyncThunk(
  "package-types/getAll/loading",
  async () => {
    return settingApi.getAllPackageType();
  }
);

export const doGetPackageType = createAsyncThunk(
  "package-types/getOne/loading",
  async (id) => {
    return settingApi.getPackageType(id);
  }
);

export const doCreatePackageType = createAsyncThunk(
  "package-types/create/loading",
  async (data) => {
    return settingApi.createPackageType(data);
  }
);

export const doUpdatePackageType = createAsyncThunk(
  "package-types/create/loading",
  async (data) => {
    return settingApi.updatePackageType(data);
  }
);

export const doDeletePackageType = createAsyncThunk(
  "package-types/delete/loading",
  async (id) => {
    return settingApi.deletePackageType(id);
  }
);
