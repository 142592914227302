import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { AddIcon } from "../../../../../components/svgs";
import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
// import "./OrdersHeader.scss";
import { FilterIcon } from "components/svgs/FilterIcon";

const OrdersHeader = ({ breadCrumbs, hasButton = true, showFilter, setShowFilter }) => {
  return (
    <div className={`order-header-component mb-4 ${showFilter && "filter"}`}>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"}`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter &nbsp; <FilterIcon />
          </Button>
          <Link to="/orders/new?action=new">
            <Button type="secondary" size={"small"}>
              Create New Job &nbsp;
              <AddIcon />
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
export default OrdersHeader;
