import React, { useState, useContext, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";
import profileApi from "modules/client/profile/store/api";
import RoutingProcessForm from "../forms/RoutingProcess";
import { Spin } from "antd";
import { setError } from "app/global/slice";
import { ProfileContext } from "layouts/private/ProfileContext";

const RoutingProcessPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const setProfile = useMemo(() => profileCtx?.setProfile, [profileCtx]);
  const dispatch = useDispatch();

  const handleOnFinish = useCallback(
    async (data) => {
      try {
        setSubmitting(true);
        const updatedProfile = await profileApi.updateRoutingPreferences(data);
        setProfile({ ...profile, ...updatedProfile });
        dispatch(setSuccessMessage("Updated successfully!"));
      } catch (err) {
        const error = JSON.parse(err?.message || "{}");
        dispatch(setError(error));
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, setProfile]
  );

  return (
    <div className="return-process-setting">
      <Spin spinning={loading}>
        <RoutingProcessForm
          isFetching={submitting}
          profile={profile}
          onSubmit={handleOnFinish}
        />
      </Spin>
    </div>
  );
};

export default RoutingProcessPage;
