import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Rate } from "antd";
import { SentFeebackSuccessIcon } from "components/svgs";
import { shortId } from "helper/util";
import React, { memo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { doSendFeedback } from "../store/thunks";
import "./DriverFeedback.scss";

const { TextArea } = Input;

const DriverFeedbackPage = () => {
  const { jobId } = useParams();
  const isLoading = useSelector((state) => state.global.isLoading);
  const [score, setScore] = useState(0);
  const [submited, setSubmited] = useState(false);
  const dispatch = useDispatch();

  const handleOnFinish = async (values) => {
    if (!score) {
      message.warning("Please set rating for driver");
      return;
    }
    const result = await dispatch(doSendFeedback({ ...values, jobId, score }));
    setSubmited(!result?.error);
  };

  return (
    <div className="driver-feedback-page">
      <div className="wrapper">
        <img
          className="logo"
          src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`}
          alt="logo"
        />
        {submited ? (
          <div className="sent-feedback-success">
            <SentFeebackSuccessIcon />
            <p className="inform-note">Your review has been sent</p>
            <p>Thank you for spending time to rate our driver service.</p>
          </div>
        ) : (
          <>
            <p>
              Your order <strong>#{shortId(jobId)}</strong> is completed.
            </p>
            <p>Please rate our driver service:</p>
            <Form
              scrollToFirstError={true}
              layout="vertical"
              hideRequiredMark
              colon={false}
              onFinish={handleOnFinish}
              // form={form}
            >
              <div className="form">
                <div className="rating-stars">
                  <Rate onChange={(value) => setScore(value)} />
                </div>
                <Form.Item
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    >
                      <div className="left">Comment</div>
                      <div
                        className="right"
                        style={{ fontWeight: 400, color: "#888888" }}
                      >
                        Optional
                      </div>
                    </div>
                  }
                  name="comment"
                >
                  <TextArea rows={3} placeholder="Add your comment here" />
                </Form.Item>
              </div>
              <div className="form-footer">
                <Button loading={isLoading} type="primary" htmlType="submit" block>
                  Submit
                  <CheckCircleOutlined />
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(DriverFeedbackPage);
