import React from "react";
const CircleHalfStrokeIcon = ({
  className,
  width = "20px",
  height = "20px",
  color = "#1F3998",
  onClick
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 512 512"
      width={width}
      fill={color}
      onClick={onClick}
      style={{
        transform: "rotate(270deg)"
      }}
    >
      <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64V448C362 448 448 362 448 256C448 149.1 362 64 256 64z" />
    </svg>
  );
};
export { CircleHalfStrokeIcon };
