import { Popconfirm, Space, Table } from "antd";
import { setSuccessMessage, setError } from "app/global/slice";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import AdditionalVehicleStatus from "../components/AdditionalVehicleStatus";
import { EDIT_ADDTIONAL_VEHICLE_URL } from "../constants";
import { selectAllAdditionalVehicles } from "../store/additional-vehicle/slice";
import { doDelete, doGetList } from "../store/additional-vehicle/thunks";

const AdditionalVehicleList = () => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const params = useParams();
  const driverId = params?.id;
  const dispatch = useDispatch();
  const additionalVehicleList = useSelector(selectAllAdditionalVehicles);

  const columns = [
    {
      title: "Vehicle Make",
      dataIndex: "vehicleMake",
      width: 150
    },
    {
      title: "Vehicle Model",
      dataIndex: "vehicleModel",
      width: 150
    },
    {
      title: "Vehicle Color",
      dataIndex: "vehicleColor",
      width: 150
    },
    {
      title: "Vehicle Year",
      dataIndex: "vehicleYear",
      width: 150
    },
    {
      title: "Number Plate",
      dataIndex: "numberPlate",
      width: 150
    },
    {
      title: "Status",
      // eslint-disable-next-line react/display-name
      render: (record) => <AdditionalVehicleStatus status={record?.status} />
    }
  ];

  if (canDelete || canEdit) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <Link to={EDIT_ADDTIONAL_VEHICLE_URL(driverId, record?.id)}>
                <img src="/icons/edit.svg" className={`table-status`} />
              </Link>
            )}
            {canDelete && (
              <div className="confirm-action" onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => handleDelete(record?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <img src="/icons/delete.svg" className={`table-status`} />
                </Popconfirm>
              </div>
            )}
          </Space>
        );
      }
    });
  }

  useEffect(() => {
    if (!driverId) return;
    dispatch(
      doGetList({
        page: 1,
        pageSize: 100,
        filter: `driver_id||$eq||${driverId}`
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, driverId]);

  const handleDelete = async (id) => {
    try {
      const { error } = await dispatch(doDelete(id));
      if (!error) {
        dispatch(setSuccessMessage("Deleted successfully!"));
      } else {
        dispatch(setError("Deleted failure!"));
      }
    } catch (err) {
      dispatch(setError("Deleted failure!"));
      console.log(err);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={additionalVehicleList}
      rowKey="id"
      pagination={false}
    />
  );
};

export default AdditionalVehicleList;
