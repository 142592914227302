import { CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Switch, TimePicker } from "antd";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

import AddressInput from "components/inputs/AddressInput";
import DefaultMapbox from "modules/client/active-orders/components/mapbox/DefaultMapbox";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import "./ReturnProcess.scss";
import { TIME_FORMAT_BACKEND } from "configs/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { allowPermission } from "helper/util";
import { canCreatePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";

const ReturnProcess = ({ returnLocation, isLoading, onSubmit }) => {
  const [currentAddress, setCurrentAddress] = useState();
  const [form] = Form.useForm();
  const role = useSelector(selectRole);
  const canCreate = allowPermission(role, canCreatePermission);
  const canEdit = allowPermission(role, canEditPermission);

  useEffect(() => {
    const { start, end } = returnLocation;
    const isUpdating = !!returnLocation?.id;
    form.setFieldsValue({
      ...returnLocation,
      start: start && isUpdating ? moment(start, TIME_FORMAT_BACKEND) : start,
      end: end && isUpdating ? moment(end, TIME_FORMAT_BACKEND) : end
    });
    isUpdating &&
      setCurrentAddress({
        address: returnLocation.address,
        lngLat: [returnLocation.lng, returnLocation.lat]
      });
  }, [form, returnLocation]);

  const handleSetAddress = (value) => {
    setCurrentAddress({ address: value.address, lngLat: value.lngLat });
  };

  const handleOnFinish = async (values) => {
    const { address, lngLat } = currentAddress;
    const { start, end } = values;
    onSubmit({
      ...values,
      address,
      lng: lngLat[0],
      lat: lngLat[1],
      start: start
        ? typeof start === "object"
          ? start.format(TIME_FORMAT_BACKEND)
          : moment(start).format(TIME_FORMAT_BACKEND)
        : undefined,
      end: end
        ? typeof end === "object"
          ? end.format(TIME_FORMAT_BACKEND)
          : moment(end).format(TIME_FORMAT_BACKEND)
        : undefined,
      enabledReturnPickup: values?.enabledReturnPickup ?? false,
      enabled: values?.enabled ?? false
    });
  };

  const showActionButton = canCreate || (canEdit && returnLocation?.id);

  return (
    <div className="common-form return-process-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleOnFinish}
        form={form}
      >
        <h2 className="title">
          Automated Return Pickup Location Process{" "}
          <div className="switcher-btn">
            <Form.Item valuePropName="checked" name="enabledReturnPickup">
              <Switch />
            </Form.Item>
            <span> &nbsp;Enabled</span>
          </div>
        </h2>
        <h3 className="title-desc">
          If enabled, at creation of a Job it will automatically add a Return Pickup stop
          to the Pickup Location including all packages.
        </h3>
        <h2 className="title">
          Automated Return Process for Failed Stops or Partial Completions{" "}
          <div className="switcher-btn">
            <Form.Item valuePropName="checked" name="enabled">
              <Switch />
            </Form.Item>
            <span> &nbsp;Enabled</span>
          </div>
        </h2>
        <h3 className="title-desc">
          If enabled, at completion of a Job it will automatically add a Return stop to
          the Return Location including all packages not deliveried.
        </h3>
        <h2 className="form-name">RETURN LOCATION</h2>
        <div className="form">
          <Form.Item
            label="Address (move pin to exact location)"
            name="address"
            rules={[{ required: true, message: "Required" }]}
          >
            <AddressInput
              placeholder="Address"
              onSelect={(value) => handleSetAddress(value)}
              address={currentAddress}
            />
          </Form.Item>
          <div className="map">
            <DefaultMapbox address={currentAddress} setAddress={handleSetAddress} />
          </div>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div className="left">Location Name</div>
                    <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                      Optional
                    </div>
                  </div>
                }
                name="locationName"
              >
                <Input placeholder="Location Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div className="left">Physical Address</div>
                    <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                      Optional
                    </div>
                  </div>
                }
                name="physicalAddress"
              >
                <Input placeholder="Physical Address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <div className="custom-label">
                <label>Pickup Time Window</label>
              </div>
              <Row className="from_to">
                <Col span={11}>
                  <Form.Item
                    name="start"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <TimePicker
                      format={TIME_FORMAT_BACKEND}
                      value={form.getFieldValue("start")}
                      onSelect={(value) => form.setFieldsValue({ start: value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "center" }}>
                  <MinusOutlined />
                </Col>
                <Col span={11}>
                  <Form.Item name="end" rules={[{ required: true, message: "Required" }]}>
                    <TimePicker
                      format={TIME_FORMAT_BACKEND}
                      value={form.getFieldValue("end")}
                      onSelect={(value) => form.setFieldsValue({ end: value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Est. Waiting/Service Time"
                name="duration"
                rules={[{ required: true, message: "Required" }]}
                tooltip={{
                  title:
                    "Please assign an estimated duration in minutes of time taken from when the driver arrives at the location, is attended, finishes the delivery and resumes driving. Here it is better to go with a worst case scenario to ensure the routes can be finished."
                }}
              >
                <Input
                  type="number"
                  placeholder="Est. Waiting/Service Time"
                  suffix="Minutes"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Note" name="note">
            <Input.TextArea placeholder="Note" />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Contact Name"
                name="contactName"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Contact Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div className="left">E-mail</div>
                    <div className="right" style={{ fontWeight: 400, color: "#888888" }}>
                      Optional
                    </div>
                  </div>
                }
                name="email"
                rules={[{ type: "email", message: "Invalid Email" }]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12}>
            <div className="custom-label">
              <label>Phone Numbers</label>
            </div>
            <Form.List name="phoneList" label="Phone Numbers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKeyValue, ...restField }, index) => {
                    return (
                      <div key={name} className={`phone_numbers x${key}`}>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              fieldKey={[fieldKeyValue, "type"]}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select>
                                <Select.Option value="contact">Contact</Select.Option>
                                <Select.Option value="salesperson">
                                  Salesperson
                                </Select.Option>
                                <Select.Option value="mainSupport">
                                  Main Support
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Row>
                              <Col span={index > 0 ? 19 : 24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "phone"]}
                                  fieldKey={[fieldKeyValue, "phone"]}
                                  rules={[{ required: true, message: "Required" }]}
                                >
                                  <PhoneInput country="ca" enableSearch />
                                </Form.Item>
                              </Col>
                              {index > 0 && (
                                <Col span={5} className="icon">
                                  <ReactSVG
                                    src="/icons/minus.svg"
                                    onClick={() => remove(name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Button
                    className="add-more-contact-btn"
                    type="link"
                    onClick={() => add({ type: "contact" })}
                  >
                    Add More Phone Number
                    <ReactSVG src="/icons/plus.svg" className="react-icon" />
                  </Button>
                </>
              )}
            </Form.List>
          </Col>
        </div>
        {showActionButton && (
          <div className="form-footer">
            <Button loading={isLoading} type="primary" htmlType="submit">
              {returnLocation?.id ? "Update" : "Create"}
              <CheckCircleOutlined />
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ReturnProcess;
