import React, { useMemo, useCallback, useState, useEffect } from "react";
import TableBase from "../../../../components/table/TableBase";
import * as moment from "moment";
import { TOKEN_NAME } from "configs/constants";
import Cookies from "universal-cookie";
import PaymentStatusInList from "components/items/PaymentStatusInList";

const cookies = new Cookies();

export const PoolInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const getInvoices = useCallback(() => {
    const authToken = cookies.get(TOKEN_NAME, { path: "/" });
    fetch(`${window.REACT_APP_API_ENDPOINT}/invoicev2/all-invoices`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => response.json())
      .then((invoicesResponse) => setInvoices(invoicesResponse.data));
  }, []);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <div className="client-order-list-page invoicing-list-page client">
      <div className="common-header">
        <h3>Pool Invoices</h3>
      </div>
      <div className="content-table">
        <PoolInvoicingListTable dataSource={invoices} />
      </div>
    </div>
  );
};

const sortByDate = (dateA, dateB) => {
  return moment(dateA).valueOf() - moment(dateB).valueOf();
};

const PoolInvoicingListTable = ({ dataSource }) => {
  const [sortOrder, setSortOrder] = useState("descend");

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 100,
      render: (value) => (
        <p style={{ margin: 0, color: "#1f3998" }}>{value.substr(0, 8).toUpperCase()}</p>
      )
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      width: 100,
      sorter: (a, b) => sortByDate(a.created_at, b.created_at),
      sortOrder: sortOrder,
      render: (value) => moment(value).format("MMMM DD YYYY HH:MM")
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      width: 100,
      render: (value) => window.CURRENCY_SYMBOL + value
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      render: (value) => <PaymentStatusInList value={value} />
    }
  ];

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.field === "created_at") {
      setSortOrder(sorter.order);
    }
  };

  return (
    <TableBase columns={columns} data={dataSource} rowKey="id" onChange={handleChange} />
  );
};
