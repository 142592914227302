import { Button, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllFlexioServices } from "modules/client/flexio-services/store/slice";
import { getFlexioServiceList } from "modules/client/flexio-services/store/thunks";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import { getAllVehicleCategories } from "modules/admin/settings/store/VehicleCategoryThunks";
import { selectAllVehicleCategories } from "modules/admin/settings/store/VehicleCategorySlice";

import profileApi from "../store/api";
import "./APIKey.scss";

const APIKeyPage = () => {
  const [loading, setLoading] = useState(false);
  const [apiKeyDataLoading, setAPIKeyDataLoading] = useState(false);
  const [copied, setCopied] = useState({});
  const [profile, setProfile] = useState();
  const [clientId, setClientId] = useState();
  const dispatch = useDispatch();
  const flexioServices = useSelector(selectAllFlexioServices);
  const packageTypeList = useSelector(selectAllPackageTypes);
  const vehicleTypeList = useSelector(selectAllVehicleCategories);
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [apiKeyData, setAPIKeyData] = useState({});

  useEffect(() => {
    getProfile();
    dispatch(getFlexioServiceList({ clientId: clientId }));
    dispatch(getPackageTypeList());
    dispatch(getAllVehicleCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAPIKeyData({
      apiKey: profile?.apiKey
    });
  }, [profile]);

  const getProfile = async () => {
    try {
      setLoading(true);
      setProfile(await profileApi.getProfile());
      setClientId(profile?.id);
    } catch (err) {
      //const error = JSON.parse(err?.message || "{}");
      //dispatch(setError(error));
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="api-key-page">
        <Spin spinning={apiKeyDataLoading}>
          <Modal
            closable={true}
            open={showAPIKey}
            title={"API Key"}
            className="no-padding-modal"
            onCancel={() => {
              setShowAPIKey(false);
            }}
            footer={false}
            width={1000}
          >
            <div className="ml-3">Copy key and store it in a secure environment.</div>
            <div className="api-key-page p-2">
              <div className="content">
                <div className="mb-4">
                  <div className="m-2 ml-4">API Key:</div>
                  <div className="api-key-box">
                    <span>{apiKeyData?.apiKey}</span>
                    <Button
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(apiKeyData?.apiKey);
                        setCopied((prevCopiedItems) => ({
                          ...prevCopiedItems,
                          [apiKeyData?.apiKey]: true
                        }));
                      }}
                    >
                      {copied[apiKeyData?.apiKey] ? "Copied" : "Copy"}
                    </Button>
                  </div>
                </div>
                <div>
                  <div
                    className="mt-2"
                    // style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      size="small"
                      onClick={() => {
                        // setAPIKeyDataLoading(true);
                        // updateAPIKey();
                        setShowAPIKey(false);
                      }}
                      className="mt-2 ml-3"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Spin>
        <div className="content">
          <h1>Client Information</h1>
          <h3>API Key</h3>
          <Button
            size="small"
            onClick={() => {
              setShowAPIKey(true);
            }}
            className="ml-3"
          >
            Show API Key
          </Button>

          <h3 style={{ marginTop: "20px" }}>Client User ID</h3>
          <div className="api-key-box">
            <span>{profile?.client?.id}</span>
            <Button
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(profile?.client?.id);
                setCopied((prevCopiedItems) => ({
                  ...prevCopiedItems,
                  [profile?.client?.id]: true
                }));
              }}
            >
              {copied[profile?.client?.id] ? "Copied" : "Copy"}
            </Button>
          </div>
        </div>
        <div className="content">
          <h1>Services</h1>
          {flexioServices.map((service) => (
            <div key={service.id}>
              <h3 style={{ marginTop: "20px" }}>{service.name} ID</h3>
              <div className="api-key-box">
                <span>{service.id}</span>
                <Button
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(service.id);
                    setCopied((prevCopiedItems) => ({
                      ...prevCopiedItems,
                      [service.id]: true
                    }));
                  }}
                >
                  {copied[service.id] ? "Copied" : "Copy"}
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="content">
          <h1>Package Types</h1>
          {packageTypeList
            .filter((pack) => pack.name !== "Custom")
            .map((pack) => (
              <div key={pack.id}>
                <h3 style={{ marginTop: "20px" }}>{pack.name} Package ID</h3>
                <div className="api-key-box">
                  <span>{pack.id}</span>
                  <Button
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(pack.id);
                      setCopied((prevCopiedItems) => ({
                        ...prevCopiedItems,
                        [pack.id]: true
                      }));
                    }}
                  >
                    {copied[pack.id] ? "Copied" : "Copy"}
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className="content">
          <h1>Vehicle Categories</h1>
          {vehicleTypeList.map((vehicle) => (
            <div key={vehicle.id}>
              <h3 style={{ marginTop: "20px" }}>{vehicle.name} ID</h3>
              <div className="api-key-box">
                <span>{vehicle.id}</span>
                <Button
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(vehicle.id);
                    setCopied((prevCopiedItems) => ({
                      ...prevCopiedItems,
                      [vehicle.id]: true
                    }));
                  }}
                >
                  {copied[vehicle.id] ? "Copied" : "Copy"}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default APIKeyPage;
