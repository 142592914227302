import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { markerLayer, markerIcon } from "../settings";

const icon = markerIcon();

const UnscheduledLayer = ({
  stops,
  colorsHidden = [],
  handleClickMarker,
  isShowViewMore = true
}) => {
  const setCursor = (map, cursor) => {
    map.getCanvas().style.cursor = cursor;
  };
  const tmpStops = stops
    .filter((s) => !colorsHidden.includes(s?.color))
    .sort(function (a, b) {
      let first = a?.routePlanInternalDriverId ? 1 : 0;
      let second = b?.routePlanInternalDriverId ? 1 : 0;
      return first - second;
    });
  return (
    <>
      <Layer {...markerLayer("pickup", icon["pickup"])}>
        {tmpStops
          .filter((st) => st.type === "pickup")
          .map((stop, index) => {
            if (stop.display) {
              return (
                <Feature
                  key={stop.id}
                  coordinates={[stop?.lng, stop?.lat]}
                  onClick={() =>
                    handleClickMarker({ ...stop, showViewMore: isShowViewMore }, "stop")
                  }
                  onMouseEnter={({ map }) => {
                    setCursor(map, "pointer");
                  }}
                  onMouseLeave={({ map }) => {
                    setCursor(map, "");
                  }}
                  properties={{
                    label: stop?.sortNo || index + 1,
                    color: stop?.color || "#C4C4C4",
                    colorText: "#FFFFFF"
                  }}
                />
              );
            }
          })}
      </Layer>
      <Layer {...markerLayer("dropoff", icon["dropoff"])}>
        {tmpStops
          .filter((st) => st.type === "dropoff")
          .map((stop, index) => {
            if (stop.display) {
              return (
                <Feature
                  key={stop.id}
                  coordinates={[stop?.lng, stop?.lat]}
                  onClick={() =>
                    handleClickMarker({ ...stop, showViewMore: isShowViewMore }, "stop")
                  }
                  onMouseEnter={({ map }) => {
                    setCursor(map, "pointer");
                  }}
                  onMouseLeave={({ map }) => {
                    setCursor(map, "");
                  }}
                  properties={{
                    label: stop?.sortNo || index + 1,
                    color: stop?.color || "#C4C4C4",
                    colorText: "#FFFFFF"
                  }}
                />
              );
            }
          })}
      </Layer>
    </>
  );
};
export default UnscheduledLayer;
