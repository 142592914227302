import React from "react";
import { Select } from "antd";

const LocationsDropdown = ({
  options,
  handlePickup,
  locationName,
  handleNewLocationName
}) => (
  <Select
    showSearch
    value={locationName || undefined}
    placeholder="Stop Name"
    filterOption={(input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    }
    options={options.map((option) => ({
      value: option.id,
      label: `${option.locationName}, (${option.address})`
    }))}
    onSelect={(value) => {
      handlePickup(value);
    }}
    onSearch={(value) => {
      if (value !== "") {
        handleNewLocationName(value);
      }
    }}
    autoClearSearchValue={false}
  />
);
export default LocationsDropdown;
