import { createAsyncThunk } from "@reduxjs/toolkit";
import flexioPoolApi from "./api";

export const createOrder = createAsyncThunk("orderv2/create/loading", async (data) => {
  return flexioPoolApi.createOrder(data);
});

export const getPackagesList = createAsyncThunk(
  "orderv2/all-packages/loading",
  async (params) => {
    return flexioPoolApi.getPackages(params);
  }
);
