import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getPackageTypeList } from "./thunks";

const packageTypesAdapter = createEntityAdapter();

const initialState = packageTypesAdapter.getInitialState({
  metadata: {}
});

export const clientPackageTypesSlice = createSlice({
  name: "clientPackageTypes",
  initialState,
  reducers: {
    setAllRoutePlans: packageTypesAdapter.setAll
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageTypeList.fulfilled, (state, { payload }) => {
      const packageTypeEntities = payload;

      packageTypesAdapter.setAll(state, packageTypeEntities);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectPackageTypeById,
  selectIds: selectPackageTypeIds,
  selectEntities: selectPackageTypeEntities,
  selectAll: selectAllPackageTypes,
  selectTotal: selectTotalPackageTypes
} = packageTypesAdapter.getSelectors((state) => state.clientPackageTypes);

export const selectMetadata = (state) => state.clientPackageTypes.metadata;

export default clientPackageTypesSlice.reducer;
