import { useWindowSize } from "helper/hooks";
import React from "react";

//64: header, 72: footer
function withFullHeight(WrappedComponent, existHeight = 136) {
  const WithComponent = (props) => {
    const size = useWindowSize();
    const height = size.height - existHeight;
    if (isNaN(height)) {
      return <div />;
    }
    return <WrappedComponent {...props} height={height} />;
  };
  return WithComponent;
}
export default withFullHeight;
