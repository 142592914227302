/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { ExportIcon } from "components/svgs/ExportIcon";
import AdminBreadCrumb from "../../../../components/AdminBreadCrumb";
import { ImportIcon } from "components/svgs/ImportIcon";
import { FilterIcon } from "components/svgs";
import { ToolTipTitle } from "components/tooltip-title/TooltipTitle";
import { ProfileContext } from "layouts/private/ProfileContext";

const FlexioPoolHeader = ({
  breadCrumbs,
  buttonParams,
  showFilter,
  setShowFilter,
  hasButton = true,
  showImport = false,
  showExport = false,
  onExport,
  exporting,
  setShowImport
}) => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const history = useHistory();
  return (
    <div className="common-header">
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons d-flex flex-row gap-2">
          {window.POOL_CREATE_MANUAL_ORDER_FEATURE && (
            <Button onClick={() => history.push("/pool-orders/new")}>
              Create Pool Order
            </Button>
          )}
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
          {showImport && (
            <Tooltip
              color="#43C1C2"
              title={profile?.canCreatePoolRoute ? "" : <ToolTipTitle />}
            >
              <Button
                size="small"
                type="third"
                className={`svg-icon  d-flex flex-row`}
                onClick={setShowImport}
                disabled={!profile?.canCreatePoolRoute}
              >
                Import <ImportIcon />
              </Button>
            </Tooltip>
          )}
          {showExport && (
            <Button
              size="small"
              type="third"
              className={`svg-icon d-flex flex-row`}
              onClick={onExport}
              loading={exporting}
            >
              Export <ExportIcon />
            </Button>
          )}
          {buttonParams && (
            <Link to={buttonParams.link}>
              <Button type="primary" size="small">
                {buttonParams.text}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default FlexioPoolHeader;
