import React from "react";
import { useSelector } from "react-redux";

import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";

import AdminClientHeader from "../components/header/AdminClientHeader";
import ClientForm from "../components/form/ClientForm";
import withFetchOne from "../../../../hocs/withFetchOne";
import "./AdminCreateUpdateClient.scss";
import { doGetClient } from "../store/thunks";
import { selectClient } from "../store/clientSlice";

const AdminClientCreate = () => {
  const breadCrumbs = [{ text: "CLIENTS" }, { text: "EDIT CLIENT" }];
  const client = useSelector(selectClient);
  return (
    <div className="admin-client-edit admin-create-update-client">
      <AdminClientHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Edit Client</h2>
        <ClientForm client={client} />
      </div>
    </div>
  );
};
export default withPermission(
  withFetchOne(AdminClientCreate, {
    fetchData: doGetClient
  }),
  canEditPermission
);
