import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import FailedRegistrationTable from "../components/table/FailedRegistrationTable";
import { getFailedRegistrationList } from "../store/FailedRegistrationThunk";
import {
  selectAllFailedRegistration,
  updatePaginationFailedRegistration,
  selectMetadata
} from "../store/FailedRegistrationSlice";
import "../styles/settings.scss";

const params = { group: "flexio" };

const FailedRegistration = () => {
  const dispatch = useDispatch();
  const failedRegistrationList = useSelector(selectAllFailedRegistration);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Failed Registration",
    updatePaginationFailedRegistration,
    dispatch
  );

  const breadCrumbs = [{ text: "SETTINGS" }, { text: "FAILED REGISTRATION" }];

  return (
    <div className="TerritoryList">
      <SettingHeader breadCrumbs={breadCrumbs} />
      <div className="admin-content">
        <FailedRegistrationTable
          dataSource={failedRegistrationList}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default withFetchTablePagination(FailedRegistration, {
  fetchData: getFailedRegistrationList,
  selectMeta: selectMetadata,
  params
});
