import request from "../../../../helper/request";

const clientUserApi = {
  getList: (params) => {
    return request.get(`/client/users`, { params });
  },
  getOne: (id) => {
    return request.get(`/client/users/${id}`);
  },
  create: (data) => {
    return request.post("/client/users", data);
  },
  update: (data) => {
    return request.patch(`/client/users/${data.id}`, data);
  },
  delete: (id) => {
    return request.delete(`/client/users/${id}`);
  }
};

export default clientUserApi;
