import request from "../../../../helper/request";

export const notificationApi = {
  getList() {
    return request.get("/client/notifications").then((it) => ({ ...it }));
  },
  async setRead(id) {
    return request.put(`/client/notifications/${id}`, { read: true });
  }
};
