import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _uniq from "lodash/uniq";
import _find from "lodash/find";
import { useHistory } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, Select, notification } from "antd";

import { getDriverList } from "modules/admin/drivers/store/thunks";
import { selectAllDrivers } from "modules/admin/drivers/store/slice";
import { getAllVehicleCategories } from "modules/admin/settings/store/VehicleCategoryThunks";
import { selectAllVehicleCategories } from "modules/admin/settings/store/VehicleCategorySlice";
import { getTerritoryList } from "modules/admin/settings/store/TerritoryThunks";
import { selectAllTerritories } from "modules/admin/settings/store/TerritorySlice";

import "./CreateForm.scss";
import { doCreateDriverBroadcast } from "../store/thunks";

export const SENT_TO_TYPES = {
  GROUP: "group",
  INDIVIDUAL: "individual"
};

const { TextArea } = Input;
const { Option } = Select;

const CreateForm = () => {
  const allTerritories = useSelector(selectAllTerritories);
  const allDrivers = useSelector(selectAllDrivers);
  const allVehicleCategories = useSelector(selectAllVehicleCategories);
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const [type, setType] = useState(SENT_TO_TYPES.GROUP);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({ type: SENT_TO_TYPES.GROUP });
    dispatch(getTerritoryList({ pageSize: 1000 }));
    dispatch(getDriverList({ pageSize: 1000 }));
    dispatch(getAllVehicleCategories({ pageSize: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allCities = useMemo(
    () =>
      _uniq(
        allDrivers
          ?.filter((driver) => driver.active && driver.city)
          ?.map((filteredDriver) => filteredDriver.city)
      ),
    [allDrivers]
  );

  const backToListPage = () => {
    history.push("/admin/driver-broadcasts");
  };

  const onSubmit = async (values) => {
    const params = { ...values };
    const { driverIds, vehicleCategoryIds } = values;
    if (type === SENT_TO_TYPES.INDIVIDUAL) {
      if (!driverIds?.length) {
        notification.error({
          message: "Please select at least one driver to push notification",
          placement: "topRight"
        });
        return;
      }
      delete params.territoryId;
      delete params.cities;
      delete params.vehicleCategoryIds;
      params.drivers = driverIds.map((driverId) => {
        const driver = _find(allDrivers, { id: driverId });
        return { id: driverId, email: driver.email, fullName: driver.fullName };
      });
      delete params.driverIds;
    } else {
      delete params.driverIds;
      params.flexioVehicleTypes = vehicleCategoryIds?.map((vehicleCategoryId) => {
        const vehicleCategory = _find(allVehicleCategories, { id: vehicleCategoryId });
        return { id: vehicleCategoryId, name: vehicleCategory.name };
      });
      delete params.vehicleCategoryIds;
    }
    try {
      const { error } = await dispatch(doCreateDriverBroadcast(params));
      if (!error) backToListPage();
    } catch {
      return false;
    }
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  return (
    <div className="create-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input size="large" placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Required" }]}
        >
          <TextArea rows={1} placeholder="Add your message" />
        </Form.Item>
        <label>Sent to:</label>
        <Form.Item
          name="type"
          rules={[{ required: true, message: "Required" }]}
          valuePropName="value"
        >
          <Radio.Group onChange={handleChangeType}>
            <Radio.Button value={SENT_TO_TYPES.GROUP}>Group of Drivers</Radio.Button>
            <Radio.Button value={SENT_TO_TYPES.INDIVIDUAL}>
              Individual Driver
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {type === SENT_TO_TYPES.GROUP ? (
          <>
            <Form.Item label="Territory" name="territoryId">
              <Select size="large" placeholder="All Territories" allowClear>
                {allTerritories.map((territory) => (
                  <Select.Option key={territory.id} value={territory.id}>
                    {territory.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Cities" name="cities">
              <Select
                mode="multiple"
                placeholder="Please Select Cities"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.children ?? "").includes(input)}
              >
                {allCities?.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Vehicle Types" name="vehicleCategoryIds">
              <Select
                mode="multiple"
                placeholder="Please Select Vehicle Types"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.children ?? "").includes(input)}
              >
                {allVehicleCategories?.map((vehicleCategory) => (
                  <Option key={vehicleCategory.id} value={vehicleCategory.id}>
                    {vehicleCategory.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <Form.Item label="Drivers" name="driverIds">
            <Select
              mode="multiple"
              placeholder="Please Select Drivers"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.children ?? "").includes(input)}
            >
              {allDrivers
                ?.filter((driver) => driver.active)
                ?.map((filteredDriver) => (
                  <Option key={filteredDriver.id} value={filteredDriver.id}>
                    {`${filteredDriver.fullName} (${filteredDriver.email})`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <div className="line-break"></div>
        <div className="form-footer">
          <Button onClick={backToListPage}>Cancel</Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Create and Send Broadcast
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateForm;
