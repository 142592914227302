import React from "react";
import { Menu, Layout } from "antd";

const Sider = Layout.Sider;

const SiderForm = ({ type, onChangeMenu }) => {
  return (
    <Sider className="site-layout-background" width={144}>
      <Menu
        mode="inline"
        onSelect={onChangeMenu}
        defaultSelectedKeys={[type]}
        selectedKeys={[type]}
        style={{ height: "100%" }}
      >
        <Menu.Item key="pickups">Pickup/s</Menu.Item>
        <Menu.Item key="dropoffs">Drop-off/s</Menu.Item>
        <Menu.Item key="service">Service</Menu.Item>
      </Menu>
    </Sider>
  );
};
export default SiderForm;
