import React from "react";
import { Tag } from "antd";
import { convertHexToRGBA } from "../../../../../helper/util";
import { CircleIcon, CircleDotIcon, CircleHalfStrokeIcon } from "components/svgs";

const ROUTE_STATUS = {
  pending: {
    text: "Pending",
    color: "#888888",
    icon: <CircleDotIcon className={`route-status`} color={"#888888"} />
  },
  accepted: {
    text: "Accepted",
    color: "#0078D4",
    icon: <CircleDotIcon className={`route-status`} color={"#0078D4"} />
  },
  completed: {
    text: "Completed",
    color: "#00C853",
    icon: <CircleIcon className={`route-status`} color={"#00C853"} />
  },
  "in-progress": {
    text: "In Progress",
    color: "#FF8400",
    icon: <CircleHalfStrokeIcon className={`route-status`} color={"#FF8400"} />
  },
  canceled: {
    text: "Canceled",
    color: "#000000",
    icon: <CircleDotIcon className={`route-status`} color={"#000000"} />
  },
  failed: {
    text: "Failed",
    color: "#FF3D17",
    icon: <CircleDotIcon className={`route-status`} color={"#FF3D17"} />
  },
  emergency: {
    text: "Emergency",
    color: "#FF3D17",
    icon: <CircleDotIcon className={`route-status`} color={"#FF3D17"} />
  }
};

const RouteStatus = ({ value }) => {
  const status = value && ROUTE_STATUS[value] ? value : "pending";
  return (
    <Tag
      color={convertHexToRGBA(ROUTE_STATUS[status].color, 20)}
      style={{
        color: ROUTE_STATUS[status].color,
        lineHeight: "24px",
        textAlign: "center",
        borderRadius: "2px"
      }}
    >
      {ROUTE_STATUS[status].icon}
      {ROUTE_STATUS[status].text}
    </Tag>
  );
};
export default RouteStatus;
