export const FLEXIO_VEHICLE_OPTIONS = [
  "Vans XL",
  "Vans SM",
  "Pickup Trucks XL",
  "Pickup Trucks SM",
  "SUV",
  "Cars",
  "Bicycle",
  "Motorcycle",
  "Walking",
  "Dry Box Trucks Type 1",
  "Dry Box Trucks Type 2",
  "Refrigera ted Box Trucks Type 1",
  "Refrigera ted Box Trucks Type 2",
  "Container Truck",
  "Tow Truck",
  "Flat Bed Truck",
  "Dump Truck"
];
export const VEHICLE_OPTIONS = ["Cars", "SUV", "Vans", "Pickup Truck", "Other"];
export const LIST_ADDTIONAL_VEHICLE_URL = (driverId) =>
  `/admin/users/drivers/${driverId}?activeTab=4`;
export const CREATE_ADDTIONAL_VEHICLE_URL = (driverId) =>
  `/admin/users/drivers/${driverId}/add-additional-vehicle`;
export const EDIT_ADDTIONAL_VEHICLE_URL = (driverId, additionalVehicleId) =>
  `/admin/users/drivers/${driverId}/add-additional-vehicle/${additionalVehicleId}/edit`;
export const ADDITIONAL_VEHICLE_STATUS = {
  pending: "pending",
  verified: "verified"
};
