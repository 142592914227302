import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { COLORS_DRIVERS } from "configs/constants";
import { convertObjectToArray } from "helper/util";
import { decodePolyline } from "modules/shared/PickupDropoff/decodePolyline";
import { convertToMinutes } from "modules/shared/PickupDropoff/helper";
import { sortBy } from "modules/shared/PickupDropoff/helper";
import {
  doCreateActiveOrder,
  doDeleteOrder,
  doGetJobOrder,
  doGetOrder,
  doGetOrderRoutes,
  doOptimizeOrder,
  doOptimizeOrderCallback,
  doSortOrder,
  doSortOrderCallback,
  getOrderList,
  doStoreDraftId
} from "./thunks";

export const STATE_STATUSES = {
  PROCESSING: "processing",
  COMPLETED: "completed"
};

const OrdersAdapter = createEntityAdapter({
  selectId: (order) => order.id
});

const orderInit = {
  packages: [],
  jobs: [],
  user: {}
};

const initialState = OrdersAdapter.getInitialState({
  metadata: {},
  order: orderInit,
  job: {
    packages: []
  },
  newOrder: {},
  draftId: ""
});

const mapDataRoutes = (routes, isSelectRouteNumber = false, numberRoutes) => {
  let tmpRoutes = {};
  const keyRoutes = Object.keys(routes);
  const totalRouteNumber = numberRoutes ? numberRoutes : keyRoutes.length;
  keyRoutes.map((key, index) => {
    const route = routes[key];
    if (route?.jobs?.length > 0) {
      tmpRoutes = {
        ...tmpRoutes,
        [key]: {
          ...route,
          name: key.replace("_", " ")
        }
      };
    }
  });

  const lengthRouted = Object.keys(tmpRoutes).length;
  const arrRoutes = convertObjectToArray(routes);
  const routesNotAvailable = arrRoutes.filter((route) => !route.polylines);
  const routesNotAddress = arrRoutes.filter(
    (route) => !route.startAddressLat && !route.startAddressLng
  );
  const routeHasAdress = arrRoutes.find(
    (route) => route.startAddressLat && route.startAddressLng
  );
  const availableRoute = arrRoutes.find((route) => route.shiftStart);
  if (isSelectRouteNumber && lengthRouted <= totalRouteNumber) {
    for (let i = 0; i < totalRouteNumber - lengthRouted; i++) {
      const indexRoute = lengthRouted + i + 1;
      const color = `#${COLORS_DRIVERS[indexRoute % COLORS_DRIVERS.length]}`;
      tmpRoutes = {
        ...tmpRoutes,
        [`route_${indexRoute}`]: {
          ...routesNotAvailable,
          startAddress: availableRoute?.startAddress,
          shiftStart: availableRoute?.shiftStart,
          lat: availableRoute?.startAddressLat,
          lng: availableRoute?.startAddressLng,
          start_location: availableRoute?.start_location || availableRoute?.startLocation,
          name: `route ${indexRoute}`,
          color,
          display: true
        }
      };
    }
  }

  if (routesNotAddress.length > 0 && routeHasAdress) {
    arrRoutes.forEach((r, i) => {
      if (!r.startAddressLat && !r.startAddressLng) {
        tmpRoutes = {
          ...tmpRoutes,
          [`route_${i + 1}`]: {
            ...tmpRoutes[`route_${i + 1}`],
            startAddress: routeHasAdress?.startAddress,
            lat: routeHasAdress?.startAddressLat,
            lng: routeHasAdress?.startAddressLng
          }
        };
      }
    });
  }

  return tmpRoutes;
};

const mapRoutes = (routes) => {
  return routes.map((route) => ({
    ...route,
    coords: route?.polylines ? decodePolyline(route.polylines).coords : [],
    display: true,
    lat: route?.startAddressLat,
    lng: route?.startAddressLng
  }));
};

const mapLocations = (routes) => {
  const stops = [];
  let sortNoUnscheduled = 1;
  routes.map((route) => {
    route.routeLocations.forEach((routeLocation) => {
      stops.push({
        ...routeLocation,
        ...routeLocation.location,
        sortNo: route?.name === "unscheduled" ? sortNoUnscheduled : routeLocation?.sortNo,
        routeId: route?.name === "unscheduled" ? "unscheduled" : route.id,
        color: route?.color || "#c4c4c4",
        orderId: route?.orderId,
        display: !!route.display
      });
      if (route?.name === "unscheduled") {
        sortNoUnscheduled += 1;
      }
    });
  });
  return stops;
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updatePaginationOrders(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterOrders(state, action) {
      state.filter = {
        ...state?.filter,
        ...action.payload
      };
    },
    saveAsDraftNewOrder(state, action) {
      state.newOrder = { ...action.payload.data };
      action?.payload?.callback;
    },
    updateRoute(state, action) {
      const { route } = action.payload;
      state.newOrder.routes[route.id] = route;
    },
    resetNewOrder(state) {
      state.newOrder = {};
    },
    resetNewOrderStatuses(state, action) {
      delete state?.routeEngineStatus;
      delete state?.status;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderList.fulfilled, (state, { payload }) => {
      OrdersAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });

    builder.addCase(doDeleteOrder.fulfilled, (state, { payload }) => {
      OrdersAdapter.removeOne(state, payload?.id);
    });
    builder.addCase(doCreateActiveOrder.fulfilled, (state, { payload }) => {
      OrdersAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doGetOrder.fulfilled, (state, { payload }) => {
      state.order = {
        ...payload,
        routes: mapRoutes(payload.routes),
        locations: mapLocations(payload.routes)
      };
    });
    builder.addCase(doGetOrderRoutes.fulfilled, (state, { payload }) => {
      state.order = {
        ...payload,
        routes: mapRoutes(payload),
        locations: mapLocations(payload)
      };
    });
    builder.addCase(doGetOrder.pending, (state) => {
      state.order = orderInit;
    });
    builder.addCase(doGetJobOrder.fulfilled, (state, { payload }) => {
      state.job = payload;
    });
    builder.addCase(doOptimizeOrder.pending, (state, { payload }) => {
      state.routeEngineStatus = STATE_STATUSES.PROCESSING;
      state.status = STATE_STATUSES.PROCESSING;
    });
    builder.addCase(doOptimizeOrder.fulfilled, (state, { payload }) => {
      state.routeEngineStatus = STATE_STATUSES.COMPLETED;
    });
    builder.addCase(doSortOrder.pending, (state, { payload }) => {
      state.routeEngineStatus = STATE_STATUSES.PROCESSING;
      state.status = STATE_STATUSES.PROCESSING;
    });
    builder.addCase(doSortOrder.fulfilled, (state, { payload }) => {
      state.routeEngineStatus = STATE_STATUSES.COMPLETED;
    });
    builder.addCase(doOptimizeOrderCallback.fulfilled, (state, { payload }) => {
      state.status = STATE_STATUSES.PROCESSING;

      const { order, output, isSelectRouteNumber, numberRoutes } = payload;
      const { jobs, routes } = order;
      const {
        solution,
        polylines,
        report,
        unserved,
        summary,
        mapValueCalculation = {}
      } = output;

      let tmpJobs = { ...jobs };
      let tmpRoutes = { ...routes };
      let tmpNewRoutes = {};
      const routeKeys = Object.keys(solution);

      Object.keys(tmpJobs || {})?.map((jobId) => {
        tmpJobs = {
          ...tmpJobs,
          [jobId]: {
            ...tmpJobs[jobId],
            routeId: "unscheduled",
            color: "#c4c4c4",
            pickup: {
              ...tmpJobs[jobId].pickup,
              arrivalTime: "",
              finishTime: "",
              type: "pickup",
              routeId: "unscheduled",
              display: true
            },
            dropoff: {
              ...tmpJobs[jobId].dropoff,
              arrivalTime: "",
              finishTime: "",
              type: "dropoff",
              routeId: "unscheduled",
              display: true
            }
          }
        };
      });
      let keyCount = 1;
      routeKeys.map((routeId, index) => {
        let tmpRouteJob = {};
        const color = `#${COLORS_DRIVERS[index % COLORS_DRIVERS.length]}`;

        let tmpPolylines;
        let tmpCoords = [];
        if (polylines[routeId]) {
          tmpPolylines = polylines[routeId][0];
          const { coords } = decodePolyline(tmpPolylines);
          tmpCoords = coords;
        }
        const keyRoute = `route_${keyCount}`;
        if (solution[routeId] && solution[routeId].length > 0) {
          let sortNo = 1;
          const routeJobs = solution[routeId]?.sort(sortBy("arrival_time", false));

          routeJobs.map((item) => {
            if (item.location_id.includes("job")) {
              const job = tmpJobs[item.location_id];
              tmpJobs = {
                ...tmpJobs,
                [item.location_id]: {
                  ...job,
                  routeId: keyRoute,
                  color,
                  [item.type]: {
                    ...job[item.type],
                    color,
                    originalDuration:
                      jobs[item.location_id][item.type]?.duration || item.duration,
                    duration: item.duration,
                    distance: item.distance,
                    travelMins: item.travel_mins,
                    waitingMins: item.waiting_mins,
                    workingMins: item.working_mins,
                    arrivalTime: item?.arrival_time,
                    finishTime: item?.finish_time,
                    display: true,
                    sortNo
                  }
                }
              };
              tmpRouteJob = {
                ...tmpRouteJob,
                [item.location_id]: {
                  ...job,
                  routeId: keyRoute,
                  color,
                  [item.type]: {
                    ...job[item.type],
                    color,
                    originalDuration:
                      jobs[item.location_id][item.type]?.duration || item.duration,
                    duration: item.duration,
                    distance: item.distance,
                    travelMins: item.travel_mins,
                    waitingMins: item.waiting_mins,
                    workingMins: item.working_mins,
                    arrivalTime: item?.arrival_time,
                    finishTime: item?.finish_time,
                    display: true,
                    sortNo
                  }
                }
              };
              sortNo += 1;
            }
          });
          const summaryRoute = summary[routeId];
          const mapValueCalculationRoute = mapValueCalculation[routeId];
          const endRouteJob = routeJobs.filter((item) =>
            item.location_id?.includes("_end")
          )[0];

          tmpNewRoutes = {
            ...tmpNewRoutes,
            [keyRoute]: {
              shiftStart: routeJobs[0].arrival_time,
              color,
              name: keyRoute.replace("_", " "),
              polylines: tmpPolylines,
              coords: tmpCoords,
              jobs: convertObjectToArray(tmpRouteJob, "id"),
              display: true,
              startAddress: tmpRoutes[routeId]?.start_location?.name,
              startAddressLat: tmpRoutes[routeId]?.start_location?.lat,
              startAddressLng: tmpRoutes[routeId]?.start_location?.lng,
              start_location: tmpRoutes[routeId]?.start_location,
              locked: !!tmpRoutes[routeId]?.locked,
              distance: summaryRoute?.distance,
              travelMins: summaryRoute?.travel_mins,
              waitingMins: summaryRoute?.waiting_mins,
              workingMins: summaryRoute?.working_mins,
              returnDistance: mapValueCalculationRoute?.returnDistance || 0,
              // returnDistance: endRouteJob?.distance,
              returnMins: endRouteJob
                ? convertToMinutes(endRouteJob?.arrival_time || 0) -
                  convertToMinutes(routeJobs[routeJobs.length - 2]?.finish_time || 0)
                : 0,
              workingPercentage: summaryRoute?.working_percentage,
              serviceMins: summaryRoute?.service_mins
            }
          };
          keyCount += 1;
        } else {
          delete tmpRoutes[routeId];
        }
      });

      const returnRoutes = mapDataRoutes(tmpNewRoutes, isSelectRouteNumber, numberRoutes);
      state.newOrder = {
        ...state.newOrder,
        jobs: tmpJobs,
        routes: returnRoutes,
        report,
        isChanged: false
      };

      state.status = STATE_STATUSES.COMPLETED;
    });
    builder.addCase(doSortOrderCallback.fulfilled, (state, { payload }) => {
      state.status = STATE_STATUSES.PROCESSING;

      const { order, output, numberRoutes, isSelectRouteNumber } = payload;
      const { jobs, routes } = order;
      const { solution, polylines, report, summary, mapValueCalculation = {} } = output;

      let tmpJobs = { ...jobs };
      let tmpRoutes = { ...routes };
      const routeKeys = Object.keys(solution);

      let keyCount = 1;
      routeKeys.map((routeId, index) => {
        let tmpRouteJob = {};
        const color = `#${COLORS_DRIVERS[index % COLORS_DRIVERS.length]}`;

        let tmpPolylines;
        if (polylines[routeId]) {
          tmpPolylines = polylines[routeId][0];
        }
        const { coords } = decodePolyline(tmpPolylines);

        const keyRoute = `route_${keyCount}`;
        if (solution[routeId].length > 0) {
          let sortNo = 1;
          const routeJobs = solution[routeId]?.sort(sortBy("arrival_time", false));
          routeJobs.map((item) => {
            if (item.location_id.includes("job")) {
              const jobId = item.location_id.split("__")[1];
              const type = item.location_id.split("__")[0];
              const job = tmpJobs[jobId];

              tmpJobs = {
                ...tmpJobs,
                [jobId]: {
                  ...job,
                  routeId: keyRoute,
                  color,
                  [type]: {
                    ...job[type],
                    ...item,
                    color,
                    originalDuration: jobs[jobId][type]?.duration || item.duration,
                    duration: item.duration,
                    distance: item.distance,
                    travelMins: item.travel_mins,
                    waitingMins: item.waiting_mins,
                    workingMins: item.working_mins,
                    arrivalTime: item?.arrival_time,
                    finishTime: item?.finish_time,
                    sortNo,
                    display: true
                  }
                }
              };
              tmpRouteJob = {
                ...tmpRouteJob,
                [jobId]: {
                  ...job,
                  routeId: keyRoute,
                  color,
                  [item.type]: {
                    ...job[item.type],
                    color,
                    originalDuration: jobs[jobId][type]?.duration || item.duration,
                    duration: item.duration,
                    distance: item.distance,
                    travelMins: item.travel_mins,
                    waitingMins: item.waiting_mins,
                    workingMins: item.working_mins,
                    arrivalTime: item?.arrival_time,
                    finishTime: item?.finish_time,
                    display: true,
                    sortNo
                  }
                }
              };
              sortNo += 1;
            }
          });

          const summaryRoute = summary[routeId];
          const mapValueCalculationRoute = mapValueCalculation[routeId];
          const endRouteJob = routeJobs.filter((item) =>
            item.location_id?.includes("_end")
          )[0];
          tmpRoutes = {
            ...tmpRoutes,
            [keyRoute]: {
              ...tmpRoutes[keyRoute],
              color,
              shiftStart: routeJobs[0].arrival_time || "00:00",
              polylines: tmpPolylines,
              coords,
              jobs: convertObjectToArray(tmpRouteJob, "id"),
              startAddress: tmpRoutes[keyRoute]?.start_location?.name,
              startAddressLat: tmpRoutes[keyRoute]?.start_location?.lat,
              startAddressLng: tmpRoutes[keyRoute]?.start_location?.lng,
              distance: summaryRoute?.distance,
              travelMins: summaryRoute?.travel_mins,
              waitingMins: summaryRoute?.waiting_mins,
              workingMins: summaryRoute?.working_mins,
              returnDistance: mapValueCalculationRoute?.returnDistance || 0,
              // returnDistance: endRouteJob?.distance,
              returnMins: endRouteJob
                ? convertToMinutes(endRouteJob?.arrival_time || 0) -
                  convertToMinutes(routeJobs[routeJobs.length - 2]?.finish_time || 0)
                : 0,
              workingPercentage: summaryRoute?.working_percentage,
              serviceMins: summaryRoute?.service_mins,
              display: true
            }
          };
          keyCount += 1;
        } else {
          delete tmpRoutes[keyRoute];
        }
      });

      state.newOrder = {
        ...state.newOrder,
        jobs: tmpJobs,
        routes: mapDataRoutes(tmpRoutes, isSelectRouteNumber, numberRoutes),
        report,
        isChanged: false
      };
      state.status = STATE_STATUSES.COMPLETED;
    });
    builder.addCase(doStoreDraftId.fulfilled, (state, { payload }) => {
      state.draftId = payload;
    });
  }
});

export const {
  selectById: selectOrderById,
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: selectTotalOrders
} = OrdersAdapter.getSelectors((state) => {
  return state.orders;
});

export const selectMetadata = (state) => state.orders.metadata;
export const selectFilter = (state) => state.orders.filter;
export const selectOrder = (state) => state.orders.order;
export const selectJobOrder = (state) => state.orders.job;
export const selectNewOrder = (state) => state.orders.newOrder;
export const selectOrderState = (state) => state.orders;
export const selectDraftId = (state) => state.orders.draftId;

export const {
  updateFilterOrders,
  updatePaginationOrders,
  saveAsDraftNewOrder,
  updateRoute,
  resetNewOrder,
  resetNewOrderStatuses
} = orderSlice.actions;

export default orderSlice.reducer;
