import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { doCreateTerritory, doUpdateTerritory } from "../../store/TerritoryThunks";
import { getAllVehicleCategories } from "../../store/VehicleCategoryThunks";
import { getAllPackageType } from "../../store/PackageTypeThunks";
import { selectAllVehicleCategories } from "../../store/VehicleCategorySlice";
import { selectAllPackageTypes } from "../../store/PackageTypeSlice";
import PhoneInput from "react-phone-input-2";
import { ArrowDownIcon } from "components/svgs";
import "../../styles/settings.scss";
import { useState } from "react";
import momentTZ from "moment-timezone";
import PolygonMapboxForm from "./PolygonMapboxForm";
import { DEFAULT_CENTER } from "configs/constants";
import { selectAllServices } from "../../store/ServiceSlice";
import { getServiceList } from "../../store/ServiceThunks";
import { INTERNAL_DRIVER_SERVICE } from "configs/constants";
import { loadAllCountries } from "helper/util";
const { Option } = Select;

const centerOfCountry = {
  US: [-101.299591, 47.116386],
  PR: [-66.105721, 18.466333]
};

const TerritoryForm = ({ territory }) => {
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [isEditable, setEditable] = useState(false);
  const [coords, setCoords] = useState(territory?.polygon || []);
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  // const vehicleCategories = useSelector(selectAllVehicleCategories);
  // const packageTypes = useSelector(selectAllPackageTypes);
  const servicesList = useSelector(selectAllServices);
  const [vehicleCategoriesValue, setVehicleCategoriesValue] = useState([]);
  const [packageTypesValue, setPackageTypesValue] = useState([]);
  const servicesOptions = servicesList
    .filter(
      (item) => item.name?.toLowerCase() !== INTERNAL_DRIVER_SERVICE && !item.isDefault
    )
    ?.map((service) => ({
      label: service.name,
      value: service.id
    }));
  // const [servicesSelected, setServicesSelected] = useState([]);

  const timeZones = momentTZ.tz.names();

  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/settings/territories");
  };

  const onSubmit = async (values) => {
    values = {
      ...values,
      vehicleCategories: vehicleCategoriesValue.map((vehicleCategoryId) => ({
        id: vehicleCategoryId
      })),
      packageTypes: packageTypesValue.map((packageTypeId) => ({
        id: packageTypeId
      })),
      services: values.services?.map((serviceId) => ({ id: serviceId }))
    };
    try {
      let erRs = undefined;
      if (!territory) {
        const { error } = await dispatch(
          doCreateTerritory({
            ...values
          })
        );
        erRs = error;
      } else {
        const { error } = await dispatch(
          doUpdateTerritory({
            ...values,
            id: territory?.id
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/settings/territories");
    } catch {
      return false;
    }
  };

  function onChangeVehicleCategories(checkedValues) {
    setVehicleCategoriesValue(checkedValues);
  }

  function onChangePackageTypes(checkedValues) {
    setPackageTypesValue(checkedValues);
  }

  const getCenterOfPolygon = (arr) => {
    if (arr && arr.length > 0) {
      const x = arr[0].map((xy) => xy[0]);
      const y = arr[0].map((xy) => xy[1]);
      const cx = (Math.min(...x) + Math.max(...x)) / 2;
      const cy = (Math.min(...y) + Math.max(...y)) / 2;
      return [cx, cy];
    }
    return DEFAULT_CENTER;
  };

  useEffect(() => {
    if (territory) {
      setCoords(territory?.polygon);
      const tmpCenter = getCenterOfPolygon(territory?.polygon);
      setCenter(tmpCenter);
      if (territory.vehicleCategories) {
        setVehicleCategoriesValue(
          territory.vehicleCategories.map((vehicleCategory) => vehicleCategory.id)
        );
      }
      if (territory.packageTypes) {
        setPackageTypesValue(territory.packageTypes.map((packageType) => packageType.id));
      }
      form.setFieldsValue({
        ...territory,
        services: territory.services?.map((service) => service.id)
      });
    }
  }, [territory, form]);

  useEffect(() => {
    dispatch(getServiceList());
    dispatch(getAllVehicleCategories());
    dispatch(getAllPackageType());
  }, [dispatch]);

  const onCompleteDraw = (coordinates) => {
    setCoords(coordinates);
    form.setFieldsValue({ polygon: coordinates });
    setEditable(false);
  };

  const handleCountryChange = (value) => {
    setCenter(centerOfCountry[value]);
  };

  // const onChangeServicesSelect = (value) => {
  //   setServicesSelected(value);
  // };

  return (
    <div className="common-form split-two-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Territory Name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Territory Name" />
          </Form.Item>
          <Form.Item
            label="Country"
            placeholder="Country"
            name="country"
            rules={[{ required: true, message: "Required" }]}
            labelSelect="name"
          >
            <Select
              suffixIcon={<ArrowDownIcon />}
              onChange={(value) => handleCountryChange(value)}
              showSearch
              optionFilterProp="children"
              defaultValue=""
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes((input ?? "").toLowerCase())
              }
            >
              {loadAllCountries().map((option) => (
                <Option key={option["isoCode"]} value={option["isoCode"]}>
                  {option["name"]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <PolygonMapboxForm
          onCompleteDraw={onCompleteDraw}
          coords={coords}
          isEditable={isEditable}
          setEditable={setEditable}
          center={center}
        />
        <div className="form-content">
          <Form.Item
            label="Driver Support Phone Number"
            name="driverSupportPhone"
            rules={[{ required: true, message: "Required" }]}
          >
            <PhoneInput country="ca" enableSearch />
          </Form.Item>
          <Form.Item
            label="Driver Support Email Address"
            name="driverSupportEmail"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input size="large" placeholder="Driver Support Email Address" />
          </Form.Item>
          <Form.Item
            label="Client Support Phone Number"
            name="clientSupportPhone"
            rules={[{ required: true, message: "Required" }]}
          >
            <PhoneInput country="ca" enableSearch />
          </Form.Item>
          <Form.Item
            label="Client Support Email Address"
            name="clientSupportEmail"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input size="large" placeholder="Client Support Email Address" />
          </Form.Item>
          <Form.Item
            label="Accounting Email Address"
            name="accountingEmailAddress"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input size="large" placeholder="Accounting Email Address" />
          </Form.Item>
          <Form.Item name="polygon"></Form.Item>
          <Form.Item
            label="Time Zone"
            placeholder="Time Zone"
            name="timeZone"
            rules={[{ required: true, message: "Required" }]}
            labelSelect="name"
          >
            <Select showSearch suffixIcon={<ArrowDownIcon />}>
              {timeZones.map((timeZone) => (
                <Option key={timeZone} value={timeZone}>
                  {timeZone}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Client B2B Tax"
            name="clientBtbTax"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber className="form-item-full-width" placeholder="Client B2B Tax" />
          </Form.Item>
          <Form.Item
            label="Client Sales Tax"
            name="clientSalesTax"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber
              className="form-item-full-width"
              placeholder="Client Sales Tax"
            />
          </Form.Item>
          <Form.Item
            label="Driver Tax"
            name="driverTax"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber className="form-item-full-width" placeholder="Driver Tax" />
          </Form.Item>
          <Form.Item
            label="Credit Card Transacion Fee"
            name="creditCardTransacionFee"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber
              className="form-item-full-width"
              min={0}
              placeholder="Credit Card Transacion Fee"
            />
          </Form.Item>
          <Form.Item
            label="Bank Transfer Transacion Fee"
            name="bankTransferTransacionFee"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber
              className="form-item-full-width"
              min={0}
              placeholder="Bank Transfer Transacion Fee"
            />
          </Form.Item>
          <Form.Item
            label="Payout Fee"
            name="payoutFee"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber
              className="form-item-full-width"
              min={0}
              placeholder="Payout Fee"
            />
          </Form.Item>
          <Form.Item
            label="Fresh Work Support Group Id"
            name="freshWorkSupportGroupId"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Fresh Work Support Group Id" />
          </Form.Item>
          <Form.Item
            label="Licensing Percentage Of Gross Profit"
            name="licensingPercentageOfGrossProfit"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Licensing Percentage Of Gross Profit" />
          </Form.Item>
          <div>
            <Form.Item
              label="Choose which Services will be available as default"
              placeholder="Services"
              name="services"
              rules={[{ required: false, message: "Required" }]}
              // labelSelect="name"
            >
              <Select
                showSearch
                suffixIcon={<ArrowDownIcon />}
                mode="multiple"
                allowClear
                options={servicesOptions}
                // onChange={onChangeServicesSelect}
                placeholder="Choose a service"
              />
            </Form.Item>
          </div>
        </div>
        {/* <h2 className="title select-package-types-title">
          Select Package Types w/ Parameters
        </h2>
        <Checkbox.Group
          className="form-content"
          onChange={onChangePackageTypes}
          value={packageTypesValue}
        >
          {packageTypes.map((packageType) => {
            return (
              <div className="group-check-box-with-description" key={packageType.id}>
                <Checkbox className="check-box-with-description" value={packageType.id}>
                  {packageType.name}
                </Checkbox>
                <div className="two-description">
                  <div>
                    Max Weight: <b>{packageType.maxWeight}</b>
                  </div>
                  <div>
                    Max Volume: <b>{packageType.maxVolume}</b>
                  </div>
                </div>
              </div>
            );
          })}
        </Checkbox.Group>
        <h2 className="title select-vehicle-categories-title">
          Select Vehicle Categories w/ Parameters
        </h2>
        <Checkbox.Group
          onChange={onChangeVehicleCategories}
          value={vehicleCategoriesValue}
          className="form-item-full-width"
        >
          {vehicleCategories.map((vehicleCategory) => {
            return (
              <div className="group-check-box-with-description" key={vehicleCategory.id}>
                <Checkbox
                  className="check-box-with-description"
                  value={vehicleCategory.id}
                >
                  {vehicleCategory.name}
                </Checkbox>
                <div className="three-description">
                  <div>
                    Max Length: <b>{vehicleCategory.maxLength} inches</b>
                  </div>
                  <div>
                    Max Weight: <b>{vehicleCategory.maxWeight} lbs</b>
                  </div>
                  <div>
                    Max Volume: <b>{vehicleCategory.maxVolume} cuft</b>
                  </div>
                </div>
                <div className="tag-description">
                  {vehicleCategory.packageTypes &&
                    vehicleCategory.packageTypes.map((packageType) => (
                      <Tag
                        key={packageType.id}
                        color={convertHexToRGBA("#f4f4f4", 100)}
                        style={{
                          color: "#000000",
                          lineHeight: "24px",
                          textAlign: "center",
                          borderRadius: "10px"
                        }}
                      >
                        {packageType.name}
                      </Tag>
                    ))}
                </div>
              </div>
            );
          })}
        </Checkbox.Group>
        <div className="line-break"></div> */}
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {territory ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TerritoryForm;
