import React from "react";
const ClearAllIcon = ({ color = "#1F3998" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M5 13h14v-2H5v2zm-2 4h14v-2H3v2zM7 7v2h14V7H7z" />
    </svg>
  );
};
export { ClearAllIcon };
