import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  getSaasCountriesList,
  getAllSaasCountries,
  doCreateSaasCountry,
  doDeleteSaasCountry
} from "./SaasCountriesThunks";

const SaasCountriesAdapter = createEntityAdapter({
  selectId: (saasCountries) => saasCountries.id
});

const initialState = SaasCountriesAdapter.getInitialState({
  metadata: {},
  saasCountries: {}
});

export const saasCountriesSlice = createSlice({
  name: "saasCountries",
  initialState,
  reducers: {
    updatePaginationSaasCountries(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSaasCountries.fulfilled, (state, { payload }) => {
      SaasCountriesAdapter.setAll(state, payload);
      state.saasCountries = {};
      state.metadata = {};
    });
    builder.addCase(getSaasCountriesList.fulfilled, (state, { payload }) => {
      SaasCountriesAdapter.setAll(state, payload.data);
      state.saasCountries = {};
      state.metadata = payload.metadata;
    });
    builder.addCase(doCreateSaasCountry.fulfilled, (state, { payload }) => {
      SaasCountriesAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doDeleteSaasCountry.fulfilled, (state, { payload }) => {
      SaasCountriesAdapter.removeOne(state, payload?.id);
      state.metadata = {
        ...state.metadata,
        totalItems: state.metadata.totalItems - 1
      };
    });
  }
});

export const {
  selectById: selectSaasCountryById,
  selectIds: selectSaasCountryIds,
  selectEntities: selectSaasCountryEntities,
  selectAll: selectAllSaasCountries,
  selectTotal: selectTotalSaasCountries
} = SaasCountriesAdapter.getSelectors((state) => state.saasCountries);

export const selectMetadata = (state) => state.saasCountries.metadata;
export const selectSaasCountry = (state) => state.saasCountries.saasCountry;

export const { updatePaginationSaasCountries } = saasCountriesSlice.actions;

export default saasCountriesSlice.reducer;
