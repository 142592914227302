import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getInvoicingList } from "./thunks";

const InvoicingAdapter = createEntityAdapter({
  selectId: (invoicing) => invoicing.id
});

const initialState = InvoicingAdapter.getInitialState({
  metadata: {},
  filter: {}
});

export const invoicingSlice = createSlice({
  name: "invoicing",
  initialState,
  reducers: {
    updatePaginationInvoicing(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterInvoicing(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoicingList.fulfilled, (state, { payload }) => {
      InvoicingAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectInvoicingById,
  selectIds: selectInvoicingIds,
  selectEntities: selectInvoicingEntities,
  selectAll: selectAllInvoicing,
  selectTotal: selectTotalInvoicing
} = InvoicingAdapter.getSelectors((state) => {
  return state.invoicing;
});

export const selectMetadata = (state) => state.invoicing.metadata;
export const selectFilter = (state) => state.invoicing.filter;
export const selectInvoicing = (state) => state.invoicing;

export const { updatePaginationInvoicing, updateFilterInvoicing } =
  invoicingSlice.actions;

export default invoicingSlice.reducer;
