import React from "react";
const CheckIcon = ({
  className,
  width = "20px",
  height = "20px",
  color = "#1F3998",
  onClick
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </svg>
  );
};
export { CheckIcon };
