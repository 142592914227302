import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import "./HeaderContent.scss";
import UserInfo from "../components/Users/UserInfo";
import { DriverProfileContext } from "./DriverProfileContext";

const HeaderContentDriver = () => {
  const profileDriverCtx = useContext(DriverProfileContext);
  const driverProfile = useMemo(() => profileDriverCtx.profile, [profileDriverCtx]);
  return (
    <div className="header-content">
      <div className="left">
        <div className="logo">
          <Link to="/driver">
            <img src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`} alt="logo" />
          </Link>
        </div>
        <div className="title">
          <h1 className="hide-title">
            <b>FLEXIO DRIVER</b>
          </h1>
        </div>
      </div>
      <div className="user-info">
        <UserInfo isUser={false} user={driverProfile} />
      </div>
    </div>
  );
};
export const HeaderContent = React.memo(HeaderContentDriver);
