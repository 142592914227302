import React from "react";

import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";

import AdminClientHeader from "../components/header/AdminClientHeader";
import ClientForm from "../components/form/ClientForm";
import "./AdminCreateUpdateClient.scss";

const AdminClientCreate = () => {
  const breadCrumbs = [{ text: "CLIENTS" }, { text: "CREATE CLIENT" }];
  return (
    <div className="admin-client-create admin-create-update-client">
      <AdminClientHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create Client</h2>
        <ClientForm isNewForm={true} />
      </div>
    </div>
  );
};

export default withPermission(AdminClientCreate, canCreatePermission);
