import { useEffect } from "react";
import { useScopeReducer } from "../../../../modules/shared/scope-provider";
import { notificationApi } from "./api";

const Action = {
  update: 0,
  unread: 1,
  read: 2
};

function reduce(state, action, payload) {
  switch (action) {
    case Action.update:
      return { ...state, ...payload };
    case Action.unread:
      payload.read = false;
      return { ...state, unreadCount: state.unreadCount + 1 };
    case Action.read:
      payload.read = true;
      return { ...state, unreadCount: state.unreadCount - 1 };
    default:
      return state;
  }
}

function useUserInfoScope() {
  return useScopeReducer(reduce, () => ({
    notifications: [],
    unreadCount: 0
  }));
}

export function useFetchNotifications(user) {
  const [scope, dispatch] = useUserInfoScope();
  const refresh = async () => {
    if (location?.pathname?.includes("admin")) return;
    if (location?.pathname?.includes("driver")) return;
    const result = await notificationApi.getList();

    dispatch(Action.update, {
      notifications: result.data,
      unreadCount: result.metadata.unreadCount || 0
    });
  };

  useEffect(() => {
    refresh().finally();
    // eslint-disable-next-line
  }, []);

  return scope;
}

export function useReadNotification() {
  const [scope, dispatch] = useUserInfoScope();

  const request = async (id) => {
    const notification = scope.notifications.find((it) => it.id === id);
    if (!notification) return;
    if (notification.read) return;
    dispatch(Action.read, notification);
    try {
      await notificationApi.setRead(id);
    } catch (e) {
      dispatch(Action.unread, notification);
    }
  };

  return (id) => {
    request(id).finally();
  };
}
