import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";

const DriverDirection = ({ routes }) => {
  return (
    <GeoJSONLayer
      data={{
        type: "FeatureCollection",
        features: routes.reduce((res, route) => {
          if (route?.display && route?.coords) {
            res.push({
              type: "Feature",
              properties: { color: route?.color },
              geometry: {
                type: "LineString",
                coordinates: route?.coords.map((c) => [c.lng, c.lat])
              }
            });
          }
          return res;
        }, [])
      }}
      linePaint={{
        "line-color": ["get", "color"],
        "line-width": 3,
        "line-opacity": 0.8
      }}
    />
  );
};

export default React.memo(DriverDirection);
