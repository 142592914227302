import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getVehicleCategories } from "./VehicleCategoryThunks";

const VehicleCategoryAdapter = createEntityAdapter({
  selectId: (vehicleCategory) => vehicleCategory.id
});

const initialState = VehicleCategoryAdapter.getInitialState({
  metadata: {},
  vehicleCategory: {}
});

export const driverVehicleCategorySlice = createSlice({
  name: "vehicleCategories",
  initialState,
  reducers: {
    updatePaginationVehicleCategories(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleCategories.fulfilled, (state, { payload }) => {
      console.log({ payload });

      VehicleCategoryAdapter.setAll(state, payload);
      state.vehicleCategory = {};
      state.metadata = {};
    });
  }
});

export const { selectAll: selectAllDriverVehicleCategories } =
  VehicleCategoryAdapter.getSelectors((state) => state.vehicleCategories);

export const selectMetadata = (state) => state.vehicleCategories.metadata;
export const selectVehicleCategory = (state) => state.vehicleCategories.vehicleCategory;

export const { updatePaginationVehicleCategories } = driverVehicleCategorySlice.actions;

export default driverVehicleCategorySlice.reducer;
