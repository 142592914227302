import OptimizeSvg from "../icons/optimizeSvg";
import ImproveSvg from "../icons/improveSvg";
import ReduceSvg from "../icons/reduceSvg";
import "./WelcomeOverlay.scss";
import React from "react";
import { Button } from "antd";

const WelcomeOverlay = ({ setShowWelcome }) => (
  <div className="container" id="welcome-dashboard-card-container">
    <div className="subContainer">
      <p className="welcomeTitle">
        Welcome to{" "}
        <span>
          <br></br>
          Flexio Dashboard
        </span>
      </p>
      <div className="iconsContainer">
        <div className="iconSingleContainer">
          <OptimizeSvg />
          <p className="iconDescription">Optimize Your Routes</p>
        </div>
        <div className="iconSingleContainer">
          <ImproveSvg />
          <p className="iconDescription">Improve Delivery Time</p>
        </div>
        <div className="iconSingleContainer">
          <ReduceSvg />
          <p className="iconDescription">Reduce Operational Cost</p>
        </div>
      </div>

      <p className="subDescription">
        Let us guide you in saving time with{" "}
        <span>
          <br></br>
          smarter route planning!
        </span>
      </p>

      <p style={{ textAlign: "center" }}>
        Add your drivers and start your delivery today
      </p>

      <Button
        className="button"
        onClick={() => {
          setShowWelcome(false);
        }}
      >
        Continue
      </Button>
    </div>
  </div>
);

export default WelcomeOverlay;
