import React from "react";
import "./MessageMobile.scss";

export default function MessageMobile() {
  return (
    <div className="message">
      For a better experience, please use a desktop or laptop.
    </div>
  );
}
