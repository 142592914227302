import request from "../../../../helper/request";

const driverApi = {
  getDriverList: (params) => {
    console.log(params);
    return request.get("/admin/drivers", {
      params
    });
  },
  createDriver: (data) => {
    console.log(data);
    return request.post("/admin/drivers", data);
  },
  importDrivers: (data) => {
    console.log(data);
    return request.post("/admin/drivers/import", data);
  },
  updateDriver: (data) => {
    const id = data.id;
    delete data.id;
    console.log(data);
    return request.patch(`/admin/drivers/${id}`, data);
  },
  getDriver: (id) => {
    console.log(id);
    return request.get(`/admin/drivers/${id}`);
  },
  delete: (id) => {
    return request.delete(`admin/drivers/${id}`);
  },
  getCities: () => {
    return request.get("/admin/drivers/all-cities");
  }
};

export default driverApi;
