import React from "react";
const CircleDotIcon = ({
  className,
  width = "20px",
  height = "20px",
  color = "#1F3998",
  onClick
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 512 512"
      width={width}
      fill={color}
      onClick={onClick}
    >
      <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 352C309 352 352 309 352 256C352 202.1 309 160 256 160C202.1 160 160 202.1 160 256C160 309 202.1 352 256 352z" />
    </svg>
  );
};
export { CircleDotIcon };
