import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getServiceList } from "./thunks";

const ServiceAdapter = createEntityAdapter({
  selectId: (service) => service.id
});

const initialState = ServiceAdapter.getInitialState({
  metadata: {}
});

export const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceList.fulfilled, (state, { payload }) => {
      ServiceAdapter.setAll(state, payload);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectServiceById,
  selectIds: selectServiceIds,
  selectEntities: selectServiceEntities,
  selectAll: selectAllServices,
  selectTotal: selectTotalServices
} = ServiceAdapter.getSelectors((state) => {
  return state.services;
});
export const selectMetadata = (state) => state.services.metadata;
export const selectFilter = (state) => state.services.filter;
export const selectService = (state) => state.services.service;

export default serviceSlice.reducer;
