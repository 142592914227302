export function buildFirstLevelFilter(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "id",
    "first_name",
    "last_name",
    "client_id",
    "client_type"
  ];

  const FILTER_VALUES = {
    id: "$cont",
    first_name: "$contL",
    last_name: "$contL",
    client_id: "$eq",
    client_type: "$eq"
  };

  return Object.keys(filter)
    .filter(
      (field) =>
        FIRST_LEVEL_FILTER.includes(field) &&
        filter[field] !== undefined &&
        filter[field] !== null &&
        filter[field] !== ""
    )
    .map(
      (filteredField) =>
        `${filteredField}||${FILTER_VALUES[filteredField]}||${filter[filteredField]}`
    );
}
