import { PHONE_VALIDATION } from "configs/constants";
import { NAME_REGEX } from "configs/constants";
import * as yup from "yup";

export const internalDriverForm = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(NAME_REGEX, "Invalid First Name"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(NAME_REGEX, "Invalid Last Name"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(PHONE_VALIDATION, "Phone is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().when("country", {
    is: "US",
    then: yup.string().required("State is required"),
    otherwise: yup.string().nullable()
  }),
  password: yup
    .string()
    .required("Password is required")
    .test("password-length", "Password must be at least 8 characters long", (value) => {
      if (value) {
        return value.length >= 8;
      }
      return true;
    })
    .test(
      "password-uppercase",
      "Password must contain at least 1 uppercase letter",
      (value) => {
        if (value) {
          return /[A-Z]/.test(value);
        }
        return true;
      }
    )
    .test(
      "password-special",
      "Password must contain at least 1 special character (!@#$%^&*)",
      (value) => {
        if (value) {
          return /[!@#$%^&*]/.test(value);
        }
        return true;
      }
    )
    .test("password-number", "Password must contain at least 1 number", (value) => {
      if (value) {
        return /[0-9]/.test(value);
      }
      return true;
    }),
  color: yup
    .string()
    .required("Color is required")
    .matches(/^#[0-9A-Fa-f]{6}$/, "Color Is not in correct format")
});
