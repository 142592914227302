/* eslint-disable react/display-name */
import { Button, Space } from "antd";
import React from "react";
import TableBase from "../../../../components/table/TableBase";
import StatusInList from "components/items/StatusInList";
import { INVOICE_TYPES } from "configs/constants";

const InvoicingListTable = ({ dataSource, pagination, onView }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 110,
      fixed: "left",
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    {
      title: "Total Time (hrs)",
      dataIndex: "actualTotalTime",
      width: 120,
      render: (value) => (value / 60).toFixed(2)
    },
    {
      title: "Total Route Distance (mi)",
      dataIndex: "distance",
      width: 120,
      render: (value) => (value / 1609.344).toFixed(2)
    },
    {
      title: "Total Packages",
      dataIndex: "noOfPackages",
      width: 120
    },
    {
      title: "Total Pickups",
      dataIndex: "noOfPickup",
      width: 120
    },
    {
      title: "Total Deliveries",
      dataIndex: "noOfDropOff",
      width: 120
    },
    {
      title: "Pickup at",
      dataIndex: "pickupDate",
      width: 120
    },
    {
      title: "Service",
      width: 150,
      render: (value) => <div>{value?.service || "N/A"}</div>
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 120,
      fixed: "right",
      render: (value) => {
        return (
          <Space size="middle">
            <StatusInList value={value} />
          </Space>
        );
      }
    },
    {
      title: "Final Price",
      width: 200,
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <>
            {window.CURRENCY_SYMBOL +
              `${parseFloat(record.clientFinalPrice || 0).toFixed(2)}`}
          </>
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              type="link"
              onClick={() => onView(record.id, INVOICE_TYPES.CLIENT_INVOICE)}
            >
              View
            </Button>
          </div>
        </Space>
      )
    }
  ];

  return (
    <div className="InvoicingTable">
      <TableBase
        columns={columns}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default InvoicingListTable;
