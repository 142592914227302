import React from "react";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

import { ApprovalIcon } from "components/svgs";
import CreatePasswordForm from "../../../client/drivers/forms/drivers/CreatePasswordForm";
import adminUserApi from "../store/api";
import "./AdminUserCreatePassword.scss";

const AdminUserCreatePassword = () => {
  const history = useHistory();
  const submitForm = async (values) => {
    try {
      const { error } = await adminUserApi.createAdminUserPassword(values);
      if (!error) {
        notification.success({
          message: "Update password successfully",
          placement: "bottomRight"
        });
        const timer = setTimeout(() => {
          history.push("/admin/login");
          clearTimeout(timer);
        }, 300);
      }
    } catch (e) {
      notification.error({
        message: JSON.parse(e.message || "{}")?.message,
        placement: "bottomRight"
      });
    }
  };

  return (
    <div className="create-password-page">
      <div className="background-section" />
      <div className="main-content-page">
        <div className="header-title">Create Password</div>
        <div className="wrap-content">
          <ApprovalIcon />
          <div className="green-text">Your account has been successfully created.</div>
          <div className="text">Please create your password to continue</div>
          <br />
          <CreatePasswordForm submitForm={submitForm} />
        </div>
      </div>
    </div>
  );
};
export default AdminUserCreatePassword;
