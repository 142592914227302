import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paginationFunc from "../../../../helper/hooks/pagination";
import { withFetchTablePagination } from "../../../../hocs/withFetchAll";
import SettingHeader from "../components/header/SettingHeader";
import TerritoryTable from "../components/table/TerritoryTable";
import { getTerritoryList } from "../store/TerritoryThunks";

import {
  selectAllTerritories,
  selectMetadata,
  updatePaginationTerritories
} from "../store/TerritorySlice";
import "../styles/settings.scss";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { canCreatePermission } from "configs/constants";

const params = { group: "flexio" };

const TerritoryList = () => {
  const dispatch = useDispatch();
  const territoryList = useSelector(selectAllTerritories);
  const metadata = useSelector(selectMetadata);
  const pagination = paginationFunc(
    metadata,
    "Vehicle Category",
    updatePaginationTerritories,
    dispatch
  );

  const breadCrumbs = [{ text: "SETTINGS" }, { text: "TERRITORIES" }];
  const buttonParams = allowPermission(useSelector(selectRole), canCreatePermission)
    ? {
        text: "Create Territory",
        link: "/admin/settings/territories/new"
      }
    : null;

  return (
    <div className="TerritoryList">
      <SettingHeader breadCrumbs={breadCrumbs} buttonParams={buttonParams} />
      <div className="admin-content">
        <TerritoryTable dataSource={territoryList} pagination={pagination} />
      </div>
    </div>
  );
};
export default withFetchTablePagination(TerritoryList, {
  fetchData: getTerritoryList,
  selectMeta: selectMetadata,
  params
});
