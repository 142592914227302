import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import request from "../../../../../helper/request";
import { doCreateTerritoryUser, doUpdateUserTerritory } from "../../store/thunks";
import "./TerritoryForm.scss";
import { NAME_REGEX } from "configs/constants";
const clientUserInit = {
  type: "company"
};

const TerritoryForm = ({ clientUser }) => {
  const [territories, setTerritories] = useState([]);
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/users/territories");
  };

  useEffect(() => {
    async function fetchTerritories() {
      const response = await request.get("admin/territories");
      setTerritories(response?.data || []);
    }

    fetchTerritories();
  }, []);

  const onSubmit = async (values) => {
    try {
      let erRs = undefined;
      if (!clientUser) {
        const { error } = await dispatch(
          doCreateTerritoryUser({
            ...values
          })
        );
        erRs = error;
      } else {
        if (!values.password) delete values.password;
        const { error } = await dispatch(
          doUpdateUserTerritory({
            ...values,
            id: clientUser?.id
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/users/territories");
    } catch {
      return false;
    }
  };

  useEffect(() => {
    let dataForm = clientUserInit;
    if (clientUser) {
      dataForm = {
        ...clientUser,
        groupId: clientUser.client?.id
      };
    }
    form.setFieldsValue(dataForm);
  }, [clientUser, form]);
  return (
    <div className="TerritoryForm ClientForm">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item name="territoryId" label="Territory" rules={[{ required: true }]}>
            <Select placeholder="Territory" rules={[{ required: true }]} allowClear>
              {territories.map((territory) => (
                <Select.Option key={territory.id} value={territory.id}>
                  {territory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input disabled={!!clientUser} size="large" placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid First Name"
              }
            ]}
          >
            <Input size="large" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid Last Name"
              }
            ]}
          >
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone">
            <PhoneInput country="ca" enableSearch />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select size="large" placeholder="Please Select">
              <Select.Option value="admin">Super Admin</Select.Option>
              <Select.Option value="manager">Manager</Select.Option>
              <Select.Option value="viewer">View Only</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: !clientUser, message: "Required" }]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {clientUser ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default TerritoryForm;
