import React from "react";
const InvoicingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5630_10267)">
        <path
          d="M11.6667 1.66699H5C4.08333 1.66699 3.33333 2.41699 3.33333 3.33366V16.667C3.33333 17.5837 4.08333 18.3337 5 18.3337H15C15.9167 18.3337 16.6667 17.5837 16.6667 16.667V6.66699L11.6667 1.66699ZM12.5 9.16699H9.16667V10.0003H11.6667C12.125 10.0003 12.5 10.3753 12.5 10.8337V13.3337C12.5 13.792 12.125 14.167 11.6667 14.167H10.8333V15.0003H9.16667V14.167H7.5V12.5003H10.8333V11.667H8.33333C7.875 11.667 7.5 11.292 7.5 10.8337V8.33366C7.5 7.87533 7.875 7.50033 8.33333 7.50033H9.16667V6.66699H10.8333V7.50033H12.5V9.16699Z"
          fill="#F4F4F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_5630_10267">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { InvoicingIcon };
