import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { doCreatePackageType, doUpdatePackageType } from "../../store/PackageTypeThunks";
import "../../styles/settings.scss";

const clientUserInit = {
  type: "company"
};

const PackageTypeForm = ({ packageType }) => {
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const [showPoolPrice, setShowPoolPrice] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/settings/package-types");
  };

  const onSubmit = async (values) => {
    try {
      let erRs = undefined;
      if (!packageType) {
        const { error } = await dispatch(
          doCreatePackageType({
            ...values
          })
        );
        erRs = error;
      } else {
        const { error } = await dispatch(
          doUpdatePackageType({
            ...values,
            id: packageType?.id
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/settings/package-types");
    } catch {
      return false;
    }
  };

  useEffect(() => {
    let dataForm = clientUserInit;
    if (packageType) {
      dataForm = {
        ...packageType,
        groupId: packageType.client?.id
      };
    }
    form.setFieldsValue(dataForm);
    setShowPoolPrice(packageType?.isPool || false);
  }, [packageType, form]);
  return (
    <div className="common-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          <Form.Item
            label="Package Type Name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Package Type Name" />
          </Form.Item>
          <Form.Item
            label="Max Weight"
            name="maxWeight"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Weight" suffix="lbs" />
          </Form.Item>
          <Form.Item
            label="Max Length"
            name="maxLength"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Length" suffix="inches" />
          </Form.Item>
          <Form.Item
            label="Max Volume"
            name="maxVolume"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input type="number" placeholder="Max Volume" suffix="curf" />
          </Form.Item>
          <Form.Item
            label="Is Pool?"
            name="isPool"
            valuePropName="checked"
            initialValue={packageType?.isPool || false}
          >
            <Checkbox onChange={() => setShowPoolPrice((prevValue) => !prevValue)} />
          </Form.Item>
          {showPoolPrice && (
            <Form.Item
              label="Pool Price"
              name="poolPrice"
              initialValue={packageType?.poolPrice || ""}
            >
              <Input placeholder="Pool Price" prefix={window.CURRENCY_SYMBOL} />
            </Form.Item>
          )}
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {packageType ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default PackageTypeForm;
