import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getVehicleTypeList } from "./thunks";

const vehicleTypesAdapter = createEntityAdapter();

const initialState = vehicleTypesAdapter.getInitialState({
  metadata: {}
});

export const vehicleTypesSlice = createSlice({
  name: "vehicleTypes",
  initialState,
  reducers: {
    setAllRoutePlans: vehicleTypesAdapter.setAll
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleTypeList.fulfilled, (state, { payload }) => {
      vehicleTypesAdapter.setAll(state, payload);
      state.metadata = payload.metadata;
    });
  }
});

export const {
  selectById: selectVehicleTypeById,
  selectIds: selectVehicleTypeIds,
  selectEntities: selectVehicleTypeEntities,
  selectAll: selectAllVehicleTypes,
  selectTotal: selectTotalVehicleTypes
} = vehicleTypesAdapter.getSelectors((state) => state.vehicleTypes);

export const selectMetadata = (state) => state.vehicleTypes.metadata;

export default vehicleTypesSlice.reducer;
