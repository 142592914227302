import React from "react";
import { useSelector } from "react-redux";
import withFetchOne from "../../../../hocs/withFetchOne";
import ServiceForm from "../components/form/ServiceForm";
import SettingHeader from "../components/header/SettingHeader";
import { doGetService } from "../store/ServiceThunks";
import { selectService } from "../store/ServiceSlice";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canEditPermission } from "configs/constants";

const ServiceEdit = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/services", text: "SERVICES" },
    { text: "EDIT" }
  ];
  const service = useSelector(selectService);
  return (
    <div className="large-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Edit Service</h2>
        <ServiceForm service={service} />
      </div>
    </div>
  );
};
export default withPermission(
  withFetchOne(ServiceEdit, {
    fetchData: doGetService
  }),
  canEditPermission
);
