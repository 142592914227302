import * as React from "react";
const ReduceSvg = (props) => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 96 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={48} cy={48.5} rx={48} ry={48.5} fill="#00A9D6" />
    <path
      d="M39.3201 61.0152C30.7801 55.3252 30.9501 55.1352 30.0001 55.6452C29.0701 56.1452 29.3001 56.1852 29.3001 64.7752C29.3001 65.7352 29.1401 65.3852 37.8501 71.1852C38.2601 71.4552 38.7801 71.4852 39.2101 71.2552C40.1401 70.7552 39.9101 70.7152 39.9101 62.1252C39.9101 61.6852 39.6901 61.2652 39.3201 61.0252V61.0152ZM37.2501 67.5952L31.9501 64.0552V59.2852L37.2501 62.8252V67.5952Z"
      fill="white"
    />
    <path
      d="M78.9601 59.6058L71.7401 55.9957V32.9458C71.7401 31.7958 72.9901 32.8558 44.5301 17.1958C43.6201 16.6958 45.5101 15.9558 16.7301 31.7858C15.9601 32.2058 16.0401 33.1057 16.0401 32.9357C16.0401 65.7857 15.7601 64.0257 16.6801 64.5857C45.0101 81.5857 43.3201 80.6857 43.8901 80.6857C44.4001 80.6857 43.6301 81.0658 58.2701 72.2858C61.1401 77.7558 66.6501 80.6857 67.7601 80.6857C69.4501 80.6857 78.3501 75.6758 79.2801 65.4458C79.6601 61.2358 80.0801 60.1658 78.9601 59.6058ZM56.5501 59.6058C55.5801 60.0958 55.8201 60.9358 55.9801 62.6358C52.2601 65.1058 51.8401 65.1658 51.8401 66.1058V73.0458L45.2101 77.0257V49.6157L51.8401 45.6358C51.8401 51.8858 51.6301 52.1257 52.4701 52.6457C52.8501 52.8857 53.3401 52.9158 53.7601 52.7058C62.3401 48.4158 62.4501 48.6458 62.4501 47.5458V39.2757L69.0801 35.2958V54.6757C67.3301 53.7957 68.9501 53.4157 56.5501 59.6157V59.6058ZM56.2601 65.6257C56.4101 67.0757 56.7301 68.4758 57.2001 69.8258L54.5001 71.4458V66.8057L56.2701 65.6257H56.2601ZM18.6901 35.2858L42.5601 49.6058V77.0158L18.6901 62.6958V35.2858ZM36.2101 24.0857L58.8101 38.3558C54.8901 40.7058 56.1501 39.9458 53.1901 41.7258L30.5201 27.2157L36.2101 24.0857ZM59.8001 40.8558V46.7157L54.5001 49.3657V44.0358L59.8001 40.8558ZM43.8801 19.8657L67.7401 32.9858L61.3501 36.8158L38.8601 22.6157L43.8701 19.8558L43.8801 19.8657ZM27.8701 28.6658L50.6501 43.2457L43.8801 47.3057L20.0201 32.9858L27.8701 28.6658ZM76.6301 65.1958C76.1301 70.7258 72.7501 75.5357 67.7501 77.8957C62.7601 75.5357 59.3801 70.7258 58.8701 65.1958L58.5401 61.5658L67.7501 56.9657L76.9601 61.5658L76.6301 65.1958Z"
      fill="white"
    />
    <path
      d="M66.4301 61.0358C62.2101 62.5458 63.3301 68.7458 67.7601 68.7458C68.4901 68.7458 69.0901 69.3458 69.0901 70.0758C69.0901 71.8258 66.4401 71.8258 66.4401 70.0758C66.4401 68.3258 63.7901 68.3158 63.7901 70.0758C63.7901 71.8358 64.9001 73.2558 66.4401 73.8058C66.4401 75.9158 69.0901 75.9058 69.0901 73.8058C73.3101 72.2958 72.1901 66.0958 67.7601 66.0958C67.0301 66.0958 66.4301 65.4958 66.4301 64.7658C66.4301 63.0158 69.0801 63.0158 69.0801 64.7658C69.0801 66.5158 71.7301 66.5258 71.7301 64.7658C71.7301 63.0058 70.6201 61.5858 69.0801 61.0358C69.0801 58.9458 66.4301 58.9158 66.4301 61.0358Z"
      fill="white"
    />
  </svg>
);
export default ReduceSvg;
