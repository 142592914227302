/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import AdminBreadCrumb from "../../../../components/AdminBreadCrumb";
import { ExportIcon } from "components/svgs/ExportIcon";
import { FilterIcon } from "components/svgs";

const FlexioPoolHeader = ({
  breadCrumbs,
  buttonParams,
  showFilter,
  setShowFilter,
  hasButton = true,
  showExport = false,
  onExport,
  exporting,
  setBatchStatusUpdate,
  setShowUpdateStatus,
  hideBatchStatusUpdate
}) => {
  return (
    <div className="common-header" style={{ padding: "0em 1.8em" }}>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {/* Added batch status update button */}
      {hasButton && (
        <div className="buttons d-flex flex-row gap-2">
          <Button
            size="small"
            className={`svg-icon d-flex flex-row batch-right`}
            onClick={() => {
              setShowUpdateStatus(true);
              setBatchStatusUpdate(true);
            }}
            hidden={hideBatchStatusUpdate}
          >
            Batch Status Update
          </Button>
          <Button
            size={showFilter === undefined ? "small" : showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter === true && "filter"} d-flex flex-row`}
            onClick={() =>
              setShowFilter((value) =>
                value === true || value === undefined ? false : true
              )
            }
          >
            Filter <FilterIcon />
          </Button>
          {showExport && (
            <Button
              size="small"
              type="third"
              className={`svg-icon d-flex flex-row`}
              onClick={onExport}
              loading={exporting}
            >
              Export <ExportIcon />
            </Button>
          )}
          {buttonParams && (
            <Link to={buttonParams.link}>
              <Button type="primary" size="small">
                {buttonParams.text}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default FlexioPoolHeader;
