import React from "react";
import SettingHeader from "../components/header/SettingHeader";
import PackageTypeForm from "../components/form/PackageTypeForm";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";

const PackageTypeNew = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/package-types", text: "PACKAGE TYPES" },
    { text: "NEW" }
  ];
  return (
    <div className="medium-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create Package Type</h2>
        <PackageTypeForm />
      </div>
    </div>
  );
};
export default withPermission(PackageTypeNew, canCreatePermission);
