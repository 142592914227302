import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { InputHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "modules/admin/clients/components/header/AdminFilter.scss";
import { useSelector } from "react-redux";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getClientList } from "modules/admin/clients/store/thunks";
import { useDispatch } from "react-redux";
import { SelectHook } from "components/hook-forms";
import { CLIENT_TYPES } from "configs/constants";

const FilterClientUser = ({
  defaultValues,
  setShowFilter,
  handleClickFilter,
  onReset
}) => {
  const dispatch = useDispatch();
  const clients = useSelector(selectAllClients);
  const {
    reset,
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    dispatch(getClientList({ pageSize: 1000 }));
  }, [dispatch]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  return (
    <div className="admin-filter">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter">
          <InputHook
            control={control}
            placeholder="ID"
            label="ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="First Name"
            label="First Name"
            name="first_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Last Name"
            label="Last Name"
            name="last_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="Client"
            label="Client"
            name="client_id"
            valueSelect="id"
            labelSelect="clientName"
            options={[{ clientName: "Client", id: "" }, ...clients]}
            required
            errors={errors}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Client Type"
            name="clientType"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={[{ name: "All", value: "" }, ...CLIENT_TYPES]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
        <div className="wrap-button">
          <Button type="link" className="collapse" onClick={() => setShowFilter(false)}>
            Collapse <UpOutlined />
          </Button>
          <Button
            type="link"
            className="svg-icon clear"
            onClick={() => {
              reset();
              onReset();
            }}
          >
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FilterClientUser;
