import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { InputHook, SelectHook, RangePickerHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import { getClientList } from "modules/admin/clients/store/thunks";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { selectAllServices } from "modules/client/service/store/slice";
import { getServiceList } from "modules/client/service/store/thunks";
import { isInternalDriverService } from "modules/client/flexio-services/helper";
import { INVOICING_STATUS_SELECT } from "modules/client/routes/constants";

import "../../routes/components/header/FilterRoute.scss";

const FilterInvoicing = ({ isAdmin, setShowFilter, handleClickFilter }) => {
  const {
    reset,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });
  const clients = useSelector(selectAllClients);
  const services = useSelector(selectAllServices);
  const filteredServices = isAdmin
    ? services
    : services.filter(({ name }) => !isInternalDriverService(name));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceList({ pageSize: 1000 }));
    if (isAdmin) {
      dispatch(getClientList({ pageSize: 1000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAdmin]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  const handleReset = async () => {
    await reset();
    handleClickFilter(undefined);
  };

  return (
    <div className="filter-order-component">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter three-column">
          <InputHook
            control={control}
            placeholder="ID"
            label="ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <RangePickerHook
            control={control}
            getValues={getValues}
            label="Pickup Date Range"
            name="pickupDateRange"
            errors={errors}
            showOptionsName={false}
          />
          {isAdmin && (
            <SelectHook
              mode="multiple"
              allowClear
              showOptionsName={false}
              control={control}
              getValues={getValues}
              placeholder="Clients"
              label="Clients"
              name="clientIds"
              valueSelect="id"
              labelSelect="clientName"
              options={clients}
              defaultValue={[]}
              errors={errors}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            />
          )}
          <SelectHook
            mode="multiple"
            allowClear
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="Services"
            label="Services"
            name="serviceIds"
            valueSelect="id"
            labelSelect="name"
            options={filteredServices}
            defaultValue={[]}
            errors={errors}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
          <SelectHook
            mode="multiple"
            allowClear
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="All"
            label="Status"
            name="status"
            valueSelect="value"
            labelSelect="name"
            required
            errors={errors}
            options={INVOICING_STATUS_SELECT}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
        <div className="wrap-button">
          <Button type="link" className="collapse" onClick={() => setShowFilter(false)}>
            Collapse <UpOutlined />
          </Button>
          <Button type="link" className="svg-icon clear" onClick={handleReset}>
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(FilterInvoicing);
