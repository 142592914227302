import React from "react";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";

import request from "../../../helper/request";

const ForgotPasswordPage = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [step, setStep] = useState("forgot_password"); // 'forgot_password' | 'verify_code' | 'new_password'
  const onSubmitForgot = async (values) => {
    setIsLoading(true);
    try {
      await request.patch("/client/auth/forgot-password", values);
      setEmail(values.email);
      setStep("verify_code");
      notification.success({
        message: "Please check your email!",
        placement: "bottomRight"
      });
      setIsLoading(false);
    } catch (e) {
      const error = JSON.parse(e.message);
      notification.error({
        message: error.message,
        placement: "bottomRight"
      });
      setIsLoading(false);
    }
  };
  const onSubmitCode = async (values) => {
    setIsLoading(true);

    try {
      await request.patch("/client/auth/verify-code", { ...values, email });
      setCode(values.code);
      setStep("new_password");
      notification.success({
        message: "Submit code successfully!",
        placement: "bottomRight"
      });
      setIsLoading(false);
    } catch (e) {
      const error = JSON.parse(e.message);

      notification.error({
        message: error.message,
        placement: "bottomRight"
      });
      setIsLoading(false);
    }
  };
  const onSubmitNewPassword = async (values) => {
    setIsLoading(true);

    try {
      await request.patch("/client/auth/new-password", { ...values, email, code });
      notification.success({
        message: "Create new password successfully",
        placement: "bottomRight"
      });
      history.push("/");
      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
      if (e.message) {
        const error = JSON.parse(e.message);
        notification.error({
          message: error.message,
          placement: "bottomRight"
        });
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="forgot-password-page d-flex flex-items-center flex-justify-center">
      {step === "forgot_password" && (
        <div className="wrap-content bg-white rounded p-4">
          <div className="text-primary font-24">Forgot Password</div>
          <div className="text-primary pt-1 pb-2">
            We have sent you a verification code to your email. Please enter it to update
            your password. The verification code will be valid for 30 minutes.
          </div>
          <Form
            scrollToFirstError={true}
            layout="vertical"
            hideRequiredMark
            colon={false}
            onFinish={onSubmitForgot}
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { type: "email", message: "Invalid Email" },
                { required: true, message: "Required" }
              ]}
            >
              <Input size="large" placeholder="Email Address" />
            </Form.Item>

            <Button loading={isLoading} type="primary" htmlType="submit" block>
              Reset Password
            </Button>
          </Form>
        </div>
      )}

      {step === "verify_code" && (
        <div className="wrap-content bg-white rounded p-4">
          <div className="text-primary font-24">Forgot Password</div>
          <div className="text-primary pt-1 pb-2">
            We have sent you a verification code to your email. Please enter it to update
            your password. The verification code will be valid for 30 minutes.
          </div>
          <Form
            scrollToFirstError={true}
            layout="vertical"
            hideRequiredMark
            colon={false}
            onFinish={onSubmitCode}
          >
            <Form.Item
              label="Verification Code"
              name="code"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input size="large" placeholder="Verification Code" />
            </Form.Item>

            <Button loading={isLoading} type="primary" htmlType="submit" block>
              Confirm <CheckOutlined />
            </Button>
          </Form>
        </div>
      )}

      {step === "new_password" && (
        <div className="wrap-content bg-white rounded p-4">
          <div className="text-primary font-24">Forgot Password</div>
          <Form
            scrollToFirstError={true}
            layout="vertical"
            hideRequiredMark
            colon={false}
            onFinish={onSubmitNewPassword}
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password size="large" placeholder="Confirm New Password" />
            </Form.Item>

            <Button loading={isLoading} type="primary" htmlType="submit" block>
              Confirm <CheckOutlined />
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};
export default ForgotPasswordPage;
