import { Button } from "antd";
import { CheckCircleIcon } from "components/svgs";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { doGetDriver } from "../store/thunks";
import "./ActionButtons.scss";
const ActionButtons = ({ text }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const id = params?.id;
  return (
    <div className="action-buttons">
      {id && (
        <Button
          onClick={() => {
            if (id) dispatch(doGetDriver(id));
          }}
        >
          Revert
        </Button>
      )}
      {!id && (
        <Button
          onClick={() => {
            history.push("/admin/users/drivers");
          }}
        >
          Cancel
        </Button>
      )}
      <Button type="primary" className="svg-icon" htmlType="submit">
        {text} &nbsp;
        <CheckCircleIcon color="#FFFFFF" />
      </Button>
    </div>
  );
};
export default React.memo(ActionButtons);
