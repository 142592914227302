import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import {
  deleteClient,
  doCreateClient,
  doGetClient,
  doUpdateClient,
  getClientList
} from "./thunks";

const ClientsAdapter = createEntityAdapter({
  selectId: (client) => client.id
});

const initialState = ClientsAdapter.getInitialState({ metadata: {}, client: null });

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    updatePaginationClients(state, action) {
      const oldMeta = state.metadata;
      state.metadata = {
        ...oldMeta,
        ...action.payload
      };
    },
    updateFilterClients(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientList.fulfilled, (state, { payload }) => {
      ClientsAdapter.setAll(state, payload.data);
      state.metadata = payload.metadata;
    });
    builder.addCase(doCreateClient.fulfilled, (state, { payload }) => {
      ClientsAdapter.addOne(state, payload);
      state.metadata = payload.metadata;
    });
    builder.addCase(doUpdateClient.fulfilled, (state, { payload }) => {
      ClientsAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.metadata = payload.metadata;
      state.client = null;
    });
    builder.addCase(doGetClient.fulfilled, (state, { payload }) => {
      ClientsAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
      state.client = payload;
    });
    builder.addCase(deleteClient.fulfilled, (state, { payload }) => {
      const oldMeta = state.metadata;
      ClientsAdapter.removeOne(state, payload.id);
      state.metadata = {
        ...oldMeta,
        totalItems: oldMeta.totalItems ? oldMeta.totalItems - 1 : undefined
      };
    });
  }
});

export const {
  selectById: selectClientById,
  selectIds: selectClientIds,
  selectEntities: selectClientEntities,
  selectAll: selectAllClients,
  selectTotal: selectTotalClients
} = ClientsAdapter.getSelectors((state) => {
  return state.clients;
});

export const selectMetadata = (state) => state.clients.metadata;
export const selectClient = (state) => state.clients.client;
export const selectFilter = (state) => state.clients.filter;

export const { updatePaginationClients, updateFilterClients } = clientSlice.actions;

export default clientSlice.reducer;
