import { Card } from "antd";
import React from "react";

const SimpleCards = ({ title, amount, description = "", ...props }) => {
  return (
    <>
      <Card {...props}>
        <p className="simple-card-title">{title}</p>
        <p className="simple-card-amount">{amount}</p>
        <p className="simple-card-small-text">{description}</p>
      </Card>
    </>
  );
};

export default SimpleCards;
