import { createAsyncThunk } from "@reduxjs/toolkit";
import { packageTypeApi } from "./api";

export const getPackageTypeList = createAsyncThunk("get/package-types", async () => {
  return await packageTypeApi.getList();
});

export const getPoolPackageTypeList = createAsyncThunk("get/package-types", async () => {
  return await packageTypeApi.getPoolList();
});
