import request from "../../../../helper/request";

const routeHistoryApi = {
  getList: (params) => {
    return request.get("/routes/history", {
      params
    });
  }
};

export default routeHistoryApi;
