import React from "react";
import SettingHeader from "../components/header/SettingHeader";
import SaasCountriesForm from "../components/form/SaasCountriesForm";
import "../styles/settings.scss";
import { withPermission } from "hocs/withPermission";
import { canCreatePermission } from "configs/constants";

const SaasCountriesNew = () => {
  const breadCrumbs = [
    { text: "SETTINGS" },
    { url: "/admin/settings/saas-countries/new", text: "COUNTRIES" },
    { text: "NEW" }
  ];
  return (
    <div className="large-modal">
      <SettingHeader breadCrumbs={breadCrumbs} hasButton={false} />
      <div className="admin-content form-content">
        <h2 className="title">Create a Saas Country</h2>
        <SaasCountriesForm />
      </div>
    </div>
  );
};
export default withPermission(SaasCountriesNew, canCreatePermission);
