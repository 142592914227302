/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import { canEditPermission } from "configs/constants";
import { canDeletePermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TableBase from "../../../../../components/table/TableBase";
import { doDeleteVehicleCategory } from "../../store/VehicleCategoryThunks";

const VehicleCategoryTable = ({ dataSource, pagination }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDelete = (vehicleCategory) => {
    dispatch(doDeleteVehicleCategory(vehicleCategory));
  };

  const handleEdit = (record) => {
    history.push(`/admin/settings/vehicle-categories/${record.id}`);
  };
  const columns = [
    {
      title: "Vehicle Category Name",
      dataIndex: "name",
      width: 200,
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}>{value}</p>;
      }
    },
    {
      title: "Max Length (in)",
      dataIndex: "maxLength"
    },
    {
      title: "Max Weight (lbs)",
      dataIndex: "maxWeight"
    },
    {
      title: "Max Volume (cuft)",
      dataIndex: "maxVolume"
    },
    {
      title: "Package Types",
      dataIndex: "packageTypes",
      width: 400,
      render: (value) => {
        return value && value.map((o) => o.name)?.join(", ");
      }
    }
  ];
  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <img
                src="/icons/edit.svg"
                className={`table-status ${value}`}
                onClick={() => handleEdit(record)}
              />
            )}
            {canDelete && (
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <img src="/icons/delete.svg" className={`table-status ${value}`} />
              </Popconfirm>
            )}
          </Space>
        );
      }
    });
  }
  return (
    <TableBase columns={columns} data={dataSource} pagination={pagination} rowKey="id" />
  );
};
export default VehicleCategoryTable;
