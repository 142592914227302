/* eslint-disable react/display-name */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Button } from "antd";

import AdminBreadCrumb from "components/AdminBreadCrumb";
import "./ClientUserHeader.scss";
import { FilterIcon } from "components/svgs";

const ClientUserHeader = ({
  breadCrumbs,
  showFilter,
  setShowFilter,
  onCreate,
  showCreateButton
}) => {
  return (
    <div className={`order-header-component my-4 ${showFilter && "filter"}`}>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      <div className="buttons d-flex flex-justify-end gap-3">
        <Button
          size={showFilter ? "normal" : "small"}
          className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
          onClick={() => setShowFilter(!showFilter)}
        >
          Filter <FilterIcon />
        </Button>
        {showCreateButton && (
          <Button type="secondary" size={"small"} onClick={onCreate}>
            Create Company User <PlusOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};
export default ClientUserHeader;
