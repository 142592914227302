/* eslint-disable react/display-name */
import { Button, Space } from "antd";
import React from "react";
import TableBase from "../../../../components/table/TableBase";
import StatusInList from "components/items/StatusInList";
import { showInfo } from "helper/util";
import { INVOICE_TYPES } from "configs/constants";
import { FileMarkdownOutlined } from "@ant-design/icons";

const InvoicingListTable = ({ dataSource, pagination, onView }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 110,
      fixed: "left",
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    {
      title: "Client",
      dataIndex: "client",
      width: 120,
      render: (value) => showInfo(value?.clientName)
    },
    {
      title: "Total Time (hrs)",
      dataIndex: "actualTotalTime",
      width: 120,
      render: (value) => (value / 60).toFixed(2)
    },
    {
      title: "Total Route Distance (mi)",
      dataIndex: "distance",
      width: 120,
      render: (value) => (value / 1609.344).toFixed(2)
    },
    {
      title: "Total Packages",
      dataIndex: "noOfPackages",
      width: 120
    },
    {
      title: "Total Pickups",
      dataIndex: "noOfPickup",
      width: 120
    },
    {
      title: "Total Deliveries",
      dataIndex: "noOfDropOff",
      width: 120
    },
    {
      title: "Pickup at",
      dataIndex: "startDate",
      width: 120
    },
    {
      title: "Service",
      dataIndex: "service",
      width: 150,
      render: (value) => showInfo(value?.name)
    },
    {
      title: "Job Status",
      dataIndex: "status",
      width: 120,
      fixed: "right",
      render: (value) => {
        return (
          <Space size="middle">
            <StatusInList value={value} />
          </Space>
        );
      }
    },
    {
      title: () => <div style={{ textAlign: "center" }}>Final Price</div>,
      width: 180,
      fixed: "right",
      render: (_, record) => (
        <>
          <Space size="middle">
            <div>
              {window.CURRENCY_SYMBOL +
                `${parseFloat(record.clientFinalPrice || 0).toFixed(2)}`}
              <div className="ml-1 d-inline-block">
                {record?.finalPrices.find(
                  (finalPrice) => finalPrice.type === "client_final_price"
                )?.manualUpdate ? (
                  <FileMarkdownOutlined />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <Button
                type="link"
                onClick={() => onView(record.id, INVOICE_TYPES.CLIENT_INVOICE)}
              >
                View
              </Button>
            </div>
          </Space>
        </>
      )
    },
    {
      title: () => <div style={{ textAlign: "center" }}>Driver Final Price</div>,
      width: 180,
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <div>
            {window.CURRENCY_SYMBOL +
              `${parseFloat(record.driverFinalPrice || 0).toFixed(2)}`}
            <div className="ml-1 d-inline-block">
              {record?.finalPrices.find(
                (finalPrice) => finalPrice.type === "client_final_price"
              )?.manualUpdate ? (
                <FileMarkdownOutlined />
              ) : (
                ""
              )}
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              type="link"
              onClick={() => onView(record.id, INVOICE_TYPES.DRIVER_INVOICE)}
            >
              View
            </Button>
          </div>
        </Space>
      )
    }
  ];

  return (
    <div className="InvoicingTable">
      <TableBase
        columns={columns}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default InvoicingListTable;
