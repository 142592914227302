import { Rate } from "antd";
import TableBase from "components/table/TableBase";
import { shortId } from "helper/util";
import pluralize from "pluralize";
import React from "react";

const FeedbackTable = ({ dataSource }) => {
  const columns = [
    {
      title: "Feedback ID",
      width: 120,
      dataIndex: "id",
      // eslint-disable-next-line react/display-name
      render: (value) => <strong>{shortId(value)}</strong>
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 150
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      width: 120
    },
    {
      title: "Driver ID",
      dataIndex: "driverId",
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (value) => <strong>{shortId(value)}</strong>
    },
    {
      title: "Job ID",
      dataIndex: "jobId",
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (value) => <strong>{shortId(value)}</strong>
    },
    {
      title: "Rating",
      dataIndex: "score",
      width: 170,
      // eslint-disable-next-line react/display-name
      render: (value) => <Rate value={value} />
    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 170,
      render: (value) => value || "N/A"
    }
  ];

  return (
    <div className="ActiveOrdersTable">
      <TableBase
        columns={columns}
        data={dataSource}
        pagination={{
          position: ["bottomCenter"],
          total: (total) => total,
          defaultPageSize: 10,
          showTotal: (total) => pluralize("Feedback", total, true)
        }}
        rowKey="id"
      />
    </div>
  );
};
export default FeedbackTable;
