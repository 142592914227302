import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { Button, Modal, Select } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import paginationFunc from "../../../../helper/hooks/pagination";
import RouteHeader from "../components/header";
import RouteTable from "../components/table/RouteTable";
import {
  getRouteList,
  assignDriver,
  reassignDriver,
  cancelRoute,
  unassignDriver
} from "../store/RouteThunks";
import { selectAllDrivers } from "modules/admin/drivers/store/slice";
import { getDriverList } from "modules/admin/drivers/store/thunks";
import {
  selectAllRoutes,
  selectMetadata,
  updatePaginationRoutes,
  selectFilter,
  updateFilterRoutes
} from "../store/RouteSlice";
import ImportLocations from "modules/client/routes/components/exports";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import { doExportCsv } from "helper/export";
import FilterRoute from "modules/client/routes/components/header/FilterRoute";
import { buildFirstLevelFilterRoute } from "modules/shared/PickupDropoff/helper";
import { setSuccessMessage } from "app/global/slice";
import { allowPermission, fullName } from "helper/util";
import { selectGroup, selectRole } from "modules/auth/store/authSlice";
import { UserGroup, canCreatePermission } from "configs/constants";
import { buildFirstLevelFilter } from "modules/admin/clients/helper";

import "../styles/routes.scss";
import ImportHistoricalJobs from "modules/client/routes/components/exports/ImportHistoricalJobs";

const params = { group: "flexio" };

const RouteList = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showImportHistorical, setShowImportHistorical] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showAssignDriver, setShowAssignDriver] = useState(false);
  const [currentRoute, setCurrentRoute] = useState({});
  const [assignedDriverId, setAssignedDriverId] = useState("");
  const [clientDrivers, setClientDrivers] = useState([]);
  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();
  const routeList = useSelector(selectAllRoutes);
  const metadata = useSelector(selectMetadata);
  const filter = useSelector(selectFilter);
  const pagination = paginationFunc(
    { ...metadata, itemsPerPage: _get(metadata, "itemsPerPage", 10) },
    "Active Job",
    updatePaginationRoutes,
    dispatch
  );

  const breadCrumbs = [{ text: "ACTIVE ROUTES" }];
  const canCreate = allowPermission(useSelector(selectRole), canCreatePermission);

  const buttonParams =
    canCreate && isFlexioAdmin
      ? {
          text: "Create New Job",
          link: "/admin/jobs/new?action=new"
        }
      : null;
  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 10;
  const allDrivers = useSelector(selectAllDrivers);

  useEffect(() => {
    dispatch(getPackageTypeList());
    dispatch(
      getDriverList({
        pageSize: 3000,
        filter: buildFirstLevelFilter({ active: true, is_internal_driver: false })
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!allDrivers?.length) return;
    setClientDrivers(allDrivers);
    // eslint-disable-next-line
  }, [allDrivers]);

  const refreshRoutes = () => {
    const { pickupDateRange = [] } = filter || {};
    dispatch(
      getRouteList({
        ...params,
        page: page,
        pageSize: pageSize,
        filter: buildFirstLevelFilterRoute(
          pickupDateRange?.length === 2
            ? {
                ...filter,
                startPickupDate: pickupDateRange[0],
                endPickupDate: pickupDateRange[1]
              }
            : filter
        ),
        pickupFilter: filter
      })
    );
  };

  useEffect(() => {
    refreshRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleExportRoutes = async () => {
    try {
      const { pickupDateRange = [] } = filter;
      setExporting(true);
      await doExportCsv({
        path: "routes/export",
        name: `Active-Jobs-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilterRoute(
            pickupDateRange?.length === 2
              ? {
                  ...filter,
                  startPickupDate: pickupDateRange[0],
                  endPickupDate: pickupDateRange[1]
                }
              : filter
          ),
          pickupFilter: filter
        }
      });
    } finally {
      setExporting(false);
    }
  };

  const handleClickFilter = (values) => {
    dispatch(updateFilterRoutes(values));
  };

  const handleAssignDriverClick = (route) => {
    setCurrentRoute(route);
    setAssignedDriverId(route?.driverId);
    setShowAssignDriver(true);
  };
  const handleUnassignDriver = async (routeId) => {
    try {
      const { error } = await dispatch(unassignDriver(routeId));
      if (!error) {
        dispatch(setSuccessMessage("Driver removed successfully!"));
        refreshRoutes();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitAssignDriver = async () => {
    if (!assignedDriverId) return;
    try {
      const { error } = await dispatch(
        currentRoute?.driverId
          ? reassignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
          : assignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
      );
      if (!error) {
        dispatch(
          setSuccessMessage(
            `${currentRoute?.driverId ? "Reassigned" : "Assigned"} successfully!`
          )
        );
        setShowAssignDriver(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitCancelRoute = async (routeId) => {
    try {
      const { error } = await dispatch(cancelRoute(routeId));
      if (!error) {
        dispatch(setSuccessMessage("Canceled job successfully!"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <RouteHeader
        breadCrumbs={breadCrumbs}
        buttonParams={buttonParams}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showExport={true}
        showImport={canCreate && isFlexioAdmin}
        setShowImport={setShowImport}
        setShowImportHistorical={setShowImportHistorical}
        exporting={exporting}
        onExport={handleExportRoutes}
        isAdmin
      />
      {showFilter && (
        <FilterRoute
          isAdmin={true}
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterRoutes(undefined))}
        />
      )}
      <div className="admin-content">
        <RouteTable
          dataSource={routeList}
          pagination={pagination}
          onCancelRoute={handleSubmitCancelRoute}
          onAssignDriverClick={handleAssignDriverClick}
          onReassignDriverClick={handleAssignDriverClick}
          onUnassignDriverClick={handleUnassignDriver}
          hiddenColumns={["Min. Vehicle"]}
        />
      </div>
      {showImport && (
        <Modal
          closable={false}
          visible={true}
          title={"Import Routes Data"}
          className="no-padding-modal"
          onCancel={() => setShowImport(false)}
          footer={false}
          width={1000}
        >
          <ImportLocations handleCloseModal={setShowImport} isAdmin />
        </Modal>
      )}
      {showImportHistorical && (
        <Modal
          closable={false}
          visible={true}
          title={"Import Historical Jobs Data"}
          className="no-padding-modal"
          onCancel={() => setShowImportHistorical(false)}
          footer={false}
          width={1000}
        >
          <ImportHistoricalJobs
            handleCloseModal={setShowImportHistorical}
            isAdmin
            refreshRoutes={refreshRoutes}
          />
        </Modal>
      )}
      {showAssignDriver && (
        <Modal
          closable={false}
          visible={true}
          title={`${currentRoute?.driverId ? "Reassign" : "Assign"} Driver`}
          width={600}
          footer={
            <div className="buttons-modal">
              <Button size="large" onClick={() => setShowAssignDriver(false)}>
                Cancel
              </Button>
              <Button
                disabled={
                  !assignedDriverId || assignedDriverId === currentRoute?.driverId
                }
                loading={isLoading}
                size="large"
                type="primary"
                onClick={handleSubmitAssignDriver}
              >
                Assign
                <CheckCircleOutlined />
              </Button>
            </div>
          }
        >
          <Select
            size="large"
            placeholder="Select driver"
            onChange={(driverId) => setAssignedDriverId(driverId)}
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            value={assignedDriverId}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input?.toLowerCase())
            }
          >
            {clientDrivers.map((driver) => (
              <Select.Option key={driver.id} value={driver.id}>
                {`${fullName(driver)} (${driver.email})`}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      )}
    </div>
  );
};
export default RouteList;
