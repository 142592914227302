import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createDriverPassword } from "./thunk";

const DriverAdapter = createEntityAdapter({
  selectId: (driver) => driver.id
});

const initialState = DriverAdapter.getInitialState({ metadata: {}, driver: {} });

export const clientDriversSlice = createSlice({
  name: "clientDrivers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createDriverPassword.fulfilled, (state, { payload }) => {
      console.log("payload", payload);
    });
  }
});

export const {
  selectById: selectDriverById,
  selectIds: selectDriverIds,
  selectEntities: selectDriverEntities,
  selectAll: selectAllDrivers,
  selectTotal: selectTotalDrivers
} = DriverAdapter.getSelectors((state) => state.clientDrivers);

export const selectMetadata = (state) => state.clientDrivers.metadata;
export const selectDriver = (state) => state.clientDrivers.driver;

export const { updatePaginationDrivers } = clientDriversSlice.actions;

export default clientDriversSlice.reducer;
