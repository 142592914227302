import React from "react";
import "./Slot.scss";

const getItemStyle = (isDragging, draggableStyle) => {
  if (isDragging) {
    return {
      // backgroundColor: "#cecece",
      ...draggableStyle
    };
  }
  return {
    ...draggableStyle
  };
};
const Slot = ({
  stop,
  handleHoverMarker,
  handleClosePopup,
  handleClickMarker,
  provided,
  snapshot,
  handleStopDetail
}) => {
  const clickStop = () => {
    handleClickMarker(stop, "stop");
    handleStopDetail(stop);
  };

  return (
    <div
      className={`slot-component ${stop?.type}`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...getItemStyle(snapshot.isDragging, provided.draggableProps.style)
      }}
      onMouseOver={() => handleHoverMarker(stop, "stop")}
      onMouseOut={() => handleClosePopup()}
      onClick={clickStop}
    >
      {stop?.sortNo || stop?.unscheduledNo}
    </div>
  );
};
export default Slot;
