export const RouteLocationStatus = {
  Draft: "draft",
  Unassigned: "unassigned",
  Unserved: "unserved",
  Assigned: "assigned",
  Pending: "pending",
  Arrived: "arrived",
  Completed: "completed",
  Failed: "failed"
};
