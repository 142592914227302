import request from "helper/request";

const additionalVehicleApi = {
  getList: (params) => {
    console.log(params);
    return request.get("/admin/additional-vehicles", {
      params
    });
  },
  create: (data) => {
    console.log(data);
    return request.post("/admin/additional-vehicles", data);
  },
  update: (data) => {
    const id = data.id;
    delete data.id;
    console.log(data);
    return request.patch(`/admin/additional-vehicles/${id}`, data);
  },
  get: (id) => {
    console.log(id);
    return request.get(`/admin/additional-vehicles/${id}`);
  },
  delete: (id) => {
    return request.delete(`admin/additional-vehicles/${id}`);
  }
};

export default additionalVehicleApi;
