import request from "helper/request";

const flexioPoolApi = {
  createOrder: (data) => {
    return request.post("/orderv2/create", data);
  },

  getPackages: (params) => {
    return request.get("orderv2/all-packages", { params });
  },

  updateOrderStatus: (data) => {
    return request.patch("/orderv2/update-order-status", data);
  },

  updatePackageStatus: (packageId, status) => {
    return request.patch(`orderv2/${packageId}/status`, status);
  }
};

export default flexioPoolApi;
