import { createAsyncThunk } from "@reduxjs/toolkit";
import routeApi from "./api";

export const getRouteList = createAsyncThunk("orders/get/loading", async (data) => {
  return routeApi.getList(data);
});
export const getRouteHistoryList = createAsyncThunk(
  "orders-history/get/loading",
  async (data) => {
    return routeApi.getListHistory(data);
  }
);

export const doDeleteRoute = createAsyncThunk("orders/delete/loading", async (id) => {
  return routeApi.delete(id);
});

export const doGetRoute = createAsyncThunk("routes/detail/loading", async (data) => {
  return routeApi.getRoute(data);
});

export const doGetRouteOrder = createAsyncThunk("routes/detail/loading", async (data) => {
  return routeApi.getRouteOrder(data);
});

export const doGetRoutesDispatch = createAsyncThunk(
  "route-dispatch/active/loading",
  async (data) => {
    return routeApi.getRouteDispatch(data);
  }
);

export const assignDriver = createAsyncThunk(
  "admin/routes/assignDriver/loading",
  async ({ routeId, driverId }) => {
    return routeApi.assignDriver(routeId, driverId);
  }
);

export const reassignDriver = createAsyncThunk(
  "admin/routes/reassignDriver/loading",
  async ({ routeId, driverId }) => {
    return routeApi.reassignDriver(routeId, driverId);
  }
);

export const cancelRoute = createAsyncThunk(
  "routes/cancelRoute/loading",
  async (routeId) => {
    return routeApi.cancelRoute(routeId);
  }
);
