import request from "../../../../helper/request";

const settingApi = {
  getPackageTypeList: (params) => {
    return request.get("/admin/package-types", {
      params
    });
  },
  getAllPackageType: () => {
    return request.get("/admin/package-types/all");
  },
  createPackageType: (data) => {
    return request.post("/admin/package-types", data);
  },
  updatePackageType: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/package-types/${id}`, data);
  },
  getPackageType: (id) => {
    return request.get(`/admin/package-types/${id}`);
  },
  deletePackageType: (id) => {
    return request.delete(`admin/package-types/${id}`);
  },
  getVehicleCategoryList: (params) => {
    return request.get("/admin/vehicle-categories", {
      params
    });
  },
  getAllVehicleCategory: () => {
    return request.get("/admin/vehicle-categories/all");
  },
  createVehicleCategory: (data) => {
    return request.post("/admin/vehicle-categories", data);
  },
  updateVehicleCategory: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/vehicle-categories/${id}`, data);
  },
  getVehicleCategory: (id) => {
    return request.get(`/admin/vehicle-categories/${id}`);
  },
  deleteVehicleCategory: (id) => {
    return request.delete(`admin/vehicle-categories/${id}`);
  },
  getServiceList: (params) => {
    return request.get("/admin/services", {
      params
    });
  },
  createService: (data) => {
    return request.post("/admin/services", data);
  },
  updateService: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/services/${id}`, data);
  },
  setDefaultService: (id) => {
    return request.patch(`/admin/services/${id}/set-is-default`);
  },
  getService: (id) => {
    return request.get(`/admin/services/${id}`);
  },
  deleteService: (id) => {
    return request.delete(`admin/services/${id}`);
  },
  getTerritoryList: (params) => {
    return request.get("/admin/territories", {
      params
    });
  },
  createTerritory: (data) => {
    return request.post("/admin/territories", data);
  },
  updateTerritory: (data) => {
    const id = data.id;
    delete data.id;
    return request.patch(`/admin/territories/${id}`, data);
  },
  getTerritory: (id) => {
    return request.get(`/admin/territories/${id}`);
  },
  deleteTerritory: (id) => {
    return request.delete(`admin/territories/${id}`);
  },
  getSaasCountriesList: (params) => {
    return request.get("/admin/saascountries", {
      params
    });
  },
  getAllSaasCountries: () => {
    return request.get("/admin/saascountries/all");
  },
  createSaasCountry: (data) => {
    return request.post("/admin/saascountries", data);
  },
  deleteSaasCountry: (id) => {
    return request.delete(`admin/saascountries/${id}`);
  },
  getFailedRegistrationList: (params) => {
    return request.get("/admin/failedregistration", {
      params
    });
  },
  getAllFailedRegistration: () => {
    return request.get("/admin/failedregistration/all");
  }
};

export default settingApi;
