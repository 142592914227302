import { notification } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError, setSuccessMessage } from "./app/global/slice";
import { logout } from "./modules/auth/store/authSlice";

const AppError = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.global.errors);
  const successMessage = useSelector((state) => state.global.successMessage);
  useEffect(() => {
    errors.message &&
      notification.error({
        message:
          errors.status === 401 || errors.status === 403
            ? "You have been logged out due to inactivity. Please log in again to continue using the application."
            : errors.message,
        placement: "topRight",
        onClose: () => dispatch(setError({}))
      });
    if (errors.status === 401) {
      dispatch(logout());
    }
  }, [errors, dispatch]);
  useEffect(() => {
    successMessage &&
      notification.success({
        message: successMessage,
        placement: "topRight",
        onClose: () => dispatch(setSuccessMessage(""))
      });
  }, [successMessage, dispatch]);
  return <></>;
};
export default AppError;
