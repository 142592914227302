export function buildFirstLevelFilter(filter) {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = ["is_internal_driver", "active"];

  const FILTER_VALUES = {
    is_internal_driver: "$eq",
    active: "$eq"
  };
  return Object.keys(filter)
    .filter((field) => FIRST_LEVEL_FILTER.includes(field) && filter[field])
    .map((filteredField) => {
      let value = filter[filteredField];
      return `${filteredField}||${FILTER_VALUES[filteredField]}||${value}`;
    });
}
