import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "./api";

export const getRouteList = createAsyncThunk("admin/routes/get/loading", async (data) => {
  return adminApi.getRouteList(data);
});

export const doGetRoute = createAsyncThunk("admin/routes/getOne/loading", async (id) => {
  return adminApi.getRoute(id);
});

export const assignDriver = createAsyncThunk(
  "admin/routes/assignDriver/loading",
  async ({ routeId, driverId }) => {
    return adminApi.assignDriver(routeId, driverId);
  }
);

export const reassignDriver = createAsyncThunk(
  "admin/routes/reassignDriver/loading",
  async ({ routeId, driverId }) => {
    return adminApi.reassignDriver(routeId, driverId);
  }
);

export const unassignDriver = createAsyncThunk(
  "admin/routes/unassignDriver/loading",
  async (routeId) => {
    return adminApi.unassignDriver(routeId);
  }
);

export const cancelRoute = createAsyncThunk(
  "admin/routes/cancelRoute/loading",
  async (routeId) => {
    return adminApi.cancelRoute(routeId);
  }
);
export const updatePrice = createAsyncThunk(
  "admin/routes/updatePrice/loading",
  async (route) => {
    await adminApi.updatePrice(route);
    return route;
  }
);
