// export const COUNTRY_OPTIONS = [
//   {
//     isoCode: "US",
//     name: "United States"
//   },
//   {
//     isoCode: "PR",
//     name: "Puerto Rico"
//   }
// ];
export const VEHICLE_MAKE_OPTIONS = [
  {
    value: "ford",
    name: "Ford"
  },
  {
    value: "toyota",
    name: "Toyota"
  },
  {
    value: "mazDa",
    name: "MazDa"
  }
];
export const VEHICLE_MODEL_OPTIONS = [
  {
    value: "f150",
    name: "f150"
  },
  {
    value: "f170",
    name: "f170"
  }
];
export const VEHICLE_COLOR_OPTIONS = [
  {
    value: "red",
    name: "Red"
  },
  {
    value: "black",
    name: "Black"
  },
  {
    value: "white",
    name: "White"
  }
];

export const VEHICLE_YEAR_OPTIONS = [
  {
    value: "1991",
    name: "1991"
  },
  {
    value: "1992",
    name: "1992"
  },
  {
    value: "1993",
    name: "1993"
  }
];

export const ACCOUNT_TYPE_OPTIONS = [
  { value: "checking", name: "Checking" },
  { value: "savings", name: "Savings" }
];

export const BOOLEAN_OPTIONS = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" }
];

export const BOOLEAN_OTHER_OPTIONS = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
  { value: "other", name: "Other" }
];

export const RATE_OPTIONS = [
  { value: "1", name: "1" },
  { value: "2", name: "2" },
  { value: "3", name: "3" },
  { value: "4", name: "4" },
  { value: "5", name: "5" }
];

export const LANGUAGES_OPTIONS = ["English", "Spanish", "French", "Portuguese", "Other"];
export const FAVORITES_OPTIONS = [
  "Facebook",
  "Instagram",
  "Youtube",
  "TV",
  "Email",
  "Text Message",
  "Friend",
  "Other"
];
