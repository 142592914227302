import classes from "./StopOnMap.module.css";
import React from "react";

export default function StopOnMap({
  borderColor,
  backgroundColor,
  onClick,
  style,
  children
}) {
  return (
    <div className={[classes.stop_on_map]} onClick={onClick}>
      <div className={classes.drop_down} style={{ backgroundColor: borderColor }} />
      <div
        style={{
          backgroundColor: backgroundColor,
          borderColor: borderColor
        }}
        className={classes.shape_in}
      />
      <div className={classes.shadow} />
      <div
        className={classes.child}
        style={{
          color: borderColor,
          ...style
        }}
      >
        {children}
      </div>
    </div>
  );
}
