import React from "react";
const ApprovalIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.24" cx="40" cy="40" r="40" fill="#00C853" />
      <g filter="url(#filter0_d_3049_31095)">
        <circle cx="40" cy="40" r="32" fill="#00C853" />
      </g>
      <path
        d="M36 45.56L30.44 40L28.5466 41.88L36 49.3333L52 33.3333L50.12 31.4533L36 45.56Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_3049_31095"
          x="4"
          y="4"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3049_31095"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3049_31095"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export { ApprovalIcon };
